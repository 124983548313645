import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS, Transform } from '@dnd-kit/utilities';

import Photo from 'Components/Molecules/Photo';

import { AssetAdmin } from 'Operations/__generated__/graphql';

const SortablePhoto = ({
  id,
  name,
  asset,
  isPhotoSelected,
  isSortable = false,
  isLiked = false,
  isOrdered = false,
  unreadCommentsCount,
  handleOnClickImage,
  handleOnSelectPhoto,
}: {
  id: number;
  name: string;
  asset: AssetAdmin;
  isPhotoSelected: boolean;
  isLiked?: boolean;
  isOrdered?: boolean;
  isSortable?: boolean;
  unreadCommentsCount?: number;
  handleOnClickImage?: (params: { photoId: number }) => void;
  handleOnSelectPhoto: (params: { photoId: number }) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: id.toString(),
    disabled: !isSortable,
  });
  const newTransform: Transform = { x: transform?.x || 0, y: transform?.y || 0, scaleX: 1, scaleY: 1 };
  const style = {
    transform: CSS.Transform.toString(newTransform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <Photo
      id={id}
      name={name}
      asset={asset}
      isPhotoSelected={isPhotoSelected}
      isLiked={isLiked}
      isOrdered={isOrdered}
      unreadCommentsCount={unreadCommentsCount}
      handleOnClickImage={handleOnClickImage}
      handleOnSelectPhoto={handleOnSelectPhoto}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      data-draggable={true}
    />
  );
};

export default SortablePhoto;
