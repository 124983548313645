import { useSubscription } from '@apollo/client';

import { compact } from 'lodash';

import { GalleryAdmin, JobStatus } from 'Operations/__generated__/graphql';

import { ON_JOB_UPDATED } from 'Operations/Subscriptions/OnJobUpdated';

const completedStatuses = [JobStatus.COMPLETED, JobStatus.FAILED];

export const useJobSubscription = () => {
  useSubscription(ON_JOB_UPDATED, {
    onData({ data, client: { cache } }) {
      if (data.data?.OnJobUpdated) {
        const job = data.data.OnJobUpdated;
        if (job.jobType !== 'GALLERY_WATERMARK' && job.jobType !== 'GALLERY_UNWATERMARK') {
          return;
        }

        // If the job is completed, we need remove the job from the cache
        if (completedStatuses.includes(job.status)) {
          const normalizedId = cache.identify({
            id: job.id,
            __typename: job.__typename,
          });
          cache.evict({ id: normalizedId });
          cache.gc();

          return;
        }

        if (job.gallery?.id) {
          // Update gallery
          cache.modify<GalleryAdmin>({
            id: cache.identify({
              __typename: 'GalleryAdmin',
              id: job.gallery.id,
            }),
            fields: {
              jobs(existing, { toReference, readField }) {
                // Check if job is already in the list
                if (
                  !existing.find(
                    ref =>
                      ('__ref' in ref && readField('id', ref) === job.id) || (!('__ref' in ref) && ref.id === job.id),
                  )
                ) {
                  return compact([
                    toReference({
                      __typename: job.__typename,
                      id: job.id,
                    }),
                    ...(existing || []),
                  ]);
                }
                return existing;
              },
            },
          });
        }
      }
    },
  });
};
