import React, { ReactElement, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { App, Button, Divider } from 'antd';
import { Link } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { camelCase } from 'lodash';

import Container from 'Components/Atoms/Container';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import { OnSubmitPayload, SignInForm } from 'Forms/SignIn';

import { Colors, Images, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { ApolloContext } from 'Providers/ApolloProvider';

import getErrorCode from 'Helpers/GetErrorCode';

import { DisplayType } from 'Operations/__generated__/graphql';

import CreateAuth from 'Operations/Mutations/Auth/CreateAuth';
import { SIGN_IN } from 'Operations/Mutations/Auth/SignIn';
import { SIGN_IN_WITH_GOOGLE } from 'Operations/Mutations/Auth/SignInWithGoogle';
import upateUserConfig from 'Operations/Mutations/User/UpdateUserConfig';

const AuthContainer = styled(Container)`
  height: 100vh;
`;

const FormContainer = styled(Container)`
  padding: ${Metrics.baseMargin}px;
`;

const SignInTitle = styled(Title)`
  margin-top: ${Metrics.baseMargin}px;
`;

const SignUpTitle = styled(Title)`
  margin-top: ${Metrics.doubleBaseMargin}px;
`;

const SignUpText = styled(Text)`
  width: 100%;
`;

const ButtonStyled = styled(Button)`
  margin: ${Metrics.smallMargin}px auto 0 auto;
`;

const Illustration = styled.img`
  width: 70%;
  max-width: 800px;
`;

const IllustrationContainer = styled(Container)`
  flex: 1;
  height: 100vh;
  background: ${Colors.primaryMain};
  background: -moz-linear-gradient(120deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
  background: -webkit-linear-gradient(120deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
  background: linear-gradient(120deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${Colors.primaryMain}",endColorstr="${Colors.primaryOptional}",GradientType=1);
`;

const SignIn = () => {
  const { t } = useContext(LocalizationContext);
  const { refreshClient } = useContext(ApolloContext);
  const { message } = App.useApp();

  const [signIn, { data }] = useMutation(SIGN_IN, {
    fetchPolicy: 'no-cache',
  });

  const [signInWithGoogle, { data: googleData }] = useMutation(SIGN_IN_WITH_GOOGLE, {
    fetchPolicy: 'no-cache',
  });

  if (data) {
    CreateAuth({
      isLoggedIn: true,
      isSupport: false,
      accessToken: data.signIn.accessToken,
      refreshToken: data.signIn.refreshToken,
    });

    upateUserConfig({
      galleriesDisplay: DisplayType.TABLE,
      isMenuCollapsed: false,
    });
    refreshClient();
  }

  if (googleData) {
    CreateAuth({
      isLoggedIn: true,
      isSupport: false,
      accessToken: googleData.signInWithGoogle.accessToken,
      refreshToken: googleData.signInWithGoogle.refreshToken,
    });

    refreshClient();
  }

  const handleOnSubmit = async (data: OnSubmitPayload) => {
    try {
      await signIn({ variables: { data: data.values } });
      data.formikBag.setSubmitting(false);

      message.success(t('auth.signIn.message.success'));
    } catch (error) {
      const errorKey = camelCase(getErrorCode(error));

      let trKey: string | ReactElement = errorKey
        ? `auth.signIn.errors.${errorKey}`
        : 'app.message.error.somethingWentWrong';

      if (errorKey === 'subscriptionEnded') {
        trKey = (
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={t('auth.signIn.errors.subscriptionEnded', {
              crmUrl: `${process.env.REACT_APP_CRM_URL}/app/subscriptions/new`,
            })}
            components={{
              p: ({ children }) => <p style={{ display: 'inline' }}>{children}</p>,
              b: ({ children }) => (
                <Text size="medium" weight="bold">
                  {children}
                </Text>
              ),
              a: ({ children, ...props }) => <a href={props.href}>{children}</a>,
            }}
          />
        );
      }

      message.error(trKey);

      data.formikBag.setSubmitting(false);
      data.formikBag.setErrors({ email: '', password: '' });
    }
  };

  const handleOnSuccessGoogle = async (data: CredentialResponse) => {
    try {
      if (!data.credential) {
        message.success(t('app.message.error.somethingWentWrong'));
        return;
      }

      await signInWithGoogle({ variables: { data: { token: data.credential } } });
      message.success(t('auth.signIn.message.success'));
    } catch (error) {
      const errorKey = camelCase(getErrorCode(error));
      const trKey = errorKey ? `auth.signIn.errors.${errorKey}` : 'app.message.error.somethingWentWrong';
      message.error(t(trKey));
    }
  };

  return (
    <AuthContainer>
      <FormContainer direction="column">
        <Link to="/">{t('auth.common.backToHome')}</Link>
        <SignInTitle level="h2" align="center">
          {t('auth.signIn.title')}
        </SignInTitle>
        <SignInForm onSubmit={handleOnSubmit} />
        <Divider>{t('app.common.or')}</Divider>
        <Container direction="column" align="center" block>
          <GoogleLogin
            onSuccess={credentialResponse => {
              handleOnSuccessGoogle(credentialResponse);
            }}
            onError={() => {
              message.success(t('app.message.error.somethingWentWrong'));
            }}
          />
        </Container>
        <SignUpTitle level="h2" align="center">
          {t('auth.signIn.noAccount')}
        </SignUpTitle>
        <SignUpText align="center">{t('auth.signIn.noAccountSubtitle')}</SignUpText>
        <ButtonStyled href="/auth/signup">{t('app.common.signUp')}</ButtonStyled>
      </FormContainer>
      <IllustrationContainer justify="center" align="center">
        <Illustration src={Images.authIllustration} alt="Sign in illustration" />
      </IllustrationContainer>
    </AuthContainer>
  );
};

export default SignIn;
