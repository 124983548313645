import React from 'react';

import { Modal } from 'antd';

import { useModals } from 'Hooks/Modal';

type Props = {
  title: string;
  content: React.ReactNode;
};

const InfoModal = ({ title, content }: Props) => {
  const { closeModal } = useModals();
  return (
    <Modal open onCancel={() => closeModal()} title={title} footer={null}>
      {content}
    </Modal>
  );
};

export default InfoModal;
