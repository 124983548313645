import React, { useContext } from 'react';

import { Button, Flex, Table } from 'antd';

import { Formik } from 'formik';
import { Form, InputNumber, Switch } from 'formik-antd';

import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import Select from 'Components/Molecules/Form/Select';

import { LocalizationContext } from 'i18n';

import {
  GalleryWorkmode,
  IncludedProductInputType,
  IncludedRight
} from 'Operations/__generated__/graphql';

export interface IncludedProductValues extends Omit<IncludedProductInputType, 'id'> {}

interface Props {
  workmode: GalleryWorkmode;
  title?: string;
  titleActions?: React.ReactNode;
  includedProducts: IncludedProductValues[];
  isDigital?: boolean;
  isLoading: boolean;
  hideQuantity?: boolean;
  accessRole?: IncludedRight;
  loadMoreProducts: () => void;
  maxSelectableProducts?: number;
  minIncludedCount: number;
  hasFetchAllProducts: boolean;
  products: Array<{ id: number; name: string }>;
  onChange?: (values: { includedProducts: IncludedProductValues[]; errors?: Record<string, string> }) => void;
  emptyText?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const IncludedProductsForm = ({
  workmode,
  title,
  titleActions,
  includedProducts,
  maxSelectableProducts,
  minIncludedCount,
  hasFetchAllProducts,
  hideQuantity,
  products,
  accessRole,
  loadMoreProducts,
  isLoading,
  isDigital,
  onChange,
  emptyText,
  ...props
}: Props) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Formik
      initialValues={{
        includedProducts,
      }}
      enableReinitialize
      validate={values => {
        // We can't have the same product twice
        const productIds = values.includedProducts.map(ip => ip.productId);
        const duplicateProductIndex = productIds.findIndex((id, i) => productIds.indexOf(id) !== i);

        let errors: Record<string, string> | undefined;

        if (duplicateProductIndex >= 0) {
          errors = { includedProducts: t('app.form.errors.duplicateIncludedProducts') };
        }

        onChange?.({ ...values, errors });

        return errors;
      }}
      // Don't submit the form as we are handling submit in the parent component with the onChange prop
      onSubmit={values => {}}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>
          <Table
            title={
              title
                ? () => (
                    <Flex justify="space-between" align="center" gap={2}>
                      <Title level="h5">{title}</Title>
                      {titleActions && (
                        <Flex justify="end" align="center" gap={2}>
                          {titleActions}
                        </Flex>
                      )}
                    </Flex>
                  )
                : undefined
            }
            dataSource={values.includedProducts}
            loading={isLoading}
            pagination={false}
            locale={{
              emptyText,
            }}
            size="small"
            columns={[
              {
                title: t('app.common.products', { count: 1 }),
                dataIndex: 'productId',
                key: 'productId',
                width: hideQuantity ? undefined : '40%',
                render(value, record, index) {
                  return (
                    <Select
                      style={{ width: '100%' }}
                      name={`includedProducts.${index}.productId`}
                      size="large"
                      loadMore={() => loadMoreProducts}
                      hasFetchAll={hasFetchAllProducts}
                      loading={isLoading}
                      getPopupContainer={trigger => trigger.parentNode}
                    >
                      {products.map(product => (
                        <Select.Option key={product.id} value={product.id} title={''}>
                          {product.name}
                        </Select.Option>
                      ))}
                    </Select>
                  );
                },
              },
              {
                title: t('app.gallery.hasAllPhotosIncludedPublic.label'),
                hidden: workmode === GalleryWorkmode.RETOUCH_FIRST || !isDigital || !!hideQuantity,
                render(value, record, index) {
                  return <Switch name={`includedProducts.${index}.hasAllIncluded`} />;
                },
              },
              {
                title: t('app.common.quantity'),
                dataIndex: 'quantity',
                key: 'quantity',
                hidden: hideQuantity,
                render(value, record, index) {
                  return (
                    <InputNumber
                      name={`includedProducts.${index}.quantity`}
                      min={!record?.hasAllIncluded ? minIncludedCount : undefined}
                      disabled={values.includedProducts?.[index]?.hasAllIncluded}
                      size="large"
                    />
                  );
                },
              },
              {
                title: t('app.common.actions'),
                key: 'actions',
                width: 50,
                render(value, record, index) {
                  return (
                    <RoundButton
                      icon="delete"
                      danger
                      onClick={() => {
                        setFieldValue(
                          'includedProducts',
                          values.includedProducts.filter((_, i) => i !== index),
                        );
                      }}
                    />
                  );
                },
              },
            ]}
            footer={() => {
              const notAlreadyAddedProduct = products.find(
                p => !values.includedProducts.find(ip => ip.productId === p.id),
              );
              const isDuplicateProduct = values.includedProducts.some(
                (ip, i) => values.includedProducts.findIndex(ip2 => ip2.productId === ip.productId) !== i,
              );

              if (
                !isDuplicateProduct && // We can't have the same product twice
                notAlreadyAddedProduct && // There are still products that are not already added
                (maxSelectableProducts === undefined || values.includedProducts.length < maxSelectableProducts) // We can still add more products
              ) {
                return (
                  <Button
                    type="dashed"
                    block
                    onClick={() => {
                      setFieldValue('includedProducts', [
                        ...values.includedProducts,
                        {
                          productId: notAlreadyAddedProduct.id,
                          quantity: 1,
                          // If we hide the quantity field, we set the hasAllIncluded to true by default
                          hasAllIncluded: !!hideQuantity,
                          accessRole,
                        },
                      ]);
                    }}
                  >
                    {t('app.products.add', { count: 1 })}
                  </Button>
                );
              }

              return null;
            }}
            {...props}
          />
          {errors.includedProducts && (
            <Text size="medium" color="red" align="center" style={{ width: '100%' }}>
              {errors.includedProducts as string}
            </Text>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default IncludedProductsForm;
