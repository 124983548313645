import React, { forwardRef, HTMLAttributes } from 'react';

import styled, { css } from 'styled-components';

import { Metrics } from 'Themes';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  direction?: 'column' | 'center' | 'row';
  align?: 'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between' | 'stretch';
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between';
  position?: 'relative' | 'absolute' | 'fixed' | 'sticky' | 'static';
  padding?: 'baseMargin' | 'doubleBaseMargin' | 'smallMargin' | 'tinyMargin';
  flex?: number;
  block?: boolean;
  gap?: number;
}

const StyledContainer = styled.div<Props>`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: ${({ justify = 'flex-start' }) => justify};
  align-items: ${({ align = 'flex-start' }) => align};
  flex-wrap: wrap;
  position: ${({ position = 'static' }) => position};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${Metrics[padding]}px;
    `};
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `};
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `};
  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `};
`;

const Container = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return <StyledContainer ref={ref} className="Row" {...props} />;
});

export default Container;
