import { gql } from 'Operations/__generated__';

export const CREATE_PRODUCT_OPTION = gql(/* GraphQL */ `
  mutation CreateProductOption($where: IdInput!, $data: ProductOptionCreateInput!) {
    createProductOption(where: $where, data: $data) {
      id
      name
      providerPrice
      providerDescription
      pricingPlan {
        id
      }
      pricingPlanMeta {
        startingPrice
        startingQuantity
        hasMultiplePrices
        grossPrice
        grossQuantity
      }
      previewAsset {
        downloadUrl
      }
      hasPricingPlan
      hasPresetPricingPlan
    }
  }
`);
