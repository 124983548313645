import { gql } from 'Operations/__generated__';

export const GET_PRODUCTS_BY_TYPE = gql(/* GraphQL */ `
  query GetProductsByType($where: DigitalProductsWhereInput!, $paginate: PaginatedInput) {
    getProductsByType(where: $where, paginate: $paginate) {
      _count
      edges {
        id
        name
        digitalProductConfig {
          id
          downloadContent
        }
      }
    }
  }
`);
