import { gql } from 'Operations/__generated__';

export const GET_GALLERY_PRESETS = gql(/* GraphQL */ `
  query GetGalleryPresets($where: PaginatedInput) {
    getGalleryPresets(where: $where) {
      _count
      edges {
        id
        name
        createdAt
      }
    }
  }
`);
