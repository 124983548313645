import { useCallback, useContext, useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { Alert, App } from 'antd';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Title from 'Components/Atoms/Title';

import PhotoTypesSwitch from 'Components/Molecules/Form/GalleryDownloadSelect';

import DigitalProductConfigSkeleton from 'Pages/App/Products/ProductsShow/DigitalProductConfig/DigitalProductConfigSkeleton';

import { EditPricingPlanForm, EditPricingPlanFormPayload, EditPricingPlanFormValues } from 'Forms/PricingPlan';
import { EditProductForm, EditProductFormPayload, EditProductFormValues } from 'Forms/Product';

import { Images, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { Dimension, GalleryDownload, Product } from 'Operations/__generated__/graphql';

import { GET_PRICING_PLAN } from 'Operations/Queries/PricingPlan/GetPricingPlan';

import { UPDATE_DIGITAL_PRODUCT_CONFIG } from 'Operations/Mutations/DigitalProductConfig/UpdateDigitalProductConfig';
import { UPDATE_PRICING_PLAN } from 'Operations/Mutations/PricingPlan/UpdatePricingPlan';
import { UPDATE_PRODUCT } from 'Operations/Mutations/Product/UpdateProduct';

interface DigitalProductConfigProps {
  product: DeepPartial<Product>;
  pricingPlanId: number;
  digitalProductConfigId: number;
  productOptionId: number;
  productOptionName: string;
  downloadContent: GalleryDownload;
}

const LeftContainer = styled(Container)`
  flex: 1;
  margin-right: ${Metrics.smallMargin}px;
`;

const RightContainer = styled(Container)`
  flex: 1;
  margin-left: ${Metrics.smallMargin}px;
`;

const StyledPricingPlanContainer = styled(ContentContainer)`
  width: 100%;
  margin-bottom: ${Metrics.baseMargin}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledPhotoTypeContainer = styled(ContentContainer)`
  flex: 1;
  width: 100%;
  margin-bottom: ${Metrics.baseMargin}px;
`;

const StyledContainer = styled(Container)`
  flex: 1;
  width: 100%;
  margin-top: ${Metrics.baseMargin}px;
`;

const OptionTitle = styled(Title)`
  margin-bottom: ${Metrics.baseMargin}px;
`;

const AlertStyled = styled(Alert)`
  margin-bottom: ${Metrics.baseMargin}px;
`;

const DigitalProductConfig = ({
  product,
  pricingPlanId,
  digitalProductConfigId,
  downloadContent,
}: DigitalProductConfigProps) => {
  const { message } = App.useApp();
  const { t } = useContext(LocalizationContext);

  const {
    data: pricingPlanData,
    loading: isPricingPlanLoading,
    error: pricingPlanError,
  } = useQuery(GET_PRICING_PLAN, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: pricingPlanId,
      },
    },
  });

  const productDefaultValues: EditProductFormValues = useMemo(
    () => ({
      product: {
        id: product.id as number,
        categoryId: product.category?.id as number,
        contactId: product.contactId ?? undefined,
        name: product.name || '',
        previewImageUrl: product.previewAsset?.downloadUrl ?? Images.productDetailsMissing,
        maxPhotoCount: product.maxPhotoCount || 1,
        description: product.description || '',
        orderedByFotostudio: !!product.orderedByFotostudio,
        photoSizes:
          product?.photoSizes?.map(
            dimension => ({ height: dimension?.height, width: dimension?.width } as Dimension),
          ) || [],
        presetProductId: product.presetProductId ?? undefined,
        isPreconfiguredProduct: !!product.isPreconfiguredProduct,
        isFotostudioProduct: !!product.isFotostudioProduct,
        enableCrop: (product?.photoSizes?.length || 0) > 0,
        isDigital: false,
        weight: product.weight ?? undefined,
      },
    }),
    [product],
  );

  const [updatePricingPlan] = useMutation(UPDATE_PRICING_PLAN);

  const [updateDigitalProductConfig] = useMutation(UPDATE_DIGITAL_PRODUCT_CONFIG);

  const [updateProduct] = useMutation(UPDATE_PRODUCT);

  const handleProductEditOnSubmit = useCallback(
    async ({
      values: {
        product: {
          id,
          previewImageUrl: _pwUrl,
          isPreconfiguredProduct: _isPreconfigured,
          enableCrop: _enableCrop,
          isDigital: _isDigital,
          presetProductId: _presetProductId,
          pricingPlanId: _pricingPlanId,
          isFotostudioProduct: _isFotostudioProduct,
          withNewPricingPlan: _withNewPricingPlan,
          ...productData
        },
      },
      formikBag,
    }: EditProductFormPayload) => {
      try {
        await updateProduct({
          variables: {
            where: {
              id,
            },
            data: productData,
          },
        });

        message.success(t('app.message.product.update.success'));
        formikBag.setSubmitting(false);
      } catch (error) {
        const errorMessage = t(`app.message.error.somethingWentWrong`);
        message.error(errorMessage);
        formikBag.setSubmitting(false);
      }
    },
    [t, updateProduct],
  );

  const handleEditOnSubmit = useCallback(
    async ({ values, formikBag }: EditPricingPlanFormPayload) => {
      try {
        const {
          id,
          permitAdditionalItems: _isPermitAdditionalItems,
          withWholeGalleryPrice: _withWholeGalleryPrice,
          profitMarginPercent: _profitMarginPercent,
          items,
          type,
          wholeGalleryPrice,
        } = values;

        await updatePricingPlan({
          variables: {
            where: {
              id,
            },
            data: {
              items: items.map(item => ({
                id: item.id,
                price: item.price,
                quantity: item.quantity,
                additionalPrice: item.additionalPrice,
              })),
              type,
              wholeGalleryPrice,
            },
          },
        });

        message.success(t('app.message.pricingPlan.update.success'));
        formikBag.setSubmitting(false);
      } catch (error) {
        const errorMessage = t(`app.message.error.somethingWentWrong`);
        message.error(errorMessage);
        formikBag.setSubmitting(false);
      }
    },
    [t, updatePricingPlan],
  );

  const onPhotoTypesChange = useCallback(
    async (value: GalleryDownload) => {
      try {
        await updateDigitalProductConfig({
          variables: {
            where: {
              id: digitalProductConfigId,
            },
            data: {
              downloadContent: value,
            },
          },
        });

        message.success(t('app.message.product.digital.photoType.update.success'));
      } catch (error) {
        console.log(error);
        const errorMessage = t(`app.message.error.somethingWentWrong`);
        message.error(errorMessage);
      }
    },
    [digitalProductConfigId, t, updateDigitalProductConfig],
  );

  if (isPricingPlanLoading) {
    return <DigitalProductConfigSkeleton />;
  }

  if (pricingPlanError || !pricingPlanData) {
    return <Alert type="error" message="Error getting pricing plan. Try again." />;
  }

  const { __typename, ...pricingPlan } = pricingPlanData.getPricingPlan;
  const defaultValues = (({ id, name, type, wholeGalleryPrice, items }): EditPricingPlanFormValues => ({
    id,
    type,
    wholeGalleryPrice: wholeGalleryPrice ?? undefined,
    withWholeGalleryPrice: true,
    items: items.map(({ __typename, ...item }) => ({
      ...item,
      additionalPrice: item.additionalPrice || null,
    })),
  }))(pricingPlan);

  return (
    <StyledContainer>
      <LeftContainer direction="column">
        <StyledPricingPlanContainer shadow rounded padding="baseMargin">
          <OptionTitle level="h3">{t('app.product.digital.settings.title')}</OptionTitle>
          <EditProductForm defaultValues={productDefaultValues} onSubmit={handleProductEditOnSubmit} isAdmin={false} />
        </StyledPricingPlanContainer>
      </LeftContainer>
      <RightContainer direction="column">
        <StyledPhotoTypeContainer shadow rounded padding="baseMargin">
          <OptionTitle level="h3">{t('app.product.digital.photoType.label')}</OptionTitle>
          <AlertStyled type="info" message={t('app.gallery.settings.download.info2')} />
          <PhotoTypesSwitch defaultValue={downloadContent} onChange={onPhotoTypesChange} />
        </StyledPhotoTypeContainer>
        <StyledPricingPlanContainer shadow rounded padding="baseMargin">
          <OptionTitle level="h3">{t('app.common.pricingPlan')}</OptionTitle>
          <EditPricingPlanForm defaultValues={defaultValues} onSubmit={handleEditOnSubmit} />
        </StyledPricingPlanContainer>
      </RightContainer>
    </StyledContainer>
  );
};

export default DigitalProductConfig;
