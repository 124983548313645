import { gql } from 'Operations/__generated__';

export const GET_GALLERY_CONTACTS = gql(/* GraphQL */ `
  query GetGalleryContacts($where: GalleryGetWhereType!) {
    getGallery(where: $where) {
      ... on GalleryAdmin {
        id
        contact {
          ...ContactCoreFields
        }
        secondaryContacts {
          ...ContactCoreFields
        }
      }
    }
  }
`);
