import React, { useContext } from 'react';

import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import Container from 'Components/Atoms/Container';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useCurrency } from 'Hooks/useCurrency';

import { GetProductFiltersQuery, GetProductsQuery } from 'Operations/__generated__/graphql';

export interface OnChangeProps {
  page: number;
  categoryIds?: number[];
  contactIds?: number[];
}

interface Props {
  products: GetProductsQuery['getProducts']['edges'];
  filters?: GetProductFiltersQuery['getProductFilters'];
  isLoading: boolean;
  total: number;
  paginationSize: number;
  onChange: (props: OnChangeProps) => void;
  onAdd?: (props: { id: number }) => void;
}

const ProductsTable = ({ products, filters, total, paginationSize, isLoading, onChange, onAdd }: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);
  const { formatCurrency } = useCurrency();

  const columns: ColumnsType<GetProductsQuery['getProducts']['edges'][0]> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 120,
      render: (_, record) => (
        <Container justify="space-around">
          <Link
            to={
              record.isPreconfiguredProduct
                ? `/app/preconfigured-products/${record.id}`
                : `/app/fotostudio-products/${record.id}`
            }
          >
            <RoundButton icon="edit" tooltipTitle={t('app.common.edit')} />
          </Link>
          {onAdd && (
            <RoundButton
              icon="add"
              tooltipTitle={t('app.actions.addToMyProducts')}
              onClick={() => {
                onAdd({ id: record.id });
              }}
            />
          )}
        </Container>
      ),
    },
    {
      title: t('app.common.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('app.common.category'),
      dataIndex: 'category',
      key: 'category',
      filters: filters
        ? filters.categories.map(category => ({
            text: t(`app.categories.${category.name.toLowerCase()}`),
            value: category.value,
          }))
        : [],
      onFilter: (value, record) => {
        if (value === record.category.id) {
          return true;
        }

        return false;
      },
      render: (_, record) => t(`app.categories.${record.category.name.toLowerCase()}`),
    },
    {
      title: t('app.common.provider'),
      dataIndex: 'provider',
      key: 'provider',
      filters: filters
        ? filters.contacts.map(contact => ({
            text: contact.name,
            value: contact.value,
          }))
        : [],
      onFilter: (value, record) => {
        if (value === record.contact?.id) {
          return true;
        }

        return false;
      },
      render: (_, record) => record.contact?.displayName || '-',
    },
    {
      title: t('app.products.providerPrice'),
      dataIndex: 'providerPrice',
      key: 'providerPrice',
      render(providerPrice) {
        return providerPrice
          ? `${formatCurrency(providerPrice)} / ${t('app.common.unit', { count: 1 }).toLowerCase()}`
          : '-';
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()}`}
      loading={isLoading}
      dataSource={products}
      columns={columns}
      scroll={{ x: true }}
      pagination={{
        total,
        pageSize: paginationSize,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      onChange={(pagination, filters) => {
        const params: OnChangeProps = {
          page: pagination.current ? pagination.current : 1,
        };

        if (filters.category) {
          params.categoryIds = filters.category as number[];
        }

        if (filters.provider) {
          params.contactIds = filters.provider as number[];
        }

        onChange(params);
      }}
    />
  );
};

export default ProductsTable;
