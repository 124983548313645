import { gql } from 'Operations/__generated__';

export const GET_EMAIL_VARIABLES = gql(/* GraphQL */ `
  query GetEmailVariables {
    getEmailVariables {
      key
      name
    }
  }
`);
