import { createStyles } from 'antd-style';

export const useFormStyle = createStyles(({ css, prefixCls, token }) => ({
  className: css`
    &.${prefixCls}-form {
      width: 100%;

      .${prefixCls}-form-item--no-margin {
        margin-top: 0;
        margin-bottom: 0;
      }
      .${prefixCls}-form-item-label > label {
        font-size: ${token.fontSizeXS}px;
        text-transform: uppercase;
        font-weight: bold;
        color: ${token.colorTextBase};
      }

      &.${prefixCls}-form-vertical {
        .${prefixCls}-form-item {
          flex: 1;

          &--switch .${prefixCls}-form-item-row {
            flex-direction: row;
            align-items: center;
            gap: ${token.sizeSM}px;

            .${prefixCls}-form-item-control {
              order: 1;
              max-width: fit-content;
            }

            .${prefixCls}-form-item-label {
              flex: 1;
              order: 2;
              padding: 0;
            }
          }
        }
      }

      &.${prefixCls}-form-vertical
        .${prefixCls}-form-item-label,
        .${prefixCls}-col-24.${prefixCls}-form-item-label,
        .${prefixCls}-col-xl-24.${prefixCls}-form-item-label {
        padding: 0 0 2px;
      }

      .${prefixCls}-select-lg {
        &.ant-select-multiple {
          .${prefixCls}-select-selector {
            padding: 0 ${token.sizeXS}px;
          }
          .ant-select-selection-search {
            left: 0;
          }
        }
        .${prefixCls}-select-selector {
          padding: ${token.sizeXS}px ${token.size}px;
        }
        .ant-select-selection-search {
          left: ${token.size}px;
        }
      }

      .${prefixCls}-picker,
        .${prefixCls}-select-selector,
        .${prefixCls}-input-affix-wrapper,
        .${prefixCls}-input-number,
        .${prefixCls}-input-number-affix-wrapper,
        .${prefixCls}-input-number-group-wrapper {
        width: 100%;
      }

      .${prefixCls}-picker, .${prefixCls}-select-selector, .${prefixCls}-input-affix-wrapper {
        border-radius: ${token.borderRadiusRounded}px;
      }

      .Fieldset {
        transition: all 500ms ease-out;

        legend {
          transition: all 250ms ease-out;
          width: fit-content;
          margin-bottom: ${token.size}px;
          padding: 0 ${token.sizeSM}px;
          font-size: ${token.fontSizeXS}px;
          font-weight: ${token.fontWeightStrong};
          color: ${token.colorTextSecondary};
          border: 0;
        }

        .${prefixCls}-form-item--switch {
          margin: 0;
        }

        &--NoContent {
          border-color: rgba(0, 0, 0, 0);

          legend {
            padding: 0;
            margin: 0;
          }
        }

        &--Error {
          border-color: ${token.colorError};
        }
      }
    }
    .${prefixCls}-form-item-margin-offset {
      margin: 0 !important;
    }
  `,
}));
