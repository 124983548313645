import { Input, InputProps } from 'formik-antd';

interface MyProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}
export type TextInputProps = InputProps & MyProps;
const TextInput = ({ name, placeholder, size, setFieldValue }: TextInputProps) => {
  return (
    <Input
      name={name}
      placeholder={placeholder}
      size={size}
      onBlur={e => {
        if (setFieldValue) {
          const value = e.target.value || '';
          setFieldValue(name, value.trim());
        }
      }}
    ></Input>
  );
};
export default TextInput;
