import React, { useContext, useEffect, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Alert, App } from 'antd';

import { camelCase } from 'lodash';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';
import GalleryDeleteTable from 'Components/Molecules/Tables/GalleryDeleteTable';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import getErrorCode from 'Helpers/GetErrorCode';

import { GalleriesGetWhereType, GalleryField, GalleryOrderType, GalleryStatus } from 'Operations/__generated__/graphql';

import { GET_GALLERIES } from 'Operations/Queries/Gallery/GetGalleries';
import { GET_STORAGE } from 'Operations/Queries/Storage/GetStorage';

import { HARD_DELETE_GALLERY } from 'Operations/Mutations/Gallery/HardDeleteGallery';
import { PUT_BACK_GALLERY } from 'Operations/Mutations/Gallery/PutBackGallery';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  margin-top: ${Metrics.baseMargin}px;
`;

const AlertStyled = styled(Alert)`
  margin-bottom: ${Metrics.baseMargin}px;
`;

const PER_PAGE = 20;

const GalleriesTrash = () => {
  const { t } = useContext(LocalizationContext);
  const { modal, message } = App.useApp();
  const [paginationParams, setPaginationParams] = useState<GalleriesGetWhereType>({
    page: 1,
    perPage: PER_PAGE,
    field: GalleryField.UPDATEDAT,
    order: GalleryOrderType.ASC,
    delete: true,
  });

  const page = paginationParams.page || 1;

  const [getGalleries, { data, loading: isGalleriesLoading }] = useLazyQuery(GET_GALLERIES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const refetchQueries = [
    {
      query: GET_GALLERIES,
      variables: {
        where: paginationParams,
      },
    },
  ];

  const { data: storageData } = useQuery(GET_STORAGE, {
    fetchPolicy: 'cache-and-network',
  });

  const [putBackGallery] = useMutation(PUT_BACK_GALLERY, {
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: () => {
      if (data && data?.getPaginatedGalleries?._count % PER_PAGE === 0 && page > 1) {
        setPaginationParams({ ...paginationParams, page: page - 1 });
      }
    },
  });

  const [hardDeleteGallery] = useMutation(HARD_DELETE_GALLERY, {
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: () => {
      if (data && data?.getPaginatedGalleries?._count % PER_PAGE === 0 && page > 1) {
        setPaginationParams({ ...paginationParams, page: page - 1 });
      }
    },
  });

  useEffect(() => {
    getGalleries({
      variables: {
        where: paginationParams,
      },
    });
  }, [getGalleries, paginationParams]);

  if (!storageData || (storageData && !storageData?.getStorage)) {
    return null;
  }

  const { percent } = storageData.getStorage;

  const handleFilterTable = async (params: GalleriesGetWhereType) => {
    setPaginationParams({ ...paginationParams, ...params });
  };

  const handlePutBack = async ({ id, status }: { id: number; status: GalleryStatus }) => {
    if (percent < 100) {
      try {
        await putBackGallery({
          variables: {
            where: {
              id,
            },
            data: {
              status,
            },
          },
        });

        message.success(t('app.message.gallery.putBack.success'));
      } catch (error) {
        const errorCode = getErrorCode(error);
        console.log(camelCase(errorCode));
        message.error(t(`app.message.gallery.putBack.error.${camelCase(errorCode)}`));
      }
    } else {
      modal.confirm({
        title: t('app.account.spaceFull.title'),
        content: t('app.account.spaceFull.description'),
        okText: t('app.common.ok'),
      });
    }
  };

  const handleHardDeleteConfirm = async ({ id }: { id: number }) => {
    try {
      await hardDeleteGallery({
        variables: {
          where: {
            id,
          },
        },
      });

      message.success(t('app.message.gallery.hardDelete.success'));
    } catch (error) {
      const errorCode = getErrorCode(error);
      message.error(t(`app.message.gallery.hardDelete.error.${camelCase(errorCode)}`));
    }
  };

  return (
    <>
      <Header
        title={t('app.common.trash')}
        breadcrumbContent={[{ text: t('app.menu.home'), url: '/app/dashboard' }, { text: t('app.common.trash') }]}
      />
      <Layout>
        <StyledTableContentContainer>
          <AlertStyled message={t('app.gallery.trash.autoDeleteWarning')} type="warning" />
          <GalleryDeleteTable
            galleriesTotal={data ? data.getPaginatedGalleries._count : 0}
            data={data?.getPaginatedGalleries.edges}
            loading={isGalleriesLoading}
            paginationCurrent={page}
            paginationSize={paginationParams.perPage || PER_PAGE}
            handleOnChange={handleFilterTable}
            handlePutBack={handlePutBack}
            handleHardDeleteConfirm={handleHardDeleteConfirm}
          />
        </StyledTableContentContainer>
      </Layout>
    </>
  );
};

export default GalleriesTrash;
