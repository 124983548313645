import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';

import { DeepPartial } from '@apollo/client/utilities';
import { Button, Flex, Image } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';

import FileSelect from 'Components/Molecules/Form/FileSelect';

import { SaveButtonConfig } from 'Pages/App/Products/ProductsShow/SaveButtonType';

import i18n, { LocalizationContext } from 'i18n';

import { useCurrency } from 'Hooks/useCurrency';

import yup from 'Services/YupService';

import { Product } from 'Operations/__generated__/graphql';

export interface NewOptionFormValues {
  previewImage?: Blob;
  previewImageUrl?: string;
  name: string;
  categoryId: number;
  providerPrice?: number;
  providerDescription?: string;
  isMainOption: boolean;
}

export interface EditOptionFormValues extends NewOptionFormValues {
  id: number;
}

interface OptionFormProps {
  isReadOnly?: boolean;
  product: DeepPartial<Product>;
  updateSubmitButton?: (config: SaveButtonConfig | null) => void;
}

const OptionForm = <T extends EditOptionFormValues | NewOptionFormValues>({
  isSubmitting,
  values,
  dirty,
  isReadOnly,
  updateSubmitButton,
  handleSubmit,
  isValid,
}: FormikProps<T> & OptionFormProps) => {
  const { t } = useContext(LocalizationContext);
  const { symbol } = useCurrency();

  const [image, setImage] = useState<string | undefined>(undefined);

  const optionId = useMemo(() => (values as EditOptionFormValues).id, [values]);

  useEffect(() => {
    if (values.previewImageUrl && optionId) {
      setImage(values.previewImageUrl);
    } else {
      setImage(undefined);
    }
  }, [values.previewImageUrl, optionId]);

  useEffect(() => {
    if (updateSubmitButton) {
      updateSubmitButton({
        onClick: handleSubmit,
        dirty,
        isValid,
        isSubmitting,
      });
    }
  }, [dirty, handleSubmit, isSubmitting, isValid, t, updateSubmitButton]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setImage(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <Form layout="vertical">
      <Flex align="center" gap="middle">
        <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
          <Input name="name" placeholder={t('app.common.name')} size="large" autoFocus disabled={isReadOnly} />
        </Form.Item>
        {values.isMainOption && (
          <Form.Item label={t('app.products.providerPrice')} name="providerPrice" hasFeedback={false}>
            <Input
              name="providerPrice"
              type="number"
              placeholder={t('app.products.providerPrice')}
              size="large"
              disabled={isReadOnly}
              addonAfter={symbol}
            />
          </Form.Item>
        )}
      </Flex>
      {!isReadOnly && (
        <Flex gap="middle">
          <Flex flex={1}>
            <Form.Item
              name="previewImage"
              label={`${t('app.common.optionImage')} (${t('app.common.maxResolution', {
                resolution: '1620x1080',
              })} - ${t('app.common.ratio', { ratio: '3/2' })})`}
              hasFeedback={false}
            >
              <FileSelect
                name="previewImage"
                accept="image/png, image/jpeg, image/svg+xml"
                handleOnChange={handleFileChange}
              />
            </Form.Item>
          </Flex>
          {image && (
            <Flex flex={1}>
              <Image src={image} />
            </Flex>
          )}
        </Flex>
      )}
      {!isReadOnly && !updateSubmitButton && (
        <Flex justify="center">
          <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
            {t((values as EditOptionFormValues).id ? 'app.common.edit' : 'app.common.add')}
          </Button>
        </Flex>
      )}
    </Form>
  );
};

export interface NewOptionFormPayload {
  values: NewOptionFormValues;
  formikBag: FormikBag<NewOptionFormProps, NewOptionFormValues>;
}

export interface NewOptionFormProps extends OptionFormProps {
  onSubmit: (payload: NewOptionFormPayload) => void;
  defaultValues: NewOptionFormValues;
}
export interface EditOptionFormPayload {
  values: EditOptionFormValues;
  formikBag: FormikBag<EditOptionFormProps, EditOptionFormValues>;
}

export interface EditOptionFormProps extends OptionFormProps {
  onSubmit: (payload: EditOptionFormPayload) => void;
  defaultValues: EditOptionFormValues;
}

const OptionSchema = yup.object({
  name: yup.string().trim().required(),
  previewImage: yup.mixed().test(
    'previewImage',
    () => i18n.t('app.form.errors.fileToLarge', { limit: '5MB' }),
    value => {
      return value ? value.size <= 5000000 : true;
    },
  ),
  previewImageUrl: yup.string(),
  categoryId: yup.number().required(),
  providerPrice: yup.number(),
  providerDescription: yup.string(),
  isMainOption: yup.boolean().required(),
});

const NewOptionSchema: yup.SchemaOf<NewOptionFormValues> = OptionSchema.defined();
const EditOptionSchema: yup.SchemaOf<EditOptionFormValues> = OptionSchema.shape({
  id: yup.number().required(),
}).defined();

export const NewOptionForm = withFormik<NewOptionFormProps, NewOptionFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    ...defaultValues,
    previewImage: undefined,
  }),
  validationSchema: NewOptionSchema,
  enableReinitialize: true,
})(OptionForm);

export const EditOptionForm = withFormik<EditOptionFormProps, EditOptionFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    ...defaultValues,
    previewImage: undefined,
  }),
  validationSchema: EditOptionSchema,
  enableReinitialize: true,
})(OptionForm);
