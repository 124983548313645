import React, { useCallback, useContext, useMemo } from 'react';

import { Comment } from '@ant-design/compatible';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Divider, Form, List, Popconfirm, Select } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { Formik } from 'formik';

import Container from 'Components/Atoms/Container';
import Layout from 'Components/Atoms/Layout';
import StatusTag from 'Components/Atoms/StatusTag';
import Text from 'Components/Atoms/Text';

import FeatureLikeButton from 'Components/Molecules/FeatureLikeButton';
import Editor from 'Components/Molecules/Form/Editor';
import Header from 'Components/Molecules/Header';
import StatCard from 'Components/Molecules/Stats/StatCard';

import { OnSubmitFeatureRequestPayload } from 'Forms/FeatureRequest';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useFeatureRequestCategoriesMenu } from 'Hooks/useFeatureRequestCategoriesMenu';

import dayjs from 'Services/DayjsService';

import { FeatureStatus } from 'Operations/__generated__/graphql';

import { GET_FEATURE_REQUEST } from 'Operations/Queries/FeatureRequest/GetFeatureRequest';

import { CREATE_FEATURE_COMMENT } from 'Operations/Mutations/FeatureRequest/CreateFeatureComment';
import { DELETE_FEATURE_REQUEST } from 'Operations/Mutations/FeatureRequest/DeleteFeatureRequest';
import { UPDATE_FEATURE_REQUEST } from 'Operations/Mutations/FeatureRequest/UpdateFeatureRequest';
import { UPDATE_FEATURE_STATUS } from 'Operations/Mutations/FeatureRequest/UpdateFeatureStatus';

const statuses = [
  FeatureStatus.OPEN,
  FeatureStatus.PLANNED,
  FeatureStatus.IN_PROGRESS,
  FeatureStatus.LIVE,
  FeatureStatus.CLOSED,
];

const StyledContainer = styled(Container)`
  margin-top: ${Metrics.baseMargin}px;
  margin-bottom: ${Metrics.baseMargin}px;
  gap: ${Metrics.baseMargin}px;
`;

const statCardProps: {
  minWidth: number;
  shadow: boolean;
  width: 'auto';
} = {
  minWidth: 90,
  shadow: true,
  width: 'auto',
};

const HTMLContent = ({ content }: { content: string }) => (
  <ReactMarkdown
    rehypePlugins={[rehypeRaw]}
    children={content}
    components={{
      p: ({ children }) => <Text size="medium">{children}</Text>,
      b: ({ children }) => (
        <Text size="medium" weight="bold">
          {children}
        </Text>
      ),
      a: ({ children, ...props }) =>
        props.href !== '#' && props.href ? (
          <Link to={props.href}>{children}</Link>
        ) : (
          <Text style={{ display: 'inline' }} size="medium" weight="bold">
            {children}
          </Text>
        ),
    }}
  />
);

const commentsWhere = {
  page: 1,
  perPage: 5,
};

const FeatureRequestsShow = () => {
  const { id } = useParams<{ id: string }>();
  const featureId = id ? parseInt(id, 10) : undefined;
  const { t } = useContext(LocalizationContext);
  const { openModal, closeModal } = useModals();
  useFeatureRequestCategoriesMenu();
  const navigate = useNavigate();

  const { data, fetchMore } = useQuery(GET_FEATURE_REQUEST, {
    skip: !featureId,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        id: featureId as number,
      },
      commentsWhere: commentsWhere,
    },
  });

  const [updateFeatureRequest] = useMutation(UPDATE_FEATURE_REQUEST);

  const [updateFeatureStatus] = useMutation(UPDATE_FEATURE_STATUS);
  const [deleteFeatureRequest] = useMutation(DELETE_FEATURE_REQUEST);

  const [createFeatureComment] = useMutation(CREATE_FEATURE_COMMENT);

  const featureRequest = data?.getFeatureRequest;

  const handleSubmit = useCallback(
    async ({ values, formikBag }: OnSubmitFeatureRequestPayload) => {
      if (featureId && values.categoryId) {
        try {
          await updateFeatureRequest({
            variables: {
              where: {
                id: featureId,
              },
              data: {
                title: values.title,
                description: values.description,
                categoryId: values.categoryId,
              },
            },
          });

          formikBag.setSubmitting(false);
          closeModal('FEATURE_REQUEST');
        } catch (error) {
          formikBag.setSubmitting(false);
        }
      }
    },
    [closeModal, id, updateFeatureRequest],
  );

  const handleChangeStatus = useCallback(
    async (status: FeatureStatus) => {
      try {
        if (featureId) {
          await updateFeatureStatus({
            variables: {
              where: {
                id: featureId,
              },
              data: {
                status,
              },
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [id, updateFeatureStatus],
  );

  const handleDelete = useCallback(async () => {
    try {
      if (featureId) {
        await deleteFeatureRequest({
          variables: {
            where: {
              id: featureId,
            },
          },
        });
        navigate('/app/feature-requests');
      }
    } catch (error) {
      console.error(error);
    }
  }, [deleteFeatureRequest, id, navigate]);

  const buttons = useMemo(() => {
    const actions: JSX.Element[] = [];

    if (!featureRequest) {
      return actions;
    }

    if (featureRequest.isAdmin) {
      actions.push(
        <Select
          key="filter-status"
          defaultValue={featureRequest?.status || FeatureStatus.OPEN}
          onChange={handleChangeStatus}
          size="large"
        >
          {statuses.map(status => (
            <Select.Option key={status} value={status}>
              <StatusTag status={status} />
            </Select.Option>
          ))}
        </Select>,
        <Popconfirm key="delete" title={t('app.confirm.delete')} onConfirm={handleDelete}>
          <Button size="large" type="default">
            {t('app.common.delete')}
          </Button>
        </Popconfirm>,
      );
    }

    if ((featureRequest.isAuthor && featureRequest.status === FeatureStatus.OPEN) || featureRequest.isAdmin) {
      actions.push(
        <Button
          key="edit"
          type="primary"
          size="large"
          onClick={() =>
            featureRequest.category &&
            openModal('FEATURE_REQUEST', {
              defaultValues: {
                title: featureRequest.title,
                description: featureRequest.description,
                categoryId: featureRequest.category.id,
              },
              onSubmit: handleSubmit,
            })
          }
        >
          {t('app.common.edit')}
        </Button>,
      );
    }

    return actions;
  }, [featureRequest, handleChangeStatus, t, handleDelete, openModal, handleSubmit]);

  return (
    <>
      <Header
        title={featureRequest ? featureRequest.title : '-'}
        extra={featureRequest && <FeatureLikeButton id={featureRequest.id} hasVoted={featureRequest.hasVoted} />}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.featureRequest.list'), url: '/app/feature-requests' },
          { text: featureRequest?.title || '-' },
        ]}
        buttons={buttons}
      />
      <Layout flexDirection="column">
        {featureRequest && (
          <StyledContainer>
            <Container flex={1} direction="column">
              <HTMLContent content={featureRequest.description} />
            </Container>
            <StatCard {...statCardProps} label={t('app.featureRequest.vote')} value={featureRequest.votesCount || 0} />
            <StatCard
              {...statCardProps}
              label={t('app.featureRequest.category')}
              value={featureRequest.category?.name || '-'}
            />
            <StatCard
              {...statCardProps}
              label={t('app.featureRequest.status.label')}
              value={<StatusTag status={featureRequest.status ?? undefined} />}
            />
          </StyledContainer>
        )}
        <Divider />
        <Formik
          initialValues={{
            comment: '',
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              if (featureId) {
                await createFeatureComment({
                  variables: {
                    where: {
                      id: featureId,
                    },
                    data: {
                      comment: values.comment,
                    },
                  },
                  update(cache, { data }) {
                    if (!data || !featureId) {
                      return;
                    }
                    const { createFeatureComment } = data;

                    const result = cache.readQuery({
                      query: GET_FEATURE_REQUEST,
                      variables: {
                        where: {
                          id: featureId,
                        },
                      },
                    });

                    if (!result) {
                      return;
                    }

                    const { getFeatureRequest } = result;

                    cache.writeQuery({
                      query: GET_FEATURE_REQUEST,
                      variables: {
                        where: {
                          id: featureId,
                        },
                      },
                      data: {
                        getFeatureRequest: {
                          ...getFeatureRequest,
                          comments: {
                            ...getFeatureRequest.comments,
                            _count: getFeatureRequest.comments._count + 1,
                            edges: [createFeatureComment, ...getFeatureRequest.comments.edges],
                          },
                        },
                      },
                    });
                  },
                });
              }
              setSubmitting(false);
              resetForm();
            } catch (error) {
              setSubmitting(false);
            }
          }}
        >
          {({ values, isSubmitting, handleSubmit }) => (
            <Form onSubmitCapture={handleSubmit}>
              <Form.Item>
                <Editor name="comment" heightMin="100" disabledFiles />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={isSubmitting || values.comment.length === 0}>
                  {t('app.common.send')}
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
        <List
          header={t('app.featureRequest.comments', { count: featureRequest?.comments._count || 0 })}
          itemLayout="horizontal"
          dataSource={featureRequest?.comments.edges || []}
          renderItem={({ userName, comment, createdAt }) => (
            <Comment
              author={userName}
              content={<HTMLContent content={comment} />}
              datetime={dayjs(createdAt).format('LLLL')}
            />
          )}
          pagination={{
            total: featureRequest?.comments._count || 0,
            pageSize: commentsWhere.perPage,
            showSizeChanger: false,
            style: {
              textAlign: 'center',
            },
            onChange: page => {
              if (featureId) {
                fetchMore({
                  variables: {
                    where: {
                      id: featureId,
                    },
                    commentsWhere: {
                      ...commentsWhere,
                      page,
                    },
                  },
                });
              }
            },
          }}
        />
      </Layout>
    </>
  );
};

export default FeatureRequestsShow;
