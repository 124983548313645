import { gql } from 'Operations/__generated__';

export const GET_PRODUCT_CATEGORY = gql(/* GraphQL */ `
  query GetProductCategory($where: IdInput!) {
    getProductCategory(where: $where) {
      id
      name
      isDigital
      isEditable
      isDeletable
      createdAt
    }
  }
`);
