import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useMutation, useSubscription } from '@apollo/client';
import { Button, Popconfirm, Progress } from 'antd';

import Container from 'Components/Atoms/Container';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { Images, Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import { JobType } from 'Operations/__generated__/graphql';

import { UPDATE_WAITING_ORDER_STATUS } from 'Operations/Mutations/Order/UpdateWaitingOrderStatus';
import { ZIP_LABO_ORDERS } from 'Operations/Mutations/Order/ZipLaboOrders';

import { ON_JOB_UPDATED } from 'Operations/Subscriptions/OnJobUpdated';
import { ON_ZIP_ORDERS_COMPLETED } from 'Operations/Subscriptions/OnZipOrdersCompleted';

const DownloadLaboPhotosModal = () => {
  const { t } = useContext(LocalizationContext);
  const [progress, setProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState<null | string>(null);
  const { closeModal } = useModals();

  const [zipOrders] = useMutation(ZIP_LABO_ORDERS);
  const [updateWaitingOrderStatus] = useMutation(UPDATE_WAITING_ORDER_STATUS);

  useSubscription(ON_ZIP_ORDERS_COMPLETED, {
    shouldResubscribe: true,
    onData({ data }) {
      if (data.data?.OnZipOrdersCompleted) {
        setDownloadUrl(data.data.OnZipOrdersCompleted);
        setProgress(100);
      }
    },
  });
  const { data } = useSubscription(ON_JOB_UPDATED, {
    shouldResubscribe: true,
    onData({ data }) {
      const job = data.data?.OnJobUpdated;
      if (job && job.jobType === JobType.ORDERS_ZIP && job.percent && job.percent < 100) {
        setProgress(job.percent);
      }
    },
  });

  const handleUpdateStatus = useCallback(() => {
    if (data?.OnJobUpdated.id) {
      updateWaitingOrderStatus({
        variables: {
          where: { id: data.OnJobUpdated.id },
        },
      });

      closeModal('DOWNLOAD_LABO_PHOTOS');
    }
  }, [closeModal, data?.OnJobUpdated.id, updateWaitingOrderStatus]);

  useEffect(() => {
    zipOrders();
  }, [zipOrders]);

  return (
    <ModalContainer
      title={t(`app.modal.downloadLaboPhotos.title`)}
      icon={Images.download}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <Container direction="column" gap={Metrics.baseMargin} align="center" justify="center" style={{ height: '100%' }}>
        <Progress type="circle" percent={progress} />
        <Popconfirm
          title={t('app.modal.downloadLaboPhotos.willNotChangeStatus')}
          disabled={!downloadUrl}
          okButtonProps={{
            href: downloadUrl || '',
            target: '_blank',
            onClick() {
              closeModal('DOWNLOAD_LABO_PHOTOS');
            },
          }}
        >
          <Button loading={!downloadUrl} disabled={!downloadUrl} size="large">
            {t(downloadUrl ? 'app.order.downloadPhotos' : 'app.modal.downloadPhotos.zipCreationInProgress')}
          </Button>
        </Popconfirm>
        {downloadUrl && (
          <Button
            loading={!downloadUrl}
            href={downloadUrl || ''}
            target="_blank"
            type="primary"
            disabled={!downloadUrl}
            onClick={handleUpdateStatus}
          >
            {t('app.modal.downloadLaboPhotos.downloadPhotosStatus')}
          </Button>
        )}
      </Container>
    </ModalContainer>
  );
};

export default DownloadLaboPhotosModal;
