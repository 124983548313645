import React, { useCallback, useContext, useMemo, useState } from 'react';

import { ApolloCache, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Flex } from 'antd';
import { ReplaceWithReference } from 'types/ModelConnection';

import Icon from 'Components/Atoms/Icon';

import PhotosDrawer from 'Components/Molecules/Drawers/PhotosDrawer';

import { LocalizationContext } from 'i18n';

import { Picklist } from 'Operations/__generated__/graphql';

import { GET_UNPICKED_PHOTOS } from 'Operations/Queries/Photo/GetUnpickedPhotos';
import { GET_PICKLIST } from 'Operations/Queries/Picklist/GetPicklist';

import { ADD_PHOTOS_TO_PICKLIST } from 'Operations/Mutations/Picklist/addPhotosToPicklist';
import { REMOVE_PHOTOS_FROM_PICKLIST } from 'Operations/Mutations/Picklist/removePhotosFromPicklist';

interface Props {
  picklistId?: number;
  galleryId: number;
  onClose: () => void;
}

const perPage = 50;

const PicklistDrawer = ({ picklistId, galleryId, onClose }: Props) => {
  const { t } = useContext(LocalizationContext);

  const [page, setPage] = useState(1);
  const [addPage, setAddPage] = useState(1);
  const [selectedPhotoIds, setSelectedPhotoIds] = useState<number[]>([]);
  const [selectedPhotosIdsToAdd, setSelectedPhotosIdsToAdd] = useState<number[]>([]);
  const [isPhotosDrawerOpen, setIsPhotosDrawerOpen] = useState(false);

  const [removePhotosFromPicklist] = useMutation(REMOVE_PHOTOS_FROM_PICKLIST);
  const [addPhotosToPicklist] = useMutation(ADD_PHOTOS_TO_PICKLIST);

  const { data } = useQuery(GET_PICKLIST, {
    fetchPolicy: 'cache-and-network',
    skip: !picklistId,
    variables: {
      where: {
        id: picklistId as number,
      },
      wherePhotos: {
        page,
        perPage,
      },
    },
  });

  const [getUnpickedPhotos, { data: photosData, fetchMore }] = useLazyQuery(GET_UNPICKED_PHOTOS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        galleryId,
        picklistId: picklistId as number,
      },
      paginate: {
        page: 1,
        perPage,
      },
    },
  });

  const updateCache = useCallback(
    ({ cache, photoIds, add = false }: { cache: ApolloCache<any>; photoIds: number[]; add?: boolean }) => {
      cache.modify<ReplaceWithReference<Picklist, 'photos'>>({
        id: `Picklist:${picklistId}`,
        fields: {
          photos(existing) {
            if ('_count' in existing) {
              return {
                ...(existing || {}),
                edges: add
                  ? [...(existing?.edges || []), ...photoIds.map(photoId => ({ __ref: `PhotoAdmin:${photoId}` }))]
                  : existing?.edges?.filter(({ __ref }) => !photoIds.includes(parseInt(__ref.split(':')[1], 10))),
                _count: (existing?._count || 0) + (add ? photoIds.length : -photoIds.length),
              };
            }
            return existing;
          },
        },
      });

      setSelectedPhotoIds([]);
      setSelectedPhotosIdsToAdd([]);
    },
    [picklistId],
  );

  const handleAddPhotosToPicklist = useCallback(() => {
    setIsPhotosDrawerOpen(false);
    if (picklistId && selectedPhotosIdsToAdd.length > 0) {
      addPhotosToPicklist({
        variables: {
          where: { id: picklistId },
          data: { ids: selectedPhotosIdsToAdd },
        },
        update: cache => updateCache({ cache, photoIds: selectedPhotosIdsToAdd, add: true }),
      });
    }
  }, [addPhotosToPicklist, picklistId, selectedPhotosIdsToAdd, setIsPhotosDrawerOpen, updateCache]);

  const handleRemovePhotosFromPicklist = useCallback(() => {
    if (picklistId) {
      removePhotosFromPicklist({
        variables: {
          where: { id: picklistId },
          data: { ids: selectedPhotoIds },
        },
        update: cache => updateCache({ cache, photoIds: selectedPhotoIds }),
      });
    }
  }, [removePhotosFromPicklist, selectedPhotoIds, picklistId, updateCache]);

  const handleOnSelectPhoto = useCallback(
    ({ photoId, prevState }: { prevState: number[]; photoId: number }) =>
      prevState.includes(photoId) ? prevState.filter(id => id !== photoId) : [...prevState, photoId],
    [],
  );

  const handleOpenPhotosDrawer = useCallback(async () => {
    await getUnpickedPhotos();
    setIsPhotosDrawerOpen(true);
  }, [getUnpickedPhotos]);

  const photos = useMemo(
    () => data?.getPicklist.photos,
    [data?.getPicklist.photos._count, data?.getPicklist.photos.edges],
  );
  const photoToAdd = useMemo(
    () => photosData?.getUnpickedPhotos,
    [photosData?.getUnpickedPhotos._count, photosData?.getUnpickedPhotos.edges],
  );

  return (
    <PhotosDrawer
      title={data?.getPicklist.name}
      open={!!picklistId}
      onClose={onClose}
      width={675}
      footer={
        <Flex justify="space-between">
          <Button
            icon={<Icon name="minus" size={12} />}
            danger
            disabled={selectedPhotoIds.length === 0}
            onClick={handleRemovePhotosFromPicklist}
          >
            {t('app.common.delete')}
          </Button>
          <Button icon={<Icon name="plus" size={12} />} type="primary" onClick={handleOpenPhotosDrawer}>
            {t('app.common.addPhotos')}
          </Button>
        </Flex>
      }
      photos={photos}
      selectedPhotoIds={selectedPhotoIds}
      onSelect={photoId => setSelectedPhotoIds(prevState => handleOnSelectPhoto({ prevState, photoId }))}
      fetchMore={() => setPage(page + 1)}
    >
      {photoToAdd && (
        <PhotosDrawer
          title={t('app.common.selectionbar.selectCount', { count: selectedPhotoIds.length })}
          extra={<Button onClick={handleAddPhotosToPicklist}>{t('app.common.validate')}</Button>}
          onClose={() => setIsPhotosDrawerOpen(false)}
          width={525}
          open={isPhotosDrawerOpen}
          photos={photoToAdd}
          selectedPhotoIds={selectedPhotosIdsToAdd}
          onSelect={photoId => setSelectedPhotosIdsToAdd(prevState => handleOnSelectPhoto({ prevState, photoId }))}
          fetchMore={() =>
            fetchMore({
              variables: {
                paginate: { page: photoToAdd.pageInfo.currentPage + 1, perPage },
              },
            })
          }
        />
      )}
    </PhotosDrawer>
  );
};

export default PicklistDrawer;
