import { useEffect, useState } from 'react';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

// Define general type for useWindowSize hook, which includes width and height
interface Size {
  width: number | undefined;
  height: number | undefined;
  breakpoint: Breakpoint | undefined;
}

// Hook
export function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
    breakpoint: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      let breakpoint: Breakpoint = 'xs';

      // Set breakpoint based on window width
      switch (true) {
        case window.innerWidth <= 600:
          breakpoint = 'xs';
          break;
        case window.innerWidth <= 960:
          breakpoint = 'sm';
          break;
        case window.innerWidth <= 1280:
          breakpoint = 'md';
          break;
        case window.innerWidth <= 1980:
          breakpoint = 'lg';
          break;
        default:
          breakpoint = 'xl';
          break;
      }

      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        breakpoint,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
