import React, { useContext, useEffect } from 'react';

import { DeepPartial } from '@apollo/client/utilities';
import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';

import { SaveButtonConfig } from 'Pages/App/Products/ProductsShow/SaveButtonType';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

import { Product } from 'Operations/__generated__/graphql';

export interface NewOptionCategoryFormValues {
  name: string;
  isPreconfiguredProduct: boolean;
}
export interface EditOptionCategoryFormValues extends NewOptionCategoryFormValues {
  id: number;
}

export interface OptionCategoryFormProps {
  isReadOnly?: boolean;
  product: DeepPartial<Product>;
  updateSubmitButton?: (config: SaveButtonConfig | null) => void;
}

const OptionCategory = <T extends NewOptionCategoryFormValues | EditOptionCategoryFormValues>({
  isSubmitting,
  isReadOnly,
  values,
  dirty,
  updateSubmitButton,
  handleSubmit,
  isValid,
}: FormikProps<T> & OptionCategoryFormProps) => {
  const { t } = useContext(LocalizationContext);

  useEffect(() => {
    if (updateSubmitButton) {
      updateSubmitButton({
        onClick: handleSubmit,
        dirty,
        isValid,
        isSubmitting,
      });
    }
  }, [dirty, handleSubmit, isSubmitting, isValid, t, updateSubmitButton]);

  return (
    <Form layout="vertical">
      <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
        <Input name="name" size="large" autoFocus disabled={isReadOnly} />
      </Form.Item>

      {!isReadOnly && !updateSubmitButton && (
        <Flex justify="center" align="center">
          <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
            {t((values as EditOptionCategoryFormValues).id ? 'app.common.edit' : 'app.common.add')}
          </Button>
        </Flex>
      )}
    </Form>
  );
};

export interface NewOptionCategoryFormPayload {
  values: NewOptionCategoryFormValues;
  formikBag: FormikBag<NewOptionCategoryFormProps, NewOptionCategoryFormValues>;
}

export interface NewOptionCategoryFormProps extends OptionCategoryFormProps {
  onSubmit: (payload: NewOptionCategoryFormPayload) => void;
  defaultValues: NewOptionCategoryFormValues;
}
export interface EditOptionCategoryFormPayload {
  values: EditOptionCategoryFormValues;
  formikBag: FormikBag<EditOptionCategoryFormProps, EditOptionCategoryFormValues>;
}

export interface EditOptionCategoryFormProps extends OptionCategoryFormProps {
  onSubmit: (payload: EditOptionCategoryFormPayload) => void;
  defaultValues: EditOptionCategoryFormValues;
}

const OptionCategorySchema = yup.object({
  name: yup.string().required(),
  isPreconfiguredProduct: yup.boolean().required(),
});

const NewOptionCategorySchema: yup.SchemaOf<NewOptionCategoryFormValues> = OptionCategorySchema.defined();
const EditOptionCategorySchema: yup.SchemaOf<EditOptionCategoryFormValues> = OptionCategorySchema.shape({
  id: yup.number().required(),
}).defined();

export const NewOptionCategoryForm = withFormik<NewOptionCategoryFormProps, NewOptionCategoryFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: NewOptionCategorySchema,
})(OptionCategory);

export const EditOptionCategoryForm = withFormik<EditOptionCategoryFormProps, EditOptionCategoryFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: EditOptionCategorySchema,
  enableReinitialize: true,
})(OptionCategory);
