import React, { useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Tabs } from 'antd';

import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';

import MollieTab from 'Pages/App/Settings/Payments/Tabs/Mollie';
import OtherTab from 'Pages/App/Settings/Payments/Tabs/Other';
import RedysTab from 'Pages/App/Settings/Payments/Tabs/Redsys';
import StripeTab from 'Pages/App/Settings/Payments/Tabs/Stripe';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { ME } from 'Operations/Queries/User/Me';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  flex: 1;
  margin-top: ${Metrics.smallMargin}px;
`;

const StyledTabPane = styled(TabPane)`
  padding: ${Metrics.smallMargin}px ${Metrics.baseMargin}px;
`;

enum PaymentTabs {
  Mollie = '1',
  Stripe = '2',
  Redsys = '3',
  Other = '4',
}

const Payments = () => {
  const { t } = useContext(LocalizationContext);
  const [activeKey, setActiveKey] = useState<PaymentTabs>(PaymentTabs.Mollie);

  const { data: currentUser } = useQuery(ME, {
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const stripeCode = urlParams.get('code');
    const stripeScope = urlParams.get('scope');

    if (stripeCode && stripeScope) {
      setActiveKey(PaymentTabs.Stripe);
    }
  }, []);

  return (
    <>
      <Header
        title={t('app.settings.payments.title')}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.settings'), url: '/app/settings/my-activity' },
          { text: t('app.settings.payments.title') },
        ]}
      />
      <Layout>
        <StyledTabs
          defaultActiveKey={PaymentTabs.Mollie}
          activeKey={activeKey}
          onChange={key => setActiveKey(key as PaymentTabs)}
        >
          <StyledTabPane tab={t('app.settings.payments.mollie.name')} key={PaymentTabs.Mollie}>
            <MollieTab />
          </StyledTabPane>
          <StyledTabPane tab={t('app.settings.payments.stripe.name')} key={PaymentTabs.Stripe}>
            <StripeTab />
          </StyledTabPane>
          {currentUser?.me.locale === 'es' && (
            <StyledTabPane tab={'Redsys'} key={PaymentTabs.Redsys}>
              <RedysTab />
            </StyledTabPane>
          )}
          <StyledTabPane tab={t('app.settings.payments.other.name')} key={PaymentTabs.Other}>
            <OtherTab />
          </StyledTabPane>
        </StyledTabs>
      </Layout>
    </>
  );
};

export default Payments;
