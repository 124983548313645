import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, App, Button, Input, Radio, RadioChangeEvent, Switch } from 'antd';
import { useParams } from 'react-router-dom';
import { IdProps } from 'types/Common';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { camelCase, groupBy, map, mapValues } from 'lodash';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import ZoneCountriesDrawer, { OnEditCountriesProps } from 'Components/Molecules/Drawers/ZoneCountriesDrawer';
import Header from 'Components/Molecules/Header';
import TransportFeeTable, {
  DeliveryWeightRange,
  HandleEditCountriesProps,
  TransportFeeTableData,
} from 'Components/Molecules/Tables/TransportFeeTable';

import TransportFeeSkeleton from 'Pages/App/Skeletons/TransportFeeSkeleton';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useContact } from 'Hooks/useContact';
import { useCurrentUser } from 'Hooks/useCurrentUser';
import { useDebounce } from 'Hooks/useDebounce';
import { useZones } from 'Hooks/useZone';

import { Carrier } from 'Operations/__generated__/graphql';

import { GET_CONTACT_ZONES } from 'Operations/Queries/Contact/GetContactZones';
import { ME } from 'Operations/Queries/User/Me';

import { UPDATE_CONTACT } from 'Operations/Mutations/Contact/UpdateContact';
import { CREATE_ZONE } from 'Operations/Mutations/Zone/CreateZone';
import { DELETE_ZONE } from 'Operations/Mutations/Zone/DeleteZone';
import { UPDATE_DELIVERY_ZONE } from 'Operations/Mutations/Zone/UpdateDeliveryZone';
import { UPDATE_ZONE } from 'Operations/Mutations/Zone/UpdateZone';

const RadioGroup = styled(Radio.Group)`
  width: 100%;
`;

const ContentContainerStyled = styled(ContentContainer)`
  width: 100%;
  padding: ${Metrics.baseMargin}px;
`;

const TableContainerStyled = styled(ContentContainer)`
  width: 100%;
`;

const ContainerStyled = styled(Container)`
  flex: 1;
  margin-top: ${Metrics.baseMargin}px;
`;

const ConfigContainer = styled(Container)`
  flex: 1;
`;

const VatContainer = styled(Container)`
  margin-top: ${Metrics.baseMargin}px;
`;

const AddZoneButton = styled(Button)`
  align-self: center;
  margin: ${Metrics.baseMargin}px 0;
`;

const TransportFeeTableContainer = styled(Container)`
  margin-top: ${Metrics.baseMargin}px;
`;

const LaPosteAlert = styled(Alert)`
  margin-top: ${Metrics.baseMargin}px;
`;

const ContactTransportFees = () => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();

  const { id } = useParams<{ id: string }>();
  const contactId = id ? parseInt(id, 10) : undefined;

  const { currentUser, isLoading: isCurrentUserLoading } = useCurrentUser();
  const { contact, isLoading: isContactLoading } = useContact({ id: contactId });
  const { zones: contactZones, isLoading: isZonesLoading } = useZones({ contactId });

  const [isZoneCountriesVisible, setIsZoneCountriesVisible] = useState<boolean>(false);
  const [isVatIncl, setIsVatIncl] = useState<boolean>(true);
  const [currentZoneId, setCurrentZoneId] = useState<number>(0);
  const [currentZoneName, setCurrentZoneName] = useState<string>('');
  const [zoneDefaultCountryIds, setZoneDefaultCountryIds] = useState<number[]>([]);
  const [transportFeeValue, setTransportFeeValue] = useState<number | null>(null);
  const debouncedTransportFee = useDebounce<number | null>(transportFeeValue, 100);

  const carrierZones = useMemo(() => {
    return contactZones ? groupBy(contactZones?.zones, 'carrier') : {};
  }, [contactZones]);

  const { data: meData } = useQuery(ME);

  const [updateContact] = useMutation(UPDATE_CONTACT);

  const [updateDeliveryZone] = useMutation(UPDATE_DELIVERY_ZONE);

  const [createZone, { loading: isCreateZoneLoading }] = useMutation(CREATE_ZONE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CONTACT_ZONES,
        variables: {
          where: {
            id: contactId,
          },
        },
      },
    ],
  });

  const [updateZone] = useMutation(UPDATE_ZONE);

  const [deleteZone, { loading: isDeleteZoneLoading }] = useMutation(DELETE_ZONE);

  useEffect(() => {
    if (transportFeeValue === null && !isContactLoading && contact) {
      setTransportFeeValue(contact.transportFee || 0);
    }
  }, [contact, isContactLoading, transportFeeValue]);

  useEffect(() => {
    if (contact && updateContact && debouncedTransportFee !== contact.transportFee && debouncedTransportFee !== null) {
      const updateContactData = async () => {
        const {
          id,
          contactType,
          displayName: _displayName,
          createdAt: _createdAt,
          photoUrl: _photoUrl,
          __typename,
          ...contactData
        } = contact;
        const data = {
          ...contactData,
          contactTypeId: contactType.id,
          transportFee: debouncedTransportFee,
        };

        await updateContact({
          variables: {
            where: {
              id,
            },
            data,
          },
        });

        message.success(t('app.message.transportFee.update.success'));
      };

      updateContactData();
    }
  }, [contact, debouncedTransportFee, t, updateContact]);

  const isProvider = useMemo(
    () => contact?.contactType.id === currentUser?.me.providerTypeId,
    [contact?.contactType.id, currentUser?.me.providerTypeId],
  );
  const isLoading = useMemo(() => isCurrentUserLoading || isContactLoading, [isContactLoading, isCurrentUserLoading]);
  const isTableDataLoading = useMemo(
    () => isZonesLoading || isCreateZoneLoading || isDeleteZoneLoading,
    [isCreateZoneLoading, isDeleteZoneLoading, isZonesLoading],
  );
  const contactDisplayName = useMemo(
    () => (contact?.displayName ? contact.displayName : 'Chargement'),
    [contact?.displayName],
  );

  const carrierZonesData: { [key: string]: TransportFeeTableData[] } = useMemo(
    () =>
      mapValues(carrierZones, carrier =>
        carrier.map((zone, index) => ({
          id: zone.id,
          hasPresetZone: zone.hasPresetZone,
          name: `${t('app.common.zone')} ${index + 1}`,
          countries: zone.countries.map(country => ({
            id: country.id,
            name: currentUser?.me.locale === 'fr' ? country.nameFr : country.nameEn,
          })),
          weightRanges: zone.prices.map(price => ({
            id: price.deliveryWeightRange.id,
            weight: price.deliveryWeightRange.weight,
            price: price.price,
            minPrice: price.minPrice,
          })),
        })),
      ),
    [carrierZones, currentUser?.me.locale, t],
  );

  const onIsZoneEnabledChange = useCallback(
    async (e: RadioChangeEvent) => {
      try {
        if (contact) {
          const newValue = e.target.value;
          const {
            id,
            contactType,
            displayName: _displayName,
            createdAt: _createdAt,
            photoUrl: _photoUrl,
            __typename,
            ...contactData
          } = contact;
          const data = {
            ...contactData,
            isZoneEnabled: newValue,
            contactTypeId: contactType.id,
          };

          await updateContact({
            variables: {
              where: {
                id,
              },
              data,
            },
          });

          message.success(t('app.message.transportFee.update.success'));
        }
      } catch (error) {
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [contact, t, updateContact],
  );

  const handleAddZone = useCallback(() => {
    if (contactId) {
      createZone({ variables: { where: { id: contactId } } });
    }
  }, [contactId, createZone]);

  const handleOnChangeZone = useCallback(
    async ({ deliveryWeightRangeId, zoneId, price }: DeliveryWeightRange) => {
      try {
        await updateDeliveryZone({
          variables: {
            where: {
              deliveryWeightRangeId,
              zoneId,
            },
            data: {
              price,
            },
          },
        });

        message.success(t('app.message.delivery.price.update.success'));
      } catch (error) {
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [t, updateDeliveryZone],
  );

  const handleEditCountriesZone = useCallback(async ({ id, name, countryIds }: HandleEditCountriesProps) => {
    setCurrentZoneId(id);
    setCurrentZoneName(name);
    setZoneDefaultCountryIds(countryIds);
    setIsZoneCountriesVisible(true);
  }, []);

  const handleDeleteZone = useCallback(
    async ({ id }: IdProps) => {
      try {
        await deleteZone({
          variables: {
            where: {
              id,
            },
          },
          update: (cache, { data }) => {
            if (data?.deleteZone) {
              const normalizedId = cache.identify({
                id,
                __typename: data.deleteZone.__typename,
              });
              cache.evict({ id: normalizedId });
              cache.gc();
            }
          },
        });

        message.success(t('app.message.zone.delete.success'));
      } catch (error) {
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [deleteZone, t],
  );

  const resetCurrentZone = useCallback(() => {
    setCurrentZoneId(0);
    setCurrentZoneName('');
    setZoneDefaultCountryIds([]);
  }, []);

  const handleUpdateCountries = useCallback(
    async ({ zoneId, countryIds }: OnEditCountriesProps) => {
      try {
        await updateZone({
          variables: {
            where: {
              id: zoneId,
            },
            data: {
              countryIds,
            },
          },
        });

        resetCurrentZone();

        message.success(t('app.message.zone.update.success'));
      } catch (error) {
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [resetCurrentZone, t, updateZone],
  );

  if (isLoading) {
    return <TransportFeeSkeleton />;
  }

  return (
    <>
      <Header
        title={`${t('app.common.transportFee')} - ${contactDisplayName}`}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.contacts'), url: '/app/contacts' },
          { text: contactDisplayName },
          { text: t('app.common.transportFee') },
        ]}
      />
      <Layout flexDirection="column">
        {isProvider && contact && (
          <>
            <ContainerStyled gap={Metrics.baseMargin}>
              <ConfigContainer direction="column">
                <Title level="h4">{t('app.contact.transportModeToUse')}</Title>
                <ContentContainerStyled shadow rounded>
                  <RadioGroup
                    defaultValue={contact?.isZoneEnabled}
                    buttonStyle="solid"
                    onChange={onIsZoneEnabledChange}
                    size="large"
                    className="ant-radio-group--full-width"
                  >
                    <Radio.Button value={false}>{t('app.contact.fixedPrice')}</Radio.Button>
                    <Radio.Button value={true}>{t('app.contact.transportFeesMode.zonesAndRanges')}</Radio.Button>
                  </RadioGroup>
                </ContentContainerStyled>
              </ConfigContainer>
              <ConfigContainer direction="column">
                <Title level="h4">{t('app.contact.fixedPrice')}</Title>
                <ContentContainerStyled shadow rounded>
                  {transportFeeValue !== null && (
                    <Input
                      name="transportFee"
                      type="number"
                      size="large"
                      pattern="[0-9]*"
                      min={0}
                      addonAfter={currentUser?.me.currency.symbol || '€'}
                      defaultValue={transportFeeValue || 0}
                      value={transportFeeValue}
                      disabled={contact.isZoneEnabled || false}
                      onChange={e => {
                        if (e.target.validity.valid) {
                          setTransportFeeValue(Math.max(parseInt(e.target.value), 0));
                        }
                      }}
                    />
                  )}
                </ContentContainerStyled>
              </ConfigContainer>
            </ContainerStyled>
            {Object.keys(carrierZonesData).includes(Carrier.LAPOSTE_LETTRE_VERTE) && (
              <LaPosteAlert
                message={
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={t('app.contact.transportFees.greenLetter.info.text', { maxPrice: 25 })}
                    components={{
                      p: ({ children }) => <Text size="medium">{children}</Text>,
                      b: ({ children }) => (
                        <Text size="medium" weight="bold">
                          {children}
                        </Text>
                      ),
                      a: ({ children, ...props }) => (
                        <a href={props.href} target="_blank">
                          {children}
                        </a>
                      ),
                    }}
                  />
                }
                type="info"
                showIcon
              />
            )}
            {contact?.isZoneEnabled &&
              Object.keys(carrierZonesData).includes(Carrier.COLISSIMO || Carrier.LAPOSTE_LETTRE_VERTE) && (
                <VatContainer direction="row" justify="flex-end" align="center" gap={8}>
                  {isVatIncl && (
                    <Text size="medium">
                      {t('app.contact.transportFees.vat.preview', { value: meData?.me.company.vat1Value })}
                    </Text>
                  )}
                  <Title level="h6">{t('app.contact.transportFees.vat.previewLabel')}</Title>
                  <Switch
                    checked={isVatIncl}
                    checkedChildren={t('app.contact.transportFees.vat.included')}
                    unCheckedChildren={t('app.contact.transportFees.vat.excluded')}
                    onChange={() => setIsVatIncl(!isVatIncl)}
                  />
                </VatContainer>
              )}
            {map(carrierZonesData, (zonesData, carrier) => (
              <TransportFeeTableContainer direction="column" key={carrier}>
                {carrier !== Carrier.DEFAULT && (
                  <Title level="h4">
                    {t(`app.contact.transportFees.carrier.${camelCase(carrier)}`)} -{' '}
                    {t('app.contact.transportFees.zonesAndWeightRanges')}
                  </Title>
                )}
                {carrier === Carrier.DEFAULT && (
                  <Title level="h4">{t('app.contact.transportFees.zonesAndWeightRanges')}</Title>
                )}
                <TableContainerStyled shadow rounded>
                  <TransportFeeTable
                    loading={isTableDataLoading}
                    data={zonesData}
                    isActive={
                      contact.isZoneEnabled
                        ? contact.isZoneEnabled && !zonesData.find(zone => zone.hasPresetZone)
                        : false
                    }
                    currency={currentUser?.me.currency.symbol || '€'}
                    vat={isVatIncl && meData?.me.company.vat1Value ? meData?.me.company.vat1Value : 0}
                    handleOnChange={handleOnChangeZone}
                    handleEditCountries={handleEditCountriesZone}
                    handleDeleteConfirm={handleDeleteZone}
                  />
                  {!zonesData.find(zone => zone.hasPresetZone) && (
                    <AddZoneButton
                      type="primary"
                      size="large"
                      onClick={handleAddZone}
                      loading={isTableDataLoading}
                      disabled={!contact.isZoneEnabled || false}
                    >
                      <Icon name="add" />
                      {t('app.common.addZone')}
                    </AddZoneButton>
                  )}
                </TableContainerStyled>
              </TransportFeeTableContainer>
            ))}
            {Object.keys(carrierZonesData).length === 0 && (
              <TransportFeeTableContainer direction="column" key="newZone">
                <AddZoneButton
                  type="primary"
                  size="large"
                  onClick={handleAddZone}
                  loading={isTableDataLoading}
                  disabled={!contact.isZoneEnabled || false}
                >
                  <Icon name="add" />
                  {t('app.common.addZone')}
                </AddZoneButton>
              </TransportFeeTableContainer>
            )}
          </>
        )}
      </Layout>
      <ZoneCountriesDrawer
        isVisible={isZoneCountriesVisible}
        onEditCountries={handleUpdateCountries}
        onCloseDrawer={() => {
          resetCurrentZone();
          setIsZoneCountriesVisible(false);
        }}
        zoneId={currentZoneId}
        zoneName={currentZoneName}
        countryIds={zoneDefaultCountryIds}
      />
    </>
  );
};

export default ContactTransportFees;
