import React, { createContext, useEffect, useMemo, useState } from 'react';

import { ApolloClient, ApolloLink, ApolloProvider, NormalizedCacheObject, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';

import cache, { AuthVar } from 'Operations/Cache';
import { persistReactiveVariables } from 'Operations/Cache/reactiveVariables';

const apolloLogger = require('apollo-link-logger').default;

const API_URL = process.env.REACT_APP_API_URL;
const WS_URL = process.env.REACT_APP_WS_URL || 'ws://localhost:4000/subscriptions';
const STAGE = process.env.REACT_APP_STAGE;

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
let wsLink: WebSocketLink | undefined;

export const getApolloClient = () => {
  const auth = AuthVar();

  const httpLink: unknown = createUploadLink({
    uri: API_URL,
    headers: {
      'Apollo-Require-Preflight': 'true',
    },
  });

  const authHttpLink = setContext((_, { headers }) => {
    let appHeaders = headers;

    if (auth && auth.accessToken) {
      appHeaders = {
        ...appHeaders,
        authorization: `Bearer ${auth.accessToken}`,
      };
    }

    return {
      headers: appHeaders,
    };
  });

  const httpLinks: ApolloLink[] = [];
  if (STAGE === 'DEV') {
    httpLinks.push(apolloLogger);
  }
  httpLinks.push(...[authHttpLink as ApolloLink, httpLink as ApolloLink]);

  const httpChain = ApolloLink.from(httpLinks);
  let fullLink = httpChain;

  if (auth && auth.accessToken) {
    if (!wsLink) {
      wsLink = new WebSocketLink({
        uri: WS_URL,
        options: {
          reconnect: true,
          connectionParams: {
            authToken: auth.accessToken,
          },
        },
      });
    }

    const selectiveChain = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      httpChain,
    );
    fullLink = selectiveChain;
  }

  if (apolloClient) {
    apolloClient.setLink(fullLink);
    return apolloClient;
  }

  apolloClient = new ApolloClient({
    link: fullLink,
    cache,
    connectToDevTools: STAGE === 'DEV',
    queryDeduplication: true,
  });

  return apolloClient;
};

export type ApolloContextType = {
  client: ApolloClient<NormalizedCacheObject> | null;
  refreshClient: () => void;
  isPublicPage: boolean;
};

export const ApolloContext = createContext<ApolloContextType>({
  client: null,
  refreshClient: () => {
    console.log('refreshClient not implemented');
    return null;
  },
  isPublicPage: true,
});

const ApolloProviderContext = (props: { children: React.ReactNode }) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPublicPage, setIsPublicPage] = useState(
    !window.location.pathname.includes('/app') && !window.location.pathname.includes('/slip-control'),
  );

  const refreshClient = () => {
    setIsPublicPage(!window.location.pathname.includes('/app') && !window.location.pathname.includes('/slip-control'));
    setClient(getApolloClient());
  };

  const init = async () => {
    try {
      await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: STAGE === 'DEV',
      });

      await persistReactiveVariables();

      setClient(getApolloClient());
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoaded(true);
    }
  };

  const apolloContextValue = useMemo<ApolloContextType>(
    () => ({
      client,
      refreshClient,
      isPublicPage,
    }),
    [client, isPublicPage],
  );

  useEffect(() => {
    init();
  }, []);

  return isLoaded && client ? (
    <ApolloContext.Provider value={apolloContextValue}>
      <ApolloProvider client={client} {...props} />
    </ApolloContext.Provider>
  ) : null;
};

export default ApolloProviderContext;
