import { createStyles } from 'antd-style';

export const useFlexStyle = createStyles(({ css, prefixCls, token, stylish }) => ({
  className: css`
    &.${prefixCls}-flex {
      &--shadow {
        ${stylish.shadow}
      }
      &--padding {
        padding: ${token.sizeLG}px;
      }
      &--rounded {
        border-radius: ${token.borderRadius}px;
      }
    }
  `,
}));
