import React, { useEffect, useState } from 'react';

import { Images } from 'Themes';
import styled from 'Themes/Styled';

import { Position, Repeat, WatermarkType } from 'Operations/__generated__/graphql';

export interface WatermarkPreviewProps {
  watermarkType: WatermarkType;
  image?: string;
  font?: string;
  text?: string;
  textColor?: string;
  withStroke: boolean;
  strokeColor?: string;
  withBackgroundText: boolean;
  backgroundTextColor?: string;
  repeat?: Repeat;
  opacity: number;
  isResized: boolean;
  size?: number;
  position: Position;
}

const PREVIEW_HEIGHT = 186;

const WatermarkPreviewContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const Watermark = styled.div<{
  watermarkType: WatermarkType;
  image?: string;
  repeat?: Repeat;
  opacity: number;
  size?: string;
  position: Position;
}>`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
  align-items: ${({ position }) => {
    switch (position) {
      case Position.TOP_LEFT:
      case Position.TOP_RIGHT:
      case Position.TOP_CENTER:
        return 'flex-start';

      case Position.BOTTOM_LEFT:
      case Position.BOTTOM_RIGHT:
      case Position.BOTTOM_CENTER:
        return 'flex-end';

      case Position.CENTER:
      default:
        return 'center';
    }
  }};
  justify-content: ${({ position }) => {
    switch (position) {
      case Position.TOP_LEFT:
      case Position.BOTTOM_LEFT:
      case Position.LEFT:
        return 'flex-start';

      case Position.TOP_RIGHT:
      case Position.BOTTOM_RIGHT:
      case Position.RIGHT:
        return 'flex-end';

      case Position.TOP_CENTER:
      case Position.BOTTOM_CENTER:
      case Position.CENTER:
      default:
        return 'center';
    }
  }};
  background-image: ${({ image }) => image && `url(${image})`};
  background-size: ${({ size }) => size};
  background-repeat: ${({ repeat }) => {
    switch (repeat) {
      case Repeat.ALL:
        return 'repeat';

      case Repeat.HORIZONTAL:
        return 'repeat no-repeat';

      case Repeat.VERTICAL:
        return 'no-repeat repeat';

      case undefined:
      case Repeat.NO_REPEAT:
      default:
        return 'no-repeat';
    }
  }};
  background-position: ${({ position }) => {
    switch (position) {
      case Position.LEFT:
        return 'left';

      case Position.RIGHT:
        return 'right';

      case Position.TOP:
        return 'top';

      case Position.BOTTOM:
        return 'bottom';

      case Position.TOP_LEFT:
        return 'top left';

      case Position.TOP_RIGHT:
        return 'top right';

      case Position.TOP_CENTER:
        return 'top center';

      case Position.BOTTOM_LEFT:
        return 'bottom left';

      case Position.BOTTOM_RIGHT:
        return 'bottom right';

      case Position.BOTTOM_CENTER:
        return 'bottom center';

      case Position.CENTER:
      default:
        return 'center';
    }
  }};
  opacity: ${({ opacity }) => opacity};
`;

const WatermarkText = styled.p<{
  font: string;
  size: number;
  color: string;
  opacity: number;
  withStroke: boolean;
  strokeColor?: string;
}>`
  font-family: ${({ font }) => font};
  font-size: ${({ size }) => (PREVIEW_HEIGHT / 3 / 100) * size}px;
  color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity};
  -webkit-text-stroke: ${({ withStroke }) => (withStroke ? '0.03em' : 0)} ${({ strokeColor }) => `rgba(${strokeColor})`};
  word-break: break-all;
`;

const WatermarkTextBackground = styled.div<{ withBackgroundText: boolean; backgroundTextColor?: string }>`
  padding: 2px 6px;
  background-color: ${({ withBackgroundText, backgroundTextColor }) =>
    withBackgroundText && backgroundTextColor ? backgroundTextColor : 'transparent'};
`;

const WatermarkPreview = ({
  watermarkType,
  image,
  font = 'Fira Sans',
  text,
  textColor = '#000',
  repeat,
  opacity,
  isResized,
  size,
  position,
  withStroke,
  strokeColor,
  withBackgroundText,
  backgroundTextColor,
  ...props
}: WatermarkPreviewProps) => {
  const [sizeAsCssString, setSizeAsCssString] = useState<string>(isResized ? `auto ${size}%` : 'auto auto');
  useEffect(() => {
    if (image && size && isResized) {
      const img = new Image();
      img.onload = function () {
        // Select which dimension should be used as a reference
        const previewWidth = 274;
        const previewHeight = 186;
        const widthRatio = img.width / previewWidth;
        const heightRatio = img.height / previewHeight;
        if (widthRatio > heightRatio) {
          setSizeAsCssString(`${size}% auto`);
        } else {
          setSizeAsCssString(`auto ${size}%`);
        }
      };
      img.src = image;
    } else {
      setSizeAsCssString(`auto auto`);
    }
  }, [image, size, isResized]);

  return (
    <WatermarkPreviewContainer {...props}>
      <Watermark
        watermarkType={watermarkType}
        image={image}
        repeat={repeat}
        opacity={watermarkType === WatermarkType.IMAGE ? opacity : 1}
        size={sizeAsCssString}
        position={position}
      >
        {watermarkType === WatermarkType.TEXT && text && (
          <WatermarkTextBackground withBackgroundText={withBackgroundText} backgroundTextColor={backgroundTextColor}>
            <WatermarkText
              font={font}
              size={size || PREVIEW_HEIGHT}
              color={textColor}
              withStroke={withStroke}
              strokeColor={strokeColor}
              opacity={opacity}
            >
              {text}
            </WatermarkText>
          </WatermarkTextBackground>
        )}
      </Watermark>
      <img alt="Watermar preview" src={Images.watermarkPreview1} />
    </WatermarkPreviewContainer>
  );
};

export default WatermarkPreview;
