import { gql } from 'Operations/__generated__';

export const DISABLE_STRIPE_PAYMENT = gql(/* GraphQL */ `
  mutation DisableStripePayment {
    disableStripePayment {
      id
      stripePayment {
        status
      }
    }
  }
`);
