import { gql } from 'Operations/__generated__';

export const GET_CONTACTS = gql(/* GraphQL */ `
  query GetContacts($where: ContactsGetInputType!, $paginate: PaginatedInput) {
    getContacts(where: $where, paginate: $paginate) {
      _count
      edges {
        ...ContactCoreFields
      }
      pageInfo {
        currentPage
        isFirstPage
        isLastPage
        pageCount
      }
    }
  }
`);
