import React, { useCallback, useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { App } from 'antd';
import { ColorFactory } from 'antd/es/color-picker/color';

import { EditGalleryThemeForm, EditGalleryThemePayload } from 'Forms/GalleryTheme';

import { LocalizationContext } from 'i18n';

import { getRGBString } from 'Helpers/TransformColor';

import { GalleryAdmin, GalleryPreset } from 'Operations/__generated__/graphql';

import { GET_GALLERY_THEME } from 'Operations/Queries/Gallery/GetGalleryTheme';
import { GET_GALLERY_PRESET } from 'Operations/Queries/GalleryPreset/GetGalleryPreset';

import { UPDATE_GALLERY_THEME } from 'Operations/Mutations/GalleryTheme/UpdateGalleryTheme';

const GalleryThemeTab = ({
  gallery: { id, name, ...gallery },
}: {
  gallery: DeepPartial<GalleryAdmin | GalleryPreset> & { id: number };
}) => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();

  const isPreset = gallery.__typename === 'GalleryPreset';

  const { data: galleryData } = useQuery(GET_GALLERY_THEME, {
    skip: isPreset,
    variables: {
      where: { id },
    },
  });
  const { data: presetData } = useQuery(GET_GALLERY_PRESET, {
    skip: !isPreset,
    variables: {
      where: { id },
    },
  });

  const theme = isPreset ? presetData?.getGalleryPreset.theme : galleryData?.getGallery?.theme;

  const [updateGalleryTheme] = useMutation(UPDATE_GALLERY_THEME);

  const handleSubmit = useCallback(
    async ({ values, formikBag }: EditGalleryThemePayload) => {
      formikBag.setSubmitting(true);
      try {
        await updateGalleryTheme({
          variables: {
            where: {
              id: values.id,
            },
            data: {
              backgroundColor: getRGBString(values.backgroundColor, true),
              primaryColor: getRGBString(values.primaryColor, true),
              secondaryColor: getRGBString(values.secondaryColor, true),
              menuBackgroundColor: getRGBString(values.menuBackgroundColor, true),
              paymentBackgroundColor: getRGBString(values.paymentBackgroundColor, true),
              titleFont: values.titleFont,
              buttonFont: values.buttonFont,
              buttonRadius: values.buttonRadius,
              photoRadius: values.photoRadius,
            },
          },
        });

        formikBag.setSubmitting(false);
        message.success(t('app.message.gallery.update.success'));
      } catch (error) {
        message.error(t(`app.message.error.somethingWentWrong`));

        formikBag.setSubmitting(false);
      }
    },
    [updateGalleryTheme],
  );

  return (
    theme && (
      <EditGalleryThemeForm
        galleryId={id}
        defaultValues={{
          id: theme.id,
          primaryColor: new ColorFactory(`rgba(${theme.primaryColor})`),
          secondaryColor: new ColorFactory(`rgba(${theme.secondaryColor})`),
          backgroundColor: new ColorFactory(`rgba(${theme.backgroundColor})`),
          menuBackgroundColor: new ColorFactory(`rgba(${theme.menuBackgroundColor})`),
          paymentBackgroundColor: new ColorFactory(`rgba(${theme.paymentBackgroundColor})`),
          buttonFont: theme.buttonFont,
          titleFont: theme.titleFont,
          buttonRadius: theme.buttonRadius,
          photoRadius: theme.photoRadius,
        }}
        onSubmit={handleSubmit}
      />
    )
  );
};

export default GalleryThemeTab;
