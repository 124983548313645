import { gql } from 'Operations/__generated__';

export const GET_COUNTRIES = gql(/* GraphQL */ `
  query GetCountries {
    getCountries {
      _count
      edges {
        id
        nameFr
        nameEn
      }
    }
  }
`);
