import { useEffect, useMemo, useState } from 'react';

import { CategoricalChartWrapper } from 'types/CategoricalChartWrapper';

import { Area, AreaChart, Dot, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

import useDebouncedResizeObserver from 'Hooks/useDebouncedResizeObserver';

const hiddenItems = '__ignore__';

type Props = {
  data: {
    label: string;
    value: number;
  }[];
  color: string;
  name: string;
  renderTooltip?: (value: number) => React.ReactNode | string;
};

const CustomTooltip = ({
  payload,
  viewBox,
  tickWidth,
  color,
  renderTooltip,
}: TooltipProps<number, string> & {
  tickWidth: number;
  color: string;
  renderTooltip?: (value: number) => React.ReactNode | string;
}) => {
  const currentItem = payload?.[0];

  if (currentItem && currentItem?.payload?.label !== hiddenItems) {
    return (
      <div
        style={{
          boxShadow: '0 2px 16px 0 RGBA(0, 0, 0, 0.15)',
          borderRadius: 12,
          height: (viewBox?.height || 0) + (viewBox?.y || 0),
          width: tickWidth,

          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <p
          style={{
            color,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {renderTooltip && currentItem.value !== undefined ? renderTooltip(currentItem.value) : currentItem.value}
        </p>
      </div>
    );
  }

  return null;
};

const Chart = ({ data, color, name, renderTooltip }: Props) => {
  const [chartRef, setChartRef] = useState<CategoricalChartWrapper | undefined>();
  const defaultTooltip = useMemo(() => data[data.length - 1], [data]);
  const [tickWidth, setTickWidth] = useState(0);
  const { ref, width = 1 } = useDebouncedResizeObserver<HTMLDivElement>(10);

  const offsetedData = useMemo(() => {
    const lastItem = data[data.length - 1];
    const firstItem = data[0];

    return [
      {
        label: hiddenItems,
        value: firstItem.value,
      },
      ...data,
      {
        label: hiddenItems,
        value: lastItem.value,
      },
    ];
  }, [data]);

  useEffect(() => {
    if (chartRef) {
      const lastIndex = offsetedData
        .slice()
        .reverse()
        .findIndex(item => item.label !== hiddenItems);

      const nextState: CategoricalChartState = {
        activeTooltipIndex: lastIndex,
        isTooltipActive: true,
        activePayload: [
          {
            value: defaultTooltip.value,
            payload: defaultTooltip,
          },
        ],
        activeCoordinate: {
          x: tickWidth * (offsetedData.length - 2),
          y: 0,
        },
      };

      chartRef.setState(nextState);
      chartRef.triggerSyncEvent(nextState);
    }
  }, [chartRef, defaultTooltip, tickWidth, offsetedData, chartRef?.state.prevHeight, width]);

  useEffect(() => {
    // Remove one element for both half ticks on each side
    setTickWidth((chartRef?.state.prevWidth || 0) / (offsetedData.length - 1));
  }, [chartRef?.state.prevWidth, width, offsetedData]);

  return (
    <div ref={ref}>
      <ResponsiveContainer width="100%" height={150}>
        <AreaChart
          // @ts-ignore
          ref={ref => setChartRef(ref)}
          id={name}
          stackOffset="expand"
          data={offsetedData}
          margin={{
            top: 15,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          defaultShowTooltip
          onMouseMove={(_state: unknown, e: MouseEvent) => {
            const mouse = chartRef?.getMouseInfo(e);
            if (mouse?.activeLabel === '__ignore__') {
              return;
            }
            const nextState = mouse ? { ...mouse } : {};

            chartRef?.setState(nextState);
            chartRef?.triggerSyncEvent(nextState);
          }}
        >
          <defs>
            <linearGradient id={`valueArea-${name}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={color} stopOpacity={0.45} />
              <stop offset="100%" stopColor={color} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="label" axisLine={false} tickLine={true} ticks={data.map(({ label }) => label)} />
          <YAxis
            domain={[0, 'dataMax']}
            width={0}
            padding={{
              bottom: 30,
            }}
            style={{
              visibility: 'hidden',
            }}
          />
          <Tooltip
            cursor={false}
            position={{ y: 0 }}
            offset={-(tickWidth / 2)}
            allowEscapeViewBox={{ x: true }}
            trigger="click"
            content={<CustomTooltip tickWidth={tickWidth} color={color} renderTooltip={renderTooltip} />}
          />

          <Area
            type="monotone"
            dataKey="value"
            stroke={color}
            strokeWidth={3}
            dot={props =>
              props.payload?.label !== hiddenItems ? (
                <Dot {...props} r={5} fill="#fff" fillOpacity={1} strokeWidth={3} />
              ) : (
                <Dot key={props.key} />
              )
            }
            activeDot={props =>
              props.payload?.label !== hiddenItems ? (
                <Dot {...props} r={2} fill={color} fillOpacity={1} strokeWidth={0} />
              ) : (
                <Dot key={props.key} />
              )
            }
            fill={`url(#valueArea-${name})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
export default Chart;
