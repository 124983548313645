import { useCallback, useContext } from 'react';

import {
  MutationHookOptions,
  useLazyQuery,
  useMutation,
  useQuery
} from '@apollo/client';
import { App } from 'antd';

import {
  EditContactFormValues,
  EditContactPayload,
  NewContactPayload
} from 'Forms/Contact';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import {
  ContactCreateInputType,
  ContactUpdateWhereType,
  CreateContactMutation,
  Exact,
  UpdateContactMutation
} from 'Operations/__generated__/graphql';

import { GET_CONTACT } from 'Operations/Queries/Contact/GetContact';
import { ME } from 'Operations/Queries/User/Me';

import { CREATE_CONTACT } from 'Operations/Mutations/Contact/CreateContact';
import { UPDATE_CONTACT } from 'Operations/Mutations/Contact/UpdateContact';

export const useContactModal = ({
  refetchQueries,
}: MutationHookOptions<
  CreateContactMutation | UpdateContactMutation,
  Exact<{ data: ContactCreateInputType | ContactUpdateWhereType }>
> = {}) => {
  const { openModal, closeModal } = useModals();
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();

  const { data: currentUser } = useQuery(ME);

  const [createContactMutation] = useMutation(CREATE_CONTACT, {
    refetchQueries,
  });
  const [updateContactMutation] = useMutation(UPDATE_CONTACT, {
    refetchQueries,
  });

  const handleOnSubmit = useCallback(
    async ({ values, formikBag }: NewContactPayload) => {
      try {
        await createContactMutation({ variables: { data: values } });

        message.success(t('app.message.contact.create.success'));
        formikBag.setSubmitting(false);

        closeModal();
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, createContactMutation, t],
  );

  const handleUpdateOnSubmit = useCallback(
    async ({ values: { id, ...values }, formikBag }: EditContactPayload) => {
      try {
        await updateContactMutation({ variables: { where: { id }, data: values } });

        message.success(t('app.message.contact.update.success'));
        formikBag.setSubmitting(false);

        closeModal();
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, t, updateContactMutation],
  );

  const [getContact] = useLazyQuery(GET_CONTACT, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data.getContact) {
        const { contactType, photoUrl, ...contact } = data.getContact;

        openModal('CONTACT', {
          name: 'edit',
          onSubmit: handleUpdateOnSubmit,
          defaultValues: {
            id: contact.id,
            birthdate: contact.birthdate,
            birthdayEmail: contact.birthdayEmail === null ? undefined : contact.birthdayEmail,
            city: contact.city,
            company: contact.company,
            contactTypeId: contactType.id,
            countryId: contact.countryId,
            email: contact.email,
            favorite: contact.favorite === null ? undefined : contact.favorite,
            firstname: contact.firstname,
            graphicalIdentityId: contact.graphicalIdentityId,
            lastname: contact.lastname,
            phone: contact.phone,
            prospectOriginId: contact.prospectOriginId,
            street: contact.street,
            tags: contact.tags || [],
            vat: contact.vat,
            zipcode: contact.zipcode,
            taxCode: contact.taxCode === null ? undefined : contact.taxCode,
            stateId: contact.stateId,
          },
          photoUrl: photoUrl || undefined,
        });
      }
    },
  });

  const createContact = useCallback(
    () =>
      openModal('CONTACT', {
        name: 'new',
        onSubmit: handleOnSubmit,
      }),
    [handleOnSubmit, openModal],
  );

  const updateContact = useCallback((id: number) => getContact({ variables: { where: { id } } }), [getContact]);

  return { createContact, updateContact };
};
