import React, { createContext, FunctionComponent, useContext, useState } from 'react';

import { ModalArgs, ModalNames } from 'Components/Molecules/Modals';

export interface Modals {
  addModal(key: ModalNames, modal: FunctionComponent<React.PropsWithChildren<unknown>>): void;
  openModal<T extends ModalNames>(key: T, props?: ModalArgs<T>): void;
  closeModal(key?: ModalNames): void;
}

const initialState: Modals = {
  closeModal: () => {
    throw new Error('Not implemented');
  },
  openModal: () => {
    throw new Error('Not implemented');
  },
  addModal: () => {
    throw new Error('Not implemented');
  },
};

export const ModalsContext = createContext(initialState);
export const useModals = () => useContext(ModalsContext);

interface ModalItem<T extends ModalNames> {
  key: T;
  Component: FunctionComponent;
  props: ModalArgs<T>;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  initialModals?: { [key: string]: FunctionComponent<React.PropsWithChildren<any>> };
}

export const ModalsProvider: React.FC<React.PropsWithChildren<Props>> = ({ children, initialModals = {} }: Props) => {
  const [modals, setModals] = useState<{ [key: string]: FunctionComponent }>(initialModals);
  const [modal, setModal] = useState<ModalItem<ModalNames>[]>([]);

  const addModal: Modals['addModal'] = (key, ModalComponent) => {
    setModals(state => ({ ...state, [key]: ModalComponent }));
  };

  const openModal: Modals['openModal'] = (key, props = {}) => {
    setModal(state => [
      ...state,
      {
        key,
        Component: modals[key],
        props,
      },
    ]);
  };

  const closeModal: Modals['closeModal'] = (key?: string) => {
    if (key === undefined) {
      setModal([]);
    } else {
      setModal(state => state.filter(item => item.key !== key));
    }
  };

  return (
    <ModalsContext.Provider
      value={{
        addModal,
        openModal,
        closeModal,
      }}
    >
      {modal.map(item => {
        return <item.Component {...item.props} key={item.key} />;
      })}
      {children}
    </ModalsContext.Provider>
  );
};
