import React, { useContext, useState } from 'react';

import { Tooltip } from 'antd';

import { Colors, Images, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const ButtonContainer = styled.div`
  position: relative;
  width: ${Metrics.buttonHeight}px;
  height: ${Metrics.buttonHeight}px;
`;

const SwitchContainer = styled.div<{ isOpen: boolean }>`
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'relative')};
  overflow: hidden;

  width: ${({ isOpen }) => (isOpen ? Metrics.buttonHeight * 2 + Metrics.mediumMargin : Metrics.buttonHeight)}px;
  height: ${Metrics.buttonHeight}px;

  border-radius: ${Metrics.buttonHeight / 2}px;
  background-color: ${Colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
`;

const AppsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: ${Metrics.buttonHeight * 2 + Metrics.mediumMargin}px;
  height: ${Metrics.buttonHeight}px;
`;

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 44px;
  cursor: pointer;
`;

const Galleries = styled.img`
  height: 28px;
  transition: all ease-in-out 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const Crm = styled.img`
  height: 28px;
  transition: all ease-in-out 0.3s;

  &:hover {
    transform: scale(1.2);
  }
`;

const Separator = styled.div`
  width: 1px;
  height: 28px;
  background-color: ${Colors.grey};
`;

const AppSwitchButton = ({ ...props }) => {
  const { t } = useContext(LocalizationContext);

  const [isSwitchOpen, setIsSwitchOpen] = useState(false);
  const [appTitle, setAppTitle] = useState(t('app.menu.goToGalleries'));

  return (
    <ButtonContainer {...props} onMouseEnter={() => setIsSwitchOpen(true)} onMouseLeave={() => setIsSwitchOpen(false)}>
      <Tooltip title={appTitle} placement="right" mouseEnterDelay={0.3}>
        <SwitchContainer isOpen={isSwitchOpen}>
          <AppsContainer>
            <AppContainer>
              <Galleries
                src={Images.galleriesLogo}
                onMouseEnter={() => setAppTitle(t('app.menu.goToGalleries'))}
                onClick={() => setIsSwitchOpen(!isSwitchOpen)}
              />
            </AppContainer>
            <Separator />
            <AppContainer>
              <a href={`${process.env.REACT_APP_CRM_URL}/app`}>
                <Crm src={Images.crmLogo} onMouseEnter={() => setAppTitle(t('app.menu.goToCrm'))} />
              </a>
            </AppContainer>
          </AppsContainer>
        </SwitchContainer>
      </Tooltip>
    </ButtonContainer>
  );
};

export default AppSwitchButton;
