import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { EditContactForm, EditContactFormProps, NewContactForm, NewContactFormProps } from 'Forms/Contact';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

interface NewContactModalProps extends NewContactFormProps {
  name: 'new';
}
interface EditContactModalProps extends EditContactFormProps {
  name: 'edit';
}

const ContactModal = (props: NewContactModalProps | EditContactModalProps) => {
  const { t } = useContext(LocalizationContext);
  const renderForm = () => {
    if (props.name === 'edit') {
      return <EditContactForm {...props} />;
    }

    return <NewContactForm {...props} />;
  };

  return (
    <ModalContainer title={t(`app.contact.modal.${props.name}`)} icon={Images.contact}>
      {renderForm()}
    </ModalContainer>
  );
};

export default ContactModal;
