import React, { useContext, useEffect, useState } from 'react';

import { useMutation, useSubscription } from '@apollo/client';
import { Button, Progress } from 'antd';

import Container from 'Components/Atoms/Container';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { Images, Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import { JobType } from 'Operations/__generated__/graphql';

import { ZIP_PICKLIST } from 'Operations/Mutations/Picklist/zipPicklist';

import { ON_JOB_UPDATED } from 'Operations/Subscriptions/OnJobUpdated';
import { ON_ZIP_PICKLIST_COMPLETED } from 'Operations/Subscriptions/OnZipPicklistCompleted';

interface Props {
  picklistId: number;
  galleryId: number;
}

const DownloadPicklistModal = ({ picklistId, galleryId }: Props) => {
  const { t } = useContext(LocalizationContext);
  const [progress, setProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState<null | string>(null);

  const [zipPicklist] = useMutation(ZIP_PICKLIST);

  useSubscription(ON_JOB_UPDATED, {
    shouldResubscribe: true,
    variables: {
      where: {
        galleryId,
      },
    },
    onData({ data }) {
      const job = data.data?.OnJobUpdated;
      if (job && job.jobType === JobType.FAVORITE_PHOTOS_ZIP && job.picklist?.id === picklistId) {
        setProgress(job.percent || 0);
      }
    },
  });
  useSubscription(ON_ZIP_PICKLIST_COMPLETED, {
    shouldResubscribe: true,
    variables: {
      where: {
        id: picklistId,
      },
    },
    onData({ data }) {
      if (data.data?.OnZipPicklistCompleted) {
        setDownloadUrl(data.data.OnZipPicklistCompleted);
        setProgress(100);
      }
    },
  });

  useEffect(() => {
    zipPicklist({
      variables: {
        where: {
          id: picklistId,
        },
      },
    });
  }, [picklistId, zipPicklist]);

  return (
    <ModalContainer
      title={t(`app.modal.downloadFavoritePhotos.title`)}
      icon={Images.download}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <Container direction="column" gap={Metrics.baseMargin} align="center" justify="center" style={{ height: '100%' }}>
        <Progress type="circle" percent={progress} />
        <Button loading={!downloadUrl} href={downloadUrl || ''} target="_blank" type="primary" disabled={!downloadUrl}>
          {t(downloadUrl ? 'app.order.downloadPhotos' : 'app.modal.downloadPhotos.zipCreationInProgress')}
        </Button>
      </Container>
    </ModalContainer>
  );
};

export default DownloadPicklistModal;
