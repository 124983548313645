import { gql } from 'Operations/__generated__';

export const GET_ORDERS_WITH_DIGITAL_ITEMS = gql(/* GraphQL */ `
  query GetOrdersWithDigitalItems($where: GetOrdersWithDigitalItemsWhereInput!) {
    getOrdersWithDigitalItems(where: $where) {
      _count
      edges {
        id
        items {
          productName
          isDigital
        }
        createdAt
        ... on OrderAdmin {
          contact {
            ...ContactCoreFields
          }
        }
      }
    }
  }
`);
