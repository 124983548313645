import { gql } from 'Operations/__generated__';

export const SEE_GALLERY = gql(/* GraphQL */ `
  mutation SeeGallery($where: IdInput!) {
    seeGallery(where: $where) {
      id
      seenAt
    }
  }
`);
