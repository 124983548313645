import React, { useCallback, useContext, useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { App, Button, Divider, TablePaginationConfig } from 'antd';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Title from 'Components/Atoms/Title';

import LabeledSwitch from 'Components/Molecules/LabeledSwitch';
import AccessTable from 'Components/Molecules/Tables/AccessTable';

import { AccessFormPayload, AccessType, EditAccessFormPayload } from 'Forms/Access';

import { Colors } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import generateId from 'Helpers/GenerateId';

import {
  AccessCodeCharacteristic,
  AccessCodesWhereType,
  GalleryAccessPolicy,
  GetAccessCodesQuery,
  OrderType,
} from 'Operations/__generated__/graphql';

import { GET_GALLERIES_ACCESS } from 'Operations/Queries/Gallery/GetGalleriesAccess';
import { GET_GALLERY_ACCESS_CODE } from 'Operations/Queries/Gallery/GetGalleryAccessCode';

import { CREATE_ACCESS_CODE } from 'Operations/Mutations/Gallery/CreateAccessCode';
import { DELETE_ACCESS_CODE } from 'Operations/Mutations/Gallery/DeleteAccessCode';
import { UPDATE_ACCESS_CODE } from 'Operations/Mutations/Gallery/UpdateAccessCode';
import { UPDATE_GALLERY } from 'Operations/Mutations/Gallery/UpdateGallery';

interface Props {
  isClientCodesAdminEnabled: boolean;
  type: AccessType;
  galleryId: number;
  galleryUrl: string;
  galleryContactId: number | undefined;
  galleryAccessPolicy: GalleryAccessPolicy;
}

const SpaceTitle = styled(Title)`
  flex: 1;
  font-size: 18px;
`;

const StyledDiv = styled.div`
  width: 100%;
`;

const PER_PAGE = 10;

const AccessItem = ({
  isClientCodesAdminEnabled,
  type,
  galleryId,
  galleryContactId,
  galleryAccessPolicy,
  galleryUrl,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const { openModal, closeModal } = useModals();
  const { message } = App.useApp();

  const whereParams: AccessCodesWhereType = useMemo(
    () => ({
      galleryId,
      characteristics: [type === 'client' ? AccessCodeCharacteristic.CLIENT : AccessCodeCharacteristic.GROUP],
    }),
    [galleryId, type],
  );

  const {
    data: accessCodesData,
    loading: isAccessLoading,
    fetchMore,
  } = useQuery(GET_GALLERIES_ACCESS, {
    variables: {
      where: whereParams,
      paginate: {
        page: 1,
        perPage: PER_PAGE,
        order: OrderType.ASC,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  const [updateAccessCode] = useMutation(UPDATE_ACCESS_CODE, {
    refetchQueries: [{ query: GET_GALLERY_ACCESS_CODE, variables: { where: { id: galleryId } } }],
    awaitRefetchQueries: true,
  });

  const [deleteAccessCode] = useMutation(DELETE_ACCESS_CODE, {
    onError: () => {
      message.error(t('app.message.error.somethingWentWrong'));
    },
    onCompleted: () => {
      closeModal();
      message.success(t('app.access.delete.success'));
    },
  });

  const [updateIsClientCodesAdminEnabled] = useMutation(UPDATE_GALLERY, {
    variables: {
      where: {
        id: galleryId,
      },
      data: {
        isClientCodesAdminEnabled: !isClientCodesAdminEnabled,
      },
    },
  });

  const [createAccessCode] = useMutation(CREATE_ACCESS_CODE);

  const handleAddGroupOnSubmit = useCallback(
    async ({ values: { name, code }, formikBag }: AccessFormPayload) => {
      try {
        if (!name) {
          throw new Error('Name is required');
        }

        await createAccessCode({
          variables: {
            where: { id: galleryId },
            data: { code, name },
          },
          update(cache, { data }) {
            const query = cache.readQuery({
              query: GET_GALLERIES_ACCESS,
              variables: { where: whereParams },
            });

            if (query && data) {
              return cache.writeQuery({
                query: GET_GALLERIES_ACCESS,
                variables: { where: whereParams },
                data: {
                  getAccessCodes: {
                    ...query.getAccessCodes,
                    _count: query.getAccessCodes._count + 1,
                    edges: [...query.getAccessCodes.edges, { ...data.createAccessCode }],
                  },
                },
              });
            }
          },
        });

        message.success(t('app.message.gallery.create.success'));
        formikBag.setSubmitting(false);
        closeModal();
      } catch (error) {
        message.error(t(`app.message.error.somethingWentWrong`));
        formikBag.setSubmitting(false);
      }
    },
    [createAccessCode, galleryId, t, closeModal, whereParams],
  );

  const handleEditOnSubmit = useCallback(
    async ({ values: { name, code, oldCode }, formikBag }: EditAccessFormPayload) => {
      try {
        await updateAccessCode({
          variables: {
            where: { code: oldCode, galleryId },
            data: { code, name: name ?? '' },
          },
          update(cache, { data }) {
            // We only need to manually update the cache if the key field (code) has changed
            if (code && code !== oldCode) {
              // Replace the old code reference by the new one
              const query = cache.readQuery({
                query: GET_GALLERIES_ACCESS,
                variables: { where: whereParams },
              });
              if (query && data) {
                // // Remove old one
                cache.evict({
                  id: cache.identify({
                    code: oldCode,
                    galleryId: galleryId,
                    __typename: 'AccessCode',
                  }),
                });
                cache.gc();

                // Update query with the new code using the data from the getAccessCodes query
                cache.writeQuery({
                  query: GET_GALLERIES_ACCESS,
                  variables: { where: whereParams },
                  data: {
                    getAccessCodes: {
                      ...query.getAccessCodes,
                      edges: query.getAccessCodes.edges.map(access =>
                        access.code === oldCode ? { ...access, code } : access,
                      ),
                    },
                  },
                });
              }
            }
          },
        });

        message.success(t('app.message.gallery.update.success'));
        formikBag.setSubmitting(false);
        closeModal();
      } catch (error) {
        console.log(error);
        message.error(t(`app.message.error.somethingWentWrong`));
        formikBag.setSubmitting(false);
      }
    },
    [updateAccessCode, galleryId, t, closeModal, whereParams],
  );

  const handleDeleteCode = useCallback(
    async (edge: GetAccessCodesQuery['getAccessCodes']['edges'][0]) => {
      await deleteAccessCode({
        variables: {
          where: {
            code: edge.code,
            galleryId,
          },
        },
        update(cache) {
          cache.evict({
            id: cache.identify({
              code: edge.code,
              galleryId: galleryId,
              __typename: 'AccessCode',
            }),
          });
          cache.gc();

          const query = cache.readQuery({
            query: GET_GALLERIES_ACCESS,
            variables: { where: whereParams },
          });

          if (query) {
            // Update query with the new code using the data from the getAccessCodes query
            cache.writeQuery({
              query: GET_GALLERIES_ACCESS,
              variables: { where: whereParams },
              data: {
                getAccessCodes: {
                  ...query.getAccessCodes,
                  _count: query.getAccessCodes._count - 1,
                },
              },
            });
          }
        },
      });
    },
    [deleteAccessCode, galleryId, whereParams],
  );

  const handleOpenAddModal = useCallback(() => {
    openModal('ACCESS_CODE', {
      name: 'new',
      onSubmit: handleAddGroupOnSubmit,
      defaultValues: {
        code: generateId({ size: 6 }),
        accessType: type,
        name: '',
      },
      galleryAccessPolicy,
    });
  }, [galleryAccessPolicy, handleAddGroupOnSubmit, openModal, type]);

  const handleFetchMore = useCallback(
    ({ current }: TablePaginationConfig) => {
      fetchMore({
        variables: {
          paginate: {
            page: current || 1,
            perPage: PER_PAGE,
            order: OrderType.ASC,
          },
        },
      });
    },
    [fetchMore],
  );

  return (
    <Container direction="column" block>
      <Container padding="smallMargin" justify="space-between" gap={4} align="center" block flex={1}>
        <Container gap={4} align="center">
          <Icon name="admin" size={28} color={Colors.black} />
          <SpaceTitle level="h5">
            {type === 'group'
              ? t('app.gallery.access.groupTitle', { count: 2 })
              : t('app.gallery.access.customerTitle')}
          </SpaceTitle>
        </Container>
        {type === 'group' && (
          <Container justify="flex-end" gap={4} align="center" flex={1}>
            <LabeledSwitch
              checked={isClientCodesAdminEnabled}
              label={t('app.access.update.isClientCodesAdminEnabled')}
              onCheckChanged={() => updateIsClientCodesAdminEnabled()}
            />
            <Divider type="vertical" />
            <Button key="add" type="primary" size="large" onClick={handleOpenAddModal}>
              {t('app.common.addGroup')}
            </Button>
          </Container>
        )}
      </Container>
      <Container block padding="smallMargin" flex={1}>
        <StyledDiv>
          <AccessTable
            data={accessCodesData?.getAccessCodes?.edges}
            type={type}
            galleryId={galleryId}
            isLoading={isAccessLoading}
            galleryUrl={galleryUrl}
            galleryContactId={galleryContactId}
            galleryAccessPolicy={galleryAccessPolicy}
            total={accessCodesData?.getAccessCodes?._count || 0}
            paginationSize={PER_PAGE}
            onChange={handleFetchMore}
            onEdit={handleEditOnSubmit}
            onDelete={handleDeleteCode}
          />
        </StyledDiv>
      </Container>
    </Container>
  );
};

export default AccessItem;
