import { TokenPayload } from 'types/Token';

import { decodeJwt } from 'jose';

import { AuthVar } from 'Operations/Cache';

import { MeQuery } from 'Operations/__generated__/graphql';

declare global {
  interface Window {
    $crisp: {
      push(arg: (string | boolean | string[] | number[])[]): void;
      do: (arg: string) => void;
      get: (arg: string) => unknown;
    };
    CRISP_RUNTIME_CONFIG: { locale: string };
    CRISP_WEBSITE_ID: string;
    CRISP_READY_TRIGGER: () => void;
  }
}

export const initCrispService = ({ me }: MeQuery) => {
  if (!document.getElementById('crisp-script')) {
    const token = AuthVar()?.accessToken;

    const { email, firstname, lastname } = (token ? decodeJwt(token) || {} : {}) as unknown as TokenPayload;

    window.CRISP_RUNTIME_CONFIG = {
      locale: me.locale,
    };

    window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID as string;

    window.CRISP_READY_TRIGGER = () => {
      window.$crisp.push(['config', 'color:theme', ['light_blue']]);

      window.$crisp.push(['config', 'container:index', [10]]);

      window.$crisp.push(['set', 'session:data', ['16']]);

      window.$crisp.push(['set', 'user:nickname', [`${firstname}${lastname ? ' ' + lastname : ''}`]]);

      window.$crisp.push(['set', 'user:nickname', [`${firstname} ${lastname}`]]);

      window.$crisp.push(['set', 'user:email', email]);

      if (me.company.logoUrl) {
        window.$crisp.push(['set', 'user:avatar', [me.company.logoUrl]]);
      }
      if (me.company.name) {
        window.$crisp.push(['set', 'user:company', [me.company.name]]);
      }

      window.$crisp.push(['safe', true]);
    };

    const s = document.createElement('script');

    s.src = 'https://client.crisp.chat/l.js';
    s.id = 'crisp-script';
    s.async = true;
    document.getElementsByTagName('head')[0].appendChild(s);
  }
};

export const resetCrisp = () => {
  if (!window.$crisp) {
    return;
  }
  const activeSession = window.$crisp.get('session:identifier');
  if (activeSession) {
    window.$crisp.do('session:reset');
  }
  window.$crisp.do('chat:hide');
};

export default initCrispService;
