import { gql } from 'Operations/__generated__';

export const GET_CATALOG = gql(/* GraphQL */ `
  query GetCatalog($where: IdInput!, $productsWhere: CatalogProductsWhereInput) {
    getCatalog(where: $where) {
      id
      name
      isDefault
      vat
      createdAt
      productCategories {
        id
        name
        isDigital
      }
      products(where: $productsWhere) {
        _count
      }
    }
  }
`);
