import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { AccessForm, AccessFormProps, EditAccessForm, EditAccessFormProps } from 'Forms/Access';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

interface NewAccessModalProps extends AccessFormProps {
  name: 'new';
}

interface EditAccessModalProps extends EditAccessFormProps {
  name: 'edit';
}
const AccessModal = (props: NewAccessModalProps | EditAccessModalProps) => {
  const { t } = useContext(LocalizationContext);
  const renderForm = () => {
    return props.name === 'edit' ? (
      <EditAccessForm
        onSubmit={props.onSubmit}
        defaultValues={props.defaultValues}
        galleryAccessPolicy={props.galleryAccessPolicy}
      />
    ) : (
      <AccessForm
        onSubmit={props.onSubmit}
        defaultValues={props.defaultValues}
        galleryAccessPolicy={props.galleryAccessPolicy}
      />
    );
  };

  return (
    <ModalContainer
      title={props.name === 'edit' ? t(`app.access.editAccess`) : t(`app.access.addAccess`)}
      icon={Images.locked}
    >
      {renderForm()}
    </ModalContainer>
  );
};

export default AccessModal;
