import React, { useContext } from 'react';

import { Flex, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import Tag from 'Components/Atoms/Tag';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors, Metrics } from 'Themes';
import styled, { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useCurrency } from 'Hooks/useCurrency';

import {
  GetProductFiltersQuery,
  GetProductsQuery
} from 'Operations/__generated__/graphql';

const TagStyled = styled(Tag)`
  margin-left: ${Metrics.tinyMargin}px;
`;
const ProviderContainer = styled.p`
  display: flex;
  gap: ${Metrics.smallMargin}px;
  align-items: center;
`;

export interface OnChangeProps {
  page: number;
  categoryIds?: number[];
  contactIds?: number[];
}

interface Props {
  products: GetProductsQuery['getProducts']['edges'];
  filters?: GetProductFiltersQuery['getProductFilters'];
  isLoading: boolean;
  total: number;
  paginationSize: number;
  onChange: (props: OnChangeProps) => void;
  onShow?: (props: { id: number }) => void;
  onAdd?: (props: { id: number }) => void;
  onDuplicate?: (props: { id: number }) => void;
  onDelete?: (props: { id: number }) => void;
}

const ProductsTable = ({
  products,
  filters,
  total,
  paginationSize,
  isLoading,
  onChange,
  onDelete,
  onAdd,
  onDuplicate,
  onShow,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);
  const { formatCurrency } = useCurrency();

  const columns: ColumnsType<GetProductsQuery['getProducts']['edges'][0]> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 175,
      render: (_, record) => (
        <Flex justify="space-between" gap="small">
          {onShow && (
            <Link to={`/app/products/${record.id}`}>
              <RoundButton icon="edit" tooltipTitle={t('app.common.edit')} />
            </Link>
          )}
          {onAdd && (
            <RoundButton
              icon="add"
              tooltipTitle={t('app.actions.addToMyProducts')}
              onClick={() => {
                onAdd({ id: record.id });
              }}
            />
          )}
          {onDuplicate && !record.presetProductId && (
            <Popconfirm
              title={t('app.confirm.duplicate', {
                name: record.name,
              })}
              onConfirm={() => {
                onDuplicate({ id: record.id });
              }}
            >
              <RoundButton icon="duplicate" tooltipTitle={t('app.actions.duplicate')} />
            </Popconfirm>
          )}
          {onDelete && (
            <Popconfirm
              title={
                t('app.confirm.delete') +
                (record.isIncluded || record.isMandatory ? ` ${t('app.confirm.deleteIncludedProduct')}` : '')
              }
              onConfirm={() => {
                onDelete && onDelete({ id: record.id });
              }}
              style={{ maxWidth: 300 }}
            >
              <RoundButton icon="delete" tooltipTitle={t('app.common.delete')} danger />
            </Popconfirm>
          )}
        </Flex>
      ),
    },
    {
      title: t('app.common.name'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: (_, record) => (
        <Flex align="center">
          <p>{record.name}</p>
          {record.isPreconfiguredProduct && (
            <TagStyled color={Colors.primaryMain}>{t('app.common.preconfigured')}</TagStyled>
          )}
          {record.isFotostudioProduct && <TagStyled color={Colors.crmMain}>Fotostudio</TagStyled>}
        </Flex>
      ),
    },
    {
      title: t('app.common.category'),
      dataIndex: 'category',
      key: 'category',
      filters: filters
        ? filters.categories.map(category => ({
            text: category.name,
            value: category.value,
          }))
        : [],
      onFilter: (value, record) => {
        if (value === record.category.id) {
          return true;
        }

        return false;
      },
      render: (_, record) => record.category.name,
    },
    {
      title: t('app.common.provider'),
      dataIndex: 'provider',
      key: 'provider',
      filters: filters
        ? filters.contacts.map(contact => ({
            text: contact.name,
            value: contact.value,
          }))
        : [],
      onFilter: (value, record) => {
        if (value === record.contact?.id) {
          return true;
        }

        return false;
      },
      render: (_, record) =>
        record.contact?.displayName ? (
          <ProviderContainer>
            <RoundButton
              icon="delivery"
              tooltipTitle={t('app.common.transportFee')}
              href={`/app/contacts/${record.contact.id}/transport-fees`}
            />
            {record.contact?.displayName}
          </ProviderContainer>
        ) : (
          '-'
        ),
    },
    {
      title: t('app.products.providerPrice'),
      dataIndex: 'providerPrice',
      key: 'providerPrice',
      render(providerPrice) {
        return providerPrice
          ? `${formatCurrency(providerPrice)} / ${t('app.common.unit', { count: 1 }).toLowerCase()}`
          : '-';
      },
    },
    {
      title: t('app.products.myPrice'),
      dataIndex: ['pricingPlanMeta', 'startingPrice'],
      key: 'startingPrice',
      render(startingPrice, record) {
        const count = record.productOptionCategories.reduce(
          (sum, { productOptions }) => sum + productOptions.length,
          0,
        );

        return startingPrice
          ? `${t('app.products.startingPrice', {
              price: formatCurrency(startingPrice),
            })} (${count} ${t('app.products.options', {
              count,
            }).toLowerCase()})`
          : '-';
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()}`}
      loading={isLoading}
      dataSource={products}
      columns={columns}
      scroll={{ x: true }}
      pagination={{
        total,
        pageSize: paginationSize,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      onChange={(pagination, filters) => {
        const params: OnChangeProps = {
          page: pagination.current ? pagination.current : 1,
        };

        if (filters.category) {
          params.categoryIds = filters.category as number[];
        }

        if (filters.provider) {
          params.contactIds = filters.provider as number[];
        }

        onChange(params);
      }}
    />
  );
};

export default ProductsTable;
