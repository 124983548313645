import { useContext } from 'react';

import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input, InputNumber, Switch } from 'formik-antd';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

export interface NewCatalogFormValues {
  name: string;
  vat: number;
  isDefault: boolean;
}

export interface EditCatalogFormValues extends NewCatalogFormValues {
  id: number;
}

const CatalogForm = <T extends EditCatalogFormValues | NewCatalogFormValues>({
  isSubmitting,
  values,
}: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);

  return (
    <>
      <Form layout="vertical">
        <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
          <Input name="name" placeholder={t('app.common.name')} size="large" />
        </Form.Item>
        <Form.Item label={t('app.common.vat')} name="vat" required hasFeedback={false}>
          <InputNumber
            name="vat"
            type="number"
            placeholder={t('app.common.vat')}
            size="large"
            addonAfter="%"
            min={0}
            step=".01"
          />
        </Form.Item>
        <Form.Item
          label={t('app.catalog.isDefault')}
          name="isDefault"
          className="ant-form-item--switch"
          hasFeedback={false}
        >
          <Switch name="isDefault" />
        </Form.Item>
        <Flex justify="flex-end">
          <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
            {t((values as EditCatalogFormValues).id ? 'app.common.edit' : 'app.common.add')}
          </Button>
        </Flex>
      </Form>
    </>
  );
};

export interface NewCatalogFormPayload {
  values: NewCatalogFormValues;
  formikBag: FormikBag<NewCatalogFormProps, NewCatalogFormValues>;
}

export interface NewCatalogFormProps {
  onSubmit: (payload: NewCatalogFormPayload) => void;
}

export interface EditCatalogFormPayload {
  values: EditCatalogFormValues;
  formikBag: FormikBag<EditCatalogFormProps, EditCatalogFormValues>;
}

export interface EditCatalogFormProps {
  onSubmit: (payload: EditCatalogFormPayload) => void;
  defaultValues: EditCatalogFormValues;
}

const catalogSchema = yup.object({
  name: yup.string().trim().required(),
  vat: yup.number().required(),
  isDefault: yup.boolean().required(),
});

const newCatalogSchema: yup.SchemaOf<NewCatalogFormValues> = catalogSchema.defined();

const editCatalogSchema: yup.SchemaOf<EditCatalogFormValues> = catalogSchema
  .shape({ id: yup.number().required() })
  .defined();

export const NewCatalogForm = withFormik<NewCatalogFormProps, NewCatalogFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  validationSchema: newCatalogSchema,
})(CatalogForm);

export const EditCatalogForm = withFormik<EditCatalogFormProps, EditCatalogFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: editCatalogSchema,
})(CatalogForm);
