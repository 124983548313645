import { gql } from 'Operations/__generated__';

export const GET_CONTACT_ZONES = gql(/* GraphQL */ `
  query GetContactZones($where: ContactGetWhereType!) {
    getContactZones: getContact(where: $where) {
      id
      zones {
        ...ZoneCoreFields
      }
    }
  }
`);
