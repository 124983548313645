import { gql } from 'Operations/__generated__';

export const GET_GALLERY_THEME = gql(/* GraphQL */ `
  query GetGalleryTheme($where: GalleryGetWhereType!) {
    getGallery(where: $where) {
      id
      theme {
        ...GalleryThemeCoreFields
      }
    }
  }
`);
