import { ChangeEvent, forwardRef, useCallback } from 'react';

interface FilePickerInputProps {
  multiple: boolean;
  onFilesPicked: (files: File[]) => void;
  filetypes?: string[];
}

const FilePickerInput = forwardRef<HTMLInputElement, FilePickerInputProps>(
  ({ filetypes, multiple, onFilesPicked }, ref) => {
    const onFilesSelected = useCallback(
      ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
        if (currentTarget.files) {
          const selectedFiles = Array.from(currentTarget.files).filter(
            x => x.name.toLowerCase().endsWith('.jpg') || x.name.toLowerCase().endsWith('.jpeg'),
          );

          currentTarget.value = ''; // Resets value to ensure next selection will trigger event
          if (onFilesPicked && selectedFiles.length > 0) {
            onFilesPicked(selectedFiles);
          }
        }
      },
      [onFilesPicked],
    );

    return (
      <input
        type="file"
        ref={ref}
        multiple={multiple}
        style={{ display: 'none' }}
        onChange={onFilesSelected}
        accept={filetypes ? filetypes.join(',') : '*'}
      />
    );
  },
);

export default FilePickerInput;
