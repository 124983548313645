import React, { useContext, useState } from 'react';

import { Flex } from 'antd';
import { createStyles } from 'antd-style';

import { uniq } from 'lodash';

import Title from 'Components/Atoms/Title';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { GridSize, GridSpacing } from 'Operations/__generated__/graphql';

interface GridConfigProps {
  currentGridId?: string;
  handleSelectGrid: ({ size, spacing }: { size: GridSize; spacing: GridSpacing }) => void;
}

interface GridOptionProps {
  active: boolean;
}

const grids: {
  id: string;
  spacing: GridSpacing;
  size: GridSize;
  image: string;
}[] = [
  {
    id: `${GridSpacing.SPACED}-${GridSize.LARGE}`,
    spacing: GridSpacing.SPACED,
    size: GridSize.LARGE,
    image: Images.gridSpacedLargePictures,
  },
  {
    id: `${GridSpacing.SPACED}-${GridSize.NORMAL}`,
    spacing: GridSpacing.SPACED,
    size: GridSize.NORMAL,
    image: Images.gridSpacedNormalPictures,
  },
  {
    id: `${GridSpacing.SPACED}-${GridSize.SMALL}`,
    spacing: GridSpacing.SPACED,
    size: GridSize.SMALL,
    image: Images.gridSpacedSmallPictures,
  },
  {
    id: `${GridSpacing.NORMAL}-${GridSize.LARGE}`,
    spacing: GridSpacing.NORMAL,
    size: GridSize.LARGE,
    image: Images.gridNormalLargePictures,
  },
  {
    id: `${GridSpacing.NORMAL}-${GridSize.NORMAL}`,
    spacing: GridSpacing.NORMAL,
    size: GridSize.NORMAL,
    image: Images.gridNormalNormalPictures,
  },
  {
    id: `${GridSpacing.NORMAL}-${GridSize.SMALL}`,
    spacing: GridSpacing.NORMAL,
    size: GridSize.SMALL,
    image: Images.gridNormalSmallPictures,
  },
  {
    id: `${GridSpacing.SMALL}-${GridSize.LARGE}`,
    spacing: GridSpacing.SMALL,
    size: GridSize.LARGE,
    image: Images.gridSmallLargePictures,
  },
  {
    id: `${GridSpacing.SMALL}-${GridSize.NORMAL}`,
    spacing: GridSpacing.SMALL,
    size: GridSize.NORMAL,
    image: Images.gridSmallNormalPictures,
  },
  {
    id: `${GridSpacing.SMALL}-${GridSize.SMALL}`,
    spacing: GridSpacing.SMALL,
    size: GridSize.SMALL,
    image: Images.gridSmallSmallPictures,
  },
  {
    id: `${GridSpacing.TINY}-${GridSize.LARGE}`,
    spacing: GridSpacing.TINY,
    size: GridSize.LARGE,
    image: Images.gridTinyLargePictures,
  },
  {
    id: `${GridSpacing.TINY}-${GridSize.NORMAL}`,
    spacing: GridSpacing.TINY,
    size: GridSize.NORMAL,
    image: Images.gridTinyNormalPictures,
  },
  {
    id: `${GridSpacing.TINY}-${GridSize.SMALL}`,
    spacing: GridSpacing.TINY,
    size: GridSize.SMALL,
    image: Images.gridTinySmallPictures,
  },
];

const useStyles = createStyles(({ css, token }) => ({
  sizeTitle: css`
    width: 100%;
    margin: ${token.sizeSM}px 0;
    padding: ${token.sizeXS}px 0;
    background-color: ${token.colorBorderSecondary};
    border-radius: ${token.borderRadiusSM}px;
    text-align: center;
  `,
  gridOptionContainer: css`
    flex: 1;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -${token.sizeLG / 2}px;
      width: 1px;
      display: block;
      background-color: ${token.colorBorder};
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  `,
  gridOption: css`
    width: 100%;
    height: auto;
    border-radius: ${token.borderRadiusSM}px;
    border: 2px solid rgba(255, 255, 255, 0);
    cursor: pointer;

    &:hover,
    &:active,
    &.active {
      border-color: ${token.colorPrimary};
    }
  `,
}));

const GridConfig = ({ currentGridId, handleSelectGrid }: GridConfigProps) => {
  const { t } = useContext(LocalizationContext);
  const { styles, cx } = useStyles();

  const [selectedGrid, setSelectedGrid] = useState(currentGridId);

  const handleGridOptionClick = ({ id, size, spacing }: { id: string; size: GridSize; spacing: GridSpacing }) => {
    handleSelectGrid({ size, spacing });
    setSelectedGrid(id);
  };

  return (
    <Flex vertical>
      <Flex gap="large">
        {uniq(grids.map(g => g.spacing.toLowerCase())).map(gridCat => (
          <Flex flex={1}>
            <Title key={gridCat} level="h5">
              {t(`app.gallery.appearance.spacing.${gridCat}`)}
            </Title>
          </Flex>
        ))}
      </Flex>
      {uniq(grids.map(g => g.size)).map(sizeCat => {
        const gridOptions = grids.filter(g => g.size === sizeCat);

        return (
          <React.Fragment key={sizeCat}>
            <p className={styles.sizeTitle}>{t(`app.gallery.appearance.size.${sizeCat.toLowerCase()}`)}</p>
            <Flex gap="large">
              {gridOptions.map(({ id, image, spacing, size }) => (
                <div className={styles.gridOptionContainer} key={id}>
                  <img
                    src={image}
                    className={cx(styles.gridOption, selectedGrid === id && 'active')}
                    alt={`${spacing.toLowerCase()} ${size.toLowerCase()} grid option`}
                    onClick={() => handleGridOptionClick({ id, spacing, size })}
                  />
                </div>
              ))}
            </Flex>
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

export default GridConfig;
