import React, { useContext } from 'react';

import { Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { isArray } from 'lodash';

import Container from 'Components/Atoms/Container';
import Tag from 'Components/Atoms/Tag';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors } from 'Themes';
import { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';

import { EmailTemplateOrderType } from 'Operations/__generated__/graphql';

export interface EmailTemplateTableData {
  id: number;
  name: string;
  type: 'fotostudio' | 'yours';
  title: string;
  createdAt: string;
}

export interface handleOnChangeProps {
  page: number;
  perPage: number;
  order: EmailTemplateOrderType;
}

interface IdProps {
  id: number;
}

interface Props {
  emailTemplatesTotal: number;
  loading: boolean;
  paginationCurrent: number;
  paginationSize?: number;
  data?: EmailTemplateTableData[];
  handleOnChange: (params: handleOnChangeProps) => void;
  handleEditClick?: (params: IdProps) => void;
  handleDeleteConfirm?: (params: IdProps) => void;
}

const EmailTemplateTable = ({
  loading,
  emailTemplatesTotal,
  data,
  paginationCurrent,
  paginationSize = 20,
  handleOnChange,
  handleEditClick,
  handleDeleteConfirm,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);

  const columns: ColumnsType<EmailTemplateTableData> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 120,
      render: (_, { id, type }) => (
        <Container justify="space-between">
          <RoundButton
            icon="edit"
            tooltipTitle={t('app.common.edit')}
            onClick={() => {
              if (handleEditClick) {
                handleEditClick({ id });
              }
            }}
          />
          {type === 'yours' && (
            <Popconfirm
              title={t('app.confirm.delete')}
              onConfirm={() => {
                if (handleDeleteConfirm) {
                  handleDeleteConfirm({ id });
                }
              }}
            >
              <RoundButton icon="delete" tooltipTitle={t('app.common.delete')} danger />
            </Popconfirm>
          )}
        </Container>
      ),
    },
    {
      title: t('app.common.type'),
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (
        <Tag color={record.type === 'yours' ? Colors.success : Colors.crmMain}>
          {record.type === 'yours' ? t('app.emailTemplate.yours').toLocaleLowerCase() : record.type}
        </Tag>
      ),
    },
    {
      title: t('app.common.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('app.common.title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('app.common.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      render: text => dayjs(text).format('LLL'),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: true }}
      loading={loading}
      pagination={{
        total: emailTemplatesTotal,
        pageSize: paginationSize,
        current: paginationCurrent,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()}`}
      onChange={(pagination, filters, sorter) => {
        let order: EmailTemplateOrderType = EmailTemplateOrderType.DESC;

        if (isArray(sorter)) {
          order = sorter[0].order === 'ascend' ? EmailTemplateOrderType.ASC : EmailTemplateOrderType.DESC;
        } else {
          order = sorter.order === 'ascend' ? EmailTemplateOrderType.ASC : EmailTemplateOrderType.DESC;
        }

        handleOnChange({
          page: pagination.current ? pagination.current : 1,
          perPage: pagination.pageSize ? pagination.pageSize : 20,
          order,
        });
      }}
    />
  );
};

export default EmailTemplateTable;
