import React, { useContext } from "react";

import Container from "Components/Atoms/Container";

import LabeledSwitch from "Components/Molecules/LabeledSwitch";

import { LocalizationContext } from "i18n";

import { GalleryDownload } from "Operations/__generated__/graphql";

export interface GalleryDownloadSelectProps {
  defaultValue: GalleryDownload;
  onChange?: (value: GalleryDownload) => void;
  isVertical?: boolean;
}

const getGalleryDownloadValue = (hdIncluded: boolean, webIncluded: boolean) => {
  if (hdIncluded && webIncluded) {
    return GalleryDownload.HD_WEB;
  } else if (hdIncluded) {
    return GalleryDownload.HD;
  }
  return GalleryDownload.WEB;
};

const GalleryDownloadSelect = ({ defaultValue, onChange, isVertical }: GalleryDownloadSelectProps) => {
  const { t } = useContext(LocalizationContext);

  const isIncludeHdEnabled = defaultValue === GalleryDownload.HD || defaultValue === GalleryDownload.HD_WEB;
  const isIncludeWebEnabled = defaultValue === GalleryDownload.WEB || defaultValue === GalleryDownload.HD_WEB;

  const onIncludeHdChanged = async (check: boolean) => {
    let isWebEnabled = isIncludeWebEnabled;
    if (check === false && isWebEnabled === false) {
      // Hd and web can't be false at the same time
      isWebEnabled = true;
    }

    if (onChange) {
      onChange(getGalleryDownloadValue(check, isWebEnabled));
    }
  };

  const onIncludeWebChanged = async (check: boolean) => {
    let isHdEnabled = isIncludeHdEnabled;
    if (check === false && isHdEnabled === false) {
      // Hd and web can't be false at the same time
      isHdEnabled = true;
    }

    if (onChange) {
      onChange(getGalleryDownloadValue(isHdEnabled, check));
    }
  };

  return (
    <Container direction={isVertical ? 'column' : 'row'} justify={isVertical ? 'center' : 'space-between'}>
      <LabeledSwitch
        checked={isIncludeHdEnabled}
        label={t('app.gallery.settings.download.files.hd')}
        onCheckChanged={onIncludeHdChanged}
      />
      <LabeledSwitch
        checked={isIncludeWebEnabled}
        label={t('app.gallery.settings.download.files.web')}
        onCheckChanged={onIncludeWebChanged}
      />
    </Container>
  );
};

export default GalleryDownloadSelect;
