import { ReactiveVar } from '@apollo/client';

import { AuthVar, UserConfigVar } from 'Operations/Cache';

const STAGE = process.env.REACT_APP_STAGE;

// AsyncStorage keys
export const REACTIVE_VARIABLES_STORAGE_KEYS = {
  AUTH: '@fotostudio:AUTH',
  CONFIG: '@fotostudio:CONFIG',
};

const persist = async (reactiveVar: ReactiveVar<any>, key: keyof typeof REACTIVE_VARIABLES_STORAGE_KEYS) => {
  const previousValue = await window.localStorage.getItem(REACTIVE_VARIABLES_STORAGE_KEYS[key]);

  if (previousValue) {
    if (STAGE === 'DEV') {
      console.log({ key, previousValue: JSON.parse(previousValue) });
    }
    reactiveVar(JSON.parse(previousValue));
  }
};

// Persists
export const persistReactiveVariables = async () => {
  await persist(AuthVar, 'AUTH');
  await persist(UserConfigVar, 'CONFIG');
};
