import { gql } from 'Operations/__generated__';

export const GET_LAST_GALLERIES = gql(/* GraphQL */ `
  query GetLastGalleries($where: GalleriesGetWhereType!) {
    getGalleries(where: $where) {
      _count
      edges {
        id
        name
        seenAt
      }
    }
  }
`);
