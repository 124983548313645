import { useCallback, useMemo } from 'react';

import { makeReference, useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { ReplaceWithReference } from 'types/ModelConnection';

import { getKeyIdentifier } from 'Operations/Cache/Policies/Gallery';

import { GalleryAdmin, PhotoCharacteristic } from 'Operations/__generated__/graphql';

import { GET_PHOTOS_COUNT } from 'Operations/Queries/Photo/GetPhotosCount';

interface Props {
  galleryId?: number;
  folderId?: number | null;
  characteristics?: PhotoCharacteristic[] | null;
  fetchPolicy?: WatchQueryFetchPolicy;
  queryName?: string;
  skip?: boolean;
  isRoot?: boolean;
}

export const usePhotosCount = ({
  galleryId,
  folderId,
  characteristics,
  queryName = 'getPhotos',
  fetchPolicy = 'cache-and-network',
  skip,
  isRoot = true,
}: Props) => {
  const {
    data,
    loading: isLoading,
    client,
  } = useQuery(GET_PHOTOS_COUNT, {
    skip: skip || !galleryId,
    fetchPolicy,
    nextFetchPolicy: 'cache-first',
    returnPartialData: true,
    variables: {
      where: {
        galleryId: galleryId as number,
        characteristics,
        folderId,
      },
    },
  });

  const keyIdentifier = useMemo(
    () =>
      getKeyIdentifier({
        galleryId: isRoot ? galleryId : undefined,
        characteristics,
        folderId,
        queryName,
      }) as 'photos',
    [characteristics, folderId, galleryId, isRoot, queryName],
  );

  const updatePhotosCount = useCallback(
    (count: number) => {
      client.cache.modify<ReplaceWithReference<GalleryAdmin, 'photos'>>({
        id: client.cache.identify(makeReference(isRoot ? 'ROOT_QUERY' : `GalleryAdmin:${galleryId}`)),
        fields: {
          [keyIdentifier](existing) {
            if ('_count' in existing) {
              return {
                ...existing,
                _count: (existing?._count || 0) + count,
              };
            }
            return existing;
          },
        },
      });
    },
    [client.cache, keyIdentifier, isRoot, galleryId],
  );

  return {
    count: data && data?.getPhotos?._count,
    isLoading,
    updatePhotosCount,
    keyIdentifier,
  };
};
