import React from 'react';

import MenuLeftColumn, { MenuLeftColumnLink } from 'Components/Molecules/MenuLeftColumn';
import { Props as MenuLinkProps } from 'Components/Molecules/MenuLink';
import MenuRightColumn from 'Components/Molecules/MenuRightColumn';

import styled from 'Themes/Styled';

interface MenuProps {
  menuLinks: MenuLeftColumnLink[][];
  rightContentLinks: MenuLinkProps[];
}

const StyledMenu = styled.section`
  display: flex;
  flex-direction: row;
  z-index: 1;
`;

const Menu = ({ menuLinks, rightContentLinks }: MenuProps) => {
  return (
    <StyledMenu>
      {<MenuLeftColumn links={menuLinks} />}
      {<MenuRightColumn links={rightContentLinks} />}
    </StyledMenu>
  );
};

export default Menu;
