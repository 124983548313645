import React, { CSSProperties, forwardRef, SyntheticEvent, useContext } from 'react';

import { DeepPartial } from '@apollo/client/utilities';
import { Badge, Tooltip } from 'antd';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { AssetAdmin } from 'Operations/__generated__/graphql';

interface PhotoProps {
  id: string | number;
  name?: string;
  asset: DeepPartial<AssetAdmin>;
  isLiked?: boolean;
  isOrdered?: boolean;
  isPhotoSelected?: boolean;
  unreadCommentsCount?: number;
  handleOnClickImage?: (params: { photoId: number }) => void;
  handleOnSelectPhoto?: (params: { photoId: number }) => void;
  ref?: (node: HTMLElement | null) => void;
  croppedUrl?: string;
  dataDraggable?: boolean;
  style?: CSSProperties;
  size?: 'small' | 'medium';
}

const ImageContainer = styled.div<{
  size?: 'small' | 'medium';
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => {
    switch (size) {
      case 'small':
        return '120px';
      default:
        return '144px';
    }
  }};
  height: 144px;
  margin: ${Metrics.smallMargin}px;
`;

const ThumbContainer = styled.div<{
  canClick: boolean;
}>`
  height: 100%;
  display: block;
  position: relative;
  cursor: ${({ canClick }) => (canClick ? 'pointer' : 'normal')}; ;
`;

const ImageContent = styled.img`
  display: block;
  width: 100%;
  height: auto;

  &.ImageCard__Image--portrait {
    width: auto;
    height: 100%;
  }
`;

const ImageOverlay = styled.div<{ active: boolean }>`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: ${({ active }) => (active ? '1' : '0')};

  transition: all 100ms ease-out;

  &:hover {
    opacity: 1;
  }
`;

const ImageName = styled(Text)`
  z-index: 1000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  position: absolute;
  left: 0;
  right: 0;
  top: 102%;

  background-color: rgba(255, 255, 255, 0.6);
`;

const StyledBadge = styled(Badge)<{ count: number }>`
  max-height: 100%;
`;

const IconsContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
`;

const Photo = forwardRef<HTMLDivElement, PhotoProps>(
  (
    {
      id,
      name,
      asset,
      isPhotoSelected,
      isLiked,
      isOrdered,
      unreadCommentsCount = 0,
      croppedUrl,
      handleOnClickImage,
      handleOnSelectPhoto,
      ...props
    },
    ref,
  ) => {
    const { t } = useContext(LocalizationContext);
    const photoId = typeof id === 'string' ? parseInt(id) : id;

    const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
      if (e.currentTarget.naturalWidth < e.currentTarget.naturalHeight) {
        e.currentTarget.classList.add('ImageCard__Image--portrait');

        if (e?.currentTarget?.parentElement?.parentElement?.classList) {
          e.currentTarget.parentElement.parentElement.classList.add('ImageCard--portrait');
        }
      } else {
        e.currentTarget.classList.add('ImageCard__Image--landscape');
      }
    };
    const isHDMissing = !asset.assetMain;

    return (
      <ImageContainer className="ImageCard" data-id={id} ref={ref} {...props} data-disableselect>
        <StyledBadge
          count={unreadCommentsCount}
          title={t('app.common.comment', { count: unreadCommentsCount }).toLowerCase()}
        >
          <ThumbContainer canClick={!!handleOnSelectPhoto}>
            <ImageContent
              className="ImageCard__Image"
              src={croppedUrl ?? asset.noWmThumbSmall?.downloadUrl}
              onLoad={onImageLoad}
              onChange={onImageLoad}
            />
            {(handleOnSelectPhoto || name) && (
              <ImageOverlay
                className="ImageCard__ImageOverlay"
                active={!!isPhotoSelected}
                onClick={() => handleOnClickImage?.({ photoId })}
              >
                <Container flex={1} align="flex-end" justify="flex-end" gap={Metrics.tinyMargin} padding="tinyMargin">
                  {asset.assetMain?.downloadUrl && (
                    <RoundButton
                      href={asset.assetMain.downloadUrl}
                      onClick={e => e.stopPropagation()}
                      target="_blank"
                      icon="download"
                      size="small"
                    />
                  )}
                  {handleOnSelectPhoto && (
                    <RoundButton
                      onClick={e => {
                        e.stopPropagation();
                        handleOnSelectPhoto({ photoId });
                      }}
                      icon="check"
                      size="small"
                      type={isPhotoSelected ? 'primary' : 'default'}
                    />
                  )}
                </Container>
                {name && (
                  <ImageName size="small" align="center" title={name}>
                    {name}
                  </ImageName>
                )}
              </ImageOverlay>
            )}

            <IconsContainer>
              {isLiked && <Icon name="heart" fill={Colors.danger} stroke={Colors.white} strokeWidth={2} />}
              {isOrdered && <Icon name="purchased" color={Colors.black} />}
              {isHDMissing && (
                <Tooltip
                  title={t('app.gallery.photos.missingHD.content')}
                  overlayInnerStyle={{ textAlign: 'center' }}
                  color={Colors.danger}
                >
                  <Icon name="warning" color={Colors.danger} />
                </Tooltip>
              )}
            </IconsContainer>
          </ThumbContainer>
        </StyledBadge>
      </ImageContainer>
    );
  },
);

export default Photo;
