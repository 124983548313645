import React, { useContext } from 'react';

import { Drawer, Empty, Spin } from 'antd';

import InfiniteScroll from 'react-infinite-scroll-component';

import { camelCase } from 'lodash';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Title from 'Components/Atoms/Title';

import NotificationCard from 'Components/Molecules/NotificationCard';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import getNotificationIcon from 'Helpers/getNotificationIcon';

import { GetNotificationsQuery } from 'Operations/__generated__/graphql';

interface Props {
  isVisible: boolean;
  notifications: GetNotificationsQuery['getNotifications']['edges'];
  onCloseDrawer: () => void;
  loadMore: () => void;
  hasMore: boolean;
}

const DrawerContainer = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: ${Metrics.smallMargin}px 0;
`;

const GALLERY_URL_PATH = '/app/galleries/';

const NotificationDrawer = ({ isVisible, onCloseDrawer, notifications, hasMore, loadMore }: Props) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Drawer
      open={isVisible}
      title={<Title level="h2">{t('app.common.notification', { count: 2 })}</Title>}
      placement="left"
      onClose={onCloseDrawer}
      width={Metrics.drawer.notification}
      closeIcon={<Icon name="close" />}
    >
      <DrawerContainer id="NotificationDrawer">
        {notifications.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        <InfiniteScroll
          dataLength={notifications.length}
          next={loadMore}
          loader={
            <Container justify="center">
              <Spin />
            </Container>
          }
          hasMore={hasMore}
          scrollableTarget="NotificationDrawer"
        >
          {notifications.map(notification => (
            <NotificationCard
              key={notification.id}
              icon={getNotificationIcon(notification.action)}
              content={t(`app.notification.${camelCase(notification.action)}.description`, {
                galleryUrl: '#',
                ...notification.meta,
                ...(notification.gallery
                  ? {
                      gallery: notification.gallery.name,
                      galleryUrl: GALLERY_URL_PATH + notification.gallery.id,
                    }
                  : {}),
              })}
              date={notification.createdAt}
              isRead={notification.isRead}
              onCloseDrawer={onCloseDrawer}
            />
          ))}
        </InfiniteScroll>
      </DrawerContainer>
    </Drawer>
  );
};

export default NotificationDrawer;
