import { createStyles } from 'antd-style';

export const useRadioStyle = createStyles(({ css, prefixCls, token, stylish }) => ({
  className: css`
    &.${prefixCls}-radio {
      &-button-wrapper {
        &:before {
          width: ${token.lineWidth}px;
        }
      }
    }
  `,
}));
