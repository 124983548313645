import { FieldFunctionOptions } from '@apollo/client';

import i18n from 'i18n';

import { StrictTypedTypePolicies } from 'Operations/__generated__/apollo-helpers';

import { ME } from 'Operations/Queries/User/Me';

const getFormattedPrice = ({
  price,
  quantity,
  hasMultiplePrices,
  options: { cache },
}: {
  price: number;
  quantity: number;
  hasMultiplePrices: boolean;
  options: FieldFunctionOptions;
}) => {
  const meData = cache.readQuery({ query: ME });

  const { symbol } = meData?.me.currency || {};

  return price && symbol
    ? `${
        hasMultiplePrices
          ? i18n.t('app.products.startingPrice', {
              price: `${price} ${symbol}`,
            })
          : `${price} ${symbol}`
      } ${
        quantity
          ? quantity > 1
            ? i18n.t('app.products.quantity', { quantity })
            : `/ ${i18n.t('app.common.unit', { count: 1 }).toLowerCase()}`
          : ''
      }`
    : '';
};

const PricingPlanMetaPolicy: StrictTypedTypePolicies['PricingPlanMeta'] = {
  fields: {
    formattedPrice: {
      read(_, options) {
        const startingPrice = options.readField<number>('startingPrice') || 0;
        const startingQuantity = options.readField<number>('startingQuantity') || 1;
        const hasMultiplePrices = !!options.readField<boolean>('hasMultiplePrices');

        return getFormattedPrice({
          price: startingPrice,
          quantity: startingQuantity,
          hasMultiplePrices,
          options,
        });
      },
    },
    formattedGrossPrice: {
      read(_, options) {
        const grossPrice = options.readField<number>('grossPrice') || 0;
        const grossQuantity = options.readField<number>('grossQuantity') || 1;
        const hasMultipleGrossPrices = !!options.readField<boolean>('hasMultipleGrossPrices');
        return getFormattedPrice({
          price: grossPrice,
          quantity: grossQuantity,
          hasMultiplePrices: hasMultipleGrossPrices,
          options,
        });
      },
    },
  },
};

export default PricingPlanMetaPolicy;
