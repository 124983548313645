import React, { useContext, useState } from 'react';

import { Button } from 'antd';
import { createStyles } from 'antd-style';

import { Select as $Select, SelectProps } from 'formik-antd';
import useDebouncedEffect from 'use-debounced-effect-hook';

import { LocalizationContext } from 'i18n';

interface Props extends SelectProps {
  loadMore?: () => void;
  hasFetchAll?: boolean;
  name: string;
  onSearch?: (value: string) => void | Promise<unknown>;
}

const useStyles = createStyles(({ css, token }) => ({
  loadMoreContainer: css`
    display: flex;
    justify-content: center;
    padding: ${token.sizeSM}px;
  `,
}));

const Select = ({ loadMore, hasFetchAll, onSearch, ...props }: Props) => {
  const { t } = useContext(LocalizationContext);
  const { styles } = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>('');

  useDebouncedEffect(
    () => {
      if (onSearch) {
        onSearch(searchTerm || '');
      }
    },
    [searchTerm, onSearch],
    500,
  );

  return (
    <$Select
      searchValue={searchTerm}
      onSearch={setSearchTerm}
      showSearch
      dropdownRender={menu => (
        <>
          {menu}
          {loadMore && !hasFetchAll && (
            <div className={styles.loadMoreContainer}>
              <Button disabled={props.loading} size="small" onClick={loadMore}>
                {t('app.common.loadMore')}
              </Button>
            </div>
          )}
        </>
      )}
      {...props}
      loading={props.loading}
    />
  );
};

Select.Option = $Select.Option;
Select.OptGroup = $Select.OptGroup;

export default Select;
