import { Reference } from '@apollo/client';

import { StrictTypedTypePolicies } from 'Operations/__generated__/apollo-helpers';

import GalleryPolicy from './Gallery';

const PicklistPolicy: StrictTypedTypePolicies['Picklist'] = {
  fields: {
    photos: GalleryPolicy?.fields?.photos ? GalleryPolicy.fields.photos : {},
    contactAccessCode: {
      read(existing, { readField }) {
        const accessCodes = readField<Reference[]>('accessCodes');

        return accessCodes?.find(ref => !!readField('contactId', ref) && !readField('accessRight', ref));
      },
    },
    groupAccessCodes: {
      read(existing, { readField }) {
        const accessCodes = readField<Reference[]>('accessCodes');

        return accessCodes?.filter(ref => !readField('contactId', ref));
      },
    },
  },
};

export default PicklistPolicy;
