import { useContext } from 'react';

import { Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { isArray } from 'lodash';

import Container from 'Components/Atoms/Container';
import Text from 'Components/Atoms/Text';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors } from 'Themes';
import { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';

import {
  GalleriesGetWhereType,
  GalleryOrderType,
  GalleryStatus,
  GetGalleriesQuery,
} from 'Operations/__generated__/graphql';

export interface ContactType {
  id: number;
  name: string;
  color: string;
}

interface IdProps {
  id: number;
}

export interface PutBackProps extends IdProps {
  status: GalleryStatus;
}

interface Props {
  galleriesTotal: number;
  loading: boolean;
  paginationCurrent: number;
  paginationSize?: number;
  data?: GetGalleriesQuery['getPaginatedGalleries']['edges'];
  handleOnChange: (params: GalleriesGetWhereType) => void;
  handlePutBack?: (params: PutBackProps) => void;
  handleHardDeleteConfirm?: (params: IdProps) => void;
}

const GalleryDeleteTable = ({
  loading,
  galleriesTotal,
  data,
  paginationCurrent,
  paginationSize = 20,
  handleOnChange,
  handlePutBack,
  handleHardDeleteConfirm,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);

  const columns: ColumnsType<GetGalleriesQuery['getPaginatedGalleries']['edges'][0]> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 120,
      render: (_, record) => (
        <Container justify="space-between">
          <Popconfirm
            title={t('app.confirm.putBack')}
            okType="default"
            okText={t('app.menu.lastGalleries')}
            onConfirm={() => {
              if (handlePutBack) {
                handlePutBack({ id: record.id, status: GalleryStatus.OFFLINE });
              }
            }}
            cancelText={t('app.menu.archived')}
            onCancel={() => {
              if (handlePutBack) {
                handlePutBack({ id: record.id, status: GalleryStatus.ARCHIVED });
              }
            }}
          >
            <RoundButton icon="put-back" tooltipTitle={t('app.action.move')} />
          </Popconfirm>
          <Popconfirm
            title={t('app.confirm.delete')}
            onConfirm={() => {
              if (handleHardDeleteConfirm) {
                handleHardDeleteConfirm({ id: record.id });
              }
            }}
          >
            <RoundButton icon="delete" tooltipTitle={t('app.common.deletePermanently')} danger />
          </Popconfirm>
        </Container>
      ),
    },
    {
      title: t('app.common.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('app.common.contact'),
      dataIndex: 'contact',
      key: 'contact',
      render: (_, record) => record?.contact?.displayName,
    },
    {
      title: t('app.common.size'),
      dataIndex: 'usedSpace',
      key: 'usedSpace',
      render: text => `${text} GB`,
    },
    {
      title: t('app.common.deletedIn'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'ascend',
      sorter: (a, b) => dayjs(a.updatedAt).unix() - dayjs(b.updatedAt).unix(),
      render: text => {
        const deleteAt = dayjs(text).add(30, 'days');
        const count = deleteAt.diff(dayjs(), 'days');
        return (
          <Text
            size="medium"
            weight={count <= 5 ? 'bold' : 'regular'}
            color={count <= 5 ? Colors.danger : Colors.tableBlack}
          >
            {t('app.common.deletedInValue', { count })}
          </Text>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: true }}
      loading={loading}
      pagination={{
        total: galleriesTotal,
        pageSize: paginationSize,
        current: paginationCurrent,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()}`}
      onChange={(pagination, filters, sorter) => {
        let order: GalleryOrderType = GalleryOrderType.ASC;

        if (isArray(sorter)) {
          order = sorter[0].order === 'ascend' ? GalleryOrderType.ASC : GalleryOrderType.DESC;
        } else {
          order = sorter.order === 'ascend' ? GalleryOrderType.ASC : GalleryOrderType.DESC;
        }

        const params: GalleriesGetWhereType = {
          page: pagination.current ? pagination.current : 1,
          perPage: pagination.pageSize ? pagination.pageSize : 20,
          order,
        };

        handleOnChange(params);
      }}
    />
  );
};

export default GalleryDeleteTable;
