import { gql } from 'Operations/__generated__';

export const UPDATE_STRIPE_PAYMENT = gql(/* GraphQL */ `
  mutation UpdateStripePayment($data: UserStripePaymentInputType!) {
    updateStripePayment(data: $data) {
      id
      stripePayment {
        status
      }
    }
  }
`);
