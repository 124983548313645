import { HTMLAttributes } from 'react';

import { createStyles } from 'antd-style';

import { kebabCase } from 'lodash';

import Icon from 'Components/Atoms/Icon';

import { Colors } from 'Themes';

export type Status = 'Waiting' | 'InProgress' | 'Succeeded' | 'Failed';

interface TaskStatusProps extends HTMLAttributes<HTMLDivElement> {
  status?: Status;
}

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    background: transparent;
    border-radius: 7px;

    &.waiting {
      border: 2px solid ${token.colorWarning};
    }

    &.in-progress {
      border: 2px dashed ${token.colorWarning};

      -webkit-animation: spin 4s linear infinite;
      -moz-animation: spin 4s linear infinite;
      animation: spin 4s linear infinite;
    }

    &.succeeded {
      background-color: ${token.colorSuccess};
    }

    &.failed {
      background-color: ${token.colorError};
    }
  `,
}));

const TaskStatus = ({ status, ...props }: TaskStatusProps) => {
  const { styles, cx } = useStyles();

  return (
    <div className={cx(styles.container, kebabCase(status))} {...props}>
      {status === 'Succeeded' && <Icon name="check" size={10} color={Colors.white} />}
      {status === 'Failed' && <Icon name="close" size={10} color={Colors.white} />}
    </div>
  );
};

export default TaskStatus;
