import { gql } from 'Operations/__generated__';

export const DISABLE_MOLLIE_PAYMENT = gql(/* GraphQL */ `
  mutation DisableMolliePayment {
    disableMolliePayment {
      id
      molliePayment {
        status
      }
    }
  }
`);
