import { gql } from 'Operations/__generated__';

export const DELETE_ACCESS_CODE = gql(/* GraphQL */ `
  mutation DeleteAccessCode($where: AccessCodeWhereType!) {
    deleteAccessCode(where: $where) {
      code
      galleryId
    }
  }
`);
