import { useCallback } from 'react';

import { ColorPicker as ColorPickerAnt, ColorPickerProps, InputProps } from 'antd';
import { createStyles } from 'antd-style';
import { Color } from 'antd/es/color-picker';
import { ColorValueType } from 'antd/es/color-picker/interface';

import clsx from 'clsx';

import { FieldHookConfig, useField } from 'formik';

const useStyles = createStyles(({ css, token }) => ({
  colorPicker: css`
    width: 100%;
    justify-content: flex-start;

    .ant-color-picker-trigger-text {
      font-size: 14px !important;
    }
  `,
}));

const ColorPicker = (props: InputProps & FieldHookConfig<ColorValueType> & ColorPickerProps) => {
  const { styles } = useStyles();
  const [field, _meta, helpers] = useField(props);

  const handleChange = useCallback(
    (newColor: Color) => {
      helpers.setValue(newColor);
    },
    [helpers, props.defaultFormat, props.disabledAlpha],
  );

  return (
    <ColorPickerAnt
      {...props}
      {...field}
      className={clsx(props.className, styles.colorPicker)}
      onChange={handleChange}
    />
  );
};

export default ColorPicker;
