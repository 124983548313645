import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import { Button, Flex, Switch as AntSwitch } from 'antd';
import { Color } from 'antd/es/color-picker';
import { ColorFactory } from 'antd/es/color-picker/color';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input, Select, Slider, Switch } from 'formik-antd';
import { camelCase } from 'lodash';

import ColorPicker from 'Components/Molecules/Form/ColorPicker';
import FileSelect from 'Components/Molecules/Form/FileSelect';
import WatermarkPreview from 'Components/Molecules/WatermarkPreview';

import { Colors, Metrics } from 'Themes';
import { FONTS } from 'Themes/Fonts';
import styled from 'Themes/Styled';

import i18n, { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

import { Position, Repeat, WatermarkType } from 'Operations/__generated__/graphql';

export interface NewWatermarkFormValues {
  file?: Blob;
  name: string;
  size: number;
  repeat: Repeat;
  position: Position;
  opacity: number;
  watermarkType: WatermarkType;
  font?: string;
  text?: string;
  textColor?: Color;
  withStroke: boolean;
  strokeColor?: string;
  isResized: boolean;
  withBackgroundText: boolean;
  backgroundTextColor?: Color;
}

export interface EditWatermarkFormValues extends NewWatermarkFormValues {
  id: number;
  fileUrl?: string;
}

const PreviewContainer = styled(Flex)`
  margin-bottom: ${Metrics.baseMargin}px;
`;

const WatermarkPreviewStyled = styled(WatermarkPreview)`
  margin-right: ${Metrics.smallMargin}px;
`;

const WatermarkForm = <T extends EditWatermarkFormValues | NewWatermarkFormValues>({
  isSubmitting,
  values,
  setFieldValue,
  getFieldProps,
}: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);
  const { onChange: onChangeRepeat } = getFieldProps('repeat');

  const [image, setImage] = useState<string>();

  useEffect(() => {
    if (values.watermarkType === WatermarkType.TEXT) {
      setImage(undefined);
      setFieldValue('file', undefined);
    }
  }, [values.watermarkType, setFieldValue]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleTypeChange = (checked: boolean) => {
    const val = checked ? WatermarkType.TEXT : WatermarkType.IMAGE;
    setFieldValue('watermarkType', val);
  };

  const handleRepeatChange = (checked: boolean) => {
    onChangeRepeat(checked);
    setFieldValue('position', Position.CENTER);
  };

  const renderRepeatValues = () => {
    const repeats = Object.values(Repeat);
    const repeatsValue = [];

    for (const repeat of repeats) {
      repeatsValue.push(
        <Select.Option key={repeat} value={repeat} title={''}>
          {t(`app.watermark.repeat.${camelCase(repeat.toLowerCase())}`)}
        </Select.Option>,
      );
    }

    return repeatsValue;
  };

  const renderPositionValues = () => {
    const positions = Object.keys(Position);
    const positionsValue = [
      <Select.Option key={Position.CENTER} value={Position.CENTER} title={''}>
        {t('app.watermark.position.center')}
      </Select.Option>,
    ];

    for (const position of positions) {
      // Skip center, option's always present
      if (position === Position.CENTER) {
        continue;
      }

      // TOP && BOTTOM options
      if (
        values.watermarkType === WatermarkType.IMAGE &&
        values.repeat === Repeat.HORIZONTAL &&
        (position === Position.TOP || position === Position.BOTTOM)
      ) {
        positionsValue.push(
          <Select.Option key={position} value={position} title={''}>
            {t(`app.watermark.position.${camelCase(position.toLowerCase())}`)}
          </Select.Option>,
        );
        continue;
      }

      // LEFT && RIGHT options
      if (
        values.watermarkType === WatermarkType.IMAGE &&
        values.repeat === Repeat.VERTICAL &&
        (position === Position.LEFT || position === Position.RIGHT)
      ) {
        positionsValue.push(
          <Select.Option key={position} value={position} title={''}>
            {t(`app.watermark.position.${camelCase(position.toLowerCase())}`)}
          </Select.Option>,
        );
        continue;
      }

      // Other options
      if (
        (values.watermarkType === WatermarkType.TEXT ||
          (values.watermarkType === WatermarkType.IMAGE && values.repeat === Repeat.NO_REPEAT)) &&
        position !== Position.TOP &&
        position !== Position.BOTTOM
      ) {
        positionsValue.push(
          <Select.Option key={position} value={position} title={''}>
            {t(`app.watermark.position.${camelCase(position.toLowerCase())}`)}
          </Select.Option>,
        );
      }
    }
    return positionsValue;
  };

  const imageToDraw =
    values.watermarkType === WatermarkType.TEXT ? undefined : image || (values as EditWatermarkFormValues)?.fileUrl;
  return (
    <Form layout="vertical">
      <Flex gap="middle" align="center">
        <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
          <Input name="name" placeholder={t('app.common.name')} size="large" />
        </Form.Item>
        <Form.Item
          name="watermarkType"
          label={t('app.watermark.type.label')}
          className="ant-form-item--switch"
          hasFeedback={false}
        >
          <AntSwitch
            style={values.watermarkType === WatermarkType.IMAGE ? { backgroundColor: Colors.primaryMain } : undefined}
            checkedChildren={t('app.watermark.type.text')}
            unCheckedChildren={t('app.watermark.type.image')}
            onChange={handleTypeChange}
            checked={values.watermarkType === WatermarkType.TEXT}
          />
        </Form.Item>
      </Flex>
      <PreviewContainer align="center">
        <WatermarkPreviewStyled
          watermarkType={values.watermarkType}
          image={imageToDraw}
          font={values.font}
          text={values.text}
          textColor={values.textColor?.toHexString()}
          strokeColor={values.strokeColor}
          backgroundTextColor={values.backgroundTextColor?.toHexString()}
          isResized={values.isResized}
          size={values.size}
          repeat={values.repeat || undefined}
          position={values.position}
          opacity={values.opacity}
          withStroke={values.withStroke}
          withBackgroundText={values.withBackgroundText}
        />
        {values.watermarkType === WatermarkType.IMAGE && (
          <Form.Item
            name="file"
            label={t('app.common.watermark')}
            required={
              !(values as EditWatermarkFormValues).id ||
              (values.watermarkType === WatermarkType.IMAGE &&
                (values as EditWatermarkFormValues).fileUrl === undefined)
            }
            hasFeedback={false}
          >
            <FileSelect name="file" accept="image/png, image/jpeg, image/svg+xml" handleOnChange={handleFileChange} />
          </Form.Item>
        )}
        {values.watermarkType === WatermarkType.TEXT && (
          <Form.Item name="text" label={t('app.watermark.text')} required hasFeedback={false}>
            <Input name="text" size="large" />
          </Form.Item>
        )}
      </PreviewContainer>
      {values.watermarkType === WatermarkType.TEXT && (
        <Form.Item label={t('app.watermark.font.label')} name="font" required hasFeedback={false}>
          <Select name="font" size="large" getPopupContainer={trigger => trigger.parentNode}>
            {FONTS.map(font => (
              <Select.Option value={font} title={''}>
                <span style={{ fontFamily: font }}>{font}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Flex gap="middle">
        {values.watermarkType === WatermarkType.IMAGE && (
          <Form.Item
            name="isResized"
            label={t('app.watermark.isResized.label')}
            className="ant-form-item--switch"
            hasFeedback={false}
          >
            <Switch name="isResized" />
          </Form.Item>
        )}
        {((values.watermarkType === WatermarkType.IMAGE && values.isResized) ||
          values.watermarkType === WatermarkType.TEXT) && (
          <Form.Item name="size" label={`${t('app.watermark.size')} (%)`} required hasFeedback={false}>
            <Slider name="size" min={5} step={5} />
          </Form.Item>
        )}
        <Form.Item label={t('app.watermark.opacity')} name="opacity" required hasFeedback={false}>
          <Slider name="opacity" min={0.1} max={1} step={0.1} />
        </Form.Item>
      </Flex>
      <Flex gap="middle">
        {values.watermarkType === WatermarkType.IMAGE && (
          <Form.Item name="repeat" label={`${t('app.watermark.repeat.label')}`} required hasFeedback={false}>
            <Select
              name="repeat"
              size="large"
              onChange={handleRepeatChange}
              getPopupContainer={trigger => trigger.parentNode}
            >
              {renderRepeatValues()}
            </Select>
          </Form.Item>
        )}
        {values.repeat !== Repeat.ALL && (
          <Form.Item name="position" label={`${t('app.watermark.position.label')}`} required hasFeedback={false}>
            <Select name="position" size="large" getPopupContainer={trigger => trigger.parentNode}>
              {renderPositionValues()}
            </Select>
          </Form.Item>
        )}
        {values.watermarkType === WatermarkType.TEXT && (
          <Form.Item name="textColor" label={`${t('app.watermark.textColor')}`} required hasFeedback={false}>
            <ColorPicker name="textColor" size="large" disabledAlpha showText />
          </Form.Item>
        )}
      </Flex>
      {values.watermarkType === WatermarkType.TEXT && (
        <Flex gap="middle">
          <Form.Item
            name="withBackgroundText"
            label={t('app.watermark.withBackgroundText.label')}
            className="ant-form-item--switch"
            hasFeedback={false}
          >
            <Switch name="withBackgroundText" />
          </Form.Item>
          {values.withBackgroundText && (
            <Form.Item
              name="backgroundTextColor"
              label={`${t('app.watermark.backgroundTextColor.label')}`}
              required
              hasFeedback={false}
            >
              <ColorPicker name="backgroundTextColor" size="large" showText />
            </Form.Item>
          )}
        </Flex>
      )}
      {values.watermarkType === WatermarkType.TEXT && (
        <Flex gap="middle">
          <Form.Item
            name="withStroke"
            label={t('app.watermark.withStroke.label')}
            className="ant-form-item--switch"
            hasFeedback={false}
          >
            <Switch name="withStroke" />
          </Form.Item>
          {values.withStroke && (
            <Form.Item label={t('app.watermark.strokeColor.label')} name="strokeColor" required hasFeedback={false}>
              <Select name="strokeColor" size="large" getPopupContainer={trigger => trigger.parentNode}>
                <Select.Option value="0,0,0,1" title={''}>
                  {t(`app.watermark.strokeColor.black`)}
                </Select.Option>
                <Select.Option value="255,255,255,1" title={''}>
                  {t(`app.watermark.strokeColor.white`)}
                </Select.Option>
              </Select>
            </Form.Item>
          )}
        </Flex>
      )}
      <Flex gap="middle" justify="flex-end">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t((values as EditWatermarkFormValues).id ? 'app.common.edit' : 'app.common.add')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface NewWatermarkFormPayload {
  values: NewWatermarkFormValues;
  formikBag: FormikBag<NewWatermarkFormProps, NewWatermarkFormValues>;
}

export interface NewWatermarkFormProps {
  onSubmit: (payload: NewWatermarkFormPayload) => void;
}

export interface EditWatermarkFormPayload {
  values: EditWatermarkFormValues;
  formikBag: FormikBag<EditWatermarkFormProps, EditWatermarkFormValues>;
}

export interface EditWatermarkFormProps {
  onSubmit: (payload: EditWatermarkFormPayload) => void;
  defaultValues: EditWatermarkFormValues;
}

const watermarkSchema = yup.object({
  name: yup.string().trim().required(),
  isResized: yup.boolean().required(),
  size: yup.number().min(1).max(100).required(),
  file: yup
    .mixed()
    .when('watermarkType', {
      is: WatermarkType.IMAGE,
      then: yup.mixed().required(),
      otherwise: undefined,
    })
    .test(
      'file',
      () => i18n.t('app.form.errors.fileToLarge', { limit: '500KB' }),
      value => {
        return value ? value.size <= 500000 : true;
      },
    ),
  repeat: yup.mixed().when('watermarkType', {
    is: WatermarkType.IMAGE,
    then: yup.mixed().required(),
    otherwise: undefined,
  }),
  position: yup.mixed().required(),
  opacity: yup.number().required(),
  watermarkType: yup.mixed<WatermarkType>().required(),
  font: yup.string().trim().when('watermarkType', {
    is: WatermarkType.TEXT,
    then: yup.string().required(),
    otherwise: undefined,
  }),
  text: yup.string().trim().when('watermarkType', {
    is: WatermarkType.TEXT,
    then: yup.string().required(),
    otherwise: undefined,
  }),
  textColor: yup.object<any>().when('watermarkType', {
    is: WatermarkType.TEXT,
    then: yup.object<any>().required(),
    otherwise: undefined,
  }),
  withStroke: yup.boolean().required(),
  strokeColor: yup.string().trim().when('withStroke', {
    is: true,
    then: yup.string().required(),
    otherwise: undefined,
  }),
  withBackgroundText: yup.boolean().required(),
  backgroundTextColor: yup.object<any>().when('withBackgroundText', {
    is: true,
    then: yup.object<any>().required(),
    otherwise: undefined,
  }),
});

const editWatermarkSchema: yup.SchemaOf<EditWatermarkFormValues> = watermarkSchema
  .shape({
    id: yup.number().required(),
    fileUrl: yup.string(),
    file: yup
      .mixed()
      .when(['watermarkType', 'fileUrl'], {
        is: (watermarkType: WatermarkType, fileUrl: string) =>
          watermarkType === WatermarkType.IMAGE && fileUrl === undefined,
        then: yup.mixed().required(),
        otherwise: undefined,
      })
      .test(
        'file',
        () => i18n.t('app.form.errors.fileToLarge', { limit: '500KB' }),
        value => {
          return value ? value.size <= 500000 : true;
        },
      ),
  })
  .defined();

export const NewWatermarkForm = withFormik<NewWatermarkFormProps, NewWatermarkFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: () => ({
    watermarkType: WatermarkType.IMAGE,
    name: '',
    file: undefined,
    isResized: true,
    size: 50,
    repeat: Repeat.NO_REPEAT,
    position: Position.CENTER,
    opacity: 1,
    font: 'Fira Sans',
    withStroke: false,
    withBackgroundText: false,
  }),
  validationSchema: watermarkSchema,
})(WatermarkForm);

export const EditWatermarkForm = withFormik<EditWatermarkFormProps, EditWatermarkFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({ file: undefined, ...defaultValues }),
  validationSchema: editWatermarkSchema,
})(WatermarkForm);
