import { createStyles } from 'antd-style';

export const useTreeStyle = createStyles(({ css, prefixCls, token }) => ({
  className: css`
    &.${prefixCls}-tree {
      width: 100%;
      display: flex;
      align-items: center;
      &--hide-drag-icon {
        .${prefixCls}-tree-draggable-icon {
          display: none;
        }
      }
      .${prefixCls}-tree-list {
        width: 100%;
      }
      .${prefixCls}-tree-indent-unit {
        width: 20px;
      }
      .${prefixCls}-tree-switcher {
        display: none;
      }
      .${prefixCls}-tree-treenode {
        &:hover {
          &:before {
            display: none;
          }
        }
        &-selected {
          border-right: 1px solid ${token.colorPrimary};
          .${prefixCls}-tree-title {
            background-color: ${token.colorPrimaryBg};
            .tree-item-actions {
              display: block;
            }
            &:hover {
              background-color: ${token.colorPrimaryBg};
            }
          }
        }
      }
      .${prefixCls}-tree-title {
        width: 100%;
        display: block;
        padding: ${token.sizeXS}px;
        transition: all ease 150ms;
        border-radius: ${token.borderRadius}px;

        p {
          flex: 1;
          display: -webkit-box;
          line-clamp: 1;
          -webkit-line-clamp: 1;
          box-orient: vertical;
          -webkit-box-orient: vertical;
          -moz-box-orient: vertical;
          overflow: hidden;
          margin: 0;
        }
        .tree-item-actions {
          display: none;
        }

        &:hover {
          background-color: ${token.colorFillTertiary};
          .tree-item-actions {
            display: block;
          }
        }
      }
    }
  `,
}));
