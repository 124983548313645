import React, { useCallback, useContext, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, List, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from 'Components/Atoms/Layout';
import StatusTag from 'Components/Atoms/StatusTag';

import FeatureRequestItem from 'Components/Molecules/FeatureRequestItem';
import Header from 'Components/Molecules/Header';

import { OnSubmitFeatureRequestPayload } from 'Forms/FeatureRequest';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useFeatureRequestCategoriesMenu } from 'Hooks/useFeatureRequestCategoriesMenu';

import { FeatureOrderBy, FeatureStatus, OrderType } from 'Operations/__generated__/graphql';

import { GET_FEATURE_REQUESTS } from 'Operations/Queries/FeatureRequest/GetFeatureRequests';

import { CREATE_FEATURE_REQUEST } from 'Operations/Mutations/FeatureRequest/CreateFeatureRequest';

const PER_PAGE = 50;

const whereCondition = {
  order: OrderType.DESC,
  orderBy: FeatureOrderBy.VOTES,
  page: 1,
  perPage: PER_PAGE,
};

const statuses = [
  FeatureStatus.OPEN,
  FeatureStatus.PLANNED,
  FeatureStatus.IN_PROGRESS,
  FeatureStatus.LIVE,
  FeatureStatus.CLOSED,
];

const FeatureRequestsList = () => {
  const { t } = useContext(LocalizationContext);
  const { openModal, closeModal } = useModals();
  const navigate = useNavigate();
  const { category } = useParams<{ category: string }>();
  useFeatureRequestCategoriesMenu();

  const categoryId = category ? parseInt(category, 10) : undefined;

  const [selectedStatus, setSelectedStatus] = useState<FeatureStatus | undefined>(undefined);

  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery(GET_FEATURE_REQUESTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: {
        ...whereCondition,
        status: selectedStatus,
        categoryId,
      },
    },
  });

  const [createFeatureRequest] = useMutation(CREATE_FEATURE_REQUEST);

  const handleSubmit = useCallback(
    async ({ values, formikBag }: OnSubmitFeatureRequestPayload) => {
      try {
        if (values.categoryId) {
          const res = await createFeatureRequest({
            variables: {
              data: {
                title: values.title,
                description: values.description,
                categoryId: values.categoryId,
              },
            },
          });

          formikBag.setSubmitting(false);
          closeModal('FEATURE_REQUEST');

          navigate(`/app/feature-requests/${res.data?.createFeatureRequest.id}`);
        } else {
          throw new Error('Category is required');
        }
      } catch (error) {
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, createFeatureRequest, navigate],
  );

  const handleFetchMore = useCallback(
    (page: number) => {
      fetchMore({
        variables: {
          where: {
            ...whereCondition,
            status: selectedStatus,
            categoryId,
            page,
          },
        },
      });
    },
    [fetchMore, categoryId, selectedStatus],
  );

  return (
    <>
      <Header
        title={t('app.common.featureRequest.list')}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.featureRequest.list') },
        ]}
        buttons={[
          <Select
            key="status"
            defaultValue={selectedStatus || 0}
            style={{
              minWidth: 200,
            }}
            onChange={(newStatus: FeatureStatus | number) =>
              setSelectedStatus(!newStatus ? undefined : (newStatus as FeatureStatus))
            }
            size="large"
          >
            <Select.Option value={0}>{t('app.featureRequest.status.all')}</Select.Option>
            {statuses.map(status => (
              <Select.Option key={status} value={status}>
                <StatusTag status={status} />
              </Select.Option>
            ))}
          </Select>,
          <Button
            key="add"
            type="primary"
            size="large"
            onClick={() =>
              openModal('FEATURE_REQUEST', {
                onSubmit: handleSubmit,
              })
            }
          >
            {t('app.common.add')}
          </Button>,
        ]}
      />
      <Layout flexDirection="column">
        <List
          itemLayout="vertical"
          dataSource={data?.getFeatureRequests.edges || []}
          loading={isLoading}
          renderItem={item => <FeatureRequestItem {...item} showComments showStatus />}
          pagination={{
            pageSize: PER_PAGE,
            total: data?.getFeatureRequests._count || 0,
            onChange: handleFetchMore,
            showSizeChanger: false,
            style: {
              textAlign: 'center',
            },
          }}
        />
      </Layout>
    </>
  );
};

export default FeatureRequestsList;
