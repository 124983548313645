import React, { useCallback, useContext, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Alert, App, Button, Spin } from 'antd';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Layout from 'Components/Atoms/Layout';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import Header from 'Components/Molecules/Header';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { ME } from 'Operations/Queries/User/Me';

const LayoutStyled = styled(Layout)`
  align-items: flex-start;
`;

const ContainerStyled = styled(Container)`
  margin-top: ${Metrics.baseMargin}px;
`;

const LeftContainer = styled(ContentContainer)`
  width: 70%;
  margin-top: ${Metrics.baseMargin}px;
  margin-right: ${Metrics.baseMargin}px;
`;

const RightContain = styled(ContentContainer)`
  flex: 1;
  margin-top: ${Metrics.baseMargin}px;
`;

const SpinContainer = styled(Container)`
  width: 100%;
  height: 300px;
`;

const AlertStyled = styled(Alert)`
  margin-top: ${Metrics.baseMargin}px;
`;

const Iframe = styled.iframe`
  border: 0;
`;

const TextStyled = styled(Text)`
  margin-top: ${Metrics.mediumMargin}px;
`;

const IframeCodeArea = styled.textarea`
  margin-top: ${Metrics.mediumMargin}px;
  padding: ${Metrics.mediumMargin}px;
  border: 1px solid ${Colors.grey};
  background-color: ${Colors.lightGrey};
  border-radius: ${Metrics.boxRadius}px;
  min-height: 100px;
  resize: none;
`;

const GalleriesPortfolio = () => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();

  const { data: currentUser, loading: isLoading } = useQuery(ME, {
    fetchPolicy: 'cache-first',
  });

  const iframeContent = useMemo(
    () =>
      `<iframe width="100%" height="900" src="${process.env.REACT_APP_CRM_URL}/${currentUser?.me?.company.bookingUrl}/galleries" style="border:0;"></iframe>`,
    [currentUser?.me?.company.bookingUrl],
  );

  const copyToClipboard = useCallback(
    ({ value }: { value: string }) => {
      navigator.clipboard.writeText(value);
      message.success(t('app.common.clipboard.copy'));
    },
    [t],
  );

  if (isLoading) {
    return (
      <SpinContainer justify="center" align="center">
        <Spin size="large" />
      </SpinContainer>
    );
  }

  return (
    <>
      <Header
        title={t('app.common.portfolio')}
        breadcrumbContent={[{ text: t('app.menu.home'), url: '/app/dashboard' }, { text: t('app.common.portfolio') }]}
      />

      <AlertStyled message={t('app.portfolio.addInfo')} />

      <LayoutStyled>
        <LeftContainer padding="baseMargin" shadow rounded>
          <Iframe
            width="100%"
            height="900"
            src={`${process.env.REACT_APP_CRM_URL}/${currentUser?.me?.company.bookingUrl}/galleries`}
          />
        </LeftContainer>
        <RightContain padding="baseMargin" shadow rounded>
          <Title level="h4">{t('app.portfolio.addToWebsite.title')}</Title>
          <TextStyled>{t('app.portfolio.addToWebsite.description')}</TextStyled>
          <IframeCodeArea disabled>{iframeContent}</IframeCodeArea>
          <ContainerStyled align="flex-end" justify="flex-end">
            <Button type="primary" onClick={() => copyToClipboard({ value: iframeContent })}>
              {t('app.common.copyCode')}
            </Button>
          </ContainerStyled>
        </RightContain>
      </LayoutStyled>
    </>
  );
};

export default GalleriesPortfolio;
