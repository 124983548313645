import { AuthVar } from 'Operations/Cache';
import { REACTIVE_VARIABLES_STORAGE_KEYS } from 'Operations/Cache/reactiveVariables';

const initCreateAuth = (authVar: typeof AuthVar) => {
  return ({
    isLoggedIn,
    isSupport,
    accessToken,
    refreshToken,
  }: {
    isLoggedIn: boolean;
    isSupport: boolean;
    accessToken?: string;
    refreshToken?: string;
  }) => {
    const result = { isLoggedIn, isSupport, accessToken, refreshToken };
    authVar({ ...result });

    window.localStorage.setItem(REACTIVE_VARIABLES_STORAGE_KEYS.AUTH, JSON.stringify(result));

    return result;
  };
};

const createAuth = initCreateAuth(AuthVar);

export default createAuth;
