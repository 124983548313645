import React from 'react';

import { Divider, Tooltip } from 'antd';
import { createStyles, useResponsive } from 'antd-style';
import { NavLink } from 'react-router-dom';

import Icon, { IconName } from 'Components/Atoms/Icon';
import Tag from 'Components/Atoms/Tag';

export interface Props {
  href?: string;
  icon?: IconName;
  count?: number;
  countColor?: string;
  text?: string;
  textParams?: { [key: string]: string | number };
  rightIcon?: IconName;
  active?: boolean;
  collapse?: boolean;
  isSeparator?: boolean;
  className?: string;
}

const useStyles = createStyles(({ token, css, cx, responsive }) => {
  const text = cx(css`
    flex: 1;
    color: ${token.menuRightButtonColor};
    font-family: 'Fira Sans', sans-serif;
    font-weight: ${token.fontWeightStrong};
    font-size: ${token.fontSize}px;
    overflow-x: clip;
    transition: all ease 1000ms;

    margin: 0;
    opacity: 1;

    ${responsive.lg} {
      display: none;
    }
    &.collapsed {
      display: none;
    }
  `);

  const arrowIcon = cx(css`
    opacity: 0;
    ${responsive.lg} {
      display: none;
    }
    &.collapsed {
      display: none;
    }
  `);

  return {
    nav: css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: ${token.sizeSM}px;

      padding: ${token.paddingSM}px;
      border-radius: ${token.borderRadiusRounded}px;

      background: transparent;
      color: ${token.colorTextSecondary};

      text-decoration: none;
      transition: all 0.3s ease;

      &:hover,
      &:active,
      &.active {
        background: ${token.menuRightButtonBackgroundColor};
        color: ${token.colorText};

        .${text} {
          color: ${token.colorText};
        }
      }
      &.active {
        .${arrowIcon} {
          opacity: 1;
        }
      }
    `,
    text,
    arrowIcon,
    divider: css`
      margin: 0;
    `,
  };
});

const MenuLink = ({
  href = '',
  text,
  icon,
  active,
  rightIcon,
  count,
  countColor,
  collapse,
  className,
  isSeparator,
  ...props
}: Props) => {
  const { styles, cx } = useStyles();
  const { xl } = useResponsive();

  return isSeparator ? (
    <Divider className={styles.divider} />
  ) : (
    <Tooltip title={!xl || collapse ? text : undefined} placement="right">
      <NavLink to={href} {...props} className={cx(className, styles.nav)} end={!active}>
        {icon && <Icon name={icon} size={20} />}
        {count && (
          <Tag color={countColor} border="small">
            {count}
          </Tag>
        )}
        <p className={cx(styles.text, collapse && 'collapsed')}>{text}</p>
        {rightIcon && <Icon name={rightIcon} size={20} className={cx(styles.arrowIcon, collapse && 'collapsed')} />}
      </NavLink>
    </Tooltip>
  );
};

export default MenuLink;
