import { gql } from 'Operations/__generated__';

export const DELETE_FOLDER = gql(/* GraphQL */ `
  mutation DeleteFolder($where: FolderDeleteWhereType!) {
    deleteFolder(where: $where) {
      id
      name
      parentId
    }
  }
`);
