import React, { useContext } from 'react';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Card from 'Components/Atoms/Card';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const CardStyled = styled(Card)`
  background-color: #f0fffd;
  border: 1px solid #a5faf6;
  min-height: auto !important;
`;

const CardContent = styled.div`
  padding: ${Metrics.baseMargin}px;
`;

const TextStyled = styled(Text)`
  margin-top: ${Metrics.mediumMargin}px;
`;

const InfoCard = ({ width }: { width?: number | string }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <CardStyled shadow rounded width={width}>
      <CardContent>
        <Title level="h3">{t('app.dashboard.info.title')}</Title>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          children={t('app.dashboard.info.subtitle')}
          components={{
            p: ({ children }) => <Title level="h6">{children}</Title>,
            b: ({ children }) => <strong>{children}</strong>,
            a: ({ children, ...props }) => (
              <a href={props.href} target="_blank">
                {children}
              </a>
            ),
          }}
        />
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          children={t('app.dashboard.info.text')}
          components={{
            p: ({ children }) => <TextStyled size="medium">{children}</TextStyled>,
            b: ({ children }) => <strong>{children}</strong>,
          }}
        />
      </CardContent>
    </CardStyled>
  );
};

export default InfoCard;
