import React, { useContext, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { camelCase, compact, groupBy, map, uniqBy } from 'lodash';

import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useCurrency } from 'Hooks/useCurrency';
import { useRoles } from 'Hooks/useRoles';

import { OrderItemHandler } from 'Operations/__generated__/graphql';

import { GET_ORDER } from 'Operations/Queries/Order/GetOrder';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid black;
`;

const Thead = styled.thead`
  background-color: #eaeaea !important;
  border-bottom: 1px solid black !important;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
`;

const Tfoot = styled.tfoot`
  background-color: #eaeaea !important;
  border-top: 1px solid black !important;
  text-align: center;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
`;

const Th = styled.th`
  padding: 2px 6px;
  font-size: 0.75em;
  font-weight: bold;
  border: 1px solid black;
`;

const ThQuantity = styled(Th)`
  width: 70px;
`;

const Td = styled.td`
  padding: 2px 6px;
  font-size: 0.75em;
  border: 1px solid black;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  gap: 2em;
`;

const Container = styled.div`
  padding: 24px;
`;

const InfoContainer = styled.div`
  padding: 12px;
  border: 1px solid black;
`;

const OrderTitle = styled.h1`
  font-size: 1.25em;
  font-weight: bold;
`;

const InfoTitle = styled.h2`
  font-size: 1em;
`;

const ProductName = styled.h3`
  font-size: 1.25em;
  font-weight: bold;
`;

const InfoName = styled.p`
  font-size: 1.25em;
  font-weight: bold;
`;

const ProductContainer = styled.div`
  margin-top: 24px;
`;

const PrinterRules = styled.section`
  margin-top: 24px;
  font-size: 0.75em;
`;

const SlipControlOrder = () => {
  const { t } = useContext(LocalizationContext);
  const { orderId } = useParams<{ orderId: string }>();
  const { isLabo, isAdmin } = useRoles();
  const { formatCurrency } = useCurrency();
  const id = orderId?.length && parseInt(orderId);

  const { data } = useQuery(GET_ORDER, {
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        id: id as number,
      },
    },
  });

  const order = data?.getOrder.__typename === 'OrderAdmin' ? data.getOrder : undefined;

  const isHandledByIncognito = useMemo(() => {
    return (isLabo || isAdmin) && order?.handlers.includes(OrderItemHandler.INCOGNITO);
  }, [isAdmin, isLabo, order?.handlers]);

  const isPhotographerMustReceiveOrder = useMemo(
    () => order?.photographerMustReceiveOrder && order?.orderedByFotostudio,
    [order?.orderedByFotostudio, order?.photographerMustReceiveOrder],
  );

  const packages = useMemo(() => {
    if (!order || !order?.items) {
      return [];
    }

    return compact(
      uniqBy(
        order.items.map(item => item.package),
        'id',
      ),
    );
  }, [order]);

  if (!order) {
    return null;
  }

  const products = groupBy(order.items, item => item.productName);

  return (
    <Container>
      <OrderTitle>Ref. : {order.id}</OrderTitle>
      <Header>
        {!isPhotographerMustReceiveOrder && (
          <InfoContainer>
            <InfoTitle>{t('app.common.deliveryAddress')}</InfoTitle>
            <InfoName>
              {order.firstName} {order.lastName}
            </InfoName>
            <p>{order.street}</p>
            <p>
              {order.zipCode} {order.city}
            </p>
            <p>{order.country}</p>
            <p>{order.email}</p>
          </InfoContainer>
        )}
        {order.seller && isPhotographerMustReceiveOrder && (
          <InfoContainer>
            <InfoTitle>{t('app.common.deliveryAddress')}</InfoTitle>
            <InfoName>{order.seller.companyName}</InfoName>
            <p>{order.seller.street}</p>
            <p>
              {order.seller.zipcode} {order.seller.city}
            </p>
            <p>{order.seller.country}</p>
            <p>{order.seller.email}</p>
          </InfoContainer>
        )}
        {order.seller && (
          <InfoContainer>
            <InfoTitle>{t('app.common.photographer')} :</InfoTitle>
            <InfoName>{order.seller.companyName}</InfoName>
            <p>{order.seller.street}</p>
            <p>
              {order.seller.zipcode} {order.seller.city}
            </p>
            <p>{order.seller.country}</p>
          </InfoContainer>
        )}
        <InfoContainer>
          <InfoTitle>{t('app.common.information')} :</InfoTitle>
          <p>
            <strong>{t('app.common.productsWeight')}</strong>: {order.weight} g
          </p>
          <p>
            <strong>{t('app.common.transportFee')}</strong> : {formatCurrency(order.transportFee)}
          </p>
          {isHandledByIncognito &&
            packages.map(pack => (
              <div>
                <p key={pack.id}>
                  <strong>{t(`app.contact.transportFees.carrier.${camelCase(pack.carrier)}`)}</strong> :{' '}
                  {formatCurrency(pack.totalExclVat)} {t('app.contact.transportFees.vat.excluded')}
                </p>
                <ul>
                  <li>
                    {t('app.common.transportFee')}: {formatCurrency(pack.laboTransportFeeExclVat)}
                  </li>
                  <li>
                    {t('app.product.packages.title')}: {formatCurrency(pack.packagePriceExclVat)} ({pack.weight} g)
                  </li>
                </ul>
              </div>
            ))}
        </InfoContainer>
      </Header>
      {isHandledByIncognito && (
        <PrinterRules>
          <p>{t('slipControl.incognito.optionsRemainder')}</p>
          <ul>
            <li>{t('slipControl.incognito.option1')};</li>
            <li>{t('slipControl.incognito.option2')};</li>
            <li>{t('slipControl.incognito.option3')};</li>
            <li>{t('slipControl.incognito.option4')};</li>
            <li>{t('slipControl.incognito.option5')}.</li>
          </ul>
        </PrinterRules>
      )}
      {map(products, (items, key) => {
        const options = items[0].options;
        return (
          <ProductContainer key={key} className="avoid-break-inside">
            <ProductName>
              {t('app.common.products', { count: 1 })} : {key}{' '}
              {items[0]?.providerName &&
                !isHandledByIncognito &&
                `(${t('app.common.provider').toLowerCase()}: ${items[0].providerName})`}
            </ProductName>
            <Table>
              <Thead>
                <tr>
                  <ThQuantity>{t('app.common.quantity')}</ThQuantity>
                  <Th>{t('app.common.photo', { count: 1 })}</Th>
                  {!items[0].isDigital && options.map(option => <Th key={option.id}>{option.optionCategoryName}</Th>)}
                  {isHandledByIncognito && <Th>{t('app.common.crop')}</Th>}
                </tr>
              </Thead>
              <tbody>
                {items.map(item => (
                  <tr key={item.id}>
                    <Td>{item.requestQuantity}x</Td>
                    <Td>
                      {item.photos.edges.map(photo => (
                        <p>
                          {!item.isDigital
                            ? `item${item.id.toString().padStart(6, '0')}-order${order.id
                                .toString()
                                .padStart(6, '0')}_${photo.name}`
                            : photo.name}
                        </p>
                      ))}
                    </Td>
                    {!items[0].isDigital &&
                      item.options.map(option => <Td key={option.id}>{option.providerDescription || option.name}</Td>)}
                    {isHandledByIncognito && <Td>{t('slipControl.incognito.fullSize')}</Td>}
                  </tr>
                ))}
              </tbody>
              <Tfoot>
                <tr>
                  <Td colSpan={(isHandledByIncognito ? 3 : 2) + (items[0].isDigital ? 0 : options.length)}>
                    {items.reduce((acc, item) => acc + (item.requestQuantity || 0), 0)}x {key}
                    {isHandledByIncognito && items[0].laboPriceExclVat
                      ? ` - ${formatCurrency(
                          items.reduce((acc, item) => acc + (item.requestQuantity || 0), 0) * items[0].laboPriceExclVat,
                        )} ${t('app.contact.transportFees.vat.excluded')}`
                      : null}
                  </Td>
                </tr>
              </Tfoot>
            </Table>
          </ProductContainer>
        );
      })}
    </Container>
  );
};

export default SlipControlOrder;
