import React, { HTMLAttributes, useContext } from 'react';

import { DeepPartial } from '@apollo/client/utilities';
import { Card, Tag } from 'antd';

import clsx from 'clsx';

import Container from 'Components/Atoms/Container';
import Text from 'Components/Atoms/Text';

import InfoItem from 'Components/Molecules/InfoItem';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { OrderItemProductOption } from 'Operations/__generated__/graphql';

const MyTag = styled(Tag)`
  margin-left: ${Metrics.tinyMargin}px;
`;

const OptionTitle = styled(Text)`
  margin-top: ${Metrics.tinyMargin}px;
`;

interface ProductInfoCardProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  options: DeepPartial<OrderItemProductOption>[];
  quantity: number;
}

const { Meta } = Card;

const ProductInfoCard = ({ name, options, quantity, className, ...props }: ProductInfoCardProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Card bordered={false} className={clsx(className, 'ant-card--shadow')} {...props}>
      <Meta
        title={
          <Container justify="flex-start">
            <p className="ant-card-meta-title">{name}</p>
            <MyTag color={Colors.secondaryMain}>{t('app.common.products', { count: 1 })}</MyTag>
          </Container>
        }
        description={
          <>
            <InfoItem label={t('app.common.quantity')} text={quantity.toString()} />
            {options.map((option, index) => {
              return (
                <div key={option.name}>
                  <OptionTitle color={Colors.grey}>{`${t('app.orders.productOptions', { count: 1 })} #${
                    index + 1
                  }`}</OptionTitle>
                  <InfoItem label={option.optionCategoryName || '-'} text={option.name} />
                </div>
              );
            })}
          </>
        }
      />
    </Card>
  );
};

export default ProductInfoCard;
