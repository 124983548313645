import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import {
  EditEmailTemplateForm,
  EditEmailTemplateFormProps,
  NewEmailTemplateForm,
  NewEmailTemplateFormProps,
} from 'Forms/EmailTemplate';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { EmailTemplateAssociatedModel } from 'Operations/__generated__/graphql';

interface NewEmailTemplateModalProps extends NewEmailTemplateFormProps {
  name: 'new';
}
interface EditEmailTemplateModalProps extends EditEmailTemplateFormProps {
  name: 'edit';
}

const EmailTemplateModal = (props: NewEmailTemplateModalProps | EditEmailTemplateModalProps) => {
  const { t } = useContext(LocalizationContext);
  const renderForm = () => {
    if (props.name === 'edit') {
      return (
        <EditEmailTemplateForm
          onSubmit={props.onSubmit}
          defaultValues={props.defaultValues}
          associatedModel={props.associatedModel}
        />
      );
    }

    return <NewEmailTemplateForm onSubmit={props.onSubmit} associatedModel={EmailTemplateAssociatedModel.CUSTOM} />;
  };

  return (
    <ModalContainer title={t(`app.emailTemplate.modal.${props.name}`)} icon={Images.email}>
      {renderForm()}
    </ModalContainer>
  );
};

export default EmailTemplateModal;
