import React, { useContext } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Divider, Flex, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { isArray } from 'lodash';

import Icon from 'Components/Atoms/Icon';
import Tag from 'Components/Atoms/Tag';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import SearchFilter from 'Components/Molecules/SearchFilter';

import { Colors } from 'Themes';
import { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useCurrentUser } from 'Hooks/useCurrentUser';

import dayjs from 'Services/DayjsService';

import { ContactCoreFieldsFragment, OrderType } from 'Operations/__generated__/graphql';

export interface ContactType {
  id: number;
  name: string;
  color: string;
}

export interface handleOnChangeProps {
  page: number;
  perPage: number;
  contactTypes?: string;
  order: OrderType;
  search?: string;
}

interface IdProps {
  id: number;
}

interface Props {
  contactsTotal?: number | null;
  loading: boolean;
  paginationSize?: number;
  data?: ContactCoreFieldsFragment[];
  contactTypes?: ContactType[];
  handleOnChange: (params: handleOnChangeProps) => void;
  handleEditClick?: (params: IdProps) => void;
  handleEditTransportFees?: (params: IdProps) => void;
  handleDeleteConfirm?: (params: IdProps) => void;
}
const ContactTable = ({
  loading,
  contactsTotal = 0,
  data,
  contactTypes,
  paginationSize = 20,
  handleOnChange,
  handleEditClick,
  handleEditTransportFees,
  handleDeleteConfirm,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);

  const { currentUser } = useCurrentUser();

  const columns: ColumnsType<ContactCoreFieldsFragment> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 180,
      render: (_, record) => (
        <Flex justify="space-between" align="center">
          <Flex justify="flex-start" gap="small">
            <RoundButton
              icon="edit"
              tooltipTitle={t('app.common.edit')}
              onClick={() => {
                handleEditClick?.({ id: record.id });
              }}
            />
            <Popconfirm
              title={t('app.confirm.delete')}
              onConfirm={() => {
                handleDeleteConfirm?.({ id: record.id });
              }}
            >
              <RoundButton icon="delete" tooltipTitle={t('app.common.delete')} danger />
            </Popconfirm>
          </Flex>
          {record.contactType.id === currentUser?.me.providerTypeId && (
            <>
              <Divider type="vertical" />
              <RoundButton
                icon="delivery"
                tooltipTitle={t('app.common.transportFee')}
                onClick={() => {
                  handleEditTransportFees?.({ id: record.id });
                }}
              />
            </>
          )}
        </Flex>
      ),
    },
    {
      title: t('app.common.type'),
      dataIndex: 'contactType',
      key: 'contactType',
      filters: contactTypes?.map(contactType => ({
        text: contactType.name,
        value: contactType.id,
      })),
      onFilter: (value, record) => {
        if (value === record.contactType.id) {
          return true;
        }

        return false;
      },
      render: (_, record) => <Tag color={record.contactType.color}>{record.contactType.name}</Tag>,
    },
    {
      title: t('app.common.contact'),
      dataIndex: 'displayName',
      key: 'displayName',
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? Colors.primaryMain : undefined }} />,
      filterDropdown: props => {
        return <SearchFilter {...props} />;
      },
      render(displayName, record, index) {
        return (
          <Flex align="center" gap="middle">
            {record.favorite && <Icon name="star" color={Colors.secondaryOptional} size={20} />}
            <span>{displayName}</span>
          </Flex>
        );
      },
    },
    {
      title: t('app.common.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      render: text => dayjs(text).format('LLL'),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: true }}
      loading={loading}
      pagination={{
        total: contactsTotal || 0,
        pageSize: paginationSize,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()}`}
      onChange={(pagination, filters, sorter) => {
        let order: OrderType = OrderType.DESC;

        if (isArray(sorter)) {
          order = sorter[0].order === 'ascend' ? OrderType.ASC : OrderType.DESC;
        } else {
          order = sorter.order === 'ascend' ? OrderType.ASC : OrderType.DESC;
        }

        handleOnChange({
          page: pagination.current || 1,
          perPage: pagination.pageSize || 20,
          contactTypes: filters.contactType?.join(','),
          search: filters.displayName && filters.displayName.length > 0 ? `${filters.displayName[0]}` : undefined,
          order,
        });
      }}
    />
  );
};

export default ContactTable;
