import { gql } from 'Operations/__generated__';

export const VALIDATE_ORDERS = gql(/* GraphQL */ `
  mutation ValidateOrders($where: ValidateOrdersWhereType!) {
    validateOrders(where: $where) {
      id
      isValidated
      items {
        photos {
          edges {
            id
            isOrdered
            isRetouched
          }
        }
      }
    }
  }
`);
