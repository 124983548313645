import { gql } from 'Operations/__generated__';

export const GET_ORDER = gql(/* GraphQL */ `
  query GetOrder($where: IdInput!) {
    getOrder(where: $where) {
      id
      createdAt
      isPaid
      isValidated
      invoiceId
      firstName
      lastName
      email
      street
      zipCode
      city
      country
      paymentMethod
      transportFee
      gallery {
        id
        userId
        name
        workmode
        contact {
          ...ContactCoreFields
        }
      }
      invoice {
        id
        url
        ttcPrice
        createdAt
        paid
        isReceipt
        type
        title
        numero
      }
      items {
        id
        price
        laboPriceExclVat
        quantity
        requestQuantity
        status
        packageUrl
        package {
          id
          carrier
          laboTransportFeeExclVat
          packagePriceExclVat
          oilCoefficient
          weight
          totalExclVat
        }
        productName
        hasCroppedPhotos
        availableItemStatuses
        handler
        providerId
        providerName
        isDigital
        options {
          id
          name
          optionCategoryName
          providerDescription
        }
        product {
          id
          name
          category {
            name
            isDigital
          }
          description
          orderedByFotostudio
          previewAsset {
            downloadUrl
          }
          previewCoords {
            x
            y
          }
          contact {
            ...ContactCoreFields
          }
        }
        photos(where: { characteristics: [AVAILABLE] }) {
          edges {
            id
            name
            asset {
              assetWeb {
                key
                downloadUrl
              }
              thumbSmall {
                downloadUrl
              }
            }
          }
        }
      }
      ... on OrderAdmin {
        status
        orderedByFotostudio
        handlers
        photographerMustReceiveOrder
        seller {
          userId
          email
          companyName
          companyAddress
          street
          zipcode
          city
          country
        }
        contact {
          ...ContactCoreFields
        }
        weight
      }
    }
  }
`);
