import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, App, Flex, Radio, RadioChangeEvent, Space, Spin } from 'antd';
import { createStyles } from 'antd-style';

import clsx from 'clsx';

import Spinner from 'Components/Atoms/Spinner';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import Header from 'Components/Molecules/Header';

import { LocalizationContext } from 'i18n';

import { CompanySettingsInputType, GalleryAction, GalleryWorkmode } from 'Operations/__generated__/graphql';

import { GET_COMPANY_SETTINGS } from 'Operations/Queries/Company/GetCompanySettings';

import { UPDATE_COMPANY_SETTINGS } from 'Operations/Mutations/Company/UpdateCompanySettings';

const useStyles = createStyles(({ css, token, responsive }) => ({
  container: css`
    margin-top: ${token.size}px;
  `,
  card: css`
    flex: 1;
    ${responsive.xxl} {
      flex-basis: 30%;
    }
    ${responsive.xl} {
      flex-basis: 45%;
    }
    ${responsive.md} {
      flex-basis: 100%;
    }
  `,
  loader: css`
    width: 100%;
    height: 300px;
  `,
}));

enum EMAIL_TARGET {
  None = 'None',
  MainContact = 'Main',
  AllContacts = 'All',
}

const toEmailTarget = function (actions: GalleryAction[]) {
  if (actions.length === 0) {
    return EMAIL_TARGET.None;
  }
  if (actions.indexOf(GalleryAction.EMAIL_GALLERY_AVAIL_TO_SECONDARY_CONTACTS) > -1) {
    return EMAIL_TARGET.AllContacts;
  }
  return EMAIL_TARGET.MainContact;
};
const toGalleryAction = function (emailTarget: EMAIL_TARGET) {
  switch (emailTarget) {
    case EMAIL_TARGET.AllContacts:
      return [
        GalleryAction.EMAIL_GALLERY_AVAIL_TO_MAIN_CONTACT,
        GalleryAction.EMAIL_GALLERY_AVAIL_TO_SECONDARY_CONTACTS,
      ];
    case EMAIL_TARGET.MainContact:
      return [GalleryAction.EMAIL_GALLERY_AVAIL_TO_MAIN_CONTACT];
    case EMAIL_TARGET.None:
    default:
      return [];
  }
};

const GalleriesSettings = () => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const { styles } = useStyles();

  const [galleryPublishEmailTarget, setgalleryPublishEmailTarget] = useState<EMAIL_TARGET>(EMAIL_TARGET.None);

  const { loading: isLoading, data } = useQuery(GET_COMPANY_SETTINGS, {
    fetchPolicy: 'cache-first',
  });

  const [updateCompanySettings, { loading: isUpdating }] = useMutation(UPDATE_COMPANY_SETTINGS);

  const handleUpdateCompanySettings = useCallback(
    async ({ data }: { data: CompanySettingsInputType }) => {
      try {
        await updateCompanySettings({
          variables: {
            data,
          },
        });
        message.success(t('app.message.gallery.settings.update.success'));
      } catch (error) {
        console.log(error);
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [updateCompanySettings, t],
  );

  const onDefaultWorkmodeChanged = useCallback(
    async (e: RadioChangeEvent) => {
      const newValue = e.target.value;
      const data = {
        defaultGalleryWorkmode: newValue,
      };
      handleUpdateCompanySettings({ data });
    },
    [handleUpdateCompanySettings],
  );

  const onIsShopPageEnabledChanged = useCallback(
    async (e: RadioChangeEvent) => {
      const newValue = e.target.value;
      const data = {
        isShopPageEnabled: newValue,
      };
      handleUpdateCompanySettings({ data });
    },
    [handleUpdateCompanySettings],
  );

  const onGalleryPublishEmailTargetChange = useCallback(
    async (e: RadioChangeEvent) => {
      const data = {
        actionsOnGalleryPublish: toGalleryAction(e.target.value),
      };
      handleUpdateCompanySettings({ data });
    },
    [handleUpdateCompanySettings],
  );

  useEffect(() => {
    const emailTarget = toEmailTarget(data?.getCompany?.settings?.actionsOnGalleryPublish || []);
    setgalleryPublishEmailTarget(emailTarget);
  }, [data?.getCompany?.settings?.actionsOnGalleryPublish]);

  const title = useMemo((): string => {
    return t('app.common.galleriesSettings');
  }, [t]);

  const defaultGalleryWorkmode = useMemo(
    () => data?.getCompany?.settings?.defaultGalleryWorkmode || GalleryWorkmode.READY_FOR_SALE,
    [data?.getCompany?.settings?.defaultGalleryWorkmode],
  );

  const isShopPageEnabled = useMemo(
    () => data?.getCompany?.settings?.isShopPageEnabled,
    [data?.getCompany?.settings?.isShopPageEnabled],
  );

  if (isLoading) {
    return (
      <Flex justify="center" align="center" className={styles.loader}>
        <Spin size="large" />
      </Flex>
    );
  }

  return (
    <>
      <Header
        title={
          <Flex align="center" gap="middle">
            {isUpdating ? (
              <>
                <Spinner size={20} />
                <Title level="h1">{title}</Title>
              </>
            ) : (
              <span>{title}</span>
            )}
          </Flex>
        }
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.galleriesSettings') },
        ]}
      />

      <Flex gap="middle" wrap="wrap" className={styles.container}>
        <Flex
          vertical
          gap="large"
          className={clsx('ant-flex--shadow ant-flex--rounded ant-flex--padding', styles.card)}
        >
          <Title level="h3">{t('app.gallery.settings.defaultWorkmode.title')}</Title>
          <Alert message={t('app.gallery.settings.alert.bydefaultOverridable')} />

          <Radio.Group
            defaultValue={defaultGalleryWorkmode}
            buttonStyle="solid"
            onChange={onDefaultWorkmodeChanged}
            size="large"
          >
            <Radio.Button value={GalleryWorkmode.RETOUCH_FIRST}>
              {t('app.gallery.settings.defaultWorkmode.retouchFirst')}
            </Radio.Button>
            <Radio.Button value={GalleryWorkmode.READY_FOR_SALE}>
              {t('app.gallery.settings.defaultWorkmode.readyForSale')}
            </Radio.Button>
          </Radio.Group>
          <div>
            <Title level="h5">{t('app.gallery.settings.defaultWorkmode.retouchFirst')}</Title>
            <Text>{t('app.gallery.settings.defaultWorkmode.retouchInfo')}</Text>
          </div>
          <div>
            <Title level="h5">{t('app.gallery.settings.defaultWorkmode.readyForSale')}</Title>
            <Text>{t('app.gallery.settings.defaultWorkmode.readyForSaleInfo')}</Text>
          </div>
        </Flex>
        <Flex
          vertical
          gap="large"
          className={clsx('ant-flex--shadow ant-flex--rounded ant-flex--padding', styles.card)}
        >
          <Title level="h3">{t('app.gallery.settings.automatedActions.title')}</Title>
          <Alert message={t('app.gallery.settings.alert.byDefault')} />
          <Title level="h5">{t('app.gallery.settings.automatedActions.galleryPublish.title')}</Title>
          <Radio.Group value={galleryPublishEmailTarget} onChange={onGalleryPublishEmailTargetChange}>
            <Space direction="vertical">
              <Radio value={EMAIL_TARGET.None}>{t('app.gallery.settings.automatedActions.none')}</Radio>
              <Radio value={EMAIL_TARGET.MainContact}>
                {t('app.gallery.settings.automatedActions.sendGalleryToMainContact')}
              </Radio>
              <Radio value={EMAIL_TARGET.AllContacts}>
                {t('app.gallery.settings.automatedActions.sendGalleryToAllContacts')}
              </Radio>
            </Space>
          </Radio.Group>
        </Flex>
        <Flex
          vertical
          gap="large"
          className={clsx('ant-flex--shadow ant-flex--rounded ant-flex--padding', styles.card)}
        >
          <Title level="h3">{t('app.gallery.settings.isShopPageEnabled.title')}</Title>
          <Text>{t('app.gallery.settings.isShopPageEnabled.description')}</Text>
          <Radio.Group
            defaultValue={isShopPageEnabled}
            buttonStyle="solid"
            onChange={onIsShopPageEnabledChanged}
            size="large"
          >
            <Radio.Button value={false}>{t('app.gallery.settings.isShopPageEnabled.disabled')}</Radio.Button>
            <Radio.Button value={true}>{t('app.gallery.settings.isShopPageEnabled.enabled')}</Radio.Button>
          </Radio.Group>
        </Flex>
      </Flex>
    </>
  );
};

export default GalleriesSettings;
