import { useCallback, useContext, useEffect, useState } from 'react';

import { Card, Spin } from 'antd';
import { createStyles } from 'antd-style';

import LabeledSwitch from 'Components/Molecules/LabeledSwitch';
import WatermarkPreview, { WatermarkPreviewProps } from 'Components/Molecules/WatermarkPreview';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { css } from 'styled-components';

import { WatermarkMode } from 'Operations/__generated__/graphql';

interface WatermarkButtonProps extends WatermarkPreviewProps {
  id: number;
  name: string;
  active?: boolean;
  isLoading?: boolean;
  onClick: ({ id, mode }: { id: number; mode: WatermarkMode }) => void;
  thumbsWatermarked: boolean;
  className?: string;
}

const useStyles = createStyles(({ css, token }) => ({
  button: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 274px;
    padding: 0;
    border: 4px solid transparent;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border-radius: ${token.borderRadiusLG}px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
    transition: all ease-in-out 0.2s;

    &:hover {
      border: 4px solid ${token.colorPrimary};
    }

    &.active,
    &:active {
      box-shadow: none;
      border-color: ${token.colorPrimary};
    }
  `,
  card: css`
    border-radius: 0 !important;
    .ant-card-cover img {
      border-radius: 0 !important;
    }
  `,
  spinStyled: css`
    position: absolute;
    z-index: 3;
    margin: auto;
  `,
}));

const { Meta } = Card;

const WatermarkButton = ({
  id,
  watermarkType,
  active,
  isLoading,
  name,
  image,
  font,
  text,
  textColor,
  repeat,
  opacity,
  isResized,
  size,
  position,
  withStroke,
  strokeColor,
  withBackgroundText,
  backgroundTextColor,
  onClick,
  thumbsWatermarked,
  className,
  ...props
}: WatermarkButtonProps) => {
  const { t } = useContext(LocalizationContext);
  const { styles, cx } = useStyles();
  const [isThumbWmEnabled, setThumbEnabled] = useState<boolean>(thumbsWatermarked);

  const onValueChanged = useCallback(() => {
    onClick({ id, mode: isThumbWmEnabled ? WatermarkMode.WEBTHUMBS : WatermarkMode.WEB });
  }, [id, isThumbWmEnabled, onClick]);

  const onButtonClicked = function () {
    if (!active) {
      onValueChanged();
    }
  };

  useEffect(() => {
    if (active && isThumbWmEnabled !== thumbsWatermarked) {
      onValueChanged();
    }
  }, [isThumbWmEnabled, onValueChanged, thumbsWatermarked, active]);

  const description = (
    <LabeledSwitch
      defaultChecked={thumbsWatermarked}
      label={t('app.gallery.watermark.applyOnThumbs')}
      onCheckChanged={checked => {
        setThumbEnabled(checked);
      }}
    />
  );
  return (
    <div {...props} className={cx(className, styles.button, active && 'active')} onClick={() => onButtonClicked()}>
      {isLoading && <Spin className={styles.spinStyled} size="large" />}
      <Card
        bordered={false}
        className={cx('ant-card--no-shadow', styles.card)}
        cover={
          <WatermarkPreview
            watermarkType={watermarkType}
            image={image}
            font={font}
            text={text}
            textColor={textColor}
            repeat={repeat}
            opacity={opacity}
            isResized={isResized}
            size={size}
            position={position}
            withStroke={withStroke}
            strokeColor={strokeColor}
            withBackgroundText={withBackgroundText}
            backgroundTextColor={backgroundTextColor}
          />
        }
      >
        <Meta title={name} description={description} />
      </Card>
    </div>
  );
};

export default WatermarkButton;
