import { gql } from 'Operations/__generated__';

export const UPDATE_PRODUCT_OPTION_CATEGORY = gql(/* GraphQL */ `
  mutation UpdateProductOptionCategory($where: IdInput!, $data: ProductOptionCategoryUpdateInput!) {
    updateProductOptionCategory(where: $where, data: $data) {
      id
      name
    }
  }
`);
