import { useQuery } from '@apollo/client';

import { GET_CONTACT_ZONES } from 'Operations/Queries/Contact/GetContactZones';

interface UseZonesProps {
  contactId?: number;
}

const useZones = ({ contactId }: UseZonesProps) => {
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(GET_CONTACT_ZONES, {
    fetchPolicy: 'cache-and-network',
    skip: !contactId,
    variables: {
      where: {
        id: contactId as number,
      },
    },
  });

  return { zones: data?.getContactZones, isLoading, error };
};

export { useZones };
