import React, { useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { App, Spin } from 'antd';

import ContentContainer from 'Components/Atoms/ContentContainer';

import { OnSubmitPayload, RedsysPaymentForm } from 'Forms/Redsys';

import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { ME } from 'Operations/Queries/User/Me';

import { UPDATE_REDSYS_PAYMENT } from 'Operations/Mutations/User/UpdateRedsysPayment';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  width: 67%;
`;

const RedysTab = () => {
  const { message } = App.useApp();
  const { t } = useContext(LocalizationContext);

  const { data: currentUser, loading: isUserLoading } = useQuery(ME, {
    fetchPolicy: 'cache-first',
  });

  const [updateRedsysPayment] = useMutation(UPDATE_REDSYS_PAYMENT);

  const handleOnSubmit = async ({ values, formikBag }: OnSubmitPayload) => {
    try {
      await updateRedsysPayment({
        variables: {
          data: {
            merchantCode: values.merchantCode || '',
            secretKey: values.secretKey || '',
            terminal: values.terminal || '',
          },
        },
      });

      message.success(t('app.message.payments.redsys.update.success'));
      formikBag.setSubmitting(false);
    } catch (error) {
      console.log(error);

      message.error(t('app.message.error.somethingWentWrong'));
      formikBag.setSubmitting(false);
    }
  };

  return (
    <StyledTableContentContainer padding="baseMargin" shadow rounded>
      {isUserLoading && <Spin />}
      {!isUserLoading && (
        <RedsysPaymentForm
          defaultValues={{
            merchantCode: currentUser?.me.redsysPayment.merchantCode || '',
            secretKey: currentUser?.me.redsysPayment.secretKey || '',
            terminal: currentUser?.me.redsysPayment.terminal || '',
          }}
          onSubmit={handleOnSubmit}
        />
      )}
    </StyledTableContentContainer>
  );
};

export default RedysTab;
