import { gql } from 'Operations/__generated__';

export const GET_ORDERABLE_DIGITAL_PHOTOS = gql(/* GraphQL */ `
  query GetOrderableDigitalPhotos($where: OrderableDigitalPhotosGetWhereType!) {
    getOrderableDigitalPhotos(where: $where) {
      available {
        _count
        edges {
          id
          name
        }
      }
      ordered {
        _count
        edges {
          id
          name
        }
      }
      productName
    }
  }
`);
