import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import {
  EditGalleryPresetForm,
  EditGalleryPresetFormProps,
  NewGalleryPresetForm,
  NewGalleryPresetFormProps,
} from 'Forms/GalleryPreset';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

interface NewGalleryPresetModalProps extends NewGalleryPresetFormProps {
  name: 'new';
}
interface EditGalleryPresetModalProps extends EditGalleryPresetFormProps {
  name: 'edit';
}

const GalleryPresetModal = (props: NewGalleryPresetModalProps | EditGalleryPresetModalProps) => {
  const { t } = useContext(LocalizationContext);
  const renderForm = () => {
    if (props.name === 'edit') {
      return <EditGalleryPresetForm onSubmit={props.onSubmit} defaultValues={props.defaultValues} />;
    }

    return <NewGalleryPresetForm onSubmit={props.onSubmit} defaultValues={props.defaultValues} />;
  };

  return (
    <ModalContainer title={t(`app.galleryPreset.modal.${props.name}`)} icon={Images.gallery}>
      {renderForm()}
    </ModalContainer>
  );
};

export default GalleryPresetModal;
