import React from 'react';

import { Navigate } from 'react-router-dom';

interface BootNavigationProps {
  loggedIn: boolean;
  onboarded: boolean;
  isLabo: boolean;
}

const BootNavigation = ({ onboarded, loggedIn, isLabo }: BootNavigationProps) => {
  if (loggedIn) {
    if (isLabo) {
      return <Navigate to={{ pathname: '/app/orders/fotostudio' }} />;
    }

    if (!onboarded) {
      return <Navigate to={{ pathname: '/onboarding' }} />;
    }

    if (onboarded) {
      return <Navigate to={{ pathname: '/app' }} />;
    }
  }

  return <Navigate to={{ pathname: '/auth/signin' }} />;
};

export default BootNavigation;
