import { gql } from 'Operations/__generated__';

export const GET_CONTACT_ASSOCIATED_VALUES = gql(/* GraphQL */ `
  query GetContactAssociatedValues {
    me {
      id
      firstname
      lastname
      hasCRM
      locale
      company {
        id
        name
        logoUrl
      }
      contactTypes {
        _count
        edges {
          id
          name
          color
        }
      }
      graphicalIdentities {
        _count
        edges {
          id
          name
        }
      }
      prospectOrigins {
        _count
        edges {
          id
          name
        }
      }
      tags {
        _count
        edges {
          id
          name
        }
      }
    }
    getCountries {
      _count
      edges {
        id
        nameFr
        nameEn
        states {
          id
          name
        }
      }
    }
  }
`);
