import { gql } from 'Operations/__generated__';

export const UPDATE_BILLING_SETTINGS = gql(/* GraphQL */ `
  mutation UpdateBillingSettings($data: BillingSettingsInputType!) {
    updateBillingSettings(data: $data) {
      id
      genInvoice
    }
  }
`);
