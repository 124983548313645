import { gql } from 'Operations/__generated__';

export const SET_EMAIL_CONFIG_NO_REPLY = gql(/* GraphQL */ `
  mutation SetEmailConfigNoReply {
    setEmailConfigNoReply {
      id
      settings {
        useGmail
        useSmtp
        isGoogleConfigured
        smtp {
          host
          user
          withSsl
        }
      }
    }
  }
`);
