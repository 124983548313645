import React, { CSSProperties, forwardRef } from 'react';

import { Button, Tooltip } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { Link } from 'react-router-dom';

import Icon, { IconName } from 'Components/Atoms/Icon';

interface RoundButtonProps {
  icon: IconName;
  iconSize?: number;
  type?: ButtonType;
  color?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  tooltipTitle?: string;
  danger?: boolean;
  size?: 'small' | 'middle' | 'large' | undefined;
  className?: string;
  disabled?: boolean;
  href?: string;
  target?: string;
  style?: CSSProperties;
}

const RoundButton = forwardRef<HTMLButtonElement, RoundButtonProps>(
  (
    {
      icon,
      color,
      iconSize = 22,
      tooltipTitle,
      onMouseEnter,
      onMouseLeave,
      size = 'large',
      href,
      target,
      style,
      ...props
    }: RoundButtonProps,
    ref,
  ) => {
    let roundButtonComponent = (
      <Button
        ref={ref}
        size={size}
        shape="circle"
        style={{
          ...(style || {}),
          borderColor: color,
        }}
        {...props}
      >
        <Icon name={icon} size={iconSize} color={color} />
      </Button>
    );

    if (href) {
      if (href.startsWith('https://')) {
        roundButtonComponent = (
          <a href={href} target={target}>
            {roundButtonComponent}
          </a>
        );
      } else {
        roundButtonComponent = (
          <Link to={href} target={target}>
            {roundButtonComponent}
          </Link>
        );
      }
    }

    if (tooltipTitle) {
      return <Tooltip title={tooltipTitle}>{roundButtonComponent}</Tooltip>;
    }

    return roundButtonComponent;
  },
);

export default RoundButton;
