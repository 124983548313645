import { gql } from 'Operations/__generated__';

export const UPDATE_DIGITAL_PRODUCT_CONFIG = gql(/* GraphQL */ `
  mutation UpdateDigitalProductConfig($where: IdInput!, $data: UpdateDigitalProductConfigInput!) {
    updateDigitalProductConfig(where: $where, data: $data) {
      id
      downloadContent
    }
  }
`);
