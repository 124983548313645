import { gql } from 'Operations/__generated__';

export const GET_PRODUCT = gql(/* GraphQL */ `
  query GetProduct($where: IdInput!) {
    getProduct(where: $where) {
      id
      companyId
      name
      description
      packagePrice
      packages {
        _count
        edges {
          id
          carrier
          category
          priceExclVat
          weight
        }
      }
      previewAsset {
        downloadUrl
      }
      category {
        id
        name
        isDigital
      }
      contactId
      contact {
        ...ContactCoreFields
      }
      maxPhotoCount
      weight
      profitMarginPercent
      orderedByFotostudio
      photoSizes {
        width
        height
      }
      pricingPlanMeta {
        startingPrice
        startingQuantity
        hasMultiplePrices
        grossPrice
        grossQuantity
        hasMultipleGrossPrices
        formattedPrice @client
        formattedGrossPrice @client
      }
      productOptionCategories(orderBy: { position: asc }) {
        id
        name
        position
        productOptions {
          id
          name
          shouldUsePresetPricingPlan
          pricingPlan {
            id
            name
            type
            createdAt
            wholeGalleryPrice
            items {
              id
              price
              additionalPrice
              quantity
            }
          }
          pricingPlanMeta {
            startingPrice
            startingQuantity
            hasMultiplePrices
            grossPrice
            grossQuantity
            hasMultipleGrossPrices
            formattedPrice @client
            formattedGrossPrice @client
          }
          providerPrice
          providerDescription
          previewAsset {
            downloadUrl
          }
          hasPricingPlan
          hasPresetPricingPlan
        }
      }
      digitalProductConfig {
        id
        downloadContent
      }
      presetProductId
      isPreconfiguredProduct
      isFotostudioProduct
      isEditable
      isDeletable
      catalogs {
        id
        name
      }
    }
  }
`);
