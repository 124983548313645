import { Navigate, useRoutes } from 'react-router-dom';

import { GoogleOAuthProvider } from '@react-oauth/google';

import SignIn from 'Pages/Auth/SignIn';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const AuthNavigation = () => {
  const routesElement = useRoutes([
    {
      path: 'signin',
      element: <SignIn />,
    },
    {
      path: '',
      element: <Navigate to={{ pathname: 'signin' }} />,
    },
  ]);

  return <GoogleOAuthProvider clientId={clientId}>{routesElement}</GoogleOAuthProvider>;
};

export default AuthNavigation;
