import { gql } from 'Operations/__generated__';

export const GET_PRICING_PLAN = gql(/* GraphQL */ `
  query GetPricingPlan($where: IdInput!) {
    getPricingPlan(where: $where) {
      id
      name
      type
      createdAt
      wholeGalleryPrice
      items {
        id
        price
        additionalPrice
        quantity
      }
    }
  }
`);
