import { gql } from 'Operations/__generated__';

export const GET_MOST_VIEWED_PHOTOS = gql(/* GraphQL */ `
  query GetMostViewedPhotos($galleryId: Int!) {
    getMostViewedPhotos: getPhotos(
      where: { galleryId: $galleryId, page: 1, perPage: 5, sortField: viewCount, sortOrder: desc }
    ) {
      edges {
        ...PhotoCoreFields
      }
    }
    getGallery(where: { id: $galleryId }) {
      ... on GalleryAdmin {
        id
        _count {
          photosViewCount
        }
      }
    }
  }
`);
