import React, { useContext, useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { createStyles, useResponsive } from 'antd-style';

import Card from 'Components/Atoms/Card';
import CardContainer from 'Components/Atoms/CardContainer';
import Title from 'Components/Atoms/Title';

import InfoCard from 'Components/Molecules/Cards/InfoCard';
import Chart from 'Components/Molecules/Chart';
import Header from 'Components/Molecules/Header';
import RecentlyConsultedList from 'Components/Molecules/RecentlyConsultedList';
import MostViewedPhotos from 'Components/Molecules/Stats/MostViewedPhotos';
import StorageCard from 'Components/Molecules/Stats/StorageCard';

import DashboardIndexSkeleton from 'Pages/App/Skeletons/DashboardIndexSkeleton';

import { Colors, Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useMenuRight } from 'Hooks/MenuRightProvider';
import { useCurrency } from 'Hooks/useCurrency';

import dayjs from 'Services/DayjsService';

import {
  GalleryField,
  GalleryOrderType,
  GalleryStatus,
  GranularityEnum,
  PhotoAdmin,
} from 'Operations/__generated__/graphql';

import { GET_LAST_GALLERIES } from 'Operations/Queries/Gallery/GetLastGalleries';
import { GET_ORDERS_TOTAL } from 'Operations/Queries/Order/GetOrdersTotal';
import { GET_GLOBAL_MOST_VIEWED_PHOTOS } from 'Operations/Queries/Photo/GetGlobalMostViewedPhotos';
import { GET_STORAGE } from 'Operations/Queries/Storage/GetStorage';

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: ${token.size}px;
    padding: ${token.size}px 0;
  `,
  content: css`
    padding: ${token.size}px;
  `,
}));

const DashboardIndex = () => {
  const { t } = useContext(LocalizationContext);
  const { setItems } = useMenuRight();
  const { formatCurrency } = useCurrency();
  const { styles, theme } = useStyles();
  const { lg } = useResponsive();

  const {
    data,
    loading: isStorageLoading,
    error,
  } = useQuery(GET_STORAGE, {
    fetchPolicy: 'network-only',
  });

  const from = useMemo(() => dayjs().subtract(2, 'month').startOf('minute').toDate(), []);

  const { data: totalOrdersData } = useQuery(GET_ORDERS_TOTAL, {
    variables: {
      where: {
        granularity: GranularityEnum.MONTH,
        from,
        isVatIncluded: false,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { data: mostViewedPhotosData, loading: isMostViewedPhotosLoading } = useQuery(GET_GLOBAL_MOST_VIEWED_PHOTOS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { data: lastSeenGalleriesData } = useQuery(GET_LAST_GALLERIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        field: GalleryField.SEENAT,
        order: GalleryOrderType.DESC,
        status: [GalleryStatus.ONLINE, GalleryStatus.OFFLINE],
        perPage: 5,
        page: 1,
      },
    },
  });

  useEffect(() => {
    if (lastSeenGalleriesData?.getGalleries) {
      setItems([
        {
          content: (
            <RecentlyConsultedList
              recentlyConsulted={lastSeenGalleriesData.getGalleries.edges.map(gallery => ({
                href: `/app/galleries/${gallery.id}`,
                text: gallery.name || '',
                icon: 'galleries',
              }))}
            />
          ),
        },
      ]);
    }

    return () => setItems([]);
  }, [lastSeenGalleriesData, setItems]);

  if (isStorageLoading) {
    return <DashboardIndexSkeleton />;
  }

  if (error || !data || !data.getStorage) {
    // TODO: handle error view
    return null;
  }

  const { usedSpace, maxSpace, percent, galleries, photos } = data.getStorage;

  const totalOrders = totalOrdersData && totalOrdersData?.getOrdersTotal;
  const mostViewedPhotos: DeepPartial<PhotoAdmin>[] | undefined =
    mostViewedPhotosData && mostViewedPhotosData?.getMostViewedPhotos;

  const width = Metrics.cards.width.large;
  const largeWidth = width * 2 + theme.size;

  return (
    <>
      <Header
        title={t('app.menu.dashboard')}
        breadcrumbContent={[{ text: t('app.menu.home'), url: '/app/dashboard' }]}
      />
      <div className={styles.container}>
        <InfoCard width={lg ? largeWidth : width} />
        <StorageCard
          width={width}
          storage={usedSpace}
          storageMax={maxSpace}
          percent={percent}
          galleries={galleries ? galleries : 0}
          photos={photos ? photos : 0}
        />
        {totalOrders && (
          <>
            <Card shadow rounded width={width}>
              <div className={styles.content}>
                <Title level="h3">{totalOrders.items[totalOrders.items.length - 1].total}</Title>
                <Title level="h4" color={Colors.grey}>
                  {t('app.dashboard.ordersChart')}
                </Title>
              </div>
              <Chart
                data={totalOrders.items.map(item => ({
                  label: dayjs(item.key).format('MMM'),
                  value: item.total,
                }))}
                color="#0066C3"
                name="graph1"
              />
            </Card>
            <Card shadow rounded width={width}>
              <div className={styles.content}>
                <Title level="h3">{formatCurrency(totalOrders.items[totalOrders.items.length - 1].amount)}</Title>
                <Title level="h4" color={Colors.grey}>
                  {t('app.dashboard.amountChart')}{' '}
                </Title>
              </div>
              <Chart
                data={totalOrders.items.map(item => ({
                  label: dayjs(item.key).format('MMM'),
                  value: item.amount,
                }))}
                color="#4FB53D"
                name="graph2"
                renderTooltip={value => formatCurrency(value)}
              />
            </Card>
          </>
        )}
        {mostViewedPhotos && (
          <MostViewedPhotos
            isLoading={isMostViewedPhotosLoading}
            photos={mostViewedPhotos}
            isGalleryButtonVisible
            width={width}
          />
        )}
      </div>
    </>
  );
};

export default DashboardIndex;
