import { useCallback, useContext, useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Flex, Popconfirm } from 'antd';

import clsx from 'clsx';

import { Form, Input, InputNumber, Switch } from 'formik-antd';
import { reduce } from 'lodash';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import SegmentedControl from 'Components/Molecules/Form/SegmentedControl';

import { EditPricingPlanFormValues, EditPricingPlanItem, NewPricingPlanFormValues } from 'Forms/PricingPlan';
import { NewProductFormValues } from 'Forms/Product';

import { Colors, FontSize, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useCurrency } from 'Hooks/useCurrency';

import { PricingPlanType } from 'Operations/__generated__/graphql';

import { GET_USER_CURRENCY } from 'Operations/Queries/User/GetUserCurrency';

const ItemsContainer = styled(Flex)`
  .ItemContainer {
    border-bottom: 0;
    padding-bottom: 0;

    &:last-child {
      padding-bottom: ${Metrics.smallMargin}px;
      border-bottom: 1px solid ${Colors.formBorder};
    }
  }
`;

const ItemContainer = styled(Flex)`
  margin: ${Metrics.smallMargin}px 0;
  width: 100%;
  border-bottom: 1px solid ${Colors.formBorder};

  &:last-child {
    padding-bottom: ${Metrics.smallMargin}px;
    border-bottom: 1px solid ${Colors.formBorder};
  }
`;

const SegmentedControlStyled = styled(SegmentedControl)`
  margin-bottom: ${Metrics.baseMargin}px;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1.26;
`;

const ProductName = styled(Text)`
  margin-right: 0;
  transition: margin-right 0.3s ease-in-out;

  &.ProductName {
    &--with-button {
      margin-right: ${Metrics.smallMargin}px;
    }

    &--without-button {
      margin-right: 52px;
    }
  }
`;

const QuantityItem = styled(Form.Item)`
  &.ant-row.ant-form-item {
    flex-grow: 0.8;
  }
`;

const PriceItem = styled(Form.Item)`
  &.ant-row.ant-form-item {
    flex-grow: 0.8;
    transition: margin-right 0.3s ease-in-out;

    &.PriceItem {
      &--pack {
        margin-right: 0;

        &--without-button {
          margin-right: 48px;
        }

        &--with-additionnalValues {
          flex-grow: 1.2;
        }

        &--without-button {
          &--with-additionnalValues {
            flex-grow: 1.11;
          }
        }
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
`;

const DisabledText = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: ${Metrics.smallMargin}px;
  padding-bottom: ${Metrics.smallMargin}px;
  border-bottom: 1px solid ${Colors.formBorder};

  span {
    margin-left: ${Metrics.tinyMargin}px;
  }
`;

const QUANTITY_INCREMENT = 10;
const PRICE_DECREMENT = 5;

const PricingPlanFormContent = ({
  values,
  setFieldValue,
  isReadOnly,
  isFotostudioProduct,
}: {
  values: EditPricingPlanFormValues | NewPricingPlanFormValues | NewProductFormValues;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void;
  isReadOnly?: boolean;
  isFotostudioProduct?: boolean;
}) => {
  const { t } = useContext(LocalizationContext);
  const { formatCurrency } = useCurrency();

  const { data: currency, loading: isCurrencyLoading } = useQuery(GET_USER_CURRENCY, {
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!values.permitAdditionalItems) {
      values.items.forEach((_item, index) => {
        setFieldValue(`items[${index}][additionalPrice]`, null);
      });
    }
  }, [setFieldValue, values.items, values.permitAdditionalItems]);

  useEffect(() => {
    if (values.type === PricingPlanType.UNITARY) {
      // Find the first item with the lowest quantity
      const firstItem = reduce(
        values.items,
        (acc: EditPricingPlanItem, item: EditPricingPlanItem) => {
          item.additionalPrice;
          if (item.quantity < acc.quantity) {
            return item;
          }

          return acc;
        },
        { quantity: Infinity, price: Infinity, additionalPrice: Infinity },
      );

      // If firstItem quantity is greater than 1, we need to add a new item with quantity 1 and price of the firstItem / 2
      if (firstItem.quantity > 1) {
        setFieldValue('items', [
          {
            quantity: 1,
            price: Math.round((firstItem.price / 2) * 100) / 100,
          },
          ...values.items,
        ]);
      }
    }
  }, [setFieldValue, values.items, values.type]);

  useEffect(() => {
    if (values.wholeGalleryPrice?.toString() === '' || values.wholeGalleryPrice === undefined) {
      setFieldValue('wholeGalleryPrice', null);
    }
  }, [setFieldValue, values.wholeGalleryPrice]);

  const handleAddMore = useCallback(() => {
    const lastItem = values.items[values.items.length - 1];
    const lastQuantity = lastItem.quantity > 1 ? lastItem.quantity : 0;
    const lastPrice = lastItem.price - PRICE_DECREMENT <= PRICE_DECREMENT ? 10 : lastItem.price;

    setFieldValue('items', [
      ...values.items,
      {
        quantity: lastQuantity + QUANTITY_INCREMENT,
        price: values.type === PricingPlanType.UNITARY ? lastPrice - PRICE_DECREMENT : lastPrice + PRICE_DECREMENT,
      },
    ]);
  }, [setFieldValue, values.items, values.type]);

  const handleRemoveItem = useCallback(
    ({ index }: { index: number }) => {
      const newItems = [...values.items];
      newItems.splice(index, 1);
      setFieldValue('items', newItems);
    },
    [setFieldValue, values.items],
  );

  const renderGrossPrice = useCallback(
    (index: number) =>
      isFotostudioProduct && isReadOnly && (values.items[index] as EditPricingPlanItem)?.grossPrice !== undefined ? (
        <Flex flex={1} style={{ width: '100%' }}>
          <Text align="right" size="small" weight="bold" style={{ flex: 1 }}>
            {`${t('app.products.priceWithoutMargin')} ${formatCurrency(
              (values.items[index] as EditPricingPlanItem).grossPrice || 0,
            )}`}
          </Text>
        </Flex>
      ) : (
        <div />
      ),
    [formatCurrency, isFotostudioProduct, isReadOnly, t, values.items],
  );

  const renderUnitaryItems = useCallback(
    () =>
      (values.items as EditPricingPlanItem[]).map((item, index) => (
        <ItemContainer vertical gap="small">
          {!item.quantity || item.quantity > 1 ? (
            <Flex key={index} align="center" gap="small">
              <Flex align="center" flex={1.26} gap="small">
                <Text size="medium">{t('app.common.quantity').toLowerCase()} :</Text>
                <QuantityItem
                  className="ant-form-item--no-margin"
                  name={`items[${index}][quantity]`}
                  required
                  hasFeedback={false}
                >
                  <InputNumber
                    disabled={isReadOnly}
                    name={`items[${index}][quantity]`}
                    placeholder={t('app.common.quantity')}
                    size="large"
                    min={(values.items[index - 1]?.quantity || 1) + 1}
                    max={values.items[index + 1] ? values.items[index + 1].quantity - 1 : undefined}
                    precision={0}
                    onChange={value => setFieldValue(`items[${index}][quantity]`, value || undefined)}
                  />
                </QuantityItem>
                <Text size="medium">{t('app.pricingPlans.oneAndMore', { count: 0 })}</Text>
              </Flex>
              <PriceItem
                className="ant-form-item--no-margin"
                name={`items[${index}][price]`}
                required
                hasFeedback={false}
              >
                <Input
                  disabled={isReadOnly}
                  name={`items[${index}][price]`}
                  type="number"
                  placeholder={t('app.common.price')}
                  size="large"
                  addonAfter={currency?.me.currency.symbol}
                />
              </PriceItem>
              <ProductName size="medium" className="ProductName--with-button">
                / {t('app.common.unit', { count: 1 }).toLowerCase()}
              </ProductName>
              {!isReadOnly && (
                <Popconfirm title={t('app.confirm.delete')} onConfirm={() => handleRemoveItem({ index })}>
                  <RoundButton icon="delete" tooltipTitle={t('app.common.delete')} danger />
                </Popconfirm>
              )}
            </Flex>
          ) : (
            <Flex key={index} align="center" gap="small">
              <Flex align="center" flex={1.26}>
                <Text key={index} size="medium">
                  {t('app.common.quantity').toLowerCase()} : {t('app.pricingPlans.oneAndMore', { count: 1 })}
                </Text>
              </Flex>
              <PriceItem
                className="ant-form-item--no-margin"
                name={`items[${index}][price]`}
                required
                hasFeedback={false}
              >
                <Input
                  disabled={isReadOnly}
                  name={`items[${index}][price]`}
                  type="number"
                  placeholder={t('app.common.price')}
                  size="large"
                  addonAfter={currency?.me.currency.symbol}
                />
              </PriceItem>
              <ProductName size="medium" className={values.items.length > 1 ? 'ProductName--without-button' : ''}>
                / {t('app.common.unit', { count: 1 }).toLowerCase()}
              </ProductName>
            </Flex>
          )}
          {renderGrossPrice(index)}
        </ItemContainer>
      )),
    [currency?.me.currency.symbol, handleRemoveItem, isReadOnly, renderGrossPrice, setFieldValue, t, values.items],
  );
  const renderPackItems = useCallback(
    () =>
      (values.items as EditPricingPlanItem[]).map((item, index) => (
        <ItemsContainer key={index} vertical>
          <ItemContainer className="ItemContainer" align="center" gap="small" flex={1}>
            <Text size="medium">{t('app.common.quantity').toLowerCase()} :</Text>
            <QuantityItem
              className="ant-form-item--no-margin"
              name={`items[${index}][quantity]`}
              required
              hasFeedback={false}
            >
              <InputNumber
                disabled={isReadOnly}
                name={`items[${index}][quantity]`}
                placeholder={t('app.common.quantity')}
                min={(values.items[index - 1]?.quantity || 0) + 1}
                max={values.items[index + 1] ? values.items[index + 1].quantity - 1 : undefined}
                size="large"
                precision={0}
                onChange={value => setFieldValue(`items[${index}][quantity]`, value || undefined)}
              />
            </QuantityItem>
            <PriceItem
              name={`items[${index}][price]`}
              required
              className={clsx(
                'ant-form-item--no-margin',
                values.permitAdditionalItems && 'PriceItem--pack--with-additionnalValues',
              )}
              hasFeedback={false}
            >
              <Input
                disabled={isReadOnly}
                name={`items[${index}][price]`}
                type="number"
                placeholder={t('app.common.price')}
                size="large"
                addonAfter={currency?.me.currency.symbol}
              />
            </PriceItem>
            {values.items.length > 1 && !isReadOnly && (
              <Popconfirm title={t('app.confirm.delete')} onConfirm={() => handleRemoveItem({ index })}>
                <RoundButton icon="delete" tooltipTitle={t('app.common.delete')} danger />
              </Popconfirm>
            )}
          </ItemContainer>
          {renderGrossPrice(index)}
          {values.permitAdditionalItems && (
            <ItemContainer className="ItemContainer" align="center" gap="small">
              <Flex align="center" flex={1.26}>
                <Text size="medium">{t('app.common.additionalProducts')}</Text>
              </Flex>
              <PriceItem
                className="ant-form-item--no-margin"
                name={`items[${index}][additionalPrice]`}
                required
                hasFeedback={false}
              >
                <Input
                  name={`items[${index}][additionalPrice]`}
                  type="number"
                  placeholder={t('app.common.additionalProducts')}
                  size="large"
                  addonAfter={currency?.me.currency.symbol}
                  disabled={
                    isReadOnly ||
                    (values.items[index + 1] && values.items[index].quantity + 1 === values.items[index + 1].quantity)
                  }
                />
              </PriceItem>
              <ProductName size="medium" className={values.items.length > 1 ? 'ProductName--without-button' : ''}>
                / {t('app.common.products', { count: 1 }).toLowerCase()}
              </ProductName>
            </ItemContainer>
          )}
          {values.permitAdditionalItems &&
            values.items[index + 1] &&
            values.items[index].quantity + 1 === values.items[index + 1].quantity && (
              <DisabledText size="small" weight="bold" color={Colors.waiting}>
                <Icon name="warning" size={FontSize.title} />
                <span>
                  {t('app.pricingPlans.warnings.nextPackDisablePrice', { quantity: values.items[index + 1].quantity })}
                </span>
              </DisabledText>
            )}
        </ItemsContainer>
      )),
    [
      currency?.me.currency.symbol,
      handleRemoveItem,
      isReadOnly,
      renderGrossPrice,
      setFieldValue,
      t,
      values.items,
      values.permitAdditionalItems,
    ],
  );

  const renderWholeGalleryPrice = useCallback(
    () => (
      <ItemContainer className="ItemContainer" align="center">
        <Flex align="center" flex={1.26}>
          <Text size="medium">{t('app.filter.available')}</Text>
        </Flex>
        <PriceItem className="ant-form-item--no-margin" name="wholeGalleryPrice" required hasFeedback={false}>
          <Input
            disabled={isReadOnly}
            name="wholeGalleryPrice"
            type="number"
            placeholder={t('app.filter.available')}
            size="large"
            addonAfter={currency?.me.currency.symbol}
          />
        </PriceItem>
      </ItemContainer>
    ),
    [currency?.me.currency.symbol, isReadOnly, t],
  );

  const segmentedControlValues = useMemo(() => {
    const items = [];

    if ((isReadOnly && values.type === PricingPlanType.UNITARY) || !isReadOnly) {
      items.push({
        value: PricingPlanType.UNITARY,
        label: t('app.pricingPlans.type.unitary'),
        content: (
          <>
            {renderUnitaryItems()}
            {!isReadOnly && (
              <Flex justify="center">
                <Button size="small" onClick={handleAddMore}>
                  {t('app.action.addMore')}
                </Button>
              </Flex>
            )}
            {values.withWholeGalleryPrice && renderWholeGalleryPrice()}
          </>
        ),
      });
    }

    if ((isReadOnly && values.type === PricingPlanType.PACK) || !isReadOnly) {
      items.push({
        value: PricingPlanType.PACK,
        label: t('app.pricingPlans.type.pack'),
        content: (
          <>
            <Form.Item
              label={t('app.pricingPlans.permitAdditionalItems')}
              name="permitAdditionalItems"
              className="ant-form-item--switch"
              hasFeedback={false}
            >
              <Switch name="permitAdditionalItems" disabled={isReadOnly} />
            </Form.Item>
            {renderPackItems()}
            {!isReadOnly && (
              <Flex justify="center">
                <Button size="small" onClick={handleAddMore}>
                  {t('app.action.addMore')}
                </Button>
              </Flex>
            )}
            {values.withWholeGalleryPrice && renderWholeGalleryPrice()}
          </>
        ),
      });
    }

    return items;
  }, [
    isReadOnly,
    values.type,
    values.withWholeGalleryPrice,
    t,
    renderUnitaryItems,
    handleAddMore,
    renderWholeGalleryPrice,
    renderPackItems,
  ]);

  return !isCurrencyLoading ? <SegmentedControlStyled name="type" values={segmentedControlValues} /> : null;
};

export default PricingPlanFormContent;
