import React, { forwardRef, useContext, useState } from 'react';

import { Button, Drawer, Flex, InputProps, InputRef, Spin } from 'antd';

import { FieldHookConfig, useField } from 'formik';
import { Input } from 'formik-antd';

import Icon from 'Components/Atoms/Icon';
import Title from 'Components/Atoms/Title';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  flex: 1;
  height: 100%;
`;

const DrawerImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 208px;
  height: 141px;
  margin-top: ${Metrics.baseMargin}px;
  overflow-y: hidden;
`;

const DrawerImage = styled.img`
  display: block;
  width: auto;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border: 4px solid rgba(0, 0, 0, 0);

  &:hover {
    border: 4px solid ${Colors.secondaryMain};
    border-radius: 4px;
  }

  &:active,
  &.active {
    border-color: ${Colors.secondaryOptional};
  }
`;

const DrawerPicker = forwardRef<
  InputRef,
  InputProps &
    FieldHookConfig<string | number> & {
      actionLabel: string;
      dataSource: { id: number | string; url: string }[];
      hasFetchAll: boolean;
      loadMore?: () => void;
      isLoading?: boolean;
    }
>(({ actionLabel, name, dataSource, loadMore, hasFetchAll, isLoading }, ref) => {
  const [field, _meta, helpers] = useField(name);
  const { onChange, ...rest } = field;
  const { t } = useContext(LocalizationContext);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const handleChange = ({ value }: { value: number | string }) => {
    onChange(name);
    helpers.setValue(value);
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  return (
    <div>
      <Button onClick={() => setIsDrawerVisible(true)} type="primary" block size="small">
        {actionLabel}
      </Button>
      <Input ref={ref} type="hidden" {...rest} name={name} />
      <Drawer
        destroyOnClose
        title={<Title level="h2">{actionLabel}</Title>}
        placement="right"
        onClose={onCloseDrawer}
        open={isDrawerVisible}
        width={Metrics.drawer.photo}
        closeIcon={<Icon name="close" />}
      >
        <DrawerContainer id="DrawerPicker">
          {dataSource.map(({ id, url }) => (
            <DrawerImageContainer key={id}>
              <DrawerImage
                src={url}
                onClick={() => handleChange({ value: id })}
                className={rest.value === id ? 'active' : ''}
              />
            </DrawerImageContainer>
          ))}
          <Flex justify="center" flex={1}>
            {!isLoading && !hasFetchAll && !!loadMore && <Button onClick={loadMore}>{t('app.common.loadMore')}</Button>}
          </Flex>
        </DrawerContainer>
      </Drawer>
    </div>
  );
});

export default DrawerPicker;
