import React, { useCallback, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { App } from 'antd';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { OnSubmitPayload, PaymentForm } from 'Forms/Payment';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import dayjs from 'Services/DayjsService';

import { CREATE_INVOICE_PAYMENT } from 'Operations/Mutations/Invoice/CreateInvoicePayment';

const PaymentModal = (props: { invoiceId: number; invoiceAmount: number; onSubmit?: () => void }) => {
  const { t } = useContext(LocalizationContext);
  const { closeModal } = useModals();
  const { message } = App.useApp();

  const [createInvoicePayment] = useMutation(CREATE_INVOICE_PAYMENT);

  const handleSubmit = useCallback(
    async ({ values, formikBag }: OnSubmitPayload) => {
      try {
        await createInvoicePayment({
          variables: {
            where: {
              id: props.invoiceId,
            },
            data: values,
          },
        });

        message.success(t('app.message.payment.create.success'));

        if (props.onSubmit) {
          props.onSubmit();
        }

        closeModal('PAYMENT');
        formikBag.setSubmitting(false);
      } catch (error) {
        message.error(t('app.message.error.somethingWentWrong'));
        console.error(error);
        formikBag.setSubmitting(false);
      }
    },
    [createInvoicePayment, props, t, closeModal],
  );

  return (
    <ModalContainer title={t('app.invoices.createPayment')} icon={Images.money}>
      <PaymentForm
        onSubmit={handleSubmit}
        defaultValues={{ amount: props.invoiceAmount, paymentDate: dayjs().toISOString() }}
      />
    </ModalContainer>
  );
};

export default PaymentModal;
