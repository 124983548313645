import { gql } from 'Operations/__generated__';

export const ON_JOB_UPDATED = gql(/* GraphQL */ `
  subscription OnJobUpdated($where: JobSubscriptionWhereType) {
    OnJobUpdated(where: $where) {
      id
      jobType
      percent
      status
      createdAt
      gallery {
        id
        galleryAppearance {
          id
          coverUrl
          coverUrlLoader
        }
      }
      order {
        id
      }
      picklist {
        id
      }
    }
  }
`);
