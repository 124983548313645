import React, { useContext } from 'react';

import { Card } from 'antd';

import Container from 'Components/Atoms/Container';
import Text from 'Components/Atoms/Text';

import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const CardContainer = styled(Card)`
  max-width: 180px;
`;

interface DeliveryInfoCardProps {
  receiver: string;
  address: string;
}

const DeliveryInfoCard = ({ receiver, address }: DeliveryInfoCardProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <CardContainer bordered={false}>
      <Container direction="column" align="center">
        <Text size="small">{t('app.common.deliveryAddress')}</Text>
        <Text weight="bold">{receiver}</Text>
        <Text size="small" weight="bold" align="center">
          {address}
        </Text>
      </Container>
    </CardContainer>
  );
};

export default DeliveryInfoCard;
