import { useContext, useMemo } from 'react';

import { Dropdown, Menu, Popconfirm, Spin } from 'antd';

import { MenuClickEventHandler } from 'rc-menu/lib/interface';

import Container from 'Components/Atoms/Container';
import Icon, { IconName } from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { GetFoldersQuery } from 'Operations/__generated__/graphql';

const StyledContainer = styled(Container)`
  align-items: center;
`;

const StyledRoundButton = styled(RoundButton)`
  margin-right: 8px;
`;

const DeleteButton = styled(StyledRoundButton)``;
const MoveButton = styled(StyledRoundButton)``;
const CancelButton = styled(StyledRoundButton)``;

const StyledText = styled(Text)`
  margin-right: 8px;
  color: ${Colors.primaryMain};
  font-weight: bold;
`;

const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  justify-content: center;
  & svg {
    margin-top: 4px;
    margin-left: 16px;
  }
`;
const SpinContainer = styled(Container)`
  align-items: center;
  margin-left: 8px;
  margin-top: 4px;
`;

interface Props {
  selectionCount: number;
  onAddToOrderClicked?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
  onMove?: (folderId: number | null) => void;
  onLightroom?: () => void;
  folders?: GetFoldersQuery['getFolders'];
  selectedFolderId?: number | null;
  actions?: ActionOption[];
  onActionClicked?: (key: string) => void;
  onSelectAll?: () => void;
  working?: boolean;
}
export type ActionOption = {
  key: string;
  name: string;
  icon: IconName;
};

const SelectionToolbar = ({
  selectionCount,
  folders = [],
  selectedFolderId,
  onAddToOrderClicked,
  onDelete,
  onMove,
  onCancel,
  onSelectAll,
  onLightroom,
  actions,
  onActionClicked,
  working,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const onCancelClicked = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const onDeleteClicked = () => {
    if (onDelete) {
      onDelete();
    }
  };

  const handleMenuClick: MenuClickEventHandler = e => {
    console.log(e);
    if (onActionClicked) {
      onActionClicked(`${e.key}`);
    }
  };

  const folderMenu = useMemo(() => {
    if (!folders) {
      return null;
    }
    const getMenu = (currentFolder: GetFoldersQuery['getFolders'][0]) => {
      const subFolders = folders
        .filter(folder => folder.parentId === currentFolder.id && selectedFolderId !== folder.id)
        .sort((a, b) => a.name.localeCompare(b.name));
      if (subFolders.length === 0) {
        return (
          <Menu.Item key={currentFolder.id} onClick={() => onMove && onMove(currentFolder.id)}>
            {currentFolder.name}
          </Menu.Item>
        );
      }
      return (
        <Menu.SubMenu
          key={currentFolder.id}
          onTitleClick={() => onMove && onMove(currentFolder.id)}
          title={currentFolder.name}
        >
          {subFolders.map(folder => getMenu(folder))}
        </Menu.SubMenu>
      );
    };

    const rootFolders = folders.filter(folder => folder.parentId === null && folder.id !== selectedFolderId);
    return (
      <Menu>
        {selectedFolderId !== null && (
          <Menu.Item key="root" onClick={() => onMove && onMove(null)}>
            {t('app.gallery.photos.withoutFolder')}
          </Menu.Item>
        )}
        {rootFolders.map(folder => getMenu(folder))}
      </Menu>
    );
  }, [folders, t, onMove, selectedFolderId]);

  const actionMenu = actions ? (
    <Menu onClick={handleMenuClick}>
      {actions.map(x => {
        const icon = <Icon name={x.icon}></Icon>;
        return (
          <StyledMenuItem key={x.key}>
            {x.name}
            {icon}
          </StyledMenuItem>
        );
      })}
    </Menu>
  ) : undefined;

  if (working) {
    return (
      <SpinContainer justify="center" align="center">
        <Spin />
      </SpinContainer>
    );
  }

  if (selectionCount === 0) {
    return (
      <StyledContainer>
        {onSelectAll && (
          <StyledRoundButton
            icon="duplicate"
            iconSize={18}
            size="middle"
            onClick={onSelectAll}
            tooltipTitle={t('app.common.selectionbar.selectAll')}
          />
        )}
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      {actionMenu && (
        <Dropdown overlay={actionMenu} placement="bottomCenter">
          <StyledRoundButton icon="more" size="middle" />
        </Dropdown>
      )}
      <StyledText>{t('app.common.selectionbar.selectCount', { count: selectionCount })}</StyledText>
      {onLightroom && (
        <StyledRoundButton
          icon="lightroom"
          iconSize={18}
          size="middle"
          onClick={onLightroom}
          tooltipTitle={t('app.common.selectionbar.ligthroom')}
        />
      )}
      {onMove && folderMenu && (
        <Dropdown overlay={folderMenu}>
          <MoveButton icon="chevron-right" size="middle" tooltipTitle={t('app.common.move')} />
        </Dropdown>
      )}
      {onAddToOrderClicked && (
        <StyledRoundButton
          icon="purchased"
          size="middle"
          tooltipTitle={t('app.gallery.photos.addToOrder.action')}
          onClick={onAddToOrderClicked}
        />
      )}
      {onDelete && (
        <Popconfirm
          title={t(`app.confirm.delete`)}
          // @ts-ignore
          onClick={e => e.stopPropagation()}
          onConfirm={onDeleteClicked}
        >
          <DeleteButton icon="delete" size="middle" tooltipTitle={t('app.common.delete')} />
        </Popconfirm>
      )}
      {onCancel && (
        <CancelButton icon="close" size="middle" onClick={onCancelClicked} tooltipTitle={t('app.common.unselect')} />
      )}
      {working && (
        <SpinContainer justify="center" align="center">
          <Spin />
        </SpinContainer>
      )}
    </StyledContainer>
  );
};

export default SelectionToolbar;
