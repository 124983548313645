import { FeatureStatus } from 'Operations/__generated__/graphql';

export const getFeatureRequestStatusColor = (status?: FeatureStatus) => {
  switch (status) {
    case FeatureStatus.PLANNED:
      return { tag: 'blue', background: '#91f5ff' };

    case FeatureStatus.IN_PROGRESS:
      return { tag: 'green', background: '#b7eb8f' };

    case FeatureStatus.LIVE:
      return { tag: 'magenta', background: '#ffadd2' };

    case FeatureStatus.CLOSED:
      return { tag: 'red', background: '#ffa39e' };

    default:
      return { tag: undefined, background: '#fafafa' };
  }
};
