import { createContext, useCallback, useContext, useState } from 'react';

import { DeepPartial } from '@apollo/client/utilities';

import PhotoDrawer from 'Components/Molecules/Drawers/PhotoDrawer';

import { PhotoAdmin } from 'Operations/__generated__/graphql';

export interface PhotoDrawer {
  closeDrawer: () => void;
  openDrawer: (props: {
    photo?: DeepPartial<PhotoAdmin>;
    isLoading: boolean;
    isGalleryButtonVisible?: boolean;
  }) => void;
  isGalleryButtonVisible?: boolean;
}
const initialState: PhotoDrawer = {
  closeDrawer: () => {
    throw new Error('Not implemented');
  },
  openDrawer: () => {
    throw new Error('Not implemented');
  },
  isGalleryButtonVisible: false,
};

export const PhotoDrawerContext = createContext(initialState);
export const usePhotoDrawer = () => useContext(PhotoDrawerContext);

export const PhotoDrawerProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState<DeepPartial<PhotoAdmin> | undefined>(undefined);
  const [isGalleryButtonVisible, setIsGalleryButtonVisible] = useState(false);

  const closeDrawer = useCallback(() => {
    setIsOpen(false);
    setPhoto(undefined);
  }, []);

  const openDrawer = useCallback(
    (data: { photo?: DeepPartial<PhotoAdmin>; isLoading: boolean; isGalleryButtonVisible?: boolean }) => {
      if (!isOpen) {
        setIsOpen(true);
      }
      if (data.photo) {
        setPhoto(data.photo);
      }
      setIsLoading(data.isLoading);
      setIsGalleryButtonVisible(!!data.isGalleryButtonVisible);
    },
    [isOpen],
  );

  return (
    <PhotoDrawerContext.Provider value={{ closeDrawer, openDrawer, isGalleryButtonVisible }}>
      {children}
      <PhotoDrawer
        isVisible={isOpen}
        onCloseDrawer={closeDrawer}
        photo={photo}
        isLoading={isLoading}
        isGalleryButtonVisible={isGalleryButtonVisible}
      />
    </PhotoDrawerContext.Provider>
  );
};
