import { gql } from 'Operations/__generated__';

export const GET_PROJECTS = gql(/* GraphQL */ `
  query GetProjects($where: ProjectsWhereInput) {
    getProjects(where: $where) {
      _count
      edges {
        id
        name
        startTime
        contactId
        contact {
          ...ContactCoreFields
        }
        secondaryContactsIds
        secondaryContacts {
          ...ContactCoreFields
        }
      }
    }
  }
`);
