import React, { useContext, useState } from 'react';

import { Button, Flex } from 'antd';

import Text from 'Components/Atoms/Text';

import OrderStatusDropdown from 'Components/Molecules/OrderStatusDropdown';

import { LocalizationContext } from 'i18n';

import { OrderItemStatus } from 'Operations/__generated__/graphql';

const availableItemStatuses = [
  OrderItemStatus.WAITING,
  OrderItemStatus.ORDERED,
  OrderItemStatus.TRANSIT,
  OrderItemStatus.DELIVERED,
  OrderItemStatus.CANCELLED,
  OrderItemStatus.PROBLEM,
];

type Props = {
  selectedRowsIds: number[];
  onChange?: (data: { ids: number[]; status: OrderItemStatus }) => void;
  loading?: boolean;
};

const OrderBulkEdit = ({ selectedRowsIds, onChange, loading }: Props) => {
  const { t } = useContext(LocalizationContext);
  const [selectedStatus, setSelectedStatus] = useState<undefined | OrderItemStatus>();

  return (
    <Flex gap="large" align="center">
      <Text>{t('app.common.selectionbar.selectCount', { count: selectedRowsIds.length })}</Text>
      <Flex gap="small" align="center">
        <Text>{t('app.common.status')}</Text>
        <OrderStatusDropdown
          availableItemStatuses={availableItemStatuses}
          onChange={({ status }) => setSelectedStatus(status)}
          isEmptyState
          placement="topLeft"
          status={selectedStatus}
          width={'125px'}
        />
      </Flex>
      <Button
        disabled={!selectedStatus || loading}
        loading={loading}
        type="primary"
        onClick={selectedStatus ? () => onChange?.({ ids: selectedRowsIds, status: selectedStatus }) : undefined}
      >
        {t('app.common.edit')}
      </Button>
    </Flex>
  );
};

export default OrderBulkEdit;
