import React, { KeyboardEvent, useRef, useState } from 'react';

import { createStyles } from 'antd-style';

import Icon, { IconName } from 'Components/Atoms/Icon';

import { Colors } from 'Themes';

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: ${token.lineWidth}px solid ${token.colorPrimary};
    border-radius: 16px;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
    height: 32px;
  `,
  input: css`
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 14px;
    outline: none;
    appearance: none;
    box-sizing: border-box;
    transition: width 0.3s ease-in-out;
  `,
  iconContainer: css`
    display: flex;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      color: ${token.colorPrimary};
    }

    &:hover {
      background-color: ${token.colorPrimary};

      svg {
        color: ${token.colorText};
      }
    }
  `,
  closeContainer: css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 32px;
    padding: 2px;
    color: ${Colors.white};
    background-color: ${Colors.grey};
    border-radius: 50%;
    cursor: pointer;
  `,
}));

export interface ExpendableInputProps {
  onSubmit: (value: string | null) => void;
  iconName: IconName;
}

const ExpendableInput = ({ onSubmit, iconName, ...props }: ExpendableInputProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [value, setValue] = useState<string | undefined>();
  const [isFocused, setIsFocused] = useState(false);
  const { styles, theme } = useStyles();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleIconClick = () => {
    setIsExpanded(true);

    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }

    if (value) {
      onSubmit(value);
    }
  };

  const handleMouseLeave = () => {
    if (!value) {
      setIsExpanded(false);

      handleClearValue();

      if (inputRef && inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    const { key } = e;
    if (key === 'Escape') {
      handleClearValue();
    } else if (key === 'Enter') {
      handleIconClick();
    }
  };

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  const handleClearValue = () => {
    setValue(undefined);
    onSubmit(null);

    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.focus();
    }
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleOnBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      onMouseLeave={handleMouseLeave}
      className={styles.container}
      style={{ boxShadow: isFocused ? `0 0 0 2px ${theme.primaryShadowColor}` : 'none' }}
      {...props}
    >
      <input
        ref={inputRef}
        type="text"
        className={styles.input}
        style={{
          width: isExpanded ? 200 : 0,
          paddingLeft: isExpanded ? theme.sizeSM : 0,
        }}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyDown}
      />
      {value && (
        <div className={styles.closeContainer} onClick={handleClearValue}>
          <Icon name="close" size={10} />
        </div>
      )}
      <div className={styles.iconContainer} onClick={handleIconClick}>
        <Icon name={iconName} size={22} />
      </div>
    </div>
  );
};

export default ExpendableInput;
