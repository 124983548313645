import { useEffect, useState } from 'react';

export default function useSize(ref: React.RefObject<HTMLElement>): DOMRectReadOnly | null {
  const [size, setSize] = useState<DOMRectReadOnly | null>(null);

  useEffect(() => {
    if (ref.current == null) return;

    const observer = new ResizeObserver(([entry]) => {
      if (entry && entry.contentRect) {
        setSize(entry.contentRect);
      }
    });
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref]);

  return size;
}
