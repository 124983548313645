import { gql } from 'Operations/__generated__';

export const MOVE_FOLDER = gql(/* GraphQL */ `
  mutation MoveFolder($where: FolderMoveWhereType!, $data: FolderMoveDataType!) {
    moveFolder(where: $where, data: $data) {
      id
      name
      parentId
      galleryId
    }
  }
`);
