import { gql } from 'Operations/__generated__';

export const UPDATE_FOLDER = gql(/* GraphQL */ `
  mutation UpdateFolder($where: FolderUpdateWhereType!, $data: FolderUpdateDataType!) {
    updateFolder(where: $where, data: $data) {
      id
      name
      parentId
    }
  }
`);
