import React from 'react';

import ContentLoader from 'react-content-loader';

const PhotosSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      width={576}
      height={144}
      viewBox="0 0 576 144"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <path d="M 6 28 h 132 v 87.78 H 6 z M 150 28 h 132 v 87.78 H 150 z M 294 28 h 132 v 87.78 H 294 z M 438 28 h 132 v 87.78 H 438 z" />
    </ContentLoader>
  );
};

export default PhotosSkeleton;
