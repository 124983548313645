import { useCallback, useEffect, useState } from 'react';

import Maintenance from 'Pages/App/Maintenance';

import { onAppStartup } from 'Services/AppLifeCycle';

import sleep from 'Helpers/Sleep';

const AppLifecycleProvider = ({ children }: { children: JSX.Element }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMaintenance, setisMaintenance] = useState(false);

  const init = useCallback(async () => {
    try {
      await onAppStartup();
    } catch (error) {
      console.error(error);
      if (error.message === 'MAINTENANCE_ACTIVATED') {
        setisMaintenance(true);
      }
    } finally {
      setIsLoaded(true);
      await sleep(100); // Ensures children are rendered to avoid white screen jump
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  if (isLoaded) {
    if (isMaintenance) {
      return <Maintenance />;
    }
    return children;
  }

  return null;
};

export default AppLifecycleProvider;
