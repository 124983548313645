import { useCallback, useContext, useMemo } from 'react';

import { Tutorial } from 'types/Tutorial';

import { LocalizationContext } from 'i18n';

interface OpenTutorialProps {
  tutorial: Tutorial;
}

const tutorials: Record<string, Record<Tutorial, string>> = {
  fr: {
    [Tutorial.GalleryPresets]: 'modeles-de-galerie-gagnez-un-temps-precieux-pja92v',
  },
};

export const useTutorial = () => {
  const { locale } = useContext(LocalizationContext);

  const openTutorial = useCallback(
    ({ tutorial }: OpenTutorialProps): void => {
      if (window.$crisp && tutorials[locale] && tutorials[locale][tutorial]) {
        window.$crisp.push(['do', 'helpdesk:article:open', [locale, tutorials[locale][tutorial]]]);
      }
    },
    [locale],
  );

  const closeTutorial = useCallback((): void => {
    if (window.$crisp) {
      window.$crisp.push(['do', 'chat:close']);
    }
  }, []);

  return { openTutorial, closeTutorial };
};
