import { gql } from 'Operations/__generated__';

export const GET_USER_CURRENCY = gql(/* GraphQL */ `
  query GetUserCurrency {
    me {
      id
      currency {
        id
        name
        symbol
        iso
      }
    }
  }
`);
