import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, App, Spin } from 'antd';

import ContentContainer from 'Components/Atoms/ContentContainer';

import StripeCard from 'Components/Molecules/StripeStatusCard';

import { Images, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { PaymentStatus } from 'Operations/__generated__/graphql';

import { ME } from 'Operations/Queries/User/Me';

import { DISABLE_STRIPE_PAYMENT } from 'Operations/Mutations/User/DisableStripePayment';
import { UPDATE_STRIPE_PAYMENT } from 'Operations/Mutations/User/UpdateStripePayment';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  width: 67%;
`;

const StripeStatusCard = styled(StripeCard)`
  width: 100%;
  margin-top: ${Metrics.smallMargin}px;
`;

const StripeConnect = styled.img`
  display: block;
  width: 180px;
  height: 38px;
  cursor: pointer;
  margin-top: ${Metrics.smallMargin}px;
`;

const StripeUrl = process.env.REACT_APP_STRIPE_CONNECT_URL;

const StripeTab = () => {
  const { message } = App.useApp();
  const { t, isInitialized } = useContext(LocalizationContext);

  const isStripeCodeSend = useRef(false);

  const { data: currentUser, loading: isUserLoading } = useQuery(ME, {
    fetchPolicy: 'cache-first',
  });

  const [updateStripePayment] = useMutation(UPDATE_STRIPE_PAYMENT);
  const [disableStripePayment] = useMutation(DISABLE_STRIPE_PAYMENT);

  const updateStripe = useCallback(
    async ({ stripeCode }: { stripeCode: string }) => {
      try {
        isStripeCodeSend.current = true;

        await updateStripePayment({
          variables: {
            data: {
              code: stripeCode,
            },
          },
        });

        message.success(t('app.message.payments.stripe.update.success'));
      } catch (error) {
        console.log(error);
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [t, updateStripePayment],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const stripeCode = urlParams.get('code');
    const stripeScope = urlParams.get('scope');

    if (stripeCode && stripeScope && !isStripeCodeSend.current && isInitialized) {
      updateStripe({ stripeCode });
    }
  }, [updateStripe, isInitialized]);

  const handleGoToStripe = () => {
    if (StripeUrl) {
      window.location.assign(StripeUrl);
    } else {
      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  const handleDisableStripe = useCallback(async () => {
    try {
      await disableStripePayment();

      message.success(t('app.message.payments.stripe.disable.success'));
    } catch (error) {
      console.log(error);
      message.error(t('app.message.error.somethingWentWrong'));
    }
  }, [disableStripePayment, t]);

  return (
    <StyledTableContentContainer padding="baseMargin" shadow rounded>
      <Alert type="info" message={t('app.settings.payments.stripe.description')} />
      {isUserLoading && <Spin />}
      {!isUserLoading && currentUser?.me.stripePayment.status === PaymentStatus.NOT_CONFIGURED && (
        <StripeConnect src={Images.stripeConnect} alt="Stripe Connect" onClick={handleGoToStripe} />
      )}
      {!isUserLoading && currentUser && currentUser?.me.stripePayment.status !== PaymentStatus.NOT_CONFIGURED && (
        <StripeStatusCard status={currentUser.me.stripePayment.status} onDisableClick={handleDisableStripe} />
      )}
    </StyledTableContentContainer>
  );
};

export default StripeTab;
