import { gql } from 'Operations/__generated__';

export const GET_GALLERIES_NAME = gql(/* GraphQL */ `
  query GetGalleriesName($where: GalleriesGetWhereType!) {
    getGalleries(where: $where) {
      _count
      edges {
        id
        name
      }
    }
  }
`);
