import { gql } from 'Operations/__generated__';

export const GET_GALLERY_PRESET = gql(/* GraphQL */ `
  query GetGalleryPreset($where: IdInput!) {
    getGalleryPreset(where: $where) {
      id
      name
      accessPolicy
      watermark {
        id
      }
      locale
      watermarkMode
      workmode
      createdAt
      catalog {
        id
      }
      galleryAppearance {
        ...GalleryAppearanceFields
      }
      theme {
        ...GalleryThemeCoreFields
      }
      includedProducts {
        ...IncludedProductCoreFields
      }
      mandatoryProduct {
        id
        name
      }
    }
  }
`);
