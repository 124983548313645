import { gql } from 'Operations/__generated__';

export const GET_EMAIL_TEMPLATES = gql(/* GraphQL */ `
  query GetEmailTemplates($where: EmailTemplatesGetInputType!) {
    getEmailTemplates(where: $where) {
      _count
      edges {
        id
        associatedModel
        type @client
        title
        name
        createdAt
      }
    }
  }
`);
