import { gql } from 'Operations/__generated__';

export const GET_PRODUCT_FILTERS = gql(/* GraphQL */ `
  query GetProductFilters($where: ProductFiltersWhereInput) {
    getProductFilters(where: $where) {
      categories {
        id
        value
        name
      }
      contacts {
        id
        value
        name
      }
    }
  }
`);
