import { gql } from 'Operations/__generated__';

export const GET_FEATURE_REQUEST = gql(/* GraphQL */ `
  query GetFeatureRequest($where: IdInput!, $commentsWhere: PaginatedInput) {
    getFeatureRequest(where: $where) {
      ...FeatureRequestCoreFields
      comments(where: $commentsWhere) {
        _count
        edges {
          id
          comment
          userName
          createdAt
        }
      }
    }
  }
`);
