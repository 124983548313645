import { Color } from 'antd/es/color-picker';
import { ColorFactory } from 'antd/es/color-picker/color';
import tinycolor from 'tinycolor2';

export const getRGBString = (color: Color, isAlpha?: boolean) => {
  const { r, g, b, a } = color.toRgb();

  return `${r},${g},${b},${a}`;
};

export function getComplimentary(color: ColorFactory) {
  return new ColorFactory(tinycolor(color.toHexString()).complement().toHexString());
}

export function getReadableColor({
  backgroundColor,
  color = '#000',
  includeFallbackColors = false,
}: {
  backgroundColor: string;
  color?: string;
  includeFallbackColors?: boolean;
}) {
  return tinycolor.mostReadable(backgroundColor, [color], { includeFallbackColors });
}

export function isReadableColor({ backgroundColor, color }: { backgroundColor: string; color: string }) {
  return tinycolor.isReadable(tinycolor(color), tinycolor(backgroundColor));
}
