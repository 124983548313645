import { useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { kebabCase } from 'lodash';

import MenuLink from 'Components/Molecules/MenuLink';

import { LocalizationContext } from 'i18n';

import { useMenuRight } from 'Hooks/MenuRightProvider';

import { OrderItemStatus } from 'Operations/__generated__/graphql';

const statuses: string[] = [
  OrderItemStatus.WAITING_RETOUCH,
  OrderItemStatus.WAITING,
  OrderItemStatus.ORDERED,
  OrderItemStatus.TRANSIT,
  OrderItemStatus.DELIVERED,
  OrderItemStatus.CANCELLED,
];

export const useOrdersCategoriesMenu = () => {
  const { t } = useContext(LocalizationContext);
  const { setItems } = useMenuRight();
  const { pathname, search } = useLocation();

  useEffect(() => {
    setItems([
      {
        parentPath: 'orders',
        content: statuses.map(status => (
          <MenuLink
            key={`menu-right-column-${status.toLowerCase()}`}
            href={`/app/orders/status/${kebabCase(status)}`}
            text={t(`app.orders.orderItemStatus.${status.toLowerCase()}`)}
            rightIcon="chevron-right"
          />
        )),
      },
    ]);
  }, [pathname, search, setItems, t]);
};
