import React, { useContext } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { DatePicker, Form, Input } from 'formik-antd';

import Select from 'Components/Molecules/Form/Select';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

import { GET_PAYMENT_TYPES } from 'Operations/Queries/PaymentType/GetPaymentTypes';
import { GET_USER_CURRENCY } from 'Operations/Queries/User/GetUserCurrency';

export interface PaymentFormValues {
  amount: number;
  paymentTypeId: number;
  paymentDate: string;
  transactionId?: string;
  comptaCode?: string;
  comment?: string;
}

export interface PaymentFormDefaultValues {
  amount: number;
  paymentDate: string;
}

const Payment = <T extends PaymentFormValues>({ isSubmitting }: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);

  const { data: currency } = useQuery(GET_USER_CURRENCY, {
    fetchPolicy: 'cache-first',
  });

  const { data: paymentTypes } = useQuery(GET_PAYMENT_TYPES);

  return (
    <Form layout="vertical">
      <Flex gap="middle">
        <Form.Item label={t('app.common.amount')} name="amount" required hasFeedback={false}>
          <Input
            name="amount"
            size="large"
            type="number"
            min={0}
            addonAfter={currency?.me.currency.symbol}
            step="0.01"
          />
        </Form.Item>

        <Form.Item label={t('app.payment.paymentType')} name="paymentTypeId" required hasFeedback={false}>
          <Select
            name="paymentTypeId"
            size="large"
            optionFilterProp="children"
            loading={false}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {(paymentTypes?.getPaymentTypes.edges || []).map(paymentType => (
              <Select.Option key={paymentType.id} value={paymentType.id} title={''}>
                {paymentType.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Flex>

      <Form.Item label={t('app.payment.paymentDate')} name="paymentDate" required hasFeedback={false}>
        <DatePicker name="paymentDate" size="large" />
      </Form.Item>

      <Flex gap="middle">
        <Form.Item label={t('app.payment.transactionId')} name="transactionId" hasFeedback={false}>
          <Input name="transactionId" size="large" />
        </Form.Item>

        <Form.Item label={t('app.payment.comptaCode')} name="comptaCode" hasFeedback={false}>
          <Input name="comptaCode" size="large" />
        </Form.Item>
      </Flex>

      <Form.Item label={t('app.common.comment', { count: 1 })} name="comment" hasFeedback={false}>
        <Input.TextArea name="comment" size="large" />
      </Form.Item>

      <Flex gap="middle" justify="flex-end" align="center">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t('app.common.add')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface OnSubmitPayload {
  values: PaymentFormValues;
  formikBag: FormikBag<OtherPaymentFormProps, PaymentFormValues>;
}

export interface OtherPaymentFormProps {
  onSubmit: (payload: OnSubmitPayload) => void;
  defaultValues: PaymentFormDefaultValues;
}

export const PaymentForm = withFormik<OtherPaymentFormProps, PaymentFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    ...defaultValues,
    paymentTypeId: undefined as unknown as number,
    transactionId: '',
    comptaCode: '',
    comment: '',
  }),
  validationSchema: yup.object({
    amount: yup.number().required(),
    paymentTypeId: yup.number().required(),
    paymentDate: yup.string().required(),
    transactionId: yup.string().trim(),
    comptaCode: yup.string().trim(),
    comment: yup.string().trim(),
  }),
})(Payment);
