import { gql } from 'Operations/__generated__';

export const ADD_FEATURE_VOTE = gql(/* GraphQL */ `
  mutation AddFeatureVote($where: IdInput!) {
    addVoteFeatureRequest(where: $where) {
      id
      hasVoted
      votesCount
    }
  }
`);
