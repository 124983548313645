import { ApolloCache } from '@apollo/client';

export const updateFolderPhotosCount = ({
  folderId,
  count,
  cache,
}: {
  folderId: number;
  count: number;
  cache: ApolloCache<unknown>;
}) => {
  cache.modify({
    id: `Folder:${folderId}`,
    fields: {
      photos(existing) {
        return {
          ...existing,
          _count: existing._count + count,
        };
      },
    },
  });
};
