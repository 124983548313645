import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import MenuLink from 'Components/Molecules/MenuLink';

import { Colors } from 'Themes';

import { useMenuRight } from 'Hooks/MenuRightProvider';

import { GET_FEATURE_CATEGORIES } from 'Operations/Queries/FeatureRequest/GetFeatureCategories';

export const useFeatureRequestCategoriesMenu = () => {
  const { setItems } = useMenuRight();
  const { pathname } = useLocation();
  const { data: categoriesData } = useQuery(GET_FEATURE_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (categoriesData?.getFeaturesCategories) {
      setItems([
        {
          parentPath: 'feature-requests',
          content: categoriesData.getFeaturesCategories.map(category => (
            <MenuLink
              key={`menu-right-column-${category.id}`}
              href={`/app/feature-requests/category/${category.id}`}
              text={category.name}
              rightIcon="chevron-right"
              count={category.featureRequests._count}
              countColor={Colors.secondaryMain}
            />
          )),
        },
      ]);
    }
  }, [categoriesData, pathname, setItems]);
};
