import React, { useContext } from 'react';

import ContentLoader from 'react-content-loader';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Layout from 'Components/Atoms/Layout';
import Title from 'Components/Atoms/Title';

import HeaderSkeleton from 'Components/Molecules/Skeletons/HeaderSkeleton';
import TransportFeeTable from 'Components/Molecules/Tables/TransportFeeTable';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const ContainerStyled = styled(Container)`
  flex: 1;
  margin-top: ${Metrics.baseMargin}px;
`;

const TransportFeeSkeleton = () => {
  const { t } = useContext(LocalizationContext);

  return (
    <>
      <HeaderSkeleton />
      <Layout flexDirection="column">
        <ContainerStyled gap={Metrics.baseMargin}>
          <Container flex={1} direction="column">
            <Title level="h4">{t('app.contact.transportModeToUse')}</Title>
            <ContentContainer shadow rounded full padding="baseMargin">
              <ContentLoader
                speed={2}
                style={{ width: '100%' }}
                viewBox="0 0 570 40"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="20" ry="20" width="570" height="40" />
              </ContentLoader>
            </ContentContainer>
          </Container>
          <Container flex={1} direction="column">
            <Title level="h4">{t('app.contact.fixedPrice')}</Title>
            <ContentContainer shadow rounded full padding="baseMargin">
              <ContentLoader
                speed={2}
                style={{ width: '100%' }}
                viewBox="0 0 570 40"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="20" ry="20" width="570" height="40" />
              </ContentLoader>
            </ContentContainer>
          </Container>
        </ContainerStyled>
        <ContainerStyled direction="column">
          <Title level="h4">{t('app.contact.transportFees.zonesAndWeightRanges')}</Title>
          <ContentContainer shadow rounded full padding="baseMargin">
            <TransportFeeTable
              loading={true}
              data={[]}
              isActive={false}
              currency={''}
              vat={0}
              handleOnChange={() => null}
              handleEditCountries={() => null}
              handleDeleteConfirm={() => null}
            />
          </ContentContainer>
        </ContainerStyled>
      </Layout>
    </>
  );
};

export default TransportFeeSkeleton;
