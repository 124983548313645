import { ApolloError } from '@apollo/client';

const getErrorCode = (error: ApolloError) => {
  const graphQLErrors = error?.graphQLErrors;
  if (graphQLErrors.length > 0) {
    return graphQLErrors[0].extensions?.code as string;
  }
  return undefined;
};
export default getErrorCode;
