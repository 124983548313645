import { createStyles } from 'antd-style';

export const useButtonStyle = createStyles(({ css, prefixCls, token }) => ({
  className: css`
    &.${prefixCls}-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      min-height: ${token.sizeXL}px;
      min-width: ${token.sizeXL}px;
      gap: ${token.sizeXS}px;
      border-radius: 9999px;
      box-shadow: none;
      font-weight: bold;
      white-space: wrap;
      &:not(.${prefixCls}-btn-link) {
        text-transform: uppercase;
      }
      &.${prefixCls}-btn-link {
        padding: 0;
        text-align: left;
        justify-content: flex-start;
      }
      &:not(:disabled) {
        &.${prefixCls}-btn-circle {
          aspect-ratio: 1 / 1;
          justify-content: center;
          align-items: center;

          &.${prefixCls}-btn-link {
            &:hover {
              background-color: ${token.primaryShadowColor};
            }
          }
        }
        &.${prefixCls}-btn-dangerous {
          color: ${token.colorError};
          &:hover {
            background-color: ${token.colorError};
            border-color: ${token.colorError};
            color: ${token.colorWhite};
          }
        }
      }
    }
  `,
}));
