import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import {
  EditPricingPlanForm,
  EditPricingPlanFormProps,
  NewPricingPlanForm,
  NewPricingPlanFormProps,
} from 'Forms/PricingPlan';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

interface NewPricingModalProps extends NewPricingPlanFormProps {
  name: 'new';
}
interface EditPricingModalProps extends EditPricingPlanFormProps {
  name: 'edit';
}

const GalleryModal = (props: NewPricingModalProps | EditPricingModalProps) => {
  const { t } = useContext(LocalizationContext);
  const renderForm = () => {
    if (props.name === 'edit') {
      return <EditPricingPlanForm onSubmit={props.onSubmit} defaultValues={props.defaultValues} />;
    }

    return <NewPricingPlanForm onSubmit={props.onSubmit} defaultValues={props.defaultValues} />;
  };

  return (
    <ModalContainer title={t(`app.pricingPlan.modal.${props.name}`)} icon={Images.gallery}>
      {renderForm()}
    </ModalContainer>
  );
};

export default GalleryModal;
