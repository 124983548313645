import React, { useContext, useState } from 'react';

import { useQuery } from '@apollo/client';

import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';
import InvoiceTable from 'Components/Molecules/Tables/InvoiceTable';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { OrderType } from 'Operations/__generated__/graphql';

import { GET_INVOICES } from 'Operations/Queries/Invoice/GetInvoices';

const PER_PAGE = 20;

const StyledTableContentContainer = styled(Layout)`
  margin-top: ${Metrics.baseMargin}px;
`;

const InvoicesIndex = () => {
  const { t } = useContext(LocalizationContext);

  const [page, setPage] = useState(1);

  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery(GET_INVOICES, {
    variables: {
      where: {
        page: 1,
        perPage: PER_PAGE,
        sortOrder: OrderType.DESC,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const total = data?.getInvoices._count || 0;
  const invoices = data?.getInvoices.edges || [];

  return (
    <>
      <Header
        title={t('app.common.invoices', { count: 2 })}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.invoices', { count: 2 }) },
        ]}
        buttons={[]}
      />
      <StyledTableContentContainer flexDirection="column">
        <InvoiceTable
          data={invoices}
          withGallery
          isLoading={isLoading}
          total={total}
          paginationCurrent={page}
          paginationSize={PER_PAGE}
          handleOnChange={(props: { page: number; sort: 'desc' | 'asc' }) => {
            setPage(props.page);
            fetchMore({
              variables: {
                where: {
                  page: props.page,
                  perPage: PER_PAGE,
                  sortOrder: props.sort,
                },
              },
            });
          }}
        />
      </StyledTableContentContainer>
    </>
  );
};

export default InvoicesIndex;
