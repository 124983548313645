import { gql } from 'Operations/__generated__';

export const GET_PAGINATED_CATALOGS = gql(/* GraphQL */ `
  query GetPaginatedCatalogs($where: PaginatedInput) {
    getPaginatedCatalogs: getCatalogs(where: $where) {
      _count
      edges {
        id
        name
        isDefault
        vat
        createdAt
        products {
          _count
        }
      }
    }
  }
`);
