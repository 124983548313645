import { gql } from 'Operations/__generated__';

export const GET_STORAGE = gql(/* GraphQL */ `
  query GetStorage {
    getStorage {
      usedSpace
      maxSpace
      percent
      photos
      galleries
    }
  }
`);
