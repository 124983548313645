import React, { useContext, useMemo } from 'react';

import { Flex, Tooltip } from 'antd';
import { createStyles } from 'antd-style';

import { camelCase } from 'lodash';

import Spinner from 'Components/Atoms/Spinner';

import { LocalizationContext } from 'i18n';

import {
  GetGalleriesQuery,
  Job,
  JobStatus,
  JobType
} from 'Operations/__generated__/graphql';

interface Props {
  jobs: Partial<Job>[];
  children?: React.ReactNode;
}

const useStyles = createStyles(({ css, token }) => ({
  tooltip: css`
    display: flex;
    align-items: center;
    gap: ${token.sizeXS}px;
  `,
}));

const GalleryJobTitle = ({ jobs, children }: Props) => {
  const { t } = useContext(LocalizationContext);
  const { styles } = useStyles();

  const activeJobs = useMemo(
    () =>
      jobs
        .filter(job => {
          return (
            (job.status === JobStatus.RUNNING || job.status === JobStatus.WAITING) &&
            (job.jobType === JobType.GALLERY_WATERMARK || job.jobType === JobType.GALLERY_UNWATERMARK)
          );
        })
        .sort((a, b) => (a.id || 0) - (b.id || 0)),
    [jobs],
  );

  const tooltipText = useMemo(
    () =>
      activeJobs?.map(job => (
        <Flex key={job.id} gap="small">
          <span>{job.status === JobStatus.WAITING ? '⏸' : '▶'}</span>
          <span>
            {t(`app.job.${camelCase(job.jobType)}.${camelCase(job.status)}`) +
              (job.status === JobStatus.RUNNING ? ` (${job.percent}%)` : '')}
          </span>
        </Flex>
      )),
    [activeJobs],
  );

  if (activeJobs.length <= 0) {
    return children;
  }

  return (
    <Tooltip className={styles.tooltip} title={tooltipText} placement="bottomLeft">
      <Spinner size={16} />
      {children}
    </Tooltip>
  );
};

export default GalleryJobTitle;
