import { Reference } from '@apollo/client';
import { ModelConnection } from 'types/ModelConnection';

const mergeList = ({
  incoming,
  existing,
  page,
  perPage,
}: {
  incoming: ModelConnection;
  existing?: ModelConnection;
  page?: number | null;
  perPage?: number | null;
}) => {
  const offset = page && perPage ? (page - 1) * perPage : 0;

  const edges: Reference[] = existing ? existing.edges.slice(0) : [];
  const existingCount = existing ? existing._count : 0;
  // Remove eventual duplicates from incoming array
  const incomingEdges: Reference[] | undefined = incoming?.edges;

  if (incomingEdges) {
    // Place the incoming edges at their correct position given the offset
    for (let i = 0; i < incomingEdges?.length; ++i) {
      edges[offset + i] = incomingEdges[i];
    }

    const isDelete = existingCount > incoming._count;
    // If the incoming edges are less than the existing edges, remove the excess edges from the end of the list
    if (isDelete) {
      edges.splice(offset + incomingEdges.length, existingCount - incoming._count);
    }
  }

  return {
    __typename: incoming?.__typename,
    _count: incoming?._count || 0,
    edges,
  };
};

export default mergeList;
