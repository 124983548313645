import { gql } from 'Operations/__generated__';

export const GET_GALLERY_APPEARANCE = gql(/* GraphQL */ `
  query GetGalleryAppearance($where: GalleryGetWhereType!) {
    getGallery(where: $where) {
      id
      galleryAppearance {
        ...GalleryAppearanceFields
      }
    }
  }
`);
