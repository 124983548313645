import { useCallback, useEffect, useRef, useState } from 'react';

import styled from 'Themes/Styled';

import { useGalleryApp } from 'Hooks/GalleryApp';

import { AuthVar } from 'Operations/Cache';

const MyIFrame = styled.iframe`
  display: none;
`;

const getGalleryAppAuthUrl = function () {
  return `${process.env.REACT_APP_GALLERY_URL}/auth/signin`;
};

const GalleryAppIFrame = () => {
  const refIFrame = useRef<HTMLIFrameElement>(null);
  const [isIFrameLoaded, setIsIFrameLoaded] = useState<boolean>(false);
  const { lastTimestamp: lastGalleryVisit } = useGalleryApp();
  const authVar = AuthVar();

  const sendMessage = useCallback(
    ({ isRefresh }: { isRefresh: boolean }) => {
      if (isIFrameLoaded && refIFrame?.current?.contentWindow) {
        const key = isRefresh ? 'refreshAuth' : 'auth';
        refIFrame.current.contentWindow.postMessage(JSON.stringify({ key, data: authVar }), getGalleryAppAuthUrl());
        console.log(`${key} message sent to subdomain!`);
      }
    },
    [authVar, isIFrameLoaded, refIFrame],
  );

  useEffect(() => {
    sendMessage({ isRefresh: false });
  }, [lastGalleryVisit, sendMessage]);

  const onIFrameLoaded = () => {
    setTimeout(() => {
      setIsIFrameLoaded(true);
      sendMessage({ isRefresh: true });
    }, 3000);
  };

  useEffect(() => {
    sendMessage({ isRefresh: true });
  }, [authVar, sendMessage]);

  return <MyIFrame src={getGalleryAppAuthUrl()} id="authiframe" ref={refIFrame} onLoad={onIFrameLoaded}></MyIFrame>;
};

export default GalleryAppIFrame;
