import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { EditWatermarkForm, EditWatermarkFormProps, NewWatermarkForm, NewWatermarkFormProps } from 'Forms/Watermark';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

interface NewWatermarkModalProps extends NewWatermarkFormProps {
  name: 'new';
}
interface EditWatermarkModalProps extends EditWatermarkFormProps {
  name: 'edit';
}

const WatermarkModal = (props: NewWatermarkModalProps | EditWatermarkModalProps) => {
  const { t } = useContext(LocalizationContext);

  const renderForm = () => {
    if (props.name === 'edit') {
      return <EditWatermarkForm onSubmit={props.onSubmit} defaultValues={props.defaultValues} />;
    }

    return <NewWatermarkForm onSubmit={props.onSubmit} />;
  };

  return (
    <ModalContainer
      title={props.name === 'new' ? t('app.watermark.create.title') : t('app.watermark.edit.title')}
      icon={Images.copyright}
    >
      {renderForm()}
    </ModalContainer>
  );
};

export default WatermarkModal;
