import { gql } from 'Operations/__generated__';

export const GET_NOTIFICATIONS = gql(/* GraphQL */ `
  query GetNotifications($where: GetNotificationsWhereInput) {
    getNotifications(where: $where) {
      _count
      edges {
        id
        gallery {
          id
          name
        }
        action
        meta
        isRead
        createdAt
      }
    }
  }
`);
