import React from 'react';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Layout from 'Components/Atoms/Layout';

import HeaderSkeleton from 'Components/Molecules/Skeletons/HeaderSkeleton';
import ImageCardSkeleton from 'Components/Molecules/Skeletons/ImageCardSkeleton';
import StatCardsSkeleton from 'Components/Molecules/Skeletons/StatCardsSkeleton';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

const ProductContainer = styled(Container)`
  flex: 1;
`;

const RowContainer = styled(Container)`
  width: 100%;
  padding: ${Metrics.baseMargin}px 0;
  gap: ${Metrics.baseMargin}px;
`;

const StyledOptionsContainer = styled(ContentContainer)`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: ${Metrics.baseMargin}px;
  flex-wrap: wrap;
  width: 100%;
`;

const ProductShowSkeleton = () => {
  return (
    <>
      <HeaderSkeleton />
      <Layout>
        <ProductContainer direction="column">
          <RowContainer>
            <ImageCardSkeleton width={372} height={260} />
            <StatCardsSkeleton cardsCount={2} />
          </RowContainer>
          <StyledOptionsContainer shadow rounded padding="baseMargin">
            <ImageCardSkeleton width={310} height={400} />
            <ImageCardSkeleton width={310} height={400} />
            <ImageCardSkeleton width={310} height={400} />
          </StyledOptionsContainer>
        </ProductContainer>
      </Layout>
    </>
  );
};

export default ProductShowSkeleton;
