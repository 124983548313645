import { gql } from 'Operations/__generated__';

export const ARCHIVE_GALLERIES = gql(/* GraphQL */ `
  mutation ArchiveGalleries($where: IdsInput!, $data: GalleryArchiveInputType!) {
    archiveGalleries(where: $where, data: $data) {
      id
      status
    }
  }
`);
