import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

interface Props {
  flexDirection?: 'row' | 'column';
  padding?: 'baseMargin' | 'doubleBaseMargin' | 'smallMargin' | 'tinyMargin';
  paddingType?: 'all' | 'horizontal' | 'vertical';
  backgroundColor?: string;
}

const Layout = styled.section<Props>`
  display: flex;
  flex: 1;
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
  padding: ${({ padding, paddingType = 'all' }) => {
    if (padding) {
      switch (paddingType) {
        case 'horizontal':
          return `0 ${Metrics[padding]}px`;

        case 'vertical':
          return `${Metrics[padding]}px 0`;

        case 'all':
        default:
          return `${Metrics[padding]}px`;
      }
    } else {
      return '0px';
    }
  }};
  box-sizing: border-box;
  background-color: ${({ backgroundColor = 'transparent' }) => backgroundColor};
`;

export default Layout;
