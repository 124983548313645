import React, { useCallback, useContext } from 'react';

import Icon, { IconName } from 'Components/Atoms/Icon';
import Tag from 'Components/Atoms/Tag';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useRoles } from 'Hooks/useRoles';

import { OrderItemStatus } from 'Operations/__generated__/graphql';

const ChevronIcon = styled(Icon)`
  margin-left: ${Metrics.tinyMargin / 2}px;
  margin-right: ${Metrics.tinyMargin / 2}px;
  vertical-align: middle;
`;
const ChevronSpan = styled.span<{ color?: string }>`
  margin-left: ${Metrics.tinyMargin}px;
  flex: 1;
`;
const ChevronTag = styled(Tag)`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;
const TagNoChevron = styled(Tag)`
  padding-left: ${Metrics.smallMargin}px;
  padding-right: ${Metrics.smallMargin}px;
  margin-bottom: 0;
`;

const orderItemStatusColors = {
  WAITING: Colors.waiting,
  WAITING_RETOUCH: Colors.waiting,
  ORDERED: Colors.grey,
  TRANSIT: Colors.primaryOptional,
  DELIVERED: Colors.success,
  CANCELLED: Colors.error,
  PROBLEM: Colors.error,
  '-': Colors.white,
};

const orderItemTextColors = {
  WAITING: Colors.white,
  WAITING_RETOUCH: Colors.white,
  ORDERED: Colors.white,
  TRANSIT: Colors.white,
  DELIVERED: Colors.white,
  CANCELLED: Colors.white,
  PROBLEM: Colors.white,
  '-': Colors.black,
};

interface OrderStatusProps {
  itemStatus: OrderItemStatus | '-';
  chevron?: boolean;
  direction?: 'up' | 'down';
  width?: string;
}

const OrderStatusTag = ({ itemStatus, chevron, direction = 'down', ...props }: OrderStatusProps) => {
  const { t } = useContext(LocalizationContext);
  const { isLabo } = useRoles();

  const formatOrderItemStatus = useCallback(
    (status: OrderItemStatus) => {
      if (!isLabo) {
        return status.toLowerCase();
      }
      switch (status) {
        case OrderItemStatus.ORDERED:
          return 'inProgress';
        case OrderItemStatus.TRANSIT:
          return 'shipped';
        default:
          return status.toLowerCase();
      }
    },
    [isLabo],
  );

  if (!chevron) {
    return (
      <TagNoChevron
        color={orderItemStatusColors[itemStatus]}
        textColor={orderItemTextColors[itemStatus]}
        size="small"
        {...props}
      >
        {itemStatus === '-' ? '-' : t(`app.orders.orderItemStatus.${formatOrderItemStatus(itemStatus)}`)}
      </TagNoChevron>
    );
  }
  return (
    <ChevronTag
      color={orderItemStatusColors[itemStatus]}
      textColor={orderItemTextColors[itemStatus]}
      size="small"
      {...props}
    >
      <ChevronSpan>
        {itemStatus === '-' ? '-' : t(`app.orders.orderItemStatus.${formatOrderItemStatus(itemStatus)}`)}
      </ChevronSpan>
      {chevron && (
        <ChevronIcon name={`chevron-${direction}` as IconName} size={20} color={orderItemTextColors[itemStatus]} />
      )}
    </ChevronTag>
  );
};

export default OrderStatusTag;
