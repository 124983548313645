import React, { HTMLAttributes, useContext } from 'react';

import { DeepPartial } from '@apollo/client/utilities';
import { Card, Tag } from 'antd';

import clsx from 'clsx';

import Container from 'Components/Atoms/Container';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { ContactType } from 'Operations/__generated__/graphql';

import InfoItem from './InfoItem';

const MyTag = styled(Tag)`
  margin-left: ${Metrics.tinyMargin}px;
`;

interface ContactCardProps extends HTMLAttributes<HTMLDivElement> {
  displayName: string;
  contactType?: DeepPartial<ContactType>;
  email?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  company?: string | null;
  address?: string | null;
}

const { Meta } = Card;

const ContactCard = ({
  firstname,
  lastname,
  company,
  displayName,
  email,
  contactType,
  address,
  className,
  ...props
}: ContactCardProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Card bordered={false} className={clsx(className, 'ant-card--shadow')} {...props}>
      <Meta
        title={
          <Container justify="flex-start">
            <p className="ant-card-meta-title">{displayName}</p>
            {contactType && <MyTag color={contactType.color}>{contactType.name}</MyTag>}
          </Container>
        }
        description={
          <>
            {firstname && <InfoItem label={t('app.common.firstname')} text={firstname} />}
            {lastname && <InfoItem label={t('app.common.lastname')} text={lastname} />}
            {company && <InfoItem label={t('app.common.company')} text={company} />}
            {email && <InfoItem label={t('app.common.email')} text={email} />}
            {address && <InfoItem label={t('app.common.address')} text={address} />}
          </>
        }
      />
    </Card>
  );
};

export default ContactCard;
