import { RefCallback, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import useResizeObserver from 'use-resize-observer';

type ObservedSize = {
  width: number | undefined;
  height: number | undefined;
};
declare type HookResponse<T extends HTMLElement> = {
  ref: RefCallback<T>;
} & ObservedSize;

export default <T extends HTMLElement>(wait = 500): HookResponse<T> => {
  const [size, setSize] = useState<ObservedSize>({ width: undefined, height: undefined });
  const onResize = useMemo(() => debounce(setSize, wait, { leading: true }), [wait]);
  const { ref } = useResizeObserver({ onResize });

  return { ref, ...size };
};
