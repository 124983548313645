import React, { HTMLAttributes } from 'react';

import { Empty, Popconfirm } from 'antd';
import { createStyles } from 'antd-style';

import Container from 'Components/Atoms/Container';
import Icon, { IconName } from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors, Metrics } from 'Themes';

interface PanelColumn extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title: string;
  isEmpty?: boolean;
}

interface PanelItem extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  isSelected: boolean;
  icon?: IconName;
  iconConfirmMessage?: string;
  iconTooltip?: string;
  onIcon?: () => void;
  title: string;
  subtitle?: string;
}

const PanelsContainer = (props: HTMLAttributes<HTMLDivElement>) => {
  const { styles, cx } = useStyles();
  return <div {...props} className={cx(props.className, styles.container)} />;
};

const StyledColumn = (props: HTMLAttributes<HTMLDivElement>) => {
  const { styles, cx } = useStyles();
  return <div {...props} className={cx(props.className, styles.column)} />;
};

const StyledContent = (props: HTMLAttributes<HTMLDivElement>) => {
  const { styles, cx } = useStyles();
  return <div {...props} className={cx(props.className, styles.content)} />;
};

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    border: 1px solid ${token.colorBorder};
    border-radius: ${token.borderRadius}px;

    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    flex: 1;

    border-right: 1px solid ${token.colorBorder};
    min-width: 225px;

    &:last-child {
      border-right: 0;
    }
  `,
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${token.sizeXS}px;

    width: 100%;
    padding: ${token.sizeXS}px;
  `,
  title: css`
    padding: ${token.sizeXS}px;
    border-bottom: 1px solid ${token.colorBorder};
    width: 100%;
  `,
  item: css`
    display: flex;
    align-items: center;
    padding: ${token.sizeXS}px;

    border: ${token.lineWidth}px solid;
    border-color: ${token.colorBorder};
    border-radius: 8px;
    margin-right: ${Metrics.smallMargin}px;
    min-height: 50px;

    cursor: pointer;

    transition: all ease-out 150ms;

    &:hover {
      margin-right: ${token.sizeXS}px;
      border-color: ${token.colorBorderSecondary};
    }

    &.active {
      background: ${token.panelsActiveBackground};
      border-color: ${token.panelsActiveBorderColor};
      margin-right: 0;

      &:hover {
        margin-right: 0;
      }
    }
  `,
}));

const Column = ({ title, children, isEmpty, ...props }: PanelColumn) => {
  const { styles } = useStyles();

  return (
    <StyledColumn {...props}>
      <Text className={styles.title} align="center" weight="bold" size="medium">
        {title}
      </Text>
      <StyledContent>
        {isEmpty && (
          <Container padding="baseMargin" justify="center">
            <Empty />
          </Container>
        )}
        {children}
      </StyledContent>
    </StyledColumn>
  );
};

const Item = ({ isSelected, icon, iconConfirmMessage, iconTooltip, onIcon, title, subtitle, ...props }: PanelItem) => {
  const { styles, cx, theme } = useStyles();

  return (
    <div {...props} className={cx(props.className, styles.item, isSelected && 'active')}>
      <Container flex={1} justify="flex-start" align="center" gap={Metrics.smallMargin}>
        {icon && (
          <Popconfirm
            title={iconConfirmMessage}
            // @ts-ignore
            onClick={(e: Event) => e.stopPropagation()}
            onConfirm={e => {
              e?.stopPropagation();
              onIcon?.();
            }}
            onCancel={e => e?.stopPropagation()}
            disabled={!iconConfirmMessage}
          >
            <RoundButton
              icon={icon}
              size="middle"
              tooltipTitle={iconTooltip}
              color={theme.colorText}
              onClick={e => {
                e.stopPropagation();
                if (!iconConfirmMessage) {
                  onIcon?.();
                }
              }}
            />
          </Popconfirm>
        )}
        <Container direction="column">
          <Text size="medium" weight="bold">
            {title || '-'}
          </Text>
          {subtitle && <Text size="small">{subtitle}</Text>}
        </Container>
      </Container>
      <Icon name="chevron-right" />
    </div>
  );
};

export default {
  Container: PanelsContainer,
  Column,
  Item,
};
