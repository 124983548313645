import { IconName } from 'Components/Atoms/Icon';

import { NotificationAction } from 'Operations/__generated__/graphql';

const getNotificationIcon = (action: NotificationAction): { name: IconName; size?: number } => {
  switch (action) {
    case NotificationAction.NEW_PHOTOS_TO_RETOUCH:
      return {
        name: 'grid',
        size: 16,
      };
    case NotificationAction.NEW_ORDER:
      return {
        name: 'subscription',
      };
    case NotificationAction.GALLERY_AUTO_OFFLINE:
    case NotificationAction.GALLERY_AUTO_ONLINE:
      return {
        name: 'galleries',
        size: 18,
      };
    case NotificationAction.NEW_PHOTOS_AVAILABLE:
      return {
        name: 'upload',
      };
    case NotificationAction.PHOTOS_UNARCHIVED:
      return {
        name: 'archive',
      };
    case NotificationAction.WATERMARK_APPLIED:
      return {
        name: 'watermarks',
      };
    case NotificationAction.GALLERY_HARD_DELETED:
      return {
        name: 'delete',
      };
    case NotificationAction.NEW_COMMENT_ON_PHOTO:
      return {
        name: 'message',
      };
    case NotificationAction.GALLERY_DOWNLOAD:
      return {
        name: 'download',
      };
  }
};

export default getNotificationIcon;
