import { useContext, useMemo } from 'react';

import { Alert, Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

import { GalleryAccessPolicy } from 'Operations/__generated__/graphql';

export type AccessType = 'group' | 'client';

export interface AccessFormValues {
  accessType: AccessType;
  name: string | undefined;
  code: string;
}

export interface EditAccessFormValues extends AccessFormValues {
  oldCode: string;
}

export interface AccessCodeFormProps {
  galleryAccessPolicy: GalleryAccessPolicy;
}

const AlertStyled = styled(Alert)`
  margin-bottom: ${Metrics.baseMargin}px;
`;

const AccessCodeForm = <T extends AccessFormValues | EditAccessFormValues>({
  isSubmitting,
  values,
  galleryAccessPolicy,
}: FormikProps<T> & AccessCodeFormProps) => {
  const { t } = useContext(LocalizationContext);

  const isCodeValueChanged = useMemo(() => (values as EditAccessFormValues).oldCode !== values.code, [values]);
  const isPrivateClientUpdate = useMemo(
    () =>
      (values as EditAccessFormValues).oldCode &&
      isCodeValueChanged &&
      values.accessType === 'client' &&
      galleryAccessPolicy === GalleryAccessPolicy.ACCESS_CODE,
    [galleryAccessPolicy, isCodeValueChanged, values],
  );
  const isPublicClientUpdate = useMemo(
    () =>
      (values as EditAccessFormValues).oldCode &&
      isCodeValueChanged &&
      values.accessType === 'client' &&
      galleryAccessPolicy === GalleryAccessPolicy.PUBLIC,
    [galleryAccessPolicy, isCodeValueChanged, values],
  );
  const isGroupUpdate = useMemo(
    () => (values as EditAccessFormValues).oldCode && isCodeValueChanged && values.accessType === 'group',
    [isCodeValueChanged, values],
  );

  return (
    <>
      <Form layout="vertical">
        {values.accessType === 'group' && (
          <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
            <Input name="name" placeholder={t('app.common.name')} size="large" />
          </Form.Item>
        )}

        <Form.Item label={t('app.common.code')} name="code" required hasFeedback={false}>
          <Input name="code" placeholder={t('app.common.code')} size="large" />
        </Form.Item>

        {isPrivateClientUpdate && <AlertStyled type="warning" message={t('app.access.warning.privateClientUpdate')} />}
        {isPublicClientUpdate && <AlertStyled type="warning" message={t('app.access.warning.publicClientUpdate')} />}
        {isGroupUpdate && <AlertStyled type="warning" message={t('app.access.warning.groupUpdate')} />}

        <Flex justify="flex-end">
          <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
            {t((values as EditAccessFormValues).oldCode ? 'app.common.edit' : 'app.common.add')}
          </Button>
        </Flex>
      </Form>
    </>
  );
};

export interface AccessFormPayload {
  values: AccessFormValues;
  formikBag: FormikBag<AccessFormProps, AccessFormValues>;
}

export interface EditAccessFormPayload {
  values: EditAccessFormValues;
  formikBag: FormikBag<EditAccessFormProps, EditAccessFormValues>;
}

export interface EditAccessFormProps extends AccessCodeFormProps {
  onSubmit: (payload: EditAccessFormPayload) => void;
  defaultValues: EditAccessFormValues;
}
export interface AccessFormProps extends AccessCodeFormProps {
  onSubmit: (payload: AccessFormPayload) => void;
  defaultValues: AccessFormValues;
}

const accessSchema: yup.SchemaOf<AccessFormValues> = yup
  .object({
    accessType: yup.mixed<AccessType>().required(),
    name: yup.string().when('accessType', {
      is: 'group',
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
    code: yup.string().required(),
  })
  .defined();

export const AccessForm = withFormik<AccessFormProps, AccessFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: accessSchema,
})(AccessCodeForm);

export const EditAccessForm = withFormik<EditAccessFormProps, EditAccessFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: accessSchema,
})(AccessCodeForm);
