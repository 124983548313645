import { gql } from 'Operations/__generated__';

export const SIGN_IN = gql(/* GraphQL */ `
  mutation SignIn($data: AuthInputType!) {
    signIn(data: $data) {
      accessToken
      refreshToken
    }
  }
`);
