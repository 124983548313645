import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { App, Button, Drawer, Flex, Table } from 'antd';

import { compact } from 'lodash';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import { Colors, Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import { Product } from 'Operations/__generated__/graphql';

import { GET_CATALOGS } from 'Operations/Queries/Catalog/GetCatalogs';

import { ADD_PRODUCT_TO_CATALOGS } from 'Operations/Mutations/Product/AddProductToCatalogs';

interface Props {
  isVisible: boolean;
  product: DeepPartial<Product>;
  onCloseDrawer?: () => void;
}

const PER_PAGE = 10;

const ProductCatalogsDrawer = ({ product, isVisible, onCloseDrawer }: Props) => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (isVisible) {
      const defaultCatalogs = compact((product?.catalogs || []).map(c => c?.id));
      setSelectedRowKeys(defaultCatalogs);
    }
  }, [isVisible, product.catalogs]);

  const [addProductsToCatalogs, { loading: isLoading }] = useMutation(ADD_PRODUCT_TO_CATALOGS);

  const { data, loading: isLoadingCatalogs } = useQuery(GET_CATALOGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      where: {
        page,
        perPage: PER_PAGE,
      },
    },
  });

  const handleAddToCatalog = useCallback(async () => {
    if (product.id) {
      await addProductsToCatalogs({
        variables: {
          where: {
            id: product.id,
          },
          data: {
            ids: selectedRowKeys as number[],
          },
        },
      });

      onCloseDrawer?.();
      message.success(t('app.message.product.update.success'));
    }
  }, [addProductsToCatalogs, product.id, onCloseDrawer, selectedRowKeys, t]);

  return (
    <Drawer
      open={isVisible}
      title={<Title level="h2">{t('app.catalog.drawer.title')}</Title>}
      footer={
        <Flex justify="space-between" align="center">
          <Text weight="bold">
            {selectedRowKeys.length} {t('app.catalog.drawer.selected', { count: selectedRowKeys.length }).toLowerCase()}
          </Text>
          <Button disabled={isLoading} loading={isLoading} type="primary" onClick={handleAddToCatalog}>
            {t('app.common.save')}
          </Button>
        </Flex>
      }
      placement="right"
      onClose={onCloseDrawer}
      width={Metrics.drawer.products}
      closeIcon={<Icon name="close" />}
      styles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: Metrics.baseMargin,
        },
      }}
    >
      <Table
        columns={[
          {
            title: t('app.common.name'),
            dataIndex: 'name',
            key: 'name',
            width: '60%',
            render: (name, record) =>
              record.isDefault ? (
                <Flex align="center" gap="small">
                  <Icon name="star" color={Colors.secondaryOptional} size={20} />
                  <Text size="medium" color={Colors.tableBlack}>
                    {name}
                  </Text>
                </Flex>
              ) : (
                name
              ),
          },
          {
            title: t('app.common.vat'),
            dataIndex: 'vat',
            key: 'vat',
            render(vat) {
              return `${vat}%`;
            },
          },
          {
            title: t('app.catalog.totalProduct'),
            key: 'products',
            dataIndex: 'products',
            render(products) {
              return products?._count || 0;
            },
          },
        ]}
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        pagination={{
          position: ['bottomCenter'],
          onChange: setPage,
          total: data?.getCatalogs._count,
          showSizeChanger: false,
          pageSize: PER_PAGE,
        }}
        dataSource={data?.getCatalogs.edges}
        loading={isLoadingCatalogs}
        sticky
      />
    </Drawer>
  );
};

export default ProductCatalogsDrawer;
