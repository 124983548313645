import { gql } from 'Operations/__generated__';

export const GET_WATERMARK = gql(/* GraphQL */ `
  query GetWatermark($where: WatermarkGetWhereType!) {
    getWatermark(where: $where) {
      id
      name
      assetId
      watermarkType
      isResized
      size
      position
      repeat
      opacity
      text
      textColor
      font
      withStroke
      strokeColor
      withBackgroundText
      backgroundTextColor
      createdAt
      updatedAt
      asset {
        assetMain {
          downloadUrl
        }
      }
    }
  }
`);
