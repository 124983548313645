import { useContext, useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { App, Button, Empty, Spin } from 'antd';
import { ColorFactory } from 'antd/es/color-picker/color';

import InfiniteScroll from 'react-infinite-scroll-component';

import { orderBy } from 'lodash';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';
import Text from 'Components/Atoms/Text';

import Header from 'Components/Molecules/Header';
import WatermarkCard from 'Components/Molecules/WatermarkCard';

import { EditWatermarkFormPayload, NewWatermarkFormPayload } from 'Forms/Watermark';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import { getRGBString } from 'Helpers/TransformColor';

import { Repeat, WatermarkType } from 'Operations/__generated__/graphql';

import { GET_WATERMARK } from 'Operations/Queries/Watermark/GetWatermark';
import { GET_WATERMARKS } from 'Operations/Queries/Watermark/GetWatermarks';

import { CREATE_WATERMARK } from 'Operations/Mutations/Watermark/CreateWatermark';
import { DELETE_WATERMARK } from 'Operations/Mutations/Watermark/DeleteWatermark';
import { UPDATE_WATERMARK } from 'Operations/Mutations/Watermark/UpdateWatermark';

const WatermarksLayout = styled(Layout)`
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: ${Metrics.baseMargin}px;
`;

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const CreateButtonInContent = styled(CreateButton)`
  width: fit-content;
  margin: 0 auto;
  margin-top: ${Metrics.smallMargin}px;
`;

const Watermark = styled(WatermarkCard)`
  margin: ${Metrics.smallMargin}px;
`;

const PER_PAGE = 30;

const WatermarksIndex = () => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const { openModal, closeModal } = useModals();

  const [hasFetchAll, setHasFetchAll] = useState(false);
  const [paginationParams, setPaginationParams] = useState<{
    page: number;
    perPage: number;
  }>({
    page: 1,
    perPage: PER_PAGE,
  });

  const [getWatermarks, { data, loading: isLoading, fetchMore }] = useLazyQuery(GET_WATERMARKS, {
    fetchPolicy: 'network-only',
  });

  const [getWatermark] = useLazyQuery(GET_WATERMARK, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data.getWatermark) {
        console.log(
          'data',
          `rgba(${data.getWatermark.textColor})`,
          `rgba(${data.getWatermark.strokeColor})`,
          `rgba(${data.getWatermark.backgroundTextColor})`,
        );

        openModal('WATERMARK', {
          name: 'edit',
          onSubmit: handleUpdateOnSubmit,
          defaultValues: {
            id: data.getWatermark.id,
            name: data.getWatermark.name,
            isResized: !!data.getWatermark.isResized,
            size: data.getWatermark.size || 50,
            repeat: data.getWatermark.repeat || Repeat.NO_REPEAT,
            position: data.getWatermark.position,
            opacity: data.getWatermark.opacity,
            watermarkType: data.getWatermark.watermarkType,
            font: data.getWatermark.font || 'Fira Sans',
            text: data.getWatermark.text || undefined,
            textColor: data.getWatermark.textColor
              ? new ColorFactory(`rgba(${data.getWatermark.textColor})`)
              : undefined,
            withStroke: !!data.getWatermark.withStroke,
            strokeColor: data.getWatermark.strokeColor || undefined,
            withBackgroundText: !!data.getWatermark.withBackgroundText,
            backgroundTextColor: data.getWatermark.backgroundTextColor
              ? new ColorFactory(`rgba(${data.getWatermark.backgroundTextColor})`)
              : undefined,
            fileUrl:
              data.getWatermark.watermarkType === WatermarkType.IMAGE && data.getWatermark.asset
                ? data.getWatermark.asset.assetMain?.downloadUrl || undefined
                : undefined,
          },
        });
      }
    },
  });

  const [createWatermark] = useMutation(CREATE_WATERMARK, {
    refetchQueries: [
      {
        query: GET_WATERMARKS,
        variables: {
          where: paginationParams,
        },
      },
    ],
  });

  const [updateWatermark] = useMutation(UPDATE_WATERMARK);

  const [deleteWatermark] = useMutation(DELETE_WATERMARK, {
    refetchQueries: [
      {
        query: GET_WATERMARKS,
        variables: {
          where: paginationParams,
        },
      },
    ],
  });

  useEffect(() => {
    const loadedCount = data?.getWatermarks?.edges.length;
    const totalCount = data?.getWatermarks._count;
    if (totalCount !== undefined && totalCount === loadedCount) {
      setHasFetchAll(true);
    }
  }, [data?.getWatermarks?.edges.length, data?.getWatermarks._count]);

  const getMoreWatermarks = async () => {
    if (fetchMore && !hasFetchAll) {
      const newPage = paginationParams.page + 1;
      await fetchMore({
        variables: { where: { page: newPage, perPage: PER_PAGE } },
      });
      setPaginationParams({ page: newPage, perPage: PER_PAGE });
    }
  };

  useEffect(() => {
    if (paginationParams.page === 1) {
      getWatermarks({ variables: { where: { page: paginationParams.page, perPage: PER_PAGE } } });
    }
  }, [getWatermarks, paginationParams.page]);

  const handleCreateOnSubmit = async ({
    values: { textColor, backgroundTextColor, ...values },
    formikBag,
  }: NewWatermarkFormPayload) => {
    try {
      if (values.watermarkType === WatermarkType.IMAGE) {
        console.log(`size of logo to upload: ${values.file?.size}`);
      }

      await createWatermark({
        variables: {
          data: {
            ...values,
            textColor: textColor ? getRGBString(textColor) : undefined,
            backgroundTextColor: backgroundTextColor ? getRGBString(backgroundTextColor) : undefined,
          },
        },
      });

      message.success(t('app.message.watermark.create.success'));
      formikBag.setSubmitting(false);
      closeModal();
    } catch (error) {
      console.log(error);

      message.error(t('app.message.error.somethingWentWrong'));
      formikBag.setSubmitting(false);
    }
  };

  const handleUpdateOnSubmit = async ({
    values: { id, fileUrl: _fileUrl, textColor, backgroundTextColor, ...values },
    formikBag,
  }: EditWatermarkFormPayload) => {
    try {
      await updateWatermark({
        variables: {
          where: { id },
          data: {
            ...values,
            textColor: textColor ? getRGBString(textColor) : undefined,
            backgroundTextColor: backgroundTextColor ? getRGBString(backgroundTextColor) : undefined,
          },
        },
      });

      message.success(t('app.message.watermark.update.success'));
      formikBag.setSubmitting(false);
      closeModal();
    } catch (error) {
      console.log(error);

      message.error(t('app.message.error.somethingWentWrong'));
      formikBag.setSubmitting(false);
    }
  };

  const handleEditWatermark = async ({ id }: { id: number }) => {
    try {
      getWatermark({
        variables: {
          where: {
            id,
          },
        },
      });
    } catch (error) {
      console.log(error);

      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  const handleDeleteWatermark = async ({ id }: { id: number }) => {
    try {
      await deleteWatermark({
        variables: {
          where: {
            id,
          },
        },
        update(cache) {
          const normalizedId = cache.identify({ id, __typename: 'Watermark' });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });

      message.success(t('app.message.watermark.delete.success'));
    } catch (error) {
      console.log(error);

      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  const handleOnCreateClick = () => {
    openModal('WATERMARK', {
      name: 'new',
      onSubmit: handleCreateOnSubmit,
    });
  };

  return (
    <>
      <Header
        title={t('app.watermarks.title')}
        breadcrumbContent={[{ text: t('app.menu.home'), url: '/app/dashboard' }, { text: t('app.common.watermarks') }]}
        buttons={[
          <CreateButton onClick={handleOnCreateClick} key="add" size="large" type="primary">
            <Icon name="add" size={18} />
            {t('app.common.add')}
          </CreateButton>,
        ]}
      />
      {!isLoading && data?.getWatermarks?.edges.length === 0 && (
        <>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          <Text align="center">{t('app.watermarks.noData.text')}</Text>
          <CreateButtonInContent onClick={handleOnCreateClick} key="add" size="large" type="primary">
            <Icon name="add" size={18} />
            {t('app.watermarks.noData.create')}
          </CreateButtonInContent>
        </>
      )}

      <InfiniteScroll
        dataLength={data?.getWatermarks.edges.length || 0}
        next={getMoreWatermarks}
        hasMore={!hasFetchAll}
        loader={
          <Container justify="center">
            <Spin />
          </Container>
        }
      >
        <WatermarksLayout>
          {orderBy(data?.getWatermarks?.edges, 'createdAt', 'desc').map(watermark => (
            <Watermark
              key={watermark.id}
              id={watermark.id}
              watermarkType={watermark.watermarkType}
              name={watermark.name}
              watermark={
                watermark.watermarkType === WatermarkType.IMAGE && watermark?.asset?.assetMain?.downloadUrl
                  ? watermark.asset.assetMain.downloadUrl
                  : undefined
              }
              font={watermark.font || 'Fira Sans'}
              text={watermark.watermarkType === WatermarkType.TEXT && watermark.text ? watermark.text : undefined}
              textColor={watermark.textColor || undefined}
              repeat={watermark.repeat || undefined}
              isResized={!!watermark.isResized}
              size={watermark.size || undefined}
              opacity={watermark.opacity}
              position={watermark.position}
              withStroke={watermark.withStroke || false}
              strokeColor={watermark.strokeColor || '0,0,0,1'}
              withBackgroundText={watermark.withBackgroundText || false}
              backgroundTextColor={watermark.backgroundTextColor || '0,0,0,1'}
              galleries={watermark.galleries._count}
              handleEditClick={handleEditWatermark}
              handleDeleteConfirm={handleDeleteWatermark}
            />
          ))}
        </WatermarksLayout>
      </InfiniteScroll>
    </>
  );
};

export default WatermarksIndex;
