import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GET_GALLERY } from 'Operations/Queries/Gallery/GetGallery';
import { ME } from 'Operations/Queries/User/Me';

const GALLERY_URL = process.env.REACT_APP_GALLERY_URL;

const useGallery = ({ id }: { id?: number }) => {
  const { data: currentUser } = useQuery(ME, {
    fetchPolicy: 'cache-first',
  });

  const {
    data,
    loading: isGalleryLoading,
    called: isCalled,
    error,
  } = useQuery(GET_GALLERY, {
    skip: !id,
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const gallery = useMemo(
    () => (data?.getGallery?.__typename === 'GalleryAdmin' ? data.getGallery : null),
    [data?.getGallery],
  );

  const galleryRootUrl = useMemo(() => {
    if (gallery) {
      const companyUrl = currentUser?.me?.company.bookingUrl;
      let galleryUrl = `${GALLERY_URL}/${gallery.slug}`;

      if (companyUrl && gallery.url) {
        galleryUrl = `${GALLERY_URL}/${companyUrl}/${gallery.url}`;
      }
      return galleryUrl;
    }

    return null;
  }, [gallery, currentUser?.me?.company.bookingUrl]);

  return {
    gallery,
    isGalleryLoading,
    error,
    isCalled,
    galleryRootUrl,
  };
};

export default useGallery;
