import { gql } from 'Operations/__generated__';

export const UPDATE_PRODUCT_OPTION = gql(/* GraphQL */ `
  mutation UpdateProductOption($where: IdInput!, $data: ProductOptionUpdateInput!) {
    updateProductOption(where: $where, data: $data) {
      id
      name
      providerPrice
      shouldUsePresetPricingPlan
      providerDescription
      pricingPlan {
        id
        name
        type
        createdAt
        wholeGalleryPrice
        items {
          id
          price
          additionalPrice
          quantity
        }
      }
      pricingPlanMeta {
        startingPrice
        startingQuantity
        hasMultiplePrices
        grossPrice
        grossQuantity
        hasMultipleGrossPrices
        formattedPrice @client
        formattedGrossPrice @client
      }
      previewAsset {
        downloadUrl
      }
      hasPricingPlan
      hasPresetPricingPlan
    }
  }
`);
