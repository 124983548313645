import { ReactiveVar } from '@apollo/client';

import { AppVar } from 'Operations/Cache';

const initCreateApp = (
  appVar: ReactiveVar<{
    hasFreeSpace: boolean;
  }>,
) => {
  return ({ hasFreeSpace }: { hasFreeSpace: boolean }) => {
    const result = { hasFreeSpace };
    appVar({ ...result });

    return result;
  };
};

const createApp = initCreateApp(AppVar);

export default createApp;
