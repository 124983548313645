import { useCallback, useContext } from 'react';

import { useQuery } from '@apollo/client';

import { LocalizationContext } from 'i18n';

import { GET_USER_CURRENCY } from 'Operations/Queries/User/GetUserCurrency';

export const useCurrency = () => {
  const { data: currency } = useQuery(GET_USER_CURRENCY, {
    fetchPolicy: 'cache-first',
  });
  const { locale } = useContext(LocalizationContext);

  const { symbol, name, iso } = currency?.me.currency || { symbol: '-', name: '-' };

  const formatCurrency = useCallback(
    (amount: number) => {
      try {
        return amount.toLocaleString(locale, { style: 'currency', currency: iso });
      } catch (error) {
        return `${amount} ${symbol}`;
      }
    },
    [iso, locale, symbol],
  );

  return { symbol, name, iso, formatCurrency };
};
