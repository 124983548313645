import React, { useContext } from 'react';

import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

export interface SignInFormValues {
  email: string;
  password: string;
}

const AuthSignInForm = <T extends SignInFormValues>({ isSubmitting }: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Form layout="vertical">
      <Form.Item label={t('app.common.email')} name="email" required hasFeedback={false}>
        <Input name="email" placeholder={t('app.common.email')} size="large" />
      </Form.Item>
      <Form.Item label={t('app.common.password')} name="password" required hasFeedback={false}>
        <Input type="password" name="password" placeholder={t('app.common.password')} size="large" />
      </Form.Item>
      <Flex justify="flex-end" align="center">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t('app.common.signIn')}
        </Button>
      </Flex>
    </Form>
  );
};

const signInSchema = yup.object({
  email: yup.string().trim().email().required(),
  password: yup.string().trim().required(),
});

const newSignInSchema: yup.SchemaOf<SignInFormValues> = signInSchema.defined();

export interface OnSubmitPayload {
  values: SignInFormValues;
  formikBag: FormikBag<SignInFormProps, SignInFormValues>;
}

export interface SignInFormProps {
  onSubmit: (payload: OnSubmitPayload) => void;
}

export const SignInForm = withFormik<SignInFormProps, SignInFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: () => ({
    email: process.env?.REACT_APP_SIGNIN_EMAIL || '',
    password: process.env?.REACT_APP_SIGNIN_PASSWORD || '',
  }),
  validationSchema: newSignInSchema,
})(AuthSignInForm);
