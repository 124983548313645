import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Image, Table, Tag } from 'antd';

import { chain } from 'lodash';

import Container from 'Components/Atoms/Container';

import Panels from 'Components/Molecules/Panels';

import { Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import { GetOrderQuery } from 'Operations/__generated__/graphql';

interface Props {
  order?: GetOrderQuery['getOrder'];
}

const OrderPhotos = ({ order }: Props) => {
  const { t } = useContext(LocalizationContext);
  const [selectedProvider, setSelectedProvider] = useState<number | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);

  const productsByProviders = useMemo(
    () =>
      chain(order?.items || [])
        .groupBy(({ product, providerId }) => providerId || product?.contact?.id)
        .filter((_items, providerId) => providerId !== 'undefined')
        .map(items => {
          const item = items[0];
          const provider = {
            id: (item.providerId || item.product?.contact?.id) as number,
            displayName: item.providerName || item.product?.contact?.displayName,
          };

          if (provider) {
            const products = chain(items)
              .groupBy(({ product, productName }) => product?.id || productName)
              .flatMap(products => {
                return chain(products)
                  .groupBy(({ options }) => options.map(({ id }) => id).toString())
                  .map(productItemsByOption => ({
                    name: productItemsByOption[0].productName,
                    ...(productItemsByOption[0]?.product || {}),
                    options: productItemsByOption[0].options,
                    itemId: productItemsByOption[0].id,
                    photos: {
                      edges: productItemsByOption.flatMap(({ hasCroppedPhotos, photos, requestQuantity, packageUrl }) =>
                        photos.edges.map(photo => ({
                          ...photo,
                          isCropped: hasCroppedPhotos,
                          ...(hasCroppedPhotos && photo.asset?.thumbSmall && photo.asset?.assetWeb
                            ? {
                                asset: {
                                  ...photo.asset,
                                  thumbSmall: {
                                    ...photo.asset.thumbSmall,
                                    downloadUrl: packageUrl || photo.asset.thumbSmall.downloadUrl,
                                  },
                                  assetWeb: {
                                    ...photo.asset.assetWeb,
                                    downloadUrl: packageUrl || photo.asset.assetWeb.downloadUrl,
                                  },
                                },
                              }
                            : {}),
                          quantity: requestQuantity,
                        })),
                      ),
                    },
                    quantity: productItemsByOption.reduce((quantity, product) => quantity + product.quantity, 0),
                  }))
                  .value();
              })
              .compact()
              .value();

            return {
              ...provider,
              products,
            };
          }
        })
        .compact()
        .value(),
    [order?.items],
  );

  const providerProducts = useMemo(
    () => productsByProviders.find(provider => provider.id === selectedProvider)?.products,
    [productsByProviders, selectedProvider],
  );

  const productPhotos = useMemo(
    () => providerProducts?.find(product => product.itemId === selectedProduct)?.photos.edges,
    [providerProducts, selectedProduct],
  );

  useEffect(() => {
    // set default value on first render and on provider change
    if (!selectedProvider && productsByProviders) {
      setSelectedProvider(productsByProviders[0].id);
    }
    if (!selectedProduct && providerProducts) {
      setSelectedProduct(providerProducts[0].itemId);
    }
  }, [productsByProviders, providerProducts, selectedProduct, selectedProvider]);

  return (
    <div>
      {order?.items && (
        <Panels.Container>
          <Panels.Column title={t('app.common.provider')} isEmpty={!productsByProviders?.length}>
            {productsByProviders.map(provider => (
              <Panels.Item
                key={provider.id}
                isSelected={provider.id === selectedProvider}
                title={provider.displayName || '-'}
                onClick={() => {
                  if (provider.id !== selectedProvider) {
                    setSelectedProvider(provider.id);
                    setSelectedProduct(null);
                  }
                }}
              />
            ))}
          </Panels.Column>
          <Panels.Column title={t('app.common.products', { count: 2 })} isEmpty={!providerProducts?.length}>
            {providerProducts?.map(product => (
              <Panels.Item
                key={product.itemId}
                isSelected={product.itemId === selectedProduct}
                title={product.name || '-'}
                subtitle={product.options.map(o => o.name).join(', ')}
                onClick={() => setSelectedProduct(product.itemId)}
              />
            ))}
          </Panels.Column>
          <Panels.Column
            title={t('app.common.photo', { count: 2 })}
            isEmpty={!productPhotos?.length}
            style={{ minWidth: '50%' }}
          >
            {productPhotos && (
              <Image.PreviewGroup>
                <Table
                  dataSource={productPhotos}
                  size="small"
                  pagination={false}
                  rowKey="id"
                  columns={[
                    {
                      dataIndex: ['asset'],
                      width: 150,
                      render(asset: typeof productPhotos[0]['asset']) {
                        return (
                          asset && (
                            <Image
                              width={150}
                              src={asset.thumbSmall?.downloadUrl}
                              preview={{
                                src: asset.assetWeb?.downloadUrl || undefined,
                              }}
                            />
                          )
                        );
                      },
                    },
                    {
                      dataIndex: 'name',
                      render(name, { isCropped }) {
                        return (
                          <Container gap={Metrics.tinyMargin}>
                            <p>{name}</p>
                            {isCropped && <Tag color="geekblue">{t('app.common.cropped')}</Tag>}
                          </Container>
                        );
                      },
                    },
                    {
                      dataIndex: 'quantity',
                      title: t('app.common.quantity'),
                      width: 100,
                    },
                  ]}
                />
              </Image.PreviewGroup>
            )}
          </Panels.Column>
        </Panels.Container>
      )}
    </div>
  );
};

export default OrderPhotos;
