import { gql } from 'Operations/__generated__';

export const CREATE_PRODUCT_OPTION_CATEGORY = gql(/* GraphQL */ `
  mutation CreateProductOptionCategory($where: IdInput!, $data: ProductOptionCategoryCreateInput!) {
    createProductOptionCategory(where: $where, data: $data) {
      id
      name
    }
  }
`);
