import { ThemeConfig } from 'antd';
import { CustomToken } from 'antd-style';

type GlobalTheme = {
  theme?: Partial<ThemeConfig> | undefined;
  customToken: Partial<CustomToken>;
};

const dflt: GlobalTheme = {
  customToken: {
    menuLeftBackgroundColor: '#27ADD3',
    uploaderTitleBackground: '#27ADD3',
    menuLeftSeparatorColor: 'rgba(255,255,255,.5)',

    menuLeftButtonBackgroundColor: 'rgba(0,0,0,0)',
    menuLeftButtonBorderColor: 'rgba(255,255,255,.5)',
    menuLeftButtonColor: '#fff',

    menuLeftButtonHoverBackgroundColor: '#fff',
    menuLeftButtonHoverBorderColor: '#fff',
    menuLeftButtonHoverColor: '#FFA600',

    menuLeftButtonActiveBackgroundColor: '#FFA600',
    menuLeftButtonActiveBorderColor: '#FFA600',
    menuLeftButtonActiveColor: '#000',

    menuRightButtonBackgroundColor: '#FFE9BF',
    menuRightActionButtonColor: '#FFA600',
  },
};

const gradient: GlobalTheme = {
  customToken: {
    ...dflt.customToken,
    menuLeftBackgroundColor: 'linear-gradient(180deg, #27C9D3 0%, #6296EE 100%)',
    uploaderTitleBackground: `linear-gradient(90deg, #27C9D3 0%, #6296EE 100%)`,
  },
};
const support: GlobalTheme = {
  customToken: {
    ...dflt.customToken,
    menuLeftBackgroundColor: 'linear-gradient(180deg, #006A7D 0%, #00395D 100%)',
  },
};

const light: GlobalTheme = {
  theme: {
    token: {
      colorPrimary: '#27ADD3',
      colorPrimaryBg: '#EFFBFF',
      colorLink: '#27ADD3',
      colorLinkHover: '#249FC2',
      colorLinkActive: '#249FC2',
    },
    components: {
      Button: {
        primaryColor: '#fff',
        colorPrimary: '#27ADD3',
        defaultHoverBg: '#27ADD3',
        defaultBorderColor: '#27ADD3',
        defaultHoverBorderColor: '#27ADD3',
        defaultColor: '#27ADD3',
        defaultHoverColor: '#fff',
      },
      Tree: {
        directoryNodeSelectedColor: '#27ADD3',
      },
      Radio: {
        buttonSolidCheckedColor: '#fff',
      },
      Progress: {
        defaultColor: '#27ADD3',
      },
    },
  },
  customToken: {
    primaryShadowColor: 'rgba(39, 173, 211, .3)',
    colorSecondary: '#27ADD3',
    scrollBarColor: 'rgba(39, 173, 211, .3)',

    panelsActiveBackground: '#EFFBFF',
    panelsActiveBorderColor: '#27ADD3',

    batchNotificationBackground: '#EFFBFF',
    batchNotificationColor: '#27ADD3',

    menuLeftBackgroundColor: '#EFFBFF',
    menuLeftSeparatorColor: 'rgba(39, 173, 211, 0.5)',
    uploaderTitleBackground: '#27ADD3',

    menuLeftButtonBackgroundColor: '#fff',
    menuLeftButtonBorderColor: '#fff',
    menuLeftButtonColor: '#27ADD3',

    menuLeftButtonHoverBackgroundColor: '#27ADD3',
    menuLeftButtonHoverBorderColor: '#27ADD3',
    menuLeftButtonHoverColor: '#fff',

    menuLeftButtonActiveBackgroundColor: '#27ADD3',
    menuLeftButtonActiveBorderColor: '#27ADD3',
    menuLeftButtonActiveColor: '#fff',

    menuRightButtonBackgroundColor: '#EFFBFF',
    menuRightActionButtonColor: '#27ADD3',
  },
};

export const globalTheme = { dflt, gradient, light, support };
