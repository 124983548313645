import { ATTACHMENT_TYPE } from 'types/Attachment';

const hasExtension = function (fileName: string, extensions: string[]) {
  const normalizedFilename = fileName.toLowerCase();
  for (const extension of extensions) {
    if (normalizedFilename.endsWith(extension.toLowerCase())) {
      return true;
    }
  }
  return false;
};

export const toAttachmentType = function (inputString?: string | null) {
  if (!inputString) {
    return undefined;
  }
  const isPdf = inputString === 'application/pdf' || hasExtension(inputString, ['.pdf']);
  const isImage = function (inputString: string) {
    return (
      ['image/jpeg', 'image/png', 'image/gif'].indexOf(inputString) >= 0 ||
      hasExtension(inputString, ['.gif', '.jpg', '.jpeg', '.png'])
    );
  };

  return isPdf ? ATTACHMENT_TYPE.PDF : isImage(inputString) ? ATTACHMENT_TYPE.IMAGE : ATTACHMENT_TYPE.FILE;
};
