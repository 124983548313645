import { gql } from 'Operations/__generated__';

export const GET_GALLERY_PHOTOS = gql(/* GraphQL */ `
  query GetGalleryPhotos($where: GalleryPhotosGetWhereType, $galleryId: Int!) {
    getGallery(where: { id: $galleryId }) {
      id
      ... on GalleryAdmin {
        photosCustomOrder
        photosOrder
        workmode
        photos(where: $where) {
          _count
          edges {
            ...PhotoCoreFields
          }
        }
      }
    }
  }
`);
