import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';
import ModalTip from 'Components/Molecules/ModalTip';

import { EditPicklistForm, EditPicklistFormProps, NewPicklistForm, NewPicklistFormProps } from 'Forms/Picklist';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

interface NewPicklistModalProps extends NewPicklistFormProps {
  name: 'new';
}
interface EditPicklistModalProps extends EditPicklistFormProps {
  name: 'edit';
}

const PicklistModal = (props: NewPicklistModalProps | EditPicklistModalProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <ModalContainer
      title={t(`app.picklist.modal.${props.name}`)}
      subtitle={<ModalTip title={t('app.picklist.modal.subtitle')}>{t('app.picklist.modal.content')}</ModalTip>}
      icon={Images.tag}
    >
      {props.name === 'edit' ? <EditPicklistForm {...props} /> : <NewPicklistForm {...props} />}
    </ModalContainer>
  );
};

export default PicklistModal;
