import { gql } from 'Operations/__generated__';

export const ADD_PRODUCT_TO_CATALOGS = gql(/* GraphQL */ `
  mutation AddProductToCatalogs($where: IdInput!, $data: IdsInput!) {
    addProductToCatalogs(where: $where, data: $data) {
      id
      catalogs {
        id
        name
      }
    }
  }
`);
