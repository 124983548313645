import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';
import ModalTip from 'Components/Molecules/ModalTip';

import { EditProductForm, EditProductFormProps, NewProductForm, NewProductFormProps } from 'Forms/Product';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

interface NewProductModalProps extends NewProductFormProps {
  name: 'new';
}
interface EditProductModalProps extends EditProductFormProps {
  name: 'edit';
}

const ProductModal = (props: NewProductModalProps | EditProductModalProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <ModalContainer
      title={t(`app.product.modal.${props.name}`)}
      icon={Images.tag}
      subtitle={
        <ModalTip title={t('app.product.modal.subtitle')}>
          {t('app.product.modal.content', {
            link: '/app/contacts',
          })}
        </ModalTip>
      }
    >
      {props.name === 'edit' ? (
        <EditProductForm
          onSubmit={props.onSubmit}
          defaultValues={props.defaultValues}
          isAdmin={props.isAdmin}
          minPrice={props.minPrice}
        />
      ) : (
        <NewProductForm
          onSubmit={props.onSubmit}
          defaultValues={props.defaultValues}
          isAdmin={props.isAdmin}
          minPrice={props.minPrice}
        />
      )}
    </ModalContainer>
  );
};

export default ProductModal;
