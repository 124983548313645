import { gql } from 'Operations/__generated__';

export const CREATE_EMAIL_TEMPLATE = gql(/* GraphQL */ `
  mutation CreateEmailTemplate($data: EmailTemplateCreateInputType!) {
    createEmailTemplate(data: $data) {
      id
      associatedModel
      type @client
      title
      name
      createdAt
      attachmentName
      attachmentUrl
    }
  }
`);
