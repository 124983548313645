import { gql } from 'Operations/__generated__';

export const SET_EMAIL_CONFIG_SMTP = gql(/* GraphQL */ `
  mutation SetEmailConfigSmtp($data: EmailConfigSmtpInputType!) {
    setEmailConfigSmtp(data: $data) {
      id
      settings {
        useGmail
        useSmtp
        isGoogleConfigured
        smtp {
          host
          user
          withSsl
        }
      }
    }
  }
`);
