import { StrictTypedTypePolicies } from 'Operations/__generated__/apollo-helpers';

const FeatureRequest: StrictTypedTypePolicies['FeatureRequest'] = {
  fields: {
    comments: {
      keyArgs: false,
    },
  },
};

export default FeatureRequest;
