import { gql } from 'Operations/__generated__';

export const GET_EMAIL_TEMPLATE = gql(/* GraphQL */ `
  query GetEmailTemplate($where: EmailTemplateGetInputType!) {
    getEmailTemplate(where: $where) {
      id
      associatedModel
      type @client
      name
      title
      content
      attachmentName
      attachmentUrl
    }
  }
`);
