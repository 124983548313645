import { gql } from 'Operations/__generated__';

export const CREATE_CATALOG = gql(/* GraphQL */ `
  mutation CreateCatalog($data: CatalogCreateInput!) {
    createCatalog(data: $data) {
      id
      name
      isDefault
      vat
      createdAt
    }
  }
`);
