import React, { forwardRef } from 'react';

import { RadioChangeEvent, RadioProps } from 'antd';

import { FieldHookConfig, useField } from 'formik';
import { Radio } from 'formik-antd';

import { Colors } from 'Themes';
import styled from 'Themes/Styled';

const segmentedTopMargin = 21;

const SegmentedContainer = styled.div`
  position: relative;
  flex: 1;
  margin-top: ${segmentedTopMargin}px;
  padding: 16px;
  padding-top: 31px;
  border: 2px solid ${Colors.formBorder};
  border-radius: 24px;

  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;

const RadioContainer = styled.div`
  position: absolute;
  top: -${segmentedTopMargin}px;
  left: 0;
  right: 0;
  text-align: center;
`;


const SegmentedControl = forwardRef<
  RadioProps,
  FieldHookConfig<string | number> & {
    values: { value: string; label: string; content: JSX.Element }[];
    handleOnChange?: (e: RadioChangeEvent) => void;
  }
>(({ name, values, handleOnChange, disabled, ...props }) => {
  const [field, _meta, _helpers] = useField(name);
  const { onChange } = field;

  return (
    <SegmentedContainer {...props}>
      <RadioContainer>
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          size="large"
          disabled={disabled}
          css={{ backgroundColor: '#fff' }}
          name={name}
          options={values.map(val => ({ key: val.value, label: val.label, value: val.value }))}
          onChange={e => {
            onChange({ ...e, target: { ...e.target, name } });

            if (handleOnChange) {
              handleOnChange(e);
            }
          }}
        />
      </RadioContainer>
      {values.find(val => val.value === field.value)?.content}
    </SegmentedContainer>
  );
});

export default SegmentedControl;
