import React, { useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, App, Spin } from 'antd';

import ContentContainer from 'Components/Atoms/ContentContainer';

import { OnSubmitPayload, OtherPaymentForm } from 'Forms/PaymentOther';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { ME } from 'Operations/Queries/User/Me';

import { UPDATE_OTHER_PAYMENT } from 'Operations/Mutations/User/UpdateOtherPayment';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  width: 67%;
`;

const OtherAlert = styled(Alert)`
  margin-bottom: ${Metrics.smallMargin}px;
`;

const OtherTab = () => {
  const { message } = App.useApp();
  const { t } = useContext(LocalizationContext);

  const { data: currentUser, loading: isUserLoading } = useQuery(ME, {
    fetchPolicy: 'cache-first',
  });

  const [updateOtherPayment] = useMutation(UPDATE_OTHER_PAYMENT);

  const handleOnSubmit = async ({ values, formikBag }: OnSubmitPayload) => {
    try {
      await updateOtherPayment({
        variables: { data: { content: values.isEnabled && values.content ? values.content : '' } },
      });

      message.success(t('app.message.payments.other.update.success'));
      formikBag.setSubmitting(false);
    } catch (error) {
      console.log(error);

      message.error(t('app.message.error.somethingWentWrong'));
      formikBag.setSubmitting(false);
    }
  };

  return (
    <StyledTableContentContainer padding="baseMargin" shadow rounded>
      <OtherAlert type="info" message={t('app.settings.payments.other.description')} />
      {isUserLoading && <Spin />}
      {!isUserLoading && (
        <OtherPaymentForm defaultValues={{ content: currentUser?.me.otherPayment.content }} onSubmit={handleOnSubmit} />
      )}
    </StyledTableContentContainer>
  );
};

export default OtherTab;
