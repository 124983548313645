import React, { useContext } from 'react';

import Container from 'Components/Atoms/Container';
import Title from 'Components/Atoms/Title';

import { Colors, Images, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const Illustration = styled.img`
  width: 70%;
  max-width: 800px;
  margin-bottom: ${Metrics.baseMargin}px;
`;

const IllustrationContainer = styled(Container)`
  flex: 1;
  height: 100vh;
  background: ${Colors.primaryMain};
  background: -moz-linear-gradient(120deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
  background: -webkit-linear-gradient(120deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
  background: linear-gradient(120deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${Colors.primaryMain}",endColorstr="${Colors.primaryOptional}",GradientType=1);
`;

const Maintenance = () => {
  const { t } = useContext(LocalizationContext);

  return (
    <Container>
      <IllustrationContainer direction="column" justify="center" align="center">
        <Illustration src={Images.maintenance} alt="Maintenance illustration" />
        <Title level="h1">{t('maintenance.title')}</Title>
      </IllustrationContainer>
    </Container>
  );
};

export default Maintenance;
