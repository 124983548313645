import { StrictTypedTypePolicies } from 'Operations/__generated__/apollo-helpers';
import { EmailTemplateAssociatedModel, EmailTemplateType } from 'Operations/__generated__/graphql';

const EmailTemplatePolicy: StrictTypedTypePolicies['EmailTemplate'] = {
  fields: {
    type: {
      read: (_, { readField }) => {
        const associatedModel = readField<EmailTemplateAssociatedModel>('associatedModel');

        return associatedModel === EmailTemplateAssociatedModel.CUSTOM
          ? EmailTemplateType.YOURS
          : EmailTemplateType.FOTOSTUDIO;
      },
    },
  },
};

export default EmailTemplatePolicy;
