import { gql } from 'Operations/__generated__';

export const GET_PRODUCT_CATEGORIES = gql(/* GraphQL */ `
  query GetProductCategories($where: ProductCategoriesGetWhereInput!) {
    getProductCategories(where: $where) {
      _count
      edges {
        id
        name
        isDigital
        isEditable
        isDeletable
        createdAt
      }
    }
  }
`);
