import { useCallback, useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { App, Button, Flex, Popconfirm, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { isArray } from 'lodash';

import Container from 'Components/Atoms/Container';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors } from 'Themes';
import { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useContactModal } from 'Hooks/useContactModal';

import dayjs from 'Services/DayjsService';

import { GetInvoicesQuery, GetInvoicesWithoutGalleryQuery } from 'Operations/__generated__/graphql';

import { GET_USER_CURRENCY } from 'Operations/Queries/User/GetUserCurrency';

import { CONVERT_INVOICE } from 'Operations/Mutations/Invoice/ConvertReceipt';

interface HandleChangeProps {
  page: number;
  sort: 'desc' | 'asc';
}

type Invoice = GetInvoicesQuery['getInvoices']['edges'][0] | GetInvoicesWithoutGalleryQuery['getInvoices']['edges'][0];

interface Props {
  total: number;
  isLoading: boolean;
  paginationCurrent: number;
  paginationSize?: number;
  data?: Invoice[];
  handleOnChange: (props: HandleChangeProps) => void;
  withGallery?: boolean;
}

const InvoiceTable = ({
  isLoading,
  total,
  data,
  paginationCurrent,
  paginationSize = 20,
  handleOnChange,
  withGallery = false,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);
  const { message } = App.useApp();
  const { openModal } = useModals();
  const { updateContact } = useContactModal();

  const { data: currency } = useQuery(GET_USER_CURRENCY, {
    fetchPolicy: 'cache-first',
  });

  const [convertReceipt, { loading: isConvertingInvoice }] = useMutation(CONVERT_INVOICE);

  const handleOpenPaymentModal = useCallback(
    (defaultValues: { invoiceId: number; invoiceAmount: number }) => {
      openModal('PAYMENT', defaultValues);
    },
    [openModal],
  );

  const galleryColumn: ColumnsType<Invoice> = [
    {
      title: t('app.common.gallery'),
      dataIndex: 'gallery',
      key: 'gallery.id',
      render(_, record) {
        const { gallery } = record as GetInvoicesQuery['getInvoices']['edges'][0];
        if (gallery) {
          return <Link to={`/app/galleries/${gallery.id}`}>{gallery.name}</Link>;
        }
        return '';
      },
    },
  ];

  const columns: ColumnsType<Invoice> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 165,
      render: (_, record) => (
        <Flex gap="small">
          <RoundButton
            icon="add"
            tooltipTitle={t('app.invoices.createPayment')}
            onClick={() => {
              handleOpenPaymentModal({ invoiceId: record.id, invoiceAmount: record.ttcPrice });
            }}
          />
          {record.url && (
            <a href={record.url} target="_blank">
              <RoundButton type="default" icon="download" tooltipTitle={t('app.common.download')} />
            </a>
          )}
          {record.isReceipt && (
            <Popconfirm
              title={t('app.invoices.convertConfirm')}
              onConfirm={() => {
                convertReceipt({
                  variables: { where: { id: record.id } },
                  onCompleted() {
                    message.success(t('app.invoices.convertSuccess'));
                  },
                });
              }}
            >
              <RoundButton
                type="primary"
                icon="transform-file"
                tooltipTitle={t('app.invoices.convert')}
                disabled={isConvertingInvoice}
              />
            </Popconfirm>
          )}
        </Flex>
      ),
    },
    {
      title: t('app.common.status'),
      dataIndex: 'paid',
      key: 'paid',
      width: 100,
      render: (paid: boolean) => (
        <Tag color={paid ? Colors.success : Colors.waiting}>
          {t(paid ? 'app.common.paid' : 'app.common.waitingForPayment')}
        </Tag>
      ),
    },
    {
      title: t('app.common.number'),
      dataIndex: 'numero',
      key: 'numero',
    },
    {
      title: t('app.common.amount'),
      dataIndex: 'ttcPrice',
      key: 'ttcPrice',
      render: (price: number) => `${price} ${currency?.me.currency.symbol}`,
    },
    ...(withGallery ? galleryColumn : []),
    {
      title: t('app.common.contact'),
      dataIndex: 'contact',
      key: 'contact.id',
      render(_, record) {
        const { contact } = record;
        if (contact) {
          return (
            <Button
              type="link"
              href="#"
              onClick={e => {
                e.preventDefault();
                updateContact(contact.id);
              }}
            >
              {contact.displayName}
            </Button>
          );
        }
        return '-';
      },
    },
    {
      title: t('app.common.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      render: text => dayjs(text).format('LLL'),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      loading={isLoading}
      pagination={{
        total,
        pageSize: paginationSize,
        current: paginationCurrent,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()} ant-table--bordered`}
      onChange={(pagination, filters, sorter) => {
        let order: 'desc' | 'asc' = 'desc';

        if (isArray(sorter)) {
          order = sorter[0].order === 'ascend' ? 'asc' : 'desc';
        } else {
          order = sorter.order === 'ascend' ? 'asc' : 'desc';
        }

        handleOnChange({
          page: pagination.current ? pagination.current : 1,
          sort: order,
        });
      }}
    />
  );
};

export default InvoiceTable;
