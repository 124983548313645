import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { App, Button, Flex, Table, Tag, Typography } from 'antd';
import { useTheme } from 'antd-style';

import Icon from 'Components/Atoms/Icon';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import PicklistDrawer from 'Components/Molecules/Drawers/PicklistDrawer';

import { EditPicklistFormPayload, NewPicklistFormPayload } from 'Forms/Picklist';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useContactModal } from 'Hooks/useContactModal';

import { GET_PICKLISTS } from 'Operations/Queries/Picklist/GetPicklists';

import { CREATE_PICKLIST } from 'Operations/Mutations/Picklist/createPicklist';
import { REMOVE_PICKLIST } from 'Operations/Mutations/Picklist/removePicklist';
import { UPDATE_PICKLIST } from 'Operations/Mutations/Picklist/updatePicklist';

interface GalleryPicklistsTabProps {
  galleryId: number;
}

const perPage = 10;

const GalleryPicklistsTab = ({ galleryId }: GalleryPicklistsTabProps) => {
  const { t } = useContext(LocalizationContext);
  const { openModal, closeModal } = useModals();
  const { updateContact } = useContactModal();
  const theme = useTheme();
  const { message, modal } = App.useApp();

  const [page, setPage] = useState(1);
  const [selectedPicklistId, setSelectedPicklistId] = useState<number | undefined>();

  const variables = useMemo(
    () => ({
      where: {
        id: galleryId,
      },
      paginate: {
        page,
        perPage,
      },
    }),
    [galleryId, page],
  );

  const {
    data,
    fetchMore,
    loading: isGetLoading,
  } = useQuery(GET_PICKLISTS, {
    fetchPolicy: 'cache-and-network',
    returnPartialData: true,
    variables,
  });

  const [createPicklist, { loading: isAddLoading }] = useMutation(CREATE_PICKLIST);
  const [updatePicklist, { loading: isUpdateLoading }] = useMutation(UPDATE_PICKLIST);
  const [removePicklist, { loading: isRemoveLoading }] = useMutation(REMOVE_PICKLIST, {
    update(cache, { data }) {
      if (data?.removePicklist) {
        cache.evict({ id: cache.identify(data.removePicklist) });
        cache.gc();
      }
    },
  });

  const isLoading = isGetLoading || isRemoveLoading || isUpdateLoading || isAddLoading;

  const handleCreatePicklist = useCallback(
    async ({ values, formikBag }: NewPicklistFormPayload) => {
      try {
        formikBag.setSubmitting(true);

        await createPicklist({
          variables: {
            where: {
              id: galleryId,
            },
            data: {
              accessCodes: values.groupCodes,
              contactId: values.contactId,
              name: values.name,
              description: values.description,
            },
          },
          update(cache, { data }) {
            if (data?.createPicklist) {
              // Add the new picklist to the get picklists query
              const newPicklist = { ...data.createPicklist, photos: { _count: 0 } };

              const existingPicklists = cache.readQuery({
                query: GET_PICKLISTS,
                variables,
              });
              if (existingPicklists && newPicklist) {
                cache.writeQuery({
                  query: GET_PICKLISTS,
                  variables,
                  data: {
                    getPicklists: {
                      ...existingPicklists.getPicklists,
                      edges: [newPicklist, ...existingPicklists.getPicklists.edges],
                      _count: existingPicklists.getPicklists._count + 1,
                    },
                  },
                });
              }
            }
          },
        });

        closeModal();

        message.error('app.message.picklist.create.success');
      } catch (error) {
        message.error('app.error.somethingWentWrong');
      } finally {
        formikBag.setSubmitting(false);
      }
    },
    [createPicklist, galleryId],
  );

  const handleUpdatePicklist = useCallback(
    async ({ values, formikBag }: EditPicklistFormPayload) => {
      try {
        formikBag.setSubmitting(true);

        await updatePicklist({
          variables: {
            where: {
              id: values.id,
            },
            data: {
              accessCodes: values.groupCodes,
              contactId: values.contactId,
              name: values.name,
              description: values.description,
            },
          },
        });

        closeModal();

        message.error('app.message.picklist.update.success');
      } catch (error) {
        message.error('app.error.somethingWentWrong');
      } finally {
        formikBag.setSubmitting(false);
      }
    },
    [updatePicklist],
  );

  return (
    <>
      <Flex vertical gap="middle">
        <Flex gap="middle" justify="flex-start" align="center">
          <Button
            icon={<Icon name="plus" />}
            type="primary"
            onClick={() =>
              openModal('PICKLIST', {
                name: 'new',
                onSubmit: handleCreatePicklist,
                galleryId,
              })
            }
          >
            {t('app.common.add')}
          </Button>
        </Flex>
        <Table
          dataSource={data?.getPicklists?.edges}
          columns={[
            {
              title: t('app.common.actions'),
              key: 'operation',
              width: 100,
              render(_, record) {
                return (
                  <Flex gap="small">
                    <RoundButton
                      icon="show"
                      tooltipTitle={t('app.common.show')}
                      onClick={() => setSelectedPicklistId(record.id)}
                    />
                    <RoundButton
                      icon="edit"
                      tooltipTitle={t('app.common.edit')}
                      onClick={() =>
                        openModal('PICKLIST', {
                          name: 'edit',
                          galleryId,
                          contact: record.owners.find(o => !!o.contact)?.contact ?? undefined,
                          groups: record.owners.filter(owner => !owner.contact),
                          onSubmit: handleUpdatePicklist,
                          defaultValues: {
                            id: record.id,
                            name: record.name,
                            description: record.description || undefined,
                            contactId: record.owners.find(o => !!o.contactId)?.contactId ?? undefined,
                            groupCodes: record.owners.filter(owner => !owner.contact)?.map(o => o.code),
                          },
                        })
                      }
                    />
                    <RoundButton
                      icon="download"
                      disabled={!record.photos._count}
                      tooltipTitle={t('app.common.download')}
                      onClick={() =>
                        openModal('DOWNLOAD_PICKLIST', {
                          picklistId: record.id,
                          galleryId,
                        })
                      }
                    />
                    <RoundButton
                      icon="delete"
                      tooltipTitle={t('app.common.delete')}
                      danger
                      onClick={() =>
                        modal.confirm({
                          title: t('app.confirm.moveToTrash', { count: 1 }),
                          okText: t('app.gallery.moveToTrash'),
                          cancelText: t('app.common.cancel'),
                          onOk() {
                            removePicklist({ variables: { where: { id: record.id } } });
                          },
                        })
                      }
                    />
                  </Flex>
                );
              },
            },
            {
              title: t('app.common.name'),
              key: 'name',
              dataIndex: 'name',
              width: 300,
              render(name) {
                return (
                  <Flex align="center">
                    <Typography.Text>{name}</Typography.Text>
                  </Flex>
                );
              },
            },
            {
              title: t('app.common.photoCount'),
              key: 'count',
              width: 150,
              render(_, record) {
                return record.photos._count;
              },
            },
            {
              title: t('app.common.owner'),
              key: 'owner',
              render(_, record) {
                return record.owners?.map(owner =>
                  owner.contact ? (
                    <Flex gap="small" align="center">
                      <Button type="link" onClick={() => owner.contactId && updateContact(owner.contactId)}>
                        {owner.contact.displayName}
                      </Button>
                      <Tag>
                        <Typography.Text copyable>{owner.code}</Typography.Text>
                      </Tag>
                    </Flex>
                  ) : (
                    <Flex gap="small">
                      <span>{owner.name}</span>
                      <Icon
                        name="info-circle"
                        color={theme.colorSecondary}
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          openModal('INFO', {
                            title: t('app.gallery.access.groupTitle', { count: 1 }),
                            content: t('app.access.parentGroup.description'),
                          })
                        }
                      />
                    </Flex>
                  ),
                );
              },
            },
          ]}
          size="middle"
          loading={isLoading}
          pagination={{
            total: data?.getPicklists?._count,
            pageSize: perPage,
            showSizeChanger: false,
            position: ['bottomCenter'],
          }}
          onChange={pagination =>
            fetchMore({
              variables: {
                paginate: {
                  page: pagination.current,
                  perPage,
                },
              },
            })
          }
        />
      </Flex>
      <PicklistDrawer
        picklistId={selectedPicklistId}
        onClose={() => setSelectedPicklistId(undefined)}
        galleryId={galleryId}
      />
    </>
  );
};

export default GalleryPicklistsTab;
