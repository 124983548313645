import React, { HTMLAttributes } from 'react';

import Legend from 'Components/Atoms/Legend';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

const FieldsetStyled = styled.fieldset`
  margin-bottom: ${Metrics.baseMargin}px;
  padding: 0 12px;
  border: 1px solid ${Colors.border};
  border-radius: 24px;

  .Fieldset {
    &--asChildren {
      margin-bottom: ${Metrics.smallMargin}px;
    }
  }
`;

interface Props extends HTMLAttributes<'fieldset'> {
  legend?: string;
  children: React.ReactNode;
}

const Fieldset = ({ legend, children, style, className }: Props) => (
  <FieldsetStyled style={style} className={className}>
    {legend && <Legend>{legend}</Legend>}
    {children}
  </FieldsetStyled>
);

export default Fieldset;
