import { gql } from 'Operations/__generated__';

export const SIGN_IN_WITH_GOOGLE = gql(/* GraphQL */ `
  mutation SignInWithGoogle($data: GoogleAuthInputType!) {
    signInWithGoogle(data: $data) {
      accessToken
      refreshToken
    }
  }
`);
