import React, { useContext, useEffect, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';
import GalleryArchivedTable, { HandleOnChangeProps } from 'Components/Molecules/Tables/GalleryArchivedTable';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import getErrorCode from 'Helpers/GetErrorCode';

import { GalleriesGetWhereType, GalleryOrderType, GalleryStatus } from 'Operations/__generated__/graphql';

import { GET_GALLERIES } from 'Operations/Queries/Gallery/GetGalleries';
import { GET_STORAGE } from 'Operations/Queries/Storage/GetStorage';

import { DELETE_GALLERY } from 'Operations/Mutations/Gallery/DeleteGallery';
import { UPDATE_GALLERY } from 'Operations/Mutations/Gallery/UpdateGallery';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  margin-top: ${Metrics.baseMargin}px;
`;

const PER_PAGE = 20;

const GalleriesArchived = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const { modal, message } = App.useApp();

  const [paginationParams, setPaginationParams] = useState<GalleriesGetWhereType>({
    page: 1,
    perPage: PER_PAGE,
    status: [GalleryStatus.ARCHIVED],
    order: GalleryOrderType.DESC,
  });

  const [getGalleries, { data, loading: isGalleriesLoading }] = useLazyQuery(GET_GALLERIES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const refetchQueries = [
    {
      query: GET_GALLERIES,
      variables: {
        where: paginationParams,
      },
    },
  ];

  const { data: storageData } = useQuery(GET_STORAGE, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateGallery] = useMutation(UPDATE_GALLERY, {
    refetchQueries,
    onCompleted: () => {
      if (
        data &&
        data?.getPaginatedGalleries?._count % PER_PAGE === 0 &&
        paginationParams.page &&
        paginationParams.page > 1
      ) {
        setPaginationParams({ ...paginationParams, page: paginationParams.page - 1 });
      }
    },
  });

  const [deleteGallery] = useMutation(DELETE_GALLERY, {
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: () => {
      if (
        data &&
        data?.getPaginatedGalleries?._count % PER_PAGE === 0 &&
        paginationParams.page &&
        paginationParams.page > 1
      ) {
        setPaginationParams({ ...paginationParams, page: paginationParams.page - 1 });
      }
    },
  });

  useEffect(() => {
    getGalleries({
      variables: {
        where: paginationParams,
      },
    });
  }, [getGalleries, paginationParams]);

  if (!storageData || (storageData && !storageData?.getStorage)) {
    return null;
  }

  const { usedSpace, maxSpace } = storageData.getStorage;

  const handleFilterTable = async (params: GalleriesGetWhereType) => {
    setPaginationParams({ ...params, status: [GalleryStatus.ARCHIVED] });
  };

  const handlePutBack = async ({ id }: { id: number }) => {
    const gallery = data?.getPaginatedGalleries?.edges.find(x => x.id === id);
    if (!gallery) {
      return;
    }

    if (gallery.usedSpace + usedSpace > maxSpace) {
      modal.confirm({
        title: t('app.account.spaceFull.title'),
        content: t('app.account.spaceFull.description'),
        okText: t('app.common.ok'),
      });
      return;
    }

    try {
      await updateGallery({
        variables: {
          where: {
            id,
          },
          data: {
            status: GalleryStatus.OFFLINE,
          },
        },
      });

      message.success(
        <>
          {t('app.message.gallery.unarchive.success')}{' '}
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              navigate(`/app/galleries/${id}`);
              message?.destroy();
            }}
          >
            {t('app.gallery.clickHereToSee')}
          </a>
        </>,
      );
    } catch (e) {
      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  const handleDeleteClick = async ({ id }: { id: number }) => {
    return onDelete({ id, forceDelete: false });
  };

  const onDelete = async ({ id, forceDelete }: { id: number; forceDelete: boolean }) => {
    try {
      await deleteGallery({
        variables: {
          where: {
            id,
          },
          data: {
            forceDelete,
          },
        },
      });

      message.success(t('app.message.gallery.delete.success'));
    } catch (error) {
      const errorCode = getErrorCode(error);
      if (errorCode === 'DELETE_NOT_ALLOWED_ORDERS') {
        modal.confirm({
          title: t('app.message.gallery.warning.orderOpen'),
          okText: t('app.gallery.moveToTrash'),
          cancelText: t('app.common.cancel'),
          onOk() {
            onDelete({ id, forceDelete: true });
          },
        });
        return;
      }
      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  return (
    <>
      <Header
        title={t('app.menu.archived')}
        breadcrumbContent={[{ text: t('app.menu.home'), url: '/app/dashboard' }, { text: t('app.menu.archived') }]}
      />
      <Layout>
        <StyledTableContentContainer>
          <GalleryArchivedTable
            galleriesTotal={data ? data.getPaginatedGalleries._count : 0}
            data={data?.getPaginatedGalleries.edges}
            loading={isGalleriesLoading}
            paginationCurrent={paginationParams.page || 1}
            paginationSize={paginationParams.perPage || PER_PAGE}
            handleOnChange={handleFilterTable}
            handlePutBack={handlePutBack}
            handleDeleteConfirm={handleDeleteClick}
          />
        </StyledTableContentContainer>
      </Layout>
    </>
  );
};

export default GalleriesArchived;
