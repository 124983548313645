import { gql } from 'Operations/__generated__';

export const GET_PICKLIST = gql(/* GraphQL */ `
  query GetPicklist($where: IdInput!, $wherePhotos: PicklistPhotosGetWhereType!) {
    getPicklist(where: $where) {
      ...PicklistCoreFields
      accessCodes {
        ...AccessCodeCoreFields
      }
      photos(where: $wherePhotos) {
        _count
        edges {
          ...PhotoCoreFields
        }
        pageInfo {
          currentPage
          isFirstPage
          isLastPage
          pageCount
        }
      }
    }
  }
`);
