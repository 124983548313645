import React, { useContext, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useRoutes } from 'react-router-dom';

import Order from 'Pages/SlipControl/Order';

import { LocalizationContext } from 'i18n';

import { ApolloContext } from 'Providers/ApolloProvider';

import { onAppLoading } from 'Services/AppLifeCycle';

import { GET_AUTH } from 'Operations/Queries/Auth/GetAuth';

const SlipControlNavigation = () => {
  const { data } = useQuery(GET_AUTH);
  const { setLocale } = useContext(LocalizationContext);
  const { client, isPublicPage } = useContext(ApolloContext);

  const routesElement = useRoutes([
    {
      path: ':orderId',
      element: <Order />,
    },
  ]);

  const isLoggedIn = data?.auth?.isLoggedIn || false;

  useEffect(() => {
    const initApp = async () => {
      if (client) {
        await onAppLoading({ client, setLocale, isPublicPage, isLoggedIn });
      }
    };

    initApp();
  }, [client, setLocale, isPublicPage, isLoggedIn]);

  return routesElement;
};

export default SlipControlNavigation;
