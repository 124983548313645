import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { Alert, App, Button, Flex, List, Modal, Popconfirm, Tooltip } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IdProps } from 'types/Common';

import { camelCase } from 'lodash';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';
import Tag from 'Components/Atoms/Tag';
import Text from 'Components/Atoms/Text';

import ProductCatalogsDrawer from 'Components/Molecules/Drawers/ProductCatalogsDrawer';
import Header from 'Components/Molecules/Header';
import ImageCard from 'Components/Molecules/ImageCard';
import Panels from 'Components/Molecules/Panels';
import { AddOptionProps } from 'Components/Molecules/Products/CategoryColumn';
import StatCard from 'Components/Molecules/Stats/StatCard';

import DigitalProductConfig from 'Pages/App/Products/ProductsShow/DigitalProductConfig';
import ProductShowSkeleton from 'Pages/App/Skeletons/ProductShowSkeleton';

import { EditProductFormPayload } from 'Forms/Product';

import { Colors, Images, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useCurrency } from 'Hooks/useCurrency';
import { useRoles } from 'Hooks/useRoles';

import { ProductOption } from 'Operations/__generated__/graphql';

import { GET_PRODUCT } from 'Operations/Queries/Product/GetProduct';

import { DUPLICATE_PRODUCT } from 'Operations/Mutations/Product/DuplicateProduct';
import { UPDATE_PRODUCT } from 'Operations/Mutations/Product/UpdateProduct';
import { DELETE_PRODUCT_OPTION } from 'Operations/Mutations/ProductOption/DeleteProductOption';
import { DELETE_PRODUCT_OPTION_CATEGORY } from 'Operations/Mutations/ProductOptionCategory/DeleteProductOptionCategory';

import ProductOptionConfig from './ProductOptionConfig';

const StyledOptionsContainer = styled(ContentContainer)`
  flex: 1;
  width: 100%;
  max-width: 1500px;
`;

const AlertStyled = styled(Alert)`
  margin-bottom: ${Metrics.mediumMargin}px;
`;

const TagStyled = styled(Tag)`
  margin-left: ${Metrics.tinyMargin}px;
`;

const ListItem = styled(List.Item)`
  padding: 0;
  padding: ${Metrics.tinyMargin}px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const statCardProps: {
  minWidth: number;
  shadow: boolean;
  width: number | 'auto';
  type: 'medium';
} = {
  minWidth: 90,
  shadow: true,
  width: 180,
  type: 'medium',
};

const imageCardProps: {
  minWidth: number;
  maxHeight: number;
  shadow: boolean;
  width: number | 'auto';
} = {
  minWidth: 372,
  maxHeight: 300,
  shadow: true,
  width: 372,
};

const ProductsShow = () => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const { id } = useParams<{ id: string }>();
  const productId = id ? parseInt(id, 10) : undefined;

  const { openModal, closeModal } = useModals();
  const { isAdmin } = useRoles();
  const navigate = useNavigate();
  const { formatCurrency } = useCurrency();

  const [selectedCategoryId, setSelectedCategoryId] = useState<null | number>(null);
  const [selectedOptionId, setSelectedOptionId] = useState<null | number>(null);
  const [isPackagesModalOpen, setIsPackagesModalOpen] = useState<boolean>(false);
  const [isCatalogDrawerOpen, setIsCatalogDrawerOpen] = useState(false);

  const {
    data,
    loading: isProductLoading,
    called: isCalled,
  } = useQuery(GET_PRODUCT, {
    skip: !productId,
    variables: {
      where: { id: productId as number },
    },
    fetchPolicy: 'cache-and-network',
  });

  const refetchQueries = [
    {
      query: GET_PRODUCT,
      variables: {
        where: { id: productId },
      },
    },
  ];

  const [updateProduct] = useMutation(UPDATE_PRODUCT, { refetchQueries });

  const [deleteProductOptionCategory] = useMutation(DELETE_PRODUCT_OPTION_CATEGORY, { refetchQueries });

  const [deleteProductOption] = useMutation(DELETE_PRODUCT_OPTION, {
    awaitRefetchQueries: true,
    refetchQueries,
  });

  const [duplicateProduct] = useMutation(DUPLICATE_PRODUCT);

  const hasPresetPricingPlan = useMemo(() => {
    return !!data?.getProduct.productOptionCategories
      .find(c => c.position === 1)
      ?.productOptions.some(o => o.hasPresetPricingPlan && o.shouldUsePresetPricingPlan);
  }, [data?.getProduct.productOptionCategories]);

  const shouldShowPricingPlanAlert = useMemo(() => {
    return (
      data?.getProduct.presetProductId &&
      !!data?.getProduct.productOptionCategories
        .find(c => c.position === 1)
        ?.productOptions.find(o => !(o.hasPricingPlan || o.hasPresetPricingPlan))
    );
  }, [data?.getProduct.presetProductId, data?.getProduct.productOptionCategories]);

  const isReadOnly = useMemo(
    () =>
      (!!data?.getProduct.isPreconfiguredProduct || !!data?.getProduct.isFotostudioProduct) &&
      (!!data?.getProduct.presetProductId || !isAdmin),
    [
      data?.getProduct.isFotostudioProduct,
      data?.getProduct.isPreconfiguredProduct,
      data?.getProduct.presetProductId,
      isAdmin,
    ],
  );

  const productBreadcrumb = useMemo(() => {
    const product = data?.getProduct;
    if (!product?.presetProductId && product?.isFotostudioProduct) {
      return { text: t('app.common.fotostudioProducts', { count: 2 }), url: '/app/fotostudio-products' };
    } else if (!product?.presetProductId && product?.isPreconfiguredProduct) {
      return { text: t('app.common.preconfiguredProduct', { count: 2 }), url: '/app/preconfigured-products' };
    } else {
      return { text: t('app.common.products', { count: 2 }), url: '/app/products' };
    }
  }, [data?.getProduct, t]);

  const handleEditOnSubmit = useCallback(
    async ({
      values: {
        product: {
          id,
          previewImageUrl: _pwUrl,
          isPreconfiguredProduct: _isPreconfigured,
          isFotostudioProduct: _isFotostudio,
          enableCrop: _enableCrop,
          isDigital: _isDigital,
          presetProductId: _presetProductId,
          pricingPlanId: _pricingPlanId,
          withNewPricingPlan: _withNewPricingPlan,
          ...productData
        },
      },
      formikBag,
    }: EditProductFormPayload) => {
      try {
        await updateProduct({
          variables: {
            where: {
              id,
            },
            data: productData,
          },
        });

        message.success(t('app.message.product.update.success'));
        formikBag.setSubmitting(false);

        closeModal();
      } catch (error) {
        const fieldName = null;
        const errorMessage = t(`app.message.error.somethingWentWrong`);

        if (fieldName) {
          formikBag.setFieldError(fieldName, errorMessage);
        }
        message.error(errorMessage);

        formikBag.setSubmitting(false);
      }
    },
    [closeModal, t, updateProduct],
  );

  const handleDuplicateProduct = useCallback(async () => {
    if (productId) {
      const result = await duplicateProduct({
        variables: {
          where: {
            id: productId,
          },
        },
      });

      if (result.data?.duplicateProduct.id) {
        navigate(`/app/products/${result.data.duplicateProduct.id}`);
      }
    }
  }, [duplicateProduct, navigate, productId]);

  const handleAddCategory = useCallback(() => {
    if (data?.getProduct) {
      openModal('OPTION_CATEGORY', {
        defaultValues: {
          name: '',
          isPreconfiguredProduct: data.getProduct.isPreconfiguredProduct ?? false,
        },
        product: data.getProduct,
      });
    }
  }, [data?.getProduct, openModal]);

  const handleAddOption = useCallback(
    ({ categoryId }: AddOptionProps) => {
      if (data?.getProduct) {
        const optionCategory = data?.getProduct.productOptionCategories.find(category => category.id === categoryId);

        openModal('PRODUCT_OPTION', {
          defaultValues: {
            categoryId,
            name: '',
            previewImage: undefined,
            previewImageUrl: undefined,
            providerPrice: undefined,
            providerDescription: undefined,
            isMainOption: optionCategory?.position === 1 ?? false,
          },
          product: data.getProduct,
        });
      }
    },
    [data?.getProduct, openModal],
  );

  const handleDeleteOption = useCallback(
    async ({ id }: IdProps) => {
      try {
        await deleteProductOption({
          variables: {
            where: {
              id,
            },
          },
        });

        if (selectedOptionId === id) {
          setSelectedOptionId(null);
        }

        message.success(t('app.message.productOption.delete.success'));
      } catch (error) {
        const errorMessage = t(`app.message.error.somethingWentWrong`);
        message.error(errorMessage);
      }
    },
    [deleteProductOption, selectedOptionId, t],
  );

  const handleDeleteOptionCategory = useCallback(
    async ({ id }: IdProps) => {
      try {
        await deleteProductOptionCategory({
          variables: {
            where: {
              id,
            },
          },
        });
        if (selectedCategoryId === id) {
          setSelectedCategoryId(null);
          setSelectedOptionId(null);
        }

        message.success(t('app.message.optionCategory.delete.success'));
      } catch (error) {
        const errorMessage = t(`app.message.error.somethingWentWrong`);
        message.error(errorMessage);
      }
    },
    [deleteProductOptionCategory, selectedCategoryId, t],
  );

  const handlePackagePriceClick = useCallback(() => {
    setIsPackagesModalOpen(true);
  }, []);

  const closePackagesModal = useCallback(() => {
    setIsPackagesModalOpen(false);
  }, []);

  const headerButtons = useMemo(() => {
    const product = data?.getProduct;
    if (!product) {
      return [];
    }

    const buttons = [];

    if (product.isEditable) {
      if (!product.presetProductId) {
        buttons.push(
          <Popconfirm
            onConfirm={handleDuplicateProduct}
            title={t('app.confirm.duplicate', {
              name: product.name,
            })}
          >
            <Button key="duplicate" size="large">
              <Icon name="duplicate" /> {t('app.actions.duplicate')}
            </Button>
          </Popconfirm>,
        );
      }

      if (
        product.productOptionCategories.find(
          c => c.position === 1 && c.productOptions.filter(o => o.pricingPlan).length > 0,
        )
      ) {
        buttons.push(
          <Button key="catalog" size="large" onClick={() => setIsCatalogDrawerOpen(true)}>
            <Icon name="book" />
            {t('app.catalog.drawer.title')}
          </Button>,
        );
      }

      if (!product.category.isDigital) {
        buttons.push(
          <Button
            key="edit"
            size="large"
            type="primary"
            onClick={() => {
              openModal('PRODUCT', {
                name: 'edit',
                onSubmit: handleEditOnSubmit,
                defaultValues: {
                  product: {
                    id: product.id,
                    categoryId: product.category.id,
                    contactId: product.contactId ?? undefined,
                    name: product.name,
                    previewImageUrl: product.previewAsset?.downloadUrl ?? undefined,
                    maxPhotoCount: product.maxPhotoCount,
                    description: product.description || '',
                    orderedByFotostudio: product.orderedByFotostudio,
                    photoSizes: product.photoSizes.map(({ __typename, ...sizes }) => sizes),
                    presetProductId: product.presetProductId ?? undefined,
                    isPreconfiguredProduct: product.isPreconfiguredProduct,
                    isFotostudioProduct: product.isFotostudioProduct,
                    enableCrop: product.photoSizes.length > 0,
                    isDigital: false,
                    weight: product.weight,
                    profitMarginPercent:
                      product.isFotostudioProduct && product.presetProductId
                        ? product.profitMarginPercent || 0
                        : undefined,
                  },
                },
                isAdmin,
              });
            }}
          >
            <Icon name="edit" /> {t('app.common.edit')}
          </Button>,
        );
      }
    }

    return buttons;
  }, [data?.getProduct, handleDuplicateProduct, handleEditOnSubmit, isAdmin, openModal, t]);

  const renderOptionConfig = useCallback(
    (option: DeepPartial<ProductOption>) =>
      data?.getProduct ? (
        <ProductOptionConfig option={option} product={data.getProduct} isReadOnly={isReadOnly} />
      ) : null,
    [data?.getProduct, isReadOnly],
  );

  const panelData = useMemo(
    () => (data?.getProduct.productOptionCategories || []).map(i => ({ ...i })),
    [data?.getProduct.productOptionCategories],
  );

  const selectedCategory = useMemo(
    () => panelData.find(category => category.id === selectedCategoryId),
    [panelData, selectedCategoryId],
  );

  const selectedOption = useMemo(
    () => selectedCategory?.productOptions?.find(option => option.id === selectedOptionId),
    [selectedCategory?.productOptions, selectedOptionId],
  );

  const hasMultiplePackages = useMemo(
    () => (data?.getProduct ? data.getProduct.packages._count > 1 : false),
    [data?.getProduct],
  );

  useEffect(() => {
    // set default value on first render and on provider change
    if (!selectedCategoryId && panelData.length) {
      setSelectedCategoryId(panelData[0].id);
    }
    if (!selectedOptionId && selectedCategory?.productOptions.length) {
      setSelectedOptionId(selectedCategory.productOptions[0].id);
    }
  }, [panelData, selectedCategory?.productOptions, selectedCategoryId, selectedOptionId]);

  if ((isProductLoading && !isCalled) || !data) {
    return <ProductShowSkeleton />;
  }

  const product = data.getProduct;
  const { productOptionCategories } = product;

  return (
    <Flex vertical gap="middle">
      <Header
        title={
          <Flex align="center">
            <span>{data.getProduct.name}</span>
            {data.getProduct.catalogs.map(c => (
              <Link to={`/app/catalogs/${c.id}`}>
                <TagStyled color={Colors.lightGrey} textColor={Colors.black}>
                  {c.name}
                </TagStyled>
              </Link>
            ))}
          </Flex>
        }
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          productBreadcrumb,
          { text: data.getProduct.name },
        ]}
        extra={
          <>
            {data.getProduct.isPreconfiguredProduct && (
              <TagStyled color={Colors.primaryMain}>{t('app.common.preconfigured')}</TagStyled>
            )}
            {data.getProduct.isFotostudioProduct && <TagStyled color={Colors.crmMain}>Fotostudio</TagStyled>}
          </>
        }
        buttons={headerButtons}
      />
      <Layout flexDirection="column">
        <Flex flex={1} vertical gap="middle">
          {!data.getProduct.category.isDigital && (
            <Flex gap="middle">
              <ImageCard
                {...imageCardProps}
                image={product.previewAsset?.downloadUrl ?? undefined}
                missing={Images.productDetailsMissing}
                alt={product.name}
              />
              <Flex gap="middle" flex={1} wrap="wrap" align="flex-start" justify="flext-start">
                <StatCard
                  {...statCardProps}
                  label={t('app.common.provider')}
                  value={product.contact ? product.contact.displayName : '-'}
                  href={
                    product.contact && product.isEditable
                      ? `/app/contacts/${product.contact.id}/transport-fees`
                      : undefined
                  }
                />
                <StatCard
                  {...statCardProps}
                  label={t('app.common.category')}
                  value={
                    !data?.getProduct.presetProductId &&
                    (data?.getProduct.isPreconfiguredProduct || data?.getProduct.isFotostudioProduct)
                      ? t(`app.categories.${data?.getProduct.category.name.toLowerCase()}`)
                      : product.category.name
                  }
                />
                {!!data?.getProduct.pricingPlanMeta?.startingPrice && data?.getProduct.profitMarginPercent !== null && (
                  <StatCard
                    {...statCardProps}
                    label={t('app.products.grossPrice')}
                    value={data.getProduct.pricingPlanMeta.formattedGrossPrice}
                  />
                )}
                {!!data.getProduct.pricingPlanMeta?.startingPrice && (
                  <StatCard
                    {...statCardProps}
                    label={
                      hasPresetPricingPlan && data?.getProduct.profitMarginPercent !== null
                        ? t('app.products.myPrice')
                        : t('app.common.price')
                    }
                    value={data.getProduct.pricingPlanMeta.formattedPrice}
                  />
                )}
                {hasPresetPricingPlan && data?.getProduct.profitMarginPercent !== null && (
                  <StatCard
                    {...statCardProps}
                    label={t('app.common.profitMargin')}
                    value={`${data.getProduct.profitMarginPercent} %`}
                  />
                )}
                {!!data?.getProduct.weight && (
                  <StatCard {...statCardProps} label={t('app.common.weight')} value={`${data.getProduct.weight} g`} />
                )}
                {!!data?.getProduct.photoSizes.length && (
                  <StatCard
                    {...statCardProps}
                    label={t('app.common.photoCount')}
                    value={`${data.getProduct.photoSizes.length}`}
                  />
                )}
                {!!data?.getProduct.packagePrice && (
                  <Tooltip
                    title={t('app.products.packagePrice.about')}
                    overlayInnerStyle={{ padding: Metrics.smallMargin }}
                  >
                    <StatCard
                      {...statCardProps}
                      label={t('app.products.packagePrice.title')}
                      icon="info-circle"
                      value={
                        hasMultiplePackages
                          ? t('app.products.packagePrice.value', {
                              price: formatCurrency(data?.getProduct.packagePrice),
                            })
                          : formatCurrency(data?.getProduct.packagePrice)
                      }
                      onClick={handlePackagePriceClick}
                    />
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          )}
          {product.category.isDigital &&
            product.digitalProductConfig &&
            productOptionCategories[0].productOptions[0].pricingPlan && (
              <DigitalProductConfig
                product={product}
                pricingPlanId={productOptionCategories[0].productOptions[0].pricingPlan.id}
                digitalProductConfigId={product.digitalProductConfig.id}
                productOptionId={productOptionCategories[0].productOptions[0].id}
                productOptionName={productOptionCategories[0].productOptions[0].name}
                downloadContent={product.digitalProductConfig.downloadContent}
              />
            )}
          {!product.category.isDigital && (
            <StyledOptionsContainer shadow rounded padding="baseMargin">
              {shouldShowPricingPlanAlert && (
                <AlertStyled type="warning" message={t('app.product.options.info.assignPricingPlan')} />
              )}
              {hasPresetPricingPlan && (
                <AlertStyled type="info" message={t('app.product.options.info.hasPresetPricingPlan')} />
              )}
              <Panels.Container>
                <Panels.Column title={t('app.products.optionCategory', { count: 2 })} isEmpty={!panelData?.length}>
                  {panelData.map(category => (
                    <Panels.Item
                      key={category.id}
                      isSelected={category.id === selectedCategoryId}
                      title={category.name}
                      onClick={() => {
                        if (category.id !== selectedCategoryId) {
                          setSelectedCategoryId(category.id);
                          setSelectedOptionId(null);
                        }
                      }}
                      icon={
                        category.position > 1 || (productOptionCategories.length === 1 && !isReadOnly)
                          ? 'delete'
                          : undefined
                      }
                      iconTooltip={t('app.common.delete')}
                      onIcon={() => handleDeleteOptionCategory({ id: category.id })}
                      iconConfirmMessage={t('app.confirm.delete')}
                    />
                  ))}
                  {!isReadOnly && (
                    <Button type={panelData.length ? 'link' : 'primary'} onClick={handleAddCategory} size="small">
                      <Icon name="add" /> {t('app.action.addCategory')}
                    </Button>
                  )}
                </Panels.Column>
                <Panels.Column
                  title={
                    t('app.products.optionCategory', { count: 2 }) +
                    (selectedCategory?.name ? `: ${selectedCategory.name}` : '')
                  }
                  isEmpty={!selectedCategory?.productOptions.length}
                >
                  {selectedCategory?.productOptions.map(option => (
                    <Panels.Item
                      key={option.id}
                      isSelected={option.id === selectedOptionId}
                      title={option.name}
                      subtitle={option.pricingPlanMeta.formattedPrice}
                      onClick={() => setSelectedOptionId(option.id)}
                      icon={!isReadOnly ? 'delete' : undefined}
                      iconTooltip={t('app.common.delete')}
                      onIcon={() => handleDeleteOption({ id: option.id })}
                      iconConfirmMessage={t('app.confirm.delete')}
                    />
                  ))}
                  {!isReadOnly && selectedCategory && (
                    <Flex vertical gap="small">
                      <Text color={Colors.disabled}>{selectedCategory.name}&nbsp;:</Text>
                      <Button
                        type={selectedCategory?.productOptions.length ? 'link' : 'primary'}
                        onClick={() => handleAddOption({ categoryId: selectedCategory.id })}
                        block
                      >
                        <Icon name="add" /> {t('app.action.addOption')}
                      </Button>
                    </Flex>
                  )}
                </Panels.Column>
                <Panels.Column
                  title={t('app.common.configuration', {
                    content: selectedOption?.name || '-',
                  })}
                  isEmpty={!selectedOptionId}
                  style={{ minWidth: '50%' }}
                >
                  {selectedOption && renderOptionConfig(selectedOption)}
                </Panels.Column>
              </Panels.Container>
            </StyledOptionsContainer>
          )}
        </Flex>
      </Layout>
      <Modal
        title={t('app.product.packages.title')}
        open={isPackagesModalOpen}
        onCancel={closePackagesModal}
        footer={null}
      >
        <List
          itemLayout="horizontal"
          dataSource={product.packages.edges}
          renderItem={productPackage => (
            <ListItem>
              <List.Item.Meta
                title={t(`app.contact.transportFees.carrier.${camelCase(productPackage.carrier)}`)}
                description={`${formatCurrency(productPackage.priceExclVat)} ${t(
                  'app.contact.transportFees.vat.excluded',
                )} - ${productPackage.weight} g`}
              />
            </ListItem>
          )}
        />
      </Modal>
      <ProductCatalogsDrawer
        product={product}
        isVisible={isCatalogDrawerOpen}
        onCloseDrawer={() => setIsCatalogDrawerOpen(false)}
      />
    </Flex>
  );
};

export default ProductsShow;
