import { gql } from 'Operations/__generated__';

export const REMOVE_FAVORITE_CATALOG_PRODUCT = gql(/* GraphQL */ `
  mutation RemoveFavoriteCatalogProduct(
    $where: FavoriteCatalogProductWhereType!
    $data: FavoriteCatalogProductDataType!
  ) {
    removeFavoriteCatalogProduct(where: $where, data: $data)
  }
`);
