import { createContext, useCallback, useContext, useState } from 'react';

import { makeReference, useMutation, useQuery, useSubscription } from '@apollo/client';
import { App } from 'antd';

import Icon from 'Components/Atoms/Icon';

import NotificationDrawer from 'Components/Molecules/Drawers/NotificationDrawer';

import { LocalizationContext } from 'i18n';

import getNotificationIcon from 'Helpers/getNotificationIcon';

import { GET_NOTIFICATIONS } from 'Operations/Queries/Notification/GetNotifications';
import { GET_NOTIFICATIONS_UNREAD_COUNT } from 'Operations/Queries/Notification/GetNotificationsUnreadCount';

import { READ_NOTIFICATIONS } from 'Operations/Mutations/Notification/ReadNotifications';

import { ON_NOTIFICATION_ADDED } from 'Operations/Subscriptions/OnNotificationAdded';

export interface NotificationDrawer {
  closeDrawer: () => void;
  openDrawer: () => void;
}
const initialState: NotificationDrawer = {
  closeDrawer: () => {
    throw new Error('Not implemented');
  },
  openDrawer: () => {
    throw new Error('Not implemented');
  },
};

export const NotificationDrawerContext = createContext(initialState);
export const useNotificationDrawer = () => useContext(NotificationDrawerContext);

const PER_PAGE = 50;

export const NotificationDrawerProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { t } = useContext(LocalizationContext);
  const { notification: antNotification } = App.useApp();
  const [isOpen, setIsOpen] = useState(false);
  const { data, fetchMore } = useQuery(GET_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        page: 1,
        perPage: PER_PAGE,
      },
    },
  });
  const [readNotifications] = useMutation(READ_NOTIFICATIONS, {
    refetchQueries: [{ query: GET_NOTIFICATIONS_UNREAD_COUNT }],
  });

  useSubscription(ON_NOTIFICATION_ADDED, {
    onData({ data, client }) {
      if (data.data?.OnNotificationAdded) {
        const notification = data.data.OnNotificationAdded;
        client.cache.modify({
          id: client.cache.identify(makeReference('ROOT_QUERY')),
          fields: {
            getNotifications: (existing, { toReference }) => {
              if (notification) {
                return {
                  ...existing,
                  _count: existing._count + 1,
                  edges: [
                    toReference({
                      __typename: 'Notification',
                      id: notification.id,
                    }),
                    ...existing.edges,
                  ],
                };
              }
              return existing;
            },
            getNotificationsUnreadCount: existing => {
              if (notification) {
                return {
                  ...existing,
                  unread: existing.unread + 1,
                };
              }
              return existing;
            },
          },
        });

        const icon = getNotificationIcon(notification.action);

        antNotification.open({
          key: `${notification.id}`,
          onClick() {
            antNotification?.destroy(`${notification.id}`);
            setIsOpen(true);
          },
          message: t('app.notification.newNotification'),
          icon: <Icon name={icon.name} />,
          duration: 5,
          placement: 'bottomLeft',
        });
      }
    },
  });

  const closeDrawer = useCallback(() => {
    setIsOpen(false);
    const unreadNotifications = data?.getNotifications?.edges.filter(({ isRead }) => !isRead).map(({ id }) => id);

    if (unreadNotifications?.length) {
      readNotifications({
        variables: {
          where: {
            ids: unreadNotifications,
          },
        },
      });
    }
  }, [data?.getNotifications?.edges, readNotifications]);

  const openDrawer = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
    }
  }, [isOpen, setIsOpen]);

  const loadMore = useCallback(() => {
    return fetchMore({
      variables: {
        where: {
          page: Math.floor((data?.getNotifications.edges.length || 1) / PER_PAGE) + 1,
          perPage: PER_PAGE,
        },
      },
    });
  }, [data?.getNotifications.edges.length, fetchMore]);

  return (
    <NotificationDrawerContext.Provider value={{ closeDrawer, openDrawer }}>
      {children}
      <NotificationDrawer
        isVisible={isOpen}
        onCloseDrawer={closeDrawer}
        notifications={data?.getNotifications.edges || []}
        hasMore={!!data?.getNotifications && data.getNotifications.edges.length < data.getNotifications._count}
        loadMore={loadMore}
      />
    </NotificationDrawerContext.Provider>
  );
};
