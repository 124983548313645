import { gql } from 'Operations/__generated__';

export const GET_FOLDERS = gql(/* GraphQL */ `
  query GetFolders($galleryId: Int!, $photoWhere: FolderPhotosGetWhereType) {
    getFolders(where: { galleryId: $galleryId }) {
      id
      name
      parentId
      galleryId
      photos(where: $photoWhere) {
        _count
      }
    }
  }
`);
