import React, { useContext } from 'react';

import { DeepPartial } from '@apollo/client/utilities';
import { Flex, Tabs } from 'antd';

import ExtraVideo from 'Components/Molecules/Extra/video';

import GalleryStatsTab from 'Pages/App/Galleries/Tabs/GalleryStatsTab';

import { LocalizationContext } from 'i18n';

import { GalleryAdmin } from 'Operations/__generated__/graphql';

interface GalleryExtraTabProps {
  data: DeepPartial<GalleryAdmin>;
  galleryId: number;
}

const GalleryExtraTab = ({ galleryId, data }: GalleryExtraTabProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Flex align="stretch">
      <Tabs
        tabPosition="left"
        items={[
          {
            label: t('app.gallery.extra.tabs.video'),
            key: 'video',
            children: <ExtraVideo data={data} galleryId={galleryId} />,
          },
          {
            label: t('app.gallery.tabs.stats'),
            key: 'stats',
            children: <GalleryStatsTab galleryId={galleryId} />,
          },
        ]}
      />
    </Flex>
  );
};

export default GalleryExtraTab;
