import React, { createContext, useContext, useEffect, useState } from 'react';

import { App } from 'antd';

import PhotoUploader from 'Components/Molecules/PhotoUploader';

interface StartUploadProps {
  files: File[];
  targetGalleryId: number;
  targetFolderId?: number | null;
  targetOrderItemId?: number;
  isRetouched?: boolean;
}

export interface Upload {
  galleryId?: number;
  isUploadRunning: boolean;
  startUpload(props: StartUploadProps): void;
}
export type UploadedPhoto = {
  galleryId: number;
  photoName: string;
  assetId: string;
};
const initialState: Upload = {
  isUploadRunning: false,
  startUpload: () => {
    throw new Error('Not implemented');
  },
};

export const UploadContext = createContext(initialState);
export const useUpload = () => useContext(UploadContext);

interface UploadProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const UploadProvider: React.FC<React.PropsWithChildren<UploadProviderProps>> = ({
  children,
}: UploadProviderProps) => {
  const [files, setFiles] = useState<File[]>();
  const { message } = App.useApp();
  const [targetGalleryId, setTargetGalleryId] = useState<number>();
  const [targetOrderItemId, setTargetOrderItemId] = useState<number>();
  const [isRetouched, setIsRetouched] = useState(false);
  const [targetFolderId, setTargetFolderId] = useState<number | null | undefined>(undefined);

  const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    return (e.returnValue = 'Are you sure you want to exit ? Upload will be cancelled.');
  };

  useEffect(() => {
    if (files && files?.length > 0) {
      window.addEventListener('beforeunload', alertUser);
      return () => {
        window.removeEventListener('beforeunload', alertUser);
      };
    } else {
      window.removeEventListener('beforeunload', alertUser);
    }
  }, [files]);

  const startUpload: Upload['startUpload'] = ({
    files: selectedFiles,
    targetGalleryId,
    targetFolderId,
    targetOrderItemId,
    isRetouched,
  }) => {
    setFiles(selectedFiles);
    setTargetGalleryId(targetGalleryId);
    setTargetFolderId(targetFolderId);
    setTargetOrderItemId(targetOrderItemId);
    setIsRetouched(!!isRetouched);
  };

  const handleOnClose = function () {
    setFiles(undefined);
  };
  const handleOnCancel = function () {
    setFiles(undefined);
    message.success('Upload cancelled.');
  };

  return (
    <UploadContext.Provider
      value={{
        galleryId: targetGalleryId,
        isUploadRunning: files && files?.length > 0 ? true : false,
        startUpload,
      }}
    >
      {children}
      {files && files.length > 0 && targetGalleryId !== undefined && (
        <PhotoUploader
          selectedFiles={files}
          targetGallery={targetGalleryId}
          targetFolderId={targetFolderId}
          targetOrderItemId={targetOrderItemId}
          isRetouched={isRetouched}
          onClose={handleOnClose}
          onCancel={handleOnCancel}
        />
      )}
    </UploadContext.Provider>
  );
};
