import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, Card, Flex } from 'antd';
import { createStyles } from 'antd-style';

import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';

import { Images } from 'Themes';
import { globalTheme } from 'Themes/styles';

import { LocalizationContext } from 'i18n';

import { ThemeContext } from 'Providers/ThemeProvider';

import { MenuTheme } from 'Operations/__generated__/graphql';

import { ME } from 'Operations/Queries/User/Me';

import { UPDATE_THEME } from 'Operations/Mutations/User/UpdateTheme';

const useStyles = createStyles(({ css, token, stylish }) => ({
  themeItem: css`
    width: 375px;
    border: 5px solid rgba(255, 255, 255, 0);
    ${stylish.shadow}
    &.active {
      border-color: ${token.colorPrimary};
    }
  `,
}));

const Appearance = () => {
  const { t } = useContext(LocalizationContext);
  const { setTheme } = useContext(ThemeContext);
  const { styles, cx } = useStyles();

  const { data } = useQuery(ME);
  const [updateTheme] = useMutation(UPDATE_THEME);

  const currentTheme = useMemo(() => data?.me.menuTheme, [data?.me.menuTheme]);

  useEffect(() => {
    if (currentTheme) {
      setTheme({ ...globalTheme[currentTheme.toString().toLowerCase() as keyof typeof globalTheme] });
    }
  }, [currentTheme]);

  const handleUpdateTheme = useCallback(
    (newTheme: MenuTheme) => {
      updateTheme({
        variables: {
          data: { menuTheme: newTheme },
        },
      });
    },
    [updateTheme],
  );

  return (
    <Flex vertical gap="middle">
      <Header
        title={t('app.settings.appearance.title')}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.settings'), url: '/app/settings/my-activity' },
          { text: t('app.settings.appearance.title') },
        ]}
      />
      <Layout>
        <Flex vertical gap="middle">
          {/* <Alert message={t('app.settings.appearence.alert')} /> */}
          <Flex gap="large" wrap="wrap">
            <Card
              cover={<img src={Images.themePreviewDflt} />}
              className={cx(styles.themeItem, currentTheme === MenuTheme.DFLT && 'active')}
              onClick={() => handleUpdateTheme(MenuTheme.DFLT)}
              hoverable
            >
              <Card.Meta title={t('app.settings.appearance.default')} />
            </Card>
            <Card
              cover={<img src={Images.themePreviewGradient} />}
              className={cx(styles.themeItem, currentTheme === MenuTheme.GRADIENT && 'active')}
              onClick={() => handleUpdateTheme(MenuTheme.GRADIENT)}
              hoverable
            >
              <Card.Meta title={t('app.settings.appearance.gradient')} />
            </Card>
            <Card
              cover={<img src={Images.themePreviewLight} />}
              className={cx(styles.themeItem, currentTheme === MenuTheme.LIGHT && 'active')}
              onClick={() => handleUpdateTheme(MenuTheme.LIGHT)}
              hoverable
            >
              <Card.Meta title={t('app.settings.appearance.light')} />
            </Card>
          </Flex>
        </Flex>
      </Layout>
    </Flex>
  );
};

export default Appearance;
