import { createStyles } from 'antd-style';

export const useCardStyle = createStyles(({ css, prefixCls, token, stylish }) => ({
  className: css`
    &.${prefixCls}-card {
      overflow: hidden;
      border-radius: ${token.borderRadiusLG}px;
      &--no-shadow {
        box-shadow: none;
      }
      &--shadow {
        ${stylish.shadow}
        transition: box-shadow 150ms ease-in-out;
        &:hover {
          ${stylish.shadowHover}
        }
      }
      .ant-card-actions {
        border-radius: 0 0 ${token.borderRadius}px ${token.borderRadius}px;
      }
      .ant-card-actions > li {
        display: flex;
        justify-content: center;
      }
    }
  `,
}));
