import { gql } from 'Operations/__generated__';

export const ON_PHOTO_ADDED = gql(/* GraphQL */ `
  subscription OnPhotoAdded($where: PhotosSubscriptionWhereType) {
    OnPhotoAdded(where: $where) {
      galleryId
      orderItemId
      photo {
        ...PhotoCoreFields
      }
    }
  }
`);
