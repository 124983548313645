import React, { useContext } from 'react';

import { keyframes } from 'styled-components';

import Container, { Props as ContainerProps } from 'Components/Atoms/Container';
import Text from 'Components/Atoms/Text';

import { Colors } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

interface InfoContainerProps extends ContainerProps {
  statusColor: string;
}

interface StorageCircleType {
  storage: number;
  storageMax: number;
  percent: number;
}

interface StorageSvgProps {
  statusColor: string;
  percent: number;
}

interface GetCapacityColorProps {
  getGradient: boolean;
}

const lineTo = ({ percent }: { percent: number }) => keyframes`
  0% {
    stroke-dashoffset: calc(100% * (100 - 0) / 100 * 3.141592);
  }
  100% {
    stroke-dashoffset: calc(100% * (100 - ${percent}) / 100 * 3.141592);
  }
`;

const InfoContainer = styled(Container)<InfoContainerProps>`
  align-content: center;
  width: 164px;
  height: 164px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid ${({ statusColor }) => statusColor};
  border-radius: 90px;
`;

const StorageCircleContainer = styled(Container)`
  position: relative;
  width: 198px;
  height: 198px;
`;

const StorageSvgContainer = styled.div`
  position: absolute;
  width: 198px;
  height: 198px;
`;

const StorageSvg = styled.svg<StorageSvgProps>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transform: rotate(-90deg);

  circle {
    stroke-width: 4px;
    stroke-dasharray: calc(100% * 3.141592);
    stroke-dashoffset: calc(100% * (100 - 100) / 100 * 3.141592);
    stroke-linecap: round;
    stroke: rgba(255, 255, 255, 0.8);

    &:last-child {
      stroke: ${({ statusColor }) => statusColor};
      stroke-dashoffset: ${({ percent }) => `calc(100% * (100 - ${percent - 2}) / 100 * 3.141592)`};
      animation: 3s
        ${({ percent }) =>
          lineTo({
            percent: percent - 2,
          })}
        cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
`;

const ALMOST_FULL_LIMIT = 75;
const FULL_LIMIT = 100;

const StorageCircle = ({ storage, storageMax, percent }: StorageCircleType) => {
  const { t } = useContext(LocalizationContext);

  const getCapacityColor = ({ getGradient }: GetCapacityColorProps) => {
    if (percent >= ALMOST_FULL_LIMIT && percent < FULL_LIMIT) {
      return Colors.waiting;
    }

    if (percent >= FULL_LIMIT) {
      return Colors.danger;
    }

    // Return gradient for circle progress bar
    if (getGradient) {
      return 'url(#gradient)';
    }

    // InfoContainer stroke color
    return 'rgba(255, 255, 255, 0.6)';
  };

  return (
    <StorageCircleContainer align="center" justify="center">
      <StorageSvgContainer>
        <StorageSvg
          data-role="radial-progress"
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          statusColor={getCapacityColor({ getGradient: true })}
          percent={percent}
        >
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor={Colors.secondaryMain} />
              <stop offset="100%" stopColor={Colors.secondaryOptional} />
            </linearGradient>
          </defs>
          <circle r="48%" cx="50%" cy="50%" fill="transparent"></circle>
          <circle r="48%" cx="50%" cy="50%" fill="transparent"></circle>
        </StorageSvg>
      </StorageSvgContainer>
      <InfoContainer
        direction="column"
        justify="center"
        align="center"
        statusColor={getCapacityColor({ getGradient: false })}
      >
        <Text size="subtitle" weight="bold">
          {storage} / {storageMax}GB
        </Text>
        <Text size="medium">{t('app.storage.used', { percent })}</Text>
      </InfoContainer>
    </StorageCircleContainer>
  );
};

export default StorageCircle;
