import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { App, Button, Drawer, Empty, Input, Spin } from 'antd';

import InfiniteScroll from 'react-infinite-scroll-component';

import { camelCase, compact } from 'lodash';
import useDebouncedEffect from 'use-debounced-effect-hook';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import ProductCard from 'Components/Molecules/ProductCard';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import getErrorCode from 'Helpers/GetErrorCode';

import { GetCatalogQuery, GetProductsQuery, OrderType } from 'Operations/__generated__/graphql';

import { GET_PRODUCTS } from 'Operations/Queries/Product/GetProducts';

const PER_PAGE = 20;
const EDGES_PARAMS = {
  perPage: PER_PAGE,
  order: OrderType.ASC,
};

interface Props {
  isVisible: boolean;
  hideDigitalProducts: boolean;
  category: GetCatalogQuery['getCatalog']['productCategories'][0] | undefined;
  catalogId: number;
  onCloseDrawer?: () => void;
  onProductsAdded?: (products: GetProductsQuery['getProducts']['edges']) => Promise<unknown>;
}

const DrawerContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: ${Metrics.smallMargin}px 0;
`;

const DrawerContent = styled.div`
  padding: 0 ${Metrics.smallMargin}px;
  overflow: visible;
`;

const ProductsLayout = styled(Layout)`
  display: inline-flex;
  flex-wrap: wrap;
  gap: ${Metrics.baseMargin}px;
`;

const Product = styled(ProductCard)`
  width: calc(50% - ${Metrics.smallMargin}px);
`;

const CatalogProductsDrawer = ({
  isVisible,
  hideDigitalProducts,
  onCloseDrawer,
  onProductsAdded,
  catalogId,
  category,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const [search, setSearch] = useState(category?.name);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [isDebouncing, setIsDebouncing] = useState(false);

  const [searchProducts, { data, fetchMore, loading: isLoadingProducts }] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    onCompleted() {
      setIsDebouncing(false);
    },
  });

  const hasFetchAllProducts = useMemo(
    () => data?.getProducts.edges.length === data?.getProducts._count,
    [data?.getProducts._count, data?.getProducts.edges.length],
  );

  const productsPage = useMemo(() => {
    if (!data?.getProducts.edges.length) {
      return 1;
    }
    const loadedCount = data.getProducts.edges.length;

    const currentPage = Math.ceil(loadedCount / PER_PAGE);
    return loadedCount % PER_PAGE === 0 ? currentPage : currentPage - 1;
  }, [data?.getProducts.edges.length]);

  useDebouncedEffect(
    () => {
      if (isVisible) {
        searchProducts({
          variables: {
            where: {
              ...EDGES_PARAMS,
              page: 1,
              search,
              not: {
                catalogId,
              },
            },
          },
        });
      }
    },
    [search, searchProducts, catalogId, isVisible],
    500,
  );

  useEffect(() => {
    if (isVisible) {
      setIsDebouncing(true);
      setSearch(category?.name || '');
    } else {
      setSelectedProducts([]);
      setSearch(undefined);
    }
  }, [category, isVisible]);

  const getMoreProducts = useCallback(async () => {
    if (fetchMore && !hasFetchAllProducts) {
      await fetchMore({
        variables: {
          where: {
            ...EDGES_PARAMS,
            page: productsPage + 1,
            perPage: PER_PAGE,
            search,
            not: {
              catalogId,
            },
          },
        },
      });
    }
  }, [catalogId, fetchMore, hasFetchAllProducts, productsPage, search]);

  const handleSelectedProduct = useCallback(({ id }: { id: number }) => {
    setSelectedProducts(alreadySelectedProducts => {
      if (alreadySelectedProducts.includes(id)) {
        return alreadySelectedProducts.filter(selectedId => selectedId !== id);
      } else {
        return [...alreadySelectedProducts, id];
      }
    });
  }, []);

  const products = useMemo(() => {
    let productsData =
      data?.getProducts?.edges.filter(p =>
        p.productOptionCategories.find(c => c.position === 1 && c.productOptions.filter(o => o.pricingPlan).length > 0),
      ) || [];

    if (productsData.length > 0 && hideDigitalProducts) {
      productsData = productsData.filter(p => !p.category.isDigital);
    }

    return productsData;
  }, [data?.getProducts?.edges, hideDigitalProducts]);

  const onClickAddProducts = useCallback(async () => {
    try {
      await onProductsAdded?.(compact(selectedProducts.map(id => products.find(p => p.id === id))));

      onCloseDrawer?.();
    } catch (error) {
      const errorCode = getErrorCode(error);

      if (errorCode) {
        message.error(t(`app.message.catalog.addProducts.error.${camelCase(errorCode)}`));
      } else {
        message.error(t('app.message.error.somethingWentWrong'));
      }
    }
  }, [onCloseDrawer, onProductsAdded, products, selectedProducts, t]);

  return (
    <Drawer
      open={isVisible}
      title={<Title level="h2">{t('app.products.add', { count: 2 })}</Title>}
      footer={
        <Container justify="space-between" align="center">
          <Text weight="bold">{t('app.products.selected', { count: selectedProducts.length })}</Text>
          <Button disabled={!selectedProducts.length} type="primary" onClick={onClickAddProducts}>
            {t('app.products.add', { count: selectedProducts.length })}
          </Button>
        </Container>
      }
      placement="right"
      onClose={onCloseDrawer}
      width={Metrics.drawer.products}
      closeIcon={<Icon name="close" />}
      styles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          paddingTop: Metrics.smallMargin,
          paddingBottom: Metrics.smallMargin,
        },
      }}
    >
      <DrawerContent>
        <Input
          size="large"
          allowClear
          value={search}
          onChange={({ target }) => {
            setIsDebouncing(true);
            setSearch(target.value);
          }}
        />
      </DrawerContent>
      <DrawerContainer id="CatalogProductsDrawer">
        <DrawerContent>
          {isDebouncing && (
            <Container justify="center" align="center">
              <Spin />
            </Container>
          )}
          {!isLoadingProducts && products.length === 0 && (
            <Container justify="center" align="center">
              <Empty />
            </Container>
          )}
          <InfiniteScroll
            dataLength={products.length || 0}
            next={getMoreProducts}
            hasMore={!hasFetchAllProducts}
            style={{
              overflow: 'visible',
            }}
            loader={
              !isDebouncing && (
                <Container justify="center">
                  <Spin />
                </Container>
              )
            }
            scrollableTarget="CatalogProductsDrawer"
          >
            <ProductsLayout>
              {!isDebouncing &&
                products.map(product => (
                  <Product
                    key={product.id}
                    id={product.id}
                    name={product.name}
                    previewAsset={product.previewAsset?.downloadUrl ?? undefined}
                    isSelected={selectedProducts.includes(product.id)}
                    onClick={handleSelectedProduct}
                    size="small"
                  />
                ))}
            </ProductsLayout>
          </InfiniteScroll>
        </DrawerContent>
      </DrawerContainer>
    </Drawer>
  );
};

export default CatalogProductsDrawer;
