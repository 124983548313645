import { Colors } from 'Themes';
import styled from 'Themes/Styled';

const Legend = styled.legend`
  width: fit-content;
  margin-left: 8px;
  padding: 0 4px;
  font-weight: bold;
  font-size: 12px;
  color: ${Colors.black};
  text-transform: uppercase;
`;

export default Legend;
