import React, { ChangeEvent, forwardRef, useContext, useEffect, useRef, useState } from 'react';

import { Button } from 'antd';

import { FieldHookConfig, useField } from 'formik';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const TextStyled = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${Metrics.tinyMargin}px;
`;

const FileSelect = forwardRef<
  HTMLInputElement,
  FieldHookConfig<File | FileList | null> & {
    multiple?: boolean;
    accept: string;
    handleOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
  }
>((props, ref) => {
  const { t } = useContext(LocalizationContext);
  const fileInput = useRef<HTMLInputElement>(null);

  const [field, _meta, helpers] = useField(props);
  const { onChange, onBlur, value } = field;

  const [filename, setFilename] = useState<string | null>(null);

  useEffect(() => {
    if (!value) {
      setFilename(null);
    }

    if (fileInput.current) {
      fileInput.current.value = '';
    }
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      setFilename(null);
      helpers.setValue(null);
      return;
    }

    if (event.target.files.length > 1) {
      setFilename(null);

      helpers.setValue(event.target.files);
    } else if (event.target.files.length === 1) {
      setFilename(event.target.files[0].name);

      helpers.setValue(event.target.files[0]);
    }

    if (props.handleOnChange) {
      props.handleOnChange(event);
    }

    helpers.setTouched(true);
    onChange(event);
    onBlur(event);
  };

  const onButtonClick = () => {
    if (fileInput && fileInput.current) {
      helpers.setValue(field.value || null);
      fileInput.current.click();
    }
  };

  return (
    <div ref={ref}>
      {!props.multiple && filename && (
        <TextStyled size="small">
          <Icon name="invoice" /> {filename}
        </TextStyled>
      )}
      <Button onClick={onButtonClick} type="primary" size="large" disabled={props.disabled}>
        <Icon name="add-file" /> {t('app.common.chooseFile')}
      </Button>
      <input
        type="file"
        ref={fileInput}
        multiple={props.multiple}
        style={{ display: 'none' }}
        onChange={handleChange}
        accept={props.accept}
      />
    </div>
  );
});

export default FileSelect;
