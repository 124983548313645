import { gql } from 'Operations/__generated__';

export const GET_ORDERS_TOTAL = gql(/* GraphQL */ `
  query GetOrdersTotal($where: OrdersTotalWhereType!) {
    getOrdersTotal(where: $where) {
      total
      amount
      items {
        key
        total
        amount
      }
    }
  }
`);
