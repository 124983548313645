import { StrictTypedTypePolicies } from 'Operations/__generated__/apollo-helpers';

const FolderPolicy: StrictTypedTypePolicies['Folder'] = {
  fields: {
    photos: {
      keyArgs: false,
    },
  },
};

export default FolderPolicy;
