import React from 'react';

import ContentLoader from 'react-content-loader';

const MostViewedPhotosSleketon = () => {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={305}
      viewBox="0 0 400 305"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="24" y="24" rx="8" ry="8" width="122" height="40" />
      <rect x="24" y="80" rx="8" ry="8" width="250" height="20" />
      <rect x="24" y="120" rx="0" ry="0" width="170" height="170" />
      <rect x="210" y="120" rx="0" ry="0" width="79" height="79" />
      <rect x="305" y="120" rx="0" ry="0" width="79" height="79" />
      <rect x="210" y="215" rx="0" ry="0" width="79" height="79" />
      <rect x="305" y="215" rx="0" ry="0" width="79" height="79" />
    </ContentLoader>
  );
};

export default MostViewedPhotosSleketon;
