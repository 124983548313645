import { useContext } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Flex, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { isArray } from 'lodash';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import SearchFilter from 'Components/Molecules/SearchFilter';

import { Colors } from 'Themes';
import { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';

import {
  GalleriesGetWhereType,
  GalleryOrderType,
  GalleryStatus,
  GetGalleriesQuery,
} from 'Operations/__generated__/graphql';

export interface ContactType {
  id: number;
  name: string;
  color: string;
}

export interface HandleOnChangeProps {
  page: number;
  perPage: number;
  order: 'asc' | 'desc';
  status?: GalleryStatus[];
  search?: string;
}

interface IdProps {
  id: number;
}

export interface HandleChangeGalleryStatusProps extends IdProps {
  isChecked: boolean;
}

interface Props {
  galleriesTotal: number;
  loading: boolean;
  paginationCurrent: number;
  paginationSize?: number;
  data?: GetGalleriesQuery['getPaginatedGalleries']['edges'];
  handleOnChange: (params: GalleriesGetWhereType) => void;
  handlePutBack?: (params: IdProps) => void;
  handleDeleteConfirm?: (params: IdProps) => void;
}

const GalleryArchivedTable = ({
  loading,
  galleriesTotal,
  data,
  paginationCurrent,
  paginationSize = 20,
  handleOnChange,
  handlePutBack,
  handleDeleteConfirm,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);

  const columns: ColumnsType<GetGalleriesQuery['getPaginatedGalleries']['edges'][0]> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 120,
      render: (_, record) => (
        <Flex justify="space-between" gap="middle">
          <RoundButton
            icon="put-back"
            tooltipTitle={t('app.common.unarchive')}
            onClick={() => {
              if (handlePutBack) {
                handlePutBack({ id: record.id });
              }
            }}
          />
          <Popconfirm
            title={t('app.confirm.moveToTrash', { count: 1 })}
            onConfirm={() => {
              if (handleDeleteConfirm) {
                handleDeleteConfirm({ id: record.id });
              }
            }}
          >
            <RoundButton icon="delete" tooltipTitle={t('app.gallery.moveToTrash')} danger />
          </Popconfirm>
        </Flex>
      ),
    },
    {
      title: t('app.common.name'),
      dataIndex: 'name',
      key: 'name',
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? Colors.primaryMain : undefined }} />,
      filterDropdown: props => {
        return <SearchFilter {...props} />;
      },
    },
    {
      title: t('app.common.contact'),
      dataIndex: 'contact',
      key: 'contact',
      render: (_, record) => record?.contact?.displayName,
    },
    {
      title: t('app.common.size'),
      dataIndex: 'usedSpace',
      key: 'usedSpace',
      render: text => `${text} GB`,
    },
    {
      title: t('app.common.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      render: text => dayjs(text).format('LLL'),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: true }}
      loading={loading}
      pagination={{
        total: galleriesTotal,
        pageSize: paginationSize,
        current: paginationCurrent,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()}`}
      onChange={(pagination, filters, sorter) => {
        let order: GalleryOrderType = GalleryOrderType.DESC;

        if (isArray(sorter)) {
          order = sorter[0].order === 'ascend' ? GalleryOrderType.ASC : GalleryOrderType.DESC;
        } else {
          order = sorter.order === 'ascend' ? GalleryOrderType.ASC : GalleryOrderType.DESC;
        }

        const params: GalleriesGetWhereType = {
          page: pagination.current ? pagination.current : 1,
          perPage: pagination.pageSize ? pagination.pageSize : 20,
          order,
          search: filters.name && filters.name.length > 0 ? `${filters.name[0]}` : undefined,
        };

        if (filters.status) {
          const filterStatuses = filters.status.map(s =>
            s === 'OFFLINE' ? GalleryStatus.OFFLINE : GalleryStatus.ONLINE,
          );
          params.status = filterStatuses;
        }

        handleOnChange(params);
      }}
    />
  );
};

export default GalleryArchivedTable;
