import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import {
  EditProductCategoryForm,
  EditProductCategoryFormProps,
  NewProductCategoryForm,
  NewProductCategoryFormProps,
} from 'Forms/ProductCategory';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

interface NewProductCategoryModalProps extends NewProductCategoryFormProps {
  name: 'new';
}
interface EditProductCategoryModalProps extends EditProductCategoryFormProps {
  name: 'edit';
}

const ProductCategoryModal = (props: NewProductCategoryModalProps | EditProductCategoryModalProps) => {
  const { t } = useContext(LocalizationContext);
  const renderForm = () => {
    if (props.name === 'edit') {
      return <EditProductCategoryForm onSubmit={props.onSubmit} defaultValues={props.defaultValues} />;
    }

    return <NewProductCategoryForm onSubmit={props.onSubmit} />;
  };

  return (
    <ModalContainer title={t(`app.productCategory.modal.${props.name}`)} icon={Images.tag}>
      {renderForm()}
    </ModalContainer>
  );
};

export default ProductCategoryModal;
