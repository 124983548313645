import { gql } from 'Operations/__generated__';

export const ADD_PHOTOS_TO_ORDER = gql(/* GraphQL */ `
  mutation AddPhotosToOrder($where: OrderableDigitalPhotosGetWhereType!) {
    addPhotosToOrder(where: $where) {
      available {
        _count
        edges {
          id
        }
      }
      ordered {
        _count
      }
    }
  }
`);
