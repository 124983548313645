export enum ATTACHMENT_TYPE {
  FILE = 'file',
  PDF = 'pdf',
  IMAGE = 'image',
}

export interface AttachmentInfo {
  type?: ATTACHMENT_TYPE;
  name?: string;
  url?: string;
}
