import { gql } from 'Operations/__generated__';

export const UPDATE_COMPANY = gql(/* GraphQL */ `
  mutation UpdateCompany($data: CompanyUpdateInputType!) {
    updateCompany(data: $data) {
      id
      name
      bookingUrl
      street
      zipcode
      city
      countryId
      vat
      iban
      primaryColor
      mobileMessageSenderId
      logoUrl
    }
  }
`);
