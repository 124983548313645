import { gql } from 'Operations/__generated__';

export const CREATE_FEATURE_COMMENT = gql(/* GraphQL */ `
  mutation CreateFeatureComment($where: IdInput!, $data: CreateFeatureCommentInput!) {
    createFeatureComment(where: $where, data: $data) {
      id
      comment
      userName
      createdAt
    }
  }
`);
