import { createStyles } from 'antd-style';

export const useTableStyle = createStyles(({ css, prefixCls, token, stylish }) => ({
  className: css`
    &.${prefixCls}-table-wrapper {
      .${prefixCls}-table {
        flex: 1;
        border-radius: ${token.borderRadius}px;
        ${stylish.shadow}

        table > thead > tr:first-child th {
          border-radius: 0;
        }

        .${prefixCls}-table-tbody > tr:last-child > td {
          border-bottom: 0;
        }

        &--bordered {
          .${prefixCls}-table {
            border: 1px solid ${token.colorFillTertiary};
            box-shadow: none;

            &-thead .${prefixCls}-table-cell {
              font-weight: bold;
            }
          }
        }
      }

      .${prefixCls}-table-row {
        &:nth-child(even) {
          background-color: ${token.colorFillTertiary};
        }
      }

      &--topAlignContent {
        .${prefixCls}-table-cell {
          vertical-align: top;
        }
      }
    }
  `,
}));
