import { useContext } from 'react';

import { Button, Card, List, Modal } from 'antd';

import Title from 'Components/Atoms/Title';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { getGroups, VariableGroupName } from './VariableGroups';

const VariableModalStyled = styled(Modal)`
  width: 800px;
`;

const VariableButton = styled(Button)`
  margin-bottom: ${Metrics.smallMargin}px;
`;

interface VariableModalProps {
  open: boolean;
  variableGroups: VariableGroupName[];
  onCancel: () => void;
  onSelect: (variableKey: string) => void;
  hasCrm?: boolean;
}

const VariableModal = ({ open, variableGroups, onCancel, onSelect, hasCrm = false }: VariableModalProps) => {
  const { t } = useContext(LocalizationContext);
  const groups = getGroups(variableGroups, hasCrm);

  return (
    <VariableModalStyled
      title={<Title level="h5">{t('app.emailTemplate.variableModalTitle')}</Title>}
      centered
      open={open}
      okType={undefined}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <List
        grid={{ gutter: 16, column: groups.length }}
        dataSource={groups}
        renderItem={group => (
          <List.Item key={group.name}>
            <Card
              title={
                <Title level="h6" align="center">
                  {t(`app.emailTemplate.variableGroups.${group.name}`)}
                </Title>
              }
            >
              {group.variables.map(variable => {
                return (
                  <VariableButton block type="dashed" onClick={() => onSelect(variable.value)} key={variable.key}>
                    {t(`app.emailTemplate.variables.${variable.key}`)}
                  </VariableButton>
                );
              })}
            </Card>
          </List.Item>
        )}
      />
    </VariableModalStyled>
  );
};

export default VariableModal;
