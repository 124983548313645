import { ApolloError } from '@apollo/client';

const getErrorMessage = (error: ApolloError) => {
  const graphQLErrors = error?.graphQLErrors;
  if (graphQLErrors?.length > 0) {
    return graphQLErrors[0].message;
  }
  return undefined;
};
export default getErrorMessage;
