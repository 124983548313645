import React, { useContext, useRef } from 'react';

import { useDroppable } from '@dnd-kit/core';
import { Button, Input, Popconfirm, Space, Tooltip } from 'antd';
import { DataNode } from 'antd/lib/tree';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';

import { Colors } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { GetFoldersQuery } from 'Operations/__generated__/graphql';

const StyledContainer = styled(Container)<{ isOver: boolean }>`
  transition: border 0.15s ease;
  border: 1px dashed ${({ isOver }) => (isOver ? Colors.secondaryMain : 'rgba(0, 0, 0, 0)')};
`;

export type CallbackHandler = (props: { where: { id: number }; data?: { name: string } }) => void;

interface Props extends DataNode {
  id: string;
  folder?: GetFoldersQuery['getFolders'][0];
  editingKey?: string;
  isFolderLoading: boolean;
  handleSubmitFolder: CallbackHandler;
  handleDeleteFolder: CallbackHandler;
  handleCloseInput: (props: { id: string }) => void;
  handleOpenInput: (props: { id: string }) => void;
}

const FolderItem = ({
  id,
  editingKey,
  folder,
  handleSubmitFolder,
  handleCloseInput,
  handleOpenInput,
  handleDeleteFolder,
  isFolderLoading,
  ...node
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const folderName = useRef('');
  const { setNodeRef, isOver } = useDroppable({
    id: `folder-${id}`,
    data: { id: parseInt(id) === -1 ? null : id },
  });

  const where = folder ? { id: folder.id } : { id: -1 };

  return (
    <div ref={setNodeRef}>
      <StyledContainer justify="center" align="space-between" block flex={1} isOver={isOver}>
        {editingKey === id ? (
          <Space.Compact>
            <Input
              disabled={isFolderLoading}
              onChange={e => (folderName.current = e.target.value)}
              defaultValue={folder?.name}
              onPressEnter={() => handleSubmitFolder({ where, data: { name: folderName.current } })}
              // Close input on escape
              onKeyDown={e => {
                if (e.keyCode === 27) {
                  handleCloseInput({ id });
                }
              }}
              autoFocus
              style={{ width: 'calc(100% - 32px)' }}
            />
            <Button
              icon={<Icon name="check" />}
              onClick={e => {
                e.stopPropagation();
                handleSubmitFolder({ where, data: { name: folderName.current } });
              }}
              disabled={isFolderLoading}
              loading={isFolderLoading}
            />
          </Space.Compact>
        ) : (
          <>
            <p title="">{node.title as string}</p>
            {id !== '-1' && !isOver && (
              <div className="tree-item-actions">
                {!folder?.parentId && (
                  <Tooltip title={t('app.common.addSubFolder')}>
                    <Icon
                      name="add"
                      onClick={e => {
                        e.stopPropagation();

                        handleOpenInput({ id: `${id}-add` });
                      }}
                    />
                  </Tooltip>
                )}
                <Tooltip title={t('app.common.edit')}>
                  <Icon
                    name="edit"
                    onClick={e => {
                      e.stopPropagation();
                      handleOpenInput({ id });
                      folderName.current = folder?.name || '';
                    }}
                  />
                </Tooltip>
                <Tooltip title={t('app.common.delete')}>
                  <Popconfirm
                    title={t(`app.gallery.photos.folderDeleteConfirm`)}
                    // @ts-ignore
                    onClick={e => e.stopPropagation()}
                    onConfirm={() => handleDeleteFolder({ where })}
                  >
                    <Icon name="delete" />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
          </>
        )}
      </StyledContainer>
    </div>
  );
};

export default FolderItem;
