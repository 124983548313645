import { gql } from 'Operations/__generated__';

export const UPDATE_OTHER_PAYMENT = gql(/* GraphQL */ `
  mutation UpdateOtherPayment($data: UserOtherPaymentInputType!) {
    updateOtherPayment(data: $data) {
      id
      otherPayment {
        content
      }
    }
  }
`);
