import React, { useContext } from 'react';

import { Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import Container from 'Components/Atoms/Container';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useGalleryModal } from 'Hooks/useGalleryModal';

import dayjs from 'Services/DayjsService';

import { GetGalleryPresetsQuery } from 'Operations/__generated__/graphql';

export interface handleOnChangeProps {
  page: number;
}

interface IdProps {
  id: number;
}

interface Props {
  total: number;
  loading: boolean;
  paginationSize?: number;
  data?: GetGalleryPresetsQuery['getGalleryPresets']['edges'];
  handleOnChange: (params: handleOnChangeProps) => void;
  handleDeleteConfirm?: (params: IdProps) => void;
}
const GalleryPresetTable = ({
  loading,
  total,
  data,
  paginationSize = 20,
  handleOnChange,
  handleDeleteConfirm,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);
  const { createGallery } = useGalleryModal();

  const columns: ColumnsType<GetGalleryPresetsQuery['getGalleryPresets']['edges'][0]> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 175,
      render: (_, record) => (
        <Container justify="space-between">
          <Link to={`/app/galleries/presets/${record.id}`}>
            <RoundButton icon="edit" tooltipTitle={t('app.common.edit')} />
          </Link>
          <RoundButton
            icon="galleries"
            tooltipTitle={t('app.common.usePreset')}
            onClick={() =>
              createGallery({
                galleryPresetId: record.id,
              })
            }
          />
          <Popconfirm
            title={t('app.confirm.delete')}
            onConfirm={() => {
              if (handleDeleteConfirm) {
                handleDeleteConfirm({ id: record.id });
              }
            }}
          >
            <RoundButton icon="delete" tooltipTitle={t('app.common.delete')} danger />
          </Popconfirm>
        </Container>
      ),
    },
    {
      title: t('app.common.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('app.common.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => dayjs(text).format('LLL'),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: true }}
      loading={loading}
      pagination={{
        total: total,
        pageSize: paginationSize,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()}`}
      onChange={pagination => {
        handleOnChange({
          page: pagination.current ? pagination.current : 1,
        });
      }}
    />
  );
};

export default GalleryPresetTable;
