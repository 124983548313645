import { gql } from 'Operations/__generated__';

export const CREATE_INVOICE_PAYMENT = gql(/* GraphQL */ `
  mutation CreateInvoicePayment($where: IdInput!, $data: CreatePaymentInput!) {
    createInvoicePayment(where: $where, data: $data) {
      id
      paid
    }
  }
`);
