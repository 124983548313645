import { gql } from 'Operations/__generated__';

export const GET_COMPANY_SETTINGS = gql(/* GraphQL */ `
  query GetCompanySettings {
    getCompany {
      id
      settings {
        defaultGalleryDownload
        defaultGalleryDownloadForGuest
        defaultGalleryWorkmode
        actionsOnGalleryPublish
        isShopPageEnabled
      }
    }
  }
`);
