import React, { useContext } from 'react';

import { Flex } from 'antd';
import { createStyles } from 'antd-style';

import clsx from 'clsx';

import { TinyColor } from '@ctrl/tinycolor';

import Icon from 'Components/Atoms/Icon';

import { EditGalleryThemeFormValues } from 'Forms/GalleryTheme';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useStyles } from './styles';

const useProductStyles = createStyles<EditGalleryThemeFormValues>(
  ({ css }, { backgroundColor, primaryColor, secondaryColor }) => ({
    imageContainer: css`
      background-color: #fff;
    `,
    productDetailsContainer: css`
      background-color: ${backgroundColor.toRgbString()};
      color: ${primaryColor.toRgbString()};
    `,
    productSubtitle: css`
      text-align: center;
      font-weight: bold;
      font-size: 20px;
    `,
    productPrice: css`
      text-align: center;
      font-weight: bold;
      font-size: 18px;
    `,
    productOptionLabel: css`
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 16px;
    `,
    productOptionItem: css`
      padding: 16px;
      border-radius: 999px;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);

      background-color: ${new TinyColor(primaryColor.toRgbString()).setAlpha(0.2).toRgbString()};
      border: 1px solid ${primaryColor.toRgbString()};

      color: ${primaryColor.toRgbString()};

      &.active {
        color: ${secondaryColor.toRgbString()};
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -2px rgba(0, 0, 0, 0.2);
        border: 1px solid ${primaryColor.toRgbString()};
        background-color: ${new TinyColor(primaryColor.toRgbString()).setAlpha(0.75).toRgbString()};
      }
    `,
    productCropButton: css`
      flex: 1;
    `,
    productPhotosContainer: css`
      margin: 32px auto 0;
      width: 100%;

      @media (min-width: 1920px) {
        width: 75%;
      }

      > div {
        border-bottom: 1px solid rgba(184, 184, 184, 0.5);
        padding-bottom: 16px;
      }
    `,
    productPhotoName: css`
      line-break: 1;
    `,
    addToCartButton: css`
      flex: 1;
    `,
  }),
);

const ProductPreview = (props: EditGalleryThemeFormValues) => {
  const { t } = useContext(LocalizationContext);
  const { styles } = useStyles(props);
  const { styles: productStyles } = useProductStyles(props);

  // TS check
  if (typeof styles === 'string' || typeof productStyles === 'string') {
    return null;
  }

  return (
    <Flex className={clsx(styles.container, styles.responsiveContainer)}>
      <Flex align="center" justify="center" className={clsx('ant-flex--padding', productStyles.imageContainer)}>
        <img src={Images.coverMissing} style={{ width: 200 }} />
      </Flex>
      <Flex flex={1} gap="large" vertical className={clsx(productStyles.productDetailsContainer, 'ant-flex--padding')}>
        <Flex vertical gap="middle">
          <h2 className={styles.title}>{t('galleryPreview.productModal.title')}</h2>
          <h3 className={productStyles.productSubtitle}>{t('galleryPreview.productModal.productName')}</h3>
          <p className={productStyles.productPrice}>39,99 €</p>
        </Flex>
        <p>{t('galleryPreview.productModal.productDescription')}</p>
        <Flex vertical>
          <p className={productStyles.productOptionLabel}>{t('galleryPreview.productModal.option1.title')}</p>
          <Flex gap="middle" align="center" justify="center">
            <p className={clsx(productStyles.productOptionItem, 'active')}>
              {t('galleryPreview.productModal.option1.value1')}
            </p>
            <p className={productStyles.productOptionItem}>{t('galleryPreview.productModal.option1.value2')}</p>
            <p className={productStyles.productOptionItem}>{t('galleryPreview.productModal.option1.value3')}</p>
            <p className={productStyles.productOptionItem}>{t('galleryPreview.productModal.option1.value5')}</p>
          </Flex>
        </Flex>
        <Flex vertical>
          <p className={productStyles.productOptionLabel}>{t('galleryPreview.productModal.option2.title')}</p>
          <Flex gap="middle" align="center" justify="center">
            <p className={productStyles.productOptionItem}>{t('galleryPreview.productModal.option2.value1')}</p>
            <p className={clsx(productStyles.productOptionItem, 'active')}>
              {t('galleryPreview.productModal.option2.value2')}
            </p>
          </Flex>
        </Flex>
        <Flex vertical gap="middle" className={productStyles.productPhotosContainer}>
          <Flex gap="small" align="center">
            <Flex flex={1} className={productStyles.productPhotoName}>
              _CP2802.jpg
            </Flex>
            <Flex flex={1.5} gap="small">
              <button disabled className={clsx(styles.button, 'icon')}>
                <Icon name="edit" />
              </button>
              <button disabled className={clsx(styles.button, productStyles.productCropButton)}>
                {t('galleryPreview.productModal.editCrop')}
              </button>
            </Flex>
          </Flex>
          <Flex gap="small" align="center">
            <Flex flex={1} className={productStyles.productPhotoName}>
              {t('app.common.photo', { count: 1 })} 2
            </Flex>
            <Flex flex={1.5} gap="small">
              <button disabled className={clsx(styles.button, productStyles.productCropButton)}>
                {t('galleryPreview.productModal.selectPhoto')}
              </button>
            </Flex>
          </Flex>
          <Flex gap="small" align="center">
            <Flex flex={1} className={productStyles.productPhotoName}>
              {t('app.common.photo', { count: 1 })} 3
            </Flex>
            <Flex flex={1.5} gap="small">
              <button disabled className={clsx(styles.button, productStyles.productCropButton)}>
                {t('galleryPreview.productModal.selectPhoto')}
              </button>
            </Flex>
          </Flex>
          <Flex gap="middle">
            <button disabled className={clsx(styles.button)}>
              {t('galleryPreview.productModal.selectPhoto')}
            </button>
            <button disabled className={clsx(styles.button, 'primary', productStyles.addToCartButton)}>
              {t('galleryPreview.productModal.addToCart')}
            </button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductPreview;
