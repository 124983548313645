import React from 'react';

import { Colors, FontSize, Metrics } from 'Themes';
import styled from 'Themes/Styled';

export interface SubSectionTitleProps {
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  align?: 'left' | 'center' | 'right';
  children: React.ReactNode;
  className?: string;
}

const StyledSubSectionTitle = styled.h1<SubSectionTitleProps>`
  padding-bottom: ${Metrics.tinyMargin}px;
  font-weight: 800;
  font-size: ${({ level = 'h5' }) => FontSize[level]}px;
  color: ${({ theme }) => theme.colors.text};
  text-align: ${({ align }) => align};
  border-bottom: 1px solid ${Colors.border};
`;

const SubSectionTitle = ({ level = 'h5', align = 'left', ...props }: SubSectionTitleProps) => {
  return <StyledSubSectionTitle as={level} level={level} align={align} {...props} />;
};

export default SubSectionTitle;
