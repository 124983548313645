import React, { useContext } from 'react';

import { Space } from 'antd';

import Title from 'Components/Atoms/Title';

import Breadcrumb, { BreadcrumbContent } from 'Components/Molecules/Breadcrumb';

import { Colors, Metrics } from 'Themes';
import styled, { ThemeContext } from 'Themes/Styled';

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  padding-bottom: ${Metrics.tinyMargin}px;
  border-bottom: 1px solid ${Colors.border};
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  .ant-btn {
    margin-left: ${Metrics.tinyMargin}px;
  }
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-top: ${Metrics.tinyMargin}px;
`;

interface Props {
  title: string | JSX.Element;
  extra?: JSX.Element | null;
  buttons?: JSX.Element[];
  breadcrumbContent?: BreadcrumbContent[];
}

const Header = ({ title, buttons, breadcrumbContent, extra = null }: Props) => {
  const theme = useContext(ThemeContext);

  return (
    <HeaderContainer>
      <LeftContainer>
        <Space>
          {extra}
          <Title level="h2" color={theme?.colors.text}>
            {title}
          </Title>
        </Space>
        {breadcrumbContent && <StyledBreadcrumb content={breadcrumbContent} />}
      </LeftContainer>
      {buttons && <RightContainer>{buttons}</RightContainer>}
    </HeaderContainer>
  );
};

export default Header;
