import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { InputNumber, InputProps, InputRef } from 'antd';

import { FieldHookConfig, useField } from 'formik';
import { debounce } from 'lodash';

const DebounceNumberInput = forwardRef<InputRef, InputProps & FieldHookConfig<number> & { debounceTime: number }>(
  props => {
    const [field, _meta, helpers] = useField(props);
    const { onChange, ...rest } = field;

    const [displayValue, setDisplayValue] = useState(rest.value);

    const debouncedSetValue = useRef(
      debounce(value => {
        onChange(rest.name);
        helpers.setValue(value);
      }, props.debounceTime),
    ).current;

    useEffect(() => {
      return () => {
        if (debouncedSetValue) {
          debouncedSetValue.cancel();
        }
      };
    }, [debouncedSetValue]);

    const handleOnChange = useCallback(
      (value: number | null) => {
        if (!value || value < (props.min as number)) {
          helpers.setValue(props.min as number);
          return;
        }

        setDisplayValue(value);
        debouncedSetValue.cancel();
        debouncedSetValue(value);
      },
      [debouncedSetValue, helpers, props.min],
    );

    return (
      <InputNumber
        type="number"
        name={rest.name}
        value={displayValue}
        min={props.min as number}
        max={props.max as number}
        size={props.size}
        onBlur={rest.onBlur}
        placeholder={props.placeholder}
        onChange={handleOnChange}
        disabled={props.disabled}
      />
    );
  },
);

export default DebounceNumberInput;
