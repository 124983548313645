import { gql } from 'Operations/__generated__';

export const CANCEL_ORDER = gql(/* GraphQL */ `
  mutation CancelOrder($where: OrderWhereType!) {
    cancelOrder(where: $where) {
      id
      ... on OrderAdmin {
        status
      }
    }
  }
`);
