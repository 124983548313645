import { gql } from 'Operations/__generated__';

export const UPDATE_GALLERY_APPEARANCE = gql(/* GraphQL */ `
  mutation UpdateGalleryAppearance($where: IdInput!, $data: GalleryAppearanceUpdateInputType!) {
    updateGalleryAppearance(where: $where, data: $data) {
      id
      spacing
      size
      coverUrl
      coverUrlLoader
    }
  }
`);
