import { gql } from 'Operations/__generated__';

export const GET_GALLERIES_ACCESS = gql(/* GraphQL */ `
  query GetAccessCodes($where: AccessCodesWhereType!, $paginate: PaginatedInput) {
    getAccessCodes(where: $where, paginate: $paginate) {
      edges {
        ...AccessCodeCoreFields
      }
      _count
      pageInfo {
        currentPage
        isFirstPage
        isLastPage
        pageCount
      }
    }
  }
`);
