import { createStyles } from 'antd-style';

export const useNotificationStyle = createStyles(({ css, prefixCls, token }) => ({
  className: css`
    &.${prefixCls}-notification {
      &--bulk-edit {
        &.${prefixCls}-notification-notice {
          background: ${token.batchNotificationBackground};
          width: auto;
          min-width: 500px;
          border-radius: ${token.borderRadius}px;
        }

        p {
          color: ${token.batchNotificationColor};
        }

        .ant-notification-notice-message {
          margin-bottom: 0;
        }
      }
    }
  `,
}));
