import { useMemo } from 'react';

import { TokenPayload } from 'types/Token';

import { decodeJwt } from 'jose';

import { AuthVar } from 'Operations/Cache';

export const useRoles = () => {
  const authVar = AuthVar();

  const roles = useMemo(() => {
    const { roles } = (authVar.accessToken
      ? decodeJwt(authVar.accessToken) || { roles: [] }
      : { roles: [] }) as unknown as TokenPayload;

    return {
      roles,
      isAdmin: roles.includes('admin') || false,
      isSupport: roles.includes('support') || false,
      isLabo: roles.includes('incognito') || false,
      isSales: roles.includes('sales') || false,
    };
  }, [authVar.accessToken]);

  return roles;
};
