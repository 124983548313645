import { gql } from 'Operations/__generated__';

export const GET_FOTOSTUDIO_PROVIDERS = gql(/* GraphQL */ `
  query GetFotostudioProviders {
    getFotostudioProviders {
      _count
      edges {
        ...ContactCoreFields
      }
    }
  }
`);
