import { gql } from 'Operations/__generated__';

export const GET_FEATURE_CATEGORIES = gql(/* GraphQL */ `
  query GetFeatureCategories {
    getFeaturesCategories {
      id
      name
      featureRequests {
        _count
      }
    }
  }
`);
