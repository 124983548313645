import { Colors, FontSize } from 'Themes';
import styled from 'Themes/Styled';

interface Props {
  weight?: 'normal' | 'bold' | 'lighter';
  align?: 'left' | 'center' | 'right';
  size?: number;
  color?: string;
  children: React.ReactNode;
}

const TextLink = styled.a<Props>`
  font-weight: ${({ weight = 'normal' }: Props) => weight};
  font-size: ${({ size = FontSize.medium }: Props) => size}px;
  text-align: ${({ align = 'left' }: Props) => align};
  color: ${({ color = '#000' }: Props) => color};
  text-decoration: underline;

  &:hover {
    color: ${Colors.primaryMain};
    text-decoration: underline;
  }

  &:active,
  &:focus {
    color: ${Colors.primaryOptional};
    text-decoration: underline;
  }
`;

export default TextLink;
