import React from 'react';

import { Tabs } from 'antd';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Layout from 'Components/Atoms/Layout';

import HeaderSkeleton from 'Components/Molecules/Skeletons/HeaderSkeleton';
import PhotosSkeleton from 'Components/Molecules/Skeletons/PhotosSkeleton';
import StatCardsSkeleton from 'Components/Molecules/Skeletons/StatCardsSkeleton';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

const { TabPane } = Tabs;

const GalleryContainer = styled(Container)`
  flex: 1;
`;

const RowContainer = styled(Container)`
  width: 100%;
  padding: ${Metrics.baseMargin}px 0;
`;

const StyledTabsContainer = styled(ContentContainer)`
  flex: 1;
  width: 100%;
  padding-top: 0;
`;

const StyledTabs = styled(Tabs)`
  flex: 1;
`;

const GalleriesShowSkeleton = () => (
  <>
    <HeaderSkeleton />
    <Layout>
      <GalleryContainer direction="column">
        <RowContainer>
          <StatCardsSkeleton />
        </RowContainer>
        <StyledTabsContainer shadow rounded padding="baseMargin">
          <StyledTabs defaultActiveKey="1">
            <TabPane tab="Photos" key="1">
              <Container justify="flex-start">
                <PhotosSkeleton />
              </Container>
            </TabPane>
          </StyledTabs>
        </StyledTabsContainer>
      </GalleryContainer>
    </Layout>
  </>
);

export default GalleriesShowSkeleton;
