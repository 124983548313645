import { createGlobalStyle, createStyles, useTheme } from 'antd-style';

export const useModalStyle = createStyles(({ css, prefixCls }) => ({
  className: css`
    &.${prefixCls}-modal {
      .${prefixCls}-modal-confirm-btns {
        display: flex;
        justify-content: flex-end;
      }

      .${prefixCls}-modal-footer {
        display: flex;
        justify-content: flex-end;
      }
    }
  `,
}));

export const usePopConfirmStyle = (): {
  GlobalPopconfirmClass: ReturnType<typeof createGlobalStyle>;
} => {
  const GlobalPopconfirmClass = createGlobalStyle`
    &.ant-popconfirm {
      .ant-popconfirm-buttons {
        display: flex;
        justify-content: space-between;
      }
    }
   `;

  return { GlobalPopconfirmClass };
};

export const useReactModalStyle = (): {
  GlobalModalClass: ReturnType<typeof createGlobalStyle>;
} => {
  const token = useTheme();

  const GlobalModalClass = createGlobalStyle`
    // React Modal
    .ReactModal {
      &__Overlay {
        z-index: 4;
        background-color: rgba(0, 0, 0, 0.65);
        opacity: 0;
        -ms-transition: opacity 300ms ease-in-out;
        transition: opacity 300ms ease-in-out;

        &--after-open {
          opacity: 1;

          .ReactModal__Content {
            -ms-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
          }
        }

        &--before-close {
          opacity: 0;

          .ReactModal__Content {
            -ms-transform: scale(0) translate(0, 800px);
            transform: scale(0) translate(0, 800px);
          }
        }
      }

      &__Content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        width: 876px;
        height: fit-content;
        max-height: calc(100vh - 160px);
        margin: auto;
        padding: 0;
        border: 0;
        border-radius: ${token.borderRadius}px;
        background-color: ${token.colorBgContainer};
        -ms-transform: scale(0) translate(0, 800px);
        transform: scale(0) translate(0, 800px);
        -ms-transition: transform 400ms ease-in-out;
        transition: transform 400ms ease-in-out;
      }
    }
  `;

  return { GlobalModalClass };
};
