import { gql } from 'Operations/__generated__';

export const UPDATE_PRODUCT_CATEGORY = gql(/* GraphQL */ `
  mutation UpdateProductCategory($where: IdInput!, $data: ProductCategoryCreateInput!) {
    updateProductCategory(where: $where, data: $data) {
      id
      name
    }
  }
`);
