export const FontWeight = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900,
};

export const FontSize = {
  h1: 32,
  h2: 24,
  h3: 20,
  h4: 18,
  h5: 16,
  h6: 14,
  title: 24,
  subtitle: 20,
  regular: 16,
  medium: 14,
  small: 12,
  tiny: 10,
};

export const FONTS = [
  'Caudex',
  'Cormorant Garamond',
  'Domine',
  'Fira Sans',
  'Georgia',
  'Josefin Slab',
  'Lato',
  'Montserrat',
  'Noto Serif Display',
  'Open Sans',
  'Pacifico',
  'Playfair Display',
  'Qwigley',
  'Roboto',
  'Tangerine',
];

export const BUTTON_FONTS = [
  'Caudex',
  'Cormorant Garamond',
  'Domine',
  'Fira Sans',
  'Georgia',
  'Josefin Slab',
  'Lato',
  'Montserrat',
  'Noto Serif Display',
  'Open Sans',
  'Playfair Display',
  'Roboto',
];
