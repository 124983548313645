import React, { useMemo } from 'react';

import { Dropdown, MenuProps } from 'antd';

import OrderStatusTag from 'Components/Molecules/OrderStatusTag';

import styled from 'Themes/Styled';

import { OrderItemStatus } from 'Operations/__generated__/graphql';

interface Props {
  availableItemStatuses: OrderItemStatus[];
  onChange?: (params: { status: OrderItemStatus }) => void;
  isEmptyState?: boolean;
  status?: OrderItemStatus;
  width?: string;
  placement?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight' | undefined;
}

const OrderStatusTagInList = styled(OrderStatusTag)`
  width: 100%;
  text-align: center;
`;

const OrderStatusDropdown = ({ availableItemStatuses, onChange, status, isEmptyState, placement, width }: Props) => {
  const statuses = useMemo<(OrderItemStatus | '-')[]>(
    () => (isEmptyState ? ['-', ...availableItemStatuses] : availableItemStatuses),
    [availableItemStatuses, isEmptyState],
  );

  const items = useMemo<MenuProps['items']>(
    () =>
      statuses.map(itemStatus => ({
        key: itemStatus,
        label: <OrderStatusTagInList itemStatus={itemStatus} />,
        style: {
          display: 'flex',
          justifyContent: 'center',
        },
      })),
    [statuses],
  );

  return (
    <Dropdown
      placement={placement}
      menu={{
        items,
        onClick(e) {
          if (e.key !== '-') {
            onChange?.({ status: e.key as OrderItemStatus });
          }
        },
      }}
      trigger={['click']}
    >
      <OrderStatusTag
        itemStatus={status || '-'}
        chevron
        direction={placement?.includes('top') ? 'up' : 'down'}
        width={width}
      />
    </Dropdown>
  );
};

export default OrderStatusDropdown;
