import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Flex, Spin } from 'antd';
import { ATTACHMENT_TYPE, AttachmentInfo } from 'types/Attachment';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';
import { chain, groupBy, uniqBy } from 'lodash';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import EmailEditor from 'Components/Molecules/EmailEditor';
import FileSelect from 'Components/Molecules/Form/FileSelect';
import Select from 'Components/Molecules/Form/Select';
import SelectContact from 'Components/Molecules/Inputs/SelectContact';

import { Colors, FontSize, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import i18n, { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

import { toAttachmentType } from 'Helpers/AttachmentType';

import {
  EmailTemplateAssociatedModel,
  EmailTemplateOrderType,
  EmailTemplateType,
  GalleryAdmin,
  GetGalleryContactsQuery,
  OrderType,
} from 'Operations/__generated__/graphql';

import { GET_CONTACT } from 'Operations/Queries/Contact/GetContact';
import { GET_CONTACTS } from 'Operations/Queries/Contact/GetContacts';
import { GET_EMAIL_TEMPLATE_CONTENT } from 'Operations/Queries/EmailTemplate/GetEmailTemplateContent';
import { GET_EMAIL_TEMPLATES } from 'Operations/Queries/EmailTemplate/GetEmailTemplates';
import { GET_GALLERY_CONTACTS } from 'Operations/Queries/Gallery/GetGalleryContacts';

export interface SendEmailFormValues {
  contactId?: number;
  secondaryContactIds?: number[] | null;
  templateAssociatedModel?: EmailTemplateAssociatedModel;
  templateId?: number;
  title: string;
  content: string;
  attachment?: Blob | null;
  attachmentName?: string | null;
  attachmentUrl?: string | null;
}

const PER_PAGE = 20;
const CONTACT_PARAMS = {
  hasEmail: true,
  perPage: PER_PAGE,
  order: OrderType.ASC,
};

const AttachmentPreview = styled.img`
  width: 100px;
  height: auto;
  margin-right: ${Metrics.baseMargin}px;
`;

const TextStyled = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${Metrics.tinyMargin}px;

  a {
    display: flex;
    align-items: center;
    color: ${Colors.secondaryMain};
  }
`;

interface Props {
  galleryId?: number;
  contactId?: number;
}

const EmailTemplateForm = ({
  isSubmitting,
  values,
  setFieldValue,
  setValues,
  galleryId,
  contactId,
}: FormikProps<SendEmailFormValues> & Props) => {
  const { t } = useContext(LocalizationContext);
  const contactsPage = useRef(1);
  const contactsSearchTerms = useRef<string | undefined>(undefined);
  const templatesPage = useRef(1);

  const [attachmentInfo, setAttachmentInfo] = useState<AttachmentInfo>({
    type: toAttachmentType(values.attachmentName),
    name: values.attachmentName ?? undefined,
    url: values.attachmentUrl ?? undefined,
  });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const selectedFile = e.target.files[0];
    setAttachmentInfo({
      type: toAttachmentType(selectedFile.type),
      name: undefined, // Prevents display of file name, which is already done by the FileSelect component
      url: URL.createObjectURL(e.target.files[0]),
    });
  };

  const [currentTemplateAssociatedModel, setCurrentTemplateAssociatedModel] = useState<EmailTemplateAssociatedModel>(
    EmailTemplateAssociatedModel.GALLERY,
  );

  // Fetch selected gallery contacts to show them even if not in the current pagination
  const { data: galleryContactsData } = useQuery(GET_GALLERY_CONTACTS, {
    fetchPolicy: 'cache-first',
    skip: !galleryId,
    variables: {
      where: {
        id: galleryId,
      },
    },
  });

  // Fetch given contact to show him even if not in the current pagination
  const { data: contactData } = useQuery(GET_CONTACT, {
    fetchPolicy: 'cache-first',
    skip: !contactId,
    variables: {
      where: {
        id: contactId as number,
      },
    },
  });

  // Fetch a least one gallery model to set as default selected template
  const { data: defaultEmailTemplatesData } = useQuery(GET_EMAIL_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    skip: !values.templateAssociatedModel,
    variables: {
      where: {
        associatedModel: [values.templateAssociatedModel || EmailTemplateAssociatedModel.GALLERY],
        perPage: 1,
        page: 1,
        order: EmailTemplateOrderType.ASC,
      },
    },
    onCompleted(data) {
      if (data.getEmailTemplates) {
        const template = data.getEmailTemplates.edges.find(({ type }) => type === EmailTemplateType.FOTOSTUDIO);
        if (template) {
          setFieldValue('templateId', template.id);
          handleChangeTemplate(template.id);
        }
      }
    },
  });

  const {
    data: emailTemplatesData,
    loading: isEmailTemplatesLoading,
    fetchMore: fetchMoreTemplates,
  } = useQuery(GET_EMAIL_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        associatedModel: [
          EmailTemplateAssociatedModel.CUSTOM,
          EmailTemplateAssociatedModel.GALLERY,
          EmailTemplateAssociatedModel.GALLERYORDERAVAILABLE,
          EmailTemplateAssociatedModel.GALLERYINVOICE,
          EmailTemplateAssociatedModel.GALLERYACCESSCODE,
        ],
        perPage: PER_PAGE,
        page: templatesPage.current,
        order: EmailTemplateOrderType.ASC,
      },
    },
  });

  const [getEmailTemplateContent, { data: currentTemplateData, loading: isCurrentTemplateLoading }] =
    useLazyQuery(GET_EMAIL_TEMPLATE_CONTENT);

  const galleryContacts = useMemo(
    () => (galleryContactsData?.getGallery?.__typename === 'GalleryAdmin' ? galleryContactsData.getGallery : undefined),
    [galleryContactsData],
  );

  // Merge the paginated contacts, the gallery contacts and the single contact
  const contacts = useMemo(() => {
    const mergedContacts = [];

    if (galleryContacts) {
      if (galleryContacts.contact) {
        mergedContacts.push(galleryContacts.contact);
      }
      if (galleryContacts.secondaryContacts?.length) {
        mergedContacts.push(...galleryContacts.secondaryContacts);
      }
    }

    if (contactData?.getContact) {
      mergedContacts.push(contactData.getContact);
    }

    return mergedContacts;
  }, [contactData?.getContact, galleryContacts]);

  const allTemplates = useMemo(
    () =>
      uniqBy(
        [
          ...(emailTemplatesData?.getEmailTemplates?.edges || []),
          ...(defaultEmailTemplatesData?.getEmailTemplates?.edges || []),
        ],
        'id',
      ),
    [defaultEmailTemplatesData?.getEmailTemplates?.edges, emailTemplatesData?.getEmailTemplates?.edges],
  );

  const emailTemplates = useMemo(() => groupBy(allTemplates, ({ type }) => type), [allTemplates]);

  const emailTemplatesCount = useMemo(
    () => emailTemplatesData?.getEmailTemplates?._count || 0,
    [emailTemplatesData?.getEmailTemplates?._count],
  );

  const handleLoadMoreTemplates = useCallback(() => {
    templatesPage.current += 1;
    fetchMoreTemplates({
      variables: {
        where: {
          associatedModel: [
            EmailTemplateAssociatedModel.CUSTOM,
            EmailTemplateAssociatedModel.GALLERY,
            EmailTemplateAssociatedModel.GALLERYORDERAVAILABLE,
            EmailTemplateAssociatedModel.GALLERYINVOICE,
            EmailTemplateAssociatedModel.GALLERYACCESSCODE,
          ],
          perPage: PER_PAGE,
          page: templatesPage.current,
          order: OrderType.ASC,
        },
      },
    });
  }, [fetchMoreTemplates]);

  const handleChangeTemplate = useCallback(
    async (templateId?: number) => {
      if (templateId === undefined || templateId === -1) {
        setValues({
          ...values,
          title: '',
          content: '',
          attachment: undefined,
          attachmentName: undefined,
          attachmentUrl: undefined,
          templateId: undefined,
        });

        setAttachmentInfo({
          type: undefined,
          name: undefined,
          url: undefined,
        });
      } else if (templateId && values.templateId !== templateId) {
        getEmailTemplateContent({
          variables: {
            where: {
              id: templateId,
            },
          },
        });
      }
    },
    [getEmailTemplateContent, setValues, values],
  );

  useEffect(() => {
    if (currentTemplateData && currentTemplateData?.getEmailTemplate?.id === values.templateId) {
      setValues(currentValues => ({
        ...currentValues,
        title: currentTemplateData.getEmailTemplate.title,
        content: currentTemplateData.getEmailTemplate.content,
        attachment: undefined,
        attachmentName: currentTemplateData.getEmailTemplate.attachmentName,
        attachmentUrl: currentTemplateData.getEmailTemplate.attachmentUrl,
        templateAssociatedModel: currentTemplateData.getEmailTemplate.associatedModel,
      }));

      setAttachmentInfo({
        type: toAttachmentType(currentTemplateData.getEmailTemplate.attachmentName),
        name: currentTemplateData.getEmailTemplate.attachmentName ?? undefined,
        url: currentTemplateData.getEmailTemplate.attachmentUrl ?? undefined,
      });

      setCurrentTemplateAssociatedModel(currentTemplateData.getEmailTemplate.associatedModel);
    }
  }, [currentTemplateData, setValues, values.templateId]);

  if (isEmailTemplatesLoading) {
    return (
      <Flex justify="center">
        <Spin />
      </Flex>
    );
  }

  return (
    <Form layout="vertical">
      <SelectContact
        label={t('app.common.contact')}
        name="contactId"
        loadedContacts={contacts}
        excludedContacts={values.secondaryContactIds || []}
        value={values?.contactId ? [values.contactId] : []}
      />
      <SelectContact
        label={t('app.common.secondaryContacts')}
        name="secondaryContacts"
        loadedContacts={contacts}
        excludedContacts={values?.contactId ? [values.contactId] : []}
        value={values?.secondaryContactIds || []}
        multiple
      />

      <Form.Item label={t('app.common.emailTemplates', { count: 1 })} name="templateId" required hasFeedback={false}>
        <Select
          name="templateId"
          size="large"
          showSearch
          optionFilterProp="children"
          onChange={handleChangeTemplate}
          allowClear
          loadMore={handleLoadMoreTemplates}
          hasFetchAll={emailTemplatesCount <= allTemplates.length}
          loading={!defaultEmailTemplatesData?.getEmailTemplates && isEmailTemplatesLoading}
          getPopupContainer={trigger => trigger.parentNode}
        >
          <Select.Option key="unset" value={-1} title={''}>
            -
          </Select.Option>
          {emailTemplates &&
            Object.keys(emailTemplates).map(type => (
              <Select.OptGroup
                key={type}
                label={type === 'yours' ? t('app.emailTemplate.yours').toLocaleLowerCase() : type}
              >
                {emailTemplates[type].map(template => (
                  <Select.Option key={template.id} value={template.id} title={''}>
                    {template.name}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label={t('app.common.title')} name="title" required hasFeedback={false}>
        <Input name="title" placeholder={t('app.common.title')} size="large" disabled={isCurrentTemplateLoading} />
      </Form.Item>
      <Form.Item label={t('app.common.content')} name="content" required hasFeedback={false}>
        <EmailEditor
          model={values.content}
          onModelChange={(content: string) => setFieldValue('content', content)}
          associatedModel={currentTemplateAssociatedModel}
        />
        <Input type="hidden" name="content" />
      </Form.Item>
      <Form.Item label={t('app.common.attachment')} name="attachment" hasFeedback={false}>
        <div>
          {attachmentInfo.type === ATTACHMENT_TYPE.IMAGE && <AttachmentPreview src={attachmentInfo.url} />}
          {attachmentInfo.type === ATTACHMENT_TYPE.PDF && <Icon name="pdf" size={FontSize.h1 * 2} />}
          {attachmentInfo.type === ATTACHMENT_TYPE.FILE && <Icon name="file" size={FontSize.h1 * 2} />}
          {attachmentInfo.name && attachmentInfo.url && (
            <TextStyled size="small">
              <a href={attachmentInfo.url} target="_blank">
                <Icon name="invoice" />
                {attachmentInfo.name}
              </a>
            </TextStyled>
          )}
        </div>
        <FileSelect
          name="attachment"
          accept="image/jpeg, image/png, image/gif,
          application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/x-ole-storage,
          application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/vnd.ms-office, application/vnd.openxmlformats-officedocument.presentationml.presentation,
          application/rtf, application/x-rtf, text/rtf, text/richtext, application/zip,
          application/pdf"
          handleOnChange={handleFileChange}
          disabled={!!(values.templateId && currentTemplateData?.getEmailTemplate?.attachmentUrl)}
        />
      </Form.Item>

      <Flex justify="flex-end" align="center">
        <Button htmlType="submit" size="large" loading={isSubmitting}>
          {t('app.common.send')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface SendEmailPayload {
  values: SendEmailFormValues;
  formikBag: FormikBag<SendEmailFormProps, SendEmailFormValues>;
}

export interface SendEmailFormProps extends Props {
  onSubmit: (payload: SendEmailPayload) => void;
  defaultValues: SendEmailFormValues;
}

export const SendEmailForm = withFormik<SendEmailFormProps, SendEmailFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({
      values: { ...values, templateId: values.templateId === -1 ? undefined : values.templateId },
      formikBag,
    });
  },
  mapPropsToValues: ({ defaultValues }) => ({
    ...defaultValues,
    title: '',
    content: '',
    attachment: undefined,
    attachmentName: undefined,
    attachmentUrl: undefined,
  }),
  validationSchema: yup.object().shape({
    contactId: yup.number().required(),
    title: yup.string().trim().required(),
    content: yup.string().trim().required(),
    attachment: yup
      .mixed()
      .nullable()
      .test(
        'attachment',
        () => i18n.t('app.form.errors.fileToLarge', { limit: '5MB' }),
        value => {
          return value ? value.size <= 5000000 : true;
        },
      ),
  }),
})(EmailTemplateForm);
