import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import { App, Button, Spin, Tree } from 'antd';
import { DataNode, EventDataNode } from 'antd/lib/tree';

import { NodeDragEventParams } from 'rc-tree/lib/contextTypes';
import { Key } from 'rc-tree/lib/interface';

import FolderItem, { CallbackHandler } from 'Components/Molecules/FolderItem';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { usePhotosCount } from 'Hooks/usePhotosCount';

import getErrorMessage from 'Helpers/GetErrorMessage';

import { GetFoldersQuery, PhotoCharacteristic } from 'Operations/__generated__/graphql';

import { GET_FOLDERS } from 'Operations/Queries/Folder/GetFolders';
import { GET_GALLERY } from 'Operations/Queries/Gallery/GetGallery';

import { CREATE_FOLDER } from 'Operations/Mutations/Folders/CreateFolder';
import { DELETE_FOLDER } from 'Operations/Mutations/Folders/DeleteFolder';
import { MOVE_FOLDER } from 'Operations/Mutations/Folders/MoveFolder';
import { UPDATE_FOLDER } from 'Operations/Mutations/Folders/UpdateFolder';

const { DirectoryTree } = Tree;

type Props = {
  galleryId: number;
  folders: GetFoldersQuery['getFolders'];
  characteristics: PhotoCharacteristic[];
  isLoading: boolean;
  rootCount?: number;
  defaultFolder: number | null;
  onChange: (id: number | null) => void;
};

const StyledSidebar = styled.div`
  width: 200px;
  height: 100%;
  display: block;

  border-right: 1px solid ${Colors.border};
`;

const StyledActionContainer = styled.div`
  margin-right: ${Metrics.smallMargin}px;

  & > button {
    margin-top: ${Metrics.baseMargin}px;
  }
`;

const getChildIds = (folder: DataNode) => {
  const ids: number[] = [];
  ids.push(parseInt(folder.key as string));
  if (folder.children) {
    folder.children.forEach(child => {
      ids.push(...getChildIds(child));
    });
  }
  return ids;
};

const findFolderNode = ({ folders, id }: { folders: DataNode[]; id: number }) => {
  let folder: DataNode | undefined;

  for (const node of folders) {
    if (parseInt(node.key as string) === id) {
      folder = node;
      break;
    }
    if (node.children?.length) {
      folder = findFolderNode({ folders: node.children, id });
    }
  }

  return folder;
};

const GalleryFolders = ({
  galleryId,
  folders,
  rootCount = 0,
  isLoading,
  onChange,
  defaultFolder,
  characteristics,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const [editingKey, setEditingKey] = useState<string>();
  const [currentFolder, setCurrentFolder] = useState<number | null>(-1);

  const { updatePhotosCount: updateRootPhotosCount } = usePhotosCount({
    galleryId,
    folderId: null,
    characteristics,
    fetchPolicy: 'cache-only',
  });

  const [createFolder, { loading: isCreatingFolder }] = useMutation(CREATE_FOLDER);
  const [deleteFolder, { loading: isDeletingFolder }] = useMutation(DELETE_FOLDER);
  const [moveFolder] = useMutation(MOVE_FOLDER);
  const [updateFolder, { loading: isUpdatingFolder }] = useMutation(UPDATE_FOLDER);

  useEffect(() => {
    if (currentFolder !== null && currentFolder === -1) {
      setCurrentFolder(defaultFolder);
    }
  }, [defaultFolder, currentFolder]);

  const treeData: DataNode[] = useMemo(() => {
    // Get the tree directory recursively
    const getTreeDirectory = (
      folders: GetFoldersQuery['getFolders'],
      parentId: number | null,
      treeDirectory: DataNode[] = [],
    ) => {
      const children = folders.filter(folder => folder.parentId === parentId);
      if (children.length > 0) {
        children.forEach(child => {
          treeDirectory.push({
            title: `${child.name} (${child.photos._count})`,
            key: child.id.toString(),
            children: getTreeDirectory(folders, child.id),
          });
          if (editingKey === child.id + '-add') {
            treeDirectory.push({
              title: '',
              key: `${child.id}-add`,
              disabled: true,
            });
          }
        });
      }
      return treeDirectory;
    };

    const tree = getTreeDirectory(folders || [], null);
    if (editingKey === 'root-add') {
      tree.push({
        title: '',
        key: 'root-add',
      });
    }
    tree.unshift({
      title: `${t('app.gallery.photos.withoutFolder')} (${rootCount})`,
      key: '-1',
    });

    return tree;
  }, [t, folders, editingKey, rootCount]);

  const handleCloseInput = useCallback(() => {
    setEditingKey(undefined);
  }, []);

  const handleOpenInput = useCallback(({ id }: { id: string }) => {
    setEditingKey(id);
  }, []);

  const handleChange = useCallback(
    (id: number | null) => {
      if (id !== currentFolder) {
        onChange(id);
        setCurrentFolder(id);
      }
    },
    [currentFolder, onChange],
  );

  const handleSubmitFolder: CallbackHandler = useCallback(
    async ({ where, data }) => {
      try {
        if (!data?.name.length) {
          throw new Error('FOLDER_NAME_EMPTY');
        }
        if (!editingKey) {
          return;
        }

        let id: number | undefined;
        if (editingKey?.includes('-add')) {
          const folderIdStr = editingKey.substring(0, editingKey.indexOf('-add'));
          const res = await createFolder({
            variables: {
              where: { galleryId },
              data: {
                name: data.name,
                parentId: folderIdStr !== 'root' ? parseInt(folderIdStr) : null,
              },
            },
            update(cache, { data }) {
              if (!data) {
                return;
              }
              const existingFolders = cache.readQuery({
                query: GET_FOLDERS,
                variables: {
                  galleryId,
                },
              });
              cache.writeQuery({
                query: GET_FOLDERS,
                variables: {
                  galleryId,
                },
                data: {
                  getFolders: [
                    ...(existingFolders?.getFolders || []),
                    {
                      ...data.createFolder,
                      photos: {
                        _count: 0,
                        __typename: 'PhotoConnection',
                      },
                    },
                  ],
                },
              });
              const galleryData = cache.readQuery({
                query: GET_GALLERY,
                variables: {
                  where: {
                    id: galleryId,
                  },
                },
              });
              if (galleryData?.getGallery && 'id' in galleryData?.getGallery && galleryData.getGallery._count) {
                cache.writeQuery({
                  query: GET_GALLERY,
                  variables: {
                    where: {
                      id: galleryId,
                    },
                  },
                  data: {
                    getGallery: {
                      ...galleryData.getGallery,
                      _count: {
                        ...galleryData.getGallery._count,
                        folders: galleryData.getGallery._count.folders + 1,
                      },
                    },
                  },
                });
              }
            },
          });

          id = res.data?.createFolder.id;
          if (id) {
            handleChange(id);
          }
        } else {
          await updateFolder({
            variables: {
              where,
              data,
            },
          });
        }

        handleCloseInput();
      } catch (error) {
        const errorMessage = getErrorMessage(error as ApolloError);
        switch (errorMessage) {
          case 'FOLDER_ALREADY_EXIST':
            message.error(t(`app.gallery.photos.folderAlreadyExist`));
            break;
          case 'MAX_FOLDER_DEPTH_EXCEEDED':
            message.error(t(`app.gallery.photos.maxFolderDepthReached`));
            break;

          default:
            break;
        }
      }
    },
    [createFolder, editingKey, galleryId, handleChange, handleCloseInput, t, updateFolder],
  );

  const handleDeleteFolder: CallbackHandler = useCallback(
    async ({ where }) => {
      try {
        await deleteFolder({
          variables: {
            where,
          },
          update: (cache, { data }) => {
            if (data?.deleteFolder) {
              const folder = findFolderNode({ folders: treeData, id: data.deleteFolder.id });

              if (folder) {
                const ids = getChildIds(folder);
                let photosCount = 0;

                ids.forEach(id => {
                  const normalizedId = cache.identify({
                    id,
                    __typename: data.deleteFolder.__typename,
                  });
                  const subFolder = folders.find(folder => folder.id === id);
                  if (subFolder) {
                    photosCount += subFolder?.photos?._count || 0;
                  }
                  cache.evict({ id: normalizedId });
                });

                updateRootPhotosCount(photosCount);
              }

              const galleryData = cache.readQuery({
                query: GET_GALLERY,
                variables: {
                  where: {
                    id: galleryId,
                  },
                },
              });
              if (galleryData?.getGallery && 'id' in galleryData?.getGallery && galleryData.getGallery._count) {
                cache.writeQuery({
                  query: GET_GALLERY,
                  variables: {
                    where: {
                      id: galleryId,
                    },
                  },
                  data: {
                    getGallery: {
                      ...galleryData.getGallery,
                      _count: {
                        ...galleryData.getGallery._count,
                        folders: Math.max(0, galleryData.getGallery._count.folders - 1),
                      },
                    },
                  },
                });
              }

              cache.gc();
            }
          },
        });

        onChange(null);
        setCurrentFolder(null);
        handleCloseInput();
      } catch (error) {
        message.error(getErrorMessage(error as ApolloError));
      }
    },
    [deleteFolder, folders, galleryId, handleCloseInput, onChange, treeData, updateRootPhotosCount],
  );

  const onDrop: (
    info: NodeDragEventParams<DataNode, HTMLDivElement> & {
      dragNode: EventDataNode<DataNode>;
      dragNodesKeys: Key[];
      dropPosition: number;
      dropToGap: boolean;
    },
  ) => void = async info => {
    const dragFolderId = parseInt(info.dragNode.key as string);
    // Do nothing for the root folder
    if (dragFolderId === -1) {
      return;
    }

    // Do nothing if dragged before the root folder
    if (info.dropPosition <= 0) {
      return;
    }

    const dragFolderParentId = folders.find(folder => folder.id === dragFolderId)?.parentId || null;
    const dropFolderId = (info.node.key as string) === '-1' ? null : parseInt(info.node.key as string);
    const dropFolderParentId = folders.find(folder => folder.id === dropFolderId)?.parentId;
    const isInDropFolder = !info.dropToGap;

    const dropId = isInDropFolder ? dropFolderId : dropFolderParentId || null;

    let dragNodePosition = parseInt(info.dragNode.pos.split('-').slice(-1)[0], 10);
    let position = isInDropFolder ? 0 : info.dropPosition;

    // If not drop into root, add 1 to position to not count as array index
    if (dropId) {
      dragNodePosition = dragNodePosition + 1;
      position = position + 1;
    }

    // If drop position is higher than the drag node position, subtract 1 to not count the current dragging object position
    if (dropId === dragFolderParentId && dragNodePosition < position) {
      position = position - 1;
    }

    try {
      await moveFolder({
        variables: {
          where: {
            id: dragFolderId,
          },
          data: {
            parentId: dropId,
            position,
          },
        },
        refetchQueries: [
          {
            query: GET_FOLDERS,
            variables: {
              galleryId,
            },
          },
        ],
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error as ApolloError);
      switch (errorMessage) {
        case 'FOLDER_ALREADY_EXIST':
          message.error(t(`app.gallery.photos.folderAlreadyExist`));
          break;
        case 'MAX_FOLDER_DEPTH_EXCEEDED':
          message.error(t(`app.gallery.photos.maxFolderDepthReached`));
          break;

        default:
          break;
      }
    }
  };

  const isFolderLoading = isCreatingFolder || isUpdatingFolder || isDeletingFolder;

  const selectedFolder = folders.find(f => f.id === currentFolder);
  const currentParentFolder =
    currentFolder && selectedFolder && selectedFolder.parentId ? selectedFolder.parentId.toString() : '-1';

  return (
    <StyledSidebar>
      {isLoading && treeData.length === 0 ? (
        <div>
          <Spin />
        </div>
      ) : (
        <>
          <DirectoryTree
            selectedKeys={[currentFolder ? currentFolder.toString() : '-1']}
            treeData={treeData}
            showIcon={false}
            showLine
            draggable
            autoExpandParent
            defaultExpandAll
            className="ant-tree--hide-drag-icon"
            onSelect={selectedKeys => handleChange(selectedKeys[0] == -1 ? null : parseInt(selectedKeys[0] as string))}
            onDrop={onDrop}
            titleRender={node => (
              <FolderItem
                {...node}
                id={node.key + ''}
                editingKey={editingKey}
                isFolderLoading={isFolderLoading}
                folder={folders.find(folder => folder.id == node.key)}
                handleSubmitFolder={handleSubmitFolder}
                handleDeleteFolder={handleDeleteFolder}
                handleOpenInput={handleOpenInput}
                handleCloseInput={handleCloseInput}
              />
            )}
          />
          <StyledActionContainer>
            {editingKey?.includes('-add') ? (
              <Button block onClick={handleCloseInput}>
                {t('app.common.cancel')}
              </Button>
            ) : (
              <Button block onClick={() => setEditingKey('root-add')}>
                {t('app.common.addFolder')}
              </Button>
            )}
          </StyledActionContainer>
        </>
      )}
    </StyledSidebar>
  );
};

export default GalleryFolders;
