import { Link } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon, { IconName } from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import dayjs from 'Services/DayjsService';

interface Props {
  icon: {
    name: IconName;
    size?: number;
  };
  content: string;
  date: string;
  isRead: boolean;
  onCloseDrawer?: () => void;
}

const StyledContainer = styled(ContentContainer)<{ isRead: boolean }>`
  margin: ${Metrics.smallMargin}px ${Metrics.baseMargin}px;

  opacity: ${({ isRead }) => (!isRead ? 1 : 0.5)};
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const StyledContent = styled.div`
  flex: 1;
  padding-left: ${Metrics.smallMargin}px;
`;

const StyledIconContainer = styled.div`
  width: ${Metrics.icons.medium}px;
  height: ${Metrics.icons.medium}px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${Colors.primaryOptional};
  background: -moz-linear-gradient(90deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
  background: -webkit-linear-gradient(90deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
  background: linear-gradient(90deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${Colors.primaryMain}",endColorstr="${Colors.primaryOptional}",GradientType=1);

  border-radius: ${Metrics.icons.medium}px;
`;

const NotificationCard = ({ icon, content, date, isRead, onCloseDrawer = () => null }: Props) => {
  return (
    <StyledContainer shadow rounded isRead={isRead} padding="smallMargin">
      <Container>
        <StyledIconContainer>
          <Icon name={icon.name} size={icon.size || Metrics.icons.small} color={Colors.white} />
        </StyledIconContainer>
        <StyledContent>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={content}
            components={{
              p: ({ children }) => <Text size="medium">{children}</Text>,
              b: ({ children }) => (
                <Text size="medium" weight="bold">
                  {children}
                </Text>
              ),
              a: ({ children, ...props }) =>
                props.href !== '#' ? (
                  <Link to={props.href || '.'} onClick={() => onCloseDrawer()}>
                    {children}
                  </Link>
                ) : (
                  <Text style={{ display: 'inline' }} size="medium" weight="bold">
                    {children}
                  </Text>
                ),
            }}
          />
          <Text size="medium" align="right" color={Colors.disabled}>
            {dayjs(date).fromNow()}
          </Text>
        </StyledContent>
      </Container>
    </StyledContainer>
  );
};

export default NotificationCard;
