import { useContext, useMemo, useRef, useState } from 'react';

import { Input, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IdProps } from 'types/Common';

import { debounce } from 'lodash';

import Container from 'Components/Atoms/Container';
import Tag from 'Components/Atoms/Tag';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors, Metrics } from 'Themes';
import styled, { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { GalleryStatus } from 'Operations/__generated__/graphql';

export interface HandleOnChangeProps {
  page: number;
  perPage: number;
  order: 'asc' | 'desc';
  status?: GalleryStatus[];
  search?: string;
}
export interface TransportRangeData {
  id: number;
  weight: number;
  price: number;
  minPrice: number;
}

export interface TransportFeeTableData {
  id: number;
  name: string;
  hasPresetZone: boolean;
  countries: {
    id: number;
    name: string;
  }[];
  weightRanges: TransportRangeData[];
}

export interface DeliveryWeightRange {
  deliveryWeightRangeId: number;
  zoneId: number;
  price: number;
}

export interface HandleEditCountriesProps {
  id: number;
  name: string;
  countryIds: number[];
}

interface Props {
  isActive: boolean;
  loading: boolean;
  currency: string;
  vat: number;
  data?: TransportFeeTableData[];
  handleOnChange: (params: DeliveryWeightRange) => void;
  handleEditCountries: (params: HandleEditCountriesProps) => void;
  handleDeleteConfirm: (params: IdProps) => void;
}

const CountriesContainer = styled(Container)`
  flex-wrap: wrap;
  margin-top: ${Metrics.smallMargin}px;
`;

const roundFloatingNumber = (value: number) => Math.round(value * 100) / 100;

const PriceInput = ({
  handleOnChange,
  range,
  zoneId,
  isActive,
  currency,
  vat,
}: {
  range: TransportRangeData;
  handleOnChange: (params: DeliveryWeightRange) => void;
  zoneId: number;
  isActive: boolean;
  currency: string;
  vat: number;
}) => {
  const { t } = useContext(LocalizationContext);
  const [priceValue, setPriceValue] = useState(`${range.price}`);
  const [error, setError] = useState<string | null>(null);
  const debouncedSetValue = useRef(
    debounce(value => {
      handleOnChange(value);
    }, 300),
  ).current;

  const minPrice = useMemo(() => {
    if (range.minPrice) {
      if (vat > 0) {
        return roundFloatingNumber(range.minPrice * (1 + vat / 100));
      }

      return range.minPrice;
    }

    return 0;
  }, [range.minPrice, vat]);

  const price = useMemo(() => {
    if (range.price) {
      if (vat > 0) {
        return roundFloatingNumber(range.price * (1 + vat / 100));
      }

      return range.price;
    }

    return 0;
  }, [range.price, vat]);

  return (
    <Container direction="column">
      <Input
        defaultValue={minPrice ? minPrice : price}
        addonAfter={currency}
        size="large"
        pattern="[0-9]+([\.,][0-9]+)?"
        min={minPrice}
        value={!isActive ? (minPrice ? minPrice : price) : priceValue}
        disabled={!isActive}
        onChange={e => {
          debouncedSetValue.cancel();

          const value = e.target.value;
          const parsedValue = parseFloat(value) || 0;

          setPriceValue(value);
          setError(null);

          if (parsedValue < minPrice && parsedValue !== 0) {
            setError('app.zone.countries.minPrice');
            return;
          }

          if (e.target.validity.valid) {
            debouncedSetValue({
              deliveryWeightRangeId: range.id,
              zoneId,
              price: parsedValue,
            });
          }
        }}
      />
      {!!minPrice && minPrice > 0 && (
        <Text size="medium" color={error ? Colors.error : Colors.grey}>
          {error ? t(error, { price: `${minPrice}${currency}` }) : `min. ${minPrice}${currency}`}
        </Text>
      )}
    </Container>
  );
};

const TransportFeeTable = ({
  isActive,
  loading,
  currency,
  vat,
  data,
  handleOnChange,
  handleEditCountries,
  handleDeleteConfirm,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);

  const columns: ColumnsType<TransportFeeTableData> = [
    {
      title: t('app.common.zone'),
      key: 'operation',
      render: (_, record) => (
        <Container direction="column">
          <Container align="center" gap={Metrics.tinyMargin}>
            {!record.hasPresetZone && (
              <RoundButton
                icon="globe"
                tooltipTitle={t('app.zone.countries.edit')}
                onClick={() => {
                  handleEditCountries({
                    id: record.id,
                    name: record.name,
                    countryIds: record.countries.map(c => c.id),
                  });
                }}
                disabled={!isActive}
              />
            )}
            {!record.hasPresetZone && (
              <Popconfirm
                title={t('app.confirm.delete')}
                onConfirm={() => {
                  if (record.id) {
                    handleDeleteConfirm({ id: record.id });
                  }
                }}
              >
                <RoundButton icon="delete" tooltipTitle={t('app.common.delete')} danger disabled={!isActive} />
              </Popconfirm>
            )}
            <Title level="h4">{record.name}</Title>
          </Container>
          <CountriesContainer justify="flex-start" gap={Metrics.tinyMargin}>
            {record.countries.map(country => (
              <Tag key={country.id} color={Colors.grey} textColor={Colors.black} size="small">
                {country.name}
              </Tag>
            ))}
            {record.countries.length === 0 && (
              <Tag color={Colors.waiting} textColor={Colors.black} size="small">
                {t('app.zone.countries.noData')}
              </Tag>
            )}
          </CountriesContainer>
        </Container>
      ),
    },
    ...(data?.[0]?.weightRanges
      ? data[0].weightRanges.map((weightRange, index) => ({
          title: `${t('app.common.upTo')} ${weightRange.weight} g`,
          width: 164,
          dataIndex: `weightRanges[${index}].price`,
          key: `weightRanges[${index}].price`,
          render: (_text: string, record: TransportFeeTableData) => (
            <PriceInput
              handleOnChange={handleOnChange}
              range={record.weightRanges[index]}
              currency={currency}
              vat={vat}
              zoneId={record.id}
              isActive={isActive}
            />
          ),
        }))
      : [{}]),
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: true }}
      loading={loading}
      pagination={false}
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()} ant-table-wrapper--topAlignContent`}
    />
  );
};

export default TransportFeeTable;
