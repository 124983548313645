import { gql } from 'Operations/__generated__';

export const CREATE_PRODUCT_CATEGORY = gql(/* GraphQL */ `
  mutation CreateProductCategory($data: ProductCategoryCreateInput!) {
    createProductCategory(data: $data) {
      id
      name
    }
  }
`);
