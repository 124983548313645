import React, { useContext } from 'react';

import { Empty, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import Container from 'Components/Atoms/Container';
import HandlerTag from 'Components/Atoms/Tag';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import OrderStatusDropdown from 'Components/Molecules/OrderStatusDropdown';
import OrderStatusTag from 'Components/Molecules/OrderStatusTag';

import { Colors, Metrics } from 'Themes';
import styled, { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useCurrency } from 'Hooks/useCurrency';

import { GetOrderQuery, OrderItemHandler, OrderItemStatus } from 'Operations/__generated__/graphql';

interface IdProps {
  id: number;
}
export interface StatusChangeProps extends IdProps {
  newStatus: OrderItemStatus;
}

interface Props {
  order?: GetOrderQuery['getOrder'];
  handleOnView?: (params: IdProps) => void;
  handleOnStatusChange?: (params: StatusChangeProps) => void;
  isCrossAccountsEnabled: boolean;
}

const SpacedRoundButton = styled(RoundButton)`
  margin-left: ${Metrics.tinyMargin}px;
`;

const OrderItemTable = ({ order, handleOnView, handleOnStatusChange, isCrossAccountsEnabled }: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);
  const { formatCurrency } = useCurrency();

  const orderItems = order?.items;

  const columns: ColumnsType<GetOrderQuery['getOrder']['items'][0]> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 120,
      render: (_, record) => (
        <Container align="center">
          <SpacedRoundButton
            icon="show"
            tooltipTitle={t('app.common.show')}
            onClick={() => {
              if (handleOnView) {
                handleOnView({ id: record.id });
              }
            }}
          />
        </Container>
      ),
    },
    {
      title: t('app.common.products', { count: 1 }),
      dataIndex: 'product.name',
      key: 'productName',
      render(id: number, record) {
        return (
          <>
            {record.productName || record?.product?.name || '-'}{' '}
            {record.hasCroppedPhotos && <Tag color="geekblue">{t('app.common.cropped')}</Tag>}
          </>
        );
      },
    },
    {
      title: t('app.orders.productOptions', { count: 2 }),
      dataIndex: 'options',
      key: 'productOptions',
      render(options: GetOrderQuery['getOrder']['items'][0]['options']) {
        return options.reduce((acc, item) => {
          return `${acc} ${item.name}`;
        }, '');
      },
    },
    {
      title: t('app.common.provider'),
      key: 'provider',
      render(_, record) {
        return record?.providerName || record.product?.contact?.displayName || '-';
      },
    },
    {
      title: t('app.orders.handledBy'),
      dataIndex: 'handler',
      key: 'handler',
      render(_, record) {
        const isHandledByFotostudio = record.handler === OrderItemHandler.INCOGNITO;
        return (
          <HandlerTag color={isHandledByFotostudio ? Colors.crmMain : Colors.black} size="small">
            {isHandledByFotostudio ? 'Fotostudio' : t(`app.common.${record.handler.toLowerCase()}`)}
          </HandlerTag>
        );
      },
    },
    {
      title: t('app.common.status'),
      dataIndex: 'status',
      key: 'status',
      render(_, record) {
        const itemStatus = record.status;
        const isHandledByFotostudio = record.handler === OrderItemHandler.INCOGNITO;

        if ((isCrossAccountsEnabled && isHandledByFotostudio) || (!isCrossAccountsEnabled && !isHandledByFotostudio)) {
          return (
            <OrderStatusDropdown
              availableItemStatuses={record.availableItemStatuses || []}
              onChange={({ status }) => handleOnStatusChange?.({ id: record.id, newStatus: status })}
              status={itemStatus}
            />
          );
        } else {
          return <OrderStatusTag itemStatus={itemStatus} />;
        }
      },
    },
    {
      title: t('app.common.price'),
      dataIndex: 'price',
      key: 'price',
      render(_, record) {
        return formatCurrency(record.price);
      },
    },
    {
      title: t('app.common.quantity'),
      dataIndex: 'requestQuantity',
      key: 'requestQuantity',
    },
  ];
  if (!order || !orderItems) {
    return (
      <Container justify="center" align="center">
        <Empty />
      </Container>
    );
  }

  return (
    <>
      <Table
        rowKey="id"
        dataSource={orderItems}
        columns={columns}
        scroll={{ x: true }}
        className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()} ant-table--bordered`}
        pagination={false}
      />
    </>
  );
};

export default OrderItemTable;
