import { gql } from 'Operations/__generated__';

export const ON_NOTIFICATION_ADDED = gql(/* GraphQL */ `
  subscription OnNotificationAdded {
    OnNotificationAdded {
      id
      gallery {
        id
        name
      }
      action
      meta
      isRead
      createdAt
    }
  }
`);
