import { gql } from 'Operations/__generated__';

export const GET_USER_MENU_INFO = gql(/* GraphQL */ `
  query GetUserMenuInfo {
    me {
      id
      company {
        id
        logoUrl
      }
      hasUsedUploadClient
    }
  }
`);
