import { gql } from 'Operations/__generated__';

export const GET_EMAIL_TEMPLATE_CONTENT = gql(/* GraphQL */ `
  query GetEmailTemplateContent($where: EmailTemplateGetInputType!) {
    getEmailTemplate(where: $where) {
      id
      associatedModel
      type @client
      name
      title
      associatedModel
      content
      attachmentName
      attachmentUrl
      createdAt
    }
  }
`);
