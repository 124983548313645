import { gql } from 'Operations/__generated__';

export const PHOTO_CORE_FIELDS = gql(/* GraphQL */ `
  fragment PhotoCoreFields on PhotoAdmin {
    id
    name
    viewCount
    createdAt
    uploadedAt
    statuses
    galleryId
    gallery {
      id
      name
    }
    isWatermarked @client
    isRetouched
    isLiked
    isOrdered
    folder {
      id
    }
    unreadCommentsCount
    asset {
      assetMain {
        key
        type
        size
        uploadStatus
        downloadUrl
      }
      assetWeb {
        key
        type
        size
        uploadStatus
        downloadUrl
      }
      noWmAssetWeb {
        downloadUrl
      }
      noWmThumbSmall {
        downloadUrl
      }
      thumbSmall {
        downloadUrl
      }
      thumbLarge {
        downloadUrl
      }
      noWmThumbLarge {
        downloadUrl
      }
    }
  }
`);
