import React, { useContext, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Select, Table } from 'antd';

import { upperFirst } from 'lodash';

import Container from 'Components/Atoms/Container';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import Header from 'Components/Molecules/Header';

import { Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useCurrency } from 'Hooks/useCurrency';

import dayjs from 'Services/DayjsService';

import { Labo } from 'Operations/__generated__/graphql';

import { GET_LABO_ORDERS } from 'Operations/Queries/LaboOrder/getLaboOrders';

const startingYear = 2023;
const currentYear = dayjs().get('y');
const years = new Array(currentYear - startingYear + 1).fill(0).map((_, index) => currentYear - index);

const LaboOrders = () => {
  const { t } = useContext(LocalizationContext);
  const { formatCurrency } = useCurrency();
  const [year, setYear] = useState(currentYear);

  const { data, loading: isLoading } = useQuery(GET_LABO_ORDERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        labo: Labo.INCOGNITO,
        year,
      },
    },
  });

  return (
    <>
      <Header
        title={t('app.laboOrder.title', { count: 2 })}
        buttons={[
          <div key="yearSelect">
            <Select onChange={newYear => setYear(newYear)} value={year}>
              {years.map(y => (
                <Select.Option key={y} value={y}>
                  {y}
                </Select.Option>
              ))}
            </Select>
          </div>,
        ]}
      />

      <Table
        style={{ marginTop: Metrics.baseMargin }}
        loading={isLoading}
        columns={[
          {
            key: 'operation',
            width: 120,
            render: (_, record) => (
              <Container>
                <RoundButton
                  disabled={!record.fileUrl}
                  href={record.fileUrl || ''}
                  target="_blank"
                  icon="document"
                  tooltipTitle={t('app.common.download')}
                />
              </Container>
            ),
          },
          {
            key: 'month',
            title: t('app.common.month'),
            render(_, { from }) {
              return upperFirst(dayjs(from).format('MMMM YYYY'));
            },
            width: 400,
          },
          {
            key: 'count',
            title: t('app.laboOrder.count'),
            render(_, { orders }) {
              return orders?._count || 0;
            },
          },
          {
            title: `${t('app.laboOrder.products.totalPrice')} (${t('app.common.exclVAT')})`,
            key: 'totalExcVat',
            dataIndex: 'totalExcVat',
            render(_, { totalExcVat }) {
              return formatCurrency(totalExcVat);
            },
          },
          {
            title: `${t('app.products.packagePrice.title')} (${t('app.common.exclVAT')})`,
            key: 'packageTotalExcVat',
            dataIndex: 'packageTotalExcVat',
            render(packageTotalExcVat) {
              return formatCurrency(packageTotalExcVat);
            },
          },
          {
            title: `${t('app.common.transportFee')} (${t('app.common.exclVAT')})`,
            key: 'transportFeeExcVat',
            dataIndex: 'transportFeeExcVat',
            render(transportFeeExcVat) {
              return formatCurrency(transportFeeExcVat);
            },
          },
          {
            title: `${t('app.common.totalPrice')} (${t('app.common.exclVAT')})`,
            key: 'total',
            dataIndex: 'total',
            render(_, { totalExcVat, packageTotalExcVat, transportFeeExcVat }) {
              return formatCurrency(totalExcVat + packageTotalExcVat + transportFeeExcVat);
            },
          },
        ]}
        dataSource={data?.getLaboOrders}
        pagination={false}
      />
    </>
  );
};

export default LaboOrders;
