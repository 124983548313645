import { Tutorial } from 'types/Tutorial';

import { UserConfigVar } from 'Operations/Cache';
import { REACTIVE_VARIABLES_STORAGE_KEYS } from 'Operations/Cache/reactiveVariables';

import { DisplayType } from 'Operations/__generated__/graphql';

const initUserConfig = (userConfigVar: typeof UserConfigVar) => {
  return (
    newConfig: Partial<{
      galleriesDisplay: DisplayType.TABLE;
      isMenuCollapsed: boolean;
      openedTutorials: Record<Tutorial, boolean>;
    }>,
  ) => {
    const oldConfig = UserConfigVar();
    const result = { ...oldConfig, ...newConfig };
    UserConfigVar(result);

    window.localStorage.setItem(REACTIVE_VARIABLES_STORAGE_KEYS.CONFIG, JSON.stringify(result));

    return result;
  };
};

const updateUserConfig = initUserConfig(UserConfigVar);

export default updateUserConfig;
