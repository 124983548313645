import { Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { ModelConnection } from 'types/ModelConnection';

import { orderBy } from 'lodash';

const readList = ({
  readField,
  storeFieldName,
  alias,
  existing,
  page,
  perPage,
  order = ['asc'],
  defaultOrderFields = ['id'],
}: {
  readField: ReadFieldFunction;
  storeFieldName: string;
  alias?: string;
  existing?: ModelConnection;
  page?: number | null;
  perPage?: number | null;
  order?: ('asc' | 'desc')[];
  defaultOrderFields?: string[];
}) => {
  const offset = page && perPage ? (page - 1) * perPage : 0;

  let edges: Reference[] = existing?.edges || [];

  // Check if the query need to keep only the current page or all results (infinite scroll)
  if (alias && storeFieldName.includes(alias)) {
    edges = edges.slice(offset, offset + (perPage || 0));
  } else {
    edges = orderBy(
      edges,
      defaultOrderFields.map(defaultOrderField => (ref: Reference) => {
        const value = readField(defaultOrderField, ref);
        if (typeof value === 'string') {
          return value.toLocaleLowerCase();
        }
        return value;
      }),
      order,
    );
  }

  return { ...(existing || {}), edges };
};

export default readList;
