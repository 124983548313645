const primaryMain = '#27C9D3';
const primaryOptional = '#6296EE';
const secondaryMain = '#FFA600';
const secondaryOptional = '#FFD200';
const secondaryActive = '#fffae6';

const Colors = {
  primaryMain,
  primaryOptional,
  secondaryMain,
  secondaryOptional,
  secondaryActive,

  crmMain: '#6b5fff',

  white: '#FFF',
  black: '#282A2D',
  tableBlack: 'rgba(40, 42, 45, 0.85)',
  grey: '#BFBFBF',
  lightGrey: '#F7F7F7',
  disabled: '#AEAEAE',

  tableRowOdd: '#FFF',
  tableRowEven: '#F4F8FB',

  border: '#EAEAEA',
  formBorder: '#d9d9d9',

  danger: '#FF3333',
  backgroundDanger: '#fff2f0',
  success: '#91CB00',
  backgroundSuccess: '#fcffe6',
  error: '#FF0000',
  waiting: '#FFA600',

  priceType1: '#7e57c2',
  priceType2: '#5d9cec',

  light: {
    primaryMain,
    primaryOptional,
    secondaryMain,
    secondaryOptional,
    secondaryActive,
    primary: '#FFF',
    secondary: '#FFF',
    menuBackground: '#FFF',
    text: '#282A2D',
    scrollbar: {
      thumb: '#ebebeb',
      track: '#F5F5F5',
    },
    sectionContainer: {
      background: '#F7F7F7',
      border: '#E1E1E1',
    },
    loader: {
      background: '#f3f3f3',
      foreground: '#ecebeb',
    },
  },

  dark: {
    primaryMain,
    primaryOptional,
    secondaryMain,
    secondaryOptional,
    secondaryActive,
    primary: '#252526',
    secondary: '#2E2E30',
    menuBackground: '#1E1E1E',
    text: '#FFF',
    scrollbar: {
      thumb: '#34343a',
      track: '#252526',
    },
    sectionContainer: {
      background: '#34343a',
      border: '#E1E1E1',
    },
    loader: {
      background: '#f3f3f3',
      foreground: '#ecebeb',
    },
  },

  support: {
    primaryMain: '#006A7D',
    primaryOptional: '#00395D',
    secondaryMain,
    secondaryOptional,
  },
};

export interface ColorType {
  primaryMain: string;
  primaryOptional: string;
  secondaryMain: string;
  secondaryOptional: string;
  secondaryActive: string;
  primary: string;
  secondary: string;
  menuBackground: string;
  text: string;
  scrollbar: {
    thumb: string;
    track: string;
  };
  sectionContainer: {
    background: string;
    border: string;
  };
  loader: {
    background: string;
    foreground: string;
  };
}

export default Colors;
