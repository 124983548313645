import { gql } from 'Operations/__generated__';

export const UPDATE_PRICING_PLAN = gql(/* GraphQL */ `
  mutation UpdatePricingPlan($where: IdInput!, $data: PricingPlanUpdateInput!) {
    updatePricingPlan(where: $where, data: $data) {
      id
      name
      type
    }
  }
`);
