import React, { useCallback, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';

import { EDGES_PARAMS } from 'constants/paginationParams';

import { Form } from 'formik-antd';
import { chain } from 'lodash';

import Select from 'Components/Molecules/Form/Select';

import { AccessCodeCharacteristic, AccessCodeCoreFieldsFragment } from 'Operations/__generated__/graphql';

import { GET_GALLERIES_ACCESS } from 'Operations/Queries/Gallery/GetGalleriesAccess';

interface SelectGroupProps {
  /**
   * Gallery ID
   */
  galleryId: number;
  /**
   * Label for the select
   */
  label?: string;
  /**
   * Name for the select form item
   */
  name: string;
  /**
   * Selected group codes
   */
  value: string[];
  /**
   * Group codes that are already selected and should be excluded from the list
   */
  excludedGroupCode?: string[];
  /**
   * Group codes that are already loaded and should be included in the list
   */
  loadedGroupCodes: AccessCodeCoreFieldsFragment[];
  /**
   * If the select should allow multiple selections
   */
  multiple?: boolean;
}

const SelectGroup = ({
  galleryId,
  value,
  loadedGroupCodes: loadedGroups,
  excludedGroupCode,
  label,
  name,
  multiple,
}: SelectGroupProps) => {
  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery(GET_GALLERIES_ACCESS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: {
        galleryId,
        characteristics: [AccessCodeCharacteristic.GROUP],
      },
      paginate: EDGES_PARAMS,
    },
  });

  // Merge the paginated contacts and the already loaded contacts (like the selected ones)
  const accessCodeGroups = useMemo(
    () =>
      chain(data?.getAccessCodes.edges || [])
        .concat(loadedGroups || [])
        .compact()
        .uniqBy('code')
        .orderBy([({ name }) => name?.toLocaleLowerCase() || ''])
        .value(),
    [data?.getAccessCodes.edges, loadedGroups],
  );

  // Filter out the contacts that are excluded
  const groupList = useMemo(
    () => accessCodeGroups.filter(group => !excludedGroupCode?.includes(group.code)),
    [accessCodeGroups, excludedGroupCode],
  );

  const handleLoadMoreGroups = useCallback(async () => {
    await fetchMore({
      variables: {
        paginate: { ...EDGES_PARAMS, page: (data?.getAccessCodes.pageInfo.currentPage || 1) + 1 },
      },
    });
  }, [data?.getAccessCodes.pageInfo.currentPage, fetchMore]);

  return (
    <Form.Item label={label} name={name}>
      <Select
        name={name}
        size="large"
        loadMore={handleLoadMoreGroups}
        hasFetchAll={!!data?.getAccessCodes.pageInfo.isLastPage}
        loading={isLoading}
        getPopupContainer={trigger => trigger.parentNode}
        mode={multiple ? 'multiple' : undefined}
      >
        {groupList.map(group => (
          <Select.Option key={group.code} value={group.code} title={''}>
            {group.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectGroup;
