import React, { useMemo } from 'react';

import { Flex, List } from 'antd';
import { createStyles } from 'antd-style';
import { Link } from 'react-router-dom';

import { IconText } from 'Components/Atoms/Icon';
import StatusTag from 'Components/Atoms/StatusTag';

import FeatureLikeButton from 'Components/Molecules/FeatureLikeButton';

import { getFeatureRequestStatusColor } from 'Helpers/getFeatureRequestStatusColor';

import { GetFeatureRequestsQuery } from 'Operations/__generated__/graphql';

type FeatureRequest = GetFeatureRequestsQuery['getFeatureRequests']['edges'][0];

interface Props extends FeatureRequest {
  showComments?: boolean;
  showStatus?: boolean;
}

const useStyles = createStyles(({ css, token }) => ({
  item: css`
    border-left: 3px solid;

    padding: ${token.sizeSM}px !important;
    margin: ${token.sizeSM}px 0;
    width: 100%;

    .ant-list-item-action {
      margin-top: 0;
    }
  `,
  link: css`
    flex: 1;

    .ant-list-item-meta-description p {
      display: inline-block;
    }

    .ant-list-item-meta-title {
      margin-bottom: 0;
    }

    &:hover {
      h4 {
        color: ${token.colorPrimary};
        text-decoration: underline solid ${token.colorPrimary};
      }
    }

    &:active {
      h4 {
        color: ${token.colorPrimaryActive};
        text-decoration: underline solid ${token.colorPrimaryActive};
      }
    }
  `,
}));

const FeatureRequestItem = ({
  id,
  title,
  status,
  category,
  hasVoted,
  votesCount,
  comments,
  showComments,
  showStatus,
}: Props) => {
  const { styles } = useStyles();
  const actions = useMemo(() => {
    const actions = [];
    if (showComments) {
      actions.push(
        <IconText size={14} name="heart" text={votesCount.toString()} />,
        <IconText size={14} name="message" text={comments._count.toString()} />,
      );
    }
    return actions;
  }, [showComments, votesCount, comments._count]);

  return (
    <List.Item
      key={id}
      className={styles.item}
      style={
        status
          ? {
              borderLeftColor: getFeatureRequestStatusColor(status).background,
            }
          : undefined
      }
      actions={actions}
    >
      <Flex flex={1} justify="center" gap="small">
        <FeatureLikeButton {...{ id, hasVoted }} />

        <Link className={styles.link} to={`/app/feature-requests/${id}`}>
          <List.Item.Meta
            title={
              <Flex gap="small">
                {title}
                {showStatus && status && <StatusTag status={status} />}
              </Flex>
            }
            description={<p>{category?.name || '-'}</p>}
          />
        </Link>
      </Flex>
    </List.Item>
  );
};

export default FeatureRequestItem;
