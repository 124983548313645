import { gql } from 'Operations/__generated__';

export const UPDATE_ACCESS_CODE = gql(/* GraphQL */ `
  mutation UpdateAccessCode($where: AccessCodeWhereType!, $data: AccessCodeUpdateDataType!) {
    updateAccessCode(where: $where, data: $data) {
      galleryId
      code
      name
    }
  }
`);
