import { gql } from 'Operations/__generated__';

export const SET_GALLERY_PRESET_WATERMARK = gql(/* GraphQL */ `
  mutation SetGalleryPresetWatermark($where: IdInput!, $data: GalleryPresetSetWatermarkInput!) {
    setGalleryPresetWatermark(where: $where, data: $data) {
      id
      watermark {
        id
      }
      workmode
    }
  }
`);
