import React, { useContext } from 'react';

import { useQuery } from '@apollo/client';
import { Flex } from 'antd';
import { createStyles } from 'antd-style';

import clsx from 'clsx';

import { EditGalleryThemeFormValues } from 'Forms/GalleryTheme';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { getReadableColor } from 'Helpers/TransformColor';

import { ME } from 'Operations/Queries/User/Me';

import { useStyles } from './styles';

const useCartStyles = createStyles<EditGalleryThemeFormValues>(
  ({ css }, { primaryColor, paymentBackgroundColor, backgroundColor, buttonRadius }) => ({
    content: css`
      min-width: 50%;
      padding: 32px;
    `,
    itemsContainer: css`
      background-color: ${paymentBackgroundColor.toRgbString()};
      color: ${backgroundColor.toRgbString()};
      padding: 16px 46px;

      p {
        margin-bottom: 0;
      }
      h1,
      h2 {
        color: ${backgroundColor.toRgbString()} !important;
      }
      .subtext {
        opacity: 0.8;
      }
      .value {
        font-weight: bold;
      }
    `,
    logo: css`
      height: 50px;
      overflow: hidden;
    `,
    subtitle: css`
      font-size: 18px;
      font-weight: bold;
    `,
    productItem: css`
      padding-left: 8px;
      border-bottom: 1px solid ${backgroundColor.toRgbString()};
    `,
    productItemDigital: css`
      padding-bottom: 16px;
    `,
    subproductItem: css`
      padding-left: 8px;
      padding-bottom: 16px;
      border-left: 1px solid ${backgroundColor.toRgbString()};
    `,
    imagePreviewContainer: css`
      width: 140px;
    `,
    step: css`
      color: ${primaryColor.toRgbString()};
      font-size: 16px;

      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 48px;
        border: 1px solid ${primaryColor.toRgbString()};

        border-radius: ${buttonRadius}px;
      }
    `,
    formItem: css`
      label {
        text-transform: uppercase;
        font-weight: bold;
        color: ${primaryColor.toRgbString()};
        line-break: 1;
      }
      input {
        padding: 12px;
        border: 1px solid ${primaryColor.toRgbString()};
        border-radius: ${buttonRadius}px;
        background-color: ${backgroundColor.toRgbString()};
        color: ${getReadableColor({
          backgroundColor: backgroundColor.toRgbString(),
          color: primaryColor.toRgbString(),
          includeFallbackColors: true,
        }).toRgbString()};
        width: 100%;
      }
    `,
    fieldset: css`
      position: relative;
      border: 1px solid ${primaryColor.toRgbString()};
      border-radius: ${Math.min(buttonRadius, 24)}px;
      margin: 16px 0;
      padding: 16px;

      > p {
        position: absolute;
        top: -12px;
        background-color: ${backgroundColor.toRgbString()};
        color: ${primaryColor.toRgbString()};
        padding: 0 4px;

        text-transform: uppercase;
        font-weight: bold;
      }
    `,
  }),
);

const CartPreview = (props: EditGalleryThemeFormValues) => {
  const { t } = useContext(LocalizationContext);
  const { styles } = useStyles(props);
  const { styles: cartStyles } = useCartStyles(props);

  const { data } = useQuery(ME);

  // TS check
  if (typeof styles === 'string' || typeof cartStyles === 'string') {
    return null;
  }

  const FormItem = ({ label, value }: { label: string; value?: string }) => (
    <Flex vertical gap="small" flex={1} className={cartStyles.formItem}>
      <label>{label}</label>
      <input type="text" value={value} />
    </Flex>
  );

  return (
    <Flex className={clsx(styles.container, styles.responsiveContainer)}>
      <Flex vertical flex={1} gap="large" className={clsx(cartStyles.itemsContainer, cartStyles.content)}>
        <Flex justify="space-between" align="center">
          <p>&lt; {t('galleryPreview.cart.back')}</p>
          <img src={data?.me.company.logoUrl || Images.galleriesLogo} className={cartStyles.logo} />
        </Flex>
        <h1 className={clsx(styles.title, 'left', 'secondary')}>{t('galleryPreview.cart.title')}</h1>
        <h2 className={clsx(cartStyles.subtitle)}>
          <span className="subtext">{t('galleryPreview.cart.totalInclVat')}</span>
          <br />
          89,99 €
        </h2>
        <Flex gap="small" className={clsx(cartStyles.productItem, cartStyles.productItemDigital)}>
          <div className={clsx(styles.imageContainer, cartStyles.imagePreviewContainer)}>
            <img src={Images.coverMissing} />
          </div>
          <Flex flex={1} vertical gap="small">
            <strong>{t('app.common.photo', { count: 1 })}</strong>
            <Flex justify="space-between">
              <p className="subtext">{t('galleryPreview.cart.price')}</p>
              <p className="value">40 €</p>
            </Flex>
            <Flex justify="space-between">
              <p className="subtext">{t('galleryPreview.cart.quantity')}</p>
              <p className="value">2</p>
            </Flex>
          </Flex>
        </Flex>
        <Flex gap="small" vertical className={cartStyles.productItem}>
          <strong>
            {t('galleryPreview.productModal.productName')} - {t('galleryPreview.productModal.option1.value1')}
          </strong>
          <Flex justify="space-between">
            <p className="subtext">{t('galleryPreview.cart.price')}</p>
            <p className="value">39,99 €</p>
          </Flex>
          <Flex gap="small" className={cartStyles.subproductItem}>
            <div className={clsx(styles.imageContainer, cartStyles.imagePreviewContainer)}>
              <img src={Images.coverMissing} />
            </div>
            <Flex flex={1} vertical gap="small">
              <strong>
                {t('galleryPreview.productModal.option2.title')} {t('galleryPreview.productModal.option2.value2')}
              </strong>
              <Flex justify="space-between">
                <p className="subtext">{t('galleryPreview.cart.quantity')}</p>
                <p className="value">1</p>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify="space-between" className={clsx(cartStyles.productItem, cartStyles.productItemDigital)}>
          <strong className={clsx('subtext')}>{t('galleryPreview.cart.inclVAT')}</strong>
          <strong className={clsx('subtext')}>18 €</strong>
        </Flex>
        <Flex justify="space-between" className={clsx(cartStyles.productItem, cartStyles.productItemDigital)}>
          <strong className={clsx('subtext')}>{t('galleryPreview.cart.transportFee')}</strong>
          <strong className={clsx('subtext')}>10 €</strong>
        </Flex>
        <Flex justify="space-between" className={clsx(cartStyles.productItem, cartStyles.productItemDigital)}>
          <strong>{t('app.common.totalPrice')}</strong>
          <strong>89,99 €</strong>
        </Flex>
      </Flex>
      <Flex flex={1} vertical className={clsx(cartStyles.content)}>
        <Flex gap="middle" vertical>
          <Flex gap="middle" align="center" className={cartStyles.step}>
            <span className="circle">1</span>
            <span>{t('galleryPreview.cart.step1')}</span>
          </Flex>
          <Flex gap="middle" className={styles.subResponsiveContainer}>
            <FormItem label={t('app.common.firstname')} value="John" />
            <FormItem label={t('app.common.name')} value="Doe" />
          </Flex>
          <Flex gap="middle">
            <FormItem label={t('app.common.email')} value="john.doe@fotostudio.io" />
          </Flex>
          <Flex gap="middle" vertical className={cartStyles.fieldset}>
            <p>{t('app.common.address')}</p>
            <Flex gap="middle" className={styles.subResponsiveContainer}>
              <FormItem label={t('app.common.street')} />
              <FormItem label={t('app.common.zipcode')} />
            </Flex>
            <Flex gap="middle" className={styles.subResponsiveContainer}>
              <FormItem label={t('app.common.city')} />
              <FormItem label={t('app.common.country')} />
            </Flex>
          </Flex>

          <button disabled className={clsx(styles.button, 'primary')}>
            {t('app.actions.next')}
          </button>

          <Flex gap="middle" align="center" className={cartStyles.step}>
            <span className="circle">2</span>
            <span>{t('app.common.payment')}</span>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CartPreview;
