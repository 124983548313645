import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Flex, Popconfirm } from 'antd';
import { createStyles } from 'antd-style';
import axios from 'axios';

import { camelCase } from 'lodash';
import * as mime from 'mime-types';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import PhotoUploaderRow from 'Components/Molecules/PhotoUploader/PhotoUploaderRow';
import { PhotoUploadStatus } from 'Components/Molecules/PhotoUploader/Types';

import { Colors, FontSize, Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import generateId from 'Helpers/GenerateId';
import GetErrorCode from 'Helpers/GetErrorCode';

import {
  GalleryPhotosOrder,
  UploadCreateInputType,
  UploadUrlType
} from 'Operations/__generated__/graphql';

import { GET_UPLOAD_URL } from 'Operations/Queries/Upload/GetUploadUrl';

import { CREATE_UPLOAD } from 'Operations/Mutations/Upload/CreateUpload';

import ErrorDisplay from './ErrorDisplay';

type UploadSessionStatus = 'CLEARED' | 'SELECTED' | 'INPROGRESS' | 'DONE';

interface PhotoUploaderProps {
  selectedFiles: File[] | undefined;
  targetGallery: number;
  targetFolderId?: number | null;
  targetOrderItemId?: number;
  isRetouched: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onPhotoUploaded?: (photo: { galleryId: number; photoName: string; assetId: string }) => void;
}

const reducedHeight = 48;
const openedHeight = 346;

const useStyles = createStyles(({ css, token, stylish }) => ({
  mainContainer: css`
    z-index: 2;
    position: fixed;
    right: ${token.sizeSM + 90}px;
    bottom: ${token.sizeSM}px;
    width: 412px;
    background-color: ${token.colorBgBase};
    border-radius: ${token.borderRadiusSM}px;
    transition: height ease-in-out 0.3s;
    ${stylish.shadow}
  `,
  uploaderTitleBar: css`
    align-items: center;
    background: ${token.uploaderTitleBackground};
    width: 100%;
    padding: ${token.sizeXS}px;
    p {
      font-size: ${token.fontSizeXL}px;
      color: ${token.colorWhite};
    }
  `,
  uploaderList: css`
    overflow-y: auto;
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    flex: 1;
  `,
  errorFilesContainer: css`
    border: ${token.lineWidth}px dashed ${token.colorError};
    padding: ${token.sizeXS}px;
    width: 100%;
  `,
  titleBarButton: css`
    background-color: ${token.uploaderButtonBackground};
    color: ${token.uploaderButtonColor};
    &:hover {
      background-color: ${token.uploaderButtonHoverBackground} !important;
    }
  `,
}));

function isApolloError(error: Error | ApolloError): error is ApolloError {
  return (error as ApolloError).graphQLErrors !== undefined;
}

function computeProgressPercentage({ loaded, total }: ProgressEvent) {
  if (loaded && total) {
    return (loaded / total) * 100;
  }
  return undefined;
}

const PhotoUploader = ({
  selectedFiles,
  targetGallery,
  targetFolderId,
  targetOrderItemId,
  isRetouched,
  onClose,
  onCancel,
  onPhotoUploaded,
}: PhotoUploaderProps) => {
  const { t } = useContext(LocalizationContext);
  const { styles, theme } = useStyles();

  const [isExpanded, setIsExpanded] = useState(true);
  const [uploadSessionStatus, setUploadSessionStatus] = useState<UploadSessionStatus>('CLEARED');
  const [files, setFiles] = useState<PhotoUploadStatus[]>([]);
  const [errorCode, setErrorCode] = useState<string>();
  const uploadSessionId = useRef<number | null>(null);
  const uploaderListRef = useRef<HTMLDivElement>(null);

  const currentFile = useMemo(() => files.find(file => file.status === 'UPLOADING'), [files]);

  const { errorFiles, uploadingFiles } = useMemo(() => {
    const errorFiles = files.filter(file => file.status === 'ERROR');
    const uploadingFiles = files.filter(file => file.status !== 'ERROR');

    return { errorFiles, uploadingFiles };
  }, [files]);

  const [getFileUploadUrl] = useLazyQuery(GET_UPLOAD_URL, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (currentFile) {
        onUploadUrlRetrieved(currentFile, data.getUploadUrl.url);
      }
    },
    onError: error => {
      handleCurrentFileError(error);
    },
  });

  const [createUpload] = useMutation(CREATE_UPLOAD, {
    update(cache) {
      // Update gallery photosOrder only if upload is not for an order (retouch)
      if (!targetOrderItemId) {
        const galleryRef = cache.identify({ __typename: 'GalleryAdmin', id: targetGallery });

        cache.modify({
          id: galleryRef,
          fields: {
            photosOrder() {
              return GalleryPhotosOrder.CUSTOM;
            },
          },
        });

        cache.gc();
      }
    },
  });

  const updateCurrentFile = useCallback(
    (cb: (file: PhotoUploadStatus) => PhotoUploadStatus) => {
      setFiles(files => files.map(file => (file.assetId === currentFile?.assetId ? cb(file) : file)));
    },
    [currentFile?.assetId],
  );

  const uploadNextFile = useCallback(() => {
    setFiles(files => {
      let isNextFile = false;

      const updatedFiles: PhotoUploadStatus[] = files.map(file => {
        // Change the first item in map waiting to be uploaded
        if (file.status === 'WAITING' && !isNextFile) {
          const nextFile = { ...file };

          if (file.file.size === 0 || uploadSessionId.current === null) {
            nextFile.status = 'ERROR';
          } else {
            isNextFile = true;

            getFileUploadUrl({
              variables: {
                data: {
                  assetId: nextFile.assetId,
                  uploadId: uploadSessionId.current,
                  galleryId: targetGallery,
                  uploadUrlType: UploadUrlType.HD,
                },
              },
            });

            nextFile.status = 'UPLOADING';
          }

          return nextFile;
        }

        return file;
      });

      if (!isNextFile) {
        setUploadSessionStatus('DONE');
        if (uploaderListRef.current) {
          uploaderListRef.current.scrollTo({ top: 0 });
        }
      }

      return updatedFiles;
    });
  }, [getFileUploadUrl, targetGallery]);

  const handleCurrentFileError = useCallback(
    (error?: Error) => {
      if (error) {
        console.log(error);
      }

      updateCurrentFile(file => ({ ...file, status: 'ERROR' }));

      uploadNextFile();
    },
    [updateCurrentFile, uploadNextFile],
  );

  const onUploadUrlRetrieved = useCallback(
    (currentFile: PhotoUploadStatus, uploadUrl: string) => {
      const fileReader = new FileReader();
      const contentType = mime.lookup(currentFile.file.name);
      fileReader.onerror = () => {
        handleCurrentFileError(fileReader.error || undefined);
      };

      fileReader.onloadend = async () => {
        if (fileReader.error || fileReader.EMPTY) {
          return;
        }

        const onUploadProgress = (progressEvent: ProgressEvent) => {
          updateCurrentFile(file => ({
            ...file,
            progress: computeProgressPercentage(progressEvent),
          }));
        };

        const source = axios.CancelToken.source();
        try {
          const response = await axios({
            cancelToken: source.token,
            method: 'PUT',
            url: uploadUrl,
            data: fileReader.result,
            headers: {
              'Content-Type': contentType,
            },
            onUploadProgress,
          });

          if (response.status !== 200) {
            handleCurrentFileError();
          } else {
            updateCurrentFile(file => ({ ...file, status: 'SUCCESS' }));
            if (onPhotoUploaded) {
              onPhotoUploaded({
                galleryId: targetGallery,
                photoName: currentFile.file.name,
                assetId: currentFile.assetId,
              });
            }
            uploadNextFile();
          }
        } catch (error) {
          handleCurrentFileError(error);
        }
      };
      fileReader.readAsArrayBuffer(currentFile.file);
    },
    [handleCurrentFileError, onPhotoUploaded, targetGallery, updateCurrentFile, uploadNextFile],
  );

  const toggleExpansion = useCallback(() => {
    setIsExpanded(b => !b);
  }, []);

  const onCloseCancel = useCallback(() => {
    if (uploadSessionStatus === 'INPROGRESS') {
      onCancel?.();
    } else {
      onClose?.();
    }
  }, [onCancel, onClose, uploadSessionStatus]);

  const getTitleText = useCallback(
    (status: UploadSessionStatus, files: PhotoUploadStatus[], errorCode?: string) => {
      if (errorCode) {
        return t(`app.message.error.upload.${camelCase(errorCode)}.title`);
      }

      if (status === 'INPROGRESS') {
        const currentFileNumber = files.filter(x => x.status === 'SUCCESS').length;
        return t('app.uploader.title.inprogress', { currentFileNumber, totalFiles: files.length });
      } else if (status === 'SELECTED') {
        return t('app.uploader.title.selected', { totalFiles: files.length });
      } else if (status === 'DONE') {
        if (errorFiles.length > 0) {
          return t('app.uploader.title.doneWithError');
        } else {
          return t('app.uploader.title.done');
        }
      } else if (status === 'CLEARED') {
        return t('app.uploader.title.cleared');
      }
    },
    [errorFiles.length, t],
  );

  // Effect looking to start upload
  useEffect(() => {
    const startUpload = async (files: PhotoUploadStatus[]) => {
      const session: UploadCreateInputType = {
        galleryId: targetGallery,
        folderId: targetFolderId,
        orderItemId: targetOrderItemId,
        uploader: 'web-galadmin',
        isRetouched,
        assets: files.map(x => {
          return {
            id: x.assetId,
            name: x.file.name,
          };
        }),
      };

      try {
        const result = await createUpload({ variables: { data: session } });

        if (result.data?.createUpload.id) {
          uploadSessionId.current = result.data.createUpload.id;
          setUploadSessionStatus('INPROGRESS');
          uploadNextFile();
        }
      } catch (error) {
        if (isApolloError(error)) {
          setErrorCode(GetErrorCode(error));
        } else {
          setErrorCode(error);
        }
      }
    };

    if (uploadSessionStatus === 'CLEARED' && selectedFiles && selectedFiles.length > 0) {
      setUploadSessionStatus('SELECTED');

      const filesToUpload = selectedFiles.map<PhotoUploadStatus>(x => {
        return {
          file: x,
          progress: 0,
          status: 'WAITING',
          assetId: generateId(),
        };
      });

      setFiles(filesToUpload);
      startUpload(filesToUpload);
    }
  }, [
    createUpload,
    selectedFiles,
    targetGallery,
    targetFolderId,
    targetOrderItemId,
    isRetouched,
    uploadSessionStatus,
    uploadNextFile,
  ]);

  const canFreelyCloseWindow = useMemo(
    () => uploadSessionStatus === 'DONE' || !!errorCode,
    [errorCode, uploadSessionStatus],
  );

  return (
    <Flex vertical style={{ height: isExpanded ? openedHeight : reducedHeight }} className={styles.mainContainer}>
      <Flex
        justify="space-between"
        className={styles.uploaderTitleBar}
        style={{
          borderTopLeftRadius: theme.borderRadiusSM,
          borderTopRightRadius: theme.borderRadiusSM,
          borderBottomLeftRadius: isExpanded ? 0 : theme.borderRadiusSM,
          borderBottomRightRadius: isExpanded ? 0 : theme.borderRadiusSM,
        }}
      >
        <Title level="h5" color={Colors.white}>
          <Container justify="center" align="center" gap={4}>
            {errorCode && <Icon name="error" color={Colors.error} size={FontSize.h3} />}
            {getTitleText(uploadSessionStatus, files, errorCode)}
          </Container>
        </Title>
        <Container gap={Metrics.smallMargin}>
          <RoundButton
            className={styles.titleBarButton}
            type="primary"
            icon={!isExpanded ? 'chevron-up' : 'chevron-down'}
            size="middle"
            onClick={toggleExpansion}
            onMouseLeave={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.currentTarget.blur();
            }}
          />
          <Popconfirm
            title={t('app.confirm.cancel')}
            onConfirm={onCloseCancel}
            placement="topRight"
            disabled={canFreelyCloseWindow}
          >
            <RoundButton
              className={styles.titleBarButton}
              type="primary"
              icon="close"
              size="middle"
              onClick={canFreelyCloseWindow ? onCloseCancel : undefined}
            />
          </Popconfirm>
        </Container>
      </Flex>
      {isExpanded && !errorCode && (
        <Flex className={styles.uploaderList} ref={uploaderListRef}>
          {errorFiles.length > 0 && (
            <div className={styles.errorFilesContainer}>
              <Text color={Colors.danger} size="small">
                {t('app.uploader.notUploadedError')}
              </Text>
              {errorFiles.map((f, index) => (
                <PhotoUploaderRow key={f.file.name} fileUploadStatus={f} rowIndex={index} />
              ))}
            </div>
          )}
          {uploadingFiles.map((f, index) => (
            <PhotoUploaderRow key={f.file.name} fileUploadStatus={f} rowIndex={index} />
          ))}
        </Flex>
      )}
      {isExpanded && errorCode && <ErrorDisplay errorCode={errorCode} />}
    </Flex>
  );
};
export default PhotoUploader;
