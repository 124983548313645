import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { createStyles } from 'antd-style';
import { matchPath, useLocation, useRoutes } from 'react-router-dom';
import { RefreshTokenPayload } from 'types/Token';

import { decodeJwt } from 'jose';
import { intersection } from 'lodash';

import Layout from 'Components/Atoms/Layout';

import Menu from 'Components/Molecules/Menu';
import { MenuLeftColumnLink } from 'Components/Molecules/MenuLeftColumn';
import { Props as MenuLinkProps } from 'Components/Molecules/MenuLink';
import modals from 'Components/Molecules/Modals';

import { AppRoutePathsObject, appRoutes } from 'Routes/AppRoutes';

import { globalTheme } from 'Themes/styles';

import { ApolloContext } from 'Providers/ApolloProvider';
import { ThemeContext } from 'Providers/ThemeProvider';

import MenuRightProvider from 'Hooks/MenuRightProvider';
import { ModalsProvider } from 'Hooks/Modal';
import { NotificationDrawerProvider } from 'Hooks/NotificationDrawerProvider';
import PhotoAddedProvider from 'Hooks/PhotoAddedProvider';
import { PhotoDrawerProvider } from 'Hooks/PhotoDrawerProvider';
import { UploadProvider } from 'Hooks/Upload';
import { useCurrentUser } from 'Hooks/useCurrentUser';
import { useRoles } from 'Hooks/useRoles';

import { onRefreshSession } from 'Services/AppLifeCycle';

import { AuthVar } from 'Operations/Cache';
import { REACTIVE_VARIABLES_STORAGE_KEYS } from 'Operations/Cache/reactiveVariables';

import { GET_AUTH } from 'Operations/Queries/Auth/GetAuth';
import { ME } from 'Operations/Queries/User/Me';

import CreateAuth from 'Operations/Mutations/Auth/CreateAuth';

const useStyles = createStyles(({ css }) => ({
  loaderOverlay: css`
    position: fixed;
    inset: 0;
    background-color: #fff;
    z-index: 1000;
    transition: all ease-out 500ms;
    &.loaded {
      opacity: 0;
      pointer-events: none;
    }
  `,
}));

const refreshTime = process.env.REACT_APP_REFRESH_TIME ? parseInt(process.env.REACT_APP_REFRESH_TIME) : 600000;

const AppNavigation = () => {
  const { refreshClient } = useContext(ApolloContext);
  const { setTheme } = useContext(ThemeContext);
  const { pathname } = useLocation();
  const { currentUser } = useCurrentUser();
  const { styles, cx } = useStyles();

  const routesElement = useRoutes(
    appRoutes.flatMap(appRoutesGroup => appRoutesGroup.flatMap(appRoute => appRoute.paths || [])),
  );

  const { roles: userRoles } = useRoles();

  const [isLoaded, setIsLoaded] = useState(false);

  const { data } = useQuery(ME);

  const { data: authQuery } = useQuery(GET_AUTH);

  useEffect(() => {
    if (authQuery?.auth?.isSupport) {
      setTheme({ ...globalTheme['support'] });
    } else if (data?.me.menuTheme) {
      setTheme({ ...globalTheme[data?.me?.menuTheme.toString().toLowerCase() as keyof typeof globalTheme] });
    }
  }, [authQuery?.auth?.isSupport, data?.me?.menuTheme]);

  const handleOnRefreshSession = useCallback(async () => {
    await onRefreshSession({ refreshClient });
    setIsLoaded(true);
  }, [refreshClient]);

  useEffect(() => {
    handleOnRefreshSession();

    // Handle silent refresh token
    const silentRefreshInterval = setInterval(() => {
      handleOnRefreshSession();
    }, refreshTime);

    window.addEventListener('storage', () => {
      const token = AuthVar().refreshToken;
      const { exp: prevExp } = (token ? decodeJwt(token) || {} : {}) as unknown as RefreshTokenPayload;
      const storageAuth = JSON.parse(window.localStorage.getItem(REACTIVE_VARIABLES_STORAGE_KEYS.AUTH) || '{}');
      const { exp: nextExp } = (storageAuth.refreshToken
        ? decodeJwt(storageAuth.refreshToken) || {}
        : {}) as unknown as RefreshTokenPayload;

      if (storageAuth && storageAuth.accessToken && storageAuth.refreshToken && nextExp >= prevExp) {
        CreateAuth(storageAuth);
      }
    });

    return () => clearInterval(silentRefreshInterval);
  }, [handleOnRefreshSession]);

  const { parsedLeftLinks, parsedRightLinks } = useMemo(() => {
    const navigationRoutes = appRoutes.flatMap(appRoutesGroup => appRoutesGroup.flatMap(appRoute => appRoute.paths));
    const parsedLeftLinks: MenuLeftColumnLink[][] = [];
    const parsedRightLinks: MenuLinkProps[] = [];

    appRoutes.forEach(appRoutesGroup => {
      const parsedLeftLinkGroup: MenuLeftColumnLink[] = [];

      appRoutesGroup.forEach(appRoute => {
        const isAppRouteRestricted =
          appRoute.disallowedRoles && intersection(appRoute.disallowedRoles, userRoles).length > 0;
        if (isAppRouteRestricted) {
          return;
        }

        // Change help center URL according to user locale
        if (appRoute.namespace === 'help' && currentUser) {
          switch (currentUser.me.locale) {
            case 'it':
              appRoute.href = 'https://help.fotostudio.io/it/';
              break;
            case 'es':
              appRoute.href = 'https://help.fotostudio.io/es/';
              break;
            case 'fr':
              appRoute.href = 'https://help.fotostudio.io/fr/';
              break;
            default:
              appRoute.href = 'https://help.fotostudio.io/en/';
          }
        }

        if (appRoute.paths) {
          const menuRight: MenuLinkProps[] = [];

          const getPaths = ({
            children,
            routes = [],
            level = 1,
            fullPath = '',
          }: {
            children: AppRoutePathsObject[];
            routes?: AppRoutePathsObject[];
            level?: number;
            fullPath?: string;
          }) => {
            children.forEach(({ children: routeChildren, element: _el, ...route }: AppRoutePathsObject) => {
              const isRestricted =
                (route.allowedRoles && intersection(route.allowedRoles, userRoles).length === 0) ||
                (route.disallowedRoles && intersection(route.disallowedRoles, userRoles).length > 0);
              const currentPath = fullPath + (route.path ? '/' + route.path : '');
              if (!isRestricted) {
                if (route.isSeparator) {
                  menuRight.push({
                    isSeparator: true,
                  });
                } else if (route.isVisible) {
                  routes.push({ ...route, path: currentPath });

                  const matchRoute = matchPath(
                    {
                      path: `/app${currentPath}`,
                      end: false,
                    },
                    pathname,
                  );
                  const isActive = !!matchRoute;
                  if (isActive) {
                    menuRight.forEach((_, index) => (menuRight[index].active = false));
                  }
                  menuRight.push({
                    href: currentPath.substring(1),
                    text: route.title || '-',
                    textParams: route.titleParams,
                    active: isActive,
                    icon: route.icon,
                    rightIcon: 'chevron-right',
                  });
                }

                // Construct navigation routes for react-router
                if (routeChildren?.length) {
                  const subPaths = getPaths({
                    children: routeChildren,
                    level: level + 1,
                    fullPath: currentPath,
                  });

                  routes.push(...subPaths.routes);
                }
              }
            });

            return { routes };
          };

          const { routes } = getPaths({ children: appRoute.paths });

          const isActive = menuRight.some(({ active }) => active);
          if (appRoute.inMainMenu && routes.length) {
            const indexedRoute = routes.find(r => r.index) || routes[0];
            parsedLeftLinkGroup.push({
              url: indexedRoute.path?.substring(1),
              icon: appRoute.icon,
              title: appRoute.title,
              active: isActive,
            });
          }
          if (isActive && menuRight.length) {
            parsedRightLinks.push(...menuRight);
          }
        } else {
          if (appRoute.inMainMenu) {
            parsedLeftLinkGroup.push({ ...appRoute, active: false });
          }
        }
      });
      if (parsedLeftLinkGroup.length) {
        parsedLeftLinks.push(parsedLeftLinkGroup);
      }
    });

    return { navigationRoutes, parsedLeftLinks, parsedRightLinks };
  }, [currentUser, pathname, userRoles]);

  return (
    <>
      {isLoaded && !!data?.me && (
        <UploadProvider>
          <ModalsProvider initialModals={modals}>
            <PhotoDrawerProvider>
              <PhotoAddedProvider>
                <MenuRightProvider>
                  <NotificationDrawerProvider>
                    <Layout>
                      <Menu menuLinks={parsedLeftLinks} rightContentLinks={parsedRightLinks} />
                      <Layout flexDirection="column" padding="baseMargin">
                        {routesElement}
                      </Layout>
                    </Layout>
                  </NotificationDrawerProvider>
                </MenuRightProvider>
              </PhotoAddedProvider>
            </PhotoDrawerProvider>
          </ModalsProvider>
        </UploadProvider>
      )}
      <div className={cx(styles.loaderOverlay, isLoaded && !!data?.me && 'loaded')} />
    </>
  );
};

export default AppNavigation;
