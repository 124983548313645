import { gql } from 'Operations/__generated__';

export const GET_PRODUCTS = gql(/* GraphQL */ `
  query GetProducts($where: ProductsWhereInput!) {
    getProducts(where: $where) {
      _count
      edges {
        ...ProductCoreFields
      }
    }
  }
`);
