import { gql } from 'Operations/__generated__';

export const UPDATE_THEME = gql(/* GraphQL */ `
  mutation UpdateTheme($data: UserMenuThemeInputType!) {
    updateMenuTheme(data: $data) {
      id
      menuTheme
    }
  }
`);
