import { useContext, useMemo } from 'react';

import { DeepPartial } from '@apollo/client/utilities';
import { Button, Divider, Drawer } from 'antd';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import ContactCard from 'Components/Molecules/ContactCard';
import PhotoListing from 'Components/Molecules/PhotoListing';
import ProductInfoCard from 'Components/Molecules/ProductInfoCard';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import {
  GalleryWorkmode,
  OrderItem,
  OrderItemProductOption,
  PhotoCharacteristic,
} from 'Operations/__generated__/graphql';

interface Props {
  isVisible: boolean;
  onCloseDrawer: () => void;
  orderItem?: DeepPartial<OrderItem>;
  galleryId: number;
  workmode?: GalleryWorkmode;
}

const DrawerContent = styled.div`
  padding: ${Metrics.baseMargin}px;
`;

const Provider = styled(ContactCard)`
  width: calc(100% - ${Metrics.smallMargin * 2}px);
  margin: ${Metrics.smallMargin}px;
`;

const StyledProductInfoCard = styled(ProductInfoCard)`
  width: calc(100% - ${Metrics.smallMargin * 2}px);
  margin: ${Metrics.smallMargin}px;
`;

const OrderItemDrawer = ({ isVisible, onCloseDrawer, orderItem, galleryId, workmode }: Props) => {
  const { t } = useContext(LocalizationContext);

  const downloadButton = useMemo(
    () =>
      orderItem && (
        <Button disabled={!orderItem.packageUrl} key="download" size="large">
          <Icon name="download" /> {t('app.common.download')}
        </Button>
      ),
    [orderItem, t],
  );

  if (!orderItem) {
    return null;
  }
  const { product, id: orderItemId } = orderItem;
  const provider = orderItem.product?.contact;

  if (!orderItemId) {
    return null;
  }

  return (
    <Drawer
      open={isVisible}
      title={
        <Title level="h2">
          {orderItem.id
            ? `${t('app.common.orderItem', { count: 1 })} #${orderItemId.toString().padStart(6, '0')}`
            : t('app.common.loading')}
        </Title>
      }
      placement="right"
      onClose={onCloseDrawer}
      width={Metrics.drawer.photo}
      closeIcon={<Icon name="close" />}
    >
      <DrawerContent>
        {provider?.id && (
          <Provider
            key={provider.id}
            displayName={provider.displayName || ''}
            contactType={provider.contactType || undefined}
            company={provider.company || undefined}
            email={provider.email || undefined}
            firstname={provider.firstname || undefined}
            lastname={provider.lastname || undefined}
          />
        )}
        {product?.name && (
          <StyledProductInfoCard
            name={product.name}
            quantity={orderItem.quantity || 1}
            options={(orderItem.options || []) as OrderItemProductOption[]}
          />
        )}

        {galleryId && orderItemId && workmode && (
          <>
            <Divider>
              <Text weight="bold">{t('app.common.photo', { count: 2 })}</Text>
            </Divider>

            <Container justify="flex-end">
              {orderItem.packageUrl && (
                <a href={orderItem.packageUrl} target="_blank">
                  {downloadButton}
                </a>
              )}
              {!orderItem.packageUrl && downloadButton}
            </Container>
            {workmode === GalleryWorkmode.READY_FOR_SALE && (
              <PhotoListing
                croppedUrl={orderItem?.packageUrl || undefined}
                galleryId={galleryId}
                orderItemId={orderItemId}
                characteristics={[PhotoCharacteristic.ORDERED]}
              />
            )}
            {workmode === GalleryWorkmode.RETOUCH_FIRST && (
              <>
                <PhotoListing
                  title={t('app.order.photosRetouchRequest')}
                  galleryId={galleryId}
                  orderItemId={orderItemId}
                  characteristics={[PhotoCharacteristic.NEEDS_RETOUCH]}
                />
                <PhotoListing
                  croppedUrl={orderItem?.packageUrl || undefined}
                  title={t('app.order.photosRetouched')}
                  galleryId={galleryId}
                  orderItemId={orderItemId}
                  characteristics={[PhotoCharacteristic.RETOUCHED]}
                />
              </>
            )}
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default OrderItemDrawer;
