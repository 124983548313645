import React, { useContext, useState } from 'react';

import { generate, presetPalettes } from '@ant-design/colors';
import { useQuery } from '@apollo/client';
import { Button, Flex, Radio, Select as SelectAnt } from 'antd';
import { createStyles } from 'antd-style';
import { Color } from 'antd/es/color-picker';
import { ColorFactory } from 'antd/es/color-picker/color';
import { ColorPickerProps } from 'antd/lib';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Select } from 'formik-antd';
import { isEqual, sortBy } from 'lodash';

import Fieldset from 'Components/Atoms/Fieldset';
import Title from 'Components/Atoms/Title';

import ColorPicker from 'Components/Molecules/Form/ColorPicker';
import CartPreview from 'Components/Molecules/GalleryPreview/CartPreview';
import GalleryPreview from 'Components/Molecules/GalleryPreview/GalleryPreview';
import ProductPreview from 'Components/Molecules/GalleryPreview/ProductPreview';

import { BUTTON_FONTS, FONTS } from 'Themes/Fonts';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

import { getComplimentary } from 'Helpers/TransformColor';

import { GET_COMPANY } from 'Operations/Queries/Company/GetCompany';

export interface EditGalleryThemeFormValues {
  id: number;
  secondaryColor: Color;
  primaryColor: Color;
  backgroundColor: Color;
  menuBackgroundColor: Color;
  paymentBackgroundColor: Color;
  buttonFont: string;
  titleFont: string;
  buttonRadius: number;
  photoRadius: number;
}

export const useStyles = createStyles(({ css, responsive, token }) => ({
  container: css`
    ${responsive.md} {
      flex-direction: column;
    }
    width: 100%;
  `,
  formContainer: css`
    min-width: 200px;
    width: 200px;
    flex: 0;
    ${responsive.md} {
      display: flex;
      flex-direction: row;
      gap: ${token.size}px;
      width: 100%;

      .Fieldset {
        flex: 1;
      }
    }
  `,
  colorPreview: css`
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  `,
}));

const THEME_PRESETS: Record<string, Omit<EditGalleryThemeFormValues, 'id'>> = {
  Fotostudio: {
    primaryColor: new ColorFactory('#000'),
    secondaryColor: new ColorFactory('#fff'),
    menuBackgroundColor: new ColorFactory('#fff'),
    paymentBackgroundColor: new ColorFactory('#b0b0b0'),
    backgroundColor: new ColorFactory('#fff'),
    buttonFont: 'Lato',
    titleFont: 'Georgia',
    buttonRadius: 0,
    photoRadius: 0,
  },
  'Monastery Morning': {
    primaryColor: new ColorFactory('rgba(5,90,91,1)'),
    secondaryColor: new ColorFactory('rgba(230,230,230,1)'),
    backgroundColor: new ColorFactory('rgba(255,255,255,1)'),
    menuBackgroundColor: new ColorFactory('rgba(255,255,255,1)'),
    buttonRadius: 8,
    photoRadius: 0,
    titleFont: 'Playfair Display',
    buttonFont: 'Open Sans',
    paymentBackgroundColor: new ColorFactory('rgba(5,90,91,1)'),
  },
  'The Sanctuary': {
    primaryColor: new ColorFactory('rgba(55,23,34,1)'),
    secondaryColor: new ColorFactory('rgba(187,171,155,1)'),
    backgroundColor: new ColorFactory('rgba(238,225,212,1)'),
    menuBackgroundColor: new ColorFactory('rgba(238,225,212,1)'),
    buttonRadius: 0,
    photoRadius: 0,
    titleFont: 'Noto Serif Display',
    buttonFont: 'Fira Sans',
    paymentBackgroundColor: new ColorFactory('rgba(55,23,34,1)'),
  },
  'Twisted Ribbon': {
    primaryColor: new ColorFactory('rgba(119,110,167,1)'),
    secondaryColor: new ColorFactory('rgba(235,185,212,1)'),
    backgroundColor: new ColorFactory('rgba(255,255,255,1)'),
    menuBackgroundColor: new ColorFactory('rgba(252,245,249,1)'),
    buttonRadius: 999,
    photoRadius: 24,
    titleFont: 'Pacifico',
    buttonFont: 'Lato',
    paymentBackgroundColor: new ColorFactory('rgba(119,110,167,1)'),
  },
  'Lucid Dream': {
    primaryColor: new ColorFactory('rgba(18,23,56,1)'),
    secondaryColor: new ColorFactory('rgba(209,124,120,1)'),
    backgroundColor: new ColorFactory('rgba(255,255,255,1)'),
    menuBackgroundColor: new ColorFactory('rgba(255,237,236,1)'),
    buttonRadius: 0,
    photoRadius: 0,
    titleFont: 'Montserrat',
    buttonFont: 'Open Sans',
    paymentBackgroundColor: new ColorFactory('rgba(18,23,56,1)'),
  },
  'First Edition': {
    primaryColor: new ColorFactory('rgba(254,185,4,1)'),
    secondaryColor: new ColorFactory('rgba(12,72,94,1)'),
    backgroundColor: new ColorFactory('rgba(12,72,94,1)'),
    menuBackgroundColor: new ColorFactory('rgba(9,59,78,1)'),
    buttonRadius: 0,
    photoRadius: 0,
    titleFont: 'Caudex',
    buttonFont: 'Open Sans',
    paymentBackgroundColor: new ColorFactory('rgba(254,185,4,1)'),
  },
  Rosebud: {
    primaryColor: new ColorFactory('rgba(183,212,213,1)'),
    secondaryColor: new ColorFactory('rgba(5,49,97,1)'),
    backgroundColor: new ColorFactory('rgba(0,31,65,1)'),
    menuBackgroundColor: new ColorFactory('rgba(0,31,65,1)'),
    buttonRadius: 999,
    photoRadius: 24,
    titleFont: 'Noto Serif Display',
    buttonFont: 'Lato',
    paymentBackgroundColor: new ColorFactory('rgba(183,212,213,1)'),
  },
  'Old World': {
    primaryColor: new ColorFactory('rgba(145,168,207,1)'),
    secondaryColor: new ColorFactory('rgba(62,70,104,1)'),
    backgroundColor: new ColorFactory('rgba(245,249,255,1)'),
    menuBackgroundColor: new ColorFactory('rgba(245,249,255,1)'),
    buttonRadius: 999,
    photoRadius: 0,
    titleFont: 'Caudex',
    buttonFont: 'Lato',
    paymentBackgroundColor: new ColorFactory('rgba(145,168,207,1)'),
  },
  'Black Sea': {
    primaryColor: new ColorFactory('rgba(234,234,234,1)'),
    secondaryColor: new ColorFactory('rgba(32,33,33,1)'),
    backgroundColor: new ColorFactory('rgba(32,33,33,1)'),
    menuBackgroundColor: new ColorFactory('rgba(32,33,33,1)'),
    buttonRadius: 0,
    photoRadius: 0,
    titleFont: 'Cormorant Garamond',
    buttonFont: 'Cormorant Garamond',
    paymentBackgroundColor: new ColorFactory('rgba(248,248,248,1)'),
  },
  'Terracotta Cornice': {
    primaryColor: new ColorFactory('rgba(235,220,214,1)'),
    secondaryColor: new ColorFactory('rgba(207,134,108,1)'),
    backgroundColor: new ColorFactory('rgba(152,94,69,1)'),
    menuBackgroundColor: new ColorFactory('rgba(152,94,69,1)'),
    buttonRadius: 0,
    photoRadius: 0,
    titleFont: 'Noto Serif Display',
    buttonFont: 'Noto Serif Display',
    paymentBackgroundColor: new ColorFactory('rgba(253,240,236,1)'),
  },
  'Divine Purity': {
    primaryColor: new ColorFactory('rgba(144,144,118,1)'),
    secondaryColor: new ColorFactory('rgba(255,255,255,1)'),
    backgroundColor: new ColorFactory('rgba(244,244,242,1)'),
    menuBackgroundColor: new ColorFactory('rgba(255,255,255,1)'),
    buttonRadius: 0,
    photoRadius: 0,
    titleFont: 'Cormorant Garamond',
    buttonFont: 'Cormorant Garamond',
    paymentBackgroundColor: new ColorFactory('rgba(179,179,168,1)'),
  },
};

type Presets = Required<ColorPickerProps>['presets'][number];
const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map<Presets>(([label, colors]) => ({ label, colors }));

const GalleryThemeForm = <T extends EditGalleryThemeFormValues>({
  values,
  isSubmitting,
  initialValues,
  setValues,
}: FormikProps<T> & {
  galleryId?: number;
}) => {
  const { t } = useContext(LocalizationContext);
  const { styles } = useStyles();

  const [selectedPreview, setSelectedPreview] = useState<'gallery' | 'product' | 'cart'>('gallery');

  const { data } = useQuery(GET_COMPANY);

  const companyColor = data?.getCompany?.primaryColor;
  const complimentaryColor = companyColor && getComplimentary(new ColorFactory(companyColor)).toHexString();

  const presets =
    companyColor && complimentaryColor
      ? genPresets({
          [t('app.common.company')]: generate(companyColor),
          [t('app.common.complimentary')]: generate(complimentaryColor),
        })
      : undefined;

  return (
    <Form layout="vertical">
      <Flex gap="middle" className={styles.container}>
        <div className={styles.formContainer}>
          <div>
            <Form.Item name="preset" label={t('app.gallery.tabs.theme', { count: 2 })}>
              <SelectAnt
                options={sortBy(Object.keys(THEME_PRESETS)).map(presetName => ({
                  label: (
                    <Flex gap={4} align="center" justify="flex-start">
                      <div
                        className={styles.colorPreview}
                        style={{
                          backgroundColor: THEME_PRESETS[presetName].primaryColor.toRgbString(),
                        }}
                      />
                      <div
                        className={styles.colorPreview}
                        style={{
                          backgroundColor: THEME_PRESETS[presetName].secondaryColor.toRgbString(),
                        }}
                      />
                      {presetName}
                    </Flex>
                  ),
                  value: presetName,
                }))}
                onChange={(presetName: string) =>
                  setValues(oldValues => ({ ...oldValues, ...THEME_PRESETS[presetName] }))
                }
              />
            </Form.Item>
            <div className={styles.formContainer}>
              <Fieldset legend={t('app.common.color', { count: 2 })} className="Fieldset">
                <Form.Item name="primaryColor" required>
                  <ColorPicker
                    name="primaryColor"
                    presets={presets}
                    showText={() => t('app.common.primary')}
                    size="large"
                  />
                </Form.Item>
                <Form.Item name="secondaryColor" required>
                  <ColorPicker
                    name="secondaryColor"
                    presets={presets}
                    showText={() => t('app.common.secondary')}
                    size="large"
                  />
                </Form.Item>
                <Form.Item name="backgroundColor" required>
                  <ColorPicker
                    name="backgroundColor"
                    presets={presets}
                    showText={() => t('app.gallery.cover.backgroundColor')}
                    size="large"
                  />
                </Form.Item>
                <Form.Item name="menuBackgroundColor" required>
                  <ColorPicker
                    name="menuBackgroundColor"
                    presets={presets}
                    showText={() => `${t('app.common.menu')} - ${t('app.common.cart')}`}
                    size="large"
                  />
                </Form.Item>
                <Form.Item name="paymentBackgroundColor" required>
                  <ColorPicker
                    name="paymentBackgroundColor"
                    presets={presets}
                    showText={() => t('app.common.payment')}
                    size="large"
                  />
                </Form.Item>
              </Fieldset>
              <Fieldset legend={t('app.watermark.font.label')} className="Fieldset">
                <Form.Item label={t('app.common.title')} name="titleFont" required hasFeedback={false}>
                  <Select name="titleFont" size="large">
                    {FONTS.map(font => (
                      <Select.Option value={font} title={''}>
                        <span style={{ fontFamily: font }}>{font}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={t('app.common.button', { count: 2 })} name="buttonFont" required hasFeedback={false}>
                  <Select name="buttonFont" size="large">
                    {BUTTON_FONTS.map(font => (
                      <Select.Option value={font} title={''}>
                        <span style={{ fontFamily: font }}>{font}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Fieldset>
              <Fieldset legend={t('app.common.borderRadius')} className="Fieldset">
                <Form.Item
                  label={`${t('app.common.button', { count: 2 })} - ${t('app.common.box', { count: 2 })}`}
                  name="buttonRadius"
                  hasFeedback={false}
                >
                  <Select name="buttonRadius" size="large">
                    <Select.Option value={0} title={''}>
                      {t('app.common.square')}
                    </Select.Option>
                    <Select.Option value={8} title={''}>
                      {t('app.common.middle')}
                    </Select.Option>
                    <Select.Option value={999} title={''}>
                      {t('app.common.rounded')}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label={t('app.common.photos', { count: 2 })} name="photoRadius" hasFeedback={false}>
                  <Select name="photoRadius" size="large">
                    <Select.Option value={0} title={''}>
                      {t('app.common.square')}
                    </Select.Option>
                    <Select.Option value={8} title={''}>
                      {t('app.common.small')}
                    </Select.Option>
                    <Select.Option value={24} title={''}>
                      {t('app.common.large')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Fieldset>
            </div>
          </div>
        </div>
        <Flex vertical gap="small" flex={1} style={{ zIndex: 0 }}>
          <Flex justify="space-between" gap="middle">
            <Title level="h2">{t('app.common.preview')}</Title>
            <Flex gap="middle">
              <Button htmlType="reset" type="default" loading={isSubmitting} disabled={isEqual(initialValues, values)}>
                {t('app.common.reset')}
              </Button>
              <Button htmlType="submit" type="primary" loading={isSubmitting} disabled={isEqual(initialValues, values)}>
                {t('app.common.save')}
              </Button>
            </Flex>
          </Flex>
          <Radio.Group
            value={selectedPreview}
            onChange={e => setSelectedPreview(e.target.value)}
            buttonStyle="solid"
            optionType="button"
            options={[
              { label: t('app.common.gallery'), value: 'gallery', style: { flex: 1, textAlign: 'center' } },
              {
                label: t('app.common.products', { count: 1 }),
                value: 'product',
                style: { flex: 1, textAlign: 'center' },
              },
              { label: t('app.common.payment'), value: 'cart', style: { flex: 1, textAlign: 'center' } },
            ]}
            style={{
              display: 'flex',
            }}
          />
          {selectedPreview === 'gallery' && <GalleryPreview {...values} />}
          {selectedPreview === 'product' && <ProductPreview {...values} />}
          {selectedPreview === 'cart' && <CartPreview {...values} />}
        </Flex>
      </Flex>
    </Form>
  );
};

export interface EditGalleryThemePayload {
  values: EditGalleryThemeFormValues;
  formikBag: FormikBag<EditGalleryThemeFormProps, EditGalleryThemeFormValues>;
}

export interface EditGalleryThemeFormProps {
  onSubmit: (payload: EditGalleryThemePayload) => void;
  defaultValues: EditGalleryThemeFormValues;
  galleryId?: number;
}

const galleryThemeSchema = yup.object({
  id: yup.number().required(),
  primaryColor: yup.object<any>().required(),
  secondaryColor: yup.object<any>().required(),
  backgroundColor: yup.object<any>().required(),
  paymentBackgroundColor: yup.object<any>().required(),
  menuBackgroundColor: yup.object<any>().required(),
  buttonFont: yup.string().required(),
  titleFont: yup.string().required(),
  buttonRadius: yup.number().required(),
  photoRadius: yup.number().required(),
});

export const EditGalleryThemeForm = withFormik<EditGalleryThemeFormProps, EditGalleryThemeFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  enableReinitialize: true,
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: galleryThemeSchema,
})(GalleryThemeForm);
