import { gql } from 'Operations/__generated__';

export const GET_PHOTO_COMMENTS = gql(/* GraphQL */ `
  query GetPhotoComments($where: PhotoGetWhereType!, $commentsWhere: PhotoCommentsGetWhereType!) {
    getPhoto(where: $where) {
      ... on PhotoAdmin {
        id
        comments(where: $commentsWhere) {
          _count
          edges {
            ...CommentCoreFields
          }
        }
      }
    }
  }
`);
