import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { App, Button, Flex, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReplaceWithReference } from 'types/ModelConnection';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Title from 'Components/Atoms/Title';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Metrics } from 'Themes';
import styled, { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useCurrency } from 'Hooks/useCurrency';

import dayjs from 'Services/DayjsService';

import { GetCatalogProductsQuery } from 'Operations/__generated__/graphql';

import { ADD_FAVORITE_CATALOG_PRODUCT } from 'Operations/Mutations/Catalog/AddFavoriteCatalogProduct';
import { REMOVE_FAVORITE_CATALOG_PRODUCT } from 'Operations/Mutations/Catalog/RemoveFavoriteCatalogProduct';

interface Props {
  products: GetCatalogProductsQuery['getCatalog']['products']['edges'];
  catalogId: number | undefined;
  favoriteProducts: GetCatalogProductsQuery['getCatalog']['favoriteProducts'] | undefined;
  isLoading: boolean;
  total: number;
  paginationSize: number;
  onDelete?: (props: { ids: number[] }) => void;
  onShow?: (props: { id: number }) => void;
  onChange: (props: { page: number }) => void;
}

type FavoriteCallbackProps = { catalogId: number; productId: number };

const StyledContainer = styled(Flex)`
  margin-bottom: ${Metrics.smallMargin}px;
`;
const ProviderContainer = styled.p`
  display: flex;
  gap: ${Metrics.smallMargin}px;
  align-items: center;
`;

const ProductsTable = ({
  products,
  catalogId,
  favoriteProducts: favoriteProductsData,
  total,
  paginationSize,
  isLoading,
  onDelete,
  onChange,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);
  const { message } = App.useApp();
  const { formatCurrency } = useCurrency();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const isDigital = useMemo(() => products.some(p => p.category.isDigital), [products]);

  const [favoriteProducts, maxFavorites] = useMemo(
    () => [favoriteProductsData?.edges || [], favoriteProductsData?._maxFavorites || 0],
    [favoriteProductsData?._maxFavorites, favoriteProductsData?.edges],
  );

  const isFavorite = useCallback(
    (record: GetCatalogProductsQuery['getCatalog']['products']['edges'][0]) =>
      favoriteProducts.some(favorite => favorite.id === record.id),
    [favoriteProducts],
  );

  const [addFavorite] = useMutation(ADD_FAVORITE_CATALOG_PRODUCT);

  const [removeFavorite] = useMutation(REMOVE_FAVORITE_CATALOG_PRODUCT);
  const handleAddFavorite = useCallback(
    async ({ catalogId, productId }: FavoriteCallbackProps) => {
      try {
        await addFavorite({
          variables: {
            where: {
              catalogId,
            },
            data: {
              productId,
            },
          },
          update(cache, { data }) {
            const newItem = cache.identify({ id: productId, __typename: 'Product' });
            if (data?.addFavoriteCatalogProduct && newItem) {
              cache.modify<ReplaceWithReference<GetCatalogProductsQuery['getCatalog'], 'favoriteProducts'>>({
                id: cache.identify({ id: catalogId, __typename: 'Catalog' }),
                fields: {
                  favoriteProducts(existingProduct, { toReference }) {
                    const favoriteRef = toReference(newItem);
                    if ('_count' in existingProduct && favoriteRef) {
                      return {
                        ...existingProduct,
                        edges: existingProduct.edges.concat([favoriteRef]),
                        _count: existingProduct._count + 1,
                      };
                    }
                    return existingProduct;
                  },
                },
              });
            }
          },
        });

        message.success(t('app.product.addFavorite'));
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [addFavorite, t],
  );

  const handleRemoveFavorite = useCallback(
    async ({ catalogId, productId }: FavoriteCallbackProps) => {
      try {
        await removeFavorite({
          variables: {
            where: {
              catalogId,
            },
            data: {
              productId,
            },
          },
          update(cache) {
            cache.modify<ReplaceWithReference<GetCatalogProductsQuery['getCatalog'], 'favoriteProducts'>>({
              id: cache.identify({ id: catalogId, __typename: 'Catalog' }),
              fields: {
                favoriteProducts(existingProduct, { readField }) {
                  if ('_count' in existingProduct) {
                    return {
                      ...existingProduct,
                      edges: existingProduct.edges.filter(ref => readField('id', ref) !== productId),
                      _count: existingProduct._count - 1,
                    };
                  }
                  return existingProduct;
                },
              },
            });
          },
        });

        message.success(t('app.product.removeFavorite'));
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [removeFavorite, t],
  );

  const columns: ColumnsType<GetCatalogProductsQuery['getCatalog']['products']['edges'][0]> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 130,
      render: (_, record) => (
        <Flex gap="small">
          <RoundButton
            href={`/app/products/${record.id}`}
            iconSize={20}
            size="middle"
            icon="edit"
            tooltipTitle={t('app.common.edit')}
          />

          {!isDigital && !isFavorite(record) && favoriteProducts.length < maxFavorites && (
            <RoundButton
              onClick={() => {
                if (catalogId) {
                  const whereObject = {
                    catalogId,
                    id: catalogId,
                    productId: record.id,
                  };
                  handleAddFavorite(whereObject);
                }
              }}
              iconSize={20}
              size="middle"
              icon="star-outline"
              tooltipTitle={t('app.common.addToShopFavorite')}
            />
          )}
          {!isDigital && isFavorite(record) && (
            <RoundButton
              onClick={() => {
                if (catalogId) {
                  const whereObject = {
                    catalogId,
                    id: catalogId,
                    productId: record.id,
                  };
                  handleRemoveFavorite(whereObject);
                }
              }}
              iconSize={20}
              size="middle"
              icon="star-outline-off"
              tooltipTitle={t('app.common.removeFromShopFavorite')}
            />
          )}

          <Popconfirm
            title={
              t('app.confirm.remove') +
              (record.isIncluded || record.isMandatory ? ` ${t('app.confirm.deleteIncludedProduct')}` : '')
            }
            onConfirm={() => {
              onDelete && onDelete({ ids: [record.id] });
            }}
          >
            <RoundButton
              iconSize={20}
              size="middle"
              icon="delete"
              tooltipTitle={t('app.catalog.removeProduct')}
              danger
            />
          </Popconfirm>
        </Flex>
      ),
    },
    {
      title: t('app.common.name'),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (_, record) =>
        !isDigital && isFavorite(record) ? (
          <Flex gap={4} align="center">
            <Icon name={'star'} color="#FFD200" size={18} />
            <span>{record.name}</span>
          </Flex>
        ) : (
          <span>{record.name}</span>
        ),
    },
    isDigital
      ? {}
      : {
          title: t('app.common.provider'),
          dataIndex: 'provider',
          key: 'provider',
          render: (_, record) =>
            record.contact?.displayName ? (
              <ProviderContainer>
                <RoundButton
                  iconSize={20}
                  size="middle"
                  icon="delivery"
                  tooltipTitle={t('app.common.transportFee')}
                  href={`/app/contacts/${record.contact.id}/transport-fees`}
                />
                {record.contact?.displayName}
              </ProviderContainer>
            ) : (
              '-'
            ),
        },
    {
      title: t('app.products.providerPrice'),
      dataIndex: 'providerPrice',
      key: 'providerPrice',
      width: 150,
      render(providerPrice) {
        return providerPrice
          ? `${formatCurrency(providerPrice)} / ${t('app.common.unit', { count: 1 }).toLowerCase()}`
          : '/';
      },
    },
    {
      title: t('app.products.myPrice'),
      dataIndex: ['pricingPlanMeta', 'startingPrice'],
      key: 'startingPrice',
      render(startingPrice, record) {
        const count = record.productOptionCategories.reduce(
          (sum, { productOptions }) => sum + productOptions.length,
          0,
        );

        return startingPrice
          ? `${t('app.products.startingPrice', {
              price: formatCurrency(startingPrice),
            })} (${count} ${t('app.products.options', {
              count,
            }).toLowerCase()})`
          : '/';
      },
    },
    {
      title: t('app.common.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => dayjs(text).format('LLL'),
    },
  ];

  const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  }, []);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [products]);

  return (
    <>
      {selectedRowKeys.length > 0 ? (
        <StyledContainer align="center" gap="middle">
          <Title level="h6">
            {t('app.products.selected', {
              count: selectedRowKeys.length,
            }).toLowerCase()}
          </Title>
          <Popconfirm
            title={t('app.catalog.removeProduct')}
            onConfirm={() => {
              onDelete && onDelete({ ids: selectedRowKeys.map(key => parseInt(key.toString(), 10)) });
            }}
          >
            <Button>{t('app.catalog.removeProduct')}</Button>
          </Popconfirm>
        </StyledContainer>
      ) : (
        <Title level="h6" spaced>{`${total} ${t('app.common.products', {
          count: total,
        }).toLowerCase()}`}</Title>
      )}
      <Table
        rowKey="id"
        className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()} ant-table--bordered`}
        rowSelection={{
          type: 'checkbox',
          onChange: onSelectChange,
          selectedRowKeys,
        }}
        size="small"
        loading={isLoading}
        dataSource={products}
        columns={columns}
        scroll={{ x: true }}
        pagination={{
          total,
          pageSize: paginationSize,
          showSizeChanger: false,
          position: ['bottomCenter'],
        }}
        onChange={pagination => {
          onChange({
            page: pagination.current ? pagination.current : 1,
          });
        }}
      />
    </>
  );
};

export default ProductsTable;
