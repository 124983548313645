import { gql } from 'Operations/__generated__';

export const EMAIL_CONFIG = gql(/* GraphQL */ `
  query GetEmailConfig {
    getCompany {
      id
      settings {
        useSmtp
        useGmail
        isGoogleConfigured
        smtp {
          host
          user
          withSsl
        }
      }
    }
  }
`);
