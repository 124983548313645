import { gql } from 'Operations/__generated__';

export const UPDATE_MOLLIE_PAYMENT = gql(/* GraphQL */ `
  mutation UpdateMolliePayment($data: UserMolliePaymentInputType!) {
    updateMolliePayment(data: $data) {
      id
      molliePayment {
        status
      }
    }
  }
`);
