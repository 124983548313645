import { Switch } from 'antd';

import Text from 'Components/Atoms/Text';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

interface LabeledSwitchProps {
  label: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onCheckChanged: (check: boolean) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;

  padding: ${Metrics.smallMargin}px 0;
`;

const SwitchStyled = styled(Switch)`
  order: 1;
  max-width: fit-content;
  margin-right: 6px;
`;

const TextStyled = styled(Text)`
  flex: 1;
  order: 2;
  padding: 0;
  padding-right: 8px;
`;

const LabeledSwitch = ({ label, defaultChecked, onCheckChanged, checked }: LabeledSwitchProps) => (
  <Container>
    <SwitchStyled
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={checked => {
        onCheckChanged(checked);
      }}
      onClick={(checked, e) => {
        e.stopPropagation();
      }}
    />
    <TextStyled size="small" weight="bold" onClick={() => onCheckChanged(!checked)}>
      {label.toUpperCase()}
    </TextStyled>
  </Container>
);

export default LabeledSwitch;
