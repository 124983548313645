import { useContext } from 'react';

import { Card, Flex, Spin } from 'antd';
import { createStyles } from 'antd-style';

import Icon from 'Components/Atoms/Icon';

import { Colors } from 'Themes';

import { LocalizationContext } from 'i18n';

interface WatermarkCardProps {
  active?: boolean;
  isLoading?: boolean;
  onClick: ({ id }: { id: null }) => void;
  className?: string;
}

const { Meta } = Card;

const useStyles = createStyles(({ css, token }) => ({
  card: css`
    width: 274px;
    height: 251px;
    align-items: center;
  `,
  cover: css`
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 186px;
    background-color: ${token.colorFillTertiary};
  `,
  button: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 274px;
    padding: 0;
    border: 4px solid transparent;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border-radius: ${token.borderRadiusLG}px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
    transition: all ease-in-out 0.2s;

    &:hover {
      border: 4px solid ${token.colorPrimary};
    }

    &.active,
    &:active {
      box-shadow: none;
      border-color: ${token.colorPrimary};
    }
  `,
  spinStyled: css`
    position: absolute;
    z-index: 3;
    margin: auto;
  `,
}));

const NoWatermarkButton = ({ active, isLoading, onClick, className, ...props }: WatermarkCardProps) => {
  const { t } = useContext(LocalizationContext);
  const { styles, cx } = useStyles();

  return (
    <div {...props} className={cx(className, styles.button, active && 'active')} onClick={() => onClick({ id: null })}>
      {isLoading && <Spin className={styles.spinStyled} size="large" />}

      <Card
        bordered={false}
        className={styles.card}
        cover={
          <div className={styles.cover}>
            <Icon name="disable" size={88} color={Colors.grey} />
          </div>
        }
      >
        <Meta title={t('app.watermark.button.noWatermark')} />
      </Card>
    </div>
  );
};

export default NoWatermarkButton;
