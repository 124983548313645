import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, App, Spin } from 'antd';

import ContentContainer from 'Components/Atoms/ContentContainer';

import MollieCard from 'Components/Molecules/MollieStatusCard';

import { Images, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { MollieStatus } from 'Operations/__generated__/graphql';

import { ME } from 'Operations/Queries/User/Me';

import { DISABLE_MOLLIE_PAYMENT } from 'Operations/Mutations/User/DisableMolliePayment';
import { UPDATE_MOLLIE_PAYMENT } from 'Operations/Mutations/User/UpdateMolliePayment';

const MollieUrl = process.env.REACT_APP_MOLLIE_CONNECT_URL;

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  width: 67%;
`;

const MollieConnect = styled.img`
  display: block;
  width: 200px;
  height: 45px;
  cursor: pointer;
  margin-top: ${Metrics.smallMargin}px;
`;

const MollieStatusCard = styled(MollieCard)`
  width: 100%;
  margin-top: ${Metrics.smallMargin}px;
`;

const MollieTab = () => {
  const { message } = App.useApp();
  const { t, isInitialized } = useContext(LocalizationContext);

  const { data: currentUser, loading: isUserLoading } = useQuery(ME, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateMolliePayment] = useMutation(UPDATE_MOLLIE_PAYMENT);
  const [disableMolliePayment] = useMutation(DISABLE_MOLLIE_PAYMENT);

  const isMollieCodeSend = useRef(false);

  const updateMollie = useCallback(
    async ({ mollieCode }: { mollieCode: string }) => {
      try {
        isMollieCodeSend.current = true;

        await updateMolliePayment({
          variables: {
            data: {
              code: mollieCode,
            },
          },
        });

        message.success(t('app.message.payments.mollie.update.success'));
      } catch (error) {
        console.log(error);
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [t, updateMolliePayment],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mollieCode = urlParams.get('code');
    const stripeScope = urlParams.get('scope');

    if (mollieCode && !stripeScope && !isMollieCodeSend.current && isInitialized) {
      updateMollie({ mollieCode });
    }
  }, [updateMollie, isInitialized]);

  const handleGoToMollie = () => {
    if (MollieUrl) {
      window.location.assign(MollieUrl);
    } else {
      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  const handleDisableMollie = useCallback(async () => {
    try {
      await disableMolliePayment();

      message.success(t('app.message.payments.mollie.disable.success'));
    } catch (error) {
      console.log(error);
      message.error(t('app.message.error.somethingWentWrong'));
    }
  }, [disableMolliePayment, t]);

  return (
    <StyledTableContentContainer padding="baseMargin" shadow rounded>
      <Alert type="info" message={t('app.settings.payments.mollie.description')} />
      {isUserLoading && <Spin />}
      {!isUserLoading && currentUser?.me.molliePayment.status === MollieStatus.NOT_CONFIGURED && (
        <MollieConnect src={Images.mollieConnect} alt="Mollie Connect" onClick={handleGoToMollie} />
      )}
      {!isUserLoading && currentUser && currentUser?.me.molliePayment.status !== MollieStatus.NOT_CONFIGURED && (
        <MollieStatusCard status={currentUser.me.molliePayment.status} onDisableClick={handleDisableMollie} />
      )}
    </StyledTableContentContainer>
  );
};

export default MollieTab;
