import { gql } from 'Operations/__generated__';

export const SET_ORDER_STATUS = gql(/* GraphQL */ `
  mutation setOrderStatus($where: IdInput!, $data: SetOrderItemStatusDataType!) {
    setOrderStatus(where: $where, data: $data) {
      id
      ... on OrderAdmin {
        status
      }
    }
  }
`);
