import { useQuery } from '@apollo/client';

import { GET_CONTACT } from 'Operations/Queries/Contact/GetContact';

interface UseContactProps {
  id?: number;
}

const useContact = ({ id }: UseContactProps) => {
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(GET_CONTACT, {
    skip: !id,
    variables: {
      where: {
        id: id as number,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  return { contact: data?.getContact, isLoading, error };
};

export { useContact };
