import { Reference, TypePolicy } from '@apollo/client';

import { uniqBy } from 'lodash';

import { StrictTypedTypePolicies } from 'Operations/__generated__/apollo-helpers';
import { PhotoCharacteristic } from 'Operations/__generated__/graphql';

type GetKeyIdentifier = {
  galleryId?: number;
  folderId?: number | null;
  orderItemId?: number | null;
  picklistId?: number | null;
  characteristics?: PhotoCharacteristic[] | null;
  queryName: string;
};

export const getKeyIdentifier = ({
  galleryId,
  characteristics,
  folderId,
  picklistId,
  queryName,
  orderItemId,
}: GetKeyIdentifier) => {
  const cacheIdentifier =
    queryName + (galleryId ? `:${galleryId}` : '') + (picklistId ? `:picklist:${picklistId}` : '');

  if (folderId === undefined && (characteristics === undefined || (characteristics && characteristics.length === 0))) {
    return cacheIdentifier;
  }
  const folder = folderId !== undefined ? (folderId === null ? 'root' : `${folderId}`) : undefined;

  return `${cacheIdentifier}:${folder ? `folder:${folder}` : ''}${orderItemId ? `orderItemId:${orderItemId}` : ''}${
    characteristics ? `-${characteristics.toString()}` : ''
  }`;
};

const GalleryPolicy: StrictTypedTypePolicies['GalleryAdmin'] = {
  fields: {
    photos: {
      keyArgs: (variables, context) => {
        const { galleryId, folderId, characteristics, orderItemId, picklistId } = variables?.where || {};
        const queryName = context.field?.alias?.value || context.fieldName;

        return getKeyIdentifier({ galleryId, folderId, characteristics, orderItemId, picklistId, queryName });
      },
      merge(existing, incoming, { variables, readField, canRead }) {
        const { folderId, page } = variables?.where || {};
        let edges: Reference[] = [];
        // Check if there is less incoming photos than existing photos to remove the extra photos or force incoming for first page
        if (!existing || incoming._count < existing._count || page === 1) {
          edges = incoming.edges || [];
        } else {
          edges = uniqBy([...(existing?.edges || []), ...(incoming?.edges || [])], '__ref');
        }

        return {
          ...incoming,
          edges:
            folderId === undefined
              ? edges.filter(canRead)
              : edges.filter(node => {
                  if (!canRead(node)) {
                    return false;
                  }

                  // filter out any photos that are in a folder that is not the current folder
                  const folder = readField<Reference>('folder', node);
                  const photoFolderId = readField<number>('id', folder) || null;
                  return folderId === photoFolderId;
                }),
        };
      },
    },
  },
};

export default GalleryPolicy;
