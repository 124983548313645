import { createStyles } from 'antd-style';

import { EditGalleryThemeFormValues } from 'Forms/GalleryTheme';

const smallFonts = ['Qwigley', 'Tangerine'];

export const useStyles = createStyles<EditGalleryThemeFormValues>(
  (
    { css, stylish, responsive },
    { primaryColor, secondaryColor, backgroundColor, titleFont, buttonFont, buttonRadius, photoRadius },
  ) => ({
    container: css`
      position: relative;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      position: relative;
      background-color: ${backgroundColor.toRgbString()};
      min-height: 75%;
      ${stylish.shadow};
      &:before {
        content: '';
        position: absolute;
        inset: 0;
        background-color: rgba(0, 0, 0, 0);
        z-index: 10;
      }

      animation: fadeIn 500ms;
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `,
    responsiveContainer: css`
      ${responsive.xl} {
        flex-direction: column;
      }
    `,
    subResponsiveContainer: css`
      ${responsive.lg} {
        flex-direction: column;
      }
    `,
    tag: css`
      position: absolute;
      right: 0;
      top: 16px;
      height: 22px;
      width: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${primaryColor.toRgbString()};
      border-radius: 50%;
      color: ${secondaryColor.toRgbString()};
      font-size: 10px;
    `,
    button: css`
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      font-family: ${buttonFont};
      line-height: 20px;
      padding: 8px 16px;

      border-width: 1px;
      border-style: solid;

      border-radius: ${buttonRadius}px;

      background-color: ${backgroundColor.toRgbString()};
      border-color: ${primaryColor.toRgbString()};
      color: ${primaryColor.toRgbString()};

      &.primary {
        background-color: ${primaryColor.toRgbString()};
        border-color: ${primaryColor.toRgbString()};
        color: ${secondaryColor.toRgbString()};
      }
      &.secondary {
        background-color: ${secondaryColor.toRgbString()};
        border-color: ${secondaryColor.toRgbString()};
        color: ${primaryColor.toRgbString()};
      }

      &.icon {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 42px;
        min-height: 42px;
        aspect-ratio: 1/1;
        padding: 0;

        &.small {
          min-width: 32px;
          min-height: 32px;
          width: 32px;
          height: 32px;
        }
      }
    `,
    imageContainer: css`
      img {
        overflow: hidden;
        border-radius: ${photoRadius}px;
        width: 100%;
      }
    `,
    title: css`
      font-weight: 800;
      font-size: ${smallFonts.includes(titleFont) ? '36px' : '24px'};
      font-family: ${titleFont};
      text-align: center;
      width: 100%;
      color: ${primaryColor.toRgbString()};

      &.left {
        text-align: left;
      }

      &.secondary {
        color: ${secondaryColor.toRgbString()};
      }
    `,
  }),
);
