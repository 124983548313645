import React from 'react';

import Container from 'Components/Atoms/Container';
import Layout from 'Components/Atoms/Layout';

import HeaderSkeleton from 'Components/Molecules/Skeletons/HeaderSkeleton';
import StatCardsSkeleton from 'Components/Molecules/Skeletons/StatCardsSkeleton';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

const GalleryContainer = styled(Container)`
  flex: 1;
`;

const RowContainer = styled(Container)`
  width: 100%;
  padding: ${Metrics.baseMargin}px 0;
`;

const DashboardIndexSkeleton = () => (
  <>
    <HeaderSkeleton />
    <Layout>
      <GalleryContainer direction="column">
        <RowContainer>
          <StatCardsSkeleton />
        </RowContainer>
      </GalleryContainer>
    </Layout>
  </>
);

export default DashboardIndexSkeleton;
