import React, { useContext } from 'react';

import { Tabs } from 'antd';

import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';

import MyActivityCompanyTab from 'Pages/App/Settings/MyActivity/Tabs/MyActivityCompanyTab';
import MyActivityOrderTab from 'Pages/App/Settings/MyActivity/Tabs/MyActivityOrderTab';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  flex: 1;
  margin-top: ${Metrics.smallMargin}px;
`;

const StyledTabPane = styled(TabPane)`
  padding: ${Metrics.smallMargin}px ${Metrics.baseMargin}px;
`;

const MyActivity = () => {
  const { t } = useContext(LocalizationContext);

  return (
    <>
      <Header
        title={t('app.settings.myActivity')}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.settings'), url: '/app/settings/my-activity' },
          { text: t('app.settings.myActivity') },
        ]}
      />
      <Layout>
        <StyledTabs defaultActiveKey="1">
          <StyledTabPane tab={t('app.common.company')} key="1">
            <MyActivityCompanyTab />
          </StyledTabPane>
          <StyledTabPane tab={t('app.common.order', { count: 2 })} key="2">
            <MyActivityOrderTab />
          </StyledTabPane>
        </StyledTabs>
      </Layout>
    </>
  );
};

export default MyActivity;
