import { gql } from 'Operations/__generated__';

export const GET_PAYMENT_TYPES = gql(/* GraphQL */ `
  query GetPaymentTypes {
    getPaymentTypes {
      _count
      edges {
        id
        name
      }
    }
  }
`);
