import React, { useContext, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { Alert, App, Button, Empty } from 'antd';

import { orderBy } from 'lodash';

import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import NoWatermarkButton from 'Components/Molecules/Buttons/NoWatermarkButton';
import WatermarkButton from 'Components/Molecules/Buttons/WatermarkButton';
import WatermarksTabSkeleton from 'Components/Molecules/Skeletons/Gallery/WatermarksTabSkeleton';

import { NewWatermarkFormPayload } from 'Forms/Watermark';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import { getRGBString } from 'Helpers/TransformColor';

import {
  GalleryAppearance,
  GridSize,
  WatermarkMode,
  WatermarkType
} from 'Operations/__generated__/graphql';

import { GET_WATERMARKS } from 'Operations/Queries/Watermark/GetWatermarks';

import { UPDATE_GALLERY } from 'Operations/Mutations/Gallery/UpdateGallery';
import { SET_GALLERY_PRESET_WATERMARK } from 'Operations/Mutations/GalleryPreset/SetGalleryPresetWatermark';
import { CREATE_WATERMARK } from 'Operations/Mutations/Watermark/CreateWatermark';

const WatermarksLayout = styled(Layout)`
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: ${Metrics.smallMargin}px;
`;

const Watermark = styled(WatermarkButton)`
  margin: ${Metrics.smallMargin}px;
`;

const NoWatermark = styled(NoWatermarkButton)`
  margin: ${Metrics.smallMargin}px;
`;

const CreateButtonInContent = styled(Button)`
  width: fit-content;
  margin: 0 auto;
  margin-top: ${Metrics.smallMargin}px;
`;

const PHOTOS_COUNT_LIMIT = 10000;

const GalleryWatermarksTab = ({
  galleryId,
  presetId,
  galleryPhotoCount,
  currentWatermarkId,
  currentWatermarkMode,
  galleryAppearance,
}: {
  galleryId?: number;
  presetId?: number;
  galleryPhotoCount?: number;
  currentWatermarkId: number | null;
  currentWatermarkMode?: WatermarkMode | null;
  galleryAppearance: DeepPartial<GalleryAppearance>;
}) => {
  const { t } = useContext(LocalizationContext);
  const { openModal, closeModal } = useModals();
  const { message } = App.useApp();

  const [selectedWatermarkId, setSelectedWatermarkId] = useState<number | null>(null);

  const { data, loading: isLoading } = useQuery(GET_WATERMARKS, {
    variables: {
      where: {
        page: 1,
        perPage: 100,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [updateGallery, { loading: isUpdateGalleryLoading }] = useMutation(UPDATE_GALLERY);
  const [setGalleryPresetWatermark, { loading: isUpdatePresetLoading }] = useMutation(SET_GALLERY_PRESET_WATERMARK);

  const [createWatermark] = useMutation(CREATE_WATERMARK, {
    refetchQueries: [
      {
        query: GET_WATERMARKS,
        variables: {
          where: {
            page: 1,
            perPage: 100,
          },
        },
      },
    ],
  });

  const canApplyWatermark = useMemo(() => (galleryPhotoCount || 0) < PHOTOS_COUNT_LIMIT, [galleryPhotoCount]);

  if (!data || isLoading) {
    return <WatermarksTabSkeleton />;
  }

  const handleOnClickWatermark = async ({ id, mode }: { id: number | null; mode?: WatermarkMode }) => {
    try {
      if (id !== currentWatermarkId || mode !== currentWatermarkMode) {
        setSelectedWatermarkId(id);

        if (galleryId) {
          await updateGallery({
            variables: {
              where: { id: galleryId },
              data: {
                watermarkId: id,
                watermarkMode: mode,
              },
            },
          });

          message.success(t('app.message.gallery.update.success'));
        }
        if (presetId) {
          await setGalleryPresetWatermark({
            variables: {
              where: { id: presetId },
              data: {
                watermarkId: id,
                watermarkMode: mode,
              },
            },
          });

          message.success(t('app.message.galleryPreset.update.success'));
        }
      }
    } catch (error) {
      console.log(error);

      message.error(t('app.message.error.somethingWentWrong'));
    }
  };

  const handleCreateOnSubmit = async ({
    values: { textColor, backgroundTextColor, ...values },
    formikBag,
  }: NewWatermarkFormPayload) => {
    try {
      await createWatermark({
        variables: {
          data: {
            ...values,
            textColor: textColor ? getRGBString(textColor) : undefined,
            backgroundTextColor: backgroundTextColor ? getRGBString(backgroundTextColor) : undefined,
          },
          where: { galleryId, presetId },
        },
      });

      message.success(t('app.message.watermark.create.success'));
      formikBag.setSubmitting(false);
      closeModal();
    } catch (error) {
      console.log(error);

      message.error(t('app.message.error.somethingWentWrong'));
      formikBag.setSubmitting(false);
    }
  };

  const handleOnCreateClick = () => {
    openModal('WATERMARK', {
      name: 'new',
      onSubmit: handleCreateOnSubmit,
    });
  };

  const isUpdateLoading = isUpdateGalleryLoading || isUpdatePresetLoading;

  return (
    <>
      {!canApplyWatermark && !!galleryId && (
        <Alert
          type="warning"
          style={{ whiteSpace: 'pre-wrap' }}
          message={t('app.gallery.watermark.disabled', { count: PHOTOS_COUNT_LIMIT })}
        />
      )}

      {canApplyWatermark && data.getWatermarks.edges.length === 0 && (
        <>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          <Text align="center">{t('app.gallery.watermark.noData.text')}</Text>
          <CreateButtonInContent onClick={handleOnCreateClick} key="add" size="large" type="primary">
            <Icon name="add" size={18} />
            {t('app.watermarks.noData.create')}
          </CreateButtonInContent>
        </>
      )}

      {canApplyWatermark && data.getWatermarks.edges.length > 0 && (
        <>
          <Title level="h5">{t('app.gallery.watermark.title')}</Title>
          {galleryAppearance.size === GridSize.LARGE && (
            <Alert type="info" style={{ whiteSpace: 'pre-wrap' }} message={t('app.gallery.cover.largeAppearance')} />
          )}
          <WatermarksLayout>
            <NoWatermark
              onClick={handleOnClickWatermark}
              active={!isUpdateLoading && !currentWatermarkId}
              isLoading={selectedWatermarkId === null && isUpdateLoading}
            />
            {orderBy(data.getWatermarks.edges, 'createdAt', 'desc').map(watermark => (
              <Watermark
                key={watermark.id}
                id={watermark.id}
                active={!isUpdateLoading && watermark.id === currentWatermarkId}
                isLoading={selectedWatermarkId === watermark.id && isUpdateLoading}
                watermarkType={watermark.watermarkType}
                name={watermark.name}
                image={
                  watermark.watermarkType === WatermarkType.IMAGE && watermark?.asset?.assetMain?.downloadUrl
                    ? watermark.asset.assetMain.downloadUrl
                    : undefined
                }
                font={watermark.font || 'Fira Sans'}
                text={watermark.watermarkType === WatermarkType.TEXT && watermark.text ? watermark.text : undefined}
                textColor={watermark.textColor || undefined}
                repeat={watermark.repeat || undefined}
                isResized={watermark.isResized || true}
                size={watermark.size || undefined}
                opacity={watermark.opacity}
                position={watermark.position}
                withStroke={watermark.withStroke || false}
                strokeColor={watermark.strokeColor || '0,0,0,1'}
                withBackgroundText={watermark.withBackgroundText || false}
                backgroundTextColor={watermark.backgroundTextColor || '0,0,0,1'}
                onClick={handleOnClickWatermark}
                thumbsWatermarked={!(watermark.id === currentWatermarkId && currentWatermarkMode === WatermarkMode.WEB)}
              />
            ))}
          </WatermarksLayout>
        </>
      )}
    </>
  );
};

export default GalleryWatermarksTab;
