import { useContext } from 'react';

import { createStyles } from 'antd-style';

import Card, { CardProps } from 'Components/Atoms/Card';
import Title from 'Components/Atoms/Title';

import StatCard from 'Components/Molecules/Stats/StatCard';
import StorageCircle from 'Components/Molecules/StorageCircle';

import { Colors } from 'Themes';

import { LocalizationContext } from 'i18n';

interface StorageCardType extends CardProps {
  storage: number;
  storageMax: number;
  percent: number;
  galleries: number;
  photos: number;
}

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    background: ${token.colorPrimary};
    background: ${token.storageCardBackground};
  `,
  header: css`
    margin-bottom: ${token.sizeSM}px;
    padding-bottom: ${token.sizeSM}px;
    border-bottom: 1px solid ${token.storageCardBorder};
  `,
  content: css`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    flex: 1;
  `,
  circleContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    flex: 1;
  `,
  statContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${token.sizeSM}px;
  `,
  stat: css`
    flex: 1;
    width: 100px;
    min-height: 100px;
    background-color: rgba(255, 255, 255, 0.4) !important;
    border: 1px solid rgba(255, 255, 255, 0.6) !important;
    border-radius: ${token.borderRadius}px !important;
  `,
}));

const StorageCard = ({ storage, storageMax, percent, galleries, photos, className, ...rest }: StorageCardType) => {
  const { t } = useContext(LocalizationContext);
  const { styles, cx } = useStyles();

  return (
    <Card padding="smallMargin" shadow rounded className={cx(styles.container, className)} {...rest}>
      <div className={styles.header}>
        <Title level="h5" color={Colors.white}>
          {t('app.common.storage')}
        </Title>
      </div>
      <div className={styles.content}>
        <div>
          <StorageCircle storage={storage} storageMax={storageMax} percent={percent} />
        </div>
        <div className={styles.statContainer}>
          <StatCard className={styles.stat} small label={t('app.common.galleries')} value={galleries} />
          <StatCard className={styles.stat} small label={t('app.common.photos')} value={photos} />
        </div>
      </div>
    </Card>
  );
};

export default StorageCard;
