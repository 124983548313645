import React, { useCallback, useContext, useMemo, useState } from 'react';

import { DeepPartial } from '@apollo/client/utilities';

import Card, { CardProps } from 'Components/Atoms/Card';
import Container from 'Components/Atoms/Container';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import MostViewedPhotosSleketon from 'Components/Molecules/Skeletons/MostViewedPhotosSleketon';

import { Colors } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { usePhotoDrawer } from 'Hooks/PhotoDrawerProvider';

import { PhotoAdmin } from 'Operations/__generated__/graphql';

interface Props extends CardProps {
  photos: DeepPartial<PhotoAdmin>[];
  isLoading?: boolean;
  isGalleryButtonVisible?: boolean;
}

const StyledContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  overflow: hidden;
`;

const StyledSquare = styled.div<{ isHidden?: boolean }>`
  position: relative;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ isHidden }) => (isHidden ? 0.3 : 1)};

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  cursor: pointer;
`;

const MostViewedPhotos = ({ photos, isLoading, isGalleryButtonVisible, ...props }: Props) => {
  const { t } = useContext(LocalizationContext);

  const [hoveredPhoto, setHoveredPhoto] = useState<DeepPartial<PhotoAdmin> | undefined>();

  const { openDrawer } = usePhotoDrawer();

  const mostViewedPhoto = useMemo(() => photos.slice(0, 1)[0], [photos]);
  const otherPhotos = useMemo(() => photos.slice(1, 5), [photos]);
  const totalCount = useMemo(() => photos.reduce((acc, photo) => acc + (photo?.viewCount || 0), 0), [photos]);

  const handlePhotoClick = useCallback(
    (photo: DeepPartial<PhotoAdmin>) => {
      openDrawer({ photo, isLoading: false, isGalleryButtonVisible });
    },
    [openDrawer, isGalleryButtonVisible],
  );

  if (isLoading) {
    return (
      <Card shadow rounded onMouseLeave={() => setHoveredPhoto(undefined)}>
        <MostViewedPhotosSleketon />
      </Card>
    );
  }

  return (
    <Card shadow rounded padding="baseMargin" onMouseLeave={() => setHoveredPhoto(undefined)} {...props}>
      <Title level="h1">{hoveredPhoto?.viewCount || totalCount}</Title>
      <Text color={Colors.grey} weight="black" space="smallMargin" numberOfLines={1}>
        {hoveredPhoto
          ? t('app.gallery.mostViewedPhotos.photoText', { name: hoveredPhoto.name })
          : t('app.gallery.mostViewedPhotos.baseText')}
      </Text>
      {totalCount > 0 && (
        <StyledContainer direction="row">
          <StyledSquare
            key={mostViewedPhoto.id}
            isHidden={!!hoveredPhoto && hoveredPhoto.id !== mostViewedPhoto.id}
            onMouseEnter={() => setHoveredPhoto(mostViewedPhoto)}
            onClick={() => handlePhotoClick(mostViewedPhoto)}
          >
            <StyledImage
              src={mostViewedPhoto?.asset?.noWmThumbLarge?.downloadUrl}
              alt={mostViewedPhoto.name}
              loading="lazy"
            />
          </StyledSquare>
          <StyledContainer direction="row">
            {otherPhotos.map(photo => (
              <StyledSquare
                key={photo.id}
                isHidden={!!hoveredPhoto && hoveredPhoto.id !== photo.id}
                onMouseEnter={() => setHoveredPhoto(photo)}
                onClick={() => handlePhotoClick(photo)}
              >
                <StyledImage src={photo?.asset?.noWmThumbSmall?.downloadUrl} alt={photo.name} loading="lazy" />
              </StyledSquare>
            ))}
          </StyledContainer>
        </StyledContainer>
      )}
    </Card>
  );
};

export default MostViewedPhotos;
