import { useContext } from 'react';

import { Flex } from 'antd';

import { camelCase } from 'lodash';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import { Colors, FontSize, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const TextStyled = styled(Text)`
  margin-top: ${Metrics.smallMargin}px;
  text-align: center;
`;

interface ErrorDisplayProps {
  errorCode: string;
}

const ErrorDisplay = ({ errorCode }: ErrorDisplayProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Flex vertical justify="center" align="center" flex={1}>
      <Icon name="error" size={FontSize.h1 * 2} color={Colors.danger} />
      <TextStyled weight="bold" size="subtitle">
        {t(`app.message.error.upload.${camelCase(errorCode)}.message`)}
      </TextStyled>
    </Flex>
  );
};

export default ErrorDisplay;
