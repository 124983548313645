import React, { useContext } from 'react';

import { Card, Popconfirm } from 'antd';

import Container from 'Components/Atoms/Container';
import Tag from 'Components/Atoms/Tag';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors, Images, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const CardStyled = styled(Card)`
  width: 274px;
`;

const PopConfirmBody = styled.div`
  max-width: 200px;
`;

const TagStyled = styled(Tag)`
  margin-left: ${Metrics.tinyMargin}px;
`;

interface ProductCardProps {
  id: number;
  name: string;
  isPreconfiguredProduct?: boolean;
  description?: string;
  previewAsset?: string;
  catalogsCount?: number;
  handleEditClick?: (params: { id: number }) => void;
  handleDeleteConfirm?: (params: { id: number }) => void;
  handleAddClick?: (params: { id: number }) => void;
  onClick?: (params: { id: number }) => void;
  isSelected?: boolean;
  size?: 'default' | 'small';
}

const { Meta } = Card;

const ProductCard = ({
  id,
  name,
  isPreconfiguredProduct = false,
  description,
  previewAsset,
  catalogsCount,
  handleEditClick,
  handleAddClick,
  handleDeleteConfirm,
  onClick,
  isSelected,
  ...props
}: ProductCardProps) => {
  const { t } = useContext(LocalizationContext);
  const cardActions = [];

  if (handleEditClick) {
    cardActions.push(
      <RoundButton
        icon="edit"
        tooltipTitle={t('app.common.edit')}
        onClick={() => {
          handleEditClick({ id });
        }}
      />,
    );
  }

  if (handleDeleteConfirm) {
    let title: React.ReactNode = t('app.confirm.delete');
    if (catalogsCount && catalogsCount > 0) {
      title = (
        <div>
          <div>
            <b>{t('app.confirm.delete')}</b>
          </div>
          <PopConfirmBody>{t('app.products.delete.usedByCatalogs', { count: catalogsCount })}</PopConfirmBody>
        </div>
      );
    }

    cardActions.push(
      <Popconfirm
        title={title}
        onConfirm={() => {
          if (handleDeleteConfirm) {
            handleDeleteConfirm({ id });
          }
        }}
      >
        <RoundButton icon="delete" tooltipTitle={t('app.common.delete')} danger />
      </Popconfirm>,
    );
  }

  if (handleAddClick) {
    cardActions.push(
      <RoundButton
        icon="add"
        tooltipTitle={t('app.actions.addToMyProducts')}
        onClick={() => {
          handleAddClick({ id });
        }}
      />,
    );
  }

  return (
    <CardStyled
      bordered={false}
      cover={
        previewAsset ? (
          <img src={previewAsset} draggable={false} alt={name} loading="lazy" />
        ) : (
          <img src={Images.productMissing} draggable={false} />
        )
      }
      draggable={false}
      actions={cardActions}
      hoverable={!!onClick}
      onClick={onClick ? () => onClick({ id }) : undefined}
      {...props}
    >
      <Meta
        avatar={
          isSelected !== undefined ? <RoundButton icon="check" type={isSelected ? 'primary' : 'default'} /> : undefined
        }
        title={
          isPreconfiguredProduct ? (
            <Container direction="row">
              {name} <TagStyled color={Colors.crmMain}>Fotostudio</TagStyled>
            </Container>
          ) : (
            name
          )
        }
        description={description}
      />
    </CardStyled>
  );
};

export default ProductCard;
