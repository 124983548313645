import { gql } from 'Operations/__generated__';

export const GET_GALLERY_ACCESS_CODE = gql(/* GraphQL */ `
  query GetGalleryAccessCode($where: GalleryGetWhereType!) {
    getGalleryAccessCode: getGallery(where: $where) {
      ... on GalleryAdmin {
        id
        accessCode
      }
    }
  }
`);
