import { gql } from 'Operations/__generated__';

export const CREATE_FOLDER = gql(/* GraphQL */ `
  mutation CreateFolder($data: FolderCreateDataType!, $where: FolderCreateWhereType!) {
    createFolder(data: $data, where: $where) {
      id
      name
      parentId
      galleryId
    }
  }
`);
