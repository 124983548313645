import { gql } from 'Operations/__generated__';

export const UPDATE_WATERMARK = gql(/* GraphQL */ `
  mutation UpdateWatermark($where: WatermarkUpdateWhereType!, $data: WatermarkCreateInputType!) {
    updateWatermark(where: $where, data: $data) {
      id
      name
      assetId
      watermarkType
      isResized
      size
      position
      opacity
      text
      textColor
      font
      withStroke
      strokeColor
      withBackgroundText
      backgroundTextColor
      createdAt
      updatedAt
      asset {
        assetMain {
          downloadUrl
        }
      }
    }
  }
`);
