import { gql } from 'Operations/__generated__';

export const GET_WATERMARKS = gql(/* GraphQL */ `
  query GetWatermarks($where: WatermarksGetWhereType!) {
    getWatermarks(where: $where) {
      _count
      edges {
        id
        name
        assetId
        watermarkType
        isResized
        size
        position
        repeat
        opacity
        text
        textColor
        font
        withStroke
        strokeColor
        withBackgroundText
        backgroundTextColor
        createdAt
        updatedAt
        galleries {
          _count
        }
        asset {
          assetMain {
            downloadUrl
          }
        }
      }
    }
  }
`);
