import { gql } from 'Operations/__generated__';

export const GET_COMPANY = gql(/* GraphQL */ `
  query GetCompany {
    getCompany {
      id
      name
      bookingUrl
      street
      zipcode
      city
      countryId
      vat
      iban
      primaryColor
      mobileMessageSenderId
      logoUrl
      genInvoice
      settings {
        mustReceiveOrder
      }
    }
  }
`);
