import { gql } from 'Operations/__generated__';

export const READ_NOTIFICATIONS = gql(/* GraphQL */ `
  mutation ReadNotifications($where: IdsInput!) {
    readNotifications(where: $where) {
      id
      isRead
    }
  }
`);
