import { Reference } from '@apollo/client';

import mergeList from 'Operations/Cache/Helpers/mergeList';
import readList from 'Operations/Cache/Helpers/readList';

import { StrictTypedTypePolicies } from 'Operations/__generated__/apollo-helpers';
import { GetPhotoCommentsQueryVariables } from 'Operations/__generated__/graphql';

const PhotoPolicy: StrictTypedTypePolicies['PhotoAdmin'] = {
  fields: {
    isWatermarked: {
      read: (_, { readField, toReference }) => {
        const gallery = readField<Reference>('galleryId');
        const galleryRef = toReference({
          __typename: 'GalleryAdmin',
          id: gallery,
        });
        const watermark = readField<Reference>('watermark', galleryRef);

        return !!watermark;
      },
    },
    comments: {
      keyArgs: false,
      merge(existing, incoming, { variables }) {
        const {
          commentsWhere: { page, perPage },
        } = (variables as GetPhotoCommentsQueryVariables) || {};

        return mergeList({ existing, incoming, page, perPage });
      },
      read(existing, { variables, storeFieldName, readField }) {
        const {
          commentsWhere: { page, perPage },
        } = (variables as GetPhotoCommentsQueryVariables) || {};

        return readList({
          readField,
          storeFieldName,
          existing,
          page,
          perPage,
          defaultOrderFields: ['createdAt'],
        });
      },
    },
  },
};

export default PhotoPolicy;
