import React from 'react';

import { Flex, Progress } from 'antd';
import { createStyles } from 'antd-style';

import TaskStatus from 'Components/Atoms/TaskStatus';
import Text from 'Components/Atoms/Text';

import { PhotoUploadStatus } from 'Components/Molecules/PhotoUploader/Types';

interface PhotoUploaderRowProps {
  fileUploadStatus: PhotoUploadStatus;
  rowIndex: number;
}

const useStyles = createStyles(({ css, token }) => ({
  photoUploaderRowContainer: css`
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${token.sizeSM}px;
    &.odd {
      background-color: ${token.colorBgLayout};
    }
    &.even {
      background-color: ${token.colorBgBase};
    }
  `,
  text: css`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.uploading {
      width: 138px;
    }
  `,
  leftPart: css`
    max-width: 100%;
    align-items: center;
  `,
  progress: css`
    flex: 1;
    align-items: center;
    width: 200px;
  `,
}));

const getTaskStatus = (fileUploadStatus: PhotoUploadStatus) => {
  switch (fileUploadStatus.status) {
    case 'UPLOADING':
      return 'InProgress';
    case 'SUCCESS':
      return 'Succeeded';
    case 'ERROR':
      return 'Failed';
    case 'WAITING':
    default:
      return 'Waiting';
  }
};

const PhotoUploaderRow = ({ fileUploadStatus, rowIndex }: PhotoUploaderRowProps) => {
  const { styles, cx } = useStyles();

  return (
    <Flex className={cx(styles.photoUploaderRowContainer, rowIndex % 2 === 0 ? 'odd' : 'even')}>
      <Flex align="center" gap="small" className={styles.leftPart}>
        <TaskStatus status={getTaskStatus(fileUploadStatus)} />
        <Text className={cx(styles.text, fileUploadStatus.status.toLowerCase())} size="medium">
          {fileUploadStatus.file.name}
        </Text>
      </Flex>
      {fileUploadStatus.status === 'UPLOADING' && (
        <Progress className={styles.progress} percent={Math.ceil(fileUploadStatus.progress || 0)} size="small" />
      )}
    </Flex>
  );
};

export default PhotoUploaderRow;
