import { gql } from 'Operations/__generated__';

export const UPDATE_REDSYS_PAYMENT = gql(/* GraphQL */ `
  mutation UpdateRedsysPayment($data: UserRedsysPaymentInputType!) {
    updateRedsysPayment(data: $data) {
      id
      redsysPayment {
        status
      }
    }
  }
`);
