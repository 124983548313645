import React, { useCallback, useContext, useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { App, Spin } from 'antd';
import { EMAIL_CONFIG_TYPE } from 'types/EmailConfigType';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';

import { EmailConfigForm, EmailConfigFormPayload } from 'Forms/EmailConfig';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { EMAIL_CONFIG } from 'Operations/Queries/Company/GetEmailConfig';

import { SET_EMAIL_CONFIG_GOOGLE } from 'Operations/Mutations/Company/SetEmailConfigGoogle';
import { SET_EMAIL_CONFIG_NO_REPLY } from 'Operations/Mutations/Company/SetEmailConfigNoReply';
import { SET_EMAIL_CONFIG_SMTP } from 'Operations/Mutations/Company/SetEmailConfigSmtp';

const StyledTableContentContainer = styled(ContentContainer)`
  width: 67%;
  margin-top: ${Metrics.baseMargin}px;
`;

const EmailConfig = () => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();

  const { data, loading: isLoading } = useQuery(EMAIL_CONFIG, {
    fetchPolicy: 'network-only',
  });

  const [setEmailConfigGoogle] = useMutation(SET_EMAIL_CONFIG_GOOGLE);
  const [setEmailConfigNoReply] = useMutation(SET_EMAIL_CONFIG_NO_REPLY);
  const [setEmailConfigSmtp] = useMutation(SET_EMAIL_CONFIG_SMTP);

  const currentType = useMemo(() => {
    const companySettings = data?.getCompany?.settings;
    switch (true) {
      case companySettings?.useSmtp:
        return EMAIL_CONFIG_TYPE.SMTP;

      case companySettings?.useGmail:
        return EMAIL_CONFIG_TYPE.GOOGLE;

      default:
        return EMAIL_CONFIG_TYPE.NO_REPLY;
    }
  }, [data]);

  const { host, withSsl, user } = data?.getCompany?.settings?.smtp || {};

  const handleOnSubmit = useCallback(
    async ({ values: { type, refreshToken, ...values }, formikBag }: EmailConfigFormPayload) => {
      try {
        let successMessage = t('app.emailConfig.update.success');

        if (type === EMAIL_CONFIG_TYPE.SMTP) {
          await setEmailConfigSmtp({ variables: { data: values } });
        }

        if (type === EMAIL_CONFIG_TYPE.GOOGLE) {
          await setEmailConfigGoogle({ variables: { data: { refreshToken } } });

          if (refreshToken) {
            successMessage = t('auth.google.message.success');
          }
        }

        if (type === EMAIL_CONFIG_TYPE.NO_REPLY) {
          await setEmailConfigNoReply();
        }

        message.success(successMessage);
        formikBag.setSubmitting(false);
        formikBag.resetForm();
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [setEmailConfigGoogle, setEmailConfigNoReply, setEmailConfigSmtp, t],
  );

  const handleUnlinkGoogle = () => {
    setEmailConfigGoogle({ variables: { data: { refreshToken: '' } } });
  };

  return (
    <>
      <Header
        title={t('app.settings.emailConfig.title')}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.settings'), url: '/app/settings/my-activity' },
          { text: t('app.settings.emailConfig.title') },
        ]}
      />
      <Layout>
        <StyledTableContentContainer padding="baseMargin" shadow rounded>
          {isLoading || !data?.getCompany?.settings ? (
            <Spin />
          ) : (
            <EmailConfigForm
              onSubmit={handleOnSubmit}
              defaultValues={{
                type: currentType,
                host: host || '',
                withSsl: withSsl || false,
                user: user || '',
                password: '',
              }}
              isGoogleConfigured={!!data.getCompany.settings.isGoogleConfigured}
              handleUnlinkGoogle={handleUnlinkGoogle}
            />
          )}
        </StyledTableContentContainer>
      </Layout>
    </>
  );
};

export default EmailConfig;
