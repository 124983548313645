import { useContext, useEffect } from 'react';

import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';

import { SaveButtonConfig } from 'Pages/App/Products/ProductsShow/SaveButtonType';

import PricingPlanFormContent from 'Forms/PricingPlan/PricingPlanFormContent';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

import { PricingPlanType } from 'Operations/__generated__/graphql';

export interface NewPricingPlanItem {
  quantity: number;
  price: number;
  additionalPrice: number | null;
}

export interface EditPricingPlanItem extends NewPricingPlanItem {
  id?: number;
  grossPrice?: number;
}

export interface NewPricingPlanFormValues {
  type: PricingPlanType;
  items: NewPricingPlanItem[];
  wholeGalleryPrice?: number | null;
  permitAdditionalItems: boolean;
  withWholeGalleryPrice: boolean;
}

export interface EditPricingPlanFormValues extends Omit<NewPricingPlanFormValues, 'permitAdditionalItems'> {
  id: number;
  items: EditPricingPlanItem[];
  permitAdditionalItems?: boolean;
  profitMarginPercent?: number;
}

interface PricingPlanFormProps {
  isReadOnly?: boolean;
  updateSubmitButton?: (config: SaveButtonConfig | null) => void;
  isFotostudioProduct?: boolean;
}

const PricingPlanForm = <T extends EditPricingPlanFormValues | NewPricingPlanFormValues>({
  values,
  setFieldValue,
  isReadOnly,
  isSubmitting,
  dirty,
  isValid,
  handleSubmit,
  updateSubmitButton,
  isFotostudioProduct,
}: FormikProps<T> & PricingPlanFormProps) => {
  const { t } = useContext(LocalizationContext);

  useEffect(() => {
    if (updateSubmitButton) {
      updateSubmitButton({
        onClick: handleSubmit,
        dirty,
        isValid,
        isSubmitting,
      });
    }
  }, [dirty, handleSubmit, isSubmitting, isValid, t, updateSubmitButton]);

  return (
    <Form layout="vertical">
      {(values as EditPricingPlanFormValues)?.profitMarginPercent !== undefined && (
        <Flex align="center">
          <Form.Item
            label={t('app.common.profitMarginPercent')}
            name="profitMarginPercent"
            required
            hasFeedback={false}
          >
            <Input
              name="profitMarginPercent"
              placeholder={t('app.common.profitMarginPercent')}
              type="number"
              size="large"
              suffix="%"
            />
          </Form.Item>
        </Flex>
      )}
      <PricingPlanFormContent
        values={values}
        setFieldValue={setFieldValue}
        isReadOnly={isReadOnly}
        isFotostudioProduct={isFotostudioProduct}
      />
      {!isReadOnly && !updateSubmitButton && (
        <Flex justify="center">
          <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
            {t('app.common.edit')}
          </Button>
        </Flex>
      )}
    </Form>
  );
};

export interface NewPricingPlanFormPayload {
  values: NewPricingPlanFormValues;
  formikBag: FormikBag<NewPricingPlanFormProps, NewPricingPlanFormValues>;
}

export interface NewPricingPlanFormProps extends PricingPlanFormProps {
  onSubmit: (payload: NewPricingPlanFormPayload) => void;
  defaultValues: NewPricingPlanFormValues;
}

export interface EditPricingPlanFormPayload {
  values: EditPricingPlanFormValues;
  formikBag: FormikBag<EditPricingPlanFormProps, EditPricingPlanFormValues>;
}

export interface EditPricingPlanFormProps extends PricingPlanFormProps {
  onSubmit: (payload: EditPricingPlanFormPayload) => void;
  defaultValues: EditPricingPlanFormValues;
  isReadOnly?: boolean;
}

const newPricingPlanItemSchema = yup.object({
  quantity: yup.number().min(1).required(),
  price: yup.number().moreThan(0).required(),
  additionalPrice: yup.number().nullable(),
});

const editPricingPlanItemSchema = newPricingPlanItemSchema.shape({ id: yup.number(), grossPrice: yup.number() });

const newPrincingPlanItemsSchema = yup.array().of(newPricingPlanItemSchema);
const editPrincingPlanItemsSchema = yup.array().of(editPricingPlanItemSchema);

const pricingPlanSchema = yup.object({
  type: yup.mixed<PricingPlanType>().required(),
  wholeGalleryPrice: yup.number().nullable(),
  permitAdditionalItems: yup.boolean(),
  withWholeGalleryPrice: yup.boolean(),
});

export const newPricingPlanSchema: yup.SchemaOf<NewPricingPlanFormValues> = pricingPlanSchema
  .shape({
    items: newPrincingPlanItemsSchema.min(1),
  })
  .defined();
const editPricingPlanSchema: yup.SchemaOf<EditPricingPlanFormValues> = pricingPlanSchema
  .shape({
    id: yup.number().required(),
    items: editPrincingPlanItemsSchema.min(1),
    profitMarginPercent: yup.number().min(1),
  })
  .defined();

export const NewPricingPlanForm = withFormik<NewPricingPlanFormProps, NewPricingPlanFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: newPricingPlanSchema,
  enableReinitialize: true,
})(PricingPlanForm);

export const EditPricingPlanForm = withFormik<EditPricingPlanFormProps, EditPricingPlanFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => {
    const additionalPrices = defaultValues.items.filter(
      i => i.additionalPrice !== undefined && i.additionalPrice !== null,
    );

    return { ...defaultValues, permitAdditionalItems: additionalPrices.length > 0 };
  },
  validationSchema: editPricingPlanSchema,
  enableReinitialize: true,
})(PricingPlanForm);
