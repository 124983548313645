import React, { useCallback, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { App, Card, Flex, Popconfirm } from 'antd';

import Tag from 'Components/Atoms/Tag';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useCurrency } from 'Hooks/useCurrency';

import dayjs from 'Services/DayjsService';

import { Invoice } from 'Operations/__generated__/graphql';

import { CONVERT_INVOICE } from 'Operations/Mutations/Invoice/ConvertReceipt';

import InfoItem from './InfoItem';

const MySpan = styled.span<{ paid: boolean }>`
  color: ${({ paid }) => (paid ? Colors.success : Colors.waiting)};
`;

interface InvoiceInfoCardProps {
  data: Invoice;
  onInvoicePaid: () => void;
}

const { Meta } = Card;

const InvoiceInfoCard = ({
  data: { id, url, ttcPrice, createdAt, paid, title, numero, isReceipt },
  onInvoicePaid,
}: InvoiceInfoCardProps) => {
  const { t } = useContext(LocalizationContext);
  const { formatCurrency } = useCurrency();
  const { openModal } = useModals();
  const { message } = App.useApp();

  const [convertReceipt, { loading: isConvertingInvoice }] = useMutation(CONVERT_INVOICE);

  const handleOpenPaymentModal = useCallback(
    ({ invoiceId, invoiceAmount }: { invoiceId: number; invoiceAmount: number }) => {
      openModal('PAYMENT', {
        invoiceId,
        invoiceAmount,
        onSubmit: onInvoicePaid,
      });
    },
    [onInvoicePaid, openModal],
  );

  return (
    <Card bordered={false}>
      <Meta
        title={
          <Flex justify="flex-start" align="center" gap="middle">
            {isReceipt ? (
              <Tag color={Colors.grey} size="medium">
                {numero}
              </Tag>
            ) : (
              <>
                <span className="ant-card-meta-title">#{numero}</span>
                <Tag color={Colors.grey} size="medium">
                  {t('app.common.invoices', { count: 1 })}
                </Tag>
              </>
            )}
          </Flex>
        }
        description={
          <>
            <InfoItem label={t('app.common.title')} text={title} />
            <InfoItem label={t('app.common.createdAt')} text={dayjs(createdAt).format('LLL')} />
            <InfoItem label={t('app.common.price')} text={`${formatCurrency(ttcPrice)}`} />
            <InfoItem
              label={t('app.common.paymentStatus')}
              text={<MySpan paid={paid}>{t(paid ? 'app.common.paid' : 'app.common.waitingForPayment')}</MySpan>}
            />
            <br />
            <Flex justify="flex-end" gap="middle">
              {url && (
                <a href={url} target="_blank">
                  <RoundButton icon="download" tooltipTitle={t('app.common.download')} />
                </a>
              )}
              <RoundButton
                icon="add"
                tooltipTitle={t('app.invoices.createPayment')}
                onClick={() => {
                  handleOpenPaymentModal({ invoiceId: id, invoiceAmount: ttcPrice });
                }}
              />
              {isReceipt && (
                <Popconfirm
                  title={t('app.invoices.convertConfirm')}
                  onConfirm={() => {
                    convertReceipt({
                      variables: { where: { id } },
                      onCompleted() {
                        message.success(t('app.invoices.convertSuccess'));
                      },
                    });
                  }}
                >
                  <RoundButton
                    type="primary"
                    icon="transform-file"
                    tooltipTitle={t('app.invoices.convert')}
                    disabled={isConvertingInvoice}
                  />
                </Popconfirm>
              )}
            </Flex>
          </>
        }
      />
    </Card>
  );
};

export default InvoiceInfoCard;
