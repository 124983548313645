import { gql } from 'Operations/__generated__';

export const UPDATE_PRODUCT = gql(/* GraphQL */ `
  mutation UpdateProduct($where: IdInput!, $data: UpdateProductInput!) {
    updateProduct(where: $where, data: $data) {
      id
      name
      description
      previewAsset {
        downloadUrl
      }
      weight
      profitMarginPercent
      category {
        id
        name
      }
      contactId
      contact {
        ...ContactCoreFields
      }
      maxPhotoCount
      orderedByFotostudio
      photoSizes {
        width
        height
      }
      productOptionCategories(orderBy: { position: asc }) {
        id
        name
        position
        productOptions {
          id
          name
          pricingPlan {
            id
          }
          pricingPlanMeta {
            startingPrice
            startingQuantity
            hasMultiplePrices
            grossPrice
            grossQuantity
          }
          providerPrice
          providerDescription
          previewAsset {
            downloadUrl
          }
          hasPricingPlan
          hasPresetPricingPlan
        }
      }
    }
  }
`);
