import { useCallback, useContext, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Empty } from 'antd';

import Container from 'Components/Atoms/Container';
import Spinner from 'Components/Atoms/Spinner';
import Tag from 'Components/Atoms/Tag';
import Title from 'Components/Atoms/Title';

import Photo from 'Components/Molecules/Photo';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { AssetAdmin, PhotoAdmin, PhotoCharacteristic, PhotosSortField } from 'Operations/__generated__/graphql';

import { GET_ORDER_ITEM_PHOTOS } from 'Operations/Queries/Photo/GetOrderItemPhotos';

interface Props {
  title?: string;
  galleryId: number;
  orderItemId?: number;
  croppedUrl?: string;
  characteristics?: PhotoCharacteristic[];
}

const ListingContainer = styled(Container)`
  width: 100%;
  padding: 0 ${Metrics.baseMargin}px ${Metrics.baseMargin}px ${Metrics.baseMargin}px;
  margin-bottom: ${Metrics.baseMargin}px;
  justify-content: center;
`;

const PhotoListingTitle = styled(Title)`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-right: ${Metrics.tinyMargin}px;
  }
`;

const PER_PAGE = 50;

const PhotoListing = ({ galleryId, orderItemId, characteristics, croppedUrl, title }: Props) => {
  const { t } = useContext(LocalizationContext);

  const photosParams = useMemo(
    () => ({
      galleryId,
      orderItemId,
      page: 1,
      perPage: PER_PAGE,
      characteristics,
      sortField: PhotosSortField.NAME,
    }),

    [galleryId, orderItemId, characteristics],
  );

  const {
    data,
    loading: isPhotosLoading,
    fetchMore,
  } = useQuery(GET_ORDER_ITEM_PHOTOS, {
    variables: {
      where: photosParams,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  const photosPage = useMemo(() => {
    if (!data?.getOrderItemPhotos.edges.length) {
      return 1;
    }
    const loadedCount = data.getOrderItemPhotos.edges.length;

    const currentPage = Math.ceil(loadedCount / PER_PAGE);
    return loadedCount % PER_PAGE === 0 ? currentPage : currentPage - 1;
  }, [data?.getOrderItemPhotos.edges.length]);

  const hasFetchAllPhotos = useMemo(
    () => data?.getOrderItemPhotos._count === data?.getOrderItemPhotos.edges.length,
    [data],
  );

  const handleLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        where: {
          ...photosParams,
          page: photosPage + 1,
        },
      },
    });
  }, [fetchMore, photosPage, photosParams]);

  const photos = (data?.getOrderItemPhotos.edges as PhotoAdmin[]) || [];
  return (
    <>
      {title && (
        <PhotoListingTitle level="h3" color={Colors.black}>
          <span>{title}</span>
          {data && data?.getOrderItemPhotos._count > 0 && (
            <Tag size="regular" color="primary-gradient" textColor={Colors.white}>
              {data?.getOrderItemPhotos._count}
            </Tag>
          )}
        </PhotoListingTitle>
      )}
      {isPhotosLoading && (
        <ListingContainer>
          <Spinner size={32} />
        </ListingContainer>
      )}
      <ListingContainer>
        {photos.length === 0 && isPhotosLoading === false && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {photos.map(
          ({ asset, id, name }) =>
            (asset?.noWmThumbSmall || croppedUrl) && (
              <Photo croppedUrl={croppedUrl} key={id} id={id} name={name} asset={asset as AssetAdmin} />
            ),
        )}
      </ListingContainer>
      {!hasFetchAllPhotos && (
        <Container justify="center">
          <Button loading={isPhotosLoading} onClick={handleLoadMore}>
            {t('app.common.loadMore')}
          </Button>
        </Container>
      )}
    </>
  );
};

export default PhotoListing;
