import React, { useCallback, useContext, useMemo } from 'react';

import { App, Button, Flex } from 'antd';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import { LocalizationContext } from 'i18n';

type Props = {
  selectedRowsIds: number[];
  onArchive: (data: { ids: number[] }) => void;
  onDelete: (data: { ids: number[] }) => void;
  isUploadRunning?: boolean;
  galleryIdUpload?: number;
};

const GalleryBulkEdit = ({ selectedRowsIds, onArchive, onDelete, galleryIdUpload, isUploadRunning }: Props) => {
  const { t } = useContext(LocalizationContext);
  const { modal } = App.useApp();

  const isActionDisabled = useMemo(
    () => isUploadRunning && !!galleryIdUpload && selectedRowsIds.includes(galleryIdUpload),
    [isUploadRunning, galleryIdUpload, selectedRowsIds],
  );

  const handleArchive = useCallback(() => {
    onArchive({ ids: selectedRowsIds });
  }, [onArchive, selectedRowsIds]);

  const handleDelete = useCallback(() => {
    onDelete({ ids: selectedRowsIds });
  }, [onDelete, selectedRowsIds]);

  return (
    <Flex gap="large" align="center" justify="center">
      <Text>{t('app.common.selectionbar.selectCount', { count: selectedRowsIds.length })}</Text>
      <Flex gap="small" align="center">
        <Button
          type="default"
          size="large"
          disabled={isActionDisabled}
          onClick={() => {
            return modal.confirm({
              zIndex: 10000,
              title: t('app.gallery.archive.title', { count: selectedRowsIds.length }),
              content: t('app.gallery.archive.content'),
              okText: t('app.common.archive'),
              cancelText: t('app.common.cancel'),
              onOk: handleArchive,
            });
          }}
        >
          <Icon name="archive" />
          {t('app.common.archive')}
        </Button>
        <Button
          type="default"
          size="large"
          disabled={isActionDisabled}
          onClick={() => {
            return modal.confirm({
              zIndex: 10000,
              title: t('app.confirm.moveToTrash', { count: selectedRowsIds.length }),
              okText: t('app.gallery.moveToTrash'),
              cancelText: t('app.common.cancel'),
              onOk: handleDelete,
            });
          }}
          danger
        >
          <Icon name="delete" />
          {t('app.gallery.moveToTrash')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default GalleryBulkEdit;
