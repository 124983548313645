import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { makeReference, useMutation, useQuery } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { Alert, App, Button, Empty, Flex, Menu, Modal, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom';

import { camelCase, chain } from 'lodash';

import Container from 'Components/Atoms/Container';
import FilePickerInput from 'Components/Atoms/FilePickerInput';
import Icon from 'Components/Atoms/Icon';
import Tag from 'Components/Atoms/Tag';
import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import Photo from 'Components/Molecules/Photo';
import SelectionToolbar from 'Components/Molecules/SelectionToolbar';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useUpload } from 'Hooks/Upload';
import { usePhotosCount } from 'Hooks/usePhotosCount';

import getErrorCode from 'Helpers/GetErrorCode';

import {
  Contact,
  GetOrdersQuery,
  OrderAdmin,
  OrderCharacteristic,
  OrderItemStatus,
  PhotoAdmin,
  PhotoCharacteristic,
} from 'Operations/__generated__/graphql';

import { GET_ORDERS } from 'Operations/Queries/Order/GetOrders';

import { VALIDATE_ORDERS } from 'Operations/Mutations/Order/ValidateOrders';
import { DELETE_PHOTOS } from 'Operations/Mutations/Photo/DeletePhotos';

type SelectedMenu = 'edit' | 'edited' | 'validated';

interface RetouchTabOrder {
  id: number;
  contact: Contact | null;
  toEditTotal: number;
  retouchedTotal: number;
  isComplete: boolean;
  isPaid: boolean;
  isValidated: boolean;
  items: RetouchTabOrderItem[];
}

interface RetouchTabOrderItem {
  id: number;
  photos: DeepPartial<PhotoAdmin>[];
  productName: string;
  toEditTotal: number;
  retouchedTotal: number;
  isComplete: boolean;
  isPaid: boolean;
  isValidated: boolean;
}

export interface OnSendEmailProps {
  contactId?: number;
}

const StyledSidebar = styled.div`
  width: 180px;
  height: 100%;
  display: block;

  border-right: 1px solid ${Colors.border};
`;

const StyledContainer = styled(Container)`
  overflow: hidden;
  height: 100%;
`;

const PhotosContainer = styled(Container)`
  height: 100%;
  padding-bottom: ${Metrics.tinyMargin}px;
`;

const ActionsContainer = styled(Container)`
  margin-left: ${Metrics.baseMargin}px;
`;

const OrderContainer = styled.div`
  padding-top: ${Metrics.smallMargin}px;
  padding-left: ${Metrics.baseMargin}px;
  width: 100%;

  border-bottom: 1px solid ${Colors.border};
  border-left: 1px solid ${Colors.border};

  &:last-child {
    border-bottom: 0;
  }
  &:first-child {
    border-left: 0;
  }
`;

const GalleryPhotoContainer = styled(Container)`
  padding-bottom: ${Metrics.baseMargin}px;
`;

const ModalText = styled(Text)`
  flex: 1;
  margin-left: ${Metrics.tinyMargin}px;
`;

const ValidateOrdersContainer = styled(Container)`
  margin-top: ${Metrics.baseMargin}px;
  padding-left: ${Metrics.baseMargin}px;
`;

const ValidateTag = styled(Tag)`
  margin-bottom: ${Metrics.smallMargin}px;
`;

const List = styled.ul`
  margin-top: ${Metrics.smallMargin}px;
`;

const StyledEmpty = styled(Empty)`
  margin: ${Metrics.doubleBaseMargin}px;
`;

const PER_PAGE = 50;

const GalleryRetouchTab = ({ onSendEmail }: { onSendEmail?: (params: OnSendEmailProps) => void }) => {
  const { id } = useParams<{ id: string }>();
  const galleryId = id ? parseInt(id, 10) : undefined;
  const { message } = App.useApp();

  const filePickerRefs = useRef<{ [key: string]: HTMLInputElement }>({});

  const { t } = useContext(LocalizationContext);
  const [selectedPhotos, setSelectedPhotos] = useState<number[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<SelectedMenu>('edit');
  const [isValidateModalVisible, setIsValidateModalVisible] = useState<boolean>(false);
  const [lastActionOrderId, setlastActionOrderId] = useState<number>(0);

  const { startUpload, isUploadRunning } = useUpload();

  const getOrdersWhere = useMemo(
    () => ({
      galleryId,
      perPage: PER_PAGE,
      page: 1,
      characteristics: [OrderCharacteristic.RETOUCH, OrderCharacteristic.OPEN],
    }),
    [galleryId],
  );

  const { updatePhotosCount: updateRetouchedPhotosCount } = usePhotosCount({
    galleryId,
    characteristics: [PhotoCharacteristic.RETOUCHED],
    fetchPolicy: 'cache-first',
  });

  const { data: ordersData } = useQuery(GET_ORDERS, {
    variables: {
      where: getOrdersWhere,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [deletePhotos, { loading: isDeletePhotoInProgress }] = useMutation(DELETE_PHOTOS, {
    refetchQueries: [
      {
        query: GET_ORDERS,
        variables: {
          where: getOrdersWhere,
        },
      },
    ],
  });

  const [validateOrders, { loading: isValidateLoading }] = useMutation(VALIDATE_ORDERS, {
    refetchQueries: [
      {
        query: GET_ORDERS,
        variables: {
          where: getOrdersWhere,
        },
      },
    ],
    update: cache => {
      cache.modify<Record<string, GetOrdersQuery['getOrders']>>({
        id: cache.identify(makeReference('ROOT_QUERY')),
        fields: {
          [`getOrders({"where":{"galleryId":${galleryId},"isValidated":true}})`](existing) {
            if ('_count' in existing) {
              return {
                ...existing,
                _count: (existing?._count || 0) + 1,
              };
            }
            return existing;
          },
        },
      });
    },
  });

  const { orders } = useMemo(() => {
    const isEdited = selectedMenu === 'edited';
    const isValidated = selectedMenu === 'validated';
    const orders: RetouchTabOrder[] = [];
    const validatedOrders: RetouchTabOrder[] = [];

    (ordersData?.getOrders.edges as OrderAdmin[])?.forEach(order => {
      const newOrder: RetouchTabOrder = {
        id: order.id,
        contact: order.contact || null,
        toEditTotal: 0,
        retouchedTotal: 0,
        isComplete: false,
        isPaid: order.isPaid,
        isValidated: order.isValidated,
        items: [],
      };

      order.items.forEach(item => {
        if (item.status !== OrderItemStatus.CANCELLED) {
          const newOrderItem: RetouchTabOrderItem = {
            id: item.id,
            productName: item.productName || '',
            toEditTotal: 0,
            retouchedTotal: 0,
            isComplete: false,
            isPaid: order.isPaid,
            isValidated: order.isValidated,
            photos: [],
          };
          (item.photos.edges as PhotoAdmin[]).forEach(photo => {
            if (photo.isRetouched) {
              if (isEdited || isValidated) {
                newOrderItem.photos.push(photo);
              }

              newOrderItem.retouchedTotal++;
            } else {
              if (!isEdited && !isValidated) {
                newOrderItem.photos.push(photo);
              }
              newOrderItem.toEditTotal++;
            }
          });

          if (newOrderItem.retouchedTotal > 0 && newOrderItem.retouchedTotal >= newOrderItem.toEditTotal) {
            newOrderItem.isComplete = true;
          }

          newOrder.items.push(newOrderItem);
          newOrder.toEditTotal += newOrderItem.toEditTotal;
          newOrder.retouchedTotal += newOrderItem.retouchedTotal;
        }
      });

      newOrder.isComplete = newOrder.items.every(x => x.isComplete === true);

      if (order.isValidated) {
        validatedOrders.push(newOrder);
      } else {
        orders.push(newOrder);
      }
    });

    return { orders: isValidated ? validatedOrders : orders };
  }, [ordersData?.getOrders.edges, selectedMenu]);

  const ordersToValidate = useMemo(() => orders.filter(o => o.isPaid && o.retouchedTotal > 0), [orders]);
  const isOrdersToValidateCompleted = useMemo(
    () => ordersToValidate.every(o => o.isComplete) === true,
    [ordersToValidate],
  );

  const handleChangeSelectedMenu = useCallback((menu: SelectedMenu) => {
    setSelectedMenu(menu);
    setSelectedPhotos([]);
  }, []);

  const isPhotoSelected = useCallback(
    ({ photoId }: { photoId: number }) => {
      return selectedPhotos.findIndex(x => x === photoId) !== -1;
    },
    [selectedPhotos],
  );

  const onPhotoClicked = useCallback(
    ({ photoId }: { photoId: number }) => {
      const idx = selectedPhotos.findIndex(x => x === photoId);

      if (idx < 0) {
        setSelectedPhotos([...selectedPhotos, photoId]);
      } else {
        selectedPhotos.splice(idx, 1);
        setSelectedPhotos([...selectedPhotos]);
      }
    },
    [selectedPhotos, setSelectedPhotos],
  );

  const handleOrderSelectAll = useCallback(
    (orderId: number) => {
      const order = orders.find(o => o.id === orderId);
      if (!order) {
        return;
      }

      const photos = chain(order.items)
        .flatMap(item => item.photos.map(photo => photo.id))
        .concat(selectedPhotos)
        .uniq()
        .compact()
        .value();
      setSelectedPhotos(photos);
    },
    [orders, selectedPhotos],
  );

  const handleOrderClearSelection = useCallback(
    (orderId: number) => {
      const order = orders.find(o => o.id === orderId);
      if (!order) {
        return;
      }

      const newSelectedPhotos = selectedPhotos.filter(
        selectedPhoto => order.items.flatMap(item => item.photos).find(x => x.id === selectedPhoto) === undefined,
      );
      setSelectedPhotos(newSelectedPhotos);
    },
    [orders, selectedPhotos],
  );

  const handleOrderLightroomSelection = useCallback(
    (orderId: number) => {
      const order = orders.find(o => o.id === orderId);
      if (!order) {
        return;
      }

      const selectedPhotosOfOrder = chain(order.items)
        .flatMap(item => item.photos)
        .filter(photo => !!photo?.id && selectedPhotos.indexOf(photo?.id) >= 0)
        .compact()
        .value();

      const photosName = chain(selectedPhotosOfOrder)
        .map(photo => photo?.name && photo.name.replace(/\.[^/.]+$/, ''))
        .filter(name => !!name)
        .compact()
        .value();

      navigator.clipboard.writeText(photosName.join(', '));

      message.success(t('app.message.gallery.photos.clipboard.success', { count: selectedPhotos.length }));
    },
    [orders, selectedPhotos, t],
  );

  const handleOrderDeleteSelection = useCallback(
    async (orderId: number) => {
      const order = orders.find(o => o.id === orderId);
      if (!order) {
        return;
      }

      setlastActionOrderId(orderId);

      const orderSelectedPhotos = selectedPhotos.filter(selectedPhoto => {
        return !!order.items.flatMap(item => item.photos).find(orderPhoto => orderPhoto.id === selectedPhoto);
      });

      const photosToDeleteCount = orderSelectedPhotos.length;
      try {
        await deletePhotos({
          variables: {
            where: {
              ids: orderSelectedPhotos,
            },
          },
          refetchQueries: [
            {
              query: GET_ORDERS,
              variables: {
                where: getOrdersWhere,
              },
            },
          ],
          update(cache, mutationResult) {
            const deletedPhotos = mutationResult.data?.deletePhotos?.ids;

            if (deletedPhotos) {
              updateRetouchedPhotosCount(-deletedPhotos.length);

              for (const deletedPhoto of deletedPhotos) {
                cache.evict({ id: `PhotoAdmin:${deletedPhoto}` });
              }
              cache.gc();
            }

            setSelectedPhotos([]);

            if (!deletedPhotos?.length) {
              message.error(t('app.message.gallery.photos.delete.nothing_deleted'));
            } else if (deletedPhotos.length !== photosToDeleteCount) {
              message.error(t('app.message.gallery.photos.delete.partial_deletion'));
            } else {
              message.success(t('app.message.gallery.photos.delete.success', { count: deletedPhotos?.length }));
            }
          },
        });
      } catch (error) {
        const errorCode = getErrorCode(error);
        message.error(t(`app.message.gallery.photos.delete.error.${camelCase(errorCode)}`));
      }
    },
    [orders, selectedPhotos, deletePhotos, getOrdersWhere, updateRetouchedPhotosCount, t],
  );

  const getSelectedPhotosCount = useCallback(
    (orderId: number) => {
      const order = orders.find(o => o.id === orderId);
      if (!order) {
        return 0;
      }

      const orderSelectedPhotos = selectedPhotos.filter(selectedPhoto => {
        return !!order.items.flatMap(item => item.photos).find(orderPhoto => orderPhoto.id === selectedPhoto);
      });

      return orderSelectedPhotos.length;
    },
    [orders, selectedPhotos],
  );

  const handleOnPickFiles = useCallback(
    (refKey: string) => {
      if (!isUploadRunning) {
        filePickerRefs?.current?.[refKey]?.click();
      }
    },
    [isUploadRunning],
  );

  const handleOnFilePicked = useCallback(
    ({ files, orderItemId, folderId }: { files: File[]; orderItemId: number; folderId?: number }) => {
      if (!isUploadRunning && galleryId) {
        startUpload({
          files,
          targetGalleryId: galleryId,
          targetFolderId: folderId,
          isRetouched: true,
          targetOrderItemId: orderItemId,
        });
      }
    },
    [galleryId, isUploadRunning, startUpload],
  );

  const handleSubmitValidateOrders = useCallback(async () => {
    try {
      if (galleryId) {
        await validateOrders({
          variables: {
            where: {
              galleryId,
              orderIds: ordersToValidate.map(o => o.id),
            },
          },
          update(cache, mutationResult) {
            if (mutationResult?.data?.validateOrders) {
              const validatedOrders = mutationResult.data.validateOrders;

              // Get the not retouched photos and remove them from the cache
              const oldPhotos = validatedOrders
                .map(o => o.items.map(i => i.photos.edges.filter(photo => !photo.isRetouched)).flat())
                .flat();

              oldPhotos.forEach(photo => {
                cache.evict({
                  id: cache.identify({
                    id: photo.id,
                    __typename: photo.__typename,
                  }),
                });
              });

              cache.gc();
            }
          },
        });

        setIsValidateModalVisible(false);
        setSelectedMenu('validated');

        message.success(t('app.message.order.validate.success', { count: ordersToValidate.length }));
      }
    } catch (error) {
      console.log(error);
      message.error(t('app.message.error.somethingWentWrong'));
    }
  }, [galleryId, ordersToValidate, t, validateOrders]);

  const getNoOrderText = useCallback(() => {
    switch (selectedMenu) {
      case 'edit':
        return t('app.order.retouch.toEdit.none');
      case 'edited':
        return t('app.order.retouch.edited.none');
      case 'validated':
        return t('app.order.retouch.validated.none');
      default:
        return t('app.common.noData');
    }
  }, [t, selectedMenu]);

  return (
    <StyledContainer direction="row">
      <StyledSidebar>
        <Menu mode="inline" defaultSelectedKeys={['edit']} selectedKeys={[selectedMenu]}>
          <Menu.Item key="edit" onClick={() => handleChangeSelectedMenu('edit')}>
            {t('app.menu.gallery.photos.toEdit')}
          </Menu.Item>
          <Menu.Item key="edited" onClick={() => handleChangeSelectedMenu('edited')}>
            {t('app.menu.gallery.photos.edited')}
          </Menu.Item>
          <Menu.Item key="validated" onClick={() => handleChangeSelectedMenu('validated')}>
            {t('app.menu.gallery.photos.validated')}
          </Menu.Item>
        </Menu>
      </StyledSidebar>
      <GalleryPhotoContainer direction="column" flex={1}>
        <ActionsContainer direction="column">
          {selectedMenu === 'edited' &&
            ordersToValidate.length === 0 &&
            orders.filter(o => o.retouchedTotal > 0).length > 0 && (
              <Container>
                <Alert type="info" message={t('app.order.retouch.edited.partialInfo')} />
              </Container>
            )}
          {selectedMenu === 'edited' && ordersToValidate.length > 0 && (
            <Container>
              <Button type="primary" onClick={() => setIsValidateModalVisible(true)}>
                <Icon name="check" /> {t('app.common.validate')}
              </Button>
              <Alert
                type={isOrdersToValidateCompleted ? 'info' : 'warning'}
                message={
                  isOrdersToValidateCompleted
                    ? t('app.order.retouch.edited.validateInfo')
                    : t('app.order.retouch.edited.validateWarning')
                }
              />
            </Container>
          )}

          {selectedMenu === 'validated' && orders.length > 0 && (
            <Container>
              <Alert type="info" message={t('app.order.retouch.validated.sendInfo')}></Alert>
            </Container>
          )}

          {orders.length === 0 && (
            <Container block justify="center">
              <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{getNoOrderText()}</span>} />
            </Container>
          )}
        </ActionsContainer>
        {orders
          .filter(o => o.items.length > 0)
          .map((order, orderIndex) => (
            <OrderContainer key={order.id}>
              <Flex align="center" gap="small">
                <Title level="h3" color={Colors.black}>
                  {t('app.common.order', { count: 1 })} #{order.id}
                  {order.contact && ` - ${order.contact.displayName}`}
                </Title>
                <Tag size="small" color={order.isPaid ? Colors.success : Colors.waiting}>
                  {t(order.isPaid ? 'app.common.paid' : 'app.common.waitingForPayment')}
                </Tag>
                <SelectionToolbar
                  selectionCount={getSelectedPhotosCount(order.id)}
                  onCancel={() => {
                    handleOrderClearSelection(order.id);
                  }}
                  onSelectAll={() => {
                    handleOrderSelectAll(order.id);
                  }}
                  onLightroom={() => {
                    handleOrderLightroomSelection(order.id);
                  }}
                  working={isDeletePhotoInProgress && lastActionOrderId === order.id}
                  {...(selectedMenu === 'edited'
                    ? {
                        onDelete: () => {
                          handleOrderDeleteSelection(order.id);
                        },
                      }
                    : {})}
                />
                {selectedMenu === 'validated' && orders.length > 0 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      onSendEmail?.({ contactId: order?.contact?.id || undefined });
                    }}
                  >
                    <Icon name="send" /> {t('app.common.send')}
                  </Button>
                )}
              </Flex>

              {order.items.map((orderItem, itemIndex) => (
                <OrderContainer key={orderItem.id}>
                  <Flex align="center" gap="small">
                    <Title level="h4" color={Colors.black}>
                      {orderItem.productName}
                    </Title>
                    <Tag
                      size="regular"
                      color={orderItem.isComplete && orderItem.isPaid ? 'primary-gradient' : Colors.grey}
                      textColor={orderItem.isComplete && orderItem.isPaid ? Colors.white : Colors.black}
                    >
                      {orderItem.retouchedTotal}/{orderItem.toEditTotal}
                    </Tag>
                    <FilePickerInput
                      multiple
                      onFilesPicked={files => {
                        let folder = undefined;

                        if (selectedMenu === 'validated') {
                          folder = orderItem.photos.find(photo => photo.folder)?.folder;
                        }

                        handleOnFilePicked({ files, orderItemId: orderItem.id, folderId: folder?.id });
                      }}
                      ref={el => el && (filePickerRefs.current[`${orderIndex}-${itemIndex}`] = el)}
                      filetypes={['.jpg', '.jpeg']}
                    />
                    {selectedMenu === 'validated' && (
                      <Popconfirm
                        title={t(`app.gallery.photos.retouch.validated.deleteConfirm`)}
                        // @ts-ignore
                        onClick={e => e.stopPropagation()}
                        onConfirm={() => handleOnPickFiles(`${orderIndex}-${itemIndex}`)}
                      >
                        <RoundButton
                          icon="plus"
                          iconSize={18}
                          disabled={isUploadRunning}
                          size="middle"
                          tooltipTitle={t('app.action.uploadRetouched')}
                        />
                      </Popconfirm>
                    )}
                    {selectedMenu !== 'validated' && (
                      <RoundButton
                        icon="plus"
                        iconSize={18}
                        disabled={isUploadRunning}
                        size="middle"
                        onClick={() => handleOnPickFiles(`${orderIndex}-${itemIndex}`)}
                        tooltipTitle={t('app.action.uploadRetouched')}
                      />
                    )}
                  </Flex>

                  <PhotosContainer>
                    {orderItem.photos.length === 0 && (
                      <Container block justify="center">
                        <StyledEmpty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <span>
                              {t(
                                selectedMenu === 'edit'
                                  ? 'app.order.retouch.edited.none'
                                  : 'app.order.retouch.validated.noPhoto',
                              )}
                            </span>
                          }
                        />
                      </Container>
                    )}
                    {orderItem.photos.map(
                      ({ asset, id: photoId, name, isLiked }) =>
                        asset?.noWmThumbSmall &&
                        photoId &&
                        asset && (
                          <Photo
                            key={`${orderItem.id}-${photoId}`}
                            id={photoId}
                            name={name}
                            asset={asset}
                            isPhotoSelected={isPhotoSelected({ photoId })}
                            isLiked={isLiked}
                            handleOnSelectPhoto={onPhotoClicked}
                          />
                        ),
                    )}
                  </PhotosContainer>
                </OrderContainer>
              ))}
            </OrderContainer>
          ))}
      </GalleryPhotoContainer>
      <Modal
        centered
        open={isValidateModalVisible}
        okText={t('app.common.validate')}
        onOk={handleSubmitValidateOrders}
        okButtonProps={{
          loading: isValidateLoading,
        }}
        cancelText={t('app.common.cancel')}
        onCancel={() => setIsValidateModalVisible(false)}
        width={480}
      >
        <Container>
          <Icon name="exclamation" color={Colors.secondaryMain} size={24} />
          <ModalText weight="bold">{t('app.order.validate.title', { count: ordersToValidate.length })}</ModalText>
        </Container>
        <ValidateOrdersContainer justify="space-around">
          {ordersToValidate.map(order => (
            <ValidateTag size="regular" color={Colors.grey} textColor={Colors.black} key={order.id}>
              {t('app.common.order', { count: 1 })} #{order.id}
            </ValidateTag>
          ))}
        </ValidateOrdersContainer>
        <List>
          <li>{t('app.order.validate.warnings.one', { count: ordersToValidate.length })}</li>
          <li>{t('app.order.validate.warnings.two')}</li>
        </List>
      </Modal>
    </StyledContainer>
  );
};

export default GalleryRetouchTab;
