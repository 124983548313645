import { useCallback, useContext, useMemo, useState } from 'react';

import { Button, Dropdown, Flex, Menu } from 'antd';
import { createStyles } from 'antd-style';

import { MenuClickEventHandler } from 'rc-menu/lib/interface';

import Icon, { IconName } from 'Components/Atoms/Icon';

import ExpendableInput from 'Components/Molecules/ExpendableInput';

import { LocalizationContext } from 'i18n';

import { GalleryPhotosOrder, PhotoCharacteristic } from 'Operations/__generated__/graphql';

export type PhotoFilter = PhotoCharacteristic.ORDERED | PhotoCharacteristic.LIKED | PhotoCharacteristic.AVAILABLE;

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    height: 32px;
  `,
  menuItem: css`
    display: flex;
    justify-content: flex-start;
    & svg {
      margin-top: 4px;
      margin-right: 2px;
    }
  `,
  button: css`
    display: flex;
    align-items: center;
  `,
}));

interface Props {
  sortOptions: OrderOption[];
  photosOrder: GalleryPhotosOrder;
  onSortChanged: (value: OrderOption) => void;
  onFilterChanged?: (value: PhotoFilter) => void;
  onSearch: (text: string | null) => void;
}

export type OrderOption = {
  fieldName: 'name' | 'custom' | 'random';
  name: string;
  order?: 'asc' | 'desc';
};

const buildOrderItemKey = (item: OrderOption) => {
  return item.fieldName + (item.order ? item.order : '');
};

const FILTERS: PhotoFilter[] = [PhotoCharacteristic.AVAILABLE, PhotoCharacteristic.ORDERED, PhotoCharacteristic.LIKED];

const getFilterIcon = (filter: PhotoFilter) => {
  switch (filter) {
    case PhotoCharacteristic.LIKED:
      return 'heart';
    case PhotoCharacteristic.ORDERED:
      return 'purchased';
    case PhotoCharacteristic.AVAILABLE:
    default:
      return 'photos';
  }
};
const getSortIcon = (item: OrderOption): IconName => {
  switch (item.fieldName) {
    case 'name':
      return `order-${item.order || 'asc'}` as IconName;
    case 'random':
      return 'random';
    case 'custom':
    default:
      return 'drag';
  }
};

const ListToolbar = ({ onSearch, sortOptions, photosOrder, onSortChanged, onFilterChanged }: Props) => {
  const { t } = useContext(LocalizationContext);
  const { styles } = useStyles();
  const [selectedFilter, setSelectedFilter] = useState<PhotoFilter>(PhotoCharacteristic.AVAILABLE);

  const selectedSortItem = useMemo(
    () =>
      sortOptions.find(
        option =>
          (photosOrder === GalleryPhotosOrder.CUSTOM && option.fieldName === 'custom') ||
          (photosOrder === GalleryPhotosOrder.FILENAME_ASC && option.order === 'asc') ||
          (photosOrder === GalleryPhotosOrder.FILENAME_DESC && option.order === 'desc') ||
          (photosOrder === GalleryPhotosOrder.RANDOM && option.fieldName === 'random'),
      ) || sortOptions[0],
    [sortOptions, photosOrder],
  );

  const handleMenuClick: MenuClickEventHandler = useCallback(
    e => {
      const selectedItem = sortOptions.find(x => buildOrderItemKey(x) === e.key);
      if (selectedItem) {
        if (onSortChanged) {
          onSortChanged(selectedItem);
        }
      }
    },
    [sortOptions, onSortChanged],
  );

  const handleFilterClick: MenuClickEventHandler = useCallback(
    e => {
      setSelectedFilter(e.key as PhotoFilter);
      if (onFilterChanged) {
        onFilterChanged(e.key as PhotoFilter);
      }
    },
    [onFilterChanged],
  );

  const sortMenu = (
    <Menu onClick={handleMenuClick}>
      {sortOptions.map(x => {
        return (
          <Menu.Item className={styles.menuItem} key={buildOrderItemKey(x)}>
            <Icon name={getSortIcon(x)} />
            {t(x.name)}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const filterMenu = (
    <Menu onClick={handleFilterClick}>
      {FILTERS.map(filter => (
        <Menu.Item className={styles.menuItem} key={filter}>
          <Icon name={getFilterIcon(filter)} />
          {t(`app.filter.${filter.toLowerCase()}`)}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Flex gap="small" className={styles.container}>
      <ExpendableInput onSubmit={onSearch} iconName="search" />
      {onFilterChanged && (
        <Dropdown overlay={filterMenu} placement="bottomCenter">
          <Button className={styles.button} size="small">
            <Icon name={getFilterIcon(selectedFilter)} />
            {t(`app.filter.${selectedFilter.toLowerCase()}`)}
          </Button>
        </Dropdown>
      )}
      <Dropdown overlay={sortMenu} placement="bottomCenter">
        <Button className={styles.button} size="small">
          <Icon name={getSortIcon(selectedSortItem)} />
          {t(selectedSortItem.name)}
        </Button>
      </Dropdown>
    </Flex>
  );
};

export default ListToolbar;
