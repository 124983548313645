import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Alert, Button, Flex } from 'antd';
import { Color } from 'antd/es/color-picker';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { kebabCase } from 'lodash';

import Fieldset from 'Components/Atoms/Fieldset';

import ColorPicker from 'Components/Molecules/Form/ColorPicker';
import FileSelect from 'Components/Molecules/Form/FileSelect';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import i18n, { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

import { GET_COUNTRIES } from 'Operations/Queries/Country/GetCountries';

export interface NewCompanyFormValues {
  name?: string;
  street?: string;
  zipcode?: string;
  city?: string;
  countryId: number;
  vat?: string;
  iban?: string;
  logoUrl?: string | null;
  logo?: Blob;
  primaryColor?: Color;
  siren?: string;
  siret?: string;
  mobileMessageSenderId?: string;
  bookingUrl?: string;
}

export interface EditCompanyFormValues extends NewCompanyFormValues {
  id: number;
}

const LogoPreview = styled.img`
  width: 100px;
  height: auto;
  margin-right: ${Metrics.baseMargin}px;
`;

const CustomDiv = styled.div`
  margin-top: ${Metrics.tinyMargin}px;
`;

const CompanyForm = <T extends NewCompanyFormValues | EditCompanyFormValues>({
  isSubmitting,
  values,
  setFieldValue,
  setFieldTouched,
  getFieldMeta,
}: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);

  const [image, setImage] = useState<string>();

  const { data: countriesData } = useQuery(GET_COUNTRIES);

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setImage(URL.createObjectURL(e.target.files[0]));
  }, []);

  useEffect(() => {
    const { initialValue, value, touched: isTouched } = getFieldMeta('bookingUrl');
    if (!initialValue && !value && !isTouched) {
      setFieldValue('bookingUrl', kebabCase(values.name));
    }
  }, [getFieldMeta, setFieldValue, values.name]);

  return (
    <Form layout="vertical">
      <Flex gap="middle">
        <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
          <Input
            name="name"
            placeholder={t('app.common.name')}
            size="large"
            onChange={({ target: { value } }) => {
              setFieldValue('name', value);
              const { touched: isTouched, initialValue } = getFieldMeta('bookingUrl');
              if (!initialValue && !isTouched) {
                setFieldValue('bookingUrl', kebabCase(value));
              }
            }}
          />
        </Form.Item>
        <Form.Item label={t('app.common.customUrl')} name="bookingUrl" hasFeedback={false}>
          <Input
            name="bookingUrl"
            placeholder={t('app.common.customUrl')}
            size="large"
            onBlur={({ target: { value } }) => setFieldValue('bookingUrl', kebabCase(value))}
            onChange={({ target: { value } }) => {
              setFieldTouched('bookingUrl', true);
              setFieldValue('bookingUrl', value);
            }}
          />
          <CustomDiv>
            <Alert type="info" message={t('app.company.settings.customUrl')} />
          </CustomDiv>
        </Form.Item>
      </Flex>
      <Fieldset className="Fieldset" legend={t('app.common.address')}>
        <Flex gap="middle">
          <Form.Item label={t('app.common.street')} name="street" required hasFeedback={false}>
            <Input name="street" placeholder={t('app.common.street')} size="large" />
          </Form.Item>
          <Form.Item label={t('app.common.zipcode')} name="zipcode" required hasFeedback={false}>
            <Input name="zipcode" placeholder={t('app.common.zipcode')} size="large" />
          </Form.Item>
        </Flex>
        <Flex gap="middle">
          <Form.Item label={t('app.common.city')} name="city" required hasFeedback={false}>
            <Input name="city" placeholder={t('app.common.city')} size="large" />
          </Form.Item>
          <Form.Item label={t('app.common.country')} name="countryId" required hasFeedback={false}>
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              name="countryId"
              size="large"
              showSearch
              optionFilterProp="children"
            >
              {countriesData?.getCountries.edges.map(country => (
                <Select.Option key={country.id} value={country.id} title={''}>
                  {country.nameEn}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Flex>
      </Fieldset>
      <Flex gap="middle">
        <Form.Item label={t('app.company.iban')} name="iban" hasFeedback={false}>
          <Input name="iban" placeholder={t('app.company.iban')} size="large" />
        </Form.Item>
        <Form.Item label={t('app.common.vatNumber')} name="vat" hasFeedback={false}>
          <Input name="vat" placeholder={t('app.common.vatNumber')} size="large" />
        </Form.Item>
      </Flex>
      {values.countryId === 75 && (
        <Flex gap="middle">
          <Form.Item label="Siren" name="siren" hasFeedback={false}>
            <Input name="siren" placeholder="Siren" size="large" />
          </Form.Item>
          <Form.Item label="Siret" name="siret" hasFeedback={false}>
            <Input name="siret" placeholder="Siret" size="large" />
          </Form.Item>
        </Flex>
      )}
      <Form.Item label={t('app.common.logo')} name="logo">
        <Flex gap="middle">
          {image && <LogoPreview src={image} />}
          {!image && values.logoUrl && <LogoPreview src={values.logoUrl} />}
          <FileSelect name="logo" accept="image/png, image/jpeg, image/svg+xml" handleOnChange={handleFileChange} />
        </Flex>
      </Form.Item>
      <Form.Item label={t('app.company.primaryColor')} name="primaryColor" hasFeedback={false}>
        <ColorPicker name="primaryColor" size="large" defaultFormat="hex" disabledAlpha showText />
      </Form.Item>
      <Form.Item label={t('app.company.mobileMessageSenderId.label')} name="mobileMessageSenderId" hasFeedback={false}>
        <Input name="mobileMessageSenderId" placeholder={t('app.company.mobileMessageSenderId.label')} size="large" />
        <CustomDiv>
          <Alert type="info" message={t('app.company.mobileMessageSenderId.description')} />
        </CustomDiv>
      </Form.Item>
      <Flex gap="middle" justify="flex-end">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t('app.common.edit')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface EditCompanyFormPayload {
  values: EditCompanyFormValues;
  formikBag: FormikBag<EditCompanyFormProps, EditCompanyFormValues>;
}

export interface EditCompanyFormProps {
  onSubmit: (payload: EditCompanyFormPayload) => void;
  defaultValues: EditCompanyFormValues;
}

const companySchema = yup.object({
  id: yup.number().required(),
  name: yup.string().trim().required(),
  vat: yup.string().trim(),
  street: yup.string().trim().required(),
  zipcode: yup.string().trim().required(),
  city: yup.string().trim().required(),
  countryId: yup.number().required(),
  iban: yup.string().trim(),
  primaryColor: yup.object<any>().nullable(),
  siren: yup.string().trim(),
  siret: yup.string().trim(),
  mobileMessageSenderId: yup.string().trim().max(11),
  bookingUrl: yup.string().trim(),
  logoUrl: yup.string().nullable(),
  logo: yup.mixed().test(
    'file',
    () => i18n.t('app.form.errors.fileToLarge', { limit: '500KB' }),
    value => {
      return value ? value.size <= 500000 : true;
    },
  ),
});

export const EditCompanyForm = withFormik<EditCompanyFormProps, EditCompanyFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: companySchema,
})(CompanyForm);
