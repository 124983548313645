import Images from './Images';

import Colors from './Colors';
import { FontSize, FontWeight } from './Fonts';
import Metrics from './Metrics';

const ThemeConfig = [
  {
    name: 'Light',
    colors: { ...Colors.light },
  },
  {
    name: 'Dark',
    colors: { ...Colors.dark },
  },
];

export { Colors, FontWeight, FontSize, Metrics, Images, ThemeConfig };
