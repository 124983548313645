import { useContext } from 'react';

import { useMutation } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { Alert, App } from 'antd';

import GridConfig from 'Components/Molecules/GridConfig';

import { Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import {
  GalleryAppearance,
  GridSize,
  GridSpacing,
  WatermarkMode
} from 'Operations/__generated__/graphql';

import { UPDATE_GALLERY_APPEARANCE } from 'Operations/Mutations/GalleryAppearance/UpdateGalleryAppearance';

const GallerySpacingTab = ({
  galleryAppearance,
  watermarkMode,
}: {
  galleryAppearance: DeepPartial<GalleryAppearance>;
  watermarkMode?: WatermarkMode | null;
}) => {
  const { message } = App.useApp();
  const { t } = useContext(LocalizationContext);
  const [updateGalleryAppearance] = useMutation(UPDATE_GALLERY_APPEARANCE);

  const selectGridCallback = ({ size, spacing }: { size: GridSize; spacing: GridSpacing }) => {
    try {
      if (galleryAppearance.id) {
        updateGalleryAppearance({
          variables: {
            where: {
              id: galleryAppearance.id,
            },
            data: {
              size,
              spacing,
            },
          },
        });

        message.success(t('app.message.gallery.appearance.update.success'));
      }
    } catch (error) {
      console.log(error);

      message.error(t('app.message.gallery.appearance.update.error'));
    }
  };

  return (
    <>
      {watermarkMode === WatermarkMode.WEB && (
        <Alert
          type="info"
          style={{ whiteSpace: 'pre-wrap', marginBottom: Metrics.baseMargin }}
          message={t('app.gallery.appearance.watermarkThumb')}
        />
      )}
      <GridConfig
        handleSelectGrid={selectGridCallback}
        currentGridId={
          galleryAppearance.spacing && galleryAppearance.size
            ? `${galleryAppearance.spacing}-${galleryAppearance.size}`
            : undefined
        }
      />
    </>
  );
};

export default GallerySpacingTab;
