import React, { useContext } from 'react';

import {
  App,
  Card,
  Col,
  Dropdown,
  Flex,
  Pagination,
  Row,
  Switch,
  Tooltip
} from 'antd';
import { useTheme } from 'antd-style';
import { Link } from 'react-router-dom';

import Icon from 'Components/Atoms/Icon';

import {
  HandleChangeGalleryStatusProps,
  ShowGalleryProps
} from 'Components/Molecules/Tables/GalleryTable';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import {
  GalleriesGetWhereType,
  GalleryStatus,
  GetGalleriesQuery,
  JobStatus,
  JobType
} from 'Operations/__generated__/graphql';

import GalleryJobTitle from './GalleryJobTitle';

interface Props {
  galleriesTotal: number;
  loading: boolean;
  paginationCurrent: number;
  paginationSize?: number;
  data?: GetGalleriesQuery['getPaginatedGalleries']['edges'];
  galleryIdUpload?: number;
  isUploadRunning: boolean;
  handleOnChange: (params: GalleriesGetWhereType) => void;
  handleShowGalleryClick?: (params: ShowGalleryProps) => void;
  handleDeleteConfirm: (params: { ids: number[] }) => void;
  handleArchiveConfirm: (params: { ids: number[] }) => void;
  handleChangeGalleryStatus?: (params: HandleChangeGalleryStatusProps) => void;
}

const GalleriesGrid = ({
  loading,
  galleriesTotal,
  data,
  paginationCurrent,
  paginationSize = 20,
  galleryIdUpload,
  isUploadRunning,
  handleOnChange,
  handleShowGalleryClick,
  handleDeleteConfirm,
  handleArchiveConfirm,
  handleChangeGalleryStatus,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const { modal } = App.useApp();

  return (
    <Flex vertical gap="large">
      <Row gutter={[theme.sizeLG, theme.sizeLG]}>
        {data?.map(gallery => (
          <Col xs={24} md={24} lg={12} xl={8} xxl={6}>
            <Card
              className="ant-card--shadow"
              loading={loading}
              cover={
                <Link to={`${gallery.id}`}>
                  <img
                    src={
                      gallery.jobs.some(job => job.status === JobStatus.RUNNING || job.status === JobStatus.WAITING)
                        ? Images.coverMissing
                        : gallery.galleryAppearance.coverUrlLoader || Images.coverMissing
                    }
                    style={{
                      height: 'auto',
                      width: '100%',
                      objectFit: 'cover',
                      aspectRatio: '1/1',
                    }}
                  />
                </Link>
              }
              actions={[
                <Switch
                  style={{
                    backgroundColor: gallery.status === GalleryStatus.ONLINE ? theme.colorSuccess : theme.colorError,
                    marginInline: 8,
                  }}
                  size="small"
                  checkedChildren={t(`app.gallery.status.online`)}
                  unCheckedChildren={t(`app.gallery.status.offline`)}
                  checked={gallery.status === GalleryStatus.ONLINE}
                  onChange={checked => {
                    handleChangeGalleryStatus?.({
                      id: gallery.id,
                      isChecked: checked,
                    });
                  }}
                />,
                <Tooltip title={t('app.common.showGallery')}>
                  <Icon
                    name="external-link"
                    onClick={() => {
                      handleShowGalleryClick?.({ slug: gallery.slug, url: gallery.url });
                    }}
                  />
                </Tooltip>,
                <Dropdown
                  key="more"
                  disabled={(isUploadRunning && galleryIdUpload === gallery.id) || loading}
                  menu={{
                    items: [
                      {
                        key: 'archive',
                        label: t('app.common.archive'),
                        onClick() {
                          modal.confirm({
                            title: t('app.gallery.archive.title', { count: 1 }),
                            content: t('app.gallery.archive.content'),
                            okText: t('app.common.archive'),
                            cancelText: t('app.common.cancel'),
                            onOk() {
                              handleArchiveConfirm?.({ ids: [gallery.id] });
                            },
                          });
                        },
                      },
                      {
                        key: 'delete',
                        danger: true,
                        label: t('app.gallery.moveToTrash'),
                        onClick() {
                          modal.confirm({
                            title: t('app.confirm.moveToTrash', { count: 1 }),
                            okText: t('app.gallery.moveToTrash'),
                            cancelText: t('app.common.cancel'),
                            onOk() {
                              handleDeleteConfirm?.({ ids: [gallery.id] });
                            },
                          });
                        },
                      },
                    ],
                  }}
                  placement="bottomRight"
                >
                  <Icon name="more" />
                </Dropdown>,
              ]}
            >
              <Card.Meta
                title={
                  <Flex gap="small" align="center" justify="center">
                    <GalleryJobTitle jobs={gallery.jobs}>
                      <Link to={`${gallery.id}`}>{gallery.name}</Link>
                    </GalleryJobTitle>
                  </Flex>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
      <Flex vertical align="center">
        <Pagination
          pageSize={paginationSize}
          showSizeChanger={false}
          current={paginationCurrent}
          total={galleriesTotal}
          onChange={page => handleOnChange({ page })}
          disabled={loading}
        />
      </Flex>
    </Flex>
  );
};

export default GalleriesGrid;
