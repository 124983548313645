import React, { useContext } from 'react';

import { Button, Drawer, Empty, Flex } from 'antd';
import { DrawerProps } from 'antd/lib';

import PhotoEl from 'Components/Molecules/Photo';

import { LocalizationContext } from 'i18n';

import { AssetAdmin, PageInfo, Photo, PhotoCoreFieldsFragment } from 'Operations/__generated__/graphql';

interface PhotoConnection {
  _count: number;
  pageInfo: PageInfo;
  edges: (PhotoCoreFieldsFragment | Partial<Photo>)[];
}

interface Props extends DrawerProps {
  onSelect: (photoId: number) => void;
  fetchMore: () => void;
  onClose?: () => void;
  selectedPhotoIds: number[];
  photos?: PhotoConnection;
}

const PhotosDrawer = ({ onSelect, fetchMore, selectedPhotoIds, photos, children, ...props }: Props) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Drawer closable={false} {...props}>
      {photos && (
        <>
          <Flex wrap="wrap" justify="center" align="center" style={{ height: '100%' }}>
            {photos._count === 0 && <Empty />}
          </Flex>
          <Flex wrap="wrap" justify="center">
            {photos.edges.map(
              photo =>
                photo.id && (
                  <PhotoEl
                    key={photo.id}
                    id={photo.id}
                    name={photo.name}
                    asset={photo.asset as AssetAdmin}
                    handleOnSelectPhoto={() => photo.id && onSelect(photo.id)}
                    handleOnClickImage={() => photo.id && onSelect(photo.id)}
                    isPhotoSelected={selectedPhotoIds.includes(photo.id)}
                  />
                ),
            )}
          </Flex>
          {!photos.pageInfo.isLastPage && (
            <Flex justify="center">
              <Button onClick={fetchMore}>{t('app.common.loadMore')}</Button>
            </Flex>
          )}
          {children}
        </>
      )}
    </Drawer>
  );
};

export default PhotosDrawer;
