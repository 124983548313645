export type VariableGroupName = 'Client' | 'Shooting' | 'Gallery' | 'Other';

export interface VariableGroup {
  name: VariableGroupName;
  variables: Variable[];
}

export interface Variable {
  key: string;
  value: string;
  onlyCrm: boolean;
}

const groups: VariableGroup[] = [
  {
    name: 'Client',
    variables: [
      {
        key: 'fullName',
        value: '{#firstname} {#lastname}',
        onlyCrm: false,
      },
      {
        key: 'firstName',
        value: '{#firstname}',
        onlyCrm: false,
      },
      {
        key: 'lastName',
        value: '{#lastname}',
        onlyCrm: false,
      },
      {
        key: 'email',
        value: '{#email}',
        onlyCrm: false,
      },
      {
        key: 'phone',
        value: '{#phone}',
        onlyCrm: false,
      },
      {
        key: 'address',
        value: '{#street} {#zipcode} {#city} {#country}',
        onlyCrm: false,
      },
      {
        key: 'birthdate',
        value: '{#birthdate}',
        onlyCrm: true,
      },
      {
        key: 'company',
        value: '{#company}',
        onlyCrm: false,
      },
      {
        key: 'vat',
        value: '{#vat}',
        onlyCrm: false,
      },
      {
        key: 'clientPage',
        value: '{#client_page}',
        onlyCrm: true,
      },
    ],
  },
  {
    name: 'Shooting',
    variables: [
      {
        key: 'shootDate',
        value: '{#shoot_date}',
        onlyCrm: true,
      },
      {
        key: 'shootEnd',
        value: '{#shoot_end}',
        onlyCrm: true,
      },
      {
        key: 'shootType',
        value: '{#shoot_type}',
        onlyCrm: true,
      },
      {
        key: 'shootPrice',
        value: '{#shoot_price}',
        onlyCrm: true,
      },
      {
        key: 'shootDeposit', // TO BE CONFIRMED
        value: '{#shoot_deposit}', // TO BE CONFIRMED
        onlyCrm: true,
      },
      {
        key: 'shootBalance',
        value: '{#shoot_balance}', // TO BE CONFIRMED
        onlyCrm: true,
      },
      {
        key: 'shootPlace',
        value: '{#shoot_place}',
        onlyCrm: true,
      },
      {
        key: 'shootAddress',
        value: '{#shoot_street} {#shoot_zipcode} {#shoot_city} {#shoot_country}',
        onlyCrm: true,
      },
      {
        key: 'shootingDescription',
        value: '{#shoot_description}',
        onlyCrm: true,
      },
      {
        key: 'shootingName',
        value: '{#shoot_name}',
        onlyCrm: true,
      },
    ],
  },
  {
    name: 'Gallery',
    variables: [
      {
        key: 'galleryName',
        value: '{#gallery_name}',
        onlyCrm: false,
      },
      {
        key: 'galleryUrl',
        value: '{#gallery_url}',
        onlyCrm: false,
      },
      {
        key: 'gallery_orders_url',
        value: '{#gallery_orders_url}',
        onlyCrm: false,
      },
      {
        key: 'galleryCode',
        value: '{#gallery_code}',
        onlyCrm: false,
      },
      {
        key: 'galleryAvailableAt',
        value: '{#gallery_available_at}',
        onlyCrm: false,
      },
      {
        key: 'galleryExpiredAt',
        value: '{#gallery_expired_at}',
        onlyCrm: false,
      },
      {
        key: 'galleryShootDate',
        value: '{#gallery_shoot_date}',
        onlyCrm: false,
      },
    ],
  },
  {
    name: 'Other',
    variables: [],
  },
];

export function getGroups(groupNames: VariableGroupName[], hasCrm: boolean) {
  const groupsFiltered: VariableGroup[] = [];

  groups.forEach(group => {
    if (groupNames.indexOf(group.name) >= 0) {
      group.variables = group.variables.filter(variable => !variable.onlyCrm || hasCrm);

      if (group.variables.length > 0) {
        groupsFiltered.push(group);
      }
    }
  });

  return groupsFiltered;
}

export default groups;
