import mergeList from 'Operations/Cache/Helpers/mergeList';
import readList from 'Operations/Cache/Helpers/readList';

import { StrictTypedTypePolicies } from 'Operations/__generated__/apollo-helpers';
import { CatalogProductsWhereInput, GetCatalogProductsQueryVariables } from 'Operations/__generated__/graphql';

type CatalogProductsWhereInputVariables = {
  where?: CatalogProductsWhereInput;
};

const CatalogPolicy: StrictTypedTypePolicies['Catalog'] = {
  fields: {
    products: {
      keyArgs: (variables, context) => {
        const { categoryId } = (variables as CatalogProductsWhereInputVariables)?.where || {};
        const queryName = context.field?.alias?.value;

        return `${queryName || context.fieldName}:${JSON.stringify({
          categoryId,
        })}`;
      },
      merge(existing, incoming, { variables }) {
        const { page, perPage } = (variables as GetCatalogProductsQueryVariables)?.productsWhere || {};

        return mergeList({ existing, incoming, page, perPage });
      },
      read(existing, { variables, storeFieldName, readField }) {
        const { page, perPage } = (variables as GetCatalogProductsQueryVariables)?.productsWhere || {};

        return readList({
          readField,
          storeFieldName,
          existing,
          page,
          perPage,
          defaultOrderFields: ['name', 'id'],
          order: ['asc', 'asc'],
        });
      },
    },
  },
};

export default CatalogPolicy;
