import { gql } from 'Operations/__generated__';

export const SET_EMAIL_CONFIG_GOOGLE = gql(/* GraphQL */ `
  mutation SetEmailConfigGoogle($data: EmailConfigGoogleInputType!) {
    setEmailConfigGoogle(data: $data) {
      id
      settings {
        useGmail
        useSmtp
        isGoogleConfigured
        smtp {
          host
          user
          withSsl
        }
      }
    }
  }
`);
