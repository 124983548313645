import React from 'react';

import Card from 'Components/Atoms/Card';
import { Props } from 'Components/Atoms/ContentContainer';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { css } from 'styled-components';

interface ImageCardProps extends Props {
  alt: string;
  image?: string;
  missing: string;
  width?: number | 'auto';
  minWidth?: number;
  maxHeight?: number;
}

const ImageCardContainer = styled(Card)<{ minWidth?: number; maxHeight?: number }>`
  align-items: center;
  justify-content: center;
  min-height: auto;
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight}px;
    `}

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: ${Metrics.boxRadius - 6}px;
    object-fit: contain;
  }
`;

const ImageCard = ({ alt, image, missing, ...rest }: ImageCardProps) => {
  return (
    <ImageCardContainer padding="tinyMargin" {...rest}>
      <img src={image ? image : missing} alt={alt} draggable={false} />
    </ImageCardContainer>
  );
};

export default ImageCard;
