import React, { useContext } from 'react';

import { Tabs } from 'antd';

import { LocalizationContext } from 'i18n';

import useGallery from 'Hooks/useGallery';

import GalleryCoverTab from './GalleryCoverTab';
import GallerySpacingTab from './GallerySpacingTab';
import GalleryThemeTab from './GalleryThemeTab';

interface Props {
  galleryId: number;
}

const GalleryAppearanceTab = ({ galleryId }: Props) => {
  const { t } = useContext(LocalizationContext);

  const { gallery } = useGallery({ id: galleryId });

  if (!gallery) {
    return null;
  }

  return (
    <div>
      <Tabs
        tabPosition="left"
        items={[
          {
            key: 'theme',
            label: t('app.gallery.tabs.theme', { count: 1 }),
            children: <GalleryThemeTab gallery={gallery} />,
          },
          {
            key: 'cover',
            label: t('app.gallery.tabs.cover'),
            children: <GalleryCoverTab gallery={gallery} />,
          },
          // {
          //   key: 'display',
          //   label: t('app.gallery.tabs.display'),
          // },
          {
            key: 'spacing',
            label: t('app.gallery.tabs.display'),
            children: (
              <GallerySpacingTab galleryAppearance={gallery.galleryAppearance} watermarkMode={gallery.watermarkMode} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default GalleryAppearanceTab;
