import { gql } from 'Operations/__generated__';

export const PUT_BACK_GALLERY = gql(/* GraphQL */ `
  mutation PutBackGallery($where: GalleryUpdateWhereType!, $data: GalleryPutBackInputType!) {
    putBackGallery(where: $where, data: $data) {
      id
      status
    }
  }
`);
