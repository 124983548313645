import { useCallback, useContext, useRef } from 'react';

import ReactFroalaEditor from 'react-froala-wysiwyg';

import { useField } from 'formik';
import { TextAreaProps } from 'formik-antd';
import FroalaEditor from 'froala-editor';
import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/languages/fr.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import { print } from 'graphql';

import { LocalizationContext } from 'i18n';

import { AuthVar } from 'Operations/Cache';

import { CREATE_EDITOR_IMAGE } from 'Operations/Mutations/EditorImage/CreateEditorImage';

interface EditorImageResponse {
  data: {
    createEditorImage: {
      link: string;
    };
  };
}

export type EditorProps = {
  heightMin?: string;
  disabledFiles?: boolean;
} & TextAreaProps;

const Editor = ({ name, heightMin = '300', disabledFiles = false }: EditorProps) => {
  const { locale } = useContext(LocalizationContext);

  const [field, _meta, helpers] = useField(name);
  const editorRef = useRef<ReactFroalaEditor | null>(null);

  const { setValue } = helpers;
  const { value } = field;

  const handleOnModelChange = useCallback(
    (content: string) => {
      setValue(content);
    },
    [setValue],
  );

  const moreRichButtons = disabledFiles
    ? ['insertLink', 'insertTable', 'emoticons', 'insertHR']
    : ['insertLink', 'insertImage', 'insertTable', 'emoticons', 'insertHR'];

  return (
    <ReactFroalaEditor
      ref={editorRef}
      config={{
        language: locale === 'fr' ? 'fr' : 'en_gb',
        key: process.env.REACT_APP_FROALA_KEY,
        toolbarButtons: {
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'paragraphFormat',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'clearFormatting',
            ],
            align: 'left',
            buttonsVisible: 3,
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'alignJustify',
              'outdent',
              'indent',
              'formatOLSimple',
              'formatUL',
              'quote',
            ],
            align: 'right',
            buttonsVisible: 3,
          },

          moreRich: {
            buttons: moreRichButtons,
            align: 'left',
            buttonsVisible: 3,
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'fullscreen', 'selectAll', 'html'],
            align: 'right',
            buttonsVisible: 3,
          },
        },
        fontFamily: {
          'Arial,Helvetica,sans-serif': 'Arial',
          'Georgia,serif': 'Georgia',
          Impact: 'Impact',
          Tahoma: 'Tahoma',
          "'Times New Roman',Times,serif": 'Times New Roman',
          'Verdana,Geneva,sans-serif': 'Verdana',
        },
        fontFamilySelection: true,
        fontSize: ['14', '16', '18', '24', '32'],
        fontSizeSelection: true,
        attribution: false,
        heightMax: '500',
        heightMin,
        useClasses: false,
        imageMaxSize: 500000,
        imageUploadURL: process.env.REACT_APP_API_URL,
        imageUploadParam: '1',
        imageUploadParams: {
          operations: JSON.stringify({
            variables: { data: { file: null } },
            operationName: 'CreateEditorImage',
            query: print(CREATE_EDITOR_IMAGE),
          }),
          map: JSON.stringify({
            '1': ['variables.data.file'],
          }),
        },
        imageUploadMethod: 'POST',
        videoInsertButtons: ['videoBack', '|', 'videoByURL'],
        requestHeaders: {
          'apollo-require-preflight': true,
        },
        events: {
          initialized: function () {
            const editor = editorRef?.current?.getEditor() as FroalaEditor;
            if (editor?.emoticons) {
              editor.emoticons.insert = function (emoticon: string, image?: string) {
                if (image) {
                  const url = image.replace('svg', 'png').replace('svg', 'png');
                  const markup = `<img style="width:20px;height:20px;" src="${url}" />`;
                  editor.html.insert(markup, true);
                }
                return {};
              };
            }
          },
          'image.beforeUpload': function () {
            const editor = editorRef?.current?.getEditor() as FroalaEditor;
            if (editor) {
              const authVar = AuthVar();
              if (authVar?.accessToken) {
                editor.opts.requestHeaders.Authorization = `Bearer ${authVar.accessToken}`;
              }
            }
            return true;
          },
          'image.uploaded': function (response: string) {
            const expectedResponse: EditorImageResponse = JSON.parse(response);
            const editor = editorRef?.current?.getEditor() as FroalaEditor;
            if (editor) {
              editor.image.insert(
                expectedResponse.data.createEditorImage.link,
                false,
                {},
                editor.image.get(),
                expectedResponse,
              );
            }

            return false;
          },
        },
      }}
      tag="textarea"
      model={value}
      onModelChange={handleOnModelChange}
    />
  );
};

export default Editor;
