import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { App, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';
import ContactTable, { handleOnChangeProps } from 'Components/Molecules/Tables/ContactTable';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useContactModal } from 'Hooks/useContactModal';

import { ContactsGetInputType, OrderType } from 'Operations/__generated__/graphql';

import { GET_PAGINATED_CONTACTS } from 'Operations/Queries/Contact/GetPaginatedContacts';

import { DELETE_CONTACT } from 'Operations/Mutations/Contact/DeleteContact';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  margin-top: ${Metrics.baseMargin}px;
`;

const PER_PAGE = 20;

const ContactsIndex = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const { message } = App.useApp();

  const [queryParams, setQueryParams] = useState<ContactsGetInputType>({});
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(OrderType.ASC);

  const refetchQueries = useMemo(
    () => [
      {
        query: GET_PAGINATED_CONTACTS,
        variables: {
          where: queryParams,
        },
      },
    ],
    [queryParams],
  );

  const { createContact, updateContact } = useContactModal({ refetchQueries });

  const { data, loading: isContactsLoading } = useQuery(GET_PAGINATED_CONTACTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: queryParams,
      paginate: {
        page,
        perPage: PER_PAGE,
        order,
      },
    },
  });

  const [deleteContact] = useMutation(DELETE_CONTACT, {
    refetchQueries,
    awaitRefetchQueries: true,
    update(cache, { data }) {
      if (data) {
        const normalizedId = cache.identify({ id: data.deleteContact.id, __typename: 'Contact' });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
  });

  const handleFilterTable = useCallback(async ({ order, search, page: newPage }: handleOnChangeProps) => {
    setPage(newPage);
    setOrder(order);
    setQueryParams({ search });
  }, []);

  const handleDeleteConfirm = useCallback(
    async ({ id }: { id: number }) => {
      try {
        await deleteContact({
          variables: {
            where: {
              id,
            },
          },
        });

        message.success(t('app.message.contact.delete.success'));
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [deleteContact, t],
  );

  const goToTransportFees = useCallback(
    ({ id }: { id: number }) => {
      navigate(`/app/contacts/${id}/transport-fees`);
    },
    [navigate],
  );

  return (
    <>
      <Header
        title={t('app.contact.manage')}
        breadcrumbContent={[{ text: t('app.menu.home'), url: '/app/dashboard' }, { text: t('app.common.contacts') }]}
        buttons={[
          <Button key="add" type="primary" size="large" onClick={createContact}>
            {t('app.common.add')}
          </Button>,
        ]}
      />
      <Layout>
        <StyledTableContentContainer>
          <ContactTable
            contactsTotal={data?.getPaginatedContacts._count}
            data={data?.getPaginatedContacts.edges}
            paginationSize={PER_PAGE}
            loading={isContactsLoading}
            handleOnChange={handleFilterTable}
            handleEditClick={({ id }) => updateContact(id)}
            handleEditTransportFees={goToTransportFees}
            handleDeleteConfirm={handleDeleteConfirm}
          />
        </StyledTableContentContainer>
      </Layout>
    </>
  );
};

export default ContactsIndex;
