import { gql } from 'Operations/__generated__';

export const UPDATE_EMAIL_TEMPLATE = gql(/* GraphQL */ `
  mutation UpdateEmailTemplate($where: EmailTemplateUpdateWhereType!, $data: EmailTemplateCreateInputType!) {
    updateEmailTemplate(where: $where, data: $data) {
      id
      associatedModel
      type @client
      title
      name
      content
      createdAt
      attachmentName
      attachmentUrl
    }
  }
`);
