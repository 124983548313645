import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { App, Button } from 'antd';

import { camelCase } from 'lodash';

import ContentContainer from 'Components/Atoms/ContentContainer';
import Icon from 'Components/Atoms/Icon';
import Layout from 'Components/Atoms/Layout';

import Header from 'Components/Molecules/Header';
import ProductCategoriesTable, { OnChangeProps } from 'Components/Molecules/Tables/ProductCategoriesTable';

import { EditProductCategoryFormPayload, NewProductCategoryFormPayload } from 'Forms/ProductCategory';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useJobSubscription } from 'Hooks/useJobSubscription';

import getErrorCode from 'Helpers/GetErrorCode';

import { OrderType, ProductCategoriesGetWhereInput } from 'Operations/__generated__/graphql';

import { GET_PRODUCT_CATEGORIES } from 'Operations/Queries/ProductCategory/GetProductCategories';

import { CREATE_PRODUCT_CATEGORY } from 'Operations/Mutations/ProductCategory/CreateProductCategory';
import { DELETE_PRODUCT_CATEGORY } from 'Operations/Mutations/ProductCategory/DeleteProductCategory';
import { UPDATE_PRODUCT_CATEGORY } from 'Operations/Mutations/ProductCategory/UpdateProductCategory';

const StyledTableContentContainer = styled(ContentContainer)`
  flex: 1;
  align-self: flex-start;
  margin-top: ${Metrics.baseMargin}px;
`;

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const PER_PAGE = 20;

const ProductCategoriesIndex = () => {
  const { t } = useContext(LocalizationContext);
  const { openModal, closeModal } = useModals();
  const { message } = App.useApp();

  const [paginationParams, setPaginationParams] = useState<ProductCategoriesGetWhereInput>({
    page: 1,
    perPage: PER_PAGE,
    order: OrderType.DESC,
  });

  const page = paginationParams.page || 1;

  const [getProductCategories, { data, loading: isProductCategoriesLoading }] = useLazyQuery(GET_PRODUCT_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  const refetchQueries = [
    {
      query: GET_PRODUCT_CATEGORIES,
      variables: {
        where: paginationParams,
      },
    },
  ];

  useJobSubscription();

  const [createProductCategory] = useMutation(CREATE_PRODUCT_CATEGORY, {
    refetchQueries,
  });

  const [updateProductCategory] = useMutation(UPDATE_PRODUCT_CATEGORY, {
    refetchQueries,
  });

  const [deleteProductCategory] = useMutation(DELETE_PRODUCT_CATEGORY, {
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: () => {
      if (data && data?.getProductCategories?._count % PER_PAGE === 0 && page > 1) {
        setPaginationParams(oldParams => ({ ...oldParams, page: page - 1 }));
      }
    },
  });

  useEffect(() => {
    getProductCategories({
      variables: {
        where: paginationParams,
      },
    });
  }, [getProductCategories, paginationParams]);

  const handleOnSubmit = useCallback(
    async ({ values, formikBag }: NewProductCategoryFormPayload) => {
      try {
        await createProductCategory({ variables: { data: values } });

        message.success(t('app.message.productCategory.create.success'));
        formikBag.setSubmitting(false);
        closeModal();
      } catch (error) {
        console.log(error);

        const errorKey = camelCase(getErrorCode(error));

        if (errorKey === 'uniqConstraintFailed') {
          formikBag.setFieldError('url', t(`app.message.error.${errorKey}`, { field: 'url' }));
          message.error(t(`app.message.error.${errorKey}`, { field: 'url' }));
        } else {
          message.error(t('app.message.error.somethingWentWrong'));
        }

        formikBag.setSubmitting(false);
      }
    },
    [closeModal, createProductCategory, t],
  );

  const handleOnSubmitEdit = useCallback(
    async ({ values: { id, ...values }, formikBag }: EditProductCategoryFormPayload) => {
      try {
        await updateProductCategory({ variables: { where: { id }, data: values } });

        message.success(t('app.message.productCategory.update.success'));
        formikBag.setSubmitting(false);
        closeModal();
      } catch (error) {
        console.log(error);

        const errorKey = camelCase(getErrorCode(error));

        if (errorKey === 'uniqConstraintFailed') {
          formikBag.setFieldError('url', t(`app.message.error.${errorKey}`, { field: 'url' }));
          message.error(t(`app.message.error.${errorKey}`, { field: 'url' }));
        } else {
          message.error(t('app.message.error.somethingWentWrong'));
        }

        formikBag.setSubmitting(false);
      }
    },
    [closeModal, t, updateProductCategory],
  );

  const handleDelete = useCallback(
    async ({ id }: { id: number }) => {
      try {
        await deleteProductCategory({
          variables: {
            where: {
              id,
            },
          },
        });

        message.success(t('app.message.productCategory.delete.success'));
      } catch (error) {
        const errorCode = getErrorCode(error);
        let errorMessage = t(`app.message.productCategory.delete.error.${camelCase(errorCode)}`);

        switch (errorCode) {
          case 'FOREIGN_CONSTRAINT_FAILED':
            errorMessage = t(`app.message.productCategory.error.${camelCase(errorCode)}`);
            break;

          default:
            errorMessage = t(`app.message.productCategory.delete.error.${camelCase(errorCode)}`);
            break;
        }

        message.error(errorMessage);
      }
    },
    [deleteProductCategory, t],
  );

  const handleOnCreateProductCategory = useCallback(() => {
    openModal('PRODUCT_CATEGORY', {
      name: 'new',
      onSubmit: handleOnSubmit,
    });
  }, [handleOnSubmit, openModal]);

  const handleEdit = useCallback(
    ({ id }: any) => {
      const productCategory = data?.getProductCategories.edges.find(category => category.id === id);

      if (!productCategory) {
        console.log(`Product ${id} not found.`);
        message.error(t('app.message.error.somethingWentWrong'));
        return;
      }

      openModal('PRODUCT_CATEGORY', {
        name: 'edit',
        onSubmit: handleOnSubmitEdit,
        defaultValues: {
          id: productCategory.id,
          name: productCategory.name,
        },
      });
    },
    [data?.getProductCategories.edges, handleOnSubmitEdit, openModal, t],
  );

  const handleOnChange = useCallback((params: OnChangeProps) => {
    setPaginationParams({ ...params });
  }, []);

  const createButton = (
    <CreateButton key="add" onClick={handleOnCreateProductCategory} size="large" type="primary">
      <Icon name="add" />
      {t('app.common.add')}
    </CreateButton>
  );

  return (
    <>
      <Header
        title={t('app.common.productCategories', { count: 2 })}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.productCategories', { count: 2 }) },
        ]}
        buttons={[createButton]}
      />
      <Layout>
        <StyledTableContentContainer>
          <ProductCategoriesTable
            productCategoriesTotal={data ? data.getProductCategories._count : 0}
            productCategories={data?.getProductCategories.edges ?? []}
            isLoading={isProductCategoriesLoading}
            paginationCurrent={page}
            paginationSize={paginationParams.perPage || PER_PAGE}
            onChange={handleOnChange}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </StyledTableContentContainer>
      </Layout>
    </>
  );
};

export default ProductCategoriesIndex;
