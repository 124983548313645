import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useQuery } from '@apollo/client';

import ReactFroalaEditor from 'react-froala-wysiwyg';

import FroalaEditor from 'froala-editor';
import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/languages/fr.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import { print } from 'graphql';

import VariableModal from 'Components/Molecules/EmailEditor/VariableModal';

import { LocalizationContext } from 'i18n';

import { AuthVar } from 'Operations/Cache';

import { EmailTemplateAssociatedModel, GetEmailVariablesQueryVariables } from 'Operations/__generated__/graphql';

import { GET_EMAIL_VARIABLES } from 'Operations/Queries/EmailTemplate/GetEmailVariables';
import { ME } from 'Operations/Queries/User/Me';

import { CREATE_EDITOR_IMAGE } from 'Operations/Mutations/EditorImage/CreateEditorImage';

import { VariableGroupName } from './VariableGroups';

interface EmailEditorProps {
  model: string;
  onModelChange: (content: string) => void;
  associatedModel?: EmailTemplateAssociatedModel;
}

interface EditorImageResponse {
  data: {
    createEditorImage: {
      link: string;
    };
  };
}

const formatValue = (value: string, variables: GetEmailVariablesQueryVariables[]) => {
  const regex = new RegExp(/#\w+/g);
  return value.replace(regex, function (match) {
    const variableKey = match.substring(1).trim();
    const variable = variables.find(v => v.key === variableKey);
    if (variable) {
      return variable.name;
    } else {
      return match;
    }
  });
};

const EmailEditor = ({ model, onModelChange, associatedModel }: EmailEditorProps) => {
  const { locale } = useContext(LocalizationContext);

  const editorRef = useRef<ReactFroalaEditor | null>(null);

  const { data: emailVariables } = useQuery(GET_EMAIL_VARIABLES);

  const { data: currentUser } = useQuery(ME, {
    fetchPolicy: 'cache-first',
  });

  const [isVariableListVisible, setIsVariableListVisible] = useState<boolean>(false);

  useEffect(() => {
    if (editorRef.current) {
      FroalaEditor.DefineIcon('variables', { NAME: 'variable', SVG_KEY: 'tags' });
      FroalaEditor.RegisterCommand('variables', {
        title: 'Variables',
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        callback: function () {
          setIsVariableListVisible(true);
        },
      });
    }
  }, []);

  const onVariablePicked = useCallback(
    (variable: string) => {
      setIsVariableListVisible(false);
      const editor = editorRef?.current?.getEditor() as FroalaEditor;
      if (editor) {
        // @ts-ignore
        editor.html.insert(formatValue(variable, emailVariables?.getEmailVariables ?? []));
      }
    },
    [emailVariables?.getEmailVariables],
  );

  const variableGroups: VariableGroupName[] = useMemo(() => {
    if (associatedModel?.toString().startsWith('Gallery')) {
      return ['Client', 'Gallery'];
    } else if (associatedModel === EmailTemplateAssociatedModel.CUSTOM) {
      return ['Client', 'Gallery', 'Shooting'];
    } else {
      return ['Client', 'Shooting'];
    }
  }, [associatedModel]);

  return (
    <>
      <VariableModal
        onCancel={() => setIsVariableListVisible(false)}
        open={isVariableListVisible}
        onSelect={onVariablePicked}
        variableGroups={variableGroups}
        hasCrm={currentUser?.me?.hasCRM}
      />
      <ReactFroalaEditor
        ref={editorRef}
        config={{
          language: locale === 'fr' ? 'fr' : 'en_gb',
          key: process.env.REACT_APP_FROALA_KEY,
          toolbarButtons: {
            moreText: {
              buttons: [
                'bold',
                'italic',
                'underline',
                'paragraphFormat',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'clearFormatting',
              ],
              align: 'left',
              buttonsVisible: 3,
            },
            moreParagraph: {
              buttons: [
                'alignLeft',
                'alignCenter',
                'alignJustify',
                'outdent',
                'indent',
                'formatOLSimple',
                'formatUL',
                'quote',
              ],
              align: 'right',
              buttonsVisible: 3,
            },

            moreRich: {
              buttons: ['variables', 'insertLink', 'insertImage', 'insertTable', 'emoticons', 'insertHR'],
              align: 'left',
              buttonsVisible: 3,
            },
            moreMisc: {
              buttons: ['undo', 'redo', 'fullscreen', 'selectAll', 'html'],
              align: 'right',
              buttonsVisible: 3,
            },
          },
          fontFamily: {
            'Arial,Helvetica,sans-serif': 'Arial',
            'Georgia,serif': 'Georgia',
            Impact: 'Impact',
            Tahoma: 'Tahoma',
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
          },
          fontFamilySelection: true,
          fontSize: ['14', '16', '18', '24', '32'],
          fontSizeSelection: true,
          attribution: false,
          heightMax: '500',
          heightMin: '300',
          useClasses: false,
          imageMaxSize: 500000,
          imageUploadURL: process.env.REACT_APP_API_URL,
          imageUploadParam: '1',
          imageUploadParams: {
            operations: JSON.stringify({
              variables: { data: { file: null } },
              operationName: 'CreateEditorImage',
              query: print(CREATE_EDITOR_IMAGE),
            }),
            map: JSON.stringify({
              '1': ['variables.data.file'],
            }),
          },
          imageUploadMethod: 'POST',
          videoInsertButtons: ['videoBack', '|', 'videoByURL'],
          requestHeaders: {
            'apollo-require-preflight': true,
          },
          events: {
            initialized: function () {
              const editor = editorRef?.current?.getEditor() as FroalaEditor;
              if (editor?.emoticons) {
                editor.emoticons.insert = function (emoticon: string, image?: string) {
                  if (image) {
                    const url = image.replace('svg', 'png').replace('svg', 'png');
                    const markup = `<img style="width:20px;height:20px;" src="${url}" />`;
                    editor.html.insert(markup, true);
                  }
                  return {};
                };
              }
            },
            'image.beforeUpload': function () {
              const editor = editorRef?.current?.getEditor() as FroalaEditor;
              if (editor) {
                const authVar = AuthVar();
                if (authVar?.accessToken) {
                  editor.opts.requestHeaders.Authorization = `Bearer ${authVar.accessToken}`;
                }
              }
              return true;
            },
            'image.uploaded': function (response: string) {
              const expectedResponse: EditorImageResponse = JSON.parse(response);
              const editor = editorRef?.current?.getEditor() as FroalaEditor;
              if (editor) {
                editor.image.insert(
                  expectedResponse.data.createEditorImage.link,
                  false,
                  {},
                  editor.image.get(),
                  expectedResponse,
                );
              }

              return false;
            },
          },
        }}
        tag="textarea"
        model={model}
        onModelChange={onModelChange}
      />
    </>
  );
};

export default EmailEditor;
