import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Button, Checkbox, Divider, Drawer, Flex, Input, InputRef, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import Icon from 'Components/Atoms/Icon';
import Title from 'Components/Atoms/Title';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useCountries } from 'Hooks/useCountries';
import { useCurrentUser } from 'Hooks/useCurrentUser';

export interface OnEditCountriesProps {
  zoneId: number;
  countryIds: number[];
}

interface Props {
  isVisible: boolean;
  countryIds: number[];
  zoneName: string;
  zoneId: number;
  onCloseDrawer: () => void;
  onEditCountries: (params: OnEditCountriesProps) => void;
}

const DrawerContent = styled.div`
  overflow: hidden;
  height: 100%;
`;

const CountriesContainer = styled(Flex)`
  flex: 1;
  flex-wrap: nowrap;
  width: 100%;
  padding: ${Metrics.baseMargin}px;
  overflow-y: scroll;
`;

const CountryContainer = styled(Flex)`
  width: 100%;
`;

const ButtonContainer = styled(Flex)`
  width: 100%;
  padding: ${Metrics.smallMargin}px 0;
`;

const SpinContainer = styled(Flex)`
  width: 100%;
  height: 300px;
`;

const StyledContainer = styled(Flex)`
  height: 100%;
`;

const SearchContainer = styled(Flex)`
  width: 100%;
  padding: ${Metrics.smallMargin}px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: ${Metrics.smallMargin}px;
`;

const NoResultTitle = styled(Title)`
  margin-left: ${Metrics.smallMargin}px;
`;

const ZoneCountriesDrawer = ({ isVisible, countryIds, onCloseDrawer, onEditCountries, zoneId, zoneName }: Props) => {
  const { t } = useContext(LocalizationContext);

  const searchRef = useRef<InputRef>(null);

  const [search, setSearch] = useState<string>('');
  const [checkedCountriesIds, setCheckedCountriesIds] = useState<number[]>(countryIds);

  const { currentUser } = useCurrentUser();
  const { countries, isLoading } = useCountries();

  const isSearchEnabled = useMemo(() => search !== '', [search]);

  const checkedCountries = useMemo(
    () => countries?.edges.filter(country => checkedCountriesIds.includes(country.id)),
    [checkedCountriesIds, countries?.edges],
  );
  const uncheckedCountries = useMemo(
    () => countries?.edges.filter(country => !checkedCountriesIds.includes(country.id)),
    [checkedCountriesIds, countries?.edges],
  );

  const countriesResult = useMemo(
    () =>
      countries?.edges.filter(country => {
        let countryName = country.nameEn;

        if (currentUser?.me.locale === 'fr') {
          countryName = country.nameFr;
        }

        return countryName.toLowerCase().includes(search.toLowerCase());
      }),
    [countries?.edges, currentUser?.me.locale, search],
  );

  // Use the correct country ids on open because drawer isn't unmounted on close
  useEffect(() => {
    setCheckedCountriesIds(countryIds);
  }, [countryIds]);

  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(`${e.target.value}`);
  }, []);

  const handleCountryCheck = useCallback(
    ({ isChecked, countryId }: { isChecked: boolean; countryId: number }) => {
      if (isChecked) {
        setCheckedCountriesIds([...checkedCountriesIds, countryId]);
      } else {
        setCheckedCountriesIds(checkedCountriesIds.filter(id => id !== countryId));
      }
    },
    [checkedCountriesIds],
  );

  const resetDrawer = useCallback(() => {
    if (searchRef?.current?.input?.value) {
      //TODO: check if working
      searchRef.current.input.value = '';
    }

    setCheckedCountriesIds([]);
    setSearch('');
    onCloseDrawer();
  }, [onCloseDrawer]);

  const handleEditCountries = useCallback(() => {
    onEditCountries({ zoneId, countryIds: checkedCountriesIds });
    resetDrawer();
  }, [checkedCountriesIds, onEditCountries, resetDrawer, zoneId]);

  return (
    <Drawer
      open={isVisible}
      title={<Title level="h2">{t('app.zone.countries.title', { name: zoneName })}</Title>}
      placement="right"
      onClose={resetDrawer}
      width={Metrics.drawer.photo}
      bodyStyle={{ overflow: 'hidden' }}
      closeIcon={<Icon name="close" />}
    >
      <DrawerContent>
        {isLoading && (
          <SpinContainer justify="center" align="center">
            <Spin size="large" />
          </SpinContainer>
        )}
        {countries && !isLoading && (
          <StyledContainer vertical>
            <SearchContainer>
              <Input ref={searchRef} size="large" prefix={<Icon name="search" />} allowClear onChange={handleSearch} />
            </SearchContainer>
            <CountriesContainer vertical>
              {!isSearchEnabled && (
                <Flex vertical>
                  {checkedCountries && checkedCountries.length > 0 && (
                    <>
                      <StyledTitle level="h4">
                        {t('app.zone.countries.selected', { count: checkedCountries.length })}
                      </StyledTitle>
                      {checkedCountries.map(country => (
                        <CountryContainer key={country.id} vertical>
                          <Checkbox
                            onChange={(e: CheckboxChangeEvent) =>
                              handleCountryCheck({ countryId: country.id, isChecked: e.target.checked })
                            }
                            checked
                          >
                            {currentUser?.me.locale === 'fr' ? country.nameFr : country.nameEn}
                          </Checkbox>
                          <Divider type="horizontal" />
                        </CountryContainer>
                      ))}
                    </>
                  )}
                  {uncheckedCountries && uncheckedCountries.length > 0 && (
                    <>
                      {checkedCountries && checkedCountries.length > 0 && (
                        <StyledTitle level="h4">{t('app.zone.countries.other')}</StyledTitle>
                      )}
                      {uncheckedCountries.map(country => (
                        <CountryContainer key={country.id} vertical>
                          <Checkbox
                            onChange={(e: CheckboxChangeEvent) =>
                              handleCountryCheck({ countryId: country.id, isChecked: e.target.checked })
                            }
                          >
                            {currentUser?.me.locale === 'fr' ? country.nameFr : country.nameEn}
                          </Checkbox>
                          <Divider type="horizontal" />
                        </CountryContainer>
                      ))}
                    </>
                  )}
                </Flex>
              )}
              {isSearchEnabled &&
                countriesResult &&
                countriesResult.length > 0 &&
                countriesResult.map(country => (
                  <CountryContainer key={country.id} vertical>
                    <Checkbox
                      onChange={(e: CheckboxChangeEvent) =>
                        handleCountryCheck({ countryId: country.id, isChecked: e.target.checked })
                      }
                    >
                      {currentUser?.me.locale === 'fr' ? country.nameFr : country.nameEn}
                    </Checkbox>
                    <Divider type="horizontal" />
                  </CountryContainer>
                ))}
              {isSearchEnabled && countriesResult && countriesResult.length === 0 && (
                <Flex justify="center" align="center">
                  <Icon name="globe" /> <NoResultTitle level="h4">{t('app.zone.countries.noResult')}</NoResultTitle>
                </Flex>
              )}
            </CountriesContainer>
            <ButtonContainer justify="center" align="center">
              <Button type="primary" onClick={handleEditCountries}>
                <Icon name="edit" />
                {t('app.zone.countries.edit')}
              </Button>
            </ButtonContainer>
          </StyledContainer>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ZoneCountriesDrawer;
