import { gql } from 'Operations/__generated__';

export const GET_LATEST_COMMENTED_PHOTOS = gql(/* GraphQL */ `
  query GetLatestCommentedPhotos($where: LatestCommentedPhotosGetWhereType!) {
    getLatestCommentedPhotos(where: $where) {
      _count
      edges {
        ...PhotoCoreFields
      }
    }
  }
`);
