import { gql } from 'Operations/__generated__';

export const UPDATE_GALLERY_PRESET = gql(/* GraphQL */ `
  mutation UpdateGalleryPreset($where: IdInput!, $data: GalleryUpdatePresetCreateInput!) {
    updateGalleryPreset(where: $where, data: $data) {
      id
      name
      accessPolicy
      watermark {
        id
      }
      locale
      watermarkMode
      workmode
      createdAt
      catalog {
        id
        name
        isDefault
      }
      galleryAppearance {
        ...GalleryAppearanceFields
      }
    }
  }
`);
