import ContentContainer, { Props } from 'Components/Atoms/ContentContainer';

import { Metrics } from 'Themes';

export interface CardProps extends Props {
  type?: 'large' | 'small' | 'medium' | 'thin';
  width?: number | string;
}

const Card = ({ type = 'large', width, className, ...props }: CardProps) => {
  return (
    <ContentContainer
      className={className}
      style={{
        flex: `0 0 ${typeof width === 'string' ? width : (width || Metrics.cards.width.large) + 'px'}`,
        minHeight: Metrics.cards.height[type],
      }}
      {...props}
    />
  );
};

export default Card;
