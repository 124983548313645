import { gql } from 'Operations/__generated__';

export const UPDATE_DELIVERY_ZONE = gql(/* GraphQL */ `
  mutation updateDeliveryZone($where: DeliveryPriceWhere!, $data: UpdateDeliveryPriceInput!) {
    updateDeliveryZone(where: $where, data: $data) {
      zoneId
      price
      deliveryWeightRangeId
      deliveryWeightRange {
        id
        weight
      }
    }
  }
`);
