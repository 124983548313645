import { StrictTypedTypePolicies } from 'Operations/__generated__/apollo-helpers';

const voidText = '-';

const ContactPolicy: StrictTypedTypePolicies['Contact'] = {
  fields: {
    displayName: {
      read(_, { readField }) {
        const company = readField<string | null>('company') || '';
        const firstname = readField<string | null>('firstname') || '';
        const lastname = readField<string | null>('lastname') || '';
        const email = readField<string | null>('email') || '';

        if (company.length) {
          return company;
        }

        const fullName = `${firstname} ${lastname}`.trim();

        if (fullName.length) {
          return fullName;
        }
        if (email.length) {
          return email;
        }

        return voidText;
      },
    },
  },
};

export default ContactPolicy;
