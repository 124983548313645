import { useTheme } from 'antd-style';
import { DrawerConfig } from 'antd/es/config-provider/context';

import { createGlobalStyle } from 'styled-components';

// Different than others because styling wrapper with classname doesn't work
export const useDrawerStyle = (): DrawerConfig & {
  GlobalDrawerClass: ReturnType<typeof createGlobalStyle>;
} => {
  const theme = useTheme();

  const GlobalDrawerClass = createGlobalStyle`
    .ant-drawer {
      &.ant-drawer-right .ant-drawer-content-wrapper {
        right: ${theme.sizeLG}px;
      }
      &.ant-drawer-left .ant-drawer-content-wrapper {
        left: ${theme.sizeLG}px;
      }
      .ant-drawer-close {
        position: absolute;
        top: ${theme.size}px;
        right: 0;
        padding: ${theme.paddingSM}px;
      }
    }
  `;

  return {
    GlobalDrawerClass,
    styles: {
      wrapper: {
        top: theme.sizeLG,
        bottom: theme.sizeLG,
        borderRadius: theme.borderRadius,
        overflow: 'hidden',
      },
      body: {
        padding: 0,
      },
    },
  };
};
