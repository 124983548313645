import React, { useContext } from 'react';

import { Card, Popconfirm } from 'antd';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';
import WatermarkPreview, { WatermarkPreviewProps } from 'Components/Molecules/WatermarkPreview';

import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const CardStyled = styled(Card)`
  width: 274px;
`;

const PopConfirmBody = styled.div`
  max-width: 200px;
`;

interface WatermarkCardProps extends WatermarkPreviewProps {
  id: number;
  name: string;
  watermark?: string;
  galleries?: number;
  handleEditClick?: (params: { id: number }) => void;
  handleDeleteConfirm?: (params: { id: number }) => void;
}

const { Meta } = Card;

const WatermarkCard = ({
  id,
  watermarkType,
  name,
  watermark,
  font,
  text,
  textColor,
  repeat,
  opacity,
  isResized,
  size,
  position,
  withStroke,
  strokeColor,
  withBackgroundText,
  backgroundTextColor,
  galleries,
  handleEditClick,
  handleDeleteConfirm,
  ...props
}: WatermarkCardProps) => {
  const { t } = useContext(LocalizationContext);
  const cardActions = [];

  if (handleEditClick) {
    cardActions.push(
      <RoundButton
        icon="edit"
        tooltipTitle={t('app.common.edit')}
        onClick={() => {
          handleEditClick({ id });
        }}
      />,
    );
  }

  if (handleDeleteConfirm) {
    let title: React.ReactNode = t('app.confirm.delete');
    if (galleries && galleries > 0) {
      title = (
        <div>
          <div>
            <b>{t('app.confirm.delete')}</b>
          </div>
          <PopConfirmBody>{t('app.watermarks.delete.usedByGalleries', { count: galleries })}</PopConfirmBody>
        </div>
      );
    }

    cardActions.push(
      <Popconfirm
        title={title}
        onConfirm={() => {
          if (handleDeleteConfirm) {
            handleDeleteConfirm({ id });
          }
        }}
      >
        <RoundButton icon="delete" tooltipTitle={t('app.common.delete')} danger />
      </Popconfirm>,
    );
  }

  return (
    <CardStyled
      bordered={false}
      cover={
        <WatermarkPreview
          watermarkType={watermarkType}
          image={watermark}
          font={font}
          text={text}
          textColor={textColor}
          repeat={repeat}
          opacity={opacity}
          isResized={isResized}
          size={size}
          position={position}
          withStroke={withStroke}
          strokeColor={strokeColor}
          withBackgroundText={withBackgroundText}
          backgroundTextColor={backgroundTextColor}
        />
      }
      actions={cardActions}
      {...props}
    >
      <Meta title={name} description={t('app.watermarks.usedByGalleries', { count: galleries })} />
    </CardStyled>
  );
};

export default WatermarkCard;
