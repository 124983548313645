import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Comment } from '@ant-design/compatible';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Form, List } from 'antd';
import { TokenPayload } from 'types/Token';

import clsx from 'clsx';

import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { decodeJwt } from 'jose';
import { compact, orderBy } from 'lodash';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';

import { LocalizationContext } from 'i18n';

import useGallery from 'Hooks/useGallery';

import dayjs from 'Services/DayjsService';

import { AuthVar } from 'Operations/Cache';

import {
  GalleryWorkmode,
  Photo,
  PhotoCharacteristic
} from 'Operations/__generated__/graphql';

import { GET_PHOTO_COMMENTS } from 'Operations/Queries/Photo/GetPhotoComments';

import { CREATE_COMMENT } from 'Operations/Mutations/Comment/CreateComment';
import { READ_COMMENTS } from 'Operations/Mutations/Comment/ReadComments';

interface Props {
  photoId: number;
  galleryId: number;
  statuses: PhotoCharacteristic[];
}

const Comments = ({ photoId, galleryId, statuses }: Props) => {
  const authVar = AuthVar();
  const { t } = useContext(LocalizationContext);

  const [commentsWhere, setCommentsWhere] = useState({
    page: 1,
    perPage: 5,
  });

  const { email, firstName, lastName, userId } = useMemo(() => {
    const { email, firstname, lastname, userId } = (authVar.accessToken
      ? decodeJwt(authVar.accessToken) || {}
      : {}) as unknown as TokenPayload;
    return { email, firstName: firstname || '-', lastName: lastname || '-', userId };
  }, [authVar.accessToken]);

  const [createComment] = useMutation(CREATE_COMMENT);
  const [readComments] = useMutation(READ_COMMENTS);

  const { gallery } = useGallery({ id: galleryId });

  const { data, loading: isCommentsLoading } = useQuery(GET_PHOTO_COMMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        id: photoId,
        galleryId,
      },
      commentsWhere,
    },
  });

  const commentConnection = data?.getPhoto.__typename === 'PhotoAdmin' ? data?.getPhoto.comments : undefined;

  const comments = useMemo(() => orderBy(commentConnection?.edges || [], 'createdAt', 'asc'), [commentConnection]);

  const isRawRetouched = useMemo(
    () =>
      gallery?.workmode === GalleryWorkmode.RETOUCH_FIRST &&
      statuses.includes(PhotoCharacteristic.ORDERED) &&
      statuses.includes(PhotoCharacteristic.DIGITAL) &&
      !statuses.includes(PhotoCharacteristic.RETOUCHED) &&
      !statuses.includes(PhotoCharacteristic.NEEDS_RETOUCH),
    [gallery?.workmode, statuses],
  );

  const hasFetchAllComments = useMemo(
    () => (commentConnection?._count || 0) <= (commentConnection?.edges.length || 0),
    [commentConnection?._count, commentConnection?.edges.length],
  );

  const handleLoadMore = useCallback(() => {
    if (!hasFetchAllComments) {
      setCommentsWhere({
        ...commentsWhere,
        page: Math.floor((comments?.length || 0) / commentsWhere.perPage) + 1,
      });
    }
  }, [commentsWhere, comments?.length, hasFetchAllComments]);

  const handleMarkAsRead = useCallback(
    (id: number) => {
      // Take only the unread comments that are before the given comment
      const commentIndex = comments?.findIndex(c => c.id === id);
      const ids = compact(comments?.filter((c, index) => commentIndex >= index && !c.isRead).map(c => c.id));

      readComments({
        notifyOnNetworkStatusChange: true,
        variables: {
          where: {
            ids,
          },
        },
        update(cache, { data }) {
          const readCount = data?.readComments.length || 0;
          cache.modify<Photo>({
            id: cache.identify({
              id: photoId,
              __typename: 'PhotoAdmin',
            }),
            fields: {
              unreadCommentsCount(existing = 0) {
                return Math.max(existing - readCount, 0);
              },
            },
          });
        },
      });
    },
    [comments, photoId, readComments],
  );

  return (
    <>
      <List
        className="comment-list"
        header={
          <Container justify="space-between">
            <p>{t('app.comments.count', { count: commentConnection?._count || 0 })}</p>
            {!hasFetchAllComments && (
              <Button loading={isCommentsLoading} onClick={handleLoadMore}>
                {t('app.comments.loadPrevious')}
              </Button>
            )}
          </Container>
        }
        itemLayout="horizontal"
        dataSource={comments}
        loading={isCommentsLoading}
        renderItem={comment => (
          <li key={comment.id}>
            <Comment
              className={clsx(
                'ant-comment--discussion',
                comment.userId === userId && 'ant-comment--currentUser',
                comment.userId !== userId && 'ant-comment--otherUser',
                !comment.isRead && 'ant-comment--unread',
              )}
              author={
                comment.userId === userId
                  ? t('app.common.you')
                  : `${comment.firstName} ${comment.lastName} (${comment.email})`
              }
              content={comment.content}
              datetime={dayjs(comment.createdAt).format('LLL')}
              actions={
                !comment.isRead
                  ? [
                      <a
                        style={{ display: 'flex', gap: 4, alignItems: 'center' }}
                        onClick={() => handleMarkAsRead(comment.id)}
                      >
                        <Icon name="check" size={12} />
                        {t('app.comments.markAsRead')}
                      </a>,
                    ]
                  : undefined
              }
            />
          </li>
        )}
      />
      {isRawRetouched && <Alert type="warning" message={t('app.comments.rawRetouched')} />}
      {!isRawRetouched && (
        <Formik
          initialValues={{
            content: '',
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              await createComment({
                variables: {
                  where: {
                    photoId,
                  },
                  data: {
                    firstName,
                    lastName,
                    content: values.content,
                    email,
                  },
                },
                update(cache, { data }) {
                  if (!data) {
                    return;
                  }
                  const { createComment } = data;

                  const result = cache.readQuery({
                    query: GET_PHOTO_COMMENTS,
                    variables: {
                      where: {
                        id: photoId,
                        galleryId,
                      },
                      commentsWhere,
                    },
                  });

                  if (!result) {
                    return;
                  }

                  const { getPhoto: getPhotoData } = result;

                  if (getPhotoData.__typename === 'PhotoAdmin') {
                    const edges = getPhotoData.comments.edges.map(c => ({ ...c, isRead: true }));

                    const getPhoto = {
                      ...getPhotoData,
                      comments: {
                        ...getPhotoData.comments,
                        _count: getPhotoData.comments._count + 1,
                        edges: [...edges, createComment],
                      },
                    };

                    cache.writeQuery({
                      query: GET_PHOTO_COMMENTS,
                      variables: {
                        where: {
                          id: photoId,
                          galleryId,
                        },
                        commentsWhere,
                      },
                      data: {
                        getPhoto,
                      },
                    });
                  }
                },
              });

              setSubmitting(false);
              resetForm();
            } catch (error) {
              setSubmitting(false);
            }
          }}
        >
          {({ values, isSubmitting, handleSubmit }) => (
            <Form onSubmitCapture={handleSubmit}>
              <Container align="flex-end">
                <Form.Item style={{ flex: 1 }} hasFeedback={false}>
                  <Input.TextArea name="content" placeholder={t('app.common.comment', { count: 1 })} size="large" />
                </Form.Item>
                <Form.Item hasFeedback={false}>
                  <Button type="primary" htmlType="submit" disabled={isSubmitting || values.content.length === 0}>
                    {t('app.common.send')}
                  </Button>
                </Form.Item>
              </Container>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Comments;
