import { gql } from 'Operations/__generated__';

export const UPDATE_COMPANY_SETTINGS = gql(/* GraphQL */ `
  mutation UpdateCompanySettings($data: CompanySettingsInputType!) {
    updateCompanySettings(data: $data) {
      id
      settings {
        defaultGalleryDownload
        defaultGalleryWorkmode
        isShopPageEnabled
      }
    }
  }
`);
