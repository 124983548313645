import React from 'react';

import ContentLoader from 'react-content-loader';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  padding-bottom: ${Metrics.tinyMargin}px;
  border-bottom: 1px solid ${Colors.border};
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderSkeleton = () => {
  return (
    <HeaderContainer>
      <LeftContainer>
        <ContentLoader
          speed={2}
          width={270}
          height={67}
          viewBox="0 0 270 67"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="17" ry="17" width="210" height="34" />
          <rect x="0" y="47" rx="10" ry="10" width="270" height="20" />
        </ContentLoader>
      </LeftContainer>
    </HeaderContainer>
  );
};

export default HeaderSkeleton;
