import { useQuery } from '@apollo/client';

import { GET_COUNTRIES } from 'Operations/Queries/Country/GetCountries';

const useCountries = () => {
  const { data, loading: isLoading, error } = useQuery(GET_COUNTRIES);

  return { countries: data?.getCountries, isLoading, error };
};

export { useCountries };
