const baseSpacing = 24;
const buttonHeight = 44;
const inputHeight = 44;
const fileBoxSize = 64;

const screenWidth = window.innerWidth;

const metrics = {
  baseMargin: baseSpacing,
  doubleBaseMargin: baseSpacing * 2,
  mediumMargin: (baseSpacing * 2) / 3,
  smallMargin: baseSpacing / 2,
  tinyMargin: baseSpacing / 3,
  largeMargin: baseSpacing + baseSpacing / 3,
  screenWidth,
  buttonHeight,
  inputHeight,
  fileBoxSize,
  boxRadius: 18,
  border: {
    small: 2,
    large: 3,
  },
  tooltipInfoIcon: 20,
  icons: {
    tiny: 15,
    small: 20,
    medium: 30,
    large: 45,
    xl: 50,
  },
  sider: {
    width: {
      base: 360,
    },
  },
  drawer: {
    notification: screenWidth < 412 ? '100%' : 335,
    photo: screenWidth < 412 ? '100%' : 548,
    products: screenWidth < 412 ? '100%' : 548,
  },
  cards: {
    height: {
      large: 290,
      medium: 115,
      small: 100,
      thin: 85,
    },
    width: {
      large: 400,
      small: 350,
      thin: 300,
    },
  },
};

export default metrics;
