import React, { useMemo } from 'react';

import ContentLoader from 'react-content-loader';

interface StatCardsSkeletonProps {
  cardsCount?: number;
}

const StatCardsSkeleton = ({ cardsCount }: StatCardsSkeletonProps) => {
  const cards = useMemo(() => {
    const arrayOfStatCards = [];
    const cardsNumber = cardsCount || 4;
    for (let i = 0; i < cardsNumber; i++) {
      arrayOfStatCards.push(<rect x={i * 112} y="0" rx="16" ry="16" width="88" height="91" />);
    }
    return arrayOfStatCards;
  }, [cardsCount]);

  return (
    <ContentLoader
      speed={2}
      width={424}
      height={91}
      viewBox="0 0 424 91"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {cards}
    </ContentLoader>
  );
};

export default StatCardsSkeleton;
