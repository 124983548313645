import { gql } from 'Operations/__generated__';

export const UPDATE_VIDEO_GALLERY = gql(/* GraphQL */ `
  mutation UpdateVideoGallery($where: GalleryUpdateWhereType!, $data: GalleryUpdateInputType!) {
    updateGallery(where: $where, data: $data) {
      ... on GalleryAdmin {
        video
      }
    }
  }
`);
