import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { AddPhotosToOrder, AddPhotosToOrderFormProps } from 'Forms/AddPhotosToOrder';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

const AddPhotosToOrderModal = (props: AddPhotosToOrderFormProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <ModalContainer title={t(`app.gallery.photos.addToOrder.action`)} icon={Images.packages}>
      <AddPhotosToOrder {...props} />
    </ModalContainer>
  );
};

export default AddPhotosToOrderModal;
