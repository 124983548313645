import React from 'react';

import { createRoot } from 'react-dom/client';

import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';

import App from './App';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
