import { gql } from 'Operations/__generated__';

export const MOVE_PHOTO_TO_FOLDER = gql(/* GraphQL */ `
  mutation MovePhotoToFolder($where: PhotosUpdateWhereType!, $data: PhotoUpdateFolderType!) {
    movePhotoToFolder(data: $data, where: $where) {
      id
      folder {
        id
      }
    }
  }
`);
