import React, { useContext } from 'react';

import { Flex, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IdProps } from 'types/Common';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';

import { GetProductCategoriesQuery, OrderType } from 'Operations/__generated__/graphql';

export interface OnChangeProps {
  page: number;
  perPage: number;
  order: OrderType;
}

interface Props {
  productCategoriesTotal: number;
  productCategories: GetProductCategoriesQuery['getProductCategories']['edges'];
  paginationCurrent: number;
  paginationSize?: number;
  isLoading: boolean;
  onChange: (params: OnChangeProps) => void;
  onEdit?: (params: IdProps) => void;
  onDelete?: (params: IdProps) => void;
}

const ProductCategoriesTable = ({
  productCategories,
  productCategoriesTotal,
  isLoading,
  paginationCurrent,
  paginationSize,
  onEdit,
  onChange,
  onDelete,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);

  const columns: ColumnsType<GetProductCategoriesQuery['getProductCategories']['edges'][0]> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 120,
      render: (_, record) => (
        <Flex gap="small">
          {record.isEditable && (
            <RoundButton
              icon="edit"
              tooltipTitle={t('app.common.edit')}
              onClick={() => {
                if (onEdit) {
                  onEdit({ id: record.id });
                }
              }}
            />
          )}
          {record.isDeletable && (
            <Popconfirm
              title={t('app.confirm.delete')}
              onConfirm={() => {
                if (onDelete) {
                  onDelete({ id: record.id });
                }
              }}
            >
              <RoundButton icon="delete" tooltipTitle={t('app.common.delete')} danger />
            </Popconfirm>
          )}
        </Flex>
      ),
    },
    {
      title: t('app.common.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('app.common.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => dayjs(text).format('LLL'),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={productCategories}
      columns={columns}
      loading={isLoading}
      pagination={{
        total: productCategoriesTotal,
        pageSize: paginationSize,
        current: paginationCurrent,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      onChange={pagination => {
        const order: OrderType = OrderType.DESC;

        const params: OnChangeProps = {
          page: pagination.current ? pagination.current : 1,
          perPage: pagination.pageSize ? pagination.pageSize : 20,
          order,
        };

        onChange(params);
      }}
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()}`}
    />
  );
};

export default ProductCategoriesTable;
