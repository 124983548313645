import { gql } from 'Operations/__generated__';

export const UPDATE_GALLERY_PRESET_INCLUDED_PRODUCTS = gql(/* GraphQL */ `
  mutation UpdateGalleryPresetIncludedProducts($where: IdInput!, $data: UpdateGalleryIncludedProductsInputType!) {
    updateGalleryPresetIncludedProducts(where: $where, data: $data) {
      id
      catalog {
        id
      }
      includedProducts {
        ...IncludedProductCoreFields
      }
      mandatoryProduct {
        id
        name
      }
    }
  }
`);
