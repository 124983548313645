import { gql } from 'Operations/__generated__';

export const GET_FEATURE_REQUESTS = gql(/* GraphQL */ `
  query GetFeatureRequests($where: FeaturesGetWhereInput) {
    getFeatureRequests(where: $where) {
      _count
      edges {
        ...FeatureRequestCoreFields
        comments {
          _count
        }
      }
    }
  }
`);
