import React, { createContext, useContext, useState } from 'react';

export interface GalleryVisit {
  lastTimestamp?: Date;
  onVisit(): void;
}
const initialState: GalleryVisit = {
  lastTimestamp: new Date(),
  onVisit: () => {
    throw new Error('Not implemented');
  },
};

export const GalleryAppContext = createContext(initialState);
export const useGalleryApp = () => useContext(GalleryAppContext);

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const GalleryVisitsProvider: React.FC<React.PropsWithChildren<Props>> = ({ children }: Props) => {
  const [lastTimestamp, setLastTimestamp] = useState<Date>();
  const onVisit = () => {
    setLastTimestamp(new Date());
  };

  return (
    <GalleryAppContext.Provider
      value={{
        onVisit,
        lastTimestamp,
      }}
    >
      {children}
    </GalleryAppContext.Provider>
  );
};
