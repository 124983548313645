import { useQuery } from '@apollo/client';

import { ME } from 'Operations/Queries/User/Me';

const useCurrentUser = () => {
  const { data: currentUser, loading: isLoading, error } = useQuery(ME);

  return { currentUser, isLoading, error };
};

export { useCurrentUser };
