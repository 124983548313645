import { gql } from 'Operations/__generated__';

export const UPDATE_FEATURE_STATUS = gql(/* GraphQL */ `
  mutation UpdateFeatureStatus($where: IdInput!, $data: UpdateFeatureStatusInput!) {
    updateFeatureStatus(where: $where, data: $data) {
      id
      status
    }
  }
`);
