import { gql } from 'Operations/__generated__';

export const GET_CATALOGS = gql(/* GraphQL */ `
  query GetCatalogs($where: PaginatedInput) {
    getCatalogs(where: $where) {
      _count
      edges {
        id
        name
        isDefault
        vat
        createdAt
        products {
          _count
        }
      }
    }
  }
`);
