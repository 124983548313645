import { gql } from 'Operations/__generated__';

export const GET_PICKLISTS = gql(/* GraphQL */ `
  query GetPicklists($where: IdInput!, $paginate: PaginatedInput) {
    getPicklists(where: $where, paginate: $paginate) {
      _count
      edges {
        ...PicklistCoreFields
        photos {
          _count
        }
      }
      pageInfo {
        currentPage
        isFirstPage
        isLastPage
        pageCount
      }
    }
  }
`);
