import React, { useContext } from 'react';

import { Flex, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { Colors, Metrics } from 'Themes';
import styled, { ThemeContext } from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';

import { GetPaginatedCatalogsQuery } from 'Operations/__generated__/graphql';

export interface HandleChangeProps {
  page: number;
}

interface Props {
  total: number;
  isLoading: boolean;
  paginationSize?: number;
  data?: GetPaginatedCatalogsQuery['getPaginatedCatalogs']['edges'];
  handleOnChange: (props: HandleChangeProps) => void;
  handleDeleteConfirm: (params: { id: number }) => void;
}

const Name = styled(Text)`
  display: flex;
  align-items: center;
`;

const DefaultIcon = styled(Icon)`
  margin-right: ${Metrics.tinyMargin}px;
`;

const CatalogsTable = ({ data, isLoading, paginationSize, handleOnChange, total, handleDeleteConfirm }: Props) => {
  const { t } = useContext(LocalizationContext);
  const theme = useContext(ThemeContext);

  const columns: ColumnsType<GetPaginatedCatalogsQuery['getPaginatedCatalogs']['edges'][0]> = [
    {
      title: t('app.common.actions'),
      key: 'operation',
      width: 120,
      render: (_, record) => (
        <Flex justify="space-between">
          <Link to={`/app/catalogs/${record.id}`}>
            <RoundButton icon="edit" tooltipTitle={t('app.common.edit')} />
          </Link>

          <Popconfirm
            title={t('app.confirm.delete')}
            onConfirm={() => {
              handleDeleteConfirm({ id: record.id });
            }}
          >
            <RoundButton icon="delete" tooltipTitle={t('app.common.delete')} danger />
          </Popconfirm>
        </Flex>
      ),
    },
    {
      title: t('app.common.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) =>
        record.isDefault ? (
          <Name size="medium" color={Colors.tableBlack}>
            <DefaultIcon name="star" color={Colors.secondaryOptional} size={20} />
            <span>{text}</span>
          </Name>
        ) : (
          text
        ),
    },
    {
      title: t('app.common.vat'),
      dataIndex: 'vat',
      key: 'vat',
      render(vat) {
        return `${vat}%`;
      },
    },
    {
      title: t('app.catalog.totalProduct'),
      key: 'products',
      dataIndex: 'products',
      render(products) {
        return products?._count || 0;
      },
    },
    {
      title: t('app.common.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => dayjs(text).format('LLL'),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: true }}
      loading={isLoading}
      pagination={{
        total,
        pageSize: paginationSize,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      className={`ant-table-wrapper--${theme?.name.toLocaleLowerCase()}`}
      onChange={pagination => {
        handleOnChange({
          page: pagination.current ? pagination.current : 1,
        });
      }}
    />
  );
};

export default CatalogsTable;
