import { gql } from 'Operations/__generated__';

export const GET_CATALOG_PRODUCTS = gql(/* GraphQL */ `
  query GetCatalogProducts($where: IdInput!, $productsWhere: CatalogProductsWhereInput) {
    getCatalog(where: $where) {
      id
      products(where: $productsWhere) {
        _count
        edges {
          ...ProductCoreFields
        }
      }
      favoriteProducts {
        _count
        _maxFavorites
        edges {
          id
        }
      }
    }
  }
`);
