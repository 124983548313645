import { Link } from 'react-router-dom';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { css } from 'styled-components';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  padding?: 'baseMargin' | 'doubleBaseMargin' | 'smallMargin' | 'tinyMargin';
  rounded?: boolean;
  shadow?: boolean;
  backgroundColor?: string;
  full?: boolean;
  onClick?: () => void;
  href?: string;
  target?: string;
}

const StyledContentContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? Metrics[padding] : 0)}px;
  box-sizing: border-box;
  background-color: ${({ backgroundColor = 'transparent' }) => backgroundColor};
  ${({ shadow }) =>
    shadow &&
    css<Props>`
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    `}
  ${({ full }) =>
    full &&
    css<Props>`
      width: 100%;
    `}
  border-radius: ${({ rounded }) => (rounded ? Metrics.boxRadius : 0)}px;
  ${({ onClick, href }) =>
    (onClick || href) &&
    css<Props>`
      cursor: pointer;
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      &:hover {
        transform: scale(1.05, 1.05);
      }
    `}

  & > a {
    display: block;
    text-decoration: none;
  }
`;

const ContentContainer = ({ children, target, ...props }: Props) => (
  <StyledContentContainer {...props}>
    {props.href ? (
      <Link to={props.href.startsWith('http') ? props.href : { pathname: props.href }} target={target}>
        {children}
      </Link>
    ) : (
      children
    )}
  </StyledContentContainer>
);

export default ContentContainer;
