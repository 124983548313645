import React, { useCallback, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { App } from 'antd';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { NewOptionForm, NewOptionFormPayload, NewOptionFormProps } from 'Forms/Option';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useRoles } from 'Hooks/useRoles';

import { PricingPlanType } from 'Operations/__generated__/graphql';

import { GET_PRODUCT } from 'Operations/Queries/Product/GetProduct';

import { CREATE_PRICING_PLAN } from 'Operations/Mutations/PricingPlan/CreatePricingPlan';
import { CREATE_PRODUCT_OPTION } from 'Operations/Mutations/ProductOption/CreateProductOption';

type ProductOptionModalProps = Pick<NewOptionFormProps, 'product' | 'defaultValues'>;

const ProductOptionModal = ({ product, defaultValues }: ProductOptionModalProps) => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const { isAdmin } = useRoles();
  const { closeModal } = useModals();

  const [createProductOption] = useMutation(CREATE_PRODUCT_OPTION, {
    refetchQueries: [
      {
        query: GET_PRODUCT,
        variables: {
          where: { id: product.id },
        },
      },
    ],
  });
  const [createPricingPlan] = useMutation(CREATE_PRICING_PLAN);

  const handleSubmit = useCallback(
    async ({ values, formikBag }: NewOptionFormPayload) => {
      try {
        const { isMainOption, categoryId, name, previewImage, providerDescription, providerPrice } = values;

        let pricingPlanId: number | undefined;

        if (isMainOption && !product.isPreconfiguredProduct) {
          const pricingPlanResponse = await createPricingPlan({
            variables: {
              data: {
                name,
                type: PricingPlanType.UNITARY,
                items: [
                  {
                    quantity: 1,
                    price: providerPrice || 20,
                  },
                ],
                isPreset:
                  (product.isPreconfiguredProduct || product.isFotostudioProduct) && !product.presetProductId && isAdmin
                    ? true
                    : false,
              },
            },
          });

          pricingPlanId = pricingPlanResponse.data?.createPricingPlan.id;
        }

        await createProductOption({
          variables: {
            where: {
              id: categoryId,
            },
            data: {
              name,
              previewImage,
              providerDescription,
              providerPrice,
              pricingPlanId,
            },
          },
        });

        message.success(t('app.message.productOption.create.success'));
      } catch (error) {
        const errorMessage = t(`app.message.error.somethingWentWrong`);

        message.error(errorMessage);
      } finally {
        formikBag.setSubmitting(false);
        closeModal();
      }
    },
    [
      closeModal,
      createPricingPlan,
      createProductOption,
      isAdmin,
      product.isFotostudioProduct,
      product.isPreconfiguredProduct,
      product.presetProductId,
      t,
    ],
  );

  return (
    <ModalContainer title={t(`app.productOption.modal.new`)} icon={Images.tag}>
      <NewOptionForm onSubmit={handleSubmit} defaultValues={defaultValues} product={product} />
    </ModalContainer>
  );
};

export default ProductOptionModal;
