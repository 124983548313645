import { gql } from 'Operations/__generated__';

export const GET_LABO_ORDERS = gql(/* GraphQL */ `
  query GetLaboOrders($where: GetLaboOrdersInput!) {
    getLaboOrders(where: $where) {
      id
      from
      to
      labo
      packageTotalExcVat
      transportFeeExcVat
      totalExcVat
      fileUrl
      orders(where: { page: 1 }) {
        _count
      }
    }
  }
`);
