import React, { useContext, useRef } from 'react';

import { Tooltip } from 'antd';
import { createStyles } from 'antd-style';

import ContentContainer from 'Components/Atoms/ContentContainer';
import FilePickerInput from 'Components/Atoms/FilePickerInput';
import Icon from 'Components/Atoms/Icon';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

interface FilePickerProps {
  multiple: boolean;
  enabled: boolean;
  onFilesPicked: (files: File[]) => void;
  filetypes?: string[];
}

const useStyles = createStyles(({ css, token }) => ({
  filePickerContainer: css`
    top: ${Metrics.largeMargin}px;
    right: ${Metrics.largeMargin}px;
    width: 144px;
    height: 95px;
    justify-content: center;
    align-items: center;
    border: 3px solid ${token.colorPrimary};
    border-radius: 12px;
    cursor: pointer;

    &:hover {
      background-color: ${token.colorPrimaryBg};

      svg {
        color: ${token.colorPrimary};
      }
    }
  `,
  icon: css`
    color: ${token.colorPrimary};
    &:hover {
      color: ${token.colorPrimary};
    }
  `,
}));

const FilePicker = ({ multiple, enabled, onFilesPicked, filetypes }: FilePickerProps) => {
  const { t } = useContext(LocalizationContext);
  const { styles } = useStyles();

  const fileInput = useRef<HTMLInputElement>(null);

  const onButtonClick = () => {
    if (enabled && fileInput && fileInput.current) {
      fileInput.current.click();
    }
  };

  return (
    <Tooltip title={t('app.common.addPhotos')}>
      <ContentContainer onClick={onButtonClick} rounded className={styles.filePickerContainer}>
        <FilePickerInput ref={fileInput} multiple={multiple} onFilesPicked={onFilesPicked} filetypes={filetypes} />
        <Icon name="add-photo" size={44} className={styles.icon} />
      </ContentContainer>
    </Tooltip>
  );
};

export default FilePicker;
