import { createStyles } from 'antd-style';

export const usePopconfirmStyle = createStyles(({ css, prefixCls }) => ({
  className: css`
    &.${prefixCls}-popconfirm {
      .${prefixCls}-popconfirm-buttons {
        display: flex;
        justify-content: space-between;
      }
    }
  `,
}));
