import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

interface Props {
  padding?: 'baseMargin' | 'doubleBaseMargin' | 'smallMargin' | 'tinyMargin';
  paddingType?: 'all' | 'horizontal' | 'vertical';
}

const CardContainer = styled.div<Props>`
  display: flex;
  flex-wrap: wrap;
  gap: ${Metrics.baseMargin}px;
  justify-content: flex-start;
  padding: ${({ padding, paddingType = 'all' }) => {
    if (padding) {
      switch (paddingType) {
        case 'horizontal':
          return `0 ${Metrics[padding]}px`;

        case 'vertical':
          return `${Metrics[padding]}px 0`;

        case 'all':
        default:
          return `${Metrics[padding]}px`;
      }
    } else {
      return '0px';
    }
  }};
`;

export default CardContainer;
