import React, { useContext, useMemo } from 'react';

import { makeReference, useSubscription } from '@apollo/client';
import { ReplaceWithReference } from 'types/ModelConnection';
import { PhotoCustomOrder } from 'types/PhotoCustomOrder';

import { PHOTO_CORE_FIELDS } from 'Operations/Fragments/photoFragment';

import { LocalizationContext } from 'i18n';

import { updateFolderPhotosCount } from 'Helpers/updateFolderPhotoCount';

import { getKeyIdentifier } from 'Operations/Cache/Policies/Gallery';

import {
  GalleryAdmin,
  GalleryPhotosOrder,
  GetOrdersQuery,
  PhotoCharacteristic,
} from 'Operations/__generated__/graphql';

import { ON_PHOTO_ADDED } from 'Operations/Subscriptions/OnPhotoAdded';

const PER_PAGE = 50;

const PhotoAddedProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { locale } = useContext(LocalizationContext);
  const naturalSortCollator = useMemo(() => {
    return new Intl.Collator(locale, { numeric: true, sensitivity: 'base' });
  }, [locale]);

  useSubscription(ON_PHOTO_ADDED, {
    onData({ client, data }) {
      if (data.data?.OnPhotoAdded) {
        const { photo, orderItemId } = data.data.OnPhotoAdded;

        // Photo retouched uploaded from retouch tab and linked to an order
        if (photo.isRetouched && orderItemId) {
          client.cache.modify<ReplaceWithReference<GetOrdersQuery['getOrders']['edges'][0]['items'][0], 'photos'>>({
            id: client.cache.identify({ __typename: 'OrderItem', id: orderItemId }),
            fields: {
              photos(existing) {
                if ('_count' in existing) {
                  const edges = (existing?.edges || []).slice();
                  const newRef = client.cache.writeFragment({
                    data: photo,
                    fragment: PHOTO_CORE_FIELDS,
                  });

                  if (newRef) {
                    edges.push(newRef);
                  }

                  return {
                    ...existing,
                    edges,
                    _count: (existing?._count || 0) + 1,
                  };
                }
                return existing;
              },
            },
          });

          const keyIdentifier = getKeyIdentifier({
            galleryId: photo.galleryId,
            characteristics: [PhotoCharacteristic.RETOUCHED],
            folderId: photo.folder?.id,
            queryName: 'getPhotos',
          }) as 'photos';

          client.cache.modify<ReplaceWithReference<GalleryAdmin, 'photos'>>({
            id: client.cache.identify(makeReference('ROOT_QUERY')),
            fields: {
              [keyIdentifier](existing) {
                if ('_count' in existing) {
                  return {
                    ...existing,
                    _count: (existing?._count || 0) + 1,
                  };
                }
                return existing;
              },
            },
          });
        } else {
          // Photo uploaded from photo tab
          client.cache.modify<ReplaceWithReference<GalleryAdmin, 'photos'>>({
            id: client.cache.identify({ __typename: 'GalleryAdmin', id: photo.galleryId }),
            fields: {
              photos(existing, { storeFieldName, toReference, readField }) {
                // Check if the photo should be added to this specific field based on gallery and folder
                if (
                  !('__ref' in existing) && // Photo is not already referenced
                  ((photo?.folder?.id && storeFieldName.includes(photo.folder.id.toString())) || // In a specific folder
                    (!photo?.folder?.id && storeFieldName.includes('root'))) // Or in the root gallery
                ) {
                  // Read gallery photo order and custom order from cache
                  const photosOrder = readField<GalleryPhotosOrder>('photosOrder');
                  const photosCustomOrder = readField<PhotoCustomOrder>('photosCustomOrder');

                  if (photosOrder && photosCustomOrder) {
                    // Extract custom order for specific folder (default if not found)
                    const customOrder = photosCustomOrder[photo?.folder?.id || 'default'] || [];

                    // Check if using filename sorting
                    const isFilenameSort =
                      photosOrder === GalleryPhotosOrder.FILENAME_ASC ||
                      photosOrder === GalleryPhotosOrder.FILENAME_DESC;

                    // Check if using custom sorting
                    const isCustomSort =
                      photosOrder === GalleryPhotosOrder.RANDOM || photosOrder === GalleryPhotosOrder.CUSTOM;

                    // Check if photo position is in loaded range for custom order
                    const edges = existing?.edges.slice() || [];
                    const isInCustomOrderRange =
                      isCustomSort && customOrder.length + 1 < PER_PAGE * Math.ceil((edges.length || 1) / PER_PAGE);

                    // Check if photo position is in loaded range for filename order
                    let isInFilenameOrderRange = isFilenameSort && edges.length < PER_PAGE;
                    if (isFilenameSort && !isInFilenameOrderRange) {
                      // Check if the current photo should be inserted in the current loaded range
                      isInFilenameOrderRange = existing?.edges?.some(exisitingPhoto => {
                        const ref = toReference({ ...exisitingPhoto });
                        const name = readField<string>('name', ref);
                        if (!name) return false;
                        const isLower = naturalSortCollator.compare(photo.name, name) < 0;
                        return isLower; // Return true if photo name is lower alphabetically
                      });
                    }

                    // Write the photo data fragment to the cache and get a reference
                    const ref = client.cache.writeFragment({
                      data: photo,
                      fragment: PHOTO_CORE_FIELDS,
                    });

                    // Add photo to "edges" based on order type and range
                    if (isInFilenameOrderRange) {
                      if (ref) {
                        edges.push(ref);
                      }

                      // Sort edges alphabetically based on names
                      edges.sort((a, b) => {
                        return naturalSortCollator.compare(
                          readField<string>('name', a) || '',
                          readField<string>('name', b) || '',
                        );
                      });

                      // Reverse order if descending
                      if (photosOrder === GalleryPhotosOrder.FILENAME_DESC) {
                        edges.reverse();
                      }
                    } else if (isInCustomOrderRange) {
                      if (ref) {
                        edges.push(ref);
                      }
                    }

                    // Return the updated GalleryAdmin object with updated photo data
                    return {
                      ...existing,
                      edges,
                      _count: (existing?._count || 0) + 1, // Increment photo count
                    };
                  }
                }

                // Return the existing "photos" field if conditions are not met
                return existing;
              },
              photosCustomOrder(existing: PhotoCustomOrder | undefined, { readField }) {
                const customOrderFolder = photo?.folder?.id || 'default';

                const photosOrder = readField<GalleryPhotosOrder>('photosOrder');

                // Check if using filename sorting
                const isFilenameSort =
                  photosOrder === GalleryPhotosOrder.FILENAME_ASC || photosOrder === GalleryPhotosOrder.FILENAME_DESC;

                let customFolderOrder = (existing?.[customOrderFolder] || []).concat([photo.id]);

                if (isFilenameSort) {
                  // Sort ids alphabetically based on names
                  customFolderOrder = customFolderOrder.sort((a, b) =>
                    naturalSortCollator.compare(
                      readField<string>('name', { __ref: `PhotoAdmin:${a}` }) || '',
                      readField<string>('name', { __ref: `PhotoAdmin:${b}` }) || '',
                    ),
                  );

                  // Reverse order if descending
                  if (photosOrder === GalleryPhotosOrder.FILENAME_DESC) {
                    customFolderOrder.reverse();
                  }
                }

                return {
                  ...existing,
                  [customOrderFolder]: customFolderOrder,
                };
              },
            },
          });

          // Update folder photos count
          if (photo.folder?.id) {
            updateFolderPhotosCount({
              folderId: photo.folder.id,
              count: 1,
              cache: client.cache,
            });
          } else {
            const rootKeyIdentifier = getKeyIdentifier({
              galleryId: photo.galleryId,
              characteristics: [PhotoCharacteristic.AVAILABLE],
              folderId: null,
              queryName: 'getPhotos',
            }) as 'photos';

            client.cache.modify<ReplaceWithReference<GalleryAdmin, 'photos'>>({
              id: client.cache.identify(makeReference('ROOT_QUERY')),
              fields: {
                [rootKeyIdentifier](existing) {
                  if ('_count' in existing) {
                    return {
                      ...existing,
                      _count: (existing?._count || 0) + 1,
                    };
                  }
                  return existing;
                },
              },
            });
          }

          // Update gallery photos count

          const keyIdentifier = getKeyIdentifier({
            galleryId: photo.galleryId,
            characteristics: [PhotoCharacteristic.AVAILABLE],
            queryName: 'getPhotos',
          }) as 'photos';

          client.cache.modify<ReplaceWithReference<GalleryAdmin, 'photos'>>({
            id: client.cache.identify(makeReference('ROOT_QUERY')),
            fields: {
              [keyIdentifier](existing) {
                if ('_count' in existing) {
                  return {
                    ...existing,
                    _count: (existing?._count || 0) + 1,
                  };
                }
                return existing;
              },
            },
          });
        }
      }
    },
  });

  return <>{children}</>;
};

export default PhotoAddedProvider;
