import { gql } from 'Operations/__generated__';

export const GET_AUTH = gql(/* GraphQL */ `
  query GetAuth {
    auth @client {
      isLoggedIn
      isSupport
      accessToken
      refreshToken
    }
  }
`);
