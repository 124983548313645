import { gql } from 'Operations/__generated__';

export const GET_CONTACT = gql(/* GraphQL */ `
  query GetContact($where: ContactGetWhereType!) {
    getContact(where: $where) {
      ...ContactCoreFields
      vat
      phone
      birthdate
      birthdayEmail
      favorite
      createdAt
      street
      city
      zipcode
      stateId
      countryId
      prospectOriginId
      graphicalIdentityId
      transportFee
      tags
      photoUrl
      isZoneEnabled
      taxCode
    }
  }
`);
