import React from 'react';

import { Flex, Tooltip } from 'antd';
import { createStyles } from 'antd-style';

import Card from 'Components/Atoms/Card';
import { Props } from 'Components/Atoms/ContentContainer';
import Icon, { IconName } from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

interface StatCardProps extends Props {
  label: string;
  value: JSX.Element | string | number;
  icon?: IconName;
  small?: boolean;
  type?: 'large' | 'small' | 'medium' | 'thin';
  width?: number | 'auto';
  minWidth?: number;
  tooltip?: string;
}

const useStyles = createStyles(({ css }) => ({
  container: css`
    align-items: center;
    justify-content: center;
    min-height: 100px;
    max-width: 225px;
  `,
  text: css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
}));

const StatCard = ({ label, value, icon, type = 'small', tooltip, minWidth, className, ...rest }: StatCardProps) => {
  const { styles, theme, cx } = useStyles();
  const isElement = typeof value === 'object' && value !== null;

  const StatCardContent = (
    <Card
      type={type}
      padding="smallMargin"
      className={cx(styles.container, className)}
      style={{ minWidth, justifyContent: 'center' }}
      rounded
      {...rest}
    >
      {isElement ? (
        value
      ) : (
        <Flex align="center" justify="center" gap="small">
          {icon && <Icon name={icon} />}
          <Text
            weight="bold"
            color={rest.onClick || rest.href ? theme.colorPrimary : undefined}
            className={styles.text}
            align="center"
          >
            {value}
          </Text>
        </Flex>
      )}
      <Text size="small" align="center">
        {label}
      </Text>
    </Card>
  );

  const TooltipContent = (
    <Tooltip title={tooltip} placement="top">
      {StatCardContent}
    </Tooltip>
  );

  return tooltip ? TooltipContent : StatCardContent;
};

export default StatCard;
