import React, { useContext } from 'react';

import { Tag, TagProps } from 'antd';

import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { getFeatureRequestStatusColor } from 'Helpers/getFeatureRequestStatusColor';

import { FeatureStatus } from 'Operations/__generated__/graphql';

interface Props extends TagProps {
  status?: FeatureStatus;
}

const StyledTag = styled(Tag)`
  margin: 0;
`;

const StatusTag = ({ status = FeatureStatus.OPEN, ...props }: Props) => {
  const { t } = useContext(LocalizationContext);

  return (
    <StyledTag {...props} color={getFeatureRequestStatusColor(status).tag}>
      {t(`app.featureRequest.status.${status.toLowerCase()}`)}
    </StyledTag>
  );
};

export default StatusTag;
