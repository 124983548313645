import React, { useContext } from 'react';

import { Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';

import { LocalizationContext } from 'i18n';

import yup from 'Services/YupService';

export interface NewProductCategoryFormValues {
  name: string;
}

export interface EditProductCategoryFormValues extends NewProductCategoryFormValues {
  id: number;
}

const ProductCategoryForm = <T extends EditProductCategoryFormValues | NewProductCategoryFormValues>({
  isSubmitting,
  values,
}: FormikProps<T>) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Form layout="vertical">
      <Flex align="center">
        <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
          <Input name="name" placeholder={t('app.common.name')} size="large" autoFocus />
        </Form.Item>
      </Flex>
      <Flex justify="flex-end">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t((values as EditProductCategoryFormValues).id ? 'app.common.edit' : 'app.common.add')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface NewProductCategoryFormPayload {
  values: NewProductCategoryFormValues;
  formikBag: FormikBag<NewProductCategoryFormProps, NewProductCategoryFormValues>;
}

export interface NewProductCategoryFormProps {
  onSubmit: (payload: NewProductCategoryFormPayload) => void;
}

export interface EditProductCategoryFormPayload {
  values: EditProductCategoryFormValues;
  formikBag: FormikBag<EditProductCategoryFormProps, EditProductCategoryFormValues>;
}

export interface EditProductCategoryFormProps {
  onSubmit: (payload: EditProductCategoryFormPayload) => void;
  defaultValues: EditProductCategoryFormValues;
}

const ProductCategorySchema = yup.object({
  name: yup.string().trim().required(),
});

const newProductCategorySchema: yup.SchemaOf<NewProductCategoryFormValues> = ProductCategorySchema.defined();
const editProductCategorySchema: yup.SchemaOf<EditProductCategoryFormValues> = ProductCategorySchema.shape({
  id: yup.number().required(),
}).defined();

export const NewProductCategoryForm = withFormik<NewProductCategoryFormProps, NewProductCategoryFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: () => ({
    name: '',
  }),
  validationSchema: newProductCategorySchema,
})(ProductCategoryForm);

export const EditProductCategoryForm = withFormik<EditProductCategoryFormProps, EditProductCategoryFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: editProductCategorySchema,
})(ProductCategoryForm);
