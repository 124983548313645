import React, { useContext } from 'react';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { FeatureRequestForm, FeatureRequestFormProps } from 'Forms/FeatureRequest';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

const FeatureRequestModal = (props: FeatureRequestFormProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <ModalContainer title={t('app.common.featureRequest.list')} icon={Images.taskType}>
      <FeatureRequestForm {...props} />
    </ModalContainer>
  );
};

export default FeatureRequestModal;
