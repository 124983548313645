import React from 'react';

import Text from 'Components/Atoms/Text';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

interface InfoItemProps {
  label: string;
  text: string | number | React.ReactNode;
}

const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${Metrics.smallMargin}px 0;
  border-bottom: 1px solid ${Colors.border};
`;

const InfoItem = ({ label, text }: InfoItemProps) => (
  <InfoItemContainer>
    <Text size="medium">{label}</Text>
    <Text size="medium" weight="bold">
      {text}
    </Text>
  </InfoItemContainer>
);

export default InfoItem;
