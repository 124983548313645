import { gql } from 'Operations/__generated__';

export const CREATE_UPLOAD = gql(/* GraphQL */ `
  mutation CreateUpload($data: UploadCreateInputType!) {
    createUpload(data: $data) {
      id
      createdAt
      galleryId
      status
      startAt
      endAt
    }
  }
`);
