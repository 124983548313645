import { gql } from 'Operations/__generated__';

export const GET_ORDER_ITEM_PHOTOS = gql(/* GraphQL */ `
  query GetOrderItemPhotos($where: PhotosGetWhereType!) {
    getOrderItemPhotos: getPhotos(where: $where) {
      _count
      edges {
        ... on PhotoAdmin {
          id
          name
          asset {
            noWmThumbSmall {
              downloadUrl
            }
            assetMain {
              key
              downloadUrl
            }
          }
          isRetouched
        }
      }
    }
  }
`);
