import React, { createContext, useContext, useState } from 'react';

export type MenuItemNode = React.ReactNode | React.ReactNode[] | null;

interface MenuItem {
  parentPath?: string;
  content: MenuItemNode;
}

export interface MenuRight {
  items: MenuItem[];
  setItems: (props: MenuItem[]) => void;
}

const initialState: MenuRight = {
  items: [],
  setItems: () => {
    throw new Error('Not implemented');
  },
};

const MenuRightContext = createContext(initialState);
export const useMenuRight = () => useContext(MenuRightContext);

const MenuRightProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [items, setItems] = useState<MenuItem[]>([]);

  return (
    <MenuRightContext.Provider
      value={{
        items,
        setItems,
      }}
    >
      {children}
    </MenuRightContext.Provider>
  );
};

export default MenuRightProvider;
