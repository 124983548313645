import React, { useContext } from 'react';

import { createStyles } from 'antd-style';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import Icon, { IconName } from 'Components/Atoms/Icon';
import SubSectionTitle from 'Components/Atoms/SubSectionTitle';

import { LocalizationContext } from 'i18n';

interface Props {
  recentlyConsulted?: {
    href: string;
    text: string;
    icon: IconName;
  }[];
}

const useStyles = createStyles(({ css, token, stylish }) => {
  return {
    title: css`
      margin-top: ${token.size}px;
    `,
    link: css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: ${token.sizeSM}px;

      width: 100%;
      flex: 1;

      ${stylish.link}
    `,
    text: css`
      flex: 1;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: ${token.fontSizeSM}px;
    `,
  };
});

const RecentlyConsultedList = ({ recentlyConsulted }: Props) => {
  const { t } = useContext(LocalizationContext);
  const { styles } = useStyles();

  return recentlyConsulted?.length ? (
    <>
      <SubSectionTitle className={styles.title} level="h6">
        {t('app.common.recentlyConsulted')}
      </SubSectionTitle>
      {recentlyConsulted.map(({ href, text, icon }) => (
        <Link className={clsx(styles.title, styles.link)} to={href} title={text}>
          <Icon name={icon} size={14} />
          <span className={styles.text}>{text}</span>
        </Link>
      ))}
    </>
  ) : (
    <></>
  );
};

export default RecentlyConsultedList;
