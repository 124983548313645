import React from 'react';

import { useQuery } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';

import Container from 'Components/Atoms/Container';

import MostViewedPhotos from 'Components/Molecules/Stats/MostViewedPhotos';

import { PhotoAdmin } from 'Operations/__generated__/graphql';

import { GET_MOST_VIEWED_PHOTOS } from 'Operations/Queries/Photo/GetMostViewedPhotos';

const GalleryStatsTab = ({ galleryId }: { galleryId: number }) => {
  const { data, loading: isLoading } = useQuery(GET_MOST_VIEWED_PHOTOS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      galleryId,
    },
    skip: !galleryId,
  });

  return (
    <Container padding="smallMargin">
      <MostViewedPhotos
        photos={(data?.getMostViewedPhotos.edges || []) as DeepPartial<PhotoAdmin>[]}
        isLoading={isLoading && data?.getMostViewedPhotos.edges.length === 0}
      />
    </Container>
  );
};

export default GalleryStatsTab;
