import { gql } from 'Operations/__generated__';

export const UPDATE_CATALOG = gql(/* GraphQL */ `
  mutation UpdateCatalog($where: IdInput!, $data: CatalogUpdateInput!) {
    updateCatalog(where: $where, data: $data) {
      id
      name
      isDefault
      vat
    }
  }
`);
