import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { App, Button, Empty, Radio, Tabs } from 'antd';

import { Tab } from 'rc-tabs/lib/interface';

import { compact } from 'lodash';

import Container from 'Components/Atoms/Container';

import { EditOptionForm, EditOptionFormPayload, EditOptionFormValues } from 'Forms/Option';
import {
  EditOptionCategoryForm,
  EditOptionCategoryFormPayload,
  EditOptionCategoryFormValues,
} from 'Forms/OptionCategory';
import { EditPricingPlanForm, EditPricingPlanFormPayload, EditPricingPlanFormValues } from 'Forms/PricingPlan';

import { Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import { Product, ProductOption } from 'Operations/__generated__/graphql';

import { GET_PRODUCT } from 'Operations/Queries/Product/GetProduct';

import { UPDATE_PRICING_PLAN } from 'Operations/Mutations/PricingPlan/UpdatePricingPlan';
import { UPDATE_PRODUCT } from 'Operations/Mutations/Product/UpdateProduct';
import { UPDATE_PRODUCT_OPTION } from 'Operations/Mutations/ProductOption/UpdateProductOption';
import { UPDATE_PRODUCT_OPTION_CATEGORY } from 'Operations/Mutations/ProductOptionCategory/UpdateProductOptionCategory';

import { SaveButtonConfig } from './SaveButtonType';

interface Props {
  product: DeepPartial<Product>;
  option: DeepPartial<ProductOption>;
  isReadOnly: boolean;
}

const ProductOptionConfig = ({ product, option: { pricingPlan, ...option }, isReadOnly }: Props) => {
  const { t } = useContext(LocalizationContext);
  const { message } = App.useApp();

  const [saveButton, setSaveButton] = useState<SaveButtonConfig | null>(null);

  const [updatePricingPlan] = useMutation(UPDATE_PRICING_PLAN);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [updateProductOption, { loading: isUpdateProductOptionLoading }] = useMutation(UPDATE_PRODUCT_OPTION);
  const [updateProductOptionCategory] = useMutation(UPDATE_PRODUCT_OPTION_CATEGORY);

  const isPricingPlanReadOnly = useMemo(
    () => isReadOnly && !product.isPreconfiguredProduct && !option.hasPresetPricingPlan,
    [isReadOnly, option.hasPresetPricingPlan, product.isPreconfiguredProduct],
  );

  const pricingPlanType = useMemo(
    () => (option.shouldUsePresetPricingPlan ? 'preset' : 'custom'),
    [option.shouldUsePresetPricingPlan],
  );

  const handleEditPricingPlan = useCallback(
    async ({ values, formikBag }: EditPricingPlanFormPayload) => {
      try {
        const { id, items, wholeGalleryPrice, type, profitMarginPercent } = values;

        // Update profit marginonly if it has changed
        if (product.id && profitMarginPercent !== undefined && profitMarginPercent !== product.profitMarginPercent) {
          updateProduct({
            variables: {
              where: { id: product.id },
              data: {
                profitMarginPercent,
              },
            },
          });
        } else {
          await updatePricingPlan({
            variables: {
              where: {
                id,
              },
              data: {
                items: items.map(item => ({
                  id: item.id,
                  price: item.price,
                  quantity: item.quantity,
                  additionalPrice: item.additionalPrice,
                })),
                wholeGalleryPrice,
                type,
              },
            },
            refetchQueries: [
              {
                query: GET_PRODUCT,
                variables: {
                  where: { id: product.id },
                },
              },
            ],
          });
        }

        message.success(t('app.message.pricingPlan.update.success'));
        formikBag.setSubmitting(false);
      } catch (error) {
        const errorMessage = t(`app.message.error.somethingWentWrong`);
        message.error(errorMessage);
        formikBag.setSubmitting(false);
      }
    },
    [product.id, product.profitMarginPercent, t, updatePricingPlan, updateProduct],
  );

  const handleEditProductOption = useCallback(
    async ({ values, formikBag }: EditOptionFormPayload) => {
      try {
        const { id, name, previewImage, providerDescription, providerPrice } = values;

        await updateProductOption({
          variables: {
            where: {
              id,
            },
            data: {
              name,
              previewImage,
              providerDescription,
              providerPrice,
            },
          },
        });

        message.success(t('app.message.productOption.update.success'));
        formikBag.setSubmitting(false);
      } catch (error) {
        const errorMessage = t(`app.message.error.somethingWentWrong`);
        message.error(errorMessage);
        formikBag.setSubmitting(false);
      }
    },
    [t, updateProductOption],
  );

  const handleSwitchPricingPlanPresetType = useCallback(async () => {
    if (option.id) {
      await updateProductOption({
        variables: {
          where: {
            id: option.id,
          },
          data: {
            shouldUsePresetPricingPlan: !option.shouldUsePresetPricingPlan,
          },
        },
      });

      message.success(t('app.message.productOption.update.success'));
    }
  }, [option.id, option.shouldUsePresetPricingPlan, t, updateProductOption]);

  const handleEditProductOptionCategory = useCallback(
    async ({ values, formikBag }: EditOptionCategoryFormPayload) => {
      try {
        const { id, name } = values;

        await updateProductOptionCategory({
          variables: {
            where: {
              id,
            },
            data: {
              name,
            },
          },
        });

        message.success(t('app.message.optionCategory.update.success'));
        formikBag.setSubmitting(false);
      } catch (error) {
        const errorMessage = t(`app.message.error.somethingWentWrong`);
        message.error(errorMessage);
        formikBag.setSubmitting(false);
      }
    },
    [t, updateProductOptionCategory],
  );

  const handleUpdateSubmitButton = useCallback((config: SaveButtonConfig | null) => {
    setSaveButton(config);
  }, []);

  const optionCategory = useMemo(
    () => product?.productOptionCategories?.find(poc => poc?.productOptions?.find(po => option.id === po?.id)),
    [option, product],
  );

  const pricingPlanValues: EditPricingPlanFormValues | undefined = useMemo(() => {
    if (pricingPlan?.id && pricingPlan.type) {
      return {
        id: pricingPlan.id,
        name: pricingPlan.name,
        type: pricingPlan.type,
        items: compact(
          pricingPlan.items?.map(pricingPlanItem => {
            if (pricingPlanItem?.id) {
              const { __typename, price = 0, quantity = 0, additionalPrice, id } = pricingPlanItem;
              return {
                id,
                quantity,
                additionalPrice: additionalPrice || null,
                price: option.shouldUsePresetPricingPlan
                  ? Math.round((price + (price / 100) * (product?.profitMarginPercent || 0)) * 100) / 100
                  : price,
                grossPrice: price,
              };
            }
            return undefined;
          }),
        ),
        wholeGalleryPrice: pricingPlan.wholeGalleryPrice,
        withWholeGalleryPrice: product.category?.isDigital ?? false,
        profitMarginPercent: option.shouldUsePresetPricingPlan ? product.profitMarginPercent ?? undefined : undefined,
      };
    }
    return undefined;
  }, [option.shouldUsePresetPricingPlan, pricingPlan, product.category, product.profitMarginPercent]);

  const optionValues = useMemo<EditOptionFormValues | undefined>(
    () =>
      !isReadOnly && product.category?.id && option.id
        ? {
            id: option.id,
            categoryId: product.category.id,
            name: option.name || '-',
            previewImage: undefined,
            previewImageUrl: option.previewAsset?.downloadUrl || undefined,
            isMainOption: optionCategory?.position === 1,
            providerPrice: option.providerPrice || undefined,
          }
        : undefined,
    [
      isReadOnly,
      option.id,
      option.name,
      option.previewAsset?.downloadUrl,
      option.providerPrice,
      optionCategory?.position,
      product.category,
    ],
  );

  const optionCategoryValues = useMemo<EditOptionCategoryFormValues | undefined>(
    () =>
      !isReadOnly && optionCategory && optionCategory.id
        ? {
            id: optionCategory.id,
            name: optionCategory.name || '',
            isPreconfiguredProduct: !!product.isPreconfiguredProduct,
          }
        : undefined,
    [isReadOnly, optionCategory, product.isPreconfiguredProduct],
  );

  const tabItems: Tab[] = useMemo(
    () =>
      compact([
        pricingPlanValues && {
          key: 'pricings',
          label: t('app.common.pricings'),
          children: (
            <Container padding="smallMargin" direction="column" gap={Metrics.smallMargin} justify="center">
              {product.isFotostudioProduct && !!product.presetProductId && !!pricingPlanType && (
                <Radio.Group
                  name="pricingSelect"
                  optionType="button"
                  buttonStyle="solid"
                  size="large"
                  style={{
                    display: 'flex',
                    width: '100%',
                    textAlign: 'center',
                  }}
                  value={pricingPlanType}
                  onChange={handleSwitchPricingPlanPresetType}
                  disabled={isUpdateProductOptionLoading}
                  options={[
                    {
                      value: 'preset',
                      label: t('app.pricingPlan.presetPlan'),
                      style: { flex: 1 },
                    },
                    {
                      value: 'custom',
                      label: t('app.pricingPlan.custom'),
                      style: { flex: 1 },
                    },
                  ]}
                />
              )}
              <EditPricingPlanForm
                onSubmit={handleEditPricingPlan}
                defaultValues={pricingPlanValues}
                isReadOnly={isPricingPlanReadOnly || pricingPlanType === 'preset'}
                isFotostudioProduct={product.isFotostudioProduct}
                updateSubmitButton={handleUpdateSubmitButton}
              />
            </Container>
          ),
        },
        optionValues && {
          key: 'settings',
          label: t('app.common.settings'),
          children: (
            <Container padding="smallMargin">
              <EditOptionForm
                defaultValues={optionValues}
                onSubmit={handleEditProductOption}
                isReadOnly={isReadOnly}
                product={product}
                updateSubmitButton={handleUpdateSubmitButton}
              />
            </Container>
          ),
        },
        optionCategoryValues && {
          key: 'category',
          label: t('app.common.category'),
          children: (
            <Container padding="smallMargin">
              <EditOptionCategoryForm
                defaultValues={optionCategoryValues}
                onSubmit={handleEditProductOptionCategory}
                isReadOnly={isReadOnly}
                product={product}
                updateSubmitButton={handleUpdateSubmitButton}
              />
            </Container>
          ),
        },
      ]),
    [
      handleEditPricingPlan,
      handleEditProductOption,
      handleEditProductOptionCategory,
      handleSwitchPricingPlanPresetType,
      handleUpdateSubmitButton,
      isPricingPlanReadOnly,
      isReadOnly,
      isUpdateProductOptionLoading,
      optionCategoryValues,
      optionCategoryValues,
      optionValues,
      optionValues,
      pricingPlanType,
      pricingPlanValues,
      product,
      t,
    ],
  );

  if (!pricingPlanValues && !optionValues && !optionCategoryValues) {
    return (
      <Empty
        description={
          optionCategory?.position === 1 && product.isPreconfiguredProduct
            ? t('app.products.preconfigured.optionBeforeImport')
            : undefined
        }
      />
    );
  }

  return (
    <Tabs
      tabBarExtraContent={
        <Button
          type="primary"
          onClick={saveButton?.onClick}
          size="small"
          disabled={!saveButton?.isValid || !saveButton?.dirty}
          loading={saveButton?.isSubmitting}
        >
          {t('app.common.save')}
        </Button>
      }
      onChange={() => setSaveButton(null)}
      destroyInactiveTabPane
      items={tabItems}
    />
  );
};

export default ProductOptionConfig;
