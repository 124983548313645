import React, { useContext } from 'react';

import { Button, Input, Space } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

const DropdownContainerFilter = styled.div`
  width: 250px;
  padding: ${Metrics.tinyMargin}px;
`;

const SearchFilter = ({ setSelectedKeys, selectedKeys, clearFilters, confirm }: FilterDropdownProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <>
      <DropdownContainerFilter>
        <Input
          placeholder={t('app.common.search')}
          value={selectedKeys[0] as string}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
          autoFocus
        />
      </DropdownContainerFilter>
      <Space className="ant-table-filter-dropdown-btns">
        <Button
          onClick={() => {
            if (clearFilters) {
              clearFilters();
            }
          }}
          size="small"
          disabled={!selectedKeys[0] ? true : false}
        >
          {t('app.common.reset')}
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            confirm();
          }}
        >
          {t('app.common.ok')}
        </Button>
      </Space>
    </>
  );
};

export default SearchFilter;
