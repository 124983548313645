import ContentLoader from 'react-content-loader';

import Container from 'Components/Atoms/Container';
import ContentContainer from 'Components/Atoms/ContentContainer';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

const StyledContainer = styled(Container)`
  flex: 1;
  width: 100%;
`;

const StyledPricingPlanContainer = styled(ContentContainer)`
  flex: 1;
  margin-right: ${Metrics.smallMargin}px;
`;

const StyledPhotoTypeContainer = styled(ContentContainer)`
  flex: 0.7;
  margin-left: ${Metrics.smallMargin}px;
`;

const DigitalProductConfigSkeleton = () => {
  const height = 600;
  const photoTypeHeight = 200;

  return (
    <StyledContainer>
      <StyledPricingPlanContainer shadow rounded padding="baseMargin">
        <ContentLoader speed={2} width="100%" height={height} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
          <rect x="0" y="0" rx="16" ry="16" width="100%" height={height} />
        </ContentLoader>
      </StyledPricingPlanContainer>
      <StyledPhotoTypeContainer shadow rounded padding="baseMargin">
        <ContentLoader
          speed={2}
          width="100%"
          height={photoTypeHeight}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="16" ry="16" width="100%" height={photoTypeHeight} />
        </ContentLoader>
      </StyledPhotoTypeContainer>
    </StyledContainer>
  );
};

export default DigitalProductConfigSkeleton;
