import { useCallback, useContext, useMemo, useState } from 'react';

import { CaretRightOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Alert, Button, Collapse, Empty, Flex, Result } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form } from 'formik-antd';

import Spinner from 'Components/Atoms/Spinner';

import Select from 'Components/Molecules/Form/Select';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';
import yup from 'Services/YupService';

import { GalleryWorkmode, OrderAdmin } from 'Operations/__generated__/graphql';

import { GET_ORDERS_WITH_DIGITAL_ITEMS } from 'Operations/Queries/Order/GetOrdersWithDigitalItems';
import { GET_ORDERABLE_DIGITAL_PHOTOS } from 'Operations/Queries/Photo/GetOrderableDigitalPhotos';

export interface AddPhotosToOrderFormValues {
  galleryId: number;
  photoIds: number[];
  orderId?: number;
}
interface FormProps {
  workmode: GalleryWorkmode;
}

const AlertStyled = styled(Alert)`
  margin-bottom: ${Metrics.baseMargin}px;
`;

const DeleteAlert = styled(Alert)`
  flex: 1;
`;

const PhotosList = styled.ul`
  margin: 0;
`;

const panelStyle: React.CSSProperties = {
  marginBottom: Metrics.baseMargin,
  background: Colors.lightGrey,
  borderRadius: Metrics.boxRadius,
  border: `1px solid ${Colors.grey}`,
};

const AddPhotosToOrderForm = <T extends AddPhotosToOrderFormValues>({
  isSubmitting,
  values,
  workmode,
}: FormikProps<T> & FormProps) => {
  const { t } = useContext(LocalizationContext);

  const [ordersParams, setOrdersParams] = useState<{ galleryId: number; page: number; perPage: number }>({
    galleryId: values.galleryId,
    page: 1,
    perPage: 20,
  });

  const {
    data,
    loading: isOrdersLoading,
    error,
  } = useQuery(GET_ORDERS_WITH_DIGITAL_ITEMS, {
    variables: {
      where: ordersParams,
    },
  });

  const {
    data: photosData,
    loading: isPhotosLoading,
    error: photosError,
  } = useQuery(GET_ORDERABLE_DIGITAL_PHOTOS, {
    fetchPolicy: 'network-only',
    skip: !values.orderId,
    variables: {
      where: {
        galleryId: values.galleryId,
        ids: values.photoIds,
        orderId: values.orderId as number,
      },
    },
  });

  const handleLoadMoreOrders = useCallback(() => {
    setOrdersParams({ ...ordersParams, page: ordersParams.page + 1 });
  }, [ordersParams]);

  const collapseItems = useMemo(() => {
    const items = [];

    if (photosData && photosData.getOrderableDigitalPhotos.ordered._count > 0) {
      if (photosData.getOrderableDigitalPhotos.available._count > 0) {
        items.push({
          key: '1',
          label: `${t('app.gallery.photos.addToOrder.available', {
            count: photosData.getOrderableDigitalPhotos.available._count,
          })}`,
          children: (
            <PhotosList>
              {photosData.getOrderableDigitalPhotos.available.edges.map(photo => (
                <li key={photo.id}>{photo.name}</li>
              ))}
            </PhotosList>
          ),
          style: { ...panelStyle, background: Colors.backgroundSuccess, borderColor: Colors.success },
        });
      }

      if (photosData.getOrderableDigitalPhotos.ordered._count <= values.photoIds.length) {
        items.push({
          key: '2',
          label: `${t('app.gallery.photos.addToOrder.ordered', {
            count: photosData.getOrderableDigitalPhotos.ordered._count,
          })}`,
          children: (
            <PhotosList>
              {photosData.getOrderableDigitalPhotos.ordered.edges.map(photo => (
                <li key={photo.id}>{photo.name}</li>
              ))}
            </PhotosList>
          ),
          style: { ...panelStyle, background: Colors.backgroundDanger, borderColor: Colors.danger },
        });
      }
    }

    return items;
  }, [photosData, t, values.photoIds.length]);

  if (isOrdersLoading) {
    return <Spinner />;
  }

  if (!data || error || photosError) {
    return <Result status="error" title={t('app.message.error.somethingWentWrong')} />;
  }

  if (data.getOrdersWithDigitalItems._count === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('app.gallery.photos.addToOrder.noOrder')} />;
  }

  const { _count: ordersCount } = data.getOrdersWithDigitalItems;

  return (
    <>
      <Form layout="vertical">
        {!photosData && (
          <AlertStyled
            type="info"
            message={`${t('app.gallery.photos.addToOrder.selected', { count: values.photoIds.length })}`}
          />
        )}
        {workmode === GalleryWorkmode.RETOUCH_FIRST && (
          <AlertStyled type="warning" message={t('app.gallery.photos.addToOrder.retouchMode')} />
        )}
        <Form.Item label={t('app.common.order', { count: 1 })} name="orderId" required hasFeedback={false}>
          <Select
            name="orderId"
            size="large"
            optionFilterProp="children"
            loadMore={handleLoadMoreOrders}
            hasFetchAll={data.getOrdersWithDigitalItems.edges.length === ordersCount}
            loading={isOrdersLoading}
            getPopupContainer={trigger => trigger.parentNode}
            showSearch
          >
            {(data.getOrdersWithDigitalItems.edges as OrderAdmin[]).map(order => (
              <Select.Option key={order.id} value={order.id} title={''}>
                {order.contact?.displayName} - {order.items.find(i => i.isDigital && i.productName)?.productName} -{' '}
                {dayjs(order.createdAt).format('LL')}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {isPhotosLoading && (
          <Flex align="center" justify="center">
            <Spinner />
          </Flex>
        )}

        {photosData && photosData.getOrderableDigitalPhotos.ordered._count > 0 && (
          <>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              style={{ background: 'none' }}
            >
              {collapseItems.map(item => (
                <Collapse.Panel key={item.key} header={item.label} style={item.style}>
                  {item.children}
                </Collapse.Panel>
              ))}
            </Collapse>
          </>
        )}

        <Flex justify="flex-end" align="center" gap="middle">
          {photosData && photosData.getOrderableDigitalPhotos.available._count > 0 && (
            <DeleteAlert
              type="warning"
              message={t('app.gallery.photos.addToOrder.deleteDisabled', {
                count: photosData.getOrderableDigitalPhotos.available._count,
              })}
            />
          )}
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            loading={isSubmitting}
            disabled={!values.orderId || !photosData || photosData?.getOrderableDigitalPhotos.available._count === 0}
          >
            {t('app.common.add')}
          </Button>
        </Flex>
      </Form>
    </>
  );
};

export interface AddPhotosToOrderPayload {
  values: AddPhotosToOrderFormValues;
  formikBag: FormikBag<AddPhotosToOrderFormProps, AddPhotosToOrderFormValues>;
}

export interface AddPhotosToOrderFormProps extends FormProps {
  onSubmit: (payload: AddPhotosToOrderPayload) => void;
  defaultValues: AddPhotosToOrderFormValues;
}

const AddPhotosToOrderSchema: yup.SchemaOf<AddPhotosToOrderFormValues> = yup
  .object({
    galleryId: yup.number().required(),
    photoIds: yup.array().required(),
    orderId: yup.number().required(),
  })
  .defined();

export const AddPhotosToOrder = withFormik<AddPhotosToOrderFormProps, AddPhotosToOrderFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: AddPhotosToOrderSchema,
})(AddPhotosToOrderForm);
