import React from 'react';

import ContentLoader from 'react-content-loader';

interface ImageCardSkeletonProps {
  width: number;
  height: number;
}

const ImageCardsSkeleton = ({ width, height }: ImageCardSkeletonProps) => {
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="16" ry="16" width={width} height={height} />
    </ContentLoader>
  );
};

export default ImageCardsSkeleton;
