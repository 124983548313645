import { gql } from 'Operations/__generated__';

export const UPDATE_PHOTOS_ORDER = gql(/* GraphQL */ `
  mutation UpdatePhotosOrder($where: PhotosUpdateOrderWhereType!, $data: IdsInput!) {
    updatePhotosOrder(where: $where, data: $data) {
      id
      photosCustomOrder
      photosOrder
    }
  }
`);
