import { gql } from 'Operations/__generated__';

export const GET_PAGINATED_GALLERY_PRESETS = gql(/* GraphQL */ `
  query GetPaginatedGalleryPresets($where: PaginatedInput) {
    getPaginatedGalleryPresets: getGalleryPresets(where: $where) {
      _count
      edges {
        id
        name
        createdAt
      }
    }
  }
`);
