import React, { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import CardContainer from 'Components/Atoms/CardContainer';
import Layout from 'Components/Atoms/Layout';

import OrderTable from 'Components/Molecules/Tables/OrderTable';

import { GetOrdersWhereInput, OrderType } from 'Operations/__generated__/graphql';

import { GET_ORDER_LIST } from 'Operations/Queries/Order/GetOrderList';

const PER_PAGE = 20;

const GalleryOrdersTab = ({ galleryId }: { galleryId: number }) => {
  const [paginationParams, setPaginationParams] = useState<GetOrdersWhereInput>({
    page: 1,
    order: OrderType.DESC,
  });

  const {
    data,
    loading: isGetOrdersLoading,
    fetchMore,
    refetch,
    called: isCalled,
  } = useQuery(GET_ORDER_LIST, {
    skip: !galleryId,
    variables: {
      where: {
        galleryId: galleryId as number,
        page: 1,
        perPage: PER_PAGE,
        order: OrderType.DESC,
        crossAccounts: false,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (galleryId && isCalled) {
      fetchMore({
        variables: {
          where: { ...paginationParams, perPage: PER_PAGE, galleryId: galleryId, crossAccounts: false },
        },
      });
    }
  }, [paginationParams, galleryId, fetchMore, isCalled]);

  const handleRefetch = useCallback(
    () =>
      galleryId &&
      refetch({
        where: { ...paginationParams, perPage: PER_PAGE, galleryId: galleryId, crossAccounts: false },
      }),
    [galleryId, refetch, paginationParams],
  );

  return (
    <CardContainer>
      <Layout flexDirection="column">
        <OrderTable
          data={data?.getOrders.edges || []}
          loading={isGetOrdersLoading}
          ordersTotal={data?.getOrders._count || 0}
          paginationCurrent={paginationParams.page || 1}
          crossAccounts={false}
          paginationSize={PER_PAGE}
          handleOnChange={setPaginationParams}
          onRefetch={handleRefetch}
          isBordered
          shouldHideGallery
        />
      </Layout>
    </CardContainer>
  );
};

export default GalleryOrdersTab;
