import React, { useCallback, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Flex } from 'antd';

import { EDGES_PARAMS } from 'constants/paginationParams';

import { Form } from 'formik-antd';
import { chain } from 'lodash';

import Select from 'Components/Molecules/Form/Select';

import { ContactCoreFieldsFragment, ContactsGetInputType } from 'Operations/__generated__/graphql';

import { GET_CONTACTS } from 'Operations/Queries/Contact/GetContacts';

interface SelectContactProps {
  /**
   * Label for the select
   */
  label?: string;
  /**
   * Name for the select form item
   */
  name: string;
  /**
   * Selected contacts
   */
  value: number[];
  /**
   * Contacts that are already selected and should be excluded from the list
   */
  excludedContacts?: number[];
  /**
   * Contacts that are already loaded and should be included in the list
   */
  loadedContacts: ContactCoreFieldsFragment[];
  /**
   * If the select should allow multiple selections
   */
  multiple?: boolean;
  /**
   * Children to be rendered inside the select
   */
  children?: React.ReactNode;
}

const SelectContact = ({ loadedContacts, excludedContacts, label, name, multiple, children }: SelectContactProps) => {
  const [contactsQueryParams, setContactsQueryParams] = useState<ContactsGetInputType>({});

  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery(GET_CONTACTS, {
    fetchPolicy: 'cache-and-network',
    returnPartialData: true,
    variables: {
      where: contactsQueryParams,
      paginate: EDGES_PARAMS,
    },
  });

  // Merge the paginated contacts and the already loaded contacts (like the selected ones)
  const contacts = useMemo(
    () =>
      chain(data?.getContacts?.edges || [])
        .concat(loadedContacts || [])
        .compact()
        .uniqBy('id')
        .orderBy([({ displayName }) => displayName?.toLocaleLowerCase() || ''])
        .value(),
    [data?.getContacts?.edges, loadedContacts],
  );

  // Filter out the contacts that are excluded
  const contactsList = useMemo(
    () => contacts.filter(contact => !excludedContacts?.includes(contact.id)),
    [contacts, excludedContacts],
  );

  const handleSearchContact = useCallback((searchTerms: string) => {
    setContactsQueryParams({
      search: searchTerms.length > 0 ? searchTerms : undefined,
    });
  }, []);

  const handleLoadMoreContacts = useCallback(async () => {
    await fetchMore({
      variables: {
        paginate: { ...EDGES_PARAMS, page: (data?.getContacts?.pageInfo?.currentPage || 1) + 1 },
      },
    });
  }, [data?.getContacts?.pageInfo?.currentPage, fetchMore]);

  return (
    <Form.Item label={label} name={name}>
      <Flex vertical gap="small">
        <Select
          name={name}
          size="large"
          showSearch
          onSearch={handleSearchContact}
          optionFilterProp="children"
          loadMore={handleLoadMoreContacts}
          hasFetchAll={!!data?.getContacts?.pageInfo?.isLastPage}
          loading={isLoading}
          getPopupContainer={trigger => trigger.parentNode}
          mode={multiple ? 'multiple' : undefined}
          allowClear
        >
          {contactsList.map(contact => (
            <Select.Option key={contact.id} value={contact.id} title={''}>
              {contact.displayName}
            </Select.Option>
          ))}
        </Select>
        {children}
      </Flex>
    </Form.Item>
  );
};

export default SelectContact;
