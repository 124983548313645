import React, { useContext } from 'react';

import { Card } from 'antd';

import Container from 'Components/Atoms/Container';
import Text from 'Components/Atoms/Text';

import { LocalizationContext } from 'i18n';

interface ClientInfoCardProps {
  firstName: string;
  lastName: string;
  email: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
}

const ClientInfoCard = ({ firstName, lastName, street, zipCode, city, country }: ClientInfoCardProps) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Card bordered={false}>
      <Container direction="column" align="center">
        <Text size="small">{t('app.common.clientAddress')}</Text>
        <Text weight="bold">
          {firstName} {lastName}
        </Text>
        <Text size="small" weight="bold">
          {street}
        </Text>
        <Text size="small" weight="bold">
          {zipCode} {city}, {country}
        </Text>
      </Container>
    </Card>
  );
};

export default ClientInfoCard;
