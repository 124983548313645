import React from 'react';

import { Colors, FontSize, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { css } from 'styled-components';

type TagColor = 'primary-gradient';

interface Props {
  color?: TagColor | string;
  textColor?: string;
  weight?: 'normal' | 'bold' | 'lighter';
  size?: 'regular' | 'medium' | 'small' | 'tiny';
  border?: 'small' | 'large';
  width?: string;
  children: React.ReactNode;
}

const StyledTag = styled.div<Props>`
  display: block;
  width: ${({ width = 'fit-content' }) => width};
  padding: 2px 8px;
  font-weight: ${({ weight = 'bold' }) => weight};
  font-size: ${({ size = 'tiny' }) => FontSize[size]}px;
  color: ${({ textColor = Colors.white }) => textColor};
  border: ${({ border }) => (border ? Metrics.border[border] : '0')}px solid ${Colors.white};
  border-radius: 14px;
  box-sizing: border-box;
  text-align: center;

  ${({ color }: Props) => {
    switch (color) {
      case 'primary-gradient':
        return css`
          background: ${Colors.primaryOptional};
          background: -moz-linear-gradient(90deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
          background: -webkit-linear-gradient(90deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
          background: linear-gradient(90deg, ${Colors.primaryMain} 0%, ${Colors.primaryOptional} 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${Colors.primaryMain}",endColorstr="${Colors.primaryOptional}",GradientType=1);
        `;

      default:
        return css`
          background-color: ${color};
        `;
    }
  }};
`;

const Tag = ({ color, textColor, weight, size, children, ...props }: Props) => {
  return (
    <StyledTag color={color} textColor={textColor} weight={weight} size={size} {...props}>
      {children}
    </StyledTag>
  );
};

export default Tag;
