import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Alert, Button, Flex } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { DatePicker, Form, Input, InputNumber, Switch } from 'formik-antd';
import { camelCase, chain, compact, flatten, kebabCase, uniqBy } from 'lodash';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import Select from 'Components/Molecules/Form/Select';
import SelectContact from 'Components/Molecules/Inputs/SelectContact';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import i18n, { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';
import yup from 'Services/YupService';

import generateId from 'Helpers/GenerateId';

import {
  GalleryAccessPolicy,
  GalleryAdmin,
  GalleryDownload,
  GalleryStatus,
  GalleryWorkmode,
  GetCatalogsQuery,
  GetGalleryPresetsQuery,
  Locale,
  OrderType,
} from 'Operations/__generated__/graphql';

import { GET_CATALOG } from 'Operations/Queries/Catalog/GetCatalog';
import { GET_CATALOGS } from 'Operations/Queries/Catalog/GetCatalogs';
import { GET_COMPANY_SETTINGS } from 'Operations/Queries/Company/GetCompanySettings';
import { GET_GALLERY_CONTACTS } from 'Operations/Queries/Gallery/GetGalleryContacts';
import { GET_GALLERY_PRESET } from 'Operations/Queries/GalleryPreset/GetGalleryPreset';
import { GET_GALLERY_PRESETS } from 'Operations/Queries/GalleryPreset/GetGalleryPresets';
import { GET_PROJECTS } from 'Operations/Queries/Project/GetProjects';
import { ME } from 'Operations/Queries/User/Me';

export interface NewGalleryFormValues {
  projectId?: number | null;
  galleryPresetId?: number | null;
  name: string;
  url?: string | null;
  video?: string | null;
  contactId?: number | null;
  secondaryContactIds?: number[] | null;
  status: GalleryStatus;
  availableAt?: string | null;
  expiredAt?: string | null;
  shootedAt?: string | null;
  accessCode?: string;
  inPortfolio: boolean;
  isShopEnable: boolean;
  catalogId?: number | null;
  workmode?: GalleryWorkmode | null;
  accessPolicy: GalleryAccessPolicy;
}

export interface EditGalleryFormValues extends NewGalleryFormValues {
  id: number;
}

interface GalleryFormProps {}

interface DataQueryParams {
  perPage: number;
  order: OrderType;
  page: number;
  search?: string;
}

const PER_PAGE = 20;
const EDGES_PARAMS = {
  perPage: PER_PAGE,
  order: OrderType.ASC,
  page: 1,
};

const WarningText = styled(Text)`
  margin-bottom: ${Metrics.baseMargin}px;
`;

const GalleryForm = <T extends EditGalleryFormValues | NewGalleryFormValues>({
  isSubmitting,
  values,
  initialValues,
  setValues,
  setFieldValue,
  setFieldTouched,
  getFieldMeta,
  getFieldProps,
}: FormikProps<T> & GalleryFormProps) => {
  const { t } = useContext(LocalizationContext);
  const digitalProductsPage = useRef(1);

  const { onChange: onAccessTypeChanged } = getFieldProps('accessType');

  const [isAccessCodeInputAvailable, setIsAccessCodeInputVisible] = useState<boolean>(
    values.accessPolicy === GalleryAccessPolicy.ACCESS_CODE,
  );

  const [contactsQueryParams, setContactsQueryParams] = useState<DataQueryParams>(EDGES_PARAMS);
  const [projectsQueryParams, setProjectsQueryParams] = useState<DataQueryParams>(EDGES_PARAMS);
  const [catalogsQueryParams, setCatalogsQueryParams] = useState<DataQueryParams>(EDGES_PARAMS);
  const [presetsQueryParams, setPresetsQueryParams] = useState<DataQueryParams>(EDGES_PARAMS);

  const galleryId = (values as EditGalleryFormValues).id;
  const catalogId = (values as EditGalleryFormValues).catalogId;

  const { data: meData } = useQuery(ME);

  const hasCRM = !!meData?.me?.hasCRM;

  // Fetch slected gallery contacts to show them even if not in the current pagination
  const { data: galleryContactsData } = useQuery(GET_GALLERY_CONTACTS, {
    fetchPolicy: 'cache-first',
    skip: !galleryId,
    variables: {
      where: {
        id: galleryId,
      },
    },
  });

  const {
    data: presetsData,
    loading: isPresetsLoading,
    fetchMore: fetchMorePresets,
  } = useQuery(GET_GALLERY_PRESETS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: presetsQueryParams,
    },
  });

  const [getPreset, { data: galleryPresetData }] = useLazyQuery(GET_GALLERY_PRESET);

  const {
    data: projectsData,
    loading: isProjectsLoading,
    fetchMore: fetchMoreProjects,
  } = useQuery(GET_PROJECTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    skip: !hasCRM,
    variables: {
      where: projectsQueryParams,
    },
  });

  const {
    data: catalogsData,
    loading: isCatalogsLoading,
    fetchMore: fetchMoreCatalogs,
  } = useQuery(GET_CATALOGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: catalogsQueryParams,
    },
  });

  useQuery(GET_CATALOG, {
    skip: !catalogId,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        id: catalogId as number,
      },
    },
  });

  const { data: userSettingsData } = useQuery(GET_COMPANY_SETTINGS, {
    fetchPolicy: 'cache-first',
  });

  const catalogs: {
    _count: number;
    edges: GetCatalogsQuery['getCatalogs']['edges'];
  } = useMemo(() => {
    if (!catalogsData?.getCatalogs) {
      return { _count: 0, edges: [] };
    }

    return {
      edges: uniqBy([...catalogsData.getCatalogs.edges], 'id'),
      _count: catalogsData.getCatalogs._count,
    };
  }, [catalogsData?.getCatalogs]);

  const galleryContacts = useMemo(
    () => (galleryContactsData?.getGallery?.__typename === 'GalleryAdmin' ? galleryContactsData.getGallery : null),
    [galleryContactsData?.getGallery],
  );

  // Merge the paginated contacts and the gallery contacts
  const contacts = useMemo(() => {
    const mergedContacts = [];
    if (galleryContacts) {
      if (galleryContacts.contact) {
        mergedContacts.push(galleryContacts.contact);
      }
      if (galleryContacts.secondaryContacts?.length) {
        mergedContacts.push(...galleryContacts.secondaryContacts);
      }
    }

    if (projectsData?.getProjects.edges?.length) {
      const projectContacts = projectsData.getProjects.edges.map(({ contact }) => contact);
      const projectSecondaryContacts = projectsData.getProjects.edges.map(({ secondaryContacts }) => secondaryContacts);
      mergedContacts.push(...projectContacts);
      mergedContacts.push(...flatten(projectSecondaryContacts));
    }

    return compact(mergedContacts);
  }, [galleryContacts, projectsData?.getProjects.edges]);

  const projects = useMemo(() => {
    if (projectsData?.getProjects.edges) {
      return uniqBy(projectsData.getProjects.edges, 'id');
    }

    return [];
  }, [projectsData?.getProjects.edges]);

  const galleryPresets: {
    _count: number;
    edges: GetGalleryPresetsQuery['getGalleryPresets']['edges'];
  } = useMemo(() => {
    if (!presetsData?.getGalleryPresets) {
      return { _count: 0, edges: [] };
    }

    return {
      edges: uniqBy([...presetsData.getGalleryPresets.edges], 'id'),
      _count: presetsData.getGalleryPresets._count,
    };
  }, [presetsData]);

  const hasFetchAllProjects = useMemo(
    () => projects?.length === projectsData?.getProjects?._count,
    [projectsData, projects],
  );

  const isAccessCodeGallery = useMemo(
    () => values.accessPolicy === GalleryAccessPolicy.ACCESS_CODE,
    [values.accessPolicy],
  );

  const isOldAccessCodeGallery = useMemo(
    () => values.accessPolicy === GalleryAccessPolicy.OLD_ACCESS_CODE,
    [values.accessPolicy],
  );

  useEffect(() => {
    const presetId = initialValues.galleryPresetId || values.galleryPresetId;
    if (presetId) {
      getPreset({
        variables: {
          where: {
            id: presetId,
          },
        },
      });
    }
  }, [getPreset, initialValues.galleryPresetId, values.galleryPresetId]);

  useEffect(() => {
    if (
      values.status === GalleryStatus.ONLINE &&
      values.availableAt &&
      dayjs(values.availableAt).isSameOrAfter(dayjs())
    ) {
      setFieldValue('availableAt', null);
    }
  }, [values.status, values.availableAt, setFieldValue]);

  useEffect(() => {
    const { initialValue, value, touched: isTouched } = getFieldMeta('url');
    if (!initialValue && !value && !isTouched) {
      setFieldValue('url', kebabCase(values.name));
    }
  }, [getFieldMeta, setFieldValue, values.name]);

  useEffect(() => {
    if (!values.catalogId) {
      let defaultCatalog = catalogs.edges.find(catalog => catalog.isDefault);

      if (!defaultCatalog) {
        defaultCatalog = catalogs.edges[0];
      }

      if (defaultCatalog) {
        setFieldValue('catalogId', defaultCatalog.id);
      }
    }
  }, [catalogs.edges, setFieldValue, values.catalogId]);

  useEffect(() => {
    if (!values.workmode && userSettingsData?.getCompany?.settings) {
      setFieldValue('workmode', userSettingsData?.getCompany.settings.defaultGalleryWorkmode);
    }
  }, [setFieldValue, userSettingsData?.getCompany?.settings, values.workmode]);

  useEffect(() => {
    if (values.projectId) {
      const project = projects.find(project => project.id === values.projectId);
      if (!project) {
        return;
      }
      setValues(oldValues => ({
        ...oldValues,
        name: oldValues.name || project.name,
        url: oldValues.url || kebabCase(project.name),
        contactId: oldValues.contactId || project.contactId,
        secondaryContactIds: oldValues.secondaryContactIds || project.secondaryContactsIds,
        shootedAt: oldValues.shootedAt || project.startTime,
      }));
    }
  }, [projects, setValues, values.projectId]);

  useEffect(() => {
    if (values.accessPolicy === GalleryAccessPolicy.ACCESS_CODE) {
      if (
        (!initialValues.accessCode &&
          initialValues.accessPolicy === GalleryAccessPolicy.PUBLIC &&
          values.accessPolicy === GalleryAccessPolicy.ACCESS_CODE) ||
        initialValues.contactId !== values.contactId
      ) {
        setFieldValue('accessCode', generateId({ size: 6 }));
      }

      if (initialValues.accessCode && initialValues.contactId === values.contactId) {
        setFieldValue('accessCode', initialValues.accessCode);
      }
    } else {
      if (
        initialValues.accessPolicy !== GalleryAccessPolicy.EMAIL_REQUIRED &&
        values.accessPolicy === GalleryAccessPolicy.EMAIL_REQUIRED
      ) {
        setFieldValue('accessCode', '');
      }
    }
  }, [
    initialValues.accessCode,
    initialValues.accessPolicy,
    initialValues.contactId,
    setFieldValue,
    values.accessPolicy,
    values.contactId,
  ]);

  const renderAccessPolicyValues = useCallback(
    (translationsPath: string) => {
      const keys = Object.keys(GalleryAccessPolicy);
      const values = compact(
        keys.map(key => {
          if (key === GalleryAccessPolicy.OLD_PUBLIC || key === GalleryAccessPolicy.OLD_ACCESS_CODE) {
            return;
          }

          return (
            <Select.Option key={key} value={key} title={''}>
              {t(`${translationsPath}.${camelCase(key.toLowerCase())}`)}
            </Select.Option>
          );
        }),
      );
      return values;
    },
    [t],
  );

  const handleAccessPolicyChange = useCallback(
    (value: GalleryAccessPolicy) => {
      onAccessTypeChanged(value);

      if (value === GalleryAccessPolicy.ACCESS_CODE) {
        const currentAccessCode = getFieldProps('accessCode').value;
        if (currentAccessCode === null) {
          setFieldValue('accessCode', '');
        }
      }
      setIsAccessCodeInputVisible(value === GalleryAccessPolicy.ACCESS_CODE);
    },
    [getFieldProps, onAccessTypeChanged, setFieldValue],
  );

  useEffect(() => {
    if (galleryPresetData?.getGalleryPreset.id) {
      const {
        getGalleryPreset: { accessPolicy, catalog, locale, workmode },
      } = galleryPresetData;

      setValues(oldValues => ({
        ...oldValues,
        accessPolicy,
        catalogId: catalog?.id,
        locale,
        workmode,
      }));

      setIsAccessCodeInputVisible(accessPolicy === GalleryAccessPolicy.ACCESS_CODE);
    }
  }, [galleryPresetData, setIsAccessCodeInputVisible, setValues]);

  const handleLoadMoreGalleryPresets = useCallback(async () => {
    const params = { ...presetsQueryParams, page: presetsQueryParams.page + 1 };
    await fetchMorePresets({
      variables: {
        where: params,
      },
    });

    setPresetsQueryParams(params);
  }, [fetchMorePresets, presetsQueryParams]);

  const handleLoadMoreProjects = useCallback(async () => {
    const params = { ...projectsQueryParams, page: projectsQueryParams.page + 1 };
    await fetchMoreProjects({
      variables: {
        where: params,
      },
    });

    setProjectsQueryParams(params);
  }, [fetchMoreProjects, projectsQueryParams]);

  const handleLoadMoreCatalogs = useCallback(async () => {
    const params = { ...catalogsQueryParams, page: catalogsQueryParams.page + 1 };

    await fetchMoreCatalogs({
      variables: {
        where: params,
      },
    });

    setCatalogsQueryParams(params);
  }, [catalogsQueryParams, fetchMoreCatalogs]);

  const handleSearchProject = useCallback(async (searchTerms: string) => {
    setProjectsQueryParams({
      ...EDGES_PARAMS,
      search: searchTerms.length > 0 ? searchTerms : undefined,
    });
  }, []);

  const handleSearchContact = useCallback((searchTerms: string) => {
    setContactsQueryParams({
      ...EDGES_PARAMS,
      search: searchTerms.length > 0 ? searchTerms : undefined,
    });
  }, []);

  return (
    <Form layout="vertical">
      {hasCRM && (
        <Flex gap="middle">
          <Form.Item label={t('app.gallery.project')} name="projectId" hasFeedback={false}>
            <Select
              name="projectId"
              size="large"
              showSearch
              optionFilterProp="children"
              onSearch={handleSearchProject}
              loadMore={handleLoadMoreProjects}
              hasFetchAll={hasFetchAllProjects}
              loading={isProjectsLoading}
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Select.Option
                key={-1}
                // Because ant design select item value of type Key can only be string or number and not null
                // @ts-ignore
                value={null}
                title={''}
              >
                -
              </Select.Option>
              {projects.map(project => (
                <Select.Option key={project.id} value={project.id} title={''}>
                  {project.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!galleryId && !initialValues.galleryPresetId && (
            <Form.Item label={t('app.gallery.preset.label')} name="galleryPresetId" hasFeedback={false}>
              <Select
                name="galleryPresetId"
                size="large"
                loadMore={handleLoadMoreGalleryPresets}
                hasFetchAll={galleryPresets._count === galleryPresets.edges.length}
                loading={isPresetsLoading}
                getPopupContainer={trigger => trigger.parentNode}
              >
                <Select.Option
                  key={-1}
                  // Because ant design select item value of type Key can only be string or number and not null
                  // @ts-ignore
                  value={null}
                  title={''}
                >
                  -
                </Select.Option>
                {galleryPresets.edges.map(preset => (
                  <Select.Option key={preset.id} value={preset.id} title={''}>
                    {preset.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Flex>
      )}
      <Flex gap="middle">
        <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
          <Input
            name="name"
            placeholder={t('app.common.name')}
            size="large"
            onChange={({ target: { value } }) => {
              setFieldValue('name', value);
              const { touched: isTouched, initialValue } = getFieldMeta('url');
              if (!initialValue && !isTouched) {
                setFieldValue('url', kebabCase(value));
              }
            }}
          />
        </Form.Item>
        <Form.Item label={t('app.common.customUrl')} name="url" hasFeedback={false}>
          <Input
            name="url"
            placeholder={t('app.common.customUrl')}
            size="large"
            onBlur={({ target: { value } }) => setFieldValue('url', kebabCase(value))}
            onChange={({ target: { value } }) => {
              setFieldTouched('url', true);
              setFieldValue('url', value);
            }}
          />
        </Form.Item>
      </Flex>
      <Flex>
        {values.accessPolicy !== GalleryAccessPolicy.OLD_PUBLIC &&
          values.accessPolicy !== GalleryAccessPolicy.OLD_ACCESS_CODE && (
            <Form.Item name="accessPolicy" label={t('app.gallery.accessType')} required hasFeedback={false}>
              <Select
                name="accessPolicy"
                size="large"
                onChange={handleAccessPolicyChange}
                getPopupContainer={trigger => trigger.parentNode}
              >
                {renderAccessPolicyValues('app.gallery.accessTypes')}
              </Select>
            </Form.Item>
          )}
      </Flex>
      <Flex gap="middle">
        <SelectContact
          label={t('app.common.contact')}
          name="contactId"
          loadedContacts={contacts}
          excludedContacts={values?.secondaryContactIds || []}
          value={values.contactId ? [values.contactId] : []}
        />

        {/* <Form.Item
          label=
          name="contactId"
          required={isAccessCodeInputAvailable}
          hasFeedback={false}
        >
          <Select
            name="contactId"
            size="large"
            showSearch
            onSearch={handleSearchContact}
            optionFilterProp="children"
            loadMore={handleLoadMoreContacts}
            hasFetchAll={contactCount - (values?.secondaryContactIds?.length || 0) <= contacts.length}
            loading={isContactsLoading}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {contactsList.map(contact => (
              <Select.Option key={contact.id} value={contact.id} title={''}>
                {contact.displayName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        {(isAccessCodeGallery || isOldAccessCodeGallery) && (
          <Form.Item
            label={isOldAccessCodeGallery ? t('app.gallery.oldAccessCode') : t('app.gallery.accessCode')}
            name="accessCode"
            required={isAccessCodeInputAvailable}
            hasFeedback={false}
          >
            <Input
              name="accessCode"
              placeholder={t('app.gallery.accessCode')}
              size="large"
              disabled={!isAccessCodeInputAvailable || isOldAccessCodeGallery}
            />
          </Form.Item>
        )}
      </Flex>
      {!!(values as EditGalleryFormValues).id &&
        isAccessCodeGallery &&
        !!initialValues.accessCode &&
        values.accessCode !== initialValues.accessCode &&
        values.contactId === initialValues.contactId && (
          <WarningText size="medium">
            <Icon name="warning" size={24} /> {t('app.gallery.warning.accessCodeModification')}
          </WarningText>
        )}
      <Flex>
        <SelectContact
          label={t('app.common.secondaryContacts')}
          name="secondaryContactIds"
          loadedContacts={contacts}
          excludedContacts={values.contactId ? [values.contactId] : []}
          value={values.secondaryContactIds || []}
          multiple
        />
        {/* <Form.Item label={t('app.common.secondaryContacts')} name="secondaryContactIds" hasFeedback={false}>
          <Select
            name="secondaryContactIds"
            mode="multiple"
            size="large"
            onSearch={handleSearchContact}
            autoClearSearchValue
            showSearch
            optionFilterProp="children"
            loadMore={handleLoadMoreContacts}
            hasFetchAll={contactCount - (values?.contactId ? 1 : 0) <= contacts.length}
            loading={isContactsLoading}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {secondaryContactsList.map(contact => (
              <Select.Option key={contact.id} value={contact.id} title={''}>
                {contact.displayName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
      </Flex>
      {!(values as EditGalleryFormValues).id && (
        <Flex gap="middle">
          <Form.Item label={t('app.gallery.availableAt')} name="availableAt" hasFeedback={false}>
            <DatePicker
              showNow={false}
              name="availableAt"
              size="large"
              showTime={{ format: 'HH:mm', defaultValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
              format="LLL"
            />
          </Form.Item>
          <Form.Item label={t('app.gallery.expiredAt')} name="expiredAt" hasFeedback={false}>
            <DatePicker
              showNow={false}
              name="expiredAt"
              size="large"
              showTime={{ format: 'HH:mm', defaultValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
              format="LLL"
            />
          </Form.Item>
        </Flex>
      )}
      {(values as EditGalleryFormValues).id && (
        <>
          <Flex gap="middle">
            <Form.Item label={t('app.common.status')} name="status" required hasFeedback={false}>
              <Select name="status" size="large" getPopupContainer={trigger => trigger.parentNode}>
                {Object.keys(GalleryStatus)
                  .filter(status => status !== GalleryStatus.ARCHIVED)
                  .map(status => (
                    <Select.Option key={status} value={status} title={''}>
                      {t(`app.gallery.status.${status.toLowerCase()}`)}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            {values.status === GalleryStatus.OFFLINE && (
              <Form.Item label={t('app.gallery.availableAt')} name="availableAt" hasFeedback={false}>
                <DatePicker
                  showNow={false}
                  name="availableAt"
                  size="large"
                  showTime={{ format: 'HH:mm', defaultOpenValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
                  format="LLL"
                />
              </Form.Item>
            )}
          </Flex>
          <Flex>
            <Form.Item label={t('app.gallery.expiredAt')} name="expiredAt" hasFeedback={false}>
              <DatePicker
                showNow={false}
                name="expiredAt"
                size="large"
                showTime={{ format: 'HH:mm', defaultOpenValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
                format="LLL"
              />
            </Form.Item>
          </Flex>
        </>
      )}
      <Flex>
        <Form.Item label={t('app.gallery.shootedAt')} name="shootedAt" hasFeedback={false}>
          <DatePicker name="shootedAt" size="large" format="YYYY-MM-DD" minuteStep={5} />
        </Form.Item>
      </Flex>
      <Flex gap="middle">
        <Form.Item label={t('app.gallery.locale.label')} name="locale" required hasFeedback={false}>
          <Select name="locale" size="large" getPopupContainer={trigger => trigger.parentNode}>
            {Object.keys(Locale).map(lang => (
              <Select.Option key={lang} value={lang.toLowerCase()} title={''}>
                {t(`app.locale.${lang.toLowerCase()}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('app.gallery.workmode.label')} name="workmode" required hasFeedback={false}>
          <Select name="workmode" size="large" getPopupContainer={trigger => trigger.parentNode}>
            {Object.keys(GalleryWorkmode).map(mode => (
              <Select.Option key={mode} value={mode} title={''}>
                {t(`app.gallery.settings.defaultWorkmode.${camelCase(mode)}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Flex>
      {!values.galleryPresetId && (
        <Flex>
          <Form.Item label={t('app.common.catalog', { count: 1 })} name="catalogId" required hasFeedback={false}>
            <Flex vertical gap="small">
              <Select
                name="catalogId"
                size="large"
                loadMore={handleLoadMoreCatalogs}
                hasFetchAll={catalogs._count === catalogs.edges.length}
                loading={isCatalogsLoading}
                getPopupContainer={trigger => trigger.parentNode}
              >
                {catalogs.edges.map(catalog => (
                  <Select.Option key={catalog.id} value={catalog.id} title={''}>
                    {catalog.name}
                  </Select.Option>
                ))}
              </Select>
              <Alert type="info" message={t('app.gallery.catalogInfo')} />
            </Flex>
          </Form.Item>
        </Flex>
      )}

      {/* TODO: enable isShopEnable when feature is implemented */}
      {/* <Form.Item label={t('app.gallery.isShopEnable')} name="isShopEnable" className="ant-form-item--switch" hasFeedback={false}>
          <Switch name="isShopEnable" />
        </Form.Item> */}
      <Flex>
        <Form.Item label={t('app.gallery.video.label')} name="video" hasFeedback={false}>
          <Input name="video" placeholder={t('app.gallery.video.placeholder')} size="large" />
        </Form.Item>
      </Flex>
      <Form.Item
        label={t('app.gallery.inPortfolio')}
        name="inPortfolio"
        className="ant-form-item--switch"
        hasFeedback={false}
      >
        <Switch name="inPortfolio" />
      </Form.Item>
      <Flex justify="flex-end">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting}>
          {t((values as EditGalleryFormValues).id ? 'app.common.edit' : 'app.common.add')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface NewGalleryFormPayload {
  values: NewGalleryFormValues;
  formikBag: FormikBag<NewGalleryFormProps, NewGalleryFormValues>;
}

export interface NewGalleryFormProps extends GalleryFormProps {
  onSubmit: (payload: NewGalleryFormPayload) => void;
  defaultValues: NewGalleryFormValues;
}

export interface EditGalleryFormPayload {
  values: EditGalleryFormValues;
  formikBag: FormikBag<EditGalleryFormProps, EditGalleryFormValues>;
}

export interface EditGalleryFormProps extends GalleryFormProps {
  onSubmit: (payload: EditGalleryFormPayload) => void;
  defaultValues: EditGalleryFormValues;
}

const isFiveMinutesRound = function (value?: string | null) {
  return value ? dayjs(value).get('minutes') % 5 === 0 : true;
};

const gallerySchema = yup.object({
  projectId: yup.number().nullable(),
  galleryPresetId: yup.number().nullable(),
  name: yup.string().trim().required(),
  url: yup.string().nullable(),
  video: yup
    .string()
    .nullable()
    .test('is-video-url-valid', i18n.t('app.gallery.wrongVideoLink'), value => {
      if (value) {
        return /youtube\.com\/|youtu\.be\/|vimeo\.com\//.test(value);
      }
      return true;
    }),
  contactId: yup.number().when('accessPolicy', {
    is: GalleryAccessPolicy.ACCESS_CODE,
    then: yup.number().required(),
    otherwise: yup.number().nullable(),
  }),
  secondaryContactIds: yup.array().nullable(),
  status: yup.mixed<GalleryStatus>().required(),
  locale: yup.mixed<Locale>().required(),
  availableAt: yup
    .string()
    .trim()
    .nullable()
    .test('availableAt5min', () => i18n.t('app.form.errors.date.round5minutes'), isFiveMinutesRound),
  expiredAt: yup
    .string()
    .trim()
    .test('expiredAt5min', () => i18n.t('app.form.errors.date.round5minutes'), isFiveMinutesRound)
    .when('availableAt', {
      is: (availableAt: string) => !!availableAt,
      // @ts-ignore
      then: yup.string().nullable().after(yup.ref('availableAt')),
      otherwise: yup.string().nullable(),
    }),
  shootedAt: yup.string().trim().nullable(),
  accessPolicy: yup.mixed<GalleryAccessPolicy>().required(),
  accessCode: yup.string().trim().when('accessPolicy', {
    is: GalleryAccessPolicy.ACCESS_CODE,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
  inPortfolio: yup.boolean().required(),
  isShopEnable: yup.boolean().required(),
  workmode: yup.mixed<GalleryWorkmode>().required(),
  catalogId: yup.number(),
});

const newGallerySchema: yup.SchemaOf<NewGalleryFormValues> = gallerySchema.defined();
const editGallerySchema: yup.SchemaOf<EditGalleryFormValues> = gallerySchema
  .shape({ id: yup.number().required() })
  .defined();

export const NewGalleryForm = withFormik<NewGalleryFormProps, NewGalleryFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: newGallerySchema,
})(GalleryForm);

export const EditGalleryForm = withFormik<EditGalleryFormProps, EditGalleryFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: editGallerySchema,
})(GalleryForm);
