import React, { useContext } from 'react';

import ContentLoader from 'react-content-loader';

import Layout from 'Components/Atoms/Layout';

import { Metrics } from 'Themes';
import styled, { ThemeContext } from 'Themes/Styled';

const WatermarksContainer = styled(Layout)`
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: ${Metrics.smallMargin}px;
`;

interface WatermarkLoaderProps {
  backgroundColor: string;
  foregroundColor: string;
}

const Loader = ({ backgroundColor, foregroundColor, ...props }: WatermarkLoaderProps) => (
  <ContentLoader
    speed={2}
    width={274}
    height={251}
    viewBox="0 0 274 251"
    backgroundColor={backgroundColor}
    foregroundColor={foregroundColor}
    {...props}
  >
    <rect x="0" y="0" rx={Metrics.baseMargin} ry={Metrics.baseMargin} width="274" height="251" />
  </ContentLoader>
);

const TitleLoader = ({ backgroundColor, foregroundColor, ...props }: WatermarkLoaderProps) => (
  <ContentLoader
    speed={2}
    width={488}
    height={24}
    viewBox="0 0 488 24"
    backgroundColor={backgroundColor}
    foregroundColor={foregroundColor}
    {...props}
  >
    <rect x="0" y="0" rx={Metrics.smallMargin} ry={Metrics.smallMargin} width="488" height="24" />
  </ContentLoader>
);

const WatermarkLoader = styled(Loader)`
  margin: ${Metrics.smallMargin}px;
`;

const WatermarkTabSkeleton = () => {
  const theme = useContext(ThemeContext);

  const { background, foreground } = theme?.colors.loader || { background: '#f3f3f3', foreground: '#ecebeb' };

  return (
    <>
      <TitleLoader backgroundColor={background} foregroundColor={foreground} />
      <WatermarksContainer>
        <WatermarkLoader backgroundColor={background} foregroundColor={foreground} />
        <WatermarkLoader backgroundColor={background} foregroundColor={foreground} />
        <WatermarkLoader backgroundColor={background} foregroundColor={foreground} />
        <WatermarkLoader backgroundColor={background} foregroundColor={foreground} />
      </WatermarksContainer>
    </>
  );
};

export default WatermarkTabSkeleton;
