import React, { useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, App, Flex, Radio } from 'antd';
import { createStyles } from 'antd-style';

import clsx from 'clsx';

import Text from 'Components/Atoms/Text';
import Title from 'Components/Atoms/Title';

import Header from 'Components/Molecules/Header';

import { LocalizationContext } from 'i18n';

import { GET_COMPANY } from 'Operations/Queries/Company/GetCompany';

import { UPDATE_BILLING_SETTINGS } from 'Operations/Mutations/Company/UpdateBillingSettings';

const useStyles = createStyles(({ css, token, responsive }) => ({
  container: css`
    margin-top: ${token.size}px;
  `,
  card: css`
    flex: 1;
    ${responsive.xxl} {
      flex-basis: 30%;
      max-width: 50%;
    }
    ${responsive.xl} {
      flex-basis: 45%;
      max-width: 75%;
    }
    ${responsive.md} {
      flex-basis: 100%;
      max-width: 100%;
    }
  `,
  loader: css`
    width: 100%;
    height: 300px;
  `,
}));

const InvoiceSettings = () => {
  const { t } = useContext(LocalizationContext);
  const { styles } = useStyles();
  const { message } = App.useApp();

  const [updateBillingSettings, { loading: isLoading }] = useMutation(UPDATE_BILLING_SETTINGS);
  const { data: companyData } = useQuery(GET_COMPANY);

  return (
    <>
      <Header
        title={t('app.common.invoicesSettings')}
        breadcrumbContent={[
          { text: t('app.menu.home'), url: '/app/dashboard' },
          { text: t('app.common.invoices', { count: 2 }), url: '/app/invoices' },
          { text: t('app.common.invoicesSettings') },
        ]}
        buttons={[]}
      />
      <Flex gap="middle" wrap="wrap" className={styles.container}>
        <Flex
          vertical
          gap="large"
          className={clsx('ant-flex--shadow ant-flex--rounded ant-flex--padding', styles.card)}
        >
          <Title level="h3">{t('app.invoices.settings.automaticInvoice.title')}</Title>
          <Alert message={t('app.gallery.settings.alert.byDefault')} />

          <Text>{t('app.invoices.settings.automaticInvoice.description')}</Text>

          {!!companyData?.getCompany && (
            <Radio.Group
              defaultValue={companyData.getCompany.genInvoice}
              buttonStyle="solid"
              size="large"
              disabled={isLoading}
              onChange={({ target }) =>
                updateBillingSettings({
                  variables: { data: { genInvoice: target.value } },
                  onCompleted() {
                    message.success(t('app.invoices.settings.automaticInvoice.success'));
                  },
                })
              }
            >
              <Radio.Button value={false}>{t('app.common.disabled')}</Radio.Button>
              <Radio.Button value={true}>{t('app.common.enabled')}</Radio.Button>
            </Radio.Group>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default InvoiceSettings;
