import { gql } from 'Operations/__generated__';

export const REFRESH = gql(/* GraphQL */ `
  mutation Refresh($data: AuthRefreshInputType!) {
    refresh(data: $data) {
      accessToken
      refreshToken
    }
  }
`);
