import React, { useContext, useEffect, useState } from 'react';

import { useMutation, useSubscription } from '@apollo/client';
import { Button, Progress } from 'antd';

import Container from 'Components/Atoms/Container';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { Images, Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import { JobType } from 'Operations/__generated__/graphql';

import { ZIP_ORDER } from 'Operations/Mutations/Order/ZipOrder';

import { ON_JOB_UPDATED } from 'Operations/Subscriptions/OnJobUpdated';
import { ON_ZIP_ORDER_COMPLETED } from 'Operations/Subscriptions/OnZipOrderCompleted';

interface Props {
  orderId?: number;
}

const DownloadPhotosModal = ({ orderId }: Props) => {
  const { t } = useContext(LocalizationContext);
  const [progress, setProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState<null | string>(null);

  const [zipOrder] = useMutation(ZIP_ORDER);

  useSubscription(ON_JOB_UPDATED, {
    shouldResubscribe: true,
    skip: !orderId,
    variables: {
      where: {
        orderId,
      },
    },
    onData({ data }) {
      const job = data.data?.OnJobUpdated;
      if (job && job.jobType === JobType.ORDER_ZIP && job.order?.id === orderId) {
        setProgress(job.percent || 0);
      }
    },
  });
  useSubscription(ON_ZIP_ORDER_COMPLETED, {
    shouldResubscribe: true,
    skip: !orderId,
    variables: {
      where: {
        id: orderId as number,
      },
    },
    onData({ data }) {
      if (data.data?.OnZipOrderCompleted) {
        setDownloadUrl(data.data.OnZipOrderCompleted);
        setProgress(100);
      }
    },
  });

  useEffect(() => {
    if (orderId) {
      zipOrder({
        variables: {
          where: {
            id: orderId,
          },
        },
      });
    }
  }, [orderId, zipOrder]);

  return (
    <ModalContainer
      title={t(`app.modal.downloadPhotos.title`)}
      icon={Images.download}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <Container direction="column" gap={Metrics.baseMargin} align="center" justify="center" style={{ height: '100%' }}>
        <Progress type="circle" percent={progress} />
        <Button loading={!downloadUrl} href={downloadUrl || ''} target="_blank" type="primary" disabled={!downloadUrl}>
          {t(downloadUrl ? 'app.order.downloadPhotos' : 'app.modal.downloadPhotos.zipCreationInProgress')}
        </Button>
      </Container>
    </ModalContainer>
  );
};

export default DownloadPhotosModal;
