import { gql } from 'Operations/__generated__';

export const UPDATE_WAITING_ORDER_STATUS = gql(/* GraphQL */ `
  mutation UpdateWaitingOrderStatus($where: IdInput!) {
    updateWaitingOrderStatus(where: $where) {
      id
      status
    }
  }
`);
