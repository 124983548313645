import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Space } from 'antd';

import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import { ADD_FEATURE_VOTE } from 'Operations/Mutations/FeatureRequest/AddFeatureVote';

interface Props {
  id: number;
  hasVoted: boolean;
  votesCount?: number;
}

const FeatureLikeButton = ({ id, hasVoted, votesCount }: Props) => {
  const [addVote] = useMutation(ADD_FEATURE_VOTE);

  const handleLike = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      addVote({
        variables: {
          where: {
            id,
          },
        },
      });
    },
    [addVote, id],
  );

  return (
    <Space direction="vertical" align="center">
      <Button
        type={hasVoted ? 'primary' : 'default'}
        shape="circle"
        size="large"
        icon={<Icon name="heart" />}
        onClick={handleLike}
      />
      {votesCount !== undefined && <Text size="small">{votesCount}</Text>}
    </Space>
  );
};

export default FeatureLikeButton;
