import React, { useContext, useEffect, useState } from 'react';

import { useMutation, useSubscription } from '@apollo/client';
import { Button, Progress } from 'antd';

import Container from 'Components/Atoms/Container';

import ModalContainer from 'Components/Molecules/FormModalContainer';

import { Images, Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import { JobType } from 'Operations/__generated__/graphql';

import { ZIP_FAVORITE_PHOTOS } from 'Operations/Mutations/Photo/ZipFavoritePhotos';

import { ON_JOB_UPDATED } from 'Operations/Subscriptions/OnJobUpdated';
import { ON_ZIP_FAVORITE_PHOTOS_COMPLETED } from 'Operations/Subscriptions/OnZipFavoritePhotosCompleted';

interface Props {
  galleryId: number;
}

const DownloadFavoritePhotosModal = ({ galleryId }: Props) => {
  const { t } = useContext(LocalizationContext);
  const [progress, setProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState<null | string>(null);

  const [zipFavoritePhotos] = useMutation(ZIP_FAVORITE_PHOTOS);

  useSubscription(ON_JOB_UPDATED, {
    shouldResubscribe: true,
    variables: {
      where: {
        galleryId,
      },
    },
    onData({ data }) {
      const job = data.data?.OnJobUpdated;
      if (job && job.jobType === JobType.FAVORITE_PHOTOS_ZIP && job.gallery?.id === galleryId) {
        setProgress(job.percent || 0);
      }
    },
  });
  useSubscription(ON_ZIP_FAVORITE_PHOTOS_COMPLETED, {
    shouldResubscribe: true,
    variables: {
      where: {
        id: galleryId,
      },
    },
    onData({ data }) {
      if (data.data?.OnZipFavoritePhotosCompleted) {
        setDownloadUrl(data.data.OnZipFavoritePhotosCompleted);
        setProgress(100);
      }
    },
  });

  useEffect(() => {
    zipFavoritePhotos({
      variables: {
        where: {
          id: galleryId,
        },
      },
    });
  }, [galleryId, zipFavoritePhotos]);

  return (
    <ModalContainer
      title={t(`app.modal.downloadFavoritePhotos.title`)}
      icon={Images.download}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <Container direction="column" gap={Metrics.baseMargin} align="center" justify="center" style={{ height: '100%' }}>
        <Progress type="circle" percent={progress} />
        <Button loading={!downloadUrl} href={downloadUrl || ''} target="_blank" type="primary" disabled={!downloadUrl}>
          {t(downloadUrl ? 'app.order.downloadPhotos' : 'app.modal.downloadPhotos.zipCreationInProgress')}
        </Button>
      </Container>
    </ModalContainer>
  );
};

export default DownloadFavoritePhotosModal;
