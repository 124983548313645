import { gql } from 'Operations/__generated__';

export const CONVERT_INVOICE = gql(/* GraphQL */ `
  mutation ConvertReceipt($where: IdInput!) {
    convertReceipt(where: $where) {
      id
      isReceipt
      numero
    }
  }
`);
